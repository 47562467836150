import React from 'react';
import { eCountry } from '../../../../../data/countries';
import { ePlatform, GetPlatform } from '../../../../../data/platforms';
import { SafeHtmlWrapper } from '../../../../../small-components/safe-html-wrapper';
import { T } from '../../../../../utils/transShim';
import { TryGetSolutionAmazon } from '../error-solutions/solutions-amazon';
import { TryGetSolutionEbay } from '../error-solutions/solutions-ebay';
import { TryGetSolutionFacebook } from '../error-solutions/solutions-facebook';
import { TryGetSolutionShopify } from '../error-solutions/solutions-shopify';

type Props = {
  channelId: ePlatform | undefined;
  country: eCountry;
  errorMessage: string;
  errorSourceInfo: string | undefined;
}

export const ErrorSolution = (props: Props) => {
  const { channelId, country, errorMessage, errorSourceInfo } = props;

  if (!errorMessage)
    return <></>;

  const ar = errorMessage?.split('\n') ?? [];
  const solution = (() => {
    switch (channelId) {
      case ePlatform.eBay:
      case ePlatform.eBayNoApi:
        return TryGetSolutionEbay(country, errorMessage, errorSourceInfo ?? '');
      case ePlatform.Amazon:
        return TryGetSolutionAmazon();
      case ePlatform.Shopify:
        return TryGetSolutionShopify();
      case ePlatform.Facebook:
        return TryGetSolutionFacebook();
      default:
        return null;
    }
  })();

  const RenderSolution = (solution: JSX.Element[]) => {
    return (
      <React.Fragment key='solution'>
        <br />
        <b><T k='Listings.Pending.HowToSolve' /></b>
        {solution}
      </React.Fragment>
    );
  };
  const externalStyle = { border: '1px solid #ccc', borderRadius: 5, padding: 10, backgroundColor: 'rgba(0,0,0,0.02)' };//TODO: Move this to scss
  return (
    <>
      <b><T k='Listings.Pending.ErrorComesFrom' values={{ name: GetPlatform(channelId).name} } />:</b>
      {ar.map((x, i) => {
        const potentialHtml = x; //.replace('\'\'','\'')

        return <div key={i} style={externalStyle}>
          <SafeHtmlWrapper html={potentialHtml} />
        </div>;
      })}
      {solution && solution?.length > 0 && RenderSolution(solution)}
    </>
  );

};