import { Col, Row, Switch } from 'antd';
import { ePlatform, GetPlatform } from '../../../data/platforms';
import { DangerBtn, PrimaryBtn } from '../../../small-components/ActionBtns';
import { HelpTooltip } from '../../../small-components/help-tooltip';
import { TokensCosts } from '../../../small-components/Tokens';
import { ReactUtils } from '../../../utils/react-utils';
import { T } from '../../../utils/transShim';
import { Links } from 'src/links';
import { A } from '../../../small-components/A';


interface Props {
  onNext: () => void;
  optimizeTitle: boolean;
  optimizeDescription: boolean;
  setOptimizeTitle: (b: boolean) => void;
  setOptimizeDescription: (b: boolean) => void;
}

export const OptimiseStep = (props: Props) => {
  const { onNext, optimizeTitle, optimizeDescription, setOptimizeTitle, setOptimizeDescription } = props;
  const channel = ReactUtils.GetSelectedChannel();

  const HandleOptimizeDescription = () => setOptimizeDescription(!optimizeDescription);
  const HandleptimizeTitleHandle = () => setOptimizeTitle(!optimizeTitle);

  const DrawSource = (id: number) => {
    return (
      <div key={id} className="source">
        <img style={{ opacity: 0 }} className="source-img" src={require('../../../assets/logos/213.png')?.default} />
      </div>
    );
  };

  return (
    <div className="smart-lister-bar-optimise">
      <div className="optimise-div">
        <div className="title-step">
          <h2>
            <T k="SmartLister.NewFilter.Optimize" />
            <HelpTooltip>
              <ul className="onnly-bullets">
                <li>
                  <T k="Publish.Optimize.Explanation.1" />
                </li>
                <li>
                  <T
                    k="Publish.Optimize.Explanation.2"
                    values={{ searchEngineName: GetPlatform(channel?.channelId ?? ePlatform.eBay).searchEngineName }}
                  />
                </li>
                <li>
                  <T k="Publish.Optimize.Explanation.3" />
                </li>
                <li>
                  <T k="Publish.Optimize.Explanation.4" />
                </li>
                <li>
                  <T k="Publish.Optimize.Explanation.5" />
                </li>
                <li>
                  <T k="Publish.Optimize.Explanation.6" />
                </li>
              </ul>
            </HelpTooltip>
          </h2>
        </div>
        <div>
          <div className="form-item">
            <div className="section-option-container">
              <div className="section-option">
                <div className="section-switch">
                  <Switch checked={optimizeTitle} onChange={HandleptimizeTitleHandle} />
                </div>
                <div className="section-title-container">
                  <h5 className="section-option">
                    <T k="SmartLister.NewFilter.Optimize.Title" />
                    <TokensCosts cost={1} />
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="form-item">
            <div className="section-option-container">
              <div className="section-option">
                <div className="section-switch">
                  <Switch checked={optimizeDescription} onChange={HandleOptimizeDescription} />
                </div>
                <div className="section-title-container">
                  <h5 className="section-option">
                    <T k="SmartLister.NewFilter.Optimize.Description" />
                    <TokensCosts cost={2} />
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <p>
            <T k="Publish.Optimize.TotalCostPerProduct" values={{ quantity: (optimizeTitle ? 1 : 0) + (optimizeDescription ? 2 : 0) }} />
          </p>
        </div>
      </div>
      {/* This is to increase the size of the window to be similar to sources-step*/}
      <Row className="inv-row">
        <Col xs={12}></Col>
        <Col xs={12}>
          <div className="form-item">
            <div className="sourcesList">{[-1, -2, -3].map(DrawSource)}</div>
          </div>
        </Col>
      </Row>
      <div className="buttons-next-back">
        <A to={Links.SmartLister.Base}>
          <DangerBtn>Cancel</DangerBtn>
        </A>
        <PrimaryBtn htmlType="submit" onClick={onNext}>
          <T k="SmartLister.NewFilter.Save" />
        </PrimaryBtn>
      </div>
    </div>
  );
};
