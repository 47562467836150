import React from 'react';
import { PaymentMethodsDataMethod } from '../../../../redux/payment/paymentSlice';
import { Divider, Radio, RadioChangeEvent } from 'antd';
import { CloseOutlined, CreditCardFilled } from '@ant-design/icons';
import { PrimaryBtn } from '../../../../small-components/ActionBtns';
import { eCurrency } from '../../../../types/eCurrency';
import { FailedPayment } from '../../../../small-components/payment/failed-payment';
import { T } from '../../../../utils/transShim';

export interface PaymentMethodProps {
  data: PaymentMethodsDataMethod;
  relink: (data: PaymentMethodsDataMethod) => void;
  changeCurrency: (currency: eCurrency, methodId: number) => void;
  currencies: eCurrency[];
  removePayment: (data: PaymentMethodsDataMethod) => void;
  retryPayment: (id: PaymentMethodsDataMethod, currencies: eCurrency[]) => void;
}

export const PaymentMethods = (props: PaymentMethodProps) => {
  const { data, relink, changeCurrency, currencies, removePayment, retryPayment } = props;
  const radioChange = (e: RadioChangeEvent) => {
    if (e.target.checked) changeCurrency(e.target.value as eCurrency, data.id);
  };

  const cSet = new Set(currencies);

  const inputR = (c: eCurrency) => {
    const str = eCurrency[c].toString();
    const strId = 'currencyRadio_' + str + '_' + data.id;
    return (
      <div className="currencyP">
        <Radio
          className="currency-selector-input"
          type="checkbox"
          name={str}
          value={c}
          onChange={radioChange}
          checked={cSet.has(c)}
          id={strId}
        />
        <label htmlFor={strId}>{str}</label>
      </div>
    );
  };

  const onClickRemove = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    removePayment(data);
    evt.preventDefault();
    evt.stopPropagation();
  };
  const RetryPayment = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    retryPayment(data, currencies);
    evt.preventDefault();
    evt.stopPropagation();
  };

  return (
    <div className="all-payment-methods-container">
      <div className={'paymentLine' + (data.lastPaymentFailed ? ' alert alert-danger' : '')}>
        {!!data.lastPaymentFailed && <div className="pmm"><T k='Payment.Failed'/>{': '} {<FailedPayment error={data.lastPaymentError}/>}</div>}
        <div className="single-payment-method">
          <div className="payment-fix-buttons">
            <PrimaryBtn onClick={() => relink(data)}>
              {!!data.lastPaymentFailed && 'Fix'}
              {!data.lastPaymentFailed && 'Change or relink card'}
            </PrimaryBtn>
            {data.lastPaymentFailed && <PrimaryBtn onClick={RetryPayment}>Retry</PrimaryBtn>}
          </div>
          <Divider className="divider" />
          <div className="top-section">
            <CreditCardFilled />
            <p className="card-number">**** **** **** {data.last4}</p>
            <CloseOutlined className="close-icon" onClick={onClickRemove} />
          </div>
          <div className="currencies-selector">
            {inputR(eCurrency.USD)}
            {inputR(eCurrency.EUR)}
            {inputR(eCurrency.GBP)}
            {inputR(eCurrency.AUD)}
          </div>
        </div>
      </div>
    </div>
  );
};
