import { ReactNode } from 'react';
import '../../sass/tables/data-table.scss';
import { ColumnType, TableRowSelection } from 'antd/es/table/interface';
import { ExpandableType, OnRow, SimpleTable } from './simple-table';
import { ActionMenuKind, ActionMenuType } from './types/action-menu-type';
import { SuccessBtn, SecondaryBtn } from '../ActionBtns';

export type DataTableKey = React.Key;

interface Props<RecordType> {
  columns: ColumnType<RecordType>[];

  dataSource: RecordType[];
  totalItems?: number;
  productNamePlural?: React.ReactNode;
  loading?: boolean | ReactNode;
  showTableInfo?: boolean;
  hideTotal?: boolean;
  total?: number;
  rowClassName?: string;
  onRow?: OnRow<RecordType>;
  rowSelection?: TableRowSelection<RecordType>;
  isListingsTable?: boolean;
  expandable?: ExpandableType<RecordType>;

  currentPage?: number;
  onPageChange?: (pageNumber: number) => void;
  pageSize?: number;
  onPageSizeChanged?: (pageNumber: number) => void;
  pageSizes?: number[];
  hidePagination?: boolean;

  onChangeVisibleRows?: (rows: RecordType[]) => void;

  actionsMenu?: ActionMenuType[];
}
//eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
export const DataTable = <RecordType extends object = any>(props: Props<RecordType>) => {
  const {
    columns,
    dataSource,
    rowSelection,
    totalItems,
    productNamePlural,
    showTableInfo,
    hideTotal,
    onRow,
    rowClassName,
    currentPage,
    pageSize,
    pageSizes,
    onPageChange,
    onPageSizeChanged,
    hidePagination,
    onChangeVisibleRows,
    actionsMenu,
    expandable
  } = props;

  const pageSizeOptionArray = pageSizes ?? [10, 20, 50, 100, 250, 500];
  //const selectedRows = rowSelection?.selectedRowKeys?.length ?? 0;

  const RenderActions = () => {
    if (!actionsMenu || actionsMenu.length == 0)
      return <></>;

    const RenderBtn = (x: ActionMenuType, i: number) => {
      switch (x.type) {
        default:
        case ActionMenuKind.Normal:
          return <SecondaryBtn key={x.key ?? i} onClick={x.onClick} > {x.label}</SecondaryBtn >;
        case ActionMenuKind.Important:
          return <SuccessBtn key={x.key ?? i} onClick={x.onClick} > {x.label}</SuccessBtn>;
      }
    };

    return <>
      {actionsMenu.map(RenderBtn)}
    </>;

    //return <Dropdown overlay={<Menu items={actionsMenu} />} className="actions-dropdown">
    //  <Space>{/* Dropdown will crash if you don't put this <space> thing*/}
    //    <T k='Table.BulkAction' />
    //    <DownOutlined />
    //  </Space>
    //</Dropdown>;
  };

  return (
    <div className="data-table-container">
      {showTableInfo && (
        <div className="table-info">
          {/*{selectedRows ? (*/}
          {/*  <><strong><CheckSquareOutlined />{' '}{selectedRows}</strong></>*/}
          {/*) : null}*/}
          {RenderActions()}
          {!hideTotal && <p className="total-items">
            <strong>
              {totalItems ?? dataSource?.length ?? 0} {productNamePlural}
            </strong>
          </p>}
        </div>
      )}

      <SimpleTable
        columns={columns}
        dataSource={dataSource}
        rowSelection={rowSelection}
        rowClassName={rowClassName}
        onRow={onRow}
        currentPage={currentPage}
        pageSize={pageSize}
        hidePagination={hidePagination}
        onPageChange={onPageChange}
        onPageSizeChanged={onPageSizeChanged}
        pageSizes={pageSizeOptionArray}
        onChangeVisibleRows={onChangeVisibleRows}
        expandable={expandable}
      />
    </div>
  );
};
