import { useState } from 'react';
import { Layout, Menu } from 'antd';
import type { MenuProps } from 'antd';
import { useHistory } from 'react-router-dom';
import { T } from '../../utils/transShim';
import {
  DashBoardIcon,
  CatalogIcon,
  ListNowIcon,
  ListingsIcon,
  ServiceIcon,
  SettingsIcon,
  HelpIcon,
  OrdersIcon,
  LogoutIcon,
  SmartListerIcon
} from '../common/Icons';
import { MenuListItem } from './MenuListItem';
import { actions } from '../../redux/user/userSlice';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { persistor } from 'src/redux/store';
import { MobileSiderDrawer } from '../../small-components/MobileSiderDrawer';
import { LeftOutlined, PushpinOutlined } from '@ant-design/icons';
import '../../sass/side-bar.scss';
import { Links } from '../../links';
import { ChatContainerActionsRef } from '../../small-components/chat-container';
import { A } from '../../small-components/A';
import { UserUtil } from '../../utils/user';
import { ReactUtils } from '../../utils/react-utils';
import { ePlatform } from '../../data/platforms';

interface Props {
  className: string;
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
  toggle?: () => void;
  staticValue?: boolean;
  togglestatic?: () => void;
  handleSidebarMobile?: () => void;
  mobileSiderVisible: boolean;
  closeMobileSider: () => void;

  chatEnabled: boolean;
  chatActions: ChatContainerActionsRef;
  affiliates: boolean;
}
//interface SubMenu { id: string, listName: React.ReactNode, route: string, pathHttp?: boolean, onClick?: () => void }

const mobileScreenSize = window.matchMedia('(max-width: 1030px)');

type MenuItem = Required<MenuProps>['items'][number];

export const Sidebar = (props: Props) => {
  const { collapsed, staticValue, togglestatic, className, setCollapsed, mobileSiderVisible, closeMobileSider, chatEnabled, chatActions, affiliates } = props;

  const dispatch = useAppDispatch();
  const rootSubmenuKeys: string[] = ['6', '7', '8'];
  const history = useHistory();

  const { quota, user } = useAppSelector((state) => state.user);

  const selectedChannel = ReactUtils.GetSelectedChannel();
  const hideSmartListing = UserUtil.HideSmartLister(user?.user) || selectedChannel?.channelId == ePlatform.Amazon;

  const HandleMouseEnter = () => {
    if (staticValue) {
      return;
    }
    setCollapsed(false);
  };

  const HandleMouseLeave = () => {
    if (staticValue) {
      return;
    }
    setCollapsed(true);
  };

  const RouteChange = (route: string) => {
    history.push(route);
    if (mobileScreenSize.matches) closeMobileSider();
  };

  const HandleLogout = () => {
    const keysToRemove = ['root', 'Authorization', 'globalTheme', 'isAuthenticated', 'newChannelSuccess'];
    dispatch(actions.logout());
    keysToRemove.forEach((key) => localStorage.removeItem(key));
    persistor.purge();
    RouteChange(Links.Credentials.Login);
  };


  type menuI = {
    k: number,
    u?: string,
    t: React.ReactNode,
    i?: React.ReactNode,
    c?: menuI[],
    pathHttp?: boolean,
    onClick?: () => void
  };

  const publishProducts: menuI[] = [
    { k: 11, t: <T k="SmartLister.Name" />, u: Links.SmartLister.Base },
    { k: 12, t: <T k="Catalog.Name" />, u: Links.Catalog },
    { k: 13, t: <T k="ListNow.ManualListing.Name.Short" />, u: Links.ManualPublish },
    { k: 14, t: <T k="BulkListing.Name.Short" />, u: Links.BulkPublish },
    { k: 15, t: <T k="WeListForYou.Name" />, u: Links.ListingService, pathHttp: true },
  ];
  if (hideSmartListing)
    publishProducts.shift();//remove first element

  let settingsListArray: menuI[] = [
    { k: 16, t: <T k="Menu.AutoOrdering" />, u: Links.Configuration.AutoOrdering.Main },
    { k: 17, t: <T k="Menu.Channel" />, u: Links.Configuration.Channel.Main },
    { k: 18, t: <T k="Menu.SourcesTable" />, u: Links.Configuration.Sources.Main },
    { k: 19, t: <T k="Menu.PricingRules" />, u: Links.Configuration.PricingRules },
    { k: 20, t: <T k="Menu.BrowserExtensions" />, u: Links.BrowserExtension },
    { k: 21, t: <T k="Menu.Account" />, u: Links.Configuration.Account.BillingAddress },
    { k: 22, t: <T k="Menu.Subscriptions" />, u: Links.Subscriptions },
    { k: 23, t: <T k="Menu.VaProfiles" />, u: Links.Configuration.VaProfiles },
    { k: 24, t: <T k="Menu.Templates" />, u: Links.Configuration.Templates.Main }
  ];
  if (affiliates) {
    settingsListArray = settingsListArray.filter(x => x.k == 21);
  }

  const helpListArray: menuI[] = [
    { k: 25, t: <T k="Menu.Start" />, u: Links.GetStarted },
    { k: 26, t: <T k="Menu.FAQ" />, u: Links.Faq, pathHttp: true }
  ];
  if (chatEnabled && chatActions?.maximize) {
    helpListArray.push(
      { k: 27, t: <T k="Menu.Chat" />, u: '#', onClick: () => { chatActions?.maximize?.(); } }
    );
  }

  const GetItem = (
    d: menuI,
    className?: string
  ): MenuItem => {
    return {
      key: d.k.toString(),
      className: className ?? 'menu-item',
      label: d.u != undefined ? <A to={d.u}>{d.t}</A> : d.t,
      style: {},
      icon: d.i,
      children: d.c?.filter(x => !!x).map(item => {
        return GetItem(
          {
            k: item.k,
            t: <MenuListItem key={item.k.toString()} route={item.u ?? ''} listName={item.t} pathHttp={item.pathHttp ?? false} onClick={item.onClick} />
          }, ''
        );
      }),
      onClick: d.onClick,
      type: undefined
    } as MenuItem;
  };

  const urls: {
    Dashboard: menuI,
    Products: menuI,
    Orders: menuI,
    SmartLister: menuI,
    Catalog?: menuI,
    ListNow: menuI,
    Settings: menuI,
    Services: menuI,
    Help: menuI,
    Logout: menuI
  } = {
    Dashboard: {
      k: 1,
      u: Links.Dashboard,
      t: <T k="Menu.Dashboard" />,
      i: <DashBoardIcon />
    },
    Products: {
      k: 2,
      u: Links.Products.Active,
      t: <T k="Menu.Listings" />,
      i: <ListingsIcon />
    },
    Orders: {
      k: 3,
      u: Links.Orders,
      t: <T k="Menu.Orders" />,
      i: <OrdersIcon />
    },
    SmartLister: {
      k: 4,
      u: Links.SmartLister.Base,
      t: <T k="SmartLister.Name" />,
      i: <SmartListerIcon />
    },
    Catalog: {
      k: 5,
      u: Links.Catalog,
      t: <T k="Catalog.Name" />,
      i: <CatalogIcon />
    },
    ListNow: {
      k: 6,
      u: undefined,
      t: <T k="Menu.ListNow" />,
      i: <>
        {quota?.used != undefined && quota?.used < 1 && (
          <div className="alert-point-container">
            <div className="alert-point">
              <p></p>
            </div>
          </div>
        )}{' '}
        <ListNowIcon />
      </>,
      c: publishProducts
    },
    Settings: {
      k: 7 ,
      u: undefined,
      t: <T k="Menu.Settings" />,
      i: <SettingsIcon />,
      c: settingsListArray
    },
    Services: {
      k: 8,
      u: Links.Services.Main,
      t: <T k="Menu.Services" />,
      i: <ServiceIcon />
    },
    Help: {
      k: 9,
      t: <T k="Menu.Help" />,
      i: <HelpIcon />,
      c: helpListArray
    },
    Logout: {
      k: 10,
      t: <T k="Menu.Logout" />,
      i: <LogoutIcon />,
      onClick: HandleLogout
    }
  };

  if (selectedChannel?.channelId == ePlatform.Amazon) {
    urls.Catalog = undefined;
    urls.ListNow = {
      k: 6,
      u: Links.Products.Missing,
      t: <T k="Menu.ListNow" />,
      i: <>
        {quota?.used != undefined && quota?.used < 1 && (
          <div className="alert-point-container">
            <div className="alert-point">
              <p></p>
            </div>
          </div>
        )}{' '}
        <ListNowIcon />
      </>
    };
  }

  const menusDs: menuI[] = [
    urls.Dashboard,
    affiliates ? null : urls.Products,
    affiliates ? null : urls.Orders,
    affiliates || hideSmartListing ? null : urls.SmartLister,
    affiliates ? null : urls.Catalog,
    affiliates ? null : urls.ListNow,
    urls.Settings,
    affiliates ? null : urls.Services,
    urls.Help,
    urls.Logout
  ].filter(x => !!x) as menuI[];

  const menuItems: (MenuItem | null)[] = menusDs.map(x => GetItem(x));

  const selectedIds = (() => {
    for (const r of menusDs) {
      if (r.c) {
        for (const s of r.c) {
          if (location.pathname.startsWith(s.u ?? '')) {
            return {
              k: [s.k.toString()],
              o: [r.k.toString()]
            };
          }
        }
      } else {
        if (location.pathname.startsWith(r.u ?? '')) {
          return {
            k: [r.k.toString()]
          };
        }
      }
    }

    return {
      k: ['1']
    };
  })();

  const [openKeys, setOpenKeys] = useState<string[]>(selectedIds.o ?? []);

  const OnOpenChange = (openKeysValue: string[]) => {
    const latestOpenKey = openKeysValue.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys?.indexOf(latestOpenKey ?? '') === -1) {
      setOpenKeys(openKeysValue);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const SiderMenu = () => (
    <div className="side-menu-container">
      {
        <div className="sidebar-overhead">
          <div className="sidebar-control-btns">
            {staticValue || mobileScreenSize.matches ? (
              <LeftOutlined onClick={mobileScreenSize.matches ? closeMobileSider : togglestatic} />
            ) : (
              !mobileScreenSize.matches && <PushpinOutlined onClick={togglestatic} />
            )}
          </div>
        </div>
      }
      <Menu
        className="menu-container"
        mode="inline"
        defaultSelectedKeys={selectedIds.k}
        openKeys={openKeys}
        onOpenChange={OnOpenChange}
        items={menuItems.filter(x => !!x)}
      />
    </div>
  );

  const LargeScreenSider = () => (
    <Layout.Sider className={className} trigger={null} collapsible collapsed={collapsed} width="293px" collapsedWidth="80px">
      {SiderMenu()}
    </Layout.Sider>
  );

  const SmallScreenSider = () => {

    const ApplyClosing = () => {
      const x = document.getElementById('mobileSider');
      const anchors = x?.getElementsByTagName('a') as HTMLCollectionOf<HTMLElement>;
      for (const l of anchors) {
        l.addEventListener('click', closeMobileSider);
      }
    };

    return <MobileSiderDrawer visible={mobileSiderVisible} placement="left" closable={true} onClose={closeMobileSider} key="left">
      <div className="sider" id="mobileSider" onTouchStart={ApplyClosing}>
        {SiderMenu()}
      </div>
    </MobileSiderDrawer>;
  };

  return (
    <div onMouseEnter={HandleMouseEnter} onMouseLeave={HandleMouseLeave}>
      {mobileScreenSize.matches ? SmallScreenSider() : LargeScreenSider()}
    </div>
  );
};
