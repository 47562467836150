import { T } from '../../../utils/transShim';
import { Input, Form, Spin } from 'antd';
import { eShop } from './eShop';
import { useAppDispatch, useAppSelector } from 'src/custom-hooks/reduxCustomHooks';
import { useEffect, useState } from 'react';
import { getChannels } from 'src/redux/channels/channelsThunk';
import { PrimaryBtn } from '../../../small-components/ActionBtns';

import { A } from '../../../small-components/A';

interface props {
  shop: number;
  onContinue: (username: string) => void;
}

export const UserName = (props: props) => {
  const { shop, onContinue } = props;

  const dispatch = useAppDispatch();
  const { getLinkLoading, newChannelLoading } = useAppSelector((state) => state.newChannel);
  const ebayShopIdentifier = 'My_Super_Shop';
  const amazonShopIdentifier = 'MySuperShop';
  const facebookUrlIdentifier = 'https://www.facebook.com/yourname';
  const shopifyShopUrl = 'https://myshop.myshopify.com';
  const woocommerceShopUrl = 'https://yourstore.com';

  useEffect(() => {
    dispatch(getChannels());
  }, [getChannels]);

  const [username, SetUsername] = useState<string>('');
  const HandleContinue = () => onContinue(username);

  let shopUsername = <T k="NewChannel.Username.Username" />;
  let extra = <></>;
  switch (shop) {
    case eShop.Amazon:
      shopUsername = <T k="NewChannel.Username.ShopName" />;
      break;
    case eShop.Shopify:
    case eShop.WooComerce:
      shopUsername = <T k="NewChannel.Username.URL" />;
      break;
    case eShop.Facebook:
      shopUsername = <T k="NewChannel.Username.URL" />;
      extra = (
        <p>
          <br />
          <T k="NewChannel.Username.Facebook.ExtraL1"/>
          <br />
          <A to="https://www.facebook.com/me" target="_blank" rel="noreferrer">
            https://www.facebook.com/me
          </A>
          <br />
          <T k="NewChannel.Username.Facebook.ExtraL2" />
        </p>
      );
      break;
  }

  let placeHolder = ebayShopIdentifier;
  switch (shop) {
    case eShop.Shopify:
      placeHolder = shopifyShopUrl;
      break;
    case eShop.Amazon:
      placeHolder = amazonShopIdentifier;
      break;
    case eShop.Facebook:
      placeHolder = facebookUrlIdentifier;
      break;
    case eShop.WooComerce:
      placeHolder = woocommerceShopUrl;
      break;
  }

  const forbiddenNames = new Set([
    'profile.php',
    'marketplace',
    'friends',
    'watch',
    'gaming',
    'groups',
    'memories',
    'saved',
    'pages',
    'reel',
    'events',
    'ads',
    'adsmanager',
    'emotional_health',
    'crisisresponse',
    'fundraisers',
    'facebook_pay',
    'es',
    'messages',
    'coronavirus_info',
    'climatescienceinfo',
    'en'
  ]);
  const ChangeUsername = (value: string) => {
    let val = value;
    switch (shop) {
      case eShop.Facebook: {
        const ids = val.indexOf('facebook.com');
        if (ids >= 0 && val.indexOf('/') > 0) {
          if (val.indexOf('profile.php?id=') > 0) {
            val = new URLSearchParams(val.slice(val.indexOf('?'))).get('id') ?? '';
          } else {
            val = val.slice(ids)?.split('/')[1].split('?')[0];
          }
        }
        if (forbiddenNames.has(val.toLowerCase())) val = '';
        break;
      }
    }
    SetUsername(val);
  };

  return (
    <div className="container-info">
      <h2 className="title">
        <T k="NewChannel.Username.Title" values={{ n: eShop[shop] }} />
      </h2>
      <p className="ensure-warning">
        <T k="NewChannel.Username.Warning" values={{ n: eShop[shop], u: shopUsername }} />
        {extra}
      </p>
      <Form className="username-form" layout="horizontal" autoComplete="off">
        <Form.Item
          name="shopName"
          initialValue={username}
        >
          <Input
            className="input-field"
            name="username"
            placeholder={placeHolder}
            onChange={(e) => {
              ChangeUsername(e.target.value);
            }}
          />
        </Form.Item>
      </Form>
      {(getLinkLoading || newChannelLoading) && <Spin />}
      <PrimaryBtn onClick={HandleContinue} disabled={(username?.length ?? 0) == 0}><T k="NewChannel.Username.Continue"/></PrimaryBtn>
    </div>
  );
};
