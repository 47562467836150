import { Input } from 'antd';
import React from 'react';
import { AutoOrderFieldSize } from '../../../data/autoorder-fields';

export type FieldTextProp = {
  label: string | React.ReactNode;
  value: string;
  placeholder?: string;
  size?: AutoOrderFieldSize;
  onChange: (newValue: string) => void;
  type?: string;
  maxLength?: number;
}
const FieldText = (props: FieldTextProp) => {
  const { label, value, placeholder, size, onChange, type, maxLength } = props;

  let col: string;
  switch (size) {
    default:
    case AutoOrderFieldSize.MEDIUM:
      col = 'width-med';
      break;
    case AutoOrderFieldSize.LONG:
      col = 'width-long';
      break;
    case AutoOrderFieldSize.SHORT:
      col = 'width-short';
      break;
  }

  return (
    <div className={'acc-input-parent ' + col}>
      <label className='account-label-style' htmlFor=''>
        {label}
      </label>
      <Input
        className='blue-input input-setting-ao'
        type={type ?? 'text'}
        placeholder={placeholder}
        value={value ?? ''}
        onChange={(r) => {
          if (maxLength) {
            return onChange(r.currentTarget.value.substring(0, maxLength));
          } else {
            return onChange(r.currentTarget.value);
          }
        }}
      />
    </div>
  );
};
export default FieldText;