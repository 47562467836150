import { Button, Drawer, Input, InputNumber, Radio, RadioChangeEvent } from 'antd';
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { Links } from '../../links';
import { CatalogProduct } from '../../redux/catalog/catalogSlice';
import { GetCatalogProducts } from '../../redux/catalog/catalogThunk';
import { PrimaryBtn, DeleteBtn, SuccessBtn } from '../../small-components/ActionBtns';
import { CountrySelector } from '../../small-components/CountrySelector';
import { Selector, SelectorValue } from '../../small-components/form/selector';
import { HelpTooltip } from '../../small-components/help-tooltip';
import { PopupModal } from '../../small-components/modals/popup-modal';
import { T } from '../../utils/transShim';
import { NumberOrNull } from '../../utils/utils';
import { SmartListerIcon } from '../common/Icons';
import { CatalogSource } from '../sources/catalog-source';

type DrawerPlacement = 'right' | 'top' | 'left' | 'bottom';
interface Props {
  visible?: boolean;
  closable?: boolean;
  placement?: DrawerPlacement;
  onClose?: () => void;
  title?: string;
  children?: JSX.Element | JSX.Element[];
  extra?: ReactNode;
  width?: number | string;
  className?: string;
  setSearchTxt?: Dispatch<SetStateAction<string | null>>;
  setAllProducts?: React.Dispatch<React.SetStateAction<CatalogProduct[]>>;
  setProducts?: React.Dispatch<React.SetStateAction<CatalogProduct[]>>;
}

export const CatalogFilters = (props: Props) => {
  const history = useHistory();

  const orders = [
    {
      label: <T k="Catalog.Filter.OrderByDefault" />,
      value: '0'
    },
    {
      label: <T k="Catalog.Filter.SourcePriceAsc" />,
      value: '1'
    },
    {
      label: <T k="Catalog.Filter.SourcePriceDesc" />,
      value: '2'
    },
    {
      label: <T k="Catalog.Filter.ProfitAsc" />,
      value: '3'
    },
    {
      label: <T k="Catalog.Filter.ProfitDesc" />,
      value: '4'
    },
    {
      label: <T k="Catalog.Filter.TitleAsc" />,
      value: '5'
    },
    {
      label: <T k="Catalog.Filter.TitleDesc" />,
      value: '6'
    }
  ];
  const { visible, onClose, setProducts } = props;
  const { catalogProducts } = useAppSelector((state) => state.catalogProducts);
  const { sources } = useAppSelector((state) => state.sources);
  const [sessionId] = useState<number>(0);
  const dispatch = useAppDispatch();

  const {
    param_priceFrom,
    param_priceTo,
    param_profitFrom,
    param_profitTo,
    param_titleContains,
    param_warehouse,
    param_order,
    param_options,
    param_sourceIds
  } = (() => {
    const searchParams = new URLSearchParams(window.location.search);

    const priceFrom = parseFloat(searchParams.get('f') ?? '');
    const priceTo = parseFloat(searchParams.get('t') ?? '');
    const profitFrom = parseFloat(searchParams.get('p') ?? '');
    const profitTo = parseFloat(searchParams.get('r') ?? '');
    const titleContains = searchParams.get('c');
    const warehouse = searchParams.get('w');
    const order = parseInt(searchParams.get('o') ?? '');
    const options = parseInt(searchParams.get('i') ?? '');
    const sourceIds = searchParams.get('s') ?? '';

    return {
      param_priceFrom: isNaN(priceFrom) ? undefined : priceFrom,
      param_priceTo: isNaN(priceTo) ? undefined : priceTo,
      param_profitFrom: isNaN(profitFrom) ? undefined : profitFrom,
      param_profitTo: isNaN(profitTo) ? undefined : profitTo,
      param_titleContains: titleContains ?? undefined,
      param_warehouse: warehouse ?? undefined,
      param_order: isNaN(order) ? 0 : order,
      param_options: isNaN(options) ? 1 : options,
      param_sourceIds:
        sourceIds.length > 0
          ? ((sourceIds
            .split(',')
            .map((x) => NumberOrNull(x))
            .filter((x) => x != null && !isNaN(x) && x > 0) ?? []) as number[])
          : []
    };
  })();

  useEffect(() => {
    dispatch(
      GetCatalogProducts({
        sessionId,
        titleContains: param_titleContains,
        priceFrom: param_priceFrom,
        priceTo: param_priceTo,
        profitFrom: param_profitFrom,
        profitTo: param_profitTo,
        options: param_options,
        order: param_order,
        suppliersCount: param_sourceIds,
        warehouse: param_warehouse
      })
    );
  }, [
    sessionId,
    param_priceFrom,
    param_priceTo,
    param_profitFrom,
    param_profitTo,
    param_titleContains,
    param_warehouse,
    param_order,
    param_options,
    param_sourceIds?.join(',')
  ]);

  const [sourceModalOpen, setSourceModalOpen] = useState<boolean>(false);

  const [priceFrom, SetPriceFrom] = useState<number | undefined>(param_priceFrom);
  const [priceTo, SetPriceTo] = useState<number | undefined>(param_priceTo);
  const [profitFrom, SetProfitFrom] = useState<number | undefined>(param_profitFrom);
  const [profitTo, SetProfitTo] = useState<number | undefined>(param_profitTo);
  const [titleContains, SetTitleContains] = useState<string | undefined>(param_titleContains);
  const [warehouse, SetWarehouse] = useState<string | undefined>(param_warehouse);
  const [order, SetOrder] = useState<number>(param_order);
  const [options, SetOptions] = useState<number>(param_options);
  const [sourcesIds, SetSourcesIds] = useState<number[]>(param_sourceIds);

  const GenerateParams = () => {
    const params = new URLSearchParams('');
    if (priceFrom != null && priceFrom > 0) params.set('f', priceFrom.toString());
    if (priceTo != null && priceTo > 0) params.set('t', priceTo.toString());
    if (profitFrom != null && profitFrom > 0) params.set('p', profitFrom.toString());
    if (profitTo != null && profitTo > 0) params.set('r', profitTo.toString());
    if (titleContains != null && titleContains.length > 0) params.set('c', titleContains.toString());
    if (warehouse != null && warehouse.length > 0) params.set('w', warehouse.toString());
    if (order > 0) params.set('o', order.toString());
    if (options != 1) params.set('i', options.toString());
    if (sourcesIds.length > 0) params.set('s', sourcesIds.join(','));

    return params;
  };

  const HandlePriceFromChange = (e: number | null) => SetPriceFrom(e ?? undefined);
  const HandlePriceToChange = (e: number | null) => SetPriceTo(e ?? undefined);
  const HandleProfitFromChange = (e: number | null) => SetProfitFrom(e ?? undefined);
  const HandleProfitToChange = (e: number | null) => SetProfitTo(e ?? undefined);
  const HandleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => SetTitleContains(e.target.value);
  const HandleOptionsChange = (e: RadioChangeEvent) => SetOptions(NumberOrNull(e.target.value) ?? 0);
  const HandleOrdersChange = (value: SelectorValue) => SetOrder(Number(value));
  const HandleWarehouseChange = (value: SelectorValue) => {
    const v = value?.toString();
    if (!v || v == '') SetWarehouse(undefined);
    else SetWarehouse(v);
  };

  useEffect(() => {
    setProducts?.(catalogProducts ?? []);
  }, [catalogProducts]);

  const HandleReset = () => {
    SetSourcesIds([]);
    SetPriceFrom(undefined);
    SetPriceTo(undefined);
    SetProfitFrom(undefined);
    SetProfitTo(undefined);
    SetTitleContains(undefined);
    SetOptions(0);
    SetOrder(0);
    SetWarehouse(undefined);
  };

  const getSourcesData = (ids: number[]) => SetSourcesIds(ids);

  const HandleFilterSubmit = () => {
    history.push({
      search: GenerateParams().toString()
    });
  };
  const HandleOpenSourceModal = () => setSourceModalOpen(true);
  const HandleCloseSourceModal = () => setSourceModalOpen(false);

  const HandleSendToAILink = () => {
    history.push({
      pathname: Links.SmartLister.OrderCreator.Advanced,
      search: GenerateParams().toString()
    });
  };

  return (
    <>
      <Drawer
        className="advanced-search"
        title={<T k="Catalog.Filter.SearchCriteria" />}
        placement="right"
        onClose={onClose}
        open={visible}
      >
        <div className="advanced-form-container">
          <h5>
            <strong>
              <T k="Catalog.Filter.ChooseYourSuppliers" />
            </strong>
          </h5>
          <Button className="supplier-one" onClick={HandleOpenSourceModal}>
            {sourcesIds?.length > 0 && <T k="Catalog.Filter.SuppliersQ" values={{ q: sourcesIds?.length }} />}
            {!sourcesIds?.length && <T k="Catalog.Filter.Sources.NoP" />}
          </Button>
          <div className="search-inputs">
            <div className="form-item">
              <label>
                <T k="Catalog.Filter.MinSourcePrice" />
              </label>
              <InputNumber className="blue-input" name="priceFrom" value={priceFrom} onChange={HandlePriceFromChange} />
            </div>

            <div className="form-item">
              <label>
                <T k="Catalog.Filter.MinProfit" />
              </label>
              <InputNumber className="blue-input" name="profitFrom" value={profitFrom} onChange={HandleProfitFromChange} />
            </div>

            <div className="form-item">
              <label>
                <T k="Catalog.Filter.MaxSourcePrice" />
              </label>
              <InputNumber className="blue-input" name="priceTo" value={priceTo} onChange={HandlePriceToChange} />
            </div>

            <div className="form-item">
              <label>
                <T k="Catalog.Filter.MaxProfit" />
              </label>
              <InputNumber className="blue-input" name="profitTo" value={profitTo} onChange={HandleProfitToChange} />
            </div>
          </div>

          <div className="prime-options">
            <p className="amazon-prime">
              <strong>
                <T k="Catalog.Filter.AmazonPrime" />
                <HelpTooltip>
                  <T k="Catalog.Filter.AmazonPrime.Help" />
                </HelpTooltip>
              </strong>
            </p>
            <div className="checkboxes">
              <Radio.Group onChange={HandleOptionsChange} value={options} name="amazonPrime">
                <Radio value={1}>
                  <T k="Catalog.Filter.OnlyPrime" />
                </Radio>
                <Radio value={0}>
                  <T k="Catalog.Filter.NotOnlyPrime" />
                </Radio>
              </Radio.Group>
            </div>
          </div>

          <div className="form-item">
            <label>
              <T k="Catalog.Filter.Title" />
            </label>
            <Input className="blue-input" name="titleContains" value={titleContains} onChange={HandleTitleChange} />
          </div>
          <div className="form-item" style={{ display: 'none' }}>
            <label>
              <T k="Catalog.Filter.Warehouse" />
            </label>
            <CountrySelector
              size="large"
              onChange={HandleWarehouseChange}
              defaultValue=""
              dropdownRender={(menu: ReactNode) => <div className="dropdown-content mb-5">{menu}</div>}
              emptyValue="All"
            />
          </div>
          <div className="form-item">
            <label>
              <T k="Catalog.Filter.OrderBy" />
            </label>
            <Selector
              size="large"
              placeholder="Default"
              onChange={HandleOrdersChange}
              dropdownRender={(menu: ReactNode) => <div className="dropdown-content mb-5">{menu}</div>}
            >
              {orders.map((x) => {
                return { value: x.value, label: x.label };
              })}
            </Selector>
          </div>

          <div className="form-buttons">
            <DeleteBtn onClick={HandleReset} htmlType="button">
              <T k="Catalog.Filter.ClearFilters" />
            </DeleteBtn>
            <SuccessBtn htmlType="submit" onClick={HandleFilterSubmit}>
              <T k="Catalog.Filter.ApplyFilters" />
            </SuccessBtn>
          </div>
          <div className="send-to-ai">
            <PrimaryBtn onClick={HandleSendToAILink}>
              <SmartListerIcon />
              <T k="Catalog.Filter.SendToAI" />
            </PrimaryBtn>
          </div>
        </div>
      </Drawer>
      <PopupModal
        open={sourceModalOpen}
        onClose={HandleCloseSourceModal}
        maxWidth={800}
        title={
          <div className="modal-title">
            <h1 className="title">
              <T k="Catalog.Filter.ChooseYourSuppliers" />
            </h1>
            <p className="source">
              <T k="Catalog.Filter.ChooseYourSuppliers.Description" />
            </p>
          </div>
        }
      >
        <CatalogSource onClose={HandleCloseSourceModal} onChangeSelectedSources={getSourcesData} sources={sources} />
      </PopupModal>
    </>
  );
};
