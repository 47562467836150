import { AdvancedFilterSelectorValueType, AdvancedFilterValueType } from '../../types/columns';
import { MultipleSelector } from '../../../form/multiple-selector';
import { SelectorValue } from '../../../form/selector';
import { T } from '../../../../utils/transShim';

type Props = {
  onChange: (value: AdvancedFilterValueType) => void;
  values: AdvancedFilterSelectorValueType[];
}

export const MultipleSelectorField = (props: Props) => {
  const { onChange } = props;

  const HandleChange = (value: SelectorValue[]) => {
    onChange(value);
  };

  return <MultipleSelector
    onChange={HandleChange}
  >
    {props.values.map(x => {
      const [label, value] = x;
      return {
        value: value ?? 0,
        label: <T k={label ?? ''} />
      };
    })}
  </MultipleSelector>;
};