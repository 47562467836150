import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { eSmartListerFilterStatus } from '../../redux/smart-lister/data/e-smart-lister-filter-status';
import { SmartListerFiltersGet } from '../../redux/smart-lister/smart-thunk';
import '../../sass/smart-lister.scss';
import { ReactUtils } from '../../utils/react-utils';
import { PrimaryBtn } from '../../small-components/ActionBtns';
import { T } from '../../utils/transShim';

import { Links } from '../../links';
import { getSources } from '../../redux/sources/sourcesThunk';
import { SmartListerFiltersTable } from './components/smart-lister-filters-table';
import { StatusBar } from 'src/small-components/StatusBar';
import { A } from '../../small-components/A';

export const SmartLister = () => {
  const dispatch = useAppDispatch();
  const { filters /*, history*/ } = useAppSelector((state) => state.smartLister);
  const { sources, loading: sourcesLoading } = useAppSelector((state) => state.sources);
  const channel = ReactUtils.GetSelectedChannel();
  useEffect(() => {
    dispatch(SmartListerFiltersGet({ status: [eSmartListerFilterStatus.Active, eSmartListerFilterStatus.Paused] }));
  }, [channel?.id]);

  console.log(filters?.data?.length, 'filters');

  useEffect(() => {
    if (!!sources && sources.length > 0 && sources[0].site == channel?.isoCountry?.toString()) return;
    if (sourcesLoading) return;
    dispatch(getSources());
  }, [channel?.id]);

  return (
    <div className="smart-lister-layout">
      {filters?.data?.length == 0 ? (
        <StatusBar className="smart-lister">
          <h3>
            <T k="SmartLister.NoOrder.Title" />
          </h3>
          <p>
            <T k="SmartLister.NoOrder.Description" />
          </p>
          <div className="top-buttons">
            <A to={Links.SmartLister.OrderCreator.Simple}>
              <PrimaryBtn>
                <T k="SmartLister.NewFilter" />
              </PrimaryBtn>
            </A>
          </div>{' '}
        </StatusBar>
      ) : (
        <div>
          <SmartListerFiltersTable
            loading={filters?.loading ?? false}
            filters={filters?.data}
            sources={sources}
            updating={filters?.updating}
          />
          <div className="top-buttons">
            <A to={Links.SmartLister.OrderCreator.Simple}>
              <PrimaryBtn>
                <T k="SmartLister.NewFilter" />
              </PrimaryBtn>
            </A>
          </div>
        </div>
      )}
    </div>
  );
};
