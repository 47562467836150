import { UserData } from '../redux/user/userSlice';

export const UserUtil = {
  TimeSinceCreation: (user: UserData | undefined | null) => user?.createdOn != null ? (new Date().getTime() - new Date(user?.createdOn).getTime()) / (1000 * 3600 * 24) : null,
  HideSmartLister: (user: UserData | undefined | null) => {
    if (user?.createdOn == null)
      return false;
    if (user.createdOn.toString() < '2024-06-02')
      return false;
    return (UserUtil.TimeSinceCreation(user) ?? 40) < 30;
  }
};