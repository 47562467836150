import ebay from '../assets/channel/user-shops/ebay.svg';
import ebayNoApi from '../assets/channel/user-shops/ebay-no-api.svg';
import amazon from '../assets/channel/user-shops/amazon.svg';
import shopify from '../assets/channel/user-shops/shopify.svg';
import facebook from '../assets/channel/user-shops/facebook.svg';
import woocommerce from '../assets/channel/user-shops/wc-icon.svg';
import { ePlatform } from '../data/platforms';

type channelIdType = number | undefined | string | boolean | Date;

type Props = {
  channelId: channelIdType;
  title?: string;
};

export const ShopLogo = (props: Props) => {
  const { channelId, title } = props;
  switch (channelId) {
    case ePlatform.eBay:
      return <img src={ebay} className="shop-logo" title={title ? title : 'Ebay'} />;
    case ePlatform.Shopify:
      return <img src={shopify} className="shop-logo" title={title ? title : 'Shopify'} />;
    case ePlatform.eBayNoApi:
      return <img src={ebayNoApi} className="shop-logo" title={title ? title : 'Ebay No API'} />;
    case ePlatform.Amazon:
      return <img src={amazon} className="shop-logo" title={title ? title : 'Amazon'} />;
    case ePlatform.Facebook:
      return <img src={facebook} className="shop-logo" title={title ? title : 'Facebook'} />;
    case ePlatform.WooCommerce:
      return <img src={woocommerce} className="shop-logo" title={title ? title : 'Woocommerce'} />;
    default:
      return <></>;
  }
};
