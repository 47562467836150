import { Button, Input } from 'antd';
import React, { useState } from 'react';

import { Channel } from '../../../../../redux/channels/channelsSlice';
import { A } from '../../../../../small-components/A';
import { ReactUtils } from '../../../../../utils/react-utils';
import { T } from '../../../../../utils/transShim';
interface Props {
  updatedOn: string | Date;
  createdOn: string | Date;
  createdByName?: string;
  listingId: number;
  asin?: string;
  channelItem: string;
  channel?: Channel;
  valueSourceUrl: string;
  originalSourceUrl: string;

  onChangeSourceUrl: (v:string) => void;
}

export const ListingDetails = (props: Props) => {
  const { updatedOn, createdOn, valueSourceUrl, channel, createdByName, listingId, channelItem, asin, onChangeSourceUrl, originalSourceUrl } = props;
  const [editingSource, SetEditingSource] = useState<boolean>(false);

  if (!channel)
    return <></>;

  const myUrl = ReactUtils.GetItemUrl(channel.channelId, channel.isoCountry, channelItem, channel.channelIdentifier, asin);

  const HandleEditSource = () => SetEditingSource(true);
  const HandleChangeSourceUrl = (e: React.ChangeEvent<HTMLInputElement>) => onChangeSourceUrl(e.currentTarget.value);
  const HandleCancelEditSource = () => {
    SetEditingSource(false);
    if (originalSourceUrl != valueSourceUrl)
      onChangeSourceUrl(originalSourceUrl);
  };

  const PlainSourceUrlRender = () => <>
    <A to={ReactUtils.GetOutUrl(valueSourceUrl, channel.isoCountry, listingId) ?? ''} target="_blank" rel="noreferrer"><T k='Listings.Edit.OpenInNewTab' /></A>
    <span style={{ fontSize: 12, marginLeft: 8 }}><A to="#" onClick={HandleEditSource}>{'('}<T k="Listings.Edit.Edit" />{')'}</A></span>
  </>;
  const EditSourceUrlRender = () => <>
    <Input.Group compact>
      <Input style={{ width: 'calc(100% - 200px)' }} value={valueSourceUrl} onChange={HandleChangeSourceUrl} />
      <Button type="primary" onClick={HandleCancelEditSource}>Cancel</Button>
    </Input.Group>
  </>;

  return (
    <div className="listing-details">
      <ul>
        <li>
          <p>Create by</p>
          <p>{createdByName}</p>
        </li>
        <li>
          <p>Created on</p>
          <p>{ReactUtils.GetFormattedDateTime(createdOn)}</p>
        </li>
        <li>
          <p>Monitored on</p>
          <p>{ReactUtils.GetFormattedDateTime(updatedOn)}</p>
        </li>
        <li>
          <p>Source URL</p>
          {!editingSource && PlainSourceUrlRender()}
          {editingSource && EditSourceUrlRender()}
        </li>
        <li>
          <p>My URL</p>
          <A to={ReactUtils.GetOutUrl(myUrl, channel.isoCountry, listingId) ?? '#'} target="_blank" rel="noreferrer">{myUrl}</A>
        </li>
      </ul>
    </div>
  );
};