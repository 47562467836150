import { createSlice } from '@reduxjs/toolkit';
import { eCurrency } from '../../types/eCurrency';
import { ePaymentError } from '../payment/paymentSlice';
import { getAffiliateDashboardGeneral, getAffiliateDashboardMonthly, getAffiliatesStats, GetAffiliateWithdrawals, RequestWithdrawal } from './affiliatesStatsThunk';

export interface AffiliatesStats {
  quantity: number;
  date: string;
}

export type AffiliatesStatsSliceState = {
  affiliatesStats: AffiliatesStats[];
  loading: boolean;
  error: string;
}

const initialStatsState: AffiliatesStatsSliceState = {
  affiliatesStats: [],
  loading: false,
  error: ''
};

export const affiliatesStatsSlice = createSlice({
  name: 'dashboard-affiliates-stats',
  initialState: initialStatsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAffiliatesStats.pending, (state) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(getAffiliatesStats.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.affiliatesStats = payload;
    });
    builder.addCase(getAffiliatesStats.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = String(payload);
    });
  }
});














export interface AffiliatePerCurrency {
  currency: eCurrency;
  totalCommission: number;
  catalogTokensCommission: number;
  noApiServerCommission: number;
  totalSubscriptionCommission: number;
  weListForYouCommission: number;
  history: AffiliateHistory[];
}

export interface AffiliateHistory {
  id: string;
  email: string;
  totalCommission: number;
  subscriptionCommission: number;
  noApiCommission: number;
  tokensCommission: number;
  weListForYouCommission: number;
}

export interface AffiliatesDashboardGeneralStats {
  users: number;
  usersWithChannel: number;
  usersWithProducts: number;
  totalCommision: { currency: eCurrency; amount: number }[];
  percentageComission: number;
  affiliateTag: string;
  totalCommisionWithdrawable: { currency: eCurrency; amount: number }[];
}

export interface AffiliateWithdrawalsIntent {
  currency: eCurrency;
  createdOn: Date;
  amountDue: number;
  success?: boolean;
  errorType?: ePaymentError;
}

export interface AffiliatesStatsSliceStateMonthlyStats {
  users: number;
  data: AffiliatePerCurrency[];
}

export type AffiliatesStatsSliceStateGeneral = {
  data?: AffiliatesDashboardGeneralStats;
  loading?: boolean;
}
export type AffiliatesStatsSliceStateMonthly = {
  data?: { [year: number]: { [month: number]: AffiliatesStatsSliceStateMonthlyStats } };
  loading?: boolean;
}
export type AffiliateWithdrawals = {
  loading?: boolean;
  withdrawals?: AffiliateWithdrawalsIntent[];
  queues?: eCurrency[];
}

export type AffiliatesDashboardSliceState = {
  general?: AffiliatesStatsSliceStateGeneral;
  monthly?: AffiliatesStatsSliceStateMonthly;
  withdrawals?: AffiliateWithdrawals;
}

const initialDashboardState: AffiliatesDashboardSliceState = {};

export const affiliatesDashboardSlice = createSlice({
  name: 'dashboard-affiliates-stats',
  initialState: initialDashboardState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAffiliateDashboardGeneral.pending, (state) => {
      if (!state.general)
        state.general = {};
      state.general.loading = true;
    });
    builder.addCase(getAffiliateDashboardGeneral.fulfilled, (state, { payload }) => {
      if (!state.general)
        state.general = {};
      state.general.loading = false;
      state.general.data = payload;
    });
    builder.addCase(getAffiliateDashboardGeneral.rejected, (state) => {
      if (!state.general)
        state.general = {};
      state.general.loading = false;
    });


    builder.addCase(getAffiliateDashboardMonthly.pending, (state) => {
      if (!state.monthly)
        state.monthly = {};
      state.monthly.loading = true;
    });
    builder.addCase(getAffiliateDashboardMonthly.fulfilled, (state, { payload, meta }) => {
      if (!state.monthly)
        state.monthly = {};

      if (!state.monthly.data)
        state.monthly.data = [];

      if (!state.monthly.data[meta.arg.year])
        state.monthly.data[meta.arg.year] = [];

      state.monthly.loading = false;
      state.monthly.data[meta.arg.year][meta.arg.month] = payload;
    });
    builder.addCase(getAffiliateDashboardMonthly.rejected, (state) => {
      if (!state.monthly)
        state.monthly = {};
      state.monthly.loading = false;
    });


    builder.addCase(GetAffiliateWithdrawals.pending, (state) => {
      if (!state.withdrawals)
        state.withdrawals = {};
      state.withdrawals.loading = true;
    });
    builder.addCase(GetAffiliateWithdrawals.fulfilled, (state, { payload }) => {
      if (!state.withdrawals)
        state.withdrawals = {};
      state.withdrawals.loading = false;
      state.withdrawals.withdrawals = payload?.withdrawals;
      state.withdrawals.queues = payload?.inQueue;
    });
    builder.addCase(GetAffiliateWithdrawals.rejected, (state) => {
      if (!state.withdrawals)
        state.withdrawals = {};
      state.withdrawals.loading = false;
    });



    builder.addCase(RequestWithdrawal.pending, (state) => {
      if (!state.withdrawals)
        state.withdrawals = {};
      state.withdrawals.loading = true;
    });
    builder.addCase(RequestWithdrawal.fulfilled, (state, { payload, meta }) => {
      if (!state.withdrawals)
        state.withdrawals = {};
      if (!state.withdrawals.queues)
        state.withdrawals.queues = [];
      state.withdrawals.loading = false;
      if (payload)
        state.withdrawals.queues.push(meta.arg.currency);
    });
    builder.addCase(RequestWithdrawal.rejected, (state) => {
      if (!state.withdrawals)
        state.withdrawals = {};
      state.withdrawals.loading = false;
    });

  }
});







export const { reducer: affiliatesStatsReducer } = affiliatesStatsSlice;
export const { reducer: affiliatesDashboardReducer } = affiliatesDashboardSlice;
