import moment from 'moment';
import { ePlatform } from '../../../data/platforms';
import { OrderData } from '../../../redux/orders/orderSlice';
import { ColumnData, FieldType } from '../../../small-components/tables/types/columns';
import { DetermineStatus } from '../../../utils/determineStatus';
import { AOStatusFilter } from './advanced-filters';
import { RenderChannelItem, RenderDate, RenderImage, RenderProfit, RenderSource, RenderTitle } from './columns-renders';

export enum OrderColumnId {
  Image = 1,
  Reference = 2,
  ChannelItem = 3,
  Source = 4,
  Title = 5,
  Quantity = 6,
  Sold = 7,
  Cost = 8,
  Fees = 9,
  Profit = 10,
  Margin = 11,
  DateOfOrder = 12,
  Status = 13,
}

export type RecordType = {
  channelOAuthIds: [number];
  date: Date;
  imageUrl: string;
  sourceId: number;
  sourceItem: string;
  channelItem: string;
  channelShipping: number;
  channelCurrency: string;
  sourceCurrencyId: null;
  shippingAddressId: number;
  billingAddressId: number;
  channelTax: number;
  channelVAT: number;
  channelPaymentTaxes: number;
  sourceVAT: null;
  sourceShipping: null;
  sourcePath: string;
  storeStatus: number;
  hgrTrackingNumber: null;
  buyReference: string;
  cancelRequested: boolean;
  orderLineId: number;
  id: number;
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  phone: number;
  city: string;
  zip: string;
  province: string;
  country: string;
  countryCode: string;
  provinceCode: string;
  //Added for advance search
  reference: string;
  quantity: number;
  title: string;
  sold: string;
  cost: string;
  fees: number;
  profit?: number | string;//Calculated in local
  margin?: number | string;
  status: number | string;
  sourcePrice: number;
  channelPrice: number;
  key: number; //Calculated in local
  //Calculated in client:
  sourceAOConfigured: boolean;
  sourceAOEnabled: boolean;
  sourceUrl: string;
  sourceName: string;
};

type FieldValue = unknown;
//type RecordType = Record<string, FieldValue>;

export interface OrderColumnData extends ColumnData<RecordType> {
  id: OrderColumnId
}

const MultiTermFilter = (fieldValue: FieldValue, searchTerm: string) => {
  const terms = searchTerm.trim().split(' ');
  for (const term of terms) {
    if (term.length == 0)
      continue;
    if ((fieldValue as string)?.toLocaleLowerCase?.().indexOf?.(term) < 0) {
      return false;
    }
  }
  return true;
};

const OrdersColumnsD: OrderColumnData[] = [
  {
    id: OrderColumnId.Image,
    title: 'OrderTable.Image',
    dataIndex: 'imageUrl',
    align: 'center',
    className: 'v-middle',
    smartSearch: { ignore: true },
    advancedSearch: {
      ignore: true
    },
    render: RenderImage
  },
  {
    id: OrderColumnId.Reference,
    title: 'OrderTable.Reference',
    dataIndex: 'reference',
    width: 107
  },
  {
    id: OrderColumnId.ChannelItem,
    title: 'OrderTable.ChannelItem',
    dataIndex: 'channelItem',
    width: 70,
    render: RenderChannelItem
  },
  {
    id: OrderColumnId.Source,
    title: 'OrderTable.Source',
    dataIndex: 'sourceName',
    width: 70,
    render: RenderSource
  },
  {
    id: OrderColumnId.Title,
    title: 'OrderTable.Title',
    dataIndex: 'title',
    smartSearch: {
      customFilter: MultiTermFilter
    },
    render: RenderTitle
  },
  {
    id: OrderColumnId.Quantity,
    title: 'OrderTable.Quantity',
    dataIndex: 'quantity',
    align: 'center',
    advancedSearch: {
      type: FieldType.Number
    },
  },
  {
    id: OrderColumnId.Sold,
    title: 'OrderTable.Sold',
    dataIndex: 'channelPrice',
    align: 'right',
    advancedSearch: {
      type: FieldType.Number
    },
    render: (value: number, record: RecordType) => value * (record as unknown as OrderData).quantity //ChannelPrice in ebay is per product. It's the same for other platforms?
  },
  {
    id: OrderColumnId.Cost,
    title: 'OrderTable.Cost',
    dataIndex: 'sourcePrice',
    align: 'right',
    advancedSearch: {
      type: FieldType.Number
    },
  },
  {
    id: OrderColumnId.Fees,
    title: 'OrderTable.Fees',
    dataIndex: 'fees',
    align: 'right',
    advancedSearch: {
      type: FieldType.Number
    },
  },
  {
    id: OrderColumnId.Profit,
    title: 'OrderTable.Profit',
    dataIndex: 'profit',
    align: 'right',
    render: RenderProfit,
    advancedSearch: {
      type: FieldType.Number
    },
  },
  {
    id: OrderColumnId.Margin,
    title: 'OrderTable.Margin',
    dataIndex: 'margin',
    align: 'right',
    advancedSearch: {
      type: FieldType.Number
    },
  },
  {
    id: OrderColumnId.DateOfOrder,
    title: 'OrderTable.DateOfOrder',
    dataIndex: 'date',
    align: 'right',
    width: 1,
    smartSearch: { ignore: true },
    key: 'date',
    sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    render: RenderDate,
    advancedSearch: {
      type: FieldType.Date
    }
  },
  {
    id: OrderColumnId.Status,
    title: 'OrderTable.Status',
    dataIndex: 'status',
    align: 'center',
    className: 'v-middle',
    render: (status: number, record: RecordType) => DetermineStatus(status, record as unknown as OrderData),
    advancedSearch: {
      type: FieldType.MultipleSelect,
      customFilter:AOStatusFilter,
      values: [
        ['Orders.Status.Error', 1],
        ['Orders.Status.InProgress', 2],
        ['Orders.Status.Purchased', 3],
        ['Orders.Status.Dispatched', 8],
        ['Orders.Status.Pending', 7],
        ['Orders.Status.ManuallyDispatched', 4],
        ['Orders.Status.Paused', 5],
        ['Orders.Status.Cancelled', 6]
      ]
    }
  }
];

export const OrdersColumns = (platform: ePlatform | undefined | null) => {
  switch (platform) {
    default:
      return OrdersColumnsD;
    case ePlatform.eBayNoApi:
      return OrdersColumnsD.filter(x => x.id != OrderColumnId.Fees);
  }
};