import { useHistory, useParams } from 'react-router-dom';
import logo from '../../../assets/logo-blanco.svg';
import '../../../sass/user-register.scss';
import { useEffect, useState } from 'react';
import { T } from '../../../utils/transShim';
import { Links } from '../../../links';
import { A } from '../../../small-components/A';
import { UserRegisterForm } from './user-register-form';
import { UserRegisterPlan } from './user-register-plan';
import { eCurrency } from '../../../types/eCurrency';
import { useAppDispatch, useAppSelector } from '../../../custom-hooks/reduxCustomHooks';
import { getSubscriptions } from '../../../redux/subscriptions/subsThunk';
import { Product, Subscription } from '../../../redux/subscriptions/subsSlice';
import { Spin } from 'antd';
import { BillingPeriod, PaymentPlatform } from '../../subscriptions/models/types';

enum StepType {
  Landing = 0,
  Plan = 1,
  Form = 2,
  Success = 3
}

export const UserRegister = () => {
  const { products, loading } = (() => {
    const { products, loading } = useAppSelector((state) => state.subscriptions);
    return { products: products?.filter(x => x.type == 1), loading};
  })();

  const history = useHistory();
  const dispatch = useAppDispatch();

  const [firstTime, SetFirstTime] = useState<boolean>(true);
  const [currency, SetCurrency] = useState<eCurrency>(eCurrency.EUR);
  const [period, SetPeriod] = useState<BillingPeriod>(BillingPeriod.Yearly);
  const [plan, SetPlan] = useState<Product | undefined>(undefined);

  useEffect(() => {
    if (!products || products.length == 0)
      dispatch(getSubscriptions());
  }, [getSubscriptions]);

  const step = (() => {
    const { step } = useParams<{ step: string }>();
    switch (step?.toLowerCase()) {
      case 'landing': {
        const params = new URLSearchParams(window.location.search);
        localStorage.setItem('referral', params.get('src') ?? '');
        localStorage.setItem('referralDate', new Date().toISOString());
        window.location.href = 'https://www.hustlegotreal.com';
        return StepType.Landing;
      }
      default:
      case 'plan':
        return StepType.Plan;
      case 'user':
        return StepType.Form;
    }
  })();

  const HandleChangePlan = (prd: Product) => {
    SetPlan(prd);
    history.push(Links.Credentials.Register.Form);
  };

  const SelectPrice = (x: Subscription) => x.billingPeriodId == period && x.platformId == PaymentPlatform.Stripe;

  const ContentLoading = () => <div className="register-plans"><Spin /></div>;

  const Content = () => {
    if (step == StepType.Landing || loading || products == null || products.length == 0)
      return ContentLoading();

    return <>
      {step == StepType.Plan &&
        <UserRegisterPlan
          key='0'
          products={products}
          onChangePlan={HandleChangePlan}
          currency={currency}
          onChangeCurrency={SetCurrency}
          period={period}
          onChangePeriod={SetPeriod}
        />
      }
      <UserRegisterForm
        key='1'
        hidden={step == StepType.Plan}
        firstTime={firstTime}
        setFirstTime={SetFirstTime}
        plan={plan ?? products.sort(x => x.prices.find(SelectPrice)?.price ?? 999999)[0]}
        currency={currency}
        period={period}
        loading={loading}
      />
    </>;
  };

  return <>
    <div className="register-form-header">
      <div className="text-section">
        <h2 className="register-form-title">
          <T k="Register.RegisterTitle" />
        </h2>
        <p>
          <T k="Register.AlreadyAccount" />{' '}
          <A to={Links.Credentials.Login}>
            <T k="Register.Login" />
          </A>
        </p>
      </div>
      <div>
        <img src={logo} alt="" className="logo" />
      </div>
    </div>
    {Content()}
  </>;
};
