import { Col, Row } from 'antd';

type Props = {
  component: React.ReactNode,
  name: React.ReactNode,
  description: React.ReactNode,
  disabled?: boolean
}

export const SettingBase = (props: Props) => {
  const { name, description, component, disabled } = props;

  return <Row className={'description-and-controls' + (disabled ? ' disabled' : '')}>
    <Col span={12} className='description-area'>
      <h2 className={disabled ? 'disabled' : ''}>{name}</h2>
      {description}
    </Col>
    {component}
  </Row>;
};