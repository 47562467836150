import { Row } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import React from 'react';
import { Label } from './label';

interface Props extends React.ComponentPropsWithoutRef<typeof Row> {
  label?: React.ReactNode | undefined;
}

export const FormItemLayout = (props: Props) => {
  return <Row {...props} className={'ant-form-item-row ' + (props.className ?? '')}>
    <Label>
      {props.label}
    </Label>
    <FormItem>
      {props.children}
    </FormItem>
  </Row>;
};