import { createSlice } from '@reduxjs/toolkit';
import { SaveAutoOrderingAccountConfiguration, GetAutoOrderingConfiguration, DeleteAutoOrderingAccount, getAutoOrderFees, AutoOrderingConfigurationAccountData, TaskPendingAutoOrder } from './autoOrderingThunk';

export type TaskPendingAutoOrderS = {
  data?: { listingsWithSourcesNotSet: boolean },
  loading: boolean,
  error?: string
}
export type AutoOrderingSliceState = {
  aoAccounts: AutoOrderingConfigurationAccountData[];
  loading: boolean;
  updating: boolean;
  deleting: boolean;
  error: string;
  taskPendingAutoOrder?: TaskPendingAutoOrderS;
}

const autoOrderingIntialState: AutoOrderingSliceState = {
  aoAccounts: [],
  loading: false,
  updating: false,
  deleting: false,
  error: '',
  taskPendingAutoOrder: {loading:false, error:''}
};

export const getAutoOrderingSlice = createSlice({
  name: 'autoOrdering',
  initialState: autoOrderingIntialState,
  reducers: {},
  extraReducers: (builder) => {
    //Get Autoordering configuration
    builder.addCase(GetAutoOrderingConfiguration.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(GetAutoOrderingConfiguration.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.aoAccounts = payload ?? [];
    });
    builder.addCase(GetAutoOrderingConfiguration.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = String(payload);
    });
    //Save autoordering account configuration
    builder.addCase(SaveAutoOrderingAccountConfiguration.pending, (state) => {
      state.updating = true;
      state.error = '';
    });
    builder.addCase(SaveAutoOrderingAccountConfiguration.fulfilled, (state, { payload, meta }) => {
      state.updating = false;

      if (!state.aoAccounts)
        return;

      if (!payload.success)
        return;

      for (let i = 0; i < state.aoAccounts.length; i++) {
        if (state.aoAccounts[i].id == payload.id) {
          state.aoAccounts[i] = {
            ...state.aoAccounts[i],
            rawSettings: meta.arg.rawSettings
          };
          return;
        }
      }
      state.aoAccounts.push({
        channelOAuthId: meta.arg.channelOAuthId,
        createdOn: new Date(),
        rawSettings: meta.arg.rawSettings,
        sourceId: meta.arg.sourceId,
        id: payload.id
      });
    });
    builder.addCase(SaveAutoOrderingAccountConfiguration.rejected, (state, { payload }) => {
      state.updating = false;
      state.error = String(payload);
    });
    //Delete autoordering account 
    builder.addCase(DeleteAutoOrderingAccount.pending, (state) => {
      state.deleting = true;
      state.error = '';
    });
    builder.addCase(DeleteAutoOrderingAccount.fulfilled, (state, { payload, meta }) => {
      state.deleting = false;

      if (!state.aoAccounts)
        return;

      if (!payload.success)
        return;

      for (let i = 0; i < state.aoAccounts.length; i++) {
        if (state.aoAccounts[i].id == meta.arg) {
          state.aoAccounts.splice(i, 1);
          break;
        }
      }

    });
    builder.addCase(DeleteAutoOrderingAccount.rejected, (state, { payload }) => {
      state.deleting = false;
      state.error = String(payload);
    });
    //Dashboard Order Tasks
    builder.addCase(TaskPendingAutoOrder.pending, (state) => {
      state.taskPendingAutoOrder = {
        loading: true
      };
    });
    builder.addCase(TaskPendingAutoOrder.fulfilled, (state, { payload }) => {
      state.taskPendingAutoOrder = {
        loading: false,
        data: payload
      };
    });
    builder.addCase(TaskPendingAutoOrder.rejected, (state, { payload }) => {
      state.taskPendingAutoOrder = {
        loading: false,
        error: String(payload)
      };
    });
  }
});


export type AutoOrderingFee = {
  date?: string;
  fee?: number;
  reference?: string;
  paid?: boolean;
  productId?: number;
  price?: number;
}

export type AutoOrderingRecord = {
  channelOAuthId: number;
  isoCountry: number;
  fees: AutoOrderingFee[];
  name: string;
  removed: boolean;
}

export type AutoOrderingFees = {
  feesByChannel?: [AutoOrderingRecord];
}

export type AutoOrderingFeesSliceState = {
  AOFees?: AutoOrderingFees,
  loading: boolean,
  error: string
};

const autoOrderingFeesInitialState: AutoOrderingFeesSliceState = {
  loading: false,
  error: ''
};


//Fees that user has to pay
export const getAutoOrderFeesSlice = createSlice({
  name: 'autoOrderingFees',
  initialState: autoOrderingFeesInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAutoOrderFees.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getAutoOrderFees.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.AOFees = payload;
    });
    builder.addCase(getAutoOrderFees.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = String(payload);
    });
  }
});

export const { reducer: getAutoOrderFeesReducer } = getAutoOrderFeesSlice;
export const { reducer: getAutoOrderingReducer } = getAutoOrderingSlice;
