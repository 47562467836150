import React, { useState, useEffect, useMemo } from 'react';
import { Spin, Pagination } from 'antd';
import { DangerBtn, SuccessBtn } from '../../small-components/ActionBtns';
import { PrimaryBtn } from '../../small-components/ActionBtns';
import { SearchOptions } from '../../small-components/SearchOptions';
import { PopupModal } from '../../small-components/modals/popup-modal';
import { ProductDetails } from './ProductDetails';
import { AllProducts } from './AllProducts';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { ListProducts, GetBulkListingStatus, CatalogOrigin } from '../../redux/catalog/catalogThunk';
import { CatalogProduct, CatalogStatusData, ErrorWhy } from '../../redux/catalog/catalogSlice';
import { getSources } from 'src/redux/sources/sourcesThunk';
import '../../sass/catalog.scss';
import { toastAlert } from '../../utils/toastAlert';
import { CatalogFilters } from './catalog-filters';
import { T } from '../../utils/transShim';
import { ReactUtils } from '../../utils/react-utils';
import { ePlatform } from '../../data/platforms';
import emptyCatalog from '../../assets/emptyCatalog.jpg';
import { MasivePublishModal } from '../../small-components/modals/masive-publish-modal';
import { useHistory } from 'react-router';
import { CatalogCard } from './catalog-card';
import { eBulkListingStatus } from '../../redux/listings/auto-list-slice';
import { getChannelConfiguration } from '../../redux/channel-configuration/channels-configuration-thunk';
import { ChannelSettingKey } from '../../types/settings';
import { FiltersBtn } from '../../small-components/tables/complex-table-components/table-action-btns';

import { A } from '../../small-components/A';

export type OnListedData = { pid: number; key: string };

export const Catalog = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { settings, loading: settingsLoading } = useAppSelector((state) => state.channelConfiguration);

  useEffect(() => {
    dispatch(getChannelConfiguration());
  }, []);

  const { sources, loading: loadingSources } = useAppSelector((state) => state.sources);
  const { catalogProducts, loading: loadingCatalog, status } = useAppSelector((state) => state.catalogProducts);
  const { loading: listLoading } = useAppSelector((state) => state.catalogListProducts);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const loading =
    loadingSources ||
    loadingCatalog ||
    !status?.lastLoad ||
    settingsLoading ||
    new Date().getTime() - new Date(status.lastLoad).getTime() > 60000; //More than 1 min? mark as loading
  const channel = ReactUtils.GetSelectedChannel();

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [modalOpen, SetModalOpen] = useState<boolean>(false);
  const [allProductsModalOpen, setAllProductsModalOpen] = useState<boolean>(false);
  const [selectedProductDataDetail, setSelectedProductDataDetail] = useState<CatalogProduct | null>(null);
  //States for ListTheProductFunctionaity

  const [products, SetProducts] = useState<CatalogProduct[]>([]);
  const [productsInCart, SetProductsInCart] = useState<CatalogProduct[]>([]);

  const [listProductsModal, SetListProductModal] = useState<boolean>(false);

  const selectedChannel = ReactUtils.GetSelectedChannel();
  const selectedId = selectedChannel?.channelId;

  // get listing status update
  const updateFrequencyMs = 12000;

  //Pagination
  const { pageSize, currentPage } = (() => {
    const params = new URLSearchParams(window.location.search);

    const pageSize = parseInt(params.get('ps') ?? '20');
    const pageNumber = parseInt(params.get('pn') ?? '1');

    return {
      pageSize: isNaN(pageSize) ? 20 : pageSize,
      currentPage: isNaN(pageNumber) ? 1 : pageNumber
    };
  })();
  const [pageSizeOptions] = useState<number[]>([10, 20, 50, 100]);

  const GetData = () => products?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const [data, setData] = useState<CatalogProduct[]>(GetData());
  const HandlePageSize = (current: number, pageSize: number) => {
    const searchParams = new URLSearchParams(window.location.search);
    if (pageSize != 20) {
      searchParams.set('ps', pageSize?.toString());
    } else {
      searchParams.delete('ps');
    }
    history.push({
      search: searchParams.toString()
    });
  };

  const HandlePageChange = (currentPage: number) => {
    const searchParams = new URLSearchParams(window.location.search);
    if (currentPage > 1) {
      searchParams.set('pn', (currentPage ?? 1).toString());
    } else {
      searchParams.delete('pn');
    }
    history.push({
      search: searchParams.toString()
    });
  };

  useEffect(() => {
    setData(GetData());
  }, [currentPage, pageSize, products]);

  useEffect(() => {
    dispatch(GetBulkListingStatus());
    const interval = setInterval(async () => {
      await dispatch(GetBulkListingStatus());
    }, updateFrequencyMs);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!!sources && sources.length > 0 && sources[0].site == channel?.isoCountry?.toString()) return;
    if (loadingSources) return;
    dispatch(getSources());
  }, [channel?.id]);

  useEffect(() => {
    SetProducts(catalogProducts ?? []);
    setDrawerOpen(false);
  }, [catalogProducts]);

  const statusDic = useMemo(() => {
    const dic: { [psId: number]: CatalogStatusData } = {};
    for (const p of status?.data ?? []) {
      dic[p.productSourceId] = p;
    }
    return dic;
  }, [status]);

  const handleSideDrawer = () => setDrawerOpen(!drawerOpen);

  const HandleProductModal = (id: number) => {
    setSelectedProductDataDetail(products?.filter((d) => d.id === id)[0]);
    SetModalOpen(!modalOpen);
  };

  const HandleAllProductsModal = () => setAllProductsModalOpen(!allProductsModalOpen);

  const RemoveSelectedProduct = (id: number): void => {
    SetProductsInCart(productsInCart.filter((x) => x.id != id));
  };
  const CanAddProduct = (p: CatalogProduct | undefined | null) => {
    if (p != null && !p.beingSend && !p.sent) {
      switch (statusDic[p.id]?.status) {
        case eBulkListingStatus.PROCESSING:
        case eBulkListingStatus.VALIDATING:
        case eBulkListingStatus.TEMPORAL_ERROR:
        case eBulkListingStatus.DONE:
          return false;
        default:
        case eBulkListingStatus.UNKNOWN:
        case eBulkListingStatus.ERROR:
          return true;
      }
    }
    return false;
  };
  const AddSelectedProduct = (id: number): void => {
    const p = products.find((x) => x.id == id);
    if (p != null && CanAddProduct(p)) {
      SetProductsInCart([...productsInCart, p]);
    }
  };
  const HandleClickProductCard = (id: number): void => {
    if (productsInCart.find((x) => x.id == id) != null) {
      RemoveSelectedProduct(id);
    } else {
      AddSelectedProduct(id);
    }
  };

  const IsSelectedProduct = (id: number) => productsInCart.find((d) => d.id === id) != null;

  const HandleSelectAllProducts = (): void => {
    SetProductsInCart([...new Set([...productsInCart, ...data.filter((x) => CanAddProduct(x))])]);
  };

  const HandleClearAllSelectedProducts = (): void => {
    SetProductsInCart([]);
    for (let i = 0; i < productsInCart.length; i++) {
      document.getElementById(JSON.stringify(productsInCart[i].id))?.classList.remove('selected-product-card');
    }
  };

  const GetSourceName = (id: number) => sources?.find((x) => x.id == id)?.name;

  const OnListed = (data: OnListedData[]) => {
    const newProducts = [...products];
    for (const d of data) {
      for (let i = 0; i < newProducts.length; i++) {
        if (newProducts[i].id === d.pid) {
          newProducts[i] = {
            ...newProducts[i],
            batchId: d.key,
            sent: true
          };
          break;
        }
      }
    }
    SetProducts(newProducts);
  };

  const HandlePublishTheProducts = async (
    checkDate: boolean,
    frequencyData: number | undefined,
    needsReview: boolean,
    optimizeTitle: boolean,
    optimizeDescription: boolean,
    publishVariations: boolean,
    newDate: Date | undefined
  ) => {
    setSubmitting(true);
    const newProducts = [...products];
    const newProductsInCart = [...productsInCart];
    SetProductsInCart([]);

    for (const prd of newProductsInCart) {
      const ip = newProducts.findIndex((x) => x.id == prd.id);
      if (ip >= 0) newProducts[ip] = { ...newProducts[ip], beingSend: true };
    }
    SetProducts(newProducts);
    SetListProductModal(false);

    const delaysMap: { [id: number]: Date } = {};
    if (checkDate) {
      let currentDate = newDate ?? new Date();
      for (const prd of newProductsInCart) {
        delaysMap[prd.id] = currentDate;
        currentDate = new Date(currentDate.getTime() + (frequencyData ?? 0) * 60000);
      }
    }
    const productsToList = productsInCart.map((e: CatalogProduct) => {
      const { id, title } = e;
      return { id, title, publishNow: newDate };
    });
    const rs = await dispatch(
      ListProducts({
        productsToList,
        needsReview,
        optimizeTitle,
        optimizeDescription,
        publishVariations,
        delaysMap,
        origin: CatalogOrigin.CATALOG
      })
    );
    switch (rs.payload.error) {
      case ErrorWhy.NoError:
        break;
      case ErrorWhy.NoTokens:
        toastAlert("You don't have enough tokens. Refresh the page and try it again", 'error');
        break;
      case ErrorWhy.NoQuota:
        toastAlert("You don't have enough quota. If you think this is wrong, refresh the page and try again", 'error');
        break;
    }

    if (rs.payload.success) {
      const f: OnListedData[] = [];

      for (const prd of newProductsInCart) {
        const pid = prd.id;
        f.push({ pid: pid, key: rs.payload.batchId });

        const ip = newProducts.findIndex((x) => x.id == pid);
        if (ip >= 0) newProducts[ip] = { ...newProducts[ip], beingSend: false, sent: true };
      }
      SetProducts(newProducts);

      OnListed(f);
    } else {
      toastAlert(rs.payload, 'error');
    }
    setSubmitting(false);
  };

  return (
    <div className="catalog-container">
      <SearchOptions showSearchInput={false} />
      <CatalogFilters visible={drawerOpen} onClose={handleSideDrawer} setProducts={SetProducts} />
      {loading ? (
        <Spin />
      ) : (
        <>
          {selectedId != 4 ?? (
            <A to="https://hustlegotreal.com/en/listing-service/" target="_blank" className="list-link" rel="noreferrer">
              Not sure what to list? We do it for you.
            </A>
          )}

          <div className="actions-section">
            <div className="all-selected-products-container">
              <div className="all-selected-products" onClick={HandleAllProductsModal}>
                <PrimaryBtn>
                  <T k="Catalog.SelectedProducts" />
                </PrimaryBtn>
                <div className="counter-items">
                  <div className="counter-items-circle">
                    <span>{productsInCart ? productsInCart.length : '0'}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="filters-container">
              <FiltersBtn handleSideDrawer={handleSideDrawer} disabled={submitting}>
                <T k="Catalog.Filters" />
              </FiltersBtn>
            </div>
          </div>

          <PopupModal key={1} open={modalOpen} onClose={() => SetModalOpen(false)} maxWidth={900}>
            {selectedProductDataDetail && (
              <ProductDetails
                product={selectedProductDataDetail}
                channelPrice={selectedProductDataDetail.channelPrice}
                imageUrl={selectedProductDataDetail.imageUrl}
                profit={selectedProductDataDetail.profit}
                sourcePrice={selectedProductDataDetail.sourcePrice}
                onClose={() => SetModalOpen(false)}
                onSelectProduct={HandleClickProductCard}
                selected={IsSelectedProduct(selectedProductDataDetail.id)}
                currency={ReactUtils.GetCurrencyByCountry(channel?.isoCountry)}
                sourceName={GetSourceName(selectedProductDataDetail.sourceId) ?? ''}
              />
            )}
          </PopupModal>
          <PopupModal
            key={3}
            open={allProductsModalOpen}
            onClose={HandleAllProductsModal}
            maxWidth={600}
            bodyStyle={{ height: 500, overflow: 'scroll' }}
            showCloseX={false}
          >
            <AllProducts
              removeProduct={RemoveSelectedProduct}
              className="product-card"
              currency={ReactUtils.GetCurrencyByCountry(channel?.isoCountry)}
            >
              {productsInCart}
            </AllProducts>
          </PopupModal>
          <MasivePublishModal
            onSubmit={HandlePublishTheProducts}
            open={listProductsModal}
            platform={channel?.channelId ?? ePlatform.eBay}
            productsInCart={productsInCart.length}
            setOpen={SetListProductModal}
            key={4}
            loading={listLoading}
            showPublishVariants={false}
            otpimizeByDefault={(settings?.find((x) => x.key == ChannelSettingKey.OptimizeByDefault)?.value ?? '1') == '1'}
            variationsByDefault={(settings?.find((x) => x.key == ChannelSettingKey.VariationsByDefault)?.value ?? '1') == '1'}
          />
          <div className="catalog-cards">
            <div className="cards-container-catalog">
              {data.map((d: CatalogProduct, i) => (
                <CatalogCard
                  key={i}
                  className="product-card"
                  data={d}
                  onClick={HandleClickProductCard}
                  selected={IsSelectedProduct(d.id)}
                  status={statusDic[d.id]}
                  sourceName={GetSourceName(d.sourceId) ?? ''}
                  onOpenDetails={HandleProductModal}
                  currency={ReactUtils.GetCurrencyByCountry(channel?.isoCountry)}
                />
              ))}
              {!!productsInCart.length && (
                <div className="button-mobile-container">
                  <SuccessBtn className="button-list-mobile" onClick={() => SetListProductModal(!listProductsModal)}>
                    <T k="Catalog.ListNProducts" values={{ count: productsInCart.length }} />
                  </SuccessBtn>
                </div>
              )}
              {data.length == 0 && (
                <div style={{ margin: 'auto' }}>
                  {' '}
                  <img src={emptyCatalog} />{' '}
                </div>
              )}
            </div>
            <div className="pagination-addall-container">
              <div className="responsive-container">
                <div className="adall-container">
                  {!!productsInCart.length && (
                    <SuccessBtn className="button-list-desktop" onClick={() => SetListProductModal(!listProductsModal)}>
                      <T k="Catalog.ListNProducts" values={{ count: productsInCart.length }} />
                    </SuccessBtn>
                  )}
                  <PrimaryBtn onClick={HandleSelectAllProducts}>
                    <T k="Catalog.SelectAll" />
                  </PrimaryBtn>
                  <span className="clear-all" onClick={HandleClearAllSelectedProducts}>
                    <DangerBtn>
                      <T k="Catalog.ClearAll" />
                    </DangerBtn>
                  </span>
                </div>
                <div className="pagination">
                  <Pagination
                    className="pagination"
                    onChange={HandlePageChange}
                    total={products?.length}
                    current={currentPage}
                    showSizeChanger={true}
                    onShowSizeChange={HandlePageSize}
                    pageSizeOptions={pageSizeOptions}
                    defaultPageSize={pageSize}
                    pageSize={pageSize}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
