import * as React from 'react';
import { CountriesRelation } from '../../data/countries';
import { Selector, SelectorData, SelectorValue } from './selector';

export interface IRowProps {
  defaultValue: string;
  onChange: (value: string) => void;
  name: string;
  className: string;
  showDefaultOption?: boolean;
}
interface IRowState {
  value: string;
}
const CountryCodelist: SelectorData[] = CountriesRelation.map(x => ({ label: x.name, value: x.id }));
export class CountryCodeSelector extends React.Component<IRowProps, IRowState> {
  public static defaultProps = {
    defaultValue: '',
    onChange: undefined,
    name: '',
    className: '',
    showDefaultOption: true
  };

  constructor(props: IRowProps) {
    super(props);

    this.state = {
      value: props.defaultValue
    };
  }

  render() {
    const fVal = (opts: SelectorData[], val: string, defV: SelectorValue): SelectorValue => {
      const cval = opts.find((o) => o.value == val);
      if (!cval) return defV;
      return cval.value;
    };

    let options = CountryCodelist;
    if (!this.props.showDefaultOption) {
      options = [...CountryCodelist];
      options.splice(0, 1);
    }
    return (
      <Selector
        className={this.props.className}
        onChange={this.onChange}
        defaultValue={fVal(options, this.props.defaultValue, options[0].value)}
      >
        {options}
      </Selector>
    );
  }

  onChange = (val: SelectorValue) => {
    this.setState({ value: val as string });
    if (this.props.onChange) this.props.onChange(val as string);
  };
}
