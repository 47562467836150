import { useState } from 'react';
import '../../sass/settings/settings.scss';
import { SettingBoolean } from './setting-boolean';
import { SettingString } from './setting-string';

interface Props {
  value1?: string;
  value2?: string;
  defaultValue1: string | null;
  defaultValue2: string | null;
  onChange1: (value: string | null) => void;
  onChange2: (value: string | null) => void;
  loading1: boolean;
  loading2: boolean;
  disabled?: boolean;
  delayToSave?: number;
}

export const SettingBooleanString = (props: Props) => {
  const { value1, value2, defaultValue1, defaultValue2, onChange1, onChange2, loading1, loading2, disabled, delayToSave } = props;

  const [currentValue1, setCurrentValue1] = (() => {
    const [currentValue1, setCurrentValue1] = useState<string | null>(value1 ?? defaultValue1);
    return [value1 ?? currentValue1, setCurrentValue1];
  })();
  const booleanEnabled = currentValue1 == '1' || currentValue1?.toLowerCase() == 'True';

  const OnChangeBool = (v: string | null) => {
    onChange1?.(v);
    setCurrentValue1(v);
  };

  return <div className="setting setting-boolean-number">
    <SettingBoolean value={value1} defaultValue={defaultValue1} loading={loading1} onChange={OnChangeBool} disabled={disabled} />
    <SettingString value={value2} defaultValue={defaultValue2} loading={loading2} onChange={onChange2} disabled={disabled || !booleanEnabled} delayToSave={delayToSave} />
  </div>;
};