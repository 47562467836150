import { ShopOutlined } from '@ant-design/icons';
import { Steps } from 'antd';
import { useState } from 'react';
import { PrimaryBtn } from 'src/small-components/ActionBtns';
import { Links } from '../../links';
import '../../sass/dashboard-nolist.scss';
import { T } from '../../utils/transShim';
import { SuggestedProducts } from './panels/suggested-products';

export const DashboardNoList = () => {
  const { Step } = Steps;

  const [step, setStep] = useState<number>(1);

  const HandleGuideClick = () => {
    window?.open?.('https://hustlegotreal.com/en/how-to-start-a-dropshipping-business-in-6-simple-steps', '_blank')?.focus();
    if (step < 1) setStep(1);
  };
  //const HandleVideoClick = () => {
  //  window?.open?.('', '_blank')?.focus();
  //  setStep(1);
  //};
  const HandleListed = () => {
    if (step < 2) setStep(2);
  };

  //Si lo prefieres, tambi�n puedes ver nuestro v�deo que explica qu� es el dropshipping y c�mo funciona.
  //If you prefer, you can also watch our video explaining what dropshipping is and how it works.
  return (
    <div className="dashboard-nolist-container">
      <div className="dashboard-nolist">
        <div className="info-section">
          <div className="first-section">
            <h2>
              <T k="Dashboard.First.Title" />
            </h2>
            &nbsp;&nbsp;
            <ShopOutlined style={{ fontSize: '1.5rem' }} />
            &nbsp;&nbsp;
            <h3>
              <T k="Dashboard.First.Subtitle" />
            </h3>
          </div>
          <div className="reward-section">
            <p>
              <T k="Dashboard.First.Description" values={{ q: 5 }} />
            </p>
          </div>
        </div>
        <div className="steps-section">
          <Steps direction="vertical" current={step}>
            <Step
              className="first-step"
              title={<T k="Dashboard.First.DropSection.Title" />}
              description={
                <div className="step-container">
                  <p>
                    <T k="Dashboard.First.DropSection.Description" />
                  </p>
                  <div className="first-step-actions">
                    <div className="buttons-guide">
                      <PrimaryBtn onClick={HandleGuideClick}>
                        <T k="Dashboard.First.DropSection.Guide" />
                      </PrimaryBtn>
                      {/*<DangerBtn onClick={HandleVideoClick}><T k='Dashboard.First.DropSection.Video' /></DangerBtn>*/}
                    </div>
                  </div>
                </div>
              }
            />

            <Step
              className="second-step"
              title={<T k="Dashboard.First.ListSection.Title" />}
              description={
                <div className="step-container">
                  <p>
                    <T k="Dashboard.First.ListSection.Description" />
                  </p>
                  <div className="second-step-actions">
                    <SuggestedProducts disabled={step < 1} onList={HandleListed} />
                  </div>
                </div>
              }
            />

            <Step
              className="third-step"
              title={<T k="Dashboard.First.EndSection.Title" />}
              description={
                <div className="step-container">
                  <p>
                    <T
                      k="Dashboard.First.EndSection.Description"
                      values={{ to: Links.Products.Active, disabled: step < 2 ? '@true' : '@false' }}
                    />
                  </p>
                  <div className="third-step-actions"></div>
                </div>
              }
            />
          </Steps>
        </div>
      </div>
    </div>
  );
};
