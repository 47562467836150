import { Alert, Col, Row, Spin } from 'antd';
import { useAppSelector } from '../../../../custom-hooks/reduxCustomHooks';
import { AutoOrderField, Fields, UserSupplierSettingsKeys } from '../../../../data/autoorder-fields';
import Sources, { SourceStaticData } from '../../../../data/sources';
import { AutoOrderingConfigurationAccountData, RawSettingData, UserSupplierType } from '../../../../redux/auto-ordering/autoOrderingThunk';
import { Source } from '../../../../redux/sources/sourceSlice';
import { SuccessBtn } from '../../../../small-components/ActionBtns';
import { T } from '../../../../utils/transShim';
import { AmazonSourceIds } from '../../../sources/configuration/settings-list';
import Field from '../../fields/field';

interface Props {
  sourceData: Source;
  account: AutoOrderingConfigurationAccountData;
  onRemove: (account: AutoOrderingConfigurationAccountData) => void;
  onChange: (account: AutoOrderingConfigurationAccountData) => void;
}

export const AOAccountSettings = (props: Props) => {
  const { sourceData, account, onRemove, onChange } = props;

  const { updating, deleting } = useAppSelector((state) => state.autoOrdering);

  const source: SourceStaticData = Sources[sourceData.id];
  const fields: AutoOrderField[] = [];

  const GetRawSetting = (s: UserSupplierSettingsKeys) => account?.rawSettings?.find((x) => x.key == s);
  const GetRawSettingValue = (s: UserSupplierSettingsKeys) => GetRawSetting(s)?.value;

  const HandleAccountSettingChange = (value: string | undefined, key: UserSupplierSettingsKeys) => {
    const newSettings = [...account.rawSettings] as RawSettingData[];
    let found = false;
    for (let i = 0; i < newSettings.length; i++) {
      if (newSettings[i].key == key) {
        newSettings[i] = { ...newSettings[i], value: value };
        found = true;
        break;
      }
    }
    if (!found) newSettings.push({ key, value });
    onChange({ ...account, rawSettings: newSettings } as AutoOrderingConfigurationAccountData);
  };

  const RenderField = (uss: UserSupplierSettingsKeys) => {
    return (
      <>
        {!!fields[uss] && (
          <Row>
            <Col xs={24}>
              <Field field={fields[uss]} onChange={HandleAccountSettingChange} value={GetRawSettingValue(uss)} source={source} />
            </Col>
          </Row>
        )}
      </>
    );
  };
  const RenderDobleField = (us1: UserSupplierSettingsKeys, us2: UserSupplierSettingsKeys) => {
    return (
      <>
        {(!!fields[us1] || !!fields[us2]) && (
          <Row key={us1}>
            <Col xs={24}>
              <div className="d-flex">
                {!!fields[us1] && (
                  <Field field={fields[us1]} onChange={HandleAccountSettingChange} value={GetRawSettingValue(us1)} source={source} />
                )}
                {!!fields[us2] && (
                  <Field field={fields[us2]} onChange={HandleAccountSettingChange} value={GetRawSettingValue(us2)} source={source} />
                )}
              </div>
            </Col>
          </Row>
        )}
      </>
    );
  };

  const ForceRF = (field: AutoOrderField) => {
    return <Field field={field} onChange={HandleAccountSettingChange} value={GetRawSettingValue(field.setting)} source={source} />;
  };

  const ForceRenderField = (field: AutoOrderField) => {
    return (
      <Row key={field.setting}>
        <Col xs={24}>{ForceRF(field)}</Col>
      </Row>
    );
  };
  //const ForceRenderDobleField = (field1: AutoOrderField, field2: AutoOrderField) => {
  //  return (
  //    <>
  //      {
  //        <Row key={field1.setting}>
  //          <Col xs={24}>
  //            <div className="d-flex">
  //              {ForceRF(field1)}
  //              {ForceRF(field2)}
  //            </div>
  //          </Col>
  //        </Row>
  //      }
  //    </>
  //  );
  //};

  const pcustom = GetRawSettingValue(UserSupplierSettingsKeys.PROXY_CUSTOM);
  const pprivate = GetRawSettingValue(UserSupplierSettingsKeys.PROXY_PRIVATE);

  let thereIsBilling = false;
  let thereIsCard = false;
  let thereIsGift = false;
  let thereIsPaymentType = false;
  let thereGiftCard = false;
  let thereIsSupplierPaymentPassword = false;
  let otherOptions = false;
  let thereIsWallet = false;
  let thereIsProxy = false;

  const infoFields = (() => {
    if (account.type == UserSupplierType.HGR)
      return source.autoorder.hgrAccountFields ?? [];
    return [Fields.Disable, Fields.Alias, ...source.autoorder.fields, Fields.ProxyCustom, Fields.ProxyIp, Fields.ProxyPort, Fields.ProxyPrivate, Fields.ProxyUsername, Fields.ProxyPassword];
  })();

  for (const f of infoFields) {
    switch (f?.setting) {
      case UserSupplierSettingsKeys.POSTCODE:
      case UserSupplierSettingsKeys.PROVINCE:
      case UserSupplierSettingsKeys.CITY:
      case UserSupplierSettingsKeys.COUNTRY:
      case UserSupplierSettingsKeys.STREET_1:
      case UserSupplierSettingsKeys.STREET_2:
        thereIsBilling = true;
        break;
      case UserSupplierSettingsKeys.CARD_NUMBER:
      case UserSupplierSettingsKeys.CARD_CVV:
      case UserSupplierSettingsKeys.CARD_NAME:
      case UserSupplierSettingsKeys.CARD_DATE_YEAR:
      case UserSupplierSettingsKeys.CARD_DATE_MONTH:
        thereIsCard = true;
        break;
      case UserSupplierSettingsKeys.GIFT_CARD:
        thereGiftCard = true;
        break;
      case UserSupplierSettingsKeys.MARK_AS_GIFT:
      case UserSupplierSettingsKeys.GIFT_MESSAGE:
      case UserSupplierSettingsKeys.GIFT_FROM:
        thereIsGift = true;
        break;
      case UserSupplierSettingsKeys.PAYMENT_TYPE:
        thereIsPaymentType = true;
        break;
      case UserSupplierSettingsKeys.SUPPLIER_PAYMENT_PASSWORD:
        thereIsSupplierPaymentPassword = true;
        break;
      case UserSupplierSettingsKeys.PROTECTION_SERVICES_RETURN:
      case UserSupplierSettingsKeys.PROTECTION_SERVICES_LOGISTICS:
      case UserSupplierSettingsKeys.DISCOUNT_CODE:
      case UserSupplierSettingsKeys.COMPANY_NAME:
        otherOptions = true;
        break;
      case UserSupplierSettingsKeys.GIFT_CARD_NUMBER:
      case UserSupplierSettingsKeys.GIFT_CARD_PIN:
        thereIsWallet = true;
        break;
      case UserSupplierSettingsKeys.PROXY_CUSTOM:
      case UserSupplierSettingsKeys.PROXY_PRIVATE:
      case UserSupplierSettingsKeys.PROXY_IP:
      case UserSupplierSettingsKeys.PROXY_PORT:
      case UserSupplierSettingsKeys.PROXY_PASSWORD:
      case UserSupplierSettingsKeys.PROXY_USERNAME:
        thereIsProxy = true;
        break;
    }
    fields[f.setting] = f;
  }


  const HandleRemoveAccount = () => {
    if (!confirm('Are you sure you want to remove this account?')) return;
    onRemove(account);
  };

  const isAmazon = AmazonSourceIds.find((x) => x == sourceData.id);

  if (updating || deleting) return <Spin />;

  return (
    <div className="account-details">
      <div className="auto-ordering-fields">
        {!sourceData.hasAutoOrderHGRAccount && <>
          <div className="alert-section">
            {isAmazon && <Alert className="alert-text " message={<T k="AutoOrderingConfiguration.Message.Prime" />} type="info" />}
            <Alert className="alert-text" message={<T k="AutoOrderingConfiguration.Message.ExclusiveAcc" />} type="info" />
          </div>
        </>}
        {/* DISABLE */}
        {!sourceData.hasAutoOrderHGRAccount && ForceRenderField(Fields.Disable)}
        {sourceData.hasAutoOrderHGRAccount && ForceRenderField(Fields.AuthorizeHGRAccount)}
        {/* ALIAS */}
        {RenderField(UserSupplierSettingsKeys.ALIAS)}
        {/* LOGIN */}
        {RenderDobleField(UserSupplierSettingsKeys.USERNAME, UserSupplierSettingsKeys.PASSWORD)}
        {RenderField(UserSupplierSettingsKeys.OTP)}
        {/* BUSINESS ACCOUNT */}
        {RenderField(UserSupplierSettingsKeys.BUSINESS_ACCOUNT)}
        {/* PHONE */}
        {ForceRenderField(Fields.Phone)}
        {ForceRenderField(Fields.ReplaceTelephone)}
        {/* CONTACT EMAIL */}
        {RenderField(UserSupplierSettingsKeys.EMAIL)}
        {/* BILLING ADDRESS */}
        {thereIsBilling && (
          <>
            <h3 className="acc-option-title">Billing Address</h3>
            {RenderDobleField(UserSupplierSettingsKeys.FIRST_NAME, UserSupplierSettingsKeys.LAST_NAME)}
            {RenderField(UserSupplierSettingsKeys.STREET_1)}
            {RenderField(UserSupplierSettingsKeys.STREET_2)}
            {RenderField(UserSupplierSettingsKeys.POSTCODE)}
            {RenderField(UserSupplierSettingsKeys.CITY)}
            {RenderField(UserSupplierSettingsKeys.PROVINCE)}
            {RenderField(UserSupplierSettingsKeys.COUNTRY)}
          </>
        )}
        {/* PAYMENT SETTINGS */}
        {(thereIsPaymentType || thereIsCard || thereIsSupplierPaymentPassword || thereIsWallet) && (
          <>
            <h3 className="acc-option-title"><T k='AutoOrderingConfiguration.Field.Section.Payment'/></h3>
          </>
        )}
        {RenderField(UserSupplierSettingsKeys.SUPPLIER_PAYMENT_PASSWORD)}
        {RenderField(UserSupplierSettingsKeys.GIFT_CARD)}
        {RenderField(UserSupplierSettingsKeys.PAYMENT_TYPE)}
        {/* CARD */}
        {thereIsCard &&
          (!thereIsPaymentType || (GetRawSettingValue(UserSupplierSettingsKeys.PAYMENT_TYPE) ?? '1').toString() == '1') &&
          (!thereGiftCard || (GetRawSettingValue(UserSupplierSettingsKeys.GIFT_CARD) ?? '0').toString() != '2') && (<>
          <h3 className="acc-option-title"><T k='AutoOrderingConfiguration.Field.Section.Card'/></h3>
          {RenderField(UserSupplierSettingsKeys.CARD_NUMBER)}
          {RenderField(UserSupplierSettingsKeys.CARD_CVV)}
          {RenderField(UserSupplierSettingsKeys.CARD_NAME)}
          {RenderDobleField(UserSupplierSettingsKeys.CARD_DATE_MONTH, UserSupplierSettingsKeys.CARD_DATE_YEAR)}
        </>)}
        {/*ASDA GIFT CARD THAT WORKS AS WALLET*/}
        {RenderField(UserSupplierSettingsKeys.GIFT_CARD_NUMBER)}
        {RenderField(UserSupplierSettingsKeys.GIFT_CARD_PIN)}
        {/*MARK AS GIFT*/}
        {thereIsGift && (
          <>
            <h3 className="acc-option-title"><T k='AutoOrderingConfiguration.Field.Section.Gift' /></h3>
            {RenderField(UserSupplierSettingsKeys.MARK_AS_GIFT)}
            {RenderField(UserSupplierSettingsKeys.GIFT_MESSAGE)}
            {RenderField(UserSupplierSettingsKeys.GIFT_FROM)}
          </>
        )}
        {otherOptions && (
          <>
            <h3 className="acc-option-title"><T k='AutoOrderingConfiguration.Field.Section.OtherOptions' /></h3>
            {RenderField(UserSupplierSettingsKeys.PROTECTION_SERVICES_RETURN)}
            {RenderField(UserSupplierSettingsKeys.PROTECTION_SERVICES_LOGISTICS)}
            {RenderField(UserSupplierSettingsKeys.DISCOUNT_CODE)}
            {RenderField(UserSupplierSettingsKeys.COMPANY_NAME)}
          </>
        )}

        {/* CUSTOM PROXY */}
        {thereIsProxy && (<>
          <h3 className="acc-option-title">Proxy</h3>
          {RenderField(UserSupplierSettingsKeys.PROXY_CUSTOM)}
          {!!pcustom && pcustom != '0' && (
            <>
              {RenderDobleField(UserSupplierSettingsKeys.PROXY_IP, UserSupplierSettingsKeys.PROXY_PORT)}
              {RenderField(UserSupplierSettingsKeys.PROXY_PRIVATE)}
              {!!pprivate && pprivate != '0' && <>{RenderDobleField(UserSupplierSettingsKeys.PROXY_USERNAME, UserSupplierSettingsKeys.PROXY_PASSWORD)}</>}
            </>
          )}
        </>)}
      </div>
      {account.id > 0 && account.type != UserSupplierType.HGR &&
        <div className="remove-account mt-30">
          <SuccessBtn className="remove-account-btn" onClick={HandleRemoveAccount}>
            <T k='AutoOrderingConfiguration.RemoveAccount' />
          </SuccessBtn>
          <p><T k='AutoOrderingConfiguration.RemoveAccount.Description' />If you delete this account, you will have to set it up again and no payments will be made through this account.</p>
        </div>
      }
    </div>
  );
};