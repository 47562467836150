import { Alert } from 'antd';
import { useEffect, useState } from 'react';

import { T } from '../../../utils/transShim';
import { A } from '../../A';
import { RefreshData } from '../types/refresh-data';

interface Props {
  refresh: RefreshData
}

export const RefreshWarning = (props: Props) => {
  const { refresh } = props;

  const [time, SetTime] = useState<Date>(new Date());

  const timerToManuallyRefresh = (refresh?.minTime ?? 0) * 1000 - ((new Date()).getTime() - refresh.lastRefreshTime.getTime());
  const disableBtnRefresh = timerToManuallyRefresh > 0;

  const timerToAutoMaticallyRefresh = Math.max(0, (refresh?.autoTime?.time ?? 0) * 1000 - ((new Date()).getTime() - refresh.lastRefreshTime.getTime()));

  useEffect(() => {
    if (!!refresh && (refresh.minTime ?? 0) > 0) {
      setTimeout(() => SetTime(new Date()), 500);
      if (timerToAutoMaticallyRefresh <= 0) {
        refresh?.onRefresh?.();
      }
    }
  }, [refresh?.show, refresh?.minTime, time]);

  const HandleClickRefresh = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    refresh?.onRefresh?.();
  };

  const HandleStopRefresh = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    refresh.autoTime?.onStopRefresh?.();
  };

  const Refreshable = () => {
    return <>
      <T k='Table.RefreshingAutoIn' values={{ s: Math.floor(timerToAutoMaticallyRefresh / 1000) }} />
      {' '} <A to='#' onClick={HandleStopRefresh}><T k='Table.StopRefresh' /></A>
      {!disableBtnRefresh && <>{' | '}<A href='#' onClick={HandleClickRefresh} to={''}><T k='Table.RefreshNow' /></A></>}
    </>;
  };

  const Stopped = () => {
    return <>
      <T k='Table.RefreshStopped' />
    </>;
  };

  if (refresh.loading ?? false)
    return <></>;

  return <Alert message={refresh?.autoTime?.enabled ? Refreshable() : Stopped()} />;
};