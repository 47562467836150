import { Switch, SwitchProps } from 'antd';
import { Label } from './label';

export interface Props extends SwitchProps {
  label: React.ReactNode
}

export const SwitchLabel = (props: Props) => {
  return <div className={(props.className ?? '') + ' switchlabel'}>
    <Label>{props.label}</Label>
    <Switch {...{...props, className: undefined }} />
  </div>;
};