import { useState } from 'react';
import { T } from '../../../../../utils/transShim';
import '../../../../../sass/edit-single-listing.scss';
import { CheckIcon, RefreshIcon, TrashIcon } from '../../../../common/Icons';
import { ActiveListingExtended } from '../../types';
import { DeleteBtn, SuccessBtn, WarningBtn } from '../../../../../small-components/ActionBtns';
import { ePlatform } from '../../../../../data/platforms';
import { useDispatch } from 'react-redux';
import { ForceRefresh, SaveMultipleListingChanges, SaveMultipleProductChangesRequest, SaveProductChangeNumber, TerminateListing } from '../../../../../redux/listings/listings-thunk';
import { useAppSelector } from '../../../../../custom-hooks/reduxCustomHooks';
import { LoadingModal } from '../../../../../small-components/modals/loading-modal';
import { ValueIfEqual } from '../../../../../utils/utils';
import { SetValueBoolean, SetValueNumber } from './types';
import { EditMultipleListingMain } from './edit-multiple-listing-main';
import { ListingMultipleOptions } from './edit-multiple-listing-options';
import { QuestionModal } from '../../../../../small-components/modals/question-modal';
import { AmazonSourceIds } from '../../../../sources/configuration/settings-list';
import { ListingMultipleBusiness } from './edit-multiple-listing-business';
import { ListingsUtils } from '../../../../../utils/listings-utils';
import { Tabs } from 'antd';

interface Props {
  platform: ePlatform | undefined;
  listings?: ActiveListingExtended[];
  onClose: () => void;
}

enum Tab {
  Main, Description, Details, Options,
  Business//This was avaialble only for eBay API, but not for ebay no api
}

export const EditMultipleListing = (props: Props) => {
  const { listings, platform, onClose } = props;

  if (listings == null || listings.length == 0)
    return <></>;

  const [activeTab, SetActiveTab] = useState<number>(Tab.Main);
  const dispatch = useDispatch();

  //Common values?-----------------------------------------------------------------------------------------

  //Main------------
  const commonMarkup = ValueIfEqual(listings.map(x => x.overrides.markup));
  const commonQuantity = ValueIfEqual(listings.map(x => x.channelQuantity));
  const commonPrice = ValueIfEqual(listings.map(x => x.channelPrice));
  const commonProfit = ValueIfEqual(listings.map(x => x.profit));
  //Options----------
  //const commonMonitorStock = ValueIfEqual(listings.map(x => x.overrides.monitorStock));
  //const commonMonitorPrice = ValueIfEqual(listings.map(x => x.overrides.monitorPrice));
  //const commonIgnoreRules = ValueIfEqual(listings.map(x => x.overrides.ignoreRules));
  //const commonMonitorPriceDecrease = ValueIfEqual(listings.map(x => x.overrides.monitorPriceDecrease));
  //const commonPriceDecreasePercentage = ValueIfEqual(listings.map(x => x.overrides.monitorPriceDecreasePercentage));
  //const commonMinQuantity = ValueIfEqual(listings.map(x => x.overrides.minQuantity));
  //const commonPrimeOnly = ValueIfEqual(listings.map(x => x.overrides.primeOnly));
  const thereArePrime = listings.find(x => AmazonSourceIds.includes(x.sourceId)) != null;
  //-------------------------------------------------------------------------------------------------------

  //Changes------------------------------------------------------------------------------------------------
  const { saving } = useAppSelector((state) => state.listings);
  const [terminating, SetTerminating] = useState<boolean>(false);
  const [confirmatingTerminate, SetConfirmatingTerminate] = useState<boolean>(false);
  const [savingProgress, SetSavingProgress] = useState<number>(0);
  const [savingChanges, SetSavingChanges] = useState<boolean>(false);
  const [blockSaving, SetBlockSaving] = useState<boolean>(false);
  const [refreshing, SetRefreshing] = useState<boolean>(false);
  //Main------------
  const [newMarkup, SetNewMarkup] = useState<SetValueNumber>(null);
  const [newQuantity, SetNewQuantity] = useState<SetValueNumber>(null);
  const [newPrice, SetNewPrice] = useState<SetValueNumber>(null);
  const [newProfit, SetNewProfit] = useState<SetValueNumber>(null);
  const [newIncreaseMarkup, SetNewIncreaseMarkup] = useState<SetValueNumber>(null);
  //Options----------
  const [newMonitorStock, SetNewMonitorStock] = useState<SetValueBoolean>(null);
  const [newMonitorPrice, SetNewMonitorPrice] = useState<SetValueBoolean>(null);
  const [newIgnoreRules, SetNewIgnoreRules] = useState<SetValueBoolean>(null);
  const [newMonitorPriceDecrease, SetMonitorPriceDecrease] = useState<SetValueBoolean>(null);
  const [newPriceDecreasePercentage, SetNewPriceDecreasePercentage] = useState<SetValueNumber>(null);
  const [newMinQuantity, SetNewMinQuantity] = useState<SetValueNumber>(null);
  const [newOnlyPrime, SetNewOnlyPrime] = useState<SetValueBoolean>(null);
  //Business policies-------
  const [paymentProfileId, SetPaymentProfileId] = useState<SetValueNumber>(null);
  const [shippingProfileId, SetShippingProfileId] = useState<SetValueNumber>(null);
  const [returnProfileId, SetReturnProfileId] = useState<SetValueNumber>(null);

  //Main--------------------
  const changedMarkup = newMarkup != null;
  const changedQuantity = newQuantity != null;
  const changedPrice = newPrice != null;
  const changeProfit = newProfit != null;
  const changeIncreaseMarkup = newIncreaseMarkup != null;
  //Options-----------------
  const changedMonitorStock = newMonitorStock != null;
  const changedMonitorPrice = newMonitorPrice != null;
  const changedIgnoreRules = newIgnoreRules != null;
  const changedMonitorPriceDecrase = newMonitorPriceDecrease != null;
  const changedPriceDecrasePercentage = newPriceDecreasePercentage != null;
  const changedMinQuantity = newMinQuantity != null;
  const changedOnlyPrime = newOnlyPrime != null;
  //Business policies-------
  const changedPaymentProfileId = paymentProfileId != null;
  const changedShippingProfileId = shippingProfileId != null;
  const changedReturnProfileId = returnProfileId != null;


  const somethingToChange =
    (    changedMarkup || changedQuantity || changeProfit || changeIncreaseMarkup || changedPrice
      || changedMonitorStock || changedMonitorPrice || changedIgnoreRules || changedMonitorPriceDecrase || changedPriceDecrasePercentage || changedMinQuantity || changedOnlyPrime
    ) &&
    ([changedMarkup, changeProfit, changedPrice, changeIncreaseMarkup].filter(x => x === true).length <= 1)
    ;
  const FixPrice = (newPrice: number) => {
    return Math.max(0,newPrice);
  };
  const FixMarkup = (m: number | undefined) => {
    if (m == null)
      return m;
    return Math.max(m, 0);
  };
  //const CalculatePriceFromMarkup = (l: ActiveListingExtended, newMarkup: number) => FixPrice(l, Math.floor(100 * (l.sourcePrice * ((100 + newMarkup) / 100))) / 100);
  //const CalculateMarkupFromPrice = (l: ActiveListingExtended, newPrice: number) => FixMarkup(Math.round(100 * (newPrice / l.sourcePrice - 1)));
  const HandleSave = async () => {
    const chunk = 10;
    SetSavingChanges(true);
    SetBlockSaving(true);
    for (let i = 0; i < listings.length; i += chunk) {
      SetSavingProgress(100 * i / listings.length);
      const sListings = listings.slice(i, i + chunk);

      let dataByListing: {
        [id: number]: {
          markup?: SaveProductChangeNumber;
          price?: SaveProductChangeNumber;
        }
      } | undefined;
      if (changedMarkup || changedPrice || changeIncreaseMarkup || changeProfit) {
        dataByListing = {};
        if (changedMarkup) {
          for (const l of sListings) {
            const newM = newMarkup?.value as number | undefined;
            dataByListing[l.channelListingId] = {
              markup: { value: FixMarkup(newM) },
              price: { value: FixPrice(ListingsUtils.CalculateSellPriceFromMarkup(l.sourcePrice, newM ?? l.markup ?? 0, l.sourceSettings?.ending99 ?? false)) }
            };
          }
        } else if (changedPrice) {
          for (const l of sListings) {
            const newP = FixPrice(newPrice.value as number | undefined ?? 0);
            dataByListing[l.channelListingId] = {
              markup: { value: FixMarkup(ListingsUtils.CalculateMarkupFromSellPrice(newP, l.sourcePrice)) },
              price: { value: newP }
            };
          }
        } else if (changeIncreaseMarkup) {
          for (const l of sListings) {
            const newM = (l.markup ?? 0) + (newIncreaseMarkup?.value as number | undefined ?? 0);
            dataByListing[l.channelListingId] = {
              markup: { value: FixMarkup(newM) },
              price: { value: FixPrice(ListingsUtils.CalculateSellPriceFromMarkup(l.sourcePrice, newM, l.sourceSettings?.ending99 ?? false)) }
            };
          }
        } else if (changeProfit) {
          for (const l of sListings) {

            const newP = l.sourcePrice && newProfit.value ? ListingsUtils.CalculateSellPriceFromProfit(l.sourcePrice, newProfit.value, l.sourceSettings?.feePercentage ?? 0, l.sourceSettings?.ending99 ?? false) : l.channelPrice;
            const markup =
              l.sourcePrice && newProfit.value
                ? ListingsUtils.CalculateMarkupFromSellPrice(l.sourcePrice, newP)
                : l.markup;

            dataByListing[l.channelListingId] = {
              markup: { value: FixMarkup(markup) },
              price: { value: FixPrice(newP) }
            };
          }
        }
      }

      const changes: SaveMultipleProductChangesRequest = {
        listingIds: sListings.map(x => x.channelListingId),
        dataByListing: dataByListing,

        //Main
        quantity: changedQuantity ? { value: newQuantity.value as number | undefined } : undefined,
        //Options
        monitorStock: changedMonitorStock ? { value: newMonitorStock.value as boolean | undefined } : undefined,
        monitorPrice: changedMonitorPrice ? { value: newMonitorPrice.value as boolean | undefined } : undefined,
        ignoreRules: changedIgnoreRules ? { value: newIgnoreRules.value as boolean | undefined } : undefined,
        monitorPriceDecrease: changedMonitorPriceDecrase ? { value: newMonitorPriceDecrease.value as boolean | undefined } : undefined,
        priceDecreasePercentage: changedPriceDecrasePercentage ? { value: newPriceDecreasePercentage.value as number | undefined } : undefined,
        minQuantity: changedMinQuantity ? { value: newMinQuantity.value as number | undefined } : undefined,
        primeOnly: changedOnlyPrime ? { value: newOnlyPrime.value as boolean | undefined } : undefined,
        //Business policies
        shippingPolicy: changedShippingProfileId ? { value: shippingProfileId.value as number | undefined } : undefined,
        returnPolicy: changedReturnProfileId ? { value: returnProfileId.value as number | undefined } : undefined,
        paymentPolicy: changedPaymentProfileId ? { value: paymentProfileId.value as number | undefined } : undefined
      };
      await dispatch(SaveMultipleListingChanges(changes));
    }
    SetBlockSaving(false);
  };

  const HandleTerminate = () => SetConfirmatingTerminate(true);
  const HandleTerminateConfirmed = async () => {
    SetTerminating(true);
    SetBlockSaving(true);
    for (let i = 0; i < listings.length; i++) {
      SetSavingProgress(100 * i / listings.length);
      const l = listings[i];
      await dispatch(TerminateListing({ id: l.channelListingId }));
    }
    SetBlockSaving(false);
  };
  const HandleTerminatedCanceled = () => SetConfirmatingTerminate(false);
  const HandleRefresh = () => {
    SetRefreshing(true);
    dispatch(ForceRefresh({
      ids:
        listings.map(x => x.channelListingId)
    }));
  };

  const RenderLoadingModal = () => {
    if (saving == null)
      return <></>;

    const HandleClose = () => {
      if (saving != null && !saving.loading && saving.success == false) {//Error
        location.reload();
        return;
      }

      SetSavingChanges(false);
      SetRefreshing(false);
      if (terminating)
        onClose();
      SetTerminating(false);
    };

    return (
      <LoadingModal
        visible={savingChanges || terminating || refreshing}
        loading={saving.loading || blockSaving}
        title={refreshing ? <T k="Setting.RefreshingListing" /> : (terminating ? <T k="Setting.TerminatingListingMultiple" /> : <T k="Setting.SavingChanges" />)}
        onClose={HandleClose}
        progress={savingProgress}
        result={saving.success ? (refreshing ? <T k="Setting.Refreshed" /> : (terminating ? <T k="Setting.TerminatedMultiple" /> : <T k="Setting.Saved" />)) : <T k="Setting.ErrorSavingUnknownError" />}
      />
    );
  };
  const RenderConfirmationModal = () => {
    return (
      <QuestionModal
        visible={confirmatingTerminate}
        onOk={HandleTerminateConfirmed}
        onCancel={HandleTerminatedCanceled}
      >
        <T k="Setting.ConfirmationTerminateMultiple" />
      </QuestionModal>
    );
  };

  //-------------------------------------------------------------------------------------------------------
  const RenderContent = (index: Tab): React.ReactNode => {
    if (!listings[0].sourceSettings)
      return <></>;

    switch (index) {
      case Tab.Main:
        return <EditMultipleListingMain
          onIncreaseMarkupChange={SetNewIncreaseMarkup}
          onMarkupChange={SetNewMarkup}
          onPriceChange={SetNewPrice}
          onProfitChange={SetNewProfit}
          onQuantityChange={SetNewQuantity}

          valueIncreaseMarkup={newIncreaseMarkup}
          valueMarkup={newMarkup}
          valuePrice={newPrice}
          valueProfit={newProfit}
          valueQuantity={newQuantity}

          commonMarkup={commonMarkup}
          commonPrice={commonPrice}
          commonProfit={commonProfit}
          commonQuantity={commonQuantity}

          listingsBInfo={listings.map(x => ({ listingId: x.channelListingId, productSourceId: x.productSourceId }))}
        />;
      case Tab.Options:
        return <ListingMultipleOptions
          onIgnoreRulesChange={SetNewIgnoreRules}
          onMinQuantityChange={SetNewMinQuantity}
          onMonitorPriceChange={SetNewMonitorPrice}
          onMonitorPriceDecreaseChange={SetMonitorPriceDecrease}
          onMonitorStockChange={SetNewMonitorStock}
          onPriceDecreasePercentageChange={SetNewPriceDecreasePercentage}
          onPrimeOnlyChange={SetNewOnlyPrime}
          showPrimeOption={thereArePrime}

          valueIgnoreRules={newIgnoreRules}
          valueMinQuantity={newMinQuantity}
          valueMonitorPrice={newMonitorPrice}
          valueMonitorPriceDecrease={newMonitorPrice}
          valueMonitorStock={newMonitorStock}
          valuePriceDecreasePercentage={newPriceDecreasePercentage}
          valuePrime={newOnlyPrime}
        />;
      case Tab.Business:
        return <ListingMultipleBusiness
          onChangePaymentProfileId={SetPaymentProfileId}
          onChangeReturnProfileId={SetReturnProfileId}
          onChangeShippingProfileId={SetShippingProfileId}

          valuePaymentProfileId={paymentProfileId}
          valueReturnProfileId={returnProfileId}
          valueShippingProfileId={shippingProfileId}
        />;
      default:
        return <></>;
    }
  };

  const tabsL = [
    {
      label: <T k='Listings.Edit.Main' />,
      key: Tab.Main.toString()
    },
    //{
    //  label: <TTag lKey='Listings.Edit.Description' />,
    //  key: Tab.Description.toString()
    //},
    {
      label: <T k='Listings.Edit.Options' />,
      key: Tab.Options.toString()
    }
  ];
  let useBusinessPolicies = true;
  for (const l of listings) {
    if (!l.sourceSettings?.useBusinessPolicies) {
      useBusinessPolicies = false;
      break;
    }
  }
  if (platform == ePlatform.eBay && useBusinessPolicies) {
    tabsL.push({
      label: <T k='Listings.Edit.BusinessPolicies' />,
      key: Tab.Business.toString()
    });
  }

  return (
    <div className="edit-listing">
      <div className="upper-section">
        <h3>Editing multiple products</h3>
      </div>

      <Tabs
        activeKey={activeTab.toString()}
        onChange={(v) => SetActiveTab(parseInt(v) )}
        items={tabsL}
      />

      <div className="content">{RenderContent(activeTab)}</div>
      <div className="btns-container">
        <DeleteBtn onClick={HandleTerminate}>
          <TrashIcon />
          <T k='Listings.Edit.Terminate' />
        </DeleteBtn>
        <WarningBtn onClick={HandleRefresh}>
          <RefreshIcon />
          <T k='Listings.Edit.ForceRefresh' />
        </WarningBtn>
        <SuccessBtn onClick={HandleSave} disabled={!somethingToChange}>
          <CheckIcon />
          <T k='Listings.Edit.Save' />
        </SuccessBtn>
      </div>
      {RenderLoadingModal()}
      {RenderConfirmationModal()}
    </div>
  );
};
