import React, { useState } from 'react';
import { Input } from 'antd';

export interface FieldCardProp {
  label: string | React.ReactNode;
  value: string;
  placeholder?: string;
  onChange: (newValue: string) => void;
  type?: string;
}
const FieldCard = (props: FieldCardProp) => {
  const { label, value, placeholder, onChange, type } = props;

  const [showContent, setShowContent] = useState<boolean>(false);

  let prValue = '';
  if (value) {
    const value2 = value.replace(/[\s\D]/g, '');
    for (let i = 0; i < value2.length; i++) {
      if (i > 0 && i % 4 == 0) {
        prValue += ' ';
      }
      if (!showContent && i < value2.length - 4) {
        prValue += '*';
      } else {
        prValue += value2[i];
      }
    }
  }

  return (
    <div className={'acc-input-parent width-med'}>
      <label className="account-label-style" htmlFor="">
        {label}
      </label>
      <Input
        onFocus={() => setShowContent(true)}
        onBlur={() => setShowContent(false)}
        className="account-input-style blue-input"
        type={type ?? 'text'}
        placeholder={placeholder}
        value={prValue}
        onChange={(r) => onChange(r.currentTarget.value.replace(/[\s\D]/g, '').substring(0, 19))}
      />
    </div>
  );
};
export default FieldCard;
