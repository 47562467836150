import { Form, Input, Checkbox } from 'antd';
import { useHistory } from 'react-router-dom';
import { PrimaryBtn, SecondaryBtn } from '../../../small-components/ActionBtns';
import { useAppDispatch, useAppSelector } from '../../../custom-hooks/reduxCustomHooks';
import { RegisterUser } from 'src/redux/user/userThunk';
import { UserData } from '../../../redux/user/userSlice';
import '../../../sass/user-register.scss';
import { T } from '../../../utils/transShim';
import { Links } from '../../../links';
import { A } from '../../../small-components/A';
import { getChannels } from '../../../redux/channels/channelsThunk';
import { Product } from '../../../redux/subscriptions/subsSlice';
import { BillingPeriod, PaymentPlatform } from '../../subscriptions/models/types';
import { ReactUtils } from '../../../utils/react-utils';
import { StoredCheckout } from '../../../redux/checkout/data/stored-ids';
import { SaveCart } from '../../../redux/checkout/checkoutThunk';
import { eCurrency } from '../../../types/eCurrency';

interface Props {
  firstTime: boolean;
  setFirstTime: (b: boolean) => void;
  plan: Product;
  currency: eCurrency;
  period: BillingPeriod;
  loading: boolean;
  hidden?: boolean;
}

export const UserRegisterForm = (props: Props) => {
  const { firstTime, setFirstTime, plan, currency, period, hidden } = props;
  const history = useHistory();

  const { loading, registerErrors } = (() => {
    const { loading, registerAnswer } = useAppSelector((state) => state.user);

    if (firstTime) {
      return {
        loading: false,
        registerErrors: []
      };
    }

    const errors = [];
    if (registerAnswer?.data?.response_errors?.error) {
      if (Array.isArray(registerAnswer.data.response_errors?.error)) {
        for (const g of registerAnswer.data.response_errors?.error) {
          errors.push(g);
        }
      } else {
        errors.push(registerAnswer.data.response_errors?.error);
      }
    }
    if (registerAnswer?.data?.error) {
      if (Array.isArray(registerAnswer.data.error)) {
        for (const g of registerAnswer.data.error) {
          errors.push(g);
        }
      } else {
        errors.push(registerAnswer.data.error);
      }
    }
    if (registerAnswer?.data?.errors) {
      for (const n in registerAnswer.data.errors) {
        errors.push({ code: n, description: registerAnswer.data.errors[n]?.[0] });
      }
    }

    return {
      loading,
      registerErrors: errors
    };
  })();

  const dispatch = useAppDispatch();
  const OnFinish = async (values: UserData) => {
    const st: StoredCheckout = {
      products: [plan.id],
      currency: currency,
      period: period,
      platform: PaymentPlatform.Stripe
    };
    await dispatch(SaveCart(st));

    const refDate = localStorage.getItem('referralDate');
    let ref: string | null = null;
    try {
      if (refDate && new Date().getTime() - new Date(refDate).getTime() < 1000 * 60 * 60 * 24 * 7)
        ref = localStorage.getItem('referral');
    } catch (ex) {
      console.log(ex);
    }

    setFirstTime(false);

    const rp = await dispatch(RegisterUser({ ...values, referral: ref ?? undefined }));
    if ((rp.payload as { status: number })?.status == 201) {
      const c = window.setInterval(() => {
        if (window.gtag) {
          clearInterval(c);
          window.gtag('event', 'conversion', { send_to: 'AW-746219874/0egUCIn3_tABEOLS6eMC' });
        }
      }, 100);
      await dispatch(getChannels());
      history.push(Links.Checkout.PaymentMethod);
    }
   
  };

  const ChangePlan = () => {
    history.push(Links.Credentials.Register.Plan);
  };

  let passwordError: string | null = null;
  let emailError: string | null = null;
  let otherError: string | null = null;
  let nameError: React.ReactNode | null = null;
  if (registerErrors?.length > 0) {
    for (const re of registerErrors) {
      switch (re.code) {
        case 'Name':
          nameError = <T k="Register.NameError" />;
          break;
        case 'DuplicateUserName':
          emailError = re.description;
          break;
        case 'Password':
          passwordError = re.description;
          break;
        case 'PasswordRequiresDigit':
          passwordError = re.description;
          break;
        case 'PasswordRequiresUpper':
          passwordError = re.description;
          break;
        case 'PasswordRequiresLower':
          passwordError = re.description;
          break;
        case 'PasswordRequiresNonAlphanumeric':
          passwordError = re.description;
          break;
        default:
          otherError = re.description;
          break;
      }
    }
  }

  let selectedPrice = plan.prices.find(x => x.currencyId == currency && x.platformId == PaymentPlatform.Stripe && x.billingPeriodId ==period)?.price ?? 24;
  if (period == BillingPeriod.Yearly)
    selectedPrice = selectedPrice / 12;

  const style = hidden ? { display: 'none' } : {};

  return <Form
    style={style}
    className="register-form"
    layout="vertical"
    name="basic"
    initialValues={{ terms: true }}
    onFinish={OnFinish}
    autoComplete="off"
  >
    <Form.Item
      label={<T k="Register.EmailAddress" />}
      name="email"
      validateStatus={emailError ? 'error' : undefined}
      help={emailError}
      rules={[
        { required: true, message: <T k="Register.InputEmail" /> },
        { type: 'email', message: <T k="Register.InputEmail" /> }
      ]}
      normalize={(value) => value.trim()}
    >
      <Input className="auth-input" />
    </Form.Item>

    <Form.Item
      label={<T k="Register.Name" />}
      name="Name"
      validateStatus={nameError ? 'error' : undefined}
      help={nameError}
      rules={[{ required: true, message: <T k="Register.InputName" /> }]}
    >
      <Input className="auth-input" />
    </Form.Item>

    <Form.Item
      label={<T k="Register.Password" />}
      name="password"
      validateStatus={passwordError ? 'error' : undefined}
      help={passwordError}
      rules={[{ required: true, message: <T k="Register.InputPassword" /> }]}
    >
      <Input.Password className="auth-input" />
    </Form.Item>

    <Form.Item
      label={<T k="Register.SelectPlan" />}
    >
      <input type="hidden" value="_"/>
      <SecondaryBtn onClick={ChangePlan} className="selected-plan">
        {plan.productOrder}&nbsp;<T k="Register.PlanLineQuantity" />
        &nbsp;|&nbsp;
        {period == BillingPeriod.Monthly && <T k='Checkout.BillMonthly' />}
        {period == BillingPeriod.Yearly && <T k='Checkout.BillYearly' />}
        &nbsp;|&nbsp;
        {ReactUtils.DrawPrice(selectedPrice, currency, { decimals: { force: false } })}<T k='Checkout.perMonth.short' />
      </SecondaryBtn>
      <div className="refund"><T k='Register.Refund' /></div>
    </Form.Item>

    <Form.Item name="terms" valuePropName="checked">
      <Checkbox className="checkbox">
        <T k="Register.IAccept" />{' '}
        <span>
          <A target={'_blank'} rel="noreferrer" to="https://hustlegotreal.com/en/hustle-got-real-terms-and-conditions/">
            <T k="Register.TermansAndConditions" />
          </A>
        </span>
      </Checkbox>
    </Form.Item>

    <Form.Item name="offers" valuePropName="checked">
      <Checkbox className="checkbox">
        <T k="Register.PromotingLine" />
      </Checkbox>
    </Form.Item>

    <Form.Item validateStatus={otherError ? 'error' : undefined} help={otherError}>
      <PrimaryBtn htmlType="submit" disabled={loading}>
        {loading ? <T k="Register.Waiting" /> : <T k="Register.Register" />}
      </PrimaryBtn>
    </Form.Item>
  </Form>;

};
