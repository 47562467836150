import { ReactNode } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { PlatformInfo } from '../data/platforms';
import { Links } from '../links';
import { A } from '../small-components/A';

export type TransValue = string | ReactNode;
export type TransValueTypeValue = PrimitiveType | ReactNode;

const ErroredTag = (k: string) => {
  const ps = k?.split('.');
  return ps?.[(ps?.length - 1) ?? 0];
};

const AcceptedTags = {
  'b': (x: string) => <b>{x}</b>,
  'i': (x: string) => <i>{x}</i>,
  'p': (x: string) => <p>{x}</p>,
  'strong': (x: string) => <strong>{x}</strong>,
  'span': (x: string) => <span>{x}</span>,
  'linebreak': <br/>,
  'a': (x: string) => {
    try {
      const props: {[n:string]:string} = {};
      const g = x[0].split('|');
      let children: string | undefined= undefined;
      for (const p of g) {
        const vs = p.split(':');
        if (vs[0] != 'children')
          props[vs[0]] = vs[1].replace('&#58;',':');
        else
          children = vs[1];
      }
      return <a {...props}>{children}</a>;
    } catch (ex) {
      return <a>{x}</a>;
    }
  },
  'link': (x: string) => {
    try {
      const props: { [n: string]: string | boolean } = {};
      const g = x[0].split('|');
      let children: string | undefined = undefined;
      for (const p of g) {
        const vs = p.split(':');
        if (vs[0] != 'children') {
          let r: string | boolean = vs[1].replace('&#58;', ':');
          if (r == '@true')
            r = true;
          else if (r == '@false')
            r = false;
          props[vs[0]] = r;
        } else {
          children = vs[1];
        }
      }
      const ps = { to: '#', ...props };
      return <A {...ps}>{children}</A>;
    } catch (ex) {
      return <A to='#' >{x}</A>;
    }
  }
};

export function useTranslation(key: string, values?: Record<string, TransValueTypeValue>): TransValue {
  try {
    if (!key) {
      return '';
    }

    const intl = useIntl();
    const v = intl.formatMessage(
      {
        id: key,
        description: '', // Description should be a string literal
        defaultMessage: '{errorKey}' // Message should be a string literal
      },
      { ...values, key, errorKey: { key }, ...AcceptedTags } // Values should be an object literal, but not necessarily every value inside
    );

    return v;
  } catch (ex) {
    console.log(ex);
    return ErroredTag(key);
  }
}

export const T = (props: { k: string, values?: Record<string, TransValueTypeValue> }) => {
  if (!props.k)
    return <></>;
  try {
    return <FormattedMessage
      id={props.k}
      description=''
      defaultMessage='{errorKey}'
      values={{ ...props.values, key: props.k, errorKey: ErroredTag(props.k), ...AcceptedTags }}
    />;
  } catch (ex) {
    console.log(ex);
    return <>ErroredTag(props?.k)</>;
  }
};

export interface TransPlatformValues extends Record <string, TransValueTypeValue> {
  channel_platform_name: TransValueTypeValue,
  channel_noapi_username: TransValueTypeValue
}
export interface TransLinksValues extends Record<string, TransValueTypeValue> {
  sources_table_link: TransValueTypeValue,
  templates_link: TransValueTypeValue
}

export const TransUtils = {
  GetPlatformValues: (platform: PlatformInfo): TransPlatformValues => {
    return {
      channel_platform_name: platform.storeName,
      channel_noapi_username: <T k={'Platform.' + platform.storeName + '.NoApi.Username'}/>
    };
  },
  GetLinksValues: () => {
    return {
      sources_table_link: <A to={Links.Configuration.Sources.Main}><T k='Menu.SourcesTable'/></A>,
      templates_link: <A to={Links.Configuration.Templates.Main}><T k='Menu.Templates'/></A>,
    };
  }
};

