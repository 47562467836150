import { UseInterval } from '../../small-components/react-addons/use-interval';
import { UseStateRemainder } from '../../small-components/react-addons/use-state-remainder';

export interface QuoteSliderData {
  graph?: React.ReactNode;
  quote: React.ReactNode;
  photo: string;
  name: string;
}

interface Props {
  quotes: QuoteSliderData[],
  seconds: number;
}

export const QuoteSlider = (props: Props) => {

  const [quoteIndex, SetQuoteIndex] = UseStateRemainder(0, props.quotes.length);
  
  UseInterval(() => {
    SetQuoteIndex(quoteIndex + 1);
  }, props.seconds * 1000);

  const RenderQuote = (q: QuoteSliderData, i: number) => {
    return <div className="quote-ct" key={i} style={{ opacity: (i == quoteIndex ? 1 : 0) }}>
      {q.graph && <div className="quote-graph">{q.graph}</div>}
      <div className="quote-quote">
        {/*quoteIndex as key to force restart when we change quote*/}
        {/*<TypeWriter key={quoteIndex} text={'"' + q.quote + '"'} />*/}
        {q.quote}
      </div>
      <div className="quote-photo"><img src={q.photo}/></div>
      <div className="quote-name">{q.name}</div>
    </div>;
  };

  return <div className="quote-slider">
    {props.quotes.map(RenderQuote)}
  </div>;
};