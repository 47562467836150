import { createAsyncThunk } from '@reduxjs/toolkit';
import { Category } from '../../components/extension/new-listing-form/category';
import { ChannelSpecificField, Suggestion } from '../../components/extension/new-listing-form/channel-specific-field';
import { ComListing, ComProduct, FeaturesTableRow } from '../../components/extension/new-listing-form/com-listing';
import { eListingErrorFlags } from '../../components/extension/new-listing-form/eListingErrorFlags';
import { eCountry } from '../../data/countries';
import { client } from '../client';
import { ErrorType } from '../error-type';
import { ComputedSettingsData } from '../source-configuration/types';
import { Template } from '../templates/templatesSlice';
import { DebugData } from './manual-listing-slice';

export enum Problem {
  ForbiddenWordsInUrl = 1,
  SubscriptionLimit = 2,
  DuplicatedItem = 3
}

export type NullableString = string | null | undefined;

export type CategorySuggestionsResponse = {
  categories: Category[];
  errorFlag: eListingErrorFlags;
  errorMessage: NullableString;
}

export type ScrapingField<T> = {
  selector: NullableString,
  selectorAttribute: NullableString,
  type: NullableString,
  value: T
}

export type ScrapingJsonEntry = {
  begin: NullableString,
  end: NullableString,
  value: NullableString
}

export type ScrapingJson = {
  selector: ScrapingJsonEntry[],
  type: NullableString
}

export type ScrapingDataField = {
  key: NullableString,
  values: ScrapingField<NullableString[]>
}

export type ScrapingDataFields = {
  data: ScrapingDataField[],
  type: NullableString
}

export type ScrapingDictionary = {
  keyAttribute: NullableString,
  keySelector: NullableString,
  type: NullableString,
  value: { [key: string]: NullableString },
  valueAttribute: NullableString,
  valueSelector: NullableString
}

export type ScrapingConfiguration = {
  //cookies: { [key: string]: NullableString }[],
  cultureInfoCode: NullableString,
  brand: ScrapingField<NullableString[]>,
  dataFields: ScrapingDataFields,
  ean: ScrapingField<NullableString[]> ,
  features: ScrapingField<NullableString[]> ,
  images: ScrapingField<NullableString[]>,
  json: ScrapingJson,
  mpn: ScrapingField<NullableString[]>,
  paragraphs: ScrapingField<NullableString[]>,
  price: ScrapingField<NullableString[]>,
  productId: ScrapingField<NullableString[]>,
  rows: ScrapingDictionary,
  title: ScrapingField<NullableString>,
  categories: ScrapingField<NullableString[]>,
  links: ScrapingField<NullableString[]>
}

//TODO: Remove plain functions when remaking the manual listing 
export const GetSettingsPlain = async (sourceId: number, sourcePrice: number) => (await client.get('ChannelListing/GetSettings?sourceId=' + sourceId + '&sourcePrice=' + sourcePrice)).data.response_data as ComputedSettingsData;
export const IsBrandInVeRoListPlain = async (brand: string) => (await client.get('ChannelListing/IsBrandInVeRoList?brand=' + brand)).data.response_data?.success as boolean | undefined;
export const GetCustomCategoriesPlain = async () => (await client.get('ChannelListing/GetCustomCategories')).data.response_data as Category[];
export const GetSuggestedCategoriesPlain = async (url: string, text: string) => (await client.post('ChannelListing/GetSuggestedCategories', { url, text })).data.response_data as CategorySuggestionsResponse;
export const GetCategoryInfoPlain = async (id: string) => (await client.get('ChannelListing/GetCategoryInfo?id=' + id)).data.response_data as Category;
export const GetCategoriesByTextPlain = async (text: string) => (await client.post('ChannelListing/GetCategoriesByText', { text })).data.response_data as CategorySuggestionsResponse;
export const GetCategorySpecificsPlain = async (
  sourceId: number,
  categoryId: string,
  originPrice: number,
  title: string,
  paragraphs: string[],
  features: string[],
  tableValues: { [name: string]: string } | null
) => (await client.post('ChannelListing/GetCategorySpecifics', { sourceId, categoryId, originPrice, title, paragraphs, features, tableValues })).data.response_data as ChannelSpecificField[];
export const GetSuggestedTitlesPlain = async (categoryId: string, product: ComProduct) => (await client.post('ChannelListing/GetSuggestedTitles', { categoryId, product })).data.response_data as Suggestion;
export const SubmitListingPlain = async (d: ComListing) => (await client.post('ChannelListing/SubmitListing', d)).data.response_data as { success: boolean, error: string };
export const ClearVariationsPlain = async () => (await client.post('ChannelListing/ClearVariations', null)).data.response_data as { success: boolean, error: string };
export const ClearOneVariationPlain = async (channelListingId: number) => (await client.post('ChannelListing/ClearOneVariation', { channelListingId })).data.response_data as { success: boolean, error: string };
export const OptimiseTitlePlain = async (d: { listingId: number | undefined, productSourceId: number }) => (await client.post('SearchProduct/Optimize', { ...d, OptimizeTitle: true })).data.response_data as { success: boolean, error: string, title: string };
export const OptimiseDescriptionPlain = async (d: { listingId: number | undefined, productSourceId: number }) => (await client.post('SearchProduct/Optimize', { ...d, OptimizeDescription: true })).data.response_data as { success: boolean, error: string, paragraphs: string[], properties: string[], tableValues: FeaturesTableRow[] };

export const GetTemplatesPlain = async () => (await client.get('/Templates/List')).data.response_data as Template[];


export const GetSettings = createAsyncThunk('ChannelListing/GetSettings', async (data: { sourceId: number, sourcePrice: number }, thunkAPI) => {
  try {
    const res = await client.get('ChannelListing/GetSettings?sourceId=' + data.sourceId + '&sourcePrice=' + data.sourcePrice);
    return res.data.response_data as ComputedSettingsData;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const IsBrandInVeRoList = createAsyncThunk('ChannelListing/IsBrandInVeRoList', async (brand: string, thunkAPI) => {
  try {
    const res = await client.get('ChannelListing/IsBrandInVeRoList?brand=' + brand);
    return res.data.response_data?.success as boolean | undefined;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const GetCustomCategories = createAsyncThunk('ChannelListing/GetCustomCategories', async () => {
  try {
    const res = await client.get('ChannelListing/GetCustomCategories');
    return res.data.response_data as Category[];
  } catch (error) {
    return [];
    //return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const GetSuggestedCategories = createAsyncThunk('ChannelListing/GetSuggestedCategories', async (d: { url: string; text: string }, thunkAPI) => {
  try {
    const res = await client.post('ChannelListing/GetSuggestedCategories', { url: d.url, text: d.text });
    return res.data.response_data as CategorySuggestionsResponse;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const GetCategoryInfo = createAsyncThunk('ChannelListing/GetCategoryInfo', async (id: string, thunkAPI) => {
  try {
    const res = await client.get('ChannelListing/GetCategoryInfo?id=' + id);
    return res.data.response_data as Category;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const GetCategoriesByText = createAsyncThunk('ChannelListing/GetCategoriesByText', async (text: string, thunkAPI) => {
  try {
    const res = await client.post('ChannelListing/GetCategoriesByText', { text });
    return res.data.response_data as CategorySuggestionsResponse;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const GetCategorySpecifics = createAsyncThunk('ChannelListing/GetCategorySpecifics', async (
  d: {
    sourceId: number,
    categoryId: string,
    originPrice: number,
    title: string,
    paragraphs: string[],
    features: string[],
    tableValues: { [name: string]: string } | null
  }, thunkAPI
) => {
  try {
    const res = await client.post('ChannelListing/GetCategorySpecifics', d);
    return res.data.response_data as ChannelSpecificField[];
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const GetSuggestedTitles = createAsyncThunk('ChannelListing/GetSuggestedTitles', async (d: { categoryId: string, product: ComProduct }, thunkAPI) => {
  try {
    const res = await client.post('ChannelListing/GetSuggestedTitles', d);
    return res.data.response_data as Suggestion;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const SubmitListing = createAsyncThunk('ChannelListing/SubmitListing', async (d: ComListing, thunkAPI) => {
  try {
    const res = await client.post('ChannelListing/SubmitListing', d);
    return res.data.response_data as { success: boolean, error: string };
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const ClearVariations = createAsyncThunk('ChannelListing/ClearVariations', async (d: { channelListingId: number } | undefined, thunkAPI) => {
  try {
    const res = await client.post('ChannelListing/ClearVariations', null);
    return res.data.response_data as { success: boolean, error: string };
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const ClearOneVariation = createAsyncThunk('ChannelListing/ClearOneVariation', async (channelListingId: number, thunkAPI) => {
  try {
    const res = await client.post('ChannelListing/ClearOneVariation', { channelListingId });
    return res.data.response_data as { success: boolean, error: string };
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const ProcessListData = createAsyncThunk('ChannelListing/List', async (d: { url: string | null, p: string | null }, thunkAPI) => {
  try {
    const res = await client.post('ChannelListing/List', { url: d.url, extraData: d.p });
    return res.data.response_data as {
      success: boolean,
      debug?: DebugData,
      errorType?: ErrorType,
      problems: Problem,
      comListing: ComListing
    };
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});

export const GetScrapingConfig = createAsyncThunk('Listing/GetScrapingConfig', async (d: { url: string | null, site?: eCountry }, thunkAPI) => {
  try {
    const res = await client.post('Listing/GetScrapingConfig', d);
    return res.data.response_data as ScrapingConfiguration;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});