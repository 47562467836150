import { PrimaryBtn, SecondaryBtn } from '../../../small-components/ActionBtns';
import { T } from '../../../utils/transShim';

interface props {
  onContinue: (expert?: boolean) => void;
}

export const Expert = (props: props) => {
  return <div className="container-info">
    <h2 className="title"><T k="NewChannel.Expert.Question" /></h2>
    <p><T k="NewChannel.Expert.Description" /></p>
    <div className="buttons">
      <PrimaryBtn onClick={_ => props.onContinue(false)}><T k="NewChannel.Expert.No" /></PrimaryBtn>
      <PrimaryBtn onClick={_ => props.onContinue(true)}><T k="NewChannel.Expert.Yes" /></PrimaryBtn>
    </div>
    <br />
    <br />
    <div className="buttons">
      <SecondaryBtn onClick={_ => props.onContinue(undefined)}><T k="NewChannel.Expert.NoAnswer" /></SecondaryBtn>
    </div>
  </div>;
};