import '../../sass/subscriptions/subscriptions.scss';
import successLogo from '../../assets/success.svg';
import { PrimaryBtn } from 'src/small-components/ActionBtns';
import { Links } from 'src/links';

import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { getServices, getSubscriptions } from '../../redux/subscriptions/subsThunk';
import { eCurrency } from '../../types/eCurrency';
import { A } from '../../small-components/A';

export const Success = () => {
  const dispatch = useAppDispatch();
  const { products } = useAppSelector((state) => state.subscriptions);
  const { services } = useAppSelector((state) => state.services);

  useEffect(() => {
    dispatch(getSubscriptions());
    dispatch(getServices());
  }, [getServices, getSubscriptions]);

  const productId = localStorage.getItem('productId');
  const billingId = localStorage.getItem('billing');
  const currencyId = localStorage.getItem('currencyId');

  useEffect(() => {
    if (!services || !products || !productId || !billingId || !currencyId) return;

    const prd = [...services, ...products].find((x) => x.id == parseInt(productId));
    if (!prd?.prices) return;

    const price = prd.prices.find((x) => x.currencyId == parseInt(currencyId) && x.billingPeriodId == parseInt(billingId));
    if (!price?.price) return;

    const c = window.setInterval(() => {
      if (window.gtag) {
        clearInterval(c);
        window.gtag('event', 'conversion', {
          send_to: 'AW-746219874/u1-aCJqloYIYEOLS6eMC',
          value: price.price,
          currency: eCurrency[parseInt(currencyId) as eCurrency],
          billingId: billingId,
          productId: productId,
          transaction_id: ''
        });
      }
    }, 100);

    return () => clearInterval(c);
  }, [services, products]);

  return (
    <div className="payment-container">
      <div className="payment-box">
        <img className="payment-logo" src={successLogo} alt="" />
        <div className="box-logo">
          <h1 className="title-payment">Thank you, the order is processed!</h1>
          <h5 className="description-payment">Your payment has been successfully processed and we will send you a confirmation email.</h5>
          <div className="button-container">
            <A to={Links.Dashboard}>
              <PrimaryBtn className="payment-button">Great!</PrimaryBtn>
            </A>
          </div>
        </div>
      </div>
    </div>
  );
};
