import { Spin } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../custom-hooks/reduxCustomHooks';
import { Links } from '../../../links';
import { CatalogProduct, CatalogStatusData, ErrorWhy } from '../../../redux/catalog/catalogSlice';
import { CatalogOrigin, GetBulkListingStatus, GetDashboardSuggestedProducts, ListProducts } from '../../../redux/catalog/catalogThunk';
import { eChannelListingStatus } from '../../../redux/listings/listings-slice';
import { getSources } from '../../../redux/sources/sourcesThunk';
import { A } from '../../../small-components/A';
import { PrimaryBtn } from '../../../small-components/ActionBtns';
import { PopupModal } from '../../../small-components/modals/popup-modal';
import { ReactUtils } from '../../../utils/react-utils';
import { toastAlert } from '../../../utils/toastAlert';
import { T } from '../../../utils/transShim';
import { OnListedData } from '../../catalog/Catalog';
import { CatalogCard } from '../../catalog/catalog-card';

type Props = {
  disabled?: boolean;
  onList: () => void;
};

export const SuggestedProducts = (props: Props) => {
  const { disabled, onList } = props;

  const dispatch = useAppDispatch();
  const updateFrequencyMs = 12000;

  const { notifications, loading: loadingNotifications } = useAppSelector((x) => x.notifications);

  const channel = ReactUtils.GetSelectedChannel();
  const { sources, loading: loadingSources } = useAppSelector((state) => state.sources);
  const { dashboard: dprd, status } = useAppSelector((state) => state.catalogProducts);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [products, SetProducts] = useState<CatalogProduct[]>();
  const [sent, setSent] = useState<boolean>(false);
  const [showConnectExt, SetShowConnectExt] = useState<boolean>(false);

  const loading = loadingSources || dprd?.loading || loadingNotifications;

  useEffect(() => {
    dispatch(GetBulkListingStatus());
    const interval = setInterval(async () => {
      await dispatch(GetBulkListingStatus());
    }, updateFrequencyMs);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    dispatch(GetDashboardSuggestedProducts());
  }, [channel]);

  useEffect(() => {
    if (!!sources && sources.length > 0 && sources[0].site == channel?.isoCountry?.toString()) return;
    if (loadingSources) return;
    dispatch(getSources());
  }, [channel?.id]);

  useEffect(() => {
    if (status?.data?.find((x) => x.listingStatus?.status == eChannelListingStatus.ListingCreatedSuccessfully) != null) onList();
  }, [status?.data]);

  useEffect(() => {
    SetProducts(dprd?.products ?? []);
  }, [dprd?.products]);

  const GetSourceName = (id: number) => sources?.find((x) => x.id == id)?.name;
  const statusDic = useMemo(() => {
    const dic: { [psId: number]: CatalogStatusData } = {};
    for (const p of status?.data ?? []) {
      dic[p.productSourceId] = p;
    }
    return dic;
  }, [status]);

  const ExecutePublishProducts = () => {
    (async () => {
      setSubmitting(true);
      const newProducts = [...(products ?? [])];

      for (const prd of newProducts) {
        const ip = newProducts.findIndex((x) => x.id == prd.id);
        if (ip >= 0) newProducts[ip] = { ...newProducts[ip], beingSend: true };
      }
      SetProducts(newProducts);

      const delaysMap: { [id: number]: Date } = {};
      const productsToList = newProducts.map((e: CatalogProduct) => {
        const { id, title } = e;
        return { id, title };
      });
      const rs = await dispatch(
        ListProducts({
          productsToList,
          needsReview: false,
          optimizeTitle: false,
          optimizeDescription: false,
          publishVariations: false,
          delaysMap,
          origin: CatalogOrigin.FIRST_STEPS
        })
      );
      switch (rs.payload.error) {
        case ErrorWhy.NoError:
          break;
        case ErrorWhy.NoTokens:
          toastAlert("You don't have enough tokens. Refresh the page and try it again", 'error');
          break;
        case ErrorWhy.NoQuota:
          toastAlert("You don't have enough quota. If you think this is wrong, refresh the page and try again", 'error');
          break;
      }

      if (rs.payload.success) {
        const f: OnListedData[] = [];

        for (const prd of newProducts) {
          const pid = prd.id;
          f.push({ pid: pid, key: rs.payload.batchId });

          const ip = newProducts.findIndex((x) => x.id == pid);
          if (ip >= 0) newProducts[ip] = { ...newProducts[ip], beingSend: false, sent: true };
        }
        SetProducts(newProducts);
      } else {
        toastAlert(rs.payload, 'error');
      }
      setSubmitting(false);
      setSent(true);
    })();
  };

  const HandlePublishProducts = () => {
    if (notifications.noApiDisconnected && notifications.noApiDisconnected.find((x) => x.id == channel?.id)) {
      SetShowConnectExt(true);
    } else {
      ExecutePublishProducts();
    }
  };

  const selectedProducts = products?.slice(0, 3);

  if (loading || selectedProducts == null) return <Spin />;

  return (
    <div className="catalog-cards">
      <div className="cards-container-catalog">
        {selectedProducts.map((d: CatalogProduct, i) => (
          <CatalogCard
            key={i}
            className="product-card"
            data={d}
            showCompetition={true}
            selected={false}
            status={statusDic[d.id]}
            sourceName={GetSourceName(d.sourceId) ?? ''}
            currency={ReactUtils.GetCurrencyByCountry(channel?.isoCountry)}
          />
        ))}
      </div>
      <PopupModal
        title={<T k="Dashboard.First.NotConnectedExt.Title" />}
        open={showConnectExt}
        onClose={() => SetShowConnectExt(false)}
        maxWidth={800}
      >
        <p>
          <T k="Dashboard.First.NotConnectedExt.Desc1" values={{ name: channel?.name }} />
        </p>
        <div style={{ textAlign: 'center', width: '100%' }}>
          <A to={Links.HGRNoApiExtension} target="_blank" rel="noreferrer">
            <PrimaryBtn style={{ display: 'inline-flex' }}>
              <T k="Dashboard.First.NotConnectedExt.InstallExt" />
            </PrimaryBtn>
          </A>
        </div>
        <h3>
          <T k="Dashboard.First.NotConnectedExt.Title2" />
        </h3>
        <p>
          <T k="Dashboard.First.NotConnectedExt.Desc2" />
        </p>
        <div style={{ textAlign: 'center', width: '100%' }}>
          <A to={Links.Services.NoApiServer}>
            <PrimaryBtn style={{ display: 'inline-flex' }}>
              <T k="Dashboard.First.NotConnectedExt.HireServer" />
            </PrimaryBtn>
          </A>
        </div>
      </PopupModal>
      <PrimaryBtn disabled={disabled || submitting || sent || (status?.data?.length ?? 0) > 0} onClick={HandlePublishProducts}>
        <T k="Dashboard.First.ListSection.Publish" />
      </PrimaryBtn>
    </div>
  );
};
