import React from 'react';

export type PictureVariationsAttributeOptionPanelProps = {
  option: string;
  onSelect: (option: string) => void;
  selected: boolean;
}
export const PictureVariationsAttributeOptionPanel = (props: PictureVariationsAttributeOptionPanelProps) => {

  const HandleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    props.onSelect(props.option);
  };

  return (
    <button className={'btn' + (props.selected ? ' btn-primary' : '')} onClick={HandleClick}>
      {props.option}
    </button>
  );
};
