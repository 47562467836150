//import { eSmartListerFilterStatus } from '../../redux/smart-lister/data/e-smart-lister-filter-status';
//import { SmartListerFilterType } from '../../redux/smart-lister/data/smart-lister-filter-type';
//import { ChannelSettingKey } from '../../types/settings';
import { Spin } from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { Links } from '../../links';
import { eSmartListerFilterStatus } from '../../redux/smart-lister/data/e-smart-lister-filter-status';
import { SmartListerFilterType } from '../../redux/smart-lister/data/smart-lister-filter-type';
import { SmartListerFiltersNew } from '../../redux/smart-lister/smart-thunk';
import { ChannelSettingKey } from '../../types/settings';
import { OptimiseStep } from './onboarding/optimise-step';
import { SourcesStep } from './onboarding/sources-stept';

export const SmartListerNewFilterOnBoarding = () => {
  const { quota } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { tokens, loading: tokensLoading } = useAppSelector((state) => state.user);
  const { settings, loading: settingsLoading } = useAppSelector((state) => state.channelConfiguration);
  const otpimizeByDefault = (settings?.find(x => x.key == ChannelSettingKey.OptimizeByDefault)?.value ?? '1') == '1';

  const [sourcesIds, SetSourcesIds] = useState<number[]>([]);
  const [step, SetStep] = useState<number>(0);
  const [optimizeTitle, setOptimizeTitle] = useState<boolean>(otpimizeByDefault && (tokens ?? 0) >= 1);
  const [optimizeDescription, setOptimizeDescription] = useState<boolean>(otpimizeByDefault && (tokens ?? 0) >= 3);

  const HandleNext = () => SetStep(1);
  const HandleFinish = async () => {
    const variationsByDefault = (settings?.find(x => x.key == ChannelSettingKey.VariationsByDefault)?.value ?? '1') == '1';
    const filter = {
      filter: {
        id: 0,
        channelOauthId: 0,
        createdOn: new Date(),
        quantityListed: 0,
        status: eSmartListerFilterStatus.Active,
        dontStartUntil: undefined,
        listVariations: variationsByDefault,
        optimizeTitles: optimizeTitle,
        optimizeDescriptions: optimizeDescription,
        quantityByTime: Math.max(1, Math.min(30, Math.round(((quota?.quota ?? 0) - (quota?.used ?? 0)) / 30))),
        timeInterval: 1440,//1440 = days
        quantityMax: undefined,
        sources: sourcesIds,
        onlyPrime: true,
        priceMax: undefined,
        priceMin: undefined,
        profitMax: undefined,
        profitMin: undefined,
        sourcesInclude: undefined,
        titleContains: undefined,
        needsReview: false,
        type: SmartListerFilterType.Smart
      }
    };
    await dispatch(SmartListerFiltersNew(filter));
    history.push(Links.SmartLister.Base);
  };

  if (settingsLoading || tokensLoading)
    return <Spin />;

  if (step == 0)
    return <SourcesStep key={1} onNext={HandleNext} SetSourcesIds={SetSourcesIds} sourcesIds={sourcesIds} />;
  return <OptimiseStep
    onNext={HandleFinish}
    optimizeTitle={optimizeTitle}
    optimizeDescription={optimizeDescription}
    setOptimizeTitle={setOptimizeTitle}
    setOptimizeDescription={setOptimizeDescription}
  />;
};