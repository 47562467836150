// import { SuccessBtn,WarningBtn, CancelBtn } from '../components/small-components/ActionBtns';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  PauseCircleOutlined
} from '@ant-design/icons';
import { AutoOrderingState } from '../components/orders/data/auto-ordering-state';
import { OrderData } from '../redux/orders/orderSlice';
import { SuccessBtn, WarningBtn, ProgressBtn, DangerBtn, CancelBtn } from '../small-components/ActionBtns';
import { T } from './transShim';

export enum OrderStatus {
  Unkown = 0,
  Unshipped = 1,
  Shipped = 2,
  Cancelled = 3
}

export const DetermineStatus = (statusValue: string | number, order: OrderData) => {
  switch (statusValue) {
    case AutoOrderingState.AutoorderingDisabled:
      switch (order.storeStatus) {
        default:
          if (order.sourceAOEnabled) {
            if (order.sourceAOConfigured) {
              return (
                <WarningBtn>
                  <PauseCircleOutlined />&nbsp;
                  <T k='Orders.Status.Paused'/>
                </WarningBtn>
              );
            } else {
              return (
                <WarningBtn>
                  <PauseCircleOutlined />&nbsp;
                  <T k='Orders.Status.AODisabled' />
                </WarningBtn>
              );
            }
          } else {
            return (
              <WarningBtn>
                <ExclamationCircleOutlined />&nbsp;
                <T k='Orders.Status.NoAutoordering' />
              </WarningBtn>
            );
          }
        case OrderStatus.Shipped:
          return (
            <SuccessBtn>
              <CheckCircleOutlined />&nbsp;
              <T k='Orders.Status.Manually'/>
            </SuccessBtn>
          );

        case OrderStatus.Cancelled:
          return (
            <CancelBtn>
              <ExclamationCircleOutlined />&nbsp;
              <T k='Orders.Status.Cancelled' />
            </CancelBtn>
          );
      }
    default: //Including case OrderStatus.TemporaryError
      return (
        <ProgressBtn>
          <LoadingOutlined />&nbsp;
          <T k='Orders.Status.InProgress' />
        </ProgressBtn>
      );
    case AutoOrderingState.CompletedAutoOrderPending:
      return (
        <WarningBtn>
          <CheckCircleOutlined />&nbsp;
          <T k='Orders.Status.Pending' />
        </WarningBtn>
      );
    case AutoOrderingState.Completed:
      if (order.markedAsDispatched) {
        return (
          <SuccessBtn>
            <CheckCircleOutlined />&nbsp;
            <T k='Orders.Status.Dispatched' />
          </SuccessBtn>
        );
      } else {
        return (
          <SuccessBtn>
            <CheckCircleOutlined />&nbsp;
            <T k='Orders.Status.Purchased' />
          </SuccessBtn>
        );
      }
    case AutoOrderingState.ManuallyDispatched:
      return (
        <SuccessBtn>
          <CheckCircleOutlined />&nbsp;
          <T k='Orders.Status.Manually' />
        </SuccessBtn>
      );
    case AutoOrderingState.GoingToBuyError:
    case AutoOrderingState.PermanentError:
      return (
        <DangerBtn>
          <ExclamationCircleOutlined />&nbsp;
          <T k='Orders.Status.ViewError' />
        </DangerBtn>
      );
  }
};
