import TawkMessengerReact, { TawkAPI } from '@tawk.to/tawk-messenger-react';
import { useEffect, useRef, useState } from 'react';

export interface ChatContainerActionsRef {
  maximize?: () => void
}

interface Props {
  onStatusChanged: (enabled: boolean) => void;
  actionsRef: ChatContainerActionsRef;
  hidden?: boolean;
}

export const ChatContainer = (props: Props) => {
  const { onStatusChanged, actionsRef, hidden } = props;

  const tawkMessengerRef = useRef<TawkAPI>();
  const [currentStatus, SetCurrentStatus] = useState<string>('offline');
  const [isOpen, SetIsOpen] = useState<boolean>(false);

  const HandleClick = () => {
    if (!tawkMessengerRef?.current)
      return;
    tawkMessengerRef.current.maximize();
  };

  if (actionsRef)
    actionsRef.maximize = HandleClick;

  const HandleStatusChanged = (status: string) => {
    SetCurrentStatus(status);
    switch (status) {
      case 'online':
        onStatusChanged(true); break;
      case 'offline':
        onStatusChanged(false); break;
    }
  };

  useEffect(() => {
    if (!tawkMessengerRef?.current)
      return;

    if (currentStatus == 'offline')
      return;

    if (isOpen)
      return;

    if (hidden)
      tawkMessengerRef.current.hideWidget();
    else
      tawkMessengerRef.current.showWidget();
  }, [hidden]);

  const HandleLoad = () => {
    if (hidden)
      tawkMessengerRef?.current?.hideWidget();
  };

  const HandleOpen = () => SetIsOpen(true);
  const HandleClose = () => SetIsOpen(false);

  return <TawkMessengerReact
    key='1'
    propertyId="5ebab3f6967ae56c52191547"
    widgetId="default"
    onStatusChange={HandleStatusChanged}
    ref={tawkMessengerRef}
    onLoad={HandleLoad}
    onChatMaximized={HandleOpen}
    onChatMinimized={HandleClose}
  />;

};