/*import { useState } from 'react';*/
/*import { t } from '../../utils/transShim';*/
import { Row, Col } from 'antd';
import { useAppDispatch, useAppSelector } from '../custom-hooks/reduxCustomHooks';
import { ReactChild, ReactFragment, ReactPortal, useEffect } from 'react';
import { getSources } from '../redux/sources/sourcesThunk';
import '../sass/suppliers-list.scss';
import { ReactUtils } from '../utils/react-utils';
import { A } from './A';


interface Props {
  loadSources?: boolean;
}

export const SuppliersList = (props: Props) => {
  const dispatch = useAppDispatch();
  const { sources, loading } = useAppSelector((state) => state.sources);
  const channel = ReactUtils.GetSelectedChannel();

  useEffect(() => {
    if (!(props.loadSources ?? true))
      return;

    if (!!sources && sources.length > 0 && sources[0].site == channel?.isoCountry?.toString())
      return;

    if (loading)
      return;

    dispatch(getSources());
  }, [channel?.id, props.loadSources]);

  return (
    <div className="suppliers-list-container">
      <Row gutter={[24, 8]}>
        {sources?.map(
          (itm: {
            id: number | undefined;
            name: string | undefined;
            baseUrl: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined;
          }) => {
            return (
              <Col lg={6} md={8} sm={12} xs={12} key={itm.id} className="source-card">
                <A to={ReactUtils.GetOutUrl(itm.baseUrl?.toString()) ?? ''} target="_blank" rel="noreferrer">
                  <div className="source-card-inner">
                    <div className="image-container">
                      {loading}
                      {/*eslint-disable @typescript-eslint/no-var-requires */}
                      <img
                        className="source-image"
                        alt="sourcelogo"
                        src={require('../assets/logos/' + itm.id + '.png').default}
                      />
                    </div>
                    <Row className="source-card-section-title">
                      <Col span={24}>
                        <div className="source-card-title">
                          <h4>{itm.name}</h4>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </A>
              </Col>
            );
          }
        )}
      </Row>
    </div>
  );
};
