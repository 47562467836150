import { Col, Row } from 'antd';
import { useState } from 'react';
import { A } from '../../../../../small-components/A';

import { OptimiseModal } from '../../../../../small-components/modals/optimise-modal';
import { T } from '../../../../../utils/transShim';
import { MultipleFieldBase } from './fields/edit-multiple-field-base';
import { MultipleNumberField } from './fields/edit-multiple-field-number';
import { MultipleNumberFieldDefault } from './fields/edit-multiple-field-number-default';
import { CommonTypeValue, SetValueNumber } from './types';

type Props = {
  onMarkupChange: (p: SetValueNumber) => void;
  onPriceChange: (p: SetValueNumber) => void;
  onProfitChange: (p: SetValueNumber) => void;
  onIncreaseMarkupChange: (p: SetValueNumber) => void;
  onQuantityChange: (p: SetValueNumber) => void;

  valueMarkup: SetValueNumber;
  valuePrice: SetValueNumber;
  valueProfit: SetValueNumber;
  valueIncreaseMarkup: SetValueNumber;
  valueQuantity: SetValueNumber;

  commonMarkup: CommonTypeValue;
  commonPrice: CommonTypeValue;
  commonProfit: CommonTypeValue;
  commonQuantity: CommonTypeValue;

  listingsBInfo: { listingId: number, productSourceId: number }[];
};
export const EditMultipleListingMain = (props: Props) => {
  const {
    valueQuantity, commonQuantity, onQuantityChange,
    valueMarkup, commonMarkup, onMarkupChange,
    valueIncreaseMarkup, onIncreaseMarkupChange,
    valuePrice, commonPrice, onPriceChange,
    valueProfit, commonProfit, onProfitChange,
    listingsBInfo
  } = props;

  const [optimising, SetOptimising] = useState<boolean>();

  const FieldNumber = (
    label: string,
    value: SetValueNumber,
    commonValue: CommonTypeValue,
    onChange: (p: SetValueNumber) => void,
    showCommonValue: boolean,
    min?: number
  ) => {
    return <MultipleFieldBase
      label={label}
      value={value}
      commonValue={commonValue}
      onChange={onChange}
      showCommonValue={showCommonValue}
      editField={
        () => <MultipleNumberField value={value} onChange={onChange} min={min} />
      } />;
  };

  const FieldNumberDefault = (
    label: string,
    value: SetValueNumber,
    commonValue: CommonTypeValue,
    onChange: (p: SetValueNumber) => void,
    showCommonValue: boolean,
    min?: number
  ) => {
    return <MultipleFieldBase
      label={label}
      value={value}
      commonValue={commonValue}
      onChange={onChange}
      showCommonValue={showCommonValue}
      editField={
        () => <MultipleNumberFieldDefault value={value} commonValue={commonValue} onChange={onChange} min={min} />
      } />;
  };

  const HandleQuantityChanged = (p: SetValueNumber) => onQuantityChange(p);
  const HandleMarkupChanged = (p: SetValueNumber) => {
    onMarkupChange(p);
    onPriceChange(null);
    onIncreaseMarkupChange(null);
    onProfitChange(null);
  };
  const HandlePriceChanged = (p: SetValueNumber) => {
    onMarkupChange(null);
    onPriceChange(p);
    onIncreaseMarkupChange(null);
    onProfitChange(null);
  };
  const HandleIncreaseMarkupChanged = (p: SetValueNumber) => {
    onMarkupChange(null);
    onPriceChange(null);
    onIncreaseMarkupChange(p);
    onProfitChange(null);
  };
  const HandleDecreaseMarkupChanged = (p: SetValueNumber) => {
    onMarkupChange(null);
    onPriceChange(null);
    if (p != null && p.value != null)
      p.value = -p.value;
    onIncreaseMarkupChange(p);
    onProfitChange(null);
  };
  const HandleProfitChanged = (p: SetValueNumber) => {
    onMarkupChange(null);
    onPriceChange(null);
    onIncreaseMarkupChange(null);
    onProfitChange(p);
  };
  const HandleOptimise = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => { SetOptimising(true); e.preventDefault(); e.stopPropagation(); };
  //-------------------------------

  const RenderOptimising = () => {
    return <OptimiseModal
      visible={optimising}
      listings={listingsBInfo}
      onClose={() => SetOptimising(false)}
    />;
  };

  const increaseValue = (valueIncreaseMarkup?.value ?? 0) > 0 ? valueIncreaseMarkup : null;
  const decreaseValue = (valueIncreaseMarkup?.value ?? 0) < 0 ? { value: -(valueIncreaseMarkup?.value ?? 1) } : null;

  return (<>
    <div className='multiple'>
      <Row>
        <Col xs={24} className="title">
          <T k="Listings.Column.Title" />:&nbsp;&nbsp;<A to="#" onClick={HandleOptimise}><T k="Listings.Edit.Optimize" /></A>
        </Col>
      </Row>
      {FieldNumber('Listings.Column.Stock', valueQuantity, commonQuantity, HandleQuantityChanged, true, 0)}
      {FieldNumberDefault('Listings.Column.Markup', valueMarkup, commonMarkup, HandleMarkupChanged, true, 0)}
      {FieldNumber('Listings.Edit.IncreaseMarkup', increaseValue, null, HandleIncreaseMarkupChanged, false, 0)}
      {FieldNumber('Listings.Edit.DecreaseMarkup', decreaseValue, null, HandleDecreaseMarkupChanged, false, 0)}
      {FieldNumber('Setting.YourPrice', valuePrice, commonPrice, HandlePriceChanged, true, 0)}
      {FieldNumber('Listings.Column.Profit', valueProfit, commonProfit, HandleProfitChanged, true, 0)}
    </div>
    {RenderOptimising()}
  </>);
};