import { useEffect } from 'react';
import { useParams } from 'react-router';
import { API_URL } from '../../redux/client';
import { DownloadFile } from '../../utils/utils';

export const WinningDownload = () => {

  const { filename } = useParams<{ filename?: string }>();
  if (!filename)
    return <>File not found</>;

  useEffect(() => {
    DownloadFile(API_URL + '/api/Winning/Download/' + filename,filename);
  }, []);
  return <>Downloading...</>;
};
