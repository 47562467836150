import { eApiType } from '../../../redux/new-channel/newChannelThunk';
import { eShop } from './eShop';

export enum eNewChannelStep {
  Expert = 999,
  Shop = 1,
  Country = 30,
  DoYouHave = 40,
  ApiNoApi = 50,
  NoApiServer = 55,
  Name = 60,
  ApiLink = 70,
  NoApiLink = 80,
  CallBack = 85,
  End = 100
}

const FacebookSteps    = [eNewChannelStep.Shop, eNewChannelStep.Country,    eNewChannelStep.DoYouHave,                               eNewChannelStep.NoApiServer, eNewChannelStep.Name, eNewChannelStep.NoApiLink, eNewChannelStep.End];
const EbayApiSteps     = [eNewChannelStep.Shop, eNewChannelStep.Country,    eNewChannelStep.DoYouHave, eNewChannelStep.ApiNoApi,                                                        eNewChannelStep.ApiLink,   eNewChannelStep.End];
const AmazonSteps      = [eNewChannelStep.Shop, eNewChannelStep.Country,    eNewChannelStep.DoYouHave,                               eNewChannelStep.NoApiServer, eNewChannelStep.Name, eNewChannelStep.NoApiLink, eNewChannelStep.End];
const EbayNoApiSteps   = [eNewChannelStep.Shop, eNewChannelStep.Country,    eNewChannelStep.DoYouHave, eNewChannelStep.ApiNoApi,     eNewChannelStep.NoApiServer, eNewChannelStep.Name, eNewChannelStep.NoApiLink, eNewChannelStep.End];
const ShopifySteps     = [eNewChannelStep.Shop, eNewChannelStep.DoYouHave,  eNewChannelStep.ApiLink,   eNewChannelStep.Country,                                                         eNewChannelStep.CallBack,  eNewChannelStep.End];
const WooCommerceSteps = [eNewChannelStep.Shop, eNewChannelStep.Country,    eNewChannelStep.DoYouHave,                                                            eNewChannelStep.Name, eNewChannelStep.ApiLink,   eNewChannelStep.End];
const TikTokSteps      = [eNewChannelStep.Shop, eNewChannelStep.Country,    eNewChannelStep.DoYouHave,                                                            eNewChannelStep.Name, eNewChannelStep.ApiLink,   eNewChannelStep.End];

export const StepsPerShop = (shop: eShop | null, api: eApiType | null) => {
  switch (shop) {
    default:
    case eShop.eBay:
      if (api == eApiType.NO_API)
        return EbayNoApiSteps;
      else
        return EbayApiSteps;
    case eShop.Amazon: return AmazonSteps;
    case eShop.Facebook: return FacebookSteps;
    case eShop.Shopify: return ShopifySteps;
    case eShop.WooComerce: return WooCommerceSteps;
    case eShop.TikTok: return TikTokSteps;
  }
};

export const NextStep = (currentStep: eNewChannelStep, shop: eShop | null, api: eApiType | null): eNewChannelStep => {
  if (currentStep == eNewChannelStep.Expert)
    return eNewChannelStep.Shop;

  const stepList = StepsPerShop(shop, api);
  for (let i = 0; i < stepList.length - 1; i++) {
    if (stepList[i] == currentStep) {
      return stepList[i + 1];
    }
  }
  return currentStep;
};
export const PreviousStep = (currentStep: eNewChannelStep, shop: eShop | null, api: eApiType | null): eNewChannelStep => {
  const stepList = StepsPerShop(shop, api);
  if (currentStep == stepList[0])
    return currentStep;
  for (let i = 1; i < stepList.length; i++) {
    if (stepList[i] == currentStep) {
      const prv = stepList[i - 1];
      switch (prv) {
        case eNewChannelStep.ApiLink:
        case eNewChannelStep.NoApiLink:
          return stepList[i - 2];
      }
      return prv;
    }
  }
  return stepList[0];
};
export const IsFirstStep = (currentStep: eNewChannelStep, shop: eShop | null, api: eApiType | null) => {
  return StepsPerShop(shop, api)[0] == currentStep;
};
export const IsLastStep = (currentStep: eNewChannelStep, shop: eShop | null, api: eApiType | null) => {
  const stepList = StepsPerShop(shop, api);
  return stepList[stepList.length - 1] == currentStep;
};
export const GetStepIndex = (currentStep: eNewChannelStep, shop: eShop | null, api: eApiType | null) => {
  const stepList = StepsPerShop(shop, api);
  for (let i = 0; i < stepList.length; i++) {
    if (stepList[i] == currentStep)
      return i;
  }
  return 0;
};
export const GetTotalStepsCount = (shop: eShop | null, api: eApiType | null) => {
  return StepsPerShop(shop, api).length;
};