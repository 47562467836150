import { eChannelListingStatus } from '../../../redux/listings/listings-slice';
import { Source } from '../../../redux/sources/sourceSlice';
import { AdvancedFilterValueType } from '../../../small-components/tables/types/columns';
import { StringOrNull } from '../../../utils/utils';

export const MultiTermFilter = (value: unknown, searchTerm: string | null | undefined) => {
  if (searchTerm == null)
    return true;

  const terms = searchTerm.trim().toLocaleLowerCase().split(' ');
  if (terms.length == 0)
    return true;

  const fieldValue = StringOrNull(value)?.trim()?.toLocaleLowerCase();
  if (fieldValue == null)
    return false;

  for (const term of terms) {
    if (fieldValue.indexOf(term) < 0)
      return false;
  }
  return true;
};
export const SourceFilter = (_: unknown, searchTerm: string | null | undefined, row: { source?: Source, path: string }) => {
  if (!row?.source || !searchTerm)
    return true;

  return MultiTermFilter(row.source.name, searchTerm) || ('https://' + (row.source?.baseUrl ?? '') + '/' + row.path).indexOf(searchTerm) >= 0;
};

export const MultiTermFilterAdvancedSearch = (fieldValue: unknown, searchTerm: AdvancedFilterValueType) => {
  if (!searchTerm)
    return true;

  return MultiTermFilter(fieldValue, searchTerm[0]?.toString());
};

export const SourceFilterAdvancedSearch = (_: unknown, searchTerm: AdvancedFilterValueType, row: { source?: Source, path: string }) => {
  if (!searchTerm || searchTerm.length == 0 || !searchTerm[0])
    return true;

  if (!row?.source)
    return true;

  return MultiTermFilter(row.source.name, searchTerm[0]?.toString()) || ('https://' + (row.source?.baseUrl ?? '') + '/' + row.path).indexOf(searchTerm[0].toString()) >= 0;
};

export const ListingStatusFilter = (fieldValue: unknown, searchTerm: string) => {
  return eChannelListingStatus[fieldValue as eChannelListingStatus]?.indexOf(searchTerm) >= 0;
};

export const QuantityFilterAdvancedSearch = (_: unknown, searchTerm: AdvancedFilterValueType, row: { channelQuantity:number }) => {
  if (!searchTerm || searchTerm.length == 0)
    return true;

  if (searchTerm[0] == null) {//NO MIN
    if (searchTerm[1] == null) {//NO MIN & NO MAX
      return true;
    } else {
      return row.channelQuantity <= searchTerm[1];// <= MAX
    }
  } else {
    if (searchTerm[1] == null) {//>= MIN
      return row.channelQuantity >= searchTerm[0];
    } else {// >= MIN && <= MAX
      return row.channelQuantity >= searchTerm[0] && row.channelQuantity <= searchTerm[1];
    }
  }
};

export const FlagsFilter = (fieldValue: unknown, searchTerm: AdvancedFilterValueType) => {
  if (fieldValue == null)
    return true;
  if (searchTerm == null)
    return true;
  try {
    for (const g of searchTerm) {
      const v = parseInt(g as string ?? '0') ?? 0;
      const f = fieldValue as number;
      if (!v && !f)
        return true;
      if ((f & v) != 0)
        return true;
    }
  } catch (ex) {
    console.log(ex);
  }
  return false;
};