import { eAccountSettings, SavingSetting } from '../../../../redux/account-configuration/account-configuration-slice';
import { TransLinksValues, TransPlatformValues } from '../../../../utils/transShim';
import { AccountSetting } from '../../configuration/settings';
import { GenericSettingInput, SettingDataBag, SettingsValue } from '../generic/generic-setting-input';

interface Props {
  setting: AccountSetting;
  currentSettingValues: Map<eAccountSettings, SettingsValue>;
  savingSetting?: Map<eAccountSettings, SavingSetting>;
  onSave: (key: eAccountSettings, value: SettingsValue) => void;
  translationValues: TransPlatformValues | TransLinksValues;
  dataBag: SettingDataBag
  onButtonClick?: () => void,
  allFieldsInfo: AccountSetting[],
  fullWidth?: boolean
}

export const AccountSettingInput = (props: Props) => GenericSettingInput<eAccountSettings, AccountSetting>({
  ...props, delayToSave: 0
});