//https://en.wikipedia.org/wiki/List_of_GS1_country_codes
const siteCode: { [isoCountry: string]: { min: number; max: number }[] } = {
  US: [
    { min: 0, max: 19 },
    { min: 60, max: 99 },
    { min: 100, max: 139 }
  ],
  CA: [
    { min: 0, max: 19 },
    { min: 60, max: 99 }
  ],
  FR: [{ min: 300, max: 379 }],
  MC: [{ min: 300, max: 379 }],
  BG: [{ min: 380, max: 380 }],
  SI: [{ min: 383, max: 383 }],
  HR: [{ min: 385, max: 385 }],
  BA: [{ min: 387, max: 387 }],
  ME: [{ min: 389, max: 389 }],
  XK: [{ min: 389, max: 389 }],
  DE: [{ min: 400, max: 440 }],
  JP: [{ min: 450, max: 459 }],
  RU: [{ min: 460, max: 469 }],
  KZ: [{ min: 470, max: 470 }],
  TW: [{ min: 471, max: 471 }],
  EE: [{ min: 474, max: 474 }],
  LV: [{ min: 475, max: 475 }],
  AZ: [{ min: 476, max: 476 }],
  LT: [{ min: 477, max: 477 }],
  UZ: [{ min: 478, max: 478 }],
  LK: [{ min: 479, max: 479 }],
  PH: [{ min: 480, max: 480 }],
  BY: [{ min: 481, max: 481 }],
  UA: [{ min: 482, max: 482 }],
  TM: [{ min: 483, max: 483 }],

  CN: [{ min: 690, max: 699 }],

  ES: [{ min: 840, max: 849 }],
  AD: [{ min: 840, max: 849 }],
  IT: [{ min: 800, max: 839 }],
  SM: [{ min: 800, max: 839 }],
  VA: [{ min: 800, max: 839 }],

  GB: [{ min: 500, max: 509 }],
  UK: [{ min: 500, max: 509 }], //Actually GB

  AU: [
    { min: 900, max: 919 },
    { min: 930, max: 939 }
  ]
};

export const GenerateRandomEan = (site: string): string => {
  let gCodes = siteCode[site];
  if (!gCodes) gCodes = siteCode.GB;
  const possibleCodes = [];
  for (const c of gCodes) {
    for (let i = c.min; i <= c.max; i++) {
      possibleCodes.push(i);
    }
  }
  const initialNumber = possibleCodes[Math.floor(Math.random() * possibleCodes.length)];

  let code =
    initialNumber.toString().padStart(3, '0') + (Math.round(Math.random() * 999999999) + '').padStart(9, '0');
  let weightflag = true;
  let sum = 0;

  for (let i = code.length - 1; i >= 0; i--) {
    sum += parseInt(code[i]) * (weightflag ? 3 : 1);
    weightflag = !weightflag;
  }
  code += (10 - (sum % 10)) % 10;

  return code;
};

export const IsValidEan = (ean: string): boolean => {
  if (!ean) return false;

  if (ean.length != 13) return false;

  if (isNaN(parseInt(ean))) return false;

  const digits = [];
  for (let i = 0; i < ean.length; i++) {
    digits[i] = parseInt(ean[i]);
  }

  const even_sum = digits[1] + digits[3] + digits[5] + digits[7] + digits[9] + digits[11];

  const even_sum_three = even_sum * 3;

  const odd_sum = digits[0] + digits[2] + digits[4] + digits[6] + digits[8] + digits[10];

  const total_sum = even_sum_three + odd_sum;

  const next_ten = Math.ceil(total_sum / 10) * 10;
  const check_digit = next_ten - total_sum;

  if (check_digit == digits[12]) {
    return true;
  }

  return false;
};
