import { StarFilled } from '@ant-design/icons';
import { T } from '../../utils/transShim';
import { QuoteSliderData } from './quote-slider';
import face10 from '../../assets/login/face-10.png';
import face2 from '../../assets/login/face-2.png';
import face3 from '../../assets/login/face-3.png';
import face4 from '../../assets/login/face-4.png';
import face5 from '../../assets/login/face-5.png';

export const GetQuotes = () => {
  const FiveStars = () => <span><StarFilled /><StarFilled /><StarFilled /><StarFilled /><StarFilled /></span>;

  return [
    {
      quote: <T k="Register.Quote2" />,
      photo: face2,
      graph: FiveStars(),
      name: 'Elisabeth'
    },
    {
      quote: <T k="Register.Quote1" />,
      photo: face10,
      graph: FiveStars(),
      name: 'Michael'
    },
    {
      quote: <T k="Register.Quote3" />,
      photo: face3,
      graph: FiveStars(),
      name: 'George'
    },
    {
      quote: <T k="Register.Quote4" />,
      photo: face4,
      graph: FiveStars(),
      name: 'Dani'
    },
    {
      quote: <T k="Register.Quote5" />,
      photo: face5,
      graph: FiveStars(),
      name: 'Adem'
    }
  ] as QuoteSliderData[];
};