
import { A } from '../../../small-components/A';
import { AttributeOptionsPanel } from './attribute-options-panel';
import { ReCalculateUrls } from './images-view';
import { ListingVariationsResponse, VariationListingInfo } from './variation-listing-info';
import { VariationPanel } from './variation-panel';

export interface VariationsPanelProps {
  variations: ListingVariationsResponse | null;
  onVariationsChanged?: (data: ListingVariationsResponse) => void;
  selectedVariationIndex: number;
  onSelected: (v: VariationListingInfo, i: number) => void;
  attributePanel: boolean;
  onClearVariations?: () => void;
  onDeleteOneVariation?: (v: VariationListingInfo) => void;
  canClearVariations: boolean;
  mainVariationId: number;
  children?: React.ReactNode;
}


export const GetSetAttributes = (vs: VariationListingInfo[]) => {
  const ss = new Set<string>();
  vs.forEach((x) => x.attributeOptions.forEach((y) => ss.add(y.attribute)));
  return ss;
};

export const VariationsPanel = (props: VariationsPanelProps) => {


  const OnOptionUpdated = (i: number, value: string) => {
    const newList: VariationListingInfo[] = [...(props.variations?.variations ?? [])];
    const selectedVar = props.variations?.variations?.[props.selectedVariationIndex];
    const newAttributeOptions = [...(selectedVar?.attributeOptions ?? [])];
    newAttributeOptions[i] = { ...newAttributeOptions[i], option: value };
    newList[props.selectedVariationIndex] = { ...selectedVar, attributeOptions: newAttributeOptions } as VariationListingInfo;

    const newVariationInfo = { ...props.variations, variations: newList } as ListingVariationsResponse;

    props.onVariationsChanged?.(ReCalculateUrls(newVariationInfo));
  };
  const OnAttributeAdded = () => {
    const newList: VariationListingInfo[] = [...(props.variations?.variations ?? [])];
    for (const i in newList) {
      const v = { ...newList[i] };
      newList[i] = v;
      v.attributeOptions = [...v.attributeOptions];
      v.attributeOptions.push({ attribute: '', option: '' });
    }

    const newVariationInfo = { ...props.variations, variations: newList } as ListingVariationsResponse;
    props.onVariationsChanged?.(ReCalculateUrls(newVariationInfo));
  };
  const OnAttributeRemoved = (i: number) => {
    const newList: VariationListingInfo[] = [...(props.variations?.variations ?? [])];
    if (newList[0].attributeOptions.length <= 1) {
      return;
    }

    let newVariationInfo = { ...props.variations, variations: newList } as ListingVariationsResponse;

    const attributeToDelete = newList[0].attributeOptions[i].attribute;

    for (const x in newList) {
      const v = { ...newList[x] };
      newList[x] = v;
      v.attributeOptions = [...v.attributeOptions];
      v.attributeOptions.splice(i, 1);
    }

    if (attributeToDelete == props.variations?.variationImages?.attribute) {
      newVariationInfo.variationImages = { ...newVariationInfo.variationImages };
      newVariationInfo.variationImages.attribute = newList[0].attributeOptions[0].attribute;
      newVariationInfo = ReCalculateUrls(newVariationInfo);
    }

    props.onVariationsChanged?.(newVariationInfo);
  };
  const OnAttributeUpdated = (i: number, name: string) => {
    const newList: VariationListingInfo[] = [...(props.variations?.variations ?? [])];
    let newVariationInfo = { ...props.variations, variations: newList } as ListingVariationsResponse;

    const attributeToUpdate = newList[0].attributeOptions[i].attribute;

    for (const x in newList) {
      const v = { ...newList[x] };
      newList[x] = v;
      v.attributeOptions = [...v.attributeOptions];
      const newAo = { ...v.attributeOptions[i], attribute: name };
      v.attributeOptions[i]= newAo;
    }

    if (attributeToUpdate == props.variations?.variationImages.attribute) {
      newVariationInfo.variationImages = { ...newVariationInfo.variationImages };
      newVariationInfo.variationImages.attribute = name;
      newVariationInfo = ReCalculateUrls(newVariationInfo);
    }

    props.onVariationsChanged?.(newVariationInfo);
  };
  const OnClearVariations = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.onClearVariations) props.onClearVariations();
  };

  const VariationSelected = (v: VariationListingInfo, i: number) => {
    props.onSelected(v, i);
  };


  const selectedVar = props.variations?.variations?.[props.selectedVariationIndex];

  return (
    <>
      <div className='variationsPanel'>
        {props.canClearVariations && (
          <div className='header'>
            <A to='#' onClick={OnClearVariations}>
              Clear all variations
            </A>
          </div>
        )}
        <div className='list'>
          {props.variations?.variations.map((x, i) => (
            <VariationPanel
              key={i}
              info={x}
              onSelect={() => VariationSelected(x, i)}
              selected={props.selectedVariationIndex == i}
              canBeClosed={
                props.canClearVariations &&
                props.mainVariationId != x.listingId &&
                x.listingId > 0
              }
              onDeleteClicked={props.onDeleteOneVariation}
              variationsImages={props.variations?.variationImages}
            />
          ))}
        </div>
        {props.children}
        {props.attributePanel && (
          <div className='attributeOptions'>
            <AttributeOptionsPanel
              attributeOptions={selectedVar?.attributeOptions ?? []}
              onOptionUpdated={OnOptionUpdated}
              onAttributeAdded={OnAttributeAdded}
              onAttributeRemoved={OnAttributeRemoved}
              onAttributeUpdated={OnAttributeUpdated}
            />
          </div>
        )}
      </div>
    </>
  );

};
