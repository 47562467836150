import { createRef, useState, useEffect, useMemo } from 'react';
import { Card, Divider, Carousel, Space, Spin, Tooltip } from 'antd';
import { CarouselRef } from 'antd/es/carousel';
import { StatusBar } from '../../../small-components/StatusBar';
import { BoldButton, PrimaryBtn } from '../../../small-components/ActionBtns';
import { useAppDispatch, useAppSelector } from '../../../custom-hooks/reduxCustomHooks';
import { getSubscriptions } from 'src/redux/subscriptions/subsThunk';
import { Product } from '../../../redux/subscriptions/subsSlice';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import '../../../sass/subscriptions/subscriptions.scss';
import { CancellationModal } from '../cancellation/CancellationModal';
import { Links } from '../../../links';
import { T } from '../../../utils/transShim';
import moment from 'moment';
import { ReactUtils } from '../../../utils/react-utils';
import { eCurrency } from '../../../types/eCurrency';
import { SaveCart } from '../../../redux/checkout/checkoutThunk';
import { StoredCheckout } from '../../../redux/checkout/data/stored-ids';
import { BillingPeriod, PaymentPlatform } from '../models/types';
import { A } from '../../../small-components/A';

type Props = {
  sh?: number;
};

export const SubscriptionsCore = (props: Props) => {
  const dispatch = useAppDispatch();

  const { products, loading } = useAppSelector((state) => state.subscriptions);
  const { quota} = useAppSelector((state) => state.user);

  const sliderRef = createRef<CarouselRef>();

  const [slides, setSlides] = useState<number>(3);
  const [activeCurrency, setActiveCurrency] = useState<eCurrency>(eCurrency.EUR);
  const [currency, setCurrency] = useState('\u20AC');

  const freeQuota = quota != null && quota.price == 0 && quota.quota <= 10;
  const quotaAlmostFilled = quota != null && quota?.used > quota?.quota - quota?.quota * 0.2;

  const noChannel = ReactUtils.GetSelectedChannel() == null;

  useEffect(() => {
    if (!products || products.length == 0) dispatch(getSubscriptions());
  }, [getSubscriptions]);

  const handleNext = () => sliderRef?.current?.next();
  const handlePrev = () => sliderRef?.current?.prev();
  const tabletScreen = window.matchMedia('(max-width: 1030px)');
  const mobileScreen = window.matchMedia('(max-width: 750px)');

  const HandleChangeCurrency = (currency: eCurrency) => {
    setActiveCurrency(currency);
    if (currency === eCurrency.EUR) {
      setCurrency('\u20AC');
    } else if (currency === eCurrency.USD) {
      setCurrency('\u0024');
    } else if (currency === eCurrency.GBP) {
      setCurrency('\u00A3');
    }
  };

  const parentToChild = (value: number, period: BillingPeriod): void => {
    const st: StoredCheckout = {
      products: [value],
      currency: activeCurrency,
      period: period
    };
    dispatch(SaveCart(st));
  };

  const renderSlides = useMemo(() => {
    if (tabletScreen.matches) {
      setSlides(2);
      slides;
    }
    if (mobileScreen.matches) {
      setSlides(1);
      slides;
    }
    return slides;
  }, [slides]);

  //const HandleLogout = () => {
  //  const keysToRemove = ['root', 'Authorization', 'globalTheme', 'isAuthenticated', 'newChannelSuccess'];
  //  dispatch(actions.logout());
  //  keysToRemove.forEach((key) => localStorage.removeItem(key));
  //  persistor.purge();
  //  history.push(Links.Credentials.Login);
  //};

  const RenderNoSuscription = () => {

    return <div className={'freetrial-container' + (noChannel ? 'nochannel' : '')}>
      {/*To align*/}
      <div className="box-text">
        <h1 className="title-payment">
          <T k="Checkout.Expired.Title" />
        </h1>
        <p className="description-trial">
          <T k="Checkout.Expired.Description1" />
        </p>
        <p className="description-trial">
          <T k="Checkout.Expired.Description2" />
        </p>
        <A to={Links.AffiliateDashboard}>
          <T k="Affiliate.Link.Visit" />
        </A>
      </div>
      {/*{noChannel && (*/}
      {/*  <A to="#" onClick={HandleLogout} className="logout">*/}
      {/*    <T k="Menu.Logout" />*/}
      {/*  </A>*/}
      {/*)}*/}
      {/*To align*/}
    </div>;
  };

  const CurrentSubscription = () => {
    return <><h6 className="subscriptions-detail">
      <T k="Subscription.Sentence1" />
    </h6>
    <p className="subscriptions-limit">
      <T k="Subscription.SubscriptionLimit" />
          &nbsp;<span className="items-count">{quota?.quota}</span>
    </p>
    <>
      {quota != null && quota.price > 0 && (
        <p className="subscriptions-limit">
          <T k="Subscription.Cost" />
              &nbsp;
          <Tooltip>
            <span className="items-count">{ReactUtils.DrawPrice(quota.price, quota.currency as unknown as eCurrency)}</span>
          </Tooltip>
        </p>
      )}
    </>
    <>
      {quota != null && (
        <p className="subscriptions-limit">
          <T k="Subscription.NextPayment" />
              &nbsp;
          <Tooltip>
            <span className="items-count">{moment(quota.endsOn).add(-2, 'days').format('L')}</span>
          </Tooltip>
        </p>
      )}
    </>
    <p className="subscriptions-items">
      <T k="Subscription.ActiveProducts" />
          &nbsp;
      {quotaAlmostFilled ? (
        <Tooltip title="You are about to fill your quota!">
          <span className="items-count-alert">{quota?.used}</span>
        </Tooltip>
      ) : (
        <Tooltip>
          <span className="items-count">{quota?.used}</span>
        </Tooltip>
      )}
    </p>
    {
      freeQuota ? (
        <A to="https://hustlegotreal.com/en/contact-us/" target={'_blank'} rel="noreferrer">
          <BoldButton>Contact us</BoldButton>
        </A>
      ) : (
        <CancellationModal />
      )
    }
    </>;
  };

  const RenderStatusBar = () => {
    if (quota == null)
      return <></>;
    return <StatusBar>
      {quota.quota == 0 && RenderNoSuscription()}
      {quota.quota > 0 && CurrentSubscription()}
    </StatusBar>;
  };

  const RenderContent = () => {
    if (loading)
      return <Spin />;

    return (
      <div className="carousel-container">
        {RenderStatusBar()}
        <div className="currencies-container">
          <PrimaryBtn id={eCurrency.EUR.toString()} onClick={_ => HandleChangeCurrency(eCurrency.EUR)} className={activeCurrency === eCurrency.EUR ? 'active-currency' : ''}>
          EUR
          </PrimaryBtn>
          <PrimaryBtn id={eCurrency.USD.toString()} onClick={_ => HandleChangeCurrency(eCurrency.USD)} className={activeCurrency === eCurrency.USD ? 'active-currency' : ''}>
          USD
          </PrimaryBtn>
          <PrimaryBtn id={eCurrency.GBP.toString()} onClick={_ => HandleChangeCurrency(eCurrency.GBP)} className={activeCurrency === eCurrency.GBP ? 'active-currency' : ''}>
          GBP
          </PrimaryBtn>
        </div>

        <Space className="control-btns-container">
          <LeftOutlined className="prvArrow" onClick={handlePrev} />
          <Carousel slidesToShow={renderSlides} className="carousel" dots={false} ref={sliderRef}>
            {products?.filter(x => x.type == 1)?.map((p: Product) => (
              <Card key={p.id} className="subscription">
                <p className="listings-count">
                  <strong><T k={'Checkout.pname.' + p.name} /></strong>
                </p>
                <div className="container-sub">
                  <A to={Links.Checkout.Main + (props.sh ? '?sh=' + props.sh : '')} onClick={() => parentToChild(p.id, BillingPeriod.Monthly)} key={p.id}>
                    <div className="rate-details top-most">
                      <span className="euro">{currency}</span>
                      <h1 className="monthly-rate">
                        {p.prices.map((prc) => {
                          if (prc.currencyId === activeCurrency && prc.platformId === PaymentPlatform.Stripe && prc.billingPeriodId === BillingPeriod.Monthly) {
                            return prc.price;
                          }
                        })}
                      </h1>
                      <h4 className="frequency"><T k='Checkout.perMonth.short' /></h4>
                    </div>
                  </A>
                </div>
                <Divider className="divider" />
                <div className="container-sub">
                  <A to={Links.Checkout.Main + (props.sh ? '?sh=' + props.sh : '')} onClick={() => parentToChild(p.id, BillingPeriod.Biannually)}>
                    <p className="twenty-off">20% off</p>
                    <div className="rate-details">
                      <span className="euro">{currency}</span>
                      <h1 className="monthly-rate">
                        {p.prices.map((prc) => {
                          if (prc.currencyId === activeCurrency && prc.platformId === PaymentPlatform.Stripe && prc.billingPeriodId === BillingPeriod.Biannually) {
                            return (prc.price / 6).toFixed(1);
                          }
                        })}
                      </h1>
                      <h4 className="frequency"><T k='Checkout.perMonth.short' /></h4>
                      <h4 className="duration">(<T k='Checkout.per6months' />)</h4>
                    </div>
                  </A>
                </div>
                <Divider className="divider" />
                <div className="container-sub">
                  <A to={Links.Checkout.Main + (props.sh ? '?sh=' + props.sh : '')} onClick={() => parentToChild(p.id, BillingPeriod.Yearly)}>
                    <p className="forty-off">40% off</p>
                    <div className="rate-details">
                      <span className="euro">{currency}</span>
                      <h1 className="monthly-rate">
                        {p.prices.map((prc) => {
                          if (prc.currencyId === activeCurrency && prc.platformId === PaymentPlatform.Stripe && prc.billingPeriodId === BillingPeriod.Yearly) {
                            return (prc.price / 12).toFixed(1);
                          }
                        })}
                      </h1>
                      <h4 className="frequency"><T k='Checkout.perMonth.short' /></h4>
                      <h4 className="duration">(<T k='Checkout.per1year' />)</h4>
                    </div>
                  </A>
                </div>
              </Card>
            ))}
          </Carousel>
          <RightOutlined className="nxtArrow" onClick={handleNext} />
        </Space>
      </div>
    );
  };

  return (
    <div className="subscriptions-container">
      {RenderContent()}
    </div>
  );
};
