import { Spin } from 'antd';
import { useState } from 'react';
import { SmartListerFilter } from '../../../redux/smart-lister/data/smart-lister-filter';
import { Source } from '../../../redux/sources/sourceSlice';
import { ComplexTable } from '../../../small-components/tables/complex-table';
import { Columns } from './columns/columns';
import { ColumnsVisibleByDefault } from './columns/columns-visible-by-default';
import { SmartListerFilterEdit } from './smart-lister-filter-edit';

interface Props {
  filters?: SmartListerFilter[];
  loading: boolean;
  sources: Source[];
  updating?: { [id: number]: boolean };
}

export const SmartListerFiltersTable = (props: Props) => {
  const { filters, loading, sources, updating } = props;

  const [editingFilter, SetEditingFilter] = useState<number | undefined>(undefined);

  const HandleRowClick = (f: SmartListerFilter) => {
    if(!updating?.[f.id])
      SetEditingFilter(f.id);
  };
  const HandleClosePopPup = () => SetEditingFilter(undefined);

  if (!filters || loading) {
    return <Spin />;
  }

  const data = filters.map(x => ({
    ...x,
    key: x.id,
    sourceList: (x.sources && sources && sources.length) ? (x.sources.map(x => sources.find(s => s.id == x)).filter(x => !!x) as Source[] | undefined) : undefined,
    updating: updating?.[x.id]
  }));

  return <>
    <ComplexTable
      uiIdentifier={'aiassistant'}
      data={data}
      allColumnData={Columns}
      defaultVisibleColumns={ColumnsVisibleByDefault}
      hideWhenEmpty={false}
      loadingData={loading}
      hideTotal={true}
      advancedSearch={false}
      smartSearch={false}
      onRow={(x) => ({ onClick: () => HandleRowClick(x) })}
    />
    <SmartListerFilterEdit row={editingFilter == null ? undefined : data.find(x => x.id == editingFilter)} open={editingFilter != null} onClose={HandleClosePopPup} />
  </>;
};
