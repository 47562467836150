import { InputNumber } from 'antd';
import { useState } from 'react';

type ValueType = null | undefined | (number | null)[];
type Props = {
  name?: string;
  onChange: (value: ValueType) => void;
}
export const NumberField = (props: Props) => {
  const { onChange, name } = props;
  const [currentValue, setCurrentValue] = useState<ValueType>();

  const HandleChange = (value: number | null, isMax: boolean) => {
    const myIndex = isMax ? 1 : 0;
    const otherIndex = isMax ? 0 : 1;

    const newValue = ((): ValueType => {
      if (value == null && (!currentValue || currentValue.length <= otherIndex || !currentValue[otherIndex]))
        return null;
      const r: (number | null)[] = [];
      r[myIndex] = value != null ? value : null;
      r[otherIndex] = currentValue?.[otherIndex] ?? null;
      return r;
    })();

    onChange(newValue);
    setCurrentValue(newValue);
  };
  
  const HandleChangeMin = (value: number | null) => HandleChange(value, false);
  const HandleChangeMax = (value: number | null) => HandleChange(value, true);

  return (
    <div className="max-min-section" key={name}>
      <InputNumber className="blue-input" placeholder="Min" name={name} onChange={HandleChangeMin} min={0} />
      <InputNumber className="blue-input" placeholder="Max" name={name} onChange={HandleChangeMax} min={0} />
    </div>
  );
};