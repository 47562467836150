import {  InputNumber } from 'antd';
import { SettingList } from '../../../../../../small-components/settings/setting-list';
import { useTranslation } from '../../../../../../utils/transShim';
import { CommonTypeValue, SetValueNumber } from '../types';

type Props = {
  value: SetValueNumber;
  commonValue: CommonTypeValue;
  onChange: (p: SetValueNumber) => void;
  min?: number;
}


export const MultipleNumberFieldDefault = (props: Props) => {
  const { value, onChange, commonValue, min } = props;

  const HandleSelectorChange = (v: string | null) => onChange({ value: v != '0' ? (commonValue?.value as number | undefined) ?? 1 : undefined });
  const HandleInputChange = (v: number | null) => onChange({ value: v ?? undefined });

  const label = useTranslation('Setting.FinalDefinedBySettingsEmpty') as string;

  return (
    <>
      <SettingList
        listData={[{ label: label, value: '0' }, { label: useTranslation('Setting.Custom') as string, value: '1' }]}
        value={value?.value != null ? '1' : '0'}
        onChange={HandleSelectorChange}
        loadingData={false}
      />
      {value?.value != null && <InputNumber value={value?.value ?? (commonValue?.value as number | undefined) ?? 1} onChange={HandleInputChange} min={min} />}
    </>
  );
};