import { useState, useEffect } from 'react';
import { Divider } from 'antd';
import { SuccessBtn } from '../../small-components/ActionBtns';
import { T } from '../../utils/transShim';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import '../../sass/catalog-source.scss';
import { Source } from '../../redux/sources/sourceSlice';
import { SearchInput } from '../../small-components/tables/complex-table-components/table-action-btns';

interface Props {
  onClose: () => void;
  onChangeSelectedSources: (ids: number[]) => void;
  sources: Source[];
}
export const CatalogSource = ({ onClose, onChangeSelectedSources, sources }: Props) => {

  const [addedSources, setAddedSources] = useState<Source[]>([]);
  const [pendingSources, setPendingSources] = useState<Source[]>(sources ?? []);

  const addSource = (id: number): void => {
    const addedSource = pendingSources.filter((s) => s.id === id)[0];
    setAddedSources((prevState) => [...prevState, addedSource]);
    setPendingSources((prevState) => prevState.filter((s) => s.id !== id));
  };

  const removeSource = (id: number): void => {
    const removedSource = addedSources.filter((s) => s.id === id)[0];
    setPendingSources((prevState) => [...prevState, removedSource]);
    setAddedSources((prevState) => prevState.filter((s) => s.id !== id));
  };

  const onSearch = (value: string) => {
    setPendingSources(
      sources.filter((c: Source) => {
        if (c.name.toLowerCase().includes(value.toLowerCase())) {
          return c;
        }
      })
    );
  };

  useEffect(() => {
    onChangeSelectedSources(addedSources.map(item => item.id));
  }, [addedSources]);

  return (
    <div className="catalog-source">
      <p className="tag-line">
        <strong><T k='Catalog.Filter.Sources.P1' /></strong>{!addedSources.length && <span>&nbsp;<T k='Catalog.Filter.Sources.NoP' /></span>}
      </p>
      <div className="added-sources-list">
        {!!addedSources.length && (
          addedSources.map((s) => (
            <div key={s.id} className="source">
              <img
                className='source-img'
                src={require('../../assets/logos/' + s.id + '.png').default}
              />
              <MinusCircleOutlined className="remove-source-icon" onClick={() => removeSource(s.id)} />
            </div>
          ))
        )}
      </div>

      <div className="source-lists-area">
        <SearchInput onSearch={onSearch} />
        <div className="pending-sources-list">
          {pendingSources?.map((s) => (
            <div key={s.id} className="source">
              <img
                className='source-img'
                src={require('../../assets/logos/' + s.id + '.png').default}
              />
              <PlusCircleOutlined className="pending-source-icon" onClick={() => addSource(s.id)} />
            </div>
          ))}
        </div>
      </div>
      <Divider />
      <div className="action-btns">
        <SuccessBtn onClick={onClose}><T k='Question.Accept' /></SuccessBtn>
      </div>
    </div>
  );
};
