import { createAsyncThunk } from '@reduxjs/toolkit';
import { eCurrency } from '../../types/eCurrency';
import { client } from '../client';

interface GetAffiliatesStatsRequest {
  period: number;
  from: string;
  to?: string;
  timeDiff: number;
}

interface DashboardProps {
  month: number;
  year: number;
}

export const getAffiliatesStats = createAsyncThunk(
  'dashboard-affiliates/getAffiliatesStats',
  async ({ period, from, to }: GetAffiliatesStatsRequest, { rejectWithValue }) => {
    try {
      const res = await client.get(`/Dashboard/GetAffiliateStatistics/${period}/${from}/${to}`);
      return res.data.response_data;
    } catch (error) {
      return rejectWithValue('Sorry! Something went wrong');
    }
  }
);

export const getAffiliateDashboardGeneral = createAsyncThunk(
  'Dashboard/AffiliateDashboardGeneral',
  async (_, { rejectWithValue } ) => {
    try {
      const res = await client.get('/Dashboard/AffiliateDashboardGeneral');
      return res.data.response_data;
    } catch (error) {
      return rejectWithValue('Sorry! Something went wrong');
    }
  }
);
export const getAffiliateDashboardMonthly = createAsyncThunk(
  'Dashboard/AffiliateDashboardMonthly',
  async ({ month, year }: DashboardProps, { rejectWithValue } ) => {
    try {
      const res = await client.get(`/Dashboard/AffiliateDashboardMonthly?month=${month}&year=${year}`);
      return res.data.response_data;
    } catch (error) {
      return rejectWithValue('Sorry! Something went wrong');
    }
  }
);
export const GetAffiliateWithdrawals = createAsyncThunk(
  'Dashboard/AffiliateWithdrawals',
  async (_, { rejectWithValue }) => {
    try {
      const res = await client.get('/Dashboard/AffiliateWithdrawals');
      return res.data.response_data;
    } catch (error) {
      return rejectWithValue('Sorry! Something went wrong');
    }
  }
);
export const RequestWithdrawal = createAsyncThunk(
  'Dashboard/RequestWithdrawal',
  async (rp:{ currency: eCurrency} , { rejectWithValue }) => {
    try {
      const res = await client.post('/Dashboard/RequestWithdrawal', rp);
      return res.data.response_data;
    } catch (error) {
      return rejectWithValue('Sorry! Something went wrong');
    }
  }
);