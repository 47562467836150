import { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { DoYouHaveAccount } from './new-channel/do-you-account';
import { AccountTypeConnect } from './new-channel/account-connect';
import { ShopSelector, ShopSelectorImage } from './new-channel/shop-selector';
import { StoreLocation } from './new-channel/store-location';
import { UserName } from './new-channel/username';
import { useAppDispatch, useAppSelector } from 'src/custom-hooks/reduxCustomHooks';
import { CreateNewChannel, eApiType, paidHostExtension, SaveStats } from 'src/redux/new-channel/newChannelThunk';
import '../../sass/new-channel.scss';
import { store } from '../../redux/store';
import { eShop, ShopToPlatform } from './new-channel/eShop';
import { useHistory, useParams } from 'react-router';
import { Countries, eCountry } from '../../data/countries';
import { Links } from '../../links';
import { NewChannelSState } from '../../redux/new-channel/newChannelSlice';
import { getChannels, getLinkAccountURL } from '../../redux/channels/channelsThunk';
import { eNewChannelStep, NextStep, PreviousStep } from './new-channel/step';
import { ePlatform } from '../../data/platforms';
import { NewChannelCallback } from './new-channel/new-channel-callback';
import { NoApiServerSelect } from './new-channel/noapi-server-select';
import { ApiLink } from './new-channel/ApiLink';
import { ReactUtils } from '../../utils/react-utils';
import { Expert } from './new-channel/expert';

const parseIntNull = (v: string | undefined | null) => {
  if (v == null) return v;
  return parseInt(v);
};

export const NewChannel = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const channels = ReactUtils.GetAllChannels();

  const { success } = useAppSelector((state) => state.newChannel);
  //const { user } = useAppSelector((state) => state.user);
  const [alreadyExists, SetAlreadyExists] = useState<boolean | undefined>(undefined);
  const [expert, SetExpert] = useState<boolean | undefined>(undefined);
  const [ sessionId, _ ] = useState<string>('xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) { const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8); return v.toString(16); }));
  const { shop, step, storeLocation, username, api, noApiServer } = (() => {
    const { step, sh, ct, nm, api, nas } = useParams<{ step?: string; sh?: string; ct?: string; nm?: string, api?: string; nas?: string }>();

    const p = parseIntNull(step);
    const h = parseIntNull(sh);
    //if (user?.user?.id == 'ce2eb726-78f3-4bf1-a01a-e7eb5c153015' || user?.user?.id == 'eae317e1-10af-43b1-a8f5-6a7f15fd5537') {
    //  if (p == null || p < 30) {
    //    p = 30;
    //  }
    //  h = 2;
    //}

    const defaultStep = channels.length == 0 ? eNewChannelStep.Expert : eNewChannelStep.Shop;

    return {
      shop: h as eShop | undefined,
      step: (p ?? defaultStep) as eNewChannelStep,
      storeLocation: parseIntNull(ct) as eCountry | null ?? null,
      username: decodeURIComponent(nm ?? ''),
      api: (api == null ? null : parseInt(api) as eApiType),
      noApiServer: nas == null ? null : nas == '1'
    };
  })();

  const SetStep = (
    newStep: number,
    newProps?: { shop?: eShop, storeLocation?: number, api?: eApiType, noApiServer?: boolean, username?: string, expert?: boolean }
  ) => {
    const sl = newProps?.storeLocation as eCountry | undefined ?? storeLocation ?? undefined;
    const sh = newProps?.shop ?? shop;
    const apis = newProps?.api ?? api ?? undefined;
    const nas = newProps?.noApiServer ?? noApiServer ?? undefined;
    const uss = newProps?.username ?? username;
    const exp = newProps?.expert ?? expert;

    dispatch(SaveStats({
      external: false,
      token: sessionId,
      panel: newStep.toString(),

      alreadyExists: alreadyExists,
      api: api ?? undefined,
      //hasAccount:,
      IAmExpert: exp,
      channel: sh != null ? eShop[sh] : undefined,
      country: storeLocation ?? undefined,
      //finalChoice:,
      //finished:,
      hostExtension: nas ?? undefined,
      storeName: uss
    }));

    const url = Links.NewChannel.Produce(
      newStep,
      sh,
      sl,
      apis,
      nas,
      uss
    );

    history.push(url + history.location.search);
  };
  const SetShop = (shop: eShop) =>                    SetStep(NextStep(step, shop, api), { shop: shop });
  const SetStoreLocation = (storeLocation: number) => SetStep(NextStep(step, shop ?? eShop.eBay, api), { storeLocation });
  const SetUsername = (username: string) =>           SetStep(NextStep(step, shop ?? eShop.eBay, api), { username });
  const SetApi = (value: eApiType) =>                 SetStep(NextStep(step, shop ?? eShop.eBay, value), { api: value });
  const SetNoApiServer = (value: boolean) =>          SetStep(NextStep(step, shop ?? eShop.eBay, api), {noApiServer: value});

  //
  //STEP ACTIONS
  //
  //const OpenPopupWindow = async (url: string, win: Window & typeof globalThis, w: number, h: number) => {
  //  if (win?.top == null) return;
  //  const t = win.top.outerHeight / 2 + win.top.screenY - h / 2;
  //  const l = win.top.outerWidth / 2 + win.top.screenX - w / 2;
  //  const newWindow = win.open(
  //    url,
  //    '_blank',
  //    `toolbar=no, location=yes, directories=no, status=no, menubar=no,
  //                    scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${t}, left=${l}`
  //  );

  //  window.clearInterval(windowTimer);
  //  localStorage.setItem('newChannelSuccess', 'false');
  //  const timer = window.setInterval(() => {
  //    if (newWindow?.closed) {
  //      window.clearInterval(windowTimer);
  //      window.clearInterval(timer); //Actually this is the one that will work
  //      if (localStorage.getItem('newChannelSuccess') === 'true') {
  //        SetStep(NextStep(step, shop ?? eShop.eBay, api));
  //      } else {
  //        toastAlert('Unknown error', 'error');
  //        SetStep(PreviousStep(step, shop ?? eShop.eBay, api));
  //      }
  //    }
  //  }, 1000);
  //  SetWindowTimer(timer);
  //};

  const LinkApiAccount = async (success: boolean) => {
    if (success) {
      SetStep(NextStep(step, shop ?? eShop.eBay, api));
      return;
    }
    const channelId = ShopToPlatform(shop ?? eShop.eBay, api);

    //Shopify---------------------------
    if (channelId == ePlatform.Shopify) {
      location.href = 'https://apps.shopify.com/hustle-got-real';
      return;
    }
    //Ebay & Woocomerce----------------------------
    await dispatch(
      getLinkAccountURL({
        channel: channelId,
        country: storeLocation ?? eCountry.UK,
        shopName: username,
        redirectionUrl: window.location.origin + Links.NewChannelCallBack.Produce(channelId, storeLocation ?? eCountry.UK)
      })
    );
    const { shopURL } = store.getState().newChannel as NewChannelSState;
    location.href = shopURL;
    //OpenPopupWindow(shopURL, window, 800, 600);
  };

  const LinkNoApiAccount = async () => {
    const channel = ShopToPlatform(shop ?? eShop.eBay, api);
    const resp = await dispatch(
      CreateNewChannel({
        isoCountry: storeLocation ?? eCountry.UK,
        channel: channel,
        channelStoreIdentifier: username as string
      })
    );
    if ((resp.payload as { status: number }).status == 200) {
      if (noApiServer) {
        dispatch(paidHostExtension());
      }
      SetAlreadyExists(false);
      SetStep(NextStep(step, shop ?? eShop.eBay, api));
    } else {
      if ((resp.payload as { status: number }).status == 200) {
        SetAlreadyExists(true);
      }
      SetStep(PreviousStep(step, shop ?? eShop.eBay, api));
    }
  };
  useEffect(() => {
    switch (step) {
      case eNewChannelStep.NoApiLink:
        LinkNoApiAccount();
        break;
      case eNewChannelStep.End:
        (async () => {
          await dispatch(getChannels());
          history.push(Links.Dashboard);
        })();
        break;
    }
  }, [step]);
  useEffect(() => {
    switch (step) {
      case eNewChannelStep.ApiLink:
        LinkApiAccount(success);
        break;
    }
  }, [step, success]);

  //
  //HANDLES
  //

  const HandleNext = () => SetStep(NextStep(step, shop ?? eShop.eBay, api));
  const HandleExpertNext = (expert?: boolean) => {
    SetExpert(expert);
    SetStep(NextStep(step, shop ?? eShop.eBay, api), { expert });
  };
  const HandleChangePlatform = (value: number) => SetShop(value);
  const HandleChangeLocation = (value: number) => SetStoreLocation(value);
  const HandleChangeApi = (value: eApiType) => SetApi(value);
  const HandleChangeExtension = (value: boolean) => SetNoApiServer(value);

  //
  //RENDER
  //
  const RenderExpert = () => <Expert onContinue={HandleExpertNext} />;
  const RenderShop = () => <ShopSelector shop={shop ?? eShop.eBay} step={step} onChangePlatform={HandleChangePlatform} />;
  const RenderCountry = () => (
    <StoreLocation shop={shop ?? eShop.eBay} step={step} onChangeLocation={HandleChangeLocation} selectedLocation={storeLocation} />
  );
  const RenderDoYouHave = () => <DoYouHaveAccount platform={shop ?? eShop.eBay} step={step} onNext={HandleNext} />;
  const RenderApiNoApi = () => (
    <AccountTypeConnect
      api={api}
      shop={shop ?? eShop.eBay}
      onChangeApi={HandleChangeApi}
    />
  );
  const RenderNoApiServer = () => (
    <NoApiServerSelect
      shop={shop ?? eShop.eBay}
      onChangeNoApiServer={HandleChangeExtension}
    />
  );
  const RenderName = () => <UserName shop={shop ?? eShop.eBay} onContinue={SetUsername} />;
  const RenderAPILink = () => <ApiLink />;
  const RenderNoAPILink = () => <Spin />;
  const RenderCallBack = () => (
    <NewChannelCallback site={(storeLocation ?? undefined) as eCountry | undefined} platform={ShopToPlatform(shop ?? eShop.eBay, api)} />
  );
  const RenderPanel = () => {
    switch (step) {
      case eNewChannelStep.Expert:
        return RenderExpert();
      default:
      case eNewChannelStep.Shop:
        return RenderShop();
      case eNewChannelStep.Country:
        return RenderCountry();
      case eNewChannelStep.DoYouHave:
        return RenderDoYouHave();
      case eNewChannelStep.ApiNoApi:
        return RenderApiNoApi();
      case eNewChannelStep.NoApiServer:
        return RenderNoApiServer();
      case eNewChannelStep.Name:
        return RenderName();
      case eNewChannelStep.ApiLink:
        return RenderAPILink();
      case eNewChannelStep.NoApiLink:
        return RenderNoAPILink();
      case eNewChannelStep.CallBack:
        return RenderCallBack();
      case eNewChannelStep.End:
        return <Spin />;
    }
  };

  const RenderSelection = () => {
    const hasNoApi = shop == eShop.eBay || shop == eShop.Amazon || shop == eShop.Facebook;
    return <div className="creation-choosed">
      {shop && <ShopSelectorImage shop={shop} />}
      {storeLocation && Countries[storeLocation].Flag}
      {(api == eApiType.API || !hasNoApi) && 'API'}
      {api == eApiType.NO_API && hasNoApi && 'NO API'}
    </div>;
  };

  return (
    <div className="new-channel-container">
      <div className="g-section">
        {RenderSelection()}
        <div className="left-section">
          {RenderPanel()}
        </div>
      </div>
    </div>
  );
};
