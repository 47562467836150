import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../custom-hooks/reduxCustomHooks';
import { getSources } from '../../../redux/sources/sourcesThunk';
import { DangerBtn, PrimaryBtn } from '../../../small-components/ActionBtns';
import { SwitchLabel } from '../../../small-components/form/switch-label';
import { PopupModal } from '../../../small-components/modals/popup-modal';
import { ReactUtils } from '../../../utils/react-utils';
import { T } from '../../../utils/transShim';
import { CatalogSource } from '../../sources/catalog-source';
import { Links } from 'src/links';

import robotIcon from 'src/assets/icons/robot.svg';
import { A } from '../../../small-components/A';

interface Props {
  SetSourcesIds: (scs: number[]) => void;
  sourcesIds: number[];
  onNext: () => void;
}

export const SourcesStep = (props: Props) => {
  const dispatch = useAppDispatch();
  const channel = ReactUtils.GetSelectedChannel();
  const { SetSourcesIds, sourcesIds, onNext } = props;

  const { sources, loading: sourcesLoading } = useAppSelector((state) => state.sources);

  const [iaChooses, SetIAChooses] = useState<boolean>(true);
  const [sourcesModalOpen, SetSourcesModalOpen] = useState<boolean>();

  useEffect(() => {
    if (!!sources && sources.length > 0 && sources[0].site == channel?.isoCountry?.toString()) return;
    if (sourcesLoading) return;
    dispatch(getSources());
  }, [channel?.id]);

  useEffect(() => {
    if ((sources?.length ?? 0) == 0) return;
    const newS: number[] = [];
    for (const id of sourcesIds) {
      if (sources.find((x) => x.id == id)) newS.push(id);
    }
    SetSourcesIds(newS);
  }, [sources?.[0]?.id, channel?.id]);

  const HandleIAChooses = () => {
    SetIAChooses(true);
    SetSourcesIds([]);
  };
  const HandleOpenSourceModal = () => SetSourcesModalOpen(true);
  const HandleCloseSourceModal = () => SetSourcesModalOpen(false);

  const DrawSource = (id: number) => {
    if (!sources) return <></>;

    if (id < 0) {
      return (
        <div key={id} className="source">
          <img style={{ opacity: 0 }} className="source-img" src={require('../../../assets/logos/213.png')?.default} />
        </div>
      );
    }

    return (
      <div key={id} className="source">
        <img className="source-img" src={require('../../../assets/logos/' + id + '.png')?.default} />
      </div>
    );
  };

  const sourcesIds2 = [...(sourcesIds ?? [])];
  if (sourcesIds2.length == 0) sourcesIds2.push(-1);
  if (sourcesIds2.length == 1) sourcesIds2.push(-2);
  if (sourcesIds2.length == 2) sourcesIds2.push(-3);

  return (
    <div>
      <div className="tilte-step">
        <h2>
          <T k="SmartLister.NewFilter.Sources" />
        </h2>
      </div>
      <Row className="options">
        <Col xs={11}>
          <div className="option-select">
            <SwitchLabel label={<T k="SmartLister.Button.AIChooses" />} checked={iaChooses} onClick={HandleIAChooses} />
            <img src={robotIcon} alt="" className="robot-icon" />
          </div>
        </Col>
        <Col xs={11}>
          <div className="option-select">
            <SwitchLabel label={<T k="SmartLister.Button.IChoose" />} checked={!iaChooses} onClick={(_) => SetIAChooses(false)} />
            <div className="form-item">
              <h2>
                <T k="Catalog.Filter.ChooseYourSuppliers" />
              </h2>
              <PrimaryBtn className="supplier-one" onClick={HandleOpenSourceModal} disabled={sourcesLoading || iaChooses}>
                {sourcesIds?.length > 0 && <T k="Catalog.Filter.SuppliersQ" values={{ q: sourcesIds?.length }} />}
                {!sourcesIds?.length && <T k="Catalog.Filter.Sources.NoP" />}
              </PrimaryBtn>
              <div className="sourcesList">{sourcesIds2.map(DrawSource)}</div>
            </div>
          </div>
        </Col>
      </Row>
      <PopupModal
        key="s"
        open={sourcesModalOpen}
        onClose={HandleCloseSourceModal}
        maxWidth={800}
        title={
          <div className="modal-title">
            <h2 className="title">
              <T k="Catalog.Filter.ChooseYourSuppliers" />
            </h2>
          </div>
        }
      >
        <CatalogSource onClose={HandleCloseSourceModal} onChangeSelectedSources={SetSourcesIds} sources={sources} />
      </PopupModal>
      <div className="buttons-next-back">
        <A to={Links.SmartLister.Base}>
          <DangerBtn>Cancel</DangerBtn>
        </A>
        <PrimaryBtn onClick={onNext}>
          <T k="nxt" />
        </PrimaryBtn>
      </div>
    </div>
  );
};
