import { Spin } from 'antd';
import Tooltip from 'antd/es/tooltip';

import { Links } from '../../../../links';
import { eSmartListerFilterPausedReason } from '../../../../redux/smart-lister/data/e-smart-lister-filter-paused-reason';
import { eSmartListerFilterStatus } from '../../../../redux/smart-lister/data/e-smart-lister-filter-status';
import { SmartListerFilter } from '../../../../redux/smart-lister/data/smart-lister-filter';
import { SmartListerFilterType } from '../../../../redux/smart-lister/data/smart-lister-filter-type';
import { Source } from '../../../../redux/sources/sourceSlice';
import { A } from '../../../../small-components/A';
import { PrimaryBtn, ResetBtn, SuccessBtn, WarningBtn } from '../../../../small-components/ActionBtns';
import { ReactUtils } from '../../../../utils/react-utils';
import { T } from '../../../../utils/transShim';
import { RenderBoolean } from '../../../listings/Listings/columns-renders';
import { LabelTime } from '../../data/label-time';
import { TimeUnit } from '../../data/time-unit';

export const RenderStatus = (value: eSmartListerFilterStatus, filter: SmartListerFilter & { updating?: boolean }) => {
  if (filter.updating) {
    return <Spin />;
  }
  if (filter.dontStartUntil != null && new Date(filter.dontStartUntil) > new Date()) {
    return (
      <Tooltip
        title={<T k="SmartLister.Table.Status.Pending.Tooltip" values={{ d: ReactUtils.GetFormattedDateTime(filter.dontStartUntil) }} />}
      >
        <>{/*Tooltip won't work with custom directly as children, in that cases it needs something like this*/}
          <ResetBtn>
            <T k="SmartLister.Table.Status.Pending" />
          </ResetBtn>
        </>
      </Tooltip>
    );
  }
  if (filter.quantityMax != null && filter.quantityMax <= filter.quantityListed)
    return (
      <PrimaryBtn>
        <T k="SmartLister.Table.Status.Completed" />
      </PrimaryBtn>
    );
  switch (value) {
    case eSmartListerFilterStatus.Active:
      return (
        <SuccessBtn>
          <T k="SmartLister.Table.Status.Active" />
        </SuccessBtn>
      );
    case eSmartListerFilterStatus.Paused: {
      const btn = (
        <WarningBtn>
          <T k="SmartLister.Table.Status.Paused" />
        </WarningBtn>
      );
      if (filter.pausedReason == eSmartListerFilterPausedReason.NO_RESULTS_SYSTEM) {
        return (
          <Tooltip title={<T k="SmartLister.Table.Status.Paused.NoResults" />}>
            <>{btn}</>
          </Tooltip>
        ); //By some error, tooltip won't work directly over custom elements, that's why we add <></>
      } else {
        return btn;
      }
    }
  }
  return <></>;
};

export const GetQDFromInterval = (frequency: number) => {
  if (frequency < TimeUnit.HOUR) {
    return { number: frequency, unit: TimeUnit.MINUTE };
  }
  if (frequency < TimeUnit.DAY) {
    if (frequency % TimeUnit.HOUR == 0) return { number: frequency / TimeUnit.HOUR, unit: TimeUnit.HOUR };
    else return { number: frequency, unit: TimeUnit.MINUTE };
  }
  if (frequency % TimeUnit.DAY == 0) return { number: frequency / TimeUnit.DAY, unit: TimeUnit.DAY };
  else if (frequency % TimeUnit.HOUR == 0) return { number: frequency / TimeUnit.HOUR, unit: TimeUnit.HOUR };
  else return { number: frequency, unit: TimeUnit.MINUTE };
};
export const RenderBooleanSF = (value: boolean | undefined, filter: SmartListerFilter) => {
  if (filter.type == SmartListerFilterType.Smart)
    return '';
  return RenderBoolean(value);
};
export const RenderQuantityByTime = (value: number, filter: SmartListerFilter) => {
  if (filter.type == SmartListerFilterType.Smart)
    return <T k='SmartLister.Button.AIChoosing' />;

  const dd = GetQDFromInterval(filter.timeInterval);
  return (
    <T
      k="SmartLister.NewFilter.Frequency.ExplanationQS"
      values={{
        q: filter.quantityByTime,
        c: dd.number,
        d: LabelTime.find((x) => x.value == dd.unit)?.label
      }}
    />
  );
};
export const RenderQuantityListed = (value: number, filter: SmartListerFilter) => {
  if (!filter.quantityMax) return value;
  return value + '/' + filter.quantityMax;
};
const RenderMinMax = (min: number | null | undefined, max: number | null | undefined) => {
  if (!min && !max) return <></>;
  if (max) {
    return (min ?? 0) + ' - ' + max;
  }
  return min + ' - ∞';
};
export const RenderPrice = (value: number, filter: SmartListerFilter) => RenderMinMax(filter.priceMin, filter.priceMax);
export const RenderProfit = (value: number, filter: SmartListerFilter) => RenderMinMax(filter.profitMin, filter.profitMax);
export const RenderSourceList = (sources: Source[]) => sources?.map((x) => x.name)?.join(', ');
export const RenderCheckHistoryButton = (value: number) => (
  <A to={Links.SmartLister.OrderHistory.Create(value)}>
    <PrimaryBtn>
      <T k="SmartLister.Table.CheckHistory" />
    </PrimaryBtn>
  </A>
);
