import { Form, Input } from 'antd';
import { PrimaryBtn } from '../../../small-components/ActionBtns';
import { useAppDispatch, useAppSelector } from '../../../custom-hooks/reduxCustomHooks';
import { getForgotPassword } from 'src/redux/user/userThunk';
import { ResetData } from '../../../redux/user/userSlice';
import logo from '../../../assets/hgr-logo.png';
import '../../../sass/user-register.scss';
import { useState } from 'react';
import { A } from '../../../small-components/A';
import { T } from '../../../utils/transShim';

export const ForgetPassword = () => {
  const { userLoading } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const statusMessage = 'Enter your Registered Email address to get Reset Password link by Email';
  const successMessage = (
    <div className="spaced-container">
      <h3>Reset password confirmation email sent successfully.</h3>
      <p>Please check your email to reset your password.</p>
    </div>
  );
  const [success, setSuccess] = useState(false);
  const onFinish = async (values: ResetData) => {
    const rs = await dispatch(getForgotPassword(values));
    if (rs.payload) {
      setSuccess(true);
    }
  };
  return <>
    <div className="register-form-header">
      <div className="text-section">
        <h2 className="register-form-title">Forgot your password?</h2>
        <p>{success == true ? '' : statusMessage}</p>
      </div>
      <div>
        <img src={logo} alt="" className="logo" />
      </div>
    </div>
    {success == true ? (
      successMessage
    ) : (
      <Form
        className="register-form"
        layout="vertical"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="on"
      >
        <Form.Item
          label="Email address"
          name="email"
          rules={[
            { required: true, message: <T k="Register.InputEmail" /> },
            { type: 'email', message: <T k="Register.InputEmail" /> }
          ]}
        >
          <Input className="auth-input" />
        </Form.Item>

        <Form.Item>
          <PrimaryBtn htmlType="submit" disabled={userLoading}>
            {userLoading ? 'Please wait...' : 'Submit'}
          </PrimaryBtn>
        </Form.Item>
      </Form>
    )}
    <div className="no-account">
      <p>
        Don&apos;t have account yet?{' '}
        <A className="alternative-link" to="/register">
          Register
        </A>
      </p>
    </div>
  </>;
};
