import { Col, Row } from 'antd';

import { ePlatform, GetPlatform } from '../../../data/platforms';
import { A } from '../../../small-components/A';
import { ProductInChannelData } from './product-in-channel-data';

export interface ProductListProductProps {
  product: ProductInChannelData;
  onSelected?: (product: ProductInChannelData) => void;
  channelId: ePlatform;
}

export const ProductListProduct = (props: ProductListProductProps) => {
  const OnClick = () => {
    if (props.onSelected) props.onSelected(props.product);
  };

  const OnClickStopPropagation = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation();
    return false;
  };

  const prd = props.product;

  console.log(props.product);

  const chn = GetPlatform(props.channelId);
  return (
    <div className={'product'} onClick={(_) => OnClick()}>
      <Row>
        <Col sm={24} className="image">
          <img src={prd.mainImage} />
        </Col>
        <Col sm={24} className="description">
          <div className="price">{prd.price}</div>
          <div className="link">
            <A to={prd.url} onClick={OnClickStopPropagation} target="_blank" rel="noreferrer">
              See on {chn.storeName}
            </A>
          </div>
        </Col>
      </Row>
    </div>
  );
};
