import { Button, Col, Form, Input, Row } from 'antd';
import { T } from '../../../../../../utils/transShim';
import { CommonTypeValue, SetValueNumber } from '../types';

type Props = {
  label: string;
  value: SetValueNumber;
  commonValue: CommonTypeValue;
  onChange: (p: SetValueNumber) => void;
  editField: (value: SetValueNumber, onChange: (p: SetValueNumber) => void) => React.ReactNode;
  showCommonValue: boolean;
};

export const MultipleFieldBase = (props: Props) => {
  const { label, value, commonValue, onChange, editField, showCommonValue } = props;


  const RenderCommonValue = (v: CommonTypeValue) => {
    if (!showCommonValue)
      return <></>;
    if (!v || !v?.equal)
      return <>Multiple values</>;
    if (v.value == null)
      return <T k='Setting.FinalDefinedBySettingsEmpty' />;
    return <>{v.value}</>;
  };

  const ToEditField = (value: SetValueNumber) => {
    if (value != null)
      return <></>;
    return <>
      {RenderCommonValue(commonValue)}
      <Button onClick={() => onChange({ value: (commonValue?.value as number | undefined) ?? 1 })}>Edit</Button>
    </>;
  };
  const EditingField = (value: SetValueNumber, onChange: (p: SetValueNumber) => void) => {
    if (value == null)
      return <></>;
    return (
      <Input.Group>
        {editField(value, onChange)}
        <Button onClick={() => onChange(null)}>Cancel</Button>
      </Input.Group>
    );
  };
  return (
    <Row gutter={[40, 0]}>
      <Col span={24} >
        <Form.Item label={<T k={label} />}>
          {ToEditField(value)}
          {EditingField(value, onChange)}
        </Form.Item>
      </Col>
    </Row>
  );
};