import { ReactElement, JSXElementConstructor, ReactNode, CSSProperties } from 'react';
import { Select } from 'antd';
import '../sass/selector.scss';
import { CountriesRelation } from '../data/countries';

interface Props {
  defaultValue?: string;
  onChange?: (value: string) => void;
  size?: sizeType;
  value?: string;
  dropdownRender?: (
    menu: ReactElement<ReactNode, string | JSXElementConstructor<ReactNode>>
  ) => ReactElement<ReactNode, string | JSXElementConstructor<ReactNode>>;
  loading?: boolean;
  style?: CSSProperties;
  className?: string;
  emptyValue?: string;
}

type sizeType = 'large' | 'small' | 'middle';

export const CountrySelector: React.FC<Props> = (props: Props) => {
  const { defaultValue, value, onChange, dropdownRender, loading, style, size, emptyValue } = props;

  const MapOptions = () => {
    let cs = CountriesRelation;
    if (emptyValue) { 
      cs = cs.map((x) => {
        if (x.id != '')
          return x;
        else
          return { id: x.id, name: emptyValue };
      });
    }
    return cs.map(x => <option value={x.id} key={x.id}>{x.name}</option>);
  };

  return (
    <Select
      style={style}
      className="selector"
      allowClear={false}
      onChange={onChange}
      showSearch
      placeholder="Default"
      defaultValue={defaultValue}
      value={value}
      filterOption={(input, option) =>
        (option?.value as unknown as string).toLowerCase().includes(input.toLowerCase()) ||
        (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
      dropdownRender={dropdownRender}
      loading={loading}
      size={size}
    >
      {MapOptions()}
    </Select>
  );
};
