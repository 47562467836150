import { createSlice } from '@reduxjs/toolkit';
import { BulkStatus, ChannelListingStatusAndError } from '../../components/catalog/Types';
import { eBulkListingStatus } from '../listings/auto-list-slice';
import { eChannelListingStatus } from '../listings/listings-slice';
import { ListPendingNow, RemovePendingListing } from '../listings/listings-thunk';
import { GetBulkListingStatus, GetCatalogProducts, GetDashboardSuggestedProducts, ListProducts } from './catalogThunk';

export interface ProductBeingSend {
  publishNow: Date | undefined;
  id: number;
  title: string;
}

export enum ErrorWhy {
  NoError = 0,
  NoTokens = 1, //enought
  NoQuota = 2
}

export interface CatalogProduct {
  id: number;
  sourceId: number;
  imageUrl: string;
  sourcePrice: number;
  sourceName: string;
  title: string;
  url: string;
  profit: number;
  channelPrice: number;
  options: number;
  competition: number;
  sold: number;
  priority: number;
  quantityListed: number;
  [key: string]: number | string | boolean | null | undefined | BulkStatus;
  page: number;
  totalResults: number;
  pageSize: number;
  sessionId: number;
  option: number;
  //productId: number;
  hiddenInCart: boolean;
  beingSend: boolean;
  sent: boolean;
  batchId: string;
}

export interface selectedProductDetailData {
  id: number;
  sourceId: number;
  imageUrl: string;
  sourcePrice: number;
  title: string;
  url: string;
  profit: number;
  channelPrice: number;
  sold: number;
  competition: number;
  options: number;
  priority: number;
  quantityListed: number;
  [key: string]: number | string | boolean | null | undefined | BulkStatus;
  page: number;
  totalResults: number;
  pageSize: number;
  sessionId: number;
  option: number;
  productId: number;
}

export type CatalogStatusData = {
  channelListingId?: number;
  errorMessage: string;
  status: eBulkListingStatus;
  productSourceId: number;
  listingStatus: ChannelListingStatusAndError;
}

export type CatalogSliceState = {
  catalogProducts?: CatalogProduct[];
  loading: boolean;
  error: string;
  status?: {
    loading: boolean;
    lastLoad?: Date;
    data: CatalogStatusData[]
  },
  dashboard?: {
    products?: CatalogProduct[];
    loading: boolean;
    error?: string;
  }
};

const initialState: CatalogSliceState = {
  catalogProducts: [] as CatalogProduct[],
  loading: false,
  error: '',
  status: { loading: false, data: [] }
};

export type CatalogListProductListState = {
  loading: boolean;
  error: string;
}
const listProductInitialState: CatalogListProductListState = {
  loading: false,
  error: ''
};

export const catalogSlice = createSlice({
  name: 'catalog',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Get products
    builder.addCase(GetCatalogProducts.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(GetCatalogProducts.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.catalogProducts = payload;
    });
    builder.addCase(GetCatalogProducts.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = String(payload);
    });

    //Get products for dashboard
    builder.addCase(GetDashboardSuggestedProducts.pending, (state) => {
      state.dashboard = { loading: true };
      state.error = '';
    });
    builder.addCase(GetDashboardSuggestedProducts.fulfilled, (state, { payload }) => {
      state.dashboard = { loading: false, products: payload };
    });
    builder.addCase(GetDashboardSuggestedProducts.rejected, (state, { payload }) => {
      state.dashboard = { loading: false, error: String(payload) };
    });

    //Get status
    builder.addCase(GetBulkListingStatus.pending, (state) => {
      if (!state.status)
        state.status = { loading: true, data: [] };
      else
        state.status.loading = true;
    });
    builder.addCase(GetBulkListingStatus.fulfilled, (state, { payload }) => {
      if (!state.status)
        state.status = { loading: false, data: payload?.data ?? [], lastLoad: new Date() };
      else {
        state.status.loading = false;
        state.status.data = payload?.data ?? [];
        state.status.lastLoad = new Date();
      }
    });
    builder.addCase(GetBulkListingStatus.rejected, (state) => {
      if (!state.status)
        state.status = { loading: false, data: [] };
      else
        state.status.loading = false;
    });

    //Process now pending listing
    builder.addCase(ListPendingNow.fulfilled, (state, { payload, meta }) => {
      //state.saving = { loading: false, success: payload };

      if (!payload)
        return;

      if (!state?.status?.data)
        return;

      for (const s of state.status.data) {
        if (s.channelListingId == meta.arg) {
          if (s.listingStatus)
            s.listingStatus.status = eChannelListingStatus.ListingInStore;
          return;
        }
      }
    });
    //Remove pending listings
    builder.addCase(RemovePendingListing.fulfilled, (state, { payload, meta }) => {
      if (!payload)
        return;

      if (!state?.status?.data)
        return;

      for (let i = 0; i < state.status.data.length; i++) {
        if (state.status.data[i].channelListingId == meta.arg) {
          state.status.data.splice(i, 1);
          return;
        }
      }

    });

  }
});


export const catalogListProductSlice = createSlice({
  name: 'catalogSearch',
  initialState: listProductInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ListProducts.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(ListProducts.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ListProducts.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = String(payload);
    });
  }
});

export const { reducer: catalogProductsReducer } = catalogSlice;
export const { reducer: catalogListProductsReducers } = catalogListProductSlice;