import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { CSSProperties, Ref } from 'react';

import '../sass/action-btns.scss';

interface Props {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  disabled?: boolean;
  id?: string;
  htmlType?: htmlType;
  refC?: Ref<HTMLButtonElement> | undefined;
  style?: CSSProperties | undefined;
  title?: string;
}

type htmlType = 'button' | 'submit' | 'reset';

export const ProgressBtn = (props: Props) => {
  const { children, className, disabled, htmlType, onClick, refC, style, title } = props;
  return (
    <Button
      className={'progress-btn ' + (className != null ? className : '')}
      title={title}
      style={style}
      onClick={onClick}
      disabled={disabled}
      htmlType={htmlType}
      ref={refC}
    >
      <p>{children}</p>
    </Button>
  );
};

export const WarningBtn = (props: Props) => {
  const { children, className, disabled, htmlType, onClick, refC, style, title } = props;
  return (
    <Button
      className={'warning-btn ' + (className != null ? className : '')}
      title={title}
      style={style}
      onClick={onClick}
      disabled={disabled}
      htmlType={htmlType}
      ref={refC}
    >
      <p>{children}</p>
    </Button>
  );
};

export const PrimaryBtn = (props: Props) => {
  const { children, className, disabled, htmlType, onClick, refC, style, title } = props;
  return (
    <Button
      className={'primary-btn ' + (className != null ? className : '')}
      title={title}
      style={style}
      onClick={onClick}
      disabled={disabled}
      htmlType={htmlType}
      ref={refC}
    >
      <p>{children}</p>
    </Button>
  );
};

export const SecondaryBtn = (props: Props) => {
  const { children, className, disabled, htmlType, onClick, refC, style, title } = props;
  return (
    <Button
      className={'secondary-btn ' + (className != null ? className : '')}
      title={title}
      style={style}
      onClick={onClick}
      disabled={disabled}
      htmlType={htmlType}
      ref={refC}
    >
      <p>{children}</p>
    </Button>
  );
};

export const BoldButton = (props: Props) => {
  const { children, className, disabled, htmlType, onClick, refC, style, title } = props;
  return (
    <Button
      className={'bold-btn ' + (className != null ? className : '')}
      title={title}
      style={style}
      onClick={onClick}
      disabled={disabled}
      htmlType={htmlType}
      ref={refC}
    >
      <p>{children}</p>
    </Button>
  );
};

export const AddButton = (props: Props) => {
  const { children, className, disabled, htmlType, onClick, refC, style, title } = props;
  return (
    <div className="add-button">
      <Button
        className={'rounded-btn ' + (className != null ? className : '')}
        title={title}
        style={style}
        onClick={onClick}
        disabled={disabled}
        htmlType={htmlType}
        ref={refC}
      >
        <PlusCircleOutlined className="icon" />
        <p>{children}</p>
      </Button>
    </div>
  );
};

export const SuccessBtn = (props: Props) => {
  const { children, className, disabled, htmlType, onClick, refC, style, title } = props;
  return (
    <Button
      className={'success-btn ' + (className != null ? className : '')}
      onClick={onClick}
      disabled={disabled}
      htmlType={htmlType}
      ref={refC}
      style={style}
      title={title}
    >
      <p>{children}</p>
    </Button>
  );
};

export const DangerBtn = (props: Props) => {
  const { children, onClick, className, disabled, htmlType, refC, style, title } = props;
  return (
    <Button
      className={'danger-btn ' + (className != null ? className : '')}
      title={title}
      style={style}
      onClick={onClick}
      disabled={disabled}
      htmlType={htmlType}
      ref={refC}
    >
      <p>{children}</p>
    </Button>
  );
};

export const CancelBtn = (props: Props) => {
  const { children, disabled, onClick, htmlType, refC, style, title } = props;
  return (
    <Button className="cancel-btn" onClick={onClick} disabled={disabled} title={title} style={style} htmlType={htmlType} ref={refC}>
      <p>{children}</p>
    </Button>
  );
};

export const ShowVisibleColBtn = (props: Props) => {
  const { children, onClick, disabled, htmlType, refC, style, title } = props;
  return (
    <Button className="showVisibleCol-btn" onClick={onClick} disabled={disabled} title={title} style={style} htmlType={htmlType} ref={refC}>
      <p>{children}</p>
    </Button>
  );
};

export const ResetBtn = ({ children, onClick, htmlType, refC, style, title }: Props) => (
  <Button className="reset-btn" onClick={onClick} title={title} style={style} htmlType={htmlType} ref={refC}>
    <p>{children}</p>
  </Button>
);
export const DeleteBtn = ({ children, onClick, htmlType, refC, style, title }: Props) => (
  <Button className="delete-btn" onClick={onClick} title={title} style={style} htmlType={htmlType} ref={refC}>
    <p>{children}</p>
  </Button>
);
export const TransparentBtn = (props: Props) => {
  const { id, children, className, onClick, htmlType, style, title } = props;
  return (
    <Button
      id={id}
      className={'transparent-btn ' + (className != null ? className : '')}
      style={style}
      title={title}
      onClick={onClick}
      htmlType={htmlType}
    >
      <p>{children}</p>
    </Button>
  );
};
