import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useState } from 'react';
import { T } from '../../../../utils/transShim';

type Props = {
  name?: string;
  onChange: (value: string | undefined | null) => void;
}
export const BooleanField = (props: Props) => {
  const { onChange, name } = props;

  const [currentValue, setCurrentValue] = useState<string | null | undefined>();

  const HandleChange = (e: CheckboxChangeEvent) => {
    const newValue = (() => {
      if (e.target.checked)
        return e.target.value;
      return null;
    })();
    onChange(newValue);
    setCurrentValue(newValue);
  };

  return (
    <div className='max-min-section' key={name}>
      <Checkbox name={name} id={name + '_1'} value='1' checked={currentValue === '1'} onChange={HandleChange} key={1} />
      <label htmlFor={name + '_1'}><T k='Setting.Yes' /></label>
      <Checkbox name={name} id={name + '_0'} value='0' checked={currentValue === '0'} onChange={HandleChange} key={0} />
      <label htmlFor={name + '_0'}><T k='Setting.No' /></label>
    </div>
  );
};