import { Card, Divider, Button } from 'antd';
import '../../sass/browser-extensions.scss';
import chrome from '../../assets/browser-extensions/chrome-logo.png';
import opera from '../../assets/browser-extensions/opera-logo.png';
import edge from '../../assets/browser-extensions/edge-logo.png';
import noapiext from '../../assets/browser-extensions/noapi-extension.png';
import listingext from '../../assets/browser-extensions/listing-extension.png';

import { ReactUtils } from 'src/utils/react-utils';
import { ePlatform } from '../../data/platforms';
import { Links } from '../../links';

import { A } from '../../small-components/A';

export const BrowserExtensions = () => {
  const selectedChannel = ReactUtils.GetSelectedChannel();

  const ebayListingVideo = 'https://www.youtube.com/watch?v=P-CjSHtd4mQ';
  const ebayNoapiListingVideo = 'https://www.youtube.com/watch?v=paqPCAwj1lg';
  const AmazonListingVideo = 'https://www.youtube.com/watch?v=5lz8hStFAzQ';
  const shopifyListingVideo = 'https://www.youtube.com/watch?v=B074HHdXZXo';

  const AmazonNoapiExtension = 'https://www.youtube.com/watch?v=p920COtjL2o';
  const ebayNoapiNoapiExtension = 'https://www.youtube.com/watch?v=esayxH6bzs8';

  const videoListingExtension = () => {
    switch (selectedChannel?.channelId) {
      default:
      case ePlatform.eBay:
        return ebayListingVideo;
      case ePlatform.eBayNoApi:
        return ebayNoapiListingVideo;
      case ePlatform.Amazon:
        return AmazonListingVideo;
      case ePlatform.Shopify:
        return shopifyListingVideo;
    }
  };

  const videoNoapiExtension = () => {
    switch (selectedChannel?.channelId) {
      default:
      case ePlatform.eBayNoApi:
        return ebayNoapiNoapiExtension;
      case ePlatform.Amazon:
        return AmazonNoapiExtension;
    }
  };

  const onlyManualData = [
    {
      id: 1,
      title: 'Listing Extension',
      description: <p className="description">Avaialble for the following browsers</p>,
      img: <img src={listingext} alt="" className="cover-img" />,
      browsers: (
        <div className="browsers">
          <img src={edge} alt="edge" />
          <img src={chrome} alt="chrome" />
          <img src={opera} alt="opera" />
        </div>
      ),
      divider: <Divider />,
      installText: <p className="install-text">Install and start selling!</p>,
      installBtn: (
        <Button
          target={'_blank'}
          className="install-btn"
          href="https://chromewebstore.google.com/detail/lister-by-hustle-got-real/fakikmhjpdbdilplbjipceklhdglocmk?hl=en"
        >
          Install
        </Button>
      ),
      tutorial: (
        <A rel="noreferrer" target='_blank' to={videoListingExtension()} className="tutorial">
          <span>
            Manual Lister tutorial
            <i className="fa fa-arrow-right" aria-hidden="true" />
          </span>
        </A>
      )
    }
  ];

  const manualAndNoapiData = [
    {
      id: 1,
      title: 'Listing Extension',
      description: <p className="description">Avaialble for the following browsers</p>,
      img: <img src={listingext} alt="" className="cover-img" />,
      browsers: (
        <div className="browsers">
          <img src={edge} alt="edge" />
          <img src={chrome} alt="chrome" />
          <img src={opera} alt="opera" />
        </div>
      ),
      divider: <Divider />,
      installText: <p className="install-text">Install and start selling!</p>,
      installBtn: (
        <Button
          target={'_blank'}
          className="install-btn"
          href="https://chromewebstore.google.com/detail/lister-by-hustle-got-real/fakikmhjpdbdilplbjipceklhdglocmk?hl=en"
        >
          Install
        </Button>
      ),
      tutorial: (
        <A rel="noreferrer" target={'_blank'} to={videoListingExtension()} className="tutorial">
          <span>
            Manual Lister tutorial
            <i className="fa fa-arrow-right" aria-hidden="true" />
          </span>
        </A>
      )
    },
    {
      id: 2,
      title: 'Non API Extension',
      img: <img src={noapiext} alt="" className="cover-img" />,
      description: <p className="description">Avaialble for the following browsers</p>,
      browsers: (
        <div className="browsers">
          <img src={edge} alt="edge" />
          <img src={chrome} alt="chrome" />
          <img src={opera} alt="opera" />
        </div>
      ),
      divider: <Divider />,
      installText: <p className="install-text">Install and start monitoring!</p>,
      installBtn: (
        <Button
          target={'_blank'}
          className="install-btn"
          href={Links.HGRNoApiExtension}
        >
          Install
        </Button>
      ),
      tutorial: (
        <A rel="noreferrer" target={'_blank'} to={videoNoapiExtension()} className="tutorial">
          <span>
            No API tutorial
            <i className="fa fa-arrow-right" aria-hidden="true" />
          </span>
        </A>
      )
    }
  ];

  const dataExtensions = () => {
    if (selectedChannel?.channelId == 1 || selectedChannel?.channelId == 2) {
      return onlyManualData;
    } else {
      return manualAndNoapiData;
    }
  };

  return (
    <div className="browser-extensions-container">
      <div className="browser-extensions">
        {dataExtensions().map((d,i) => (
          <Card className="card" key={i}>
            <div className="upper-section">
              <h6 className="title">{d.title}</h6>
              {d.img}
              {d.description}
              {d.browsers}
            </div>
            <div className="lower-section">
              {d.divider}
              {d.installText}
              {d.installBtn}
            </div>
            <div className="tutorial-section">{d.tutorial}</div>
          </Card>
        ))}
      </div>
    </div>
  );
};
