import { useAppDispatch } from '../../custom-hooks/reduxCustomHooks';
import { ListingService } from 'src/redux/dashboard/listingServicesSlice';
import { PrimaryBtn } from 'src/small-components/ActionBtns';
import '../../sass/listing-service/configure-listing-service.scss';
import { Channel } from 'src/redux/channels/channelsSlice';
import { MultipleSelector } from 'src/small-components/form/multiple-selector';
import { Selector, SelectorValue } from 'src/small-components/form/selector';
import { Input, Radio, RadioChangeEvent, Row, Spin, Modal } from 'antd';
import { SimpleTable } from 'src/small-components/tables/simple-table';
import { useState } from 'react';
import { Source } from '../../redux/sources/sourceSlice';
import { GetListingServices, AddListingService } from '../../redux/dashboard/listingServicesThunk';

import { toastAlert } from '../../utils/toastAlert';
import { Countries, eCountry } from '../../data/countries';
import { ReactUtils } from '../../utils/react-utils';
import { CheckCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { ChannelLabel } from '../../small-components/channel-label';
import { ePlatform } from '../../data/platforms';
import { A } from '../../small-components/A';


type SourceValueType = {
  value: number;
  site: keyof typeof eCountry;
  label: React.ReactNode;
};

type Props = {
  listingServicesResult: ListingService[];
  loading: boolean;
  sources: Source[];
};

export const ConfigureListingServiceCore = (props: Props) => {
  const { listingServicesResult, loading, sources } = props;

  const dispatch = useAppDispatch();
  const channels = ReactUtils.GetAllChannels();
  const channel = ReactUtils.GetSelectedChannel();

  const criteriaOptions = [
    { value: 'hgr', label: 'No preferences' },
    { value: 'user', label: 'Set up my preferences' }
  ];
  const [selectedService, SetSelectedService] = useState<ListingService | undefined>(undefined);

  const [pricePreference, SetPricePreference] = useState('source');
  const [isModalVisible, SetIsModalVisible] = useState(false);
  const [sourceList, SetSourceList] = useState<SourceValueType[]>([]);
  const [showFlags] = useState<boolean>(true);

  const SourceLabel = (c: Source) => {
    return (
      <>
        {showFlags && Countries[eCountry[c.site]].Flag}
        {c.name}
      </>
    );
  };
  const SourceValue = (c: Source): SourceValueType => {
    return {
      value: c.id,
      site: c.site,
      label: <>{SourceLabel(c)}</>
    };
  };
  const CreateLabel = (c: Channel) => <ChannelLabel channel={c} />;
  const CreateValue = (c: Channel) => {
    return {
      value: c.id,
      label: <>{CreateLabel(c)}</>
    };
  };
  const options = channels.filter((x) => x.channelId != ePlatform.Amazon).map(CreateValue);
  const sourceOptions = sources.map(SourceValue);

  const info = () => {
    Modal.info({
      title: 'Hustle Got Real',
      content: (
        <div>
          <p>
            Your prefrences have been saved. Your Listings will be updated within the next 72 hours. Thank you for using our listing
            service!
          </p>
        </div>
      )
    });
  };

  const SetMinProfit = (value: number) => {
    if (!selectedService) return;
    SetSelectedService({ ...selectedService, minProfit: value });
  };
  const SetMaxProfit = (value: number) => {
    if (!selectedService) return;
    SetSelectedService({ ...selectedService, maxProfit: value });
  };
  const SetMinSourcePrice = (value: number) => {
    if (!selectedService) return;
    SetSelectedService({ ...selectedService, minSourcePrice: value });
  };
  const SetMaxSourcePrice = (value: number) => {
    if (!selectedService) return;
    SetSelectedService({ ...selectedService, maxSourcePrice: value });
  };

  const OnOptionsChange = (e: RadioChangeEvent) => {
    const val = e.target.value;
    if (val == pricePreference) return;
    SetPricePreference(val);
    const s: ListingService = { ...selectedService } as ListingService;
    s.minProfit = undefined;
    s.maxProfit = undefined;
    s.minSourcePrice = undefined;
    s.maxSourcePrice = undefined;
    SetSelectedService(s);
  };

  const OnMinProfitChange = (e: React.ChangeEvent<HTMLInputElement>) => SetMinProfit(parseFloat(e.target.value));

  const OnMaxProfitChange = (e: React.ChangeEvent<HTMLInputElement>) => SetMaxProfit(parseFloat(e.target.value));

  const OnMinChange = (e: React.ChangeEvent<HTMLInputElement>) => SetMinSourcePrice(parseFloat(e.target.value));

  const OnMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => SetMaxSourcePrice(parseFloat(e.target.value));

  const getListingServiceStatus = (s: ListingService) => {
    if (!s.startedOn) return 'Waiting preferences...';

    if (s.endedOn)
      return (
        <>
          <p>
            Done <CheckCircleFilled className="ls-done-icon" />
          </p>
        </>
      );

    return (
      <>
        <p>
          Our team is working on your listings <LoadingOutlined />
        </p>
      </>
    );
  };

  const OnSourceChange = (value: SelectorValue[]) =>
    SetSelectedService({ ...selectedService, includedSources: value.join(',') } as ListingService);

  const HandleChannelSelected = (value: SelectorValue, s: ListingService) => {
    let ss: ListingService | undefined = selectedService;
    if (selectedService?.userSubscriptionPaymentId != s.userSubscriptionPaymentId) {
      ss = s;
    }

    const chanel = channels.filter((x) => x.id === value);
    if (ss?.channelOAuthId == value) {
      return;
    }

    const filtered = sourceOptions?.filter((x: { site: string }) => {
      if (x.site == eCountry[chanel[0]?.isoCountry as unknown as number]) return x;
    });
    SetSourceList(filtered);
    SetSelectedService({ ...ss, channelOAuthId: chanel[0]?.id } as ListingService);
  };

  const HandleChangeSelectedAccount = (value: SelectorValue, s: ListingService) => {
    if (value === 'user') {
      if (selectedService?.userSubscriptionPaymentId == s.userSubscriptionPaymentId) return;
      SetSelectedService({ ...s });
    } else {
      if (selectedService?.userSubscriptionPaymentId != s.userSubscriptionPaymentId) return;
      SetSelectedService(undefined);
    }
  };

  const HandleDoSubmit = async () => {
    SetIsModalVisible(false);
    if (!selectedService) return;
    const rp = await dispatch(AddListingService(selectedService));
    if (rp) info();
    dispatch(GetListingServices);
    SetSelectedService(undefined);
  };

  const HandleCancelSubmit = () => SetIsModalVisible(false);

  const NoSuscribed = () => {
    if (channel?.channelId == ePlatform.Amazon)
      return <></>;

    return <div className="nosuscribed-container">
      <div className="nosuscribed">
        <h3>{"Oops it looks like you don't have any no api server contracted."}</h3>
        <p>Do you want to keep your NO API extension running 24/7?</p>
        <p>We can do it for you for only 12.99GBP/month.</p>
        <PrimaryBtn>
          <A to="https://hustlegotreal.com/en/no-api-server/" rel="noreferrer" target="_blank" className="footer-link">
            Read more
          </A>
        </PrimaryBtn>
      </div>
    </div>;
  };

  const HandleTrySubmit = () => {
    if (!selectedService?.channelOAuthId) {
      toastAlert('Please select the Account Channel', 'warning');
    } else {
      SetIsModalVisible(true);
    }
  };

  const columns = [
    {
      title: 'Account',
      dataIndex: '',
      key: 'name',
      render: (s: ListingService) => {
        return (
          <Selector
            placeholder="Select channel"
            value={
              s.userSubscriptionPaymentId == selectedService?.userSubscriptionPaymentId ? selectedService.channelOAuthId : s.channelOAuthId
            }
            onChange={(value) => HandleChannelSelected(value, s)}
            disabled={s.startedOn !== null}
          >
            {options}
          </Selector>
        );
      }
    },
    {
      title: 'Criteria',
      dataIndex: '',
      key: '',
      render: (s: ListingService) => {
        return (
          <Selector
            placeholder="No preferences"
            onChange={(value) => HandleChangeSelectedAccount(value, s)}
            value={s.startedOn || selectedService?.userSubscriptionPaymentId == s.userSubscriptionPaymentId ? 'user' : 'hgr'}
            disabled={s.startedOn !== null}
          >
            {criteriaOptions}
          </Selector>
        );
      }
    },
    {
      title: 'Listings',
      dataIndex: '',
      key: '',
      render: (s: ListingService) => <h4>{s.listings}</h4>
    },
    {
      title: 'Status',
      dataIndex: '',
      key: '',
      render: (s: ListingService) => {
        return <p>{getListingServiceStatus(s)}</p>;
      }
    },
    {
      title: 'Date',
      dataIndex: '',
      key: '',
      render: (s: ListingService) => <h4>{s.purchasedOn && new Date(s.purchasedOn as unknown as string).toLocaleString()}</h4>
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: '',
      render: (s: ListingService) => (
        <div onClick={HandleTrySubmit}>
          {s.userSubscriptionPaymentId === selectedService?.userSubscriptionPaymentId && (
            <PrimaryBtn className="list">Start {s.listings} Listing</PrimaryBtn>
          )}
        </div>
      )
    }
  ];

  const showPreference = !!selectedService;

  return loading ? (
    <Spin />
  ) : (
    <div className="configure-listingservice-container">
      <h1>Configure listing services</h1>
      <p>
        We will carefully select the products to list in your account, we just need you to tell us which account you would like us to use.
      </p>
      <p>
        If you don{"'"}t have any specific preferences, we will choose the items according to our criteria to help you sell as many of them
        as possible. Alternatively, you can select Your Criteria on Criteria column and enter your preferences there.
      </p>

      <div className="listingservices-table">
        {listingServicesResult?.length ? (
          <SimpleTable columns={columns} dataSource={listingServicesResult} hidePagination={false} />
        ) : (
          NoSuscribed()
        )}
      </div>
      {showPreference && (
        <div className="configuration-section">
          {listingServicesResult?.length ? (
            <div className="listingservice-configuration">
              <h3>
                {selectedService?.listings} listing service ({channels.find((x) => x.id == selectedService?.channelOAuthId)?.name})
              </h3>
              <div className="listingservice-configuration-container">
                <div className="sources-options">
                  <div className="sources">
                    <div className="included-sources">
                      <Row>
                        <h3>Include sources</h3>
                      </Row>
                      <MultipleSelector
                        placeholder="Select your sources"
                        style={{ width: '100%' }}
                        className="multipleSelector"
                        value={selectedService?.includedSources?.split(',').map((x) => parseInt(x)) ?? []}
                        onChange={OnSourceChange}
                      >
                        {sourceList.length > 0 ? sourceList : sourceOptions}
                      </MultipleSelector>
                    </div>
                  </div>
                </div>

                <div className="price-options">
                  <Radio.Group value={pricePreference} onChange={OnOptionsChange}>
                    <Radio name="source" value="source">
                      Source price preference
                    </Radio>
                    <Radio name="profit" value="profit">
                      Profit preference
                    </Radio>
                  </Radio.Group>
                  {pricePreference === 'profit' ? (
                    <div className="inputs-container">
                      <label>Profit</label>
                      <div className="inputs">
                        <Input
                          placeholder="Min"
                          value={selectedService?.minProfit}
                          onChange={(e) => OnMinProfitChange(e)}
                          className="blue-input"
                        />
                        <Input
                          placeholder="Max"
                          className="blue-input"
                          value={selectedService?.maxProfit}
                          onChange={(e) => OnMaxProfitChange(e)}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="inputs-container">
                      <label>Source Price</label>
                      <div className="inputs">
                        <Input
                          placeholder="Min"
                          value={selectedService?.minSourcePrice}
                          onChange={(e) => OnMinChange(e)}
                          className="blue-input"
                        />
                        <Input
                          placeholder="Max"
                          className="blue-input"
                          value={selectedService?.maxSourcePrice}
                          onChange={(e) => OnMaxChange(e)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            NoSuscribed()
          )}
        </div>
      )}
      <div className="explanation-section">
        <h2>Is your store ready for us to start listing?</h2>
        <p>
          <strong>If you are selling on eBay:</strong>
        </p>
        <p>
          Make sure your billing information is completed. eBay requires you to set up a billing method with them before they enable your
          seller account. In order to do so, you can do the following:
        </p>
        <p>
          Access your eBay account and introduce your billing details (AU, DE, ES, FR, IT, USA, UK). If you are not able to find the
          section, please contact eBay via the chat on their site and request them to send you the link to complete your billing details.
        </p>
        <p>
          <strong>Your listing settings</strong>
        </p>

        <p>
          To make sure we list according to your preferences, please access your Hustle Got Real account and configure your channel
          settings.
        </p>
        <h2>Your listing preferences</h2>
        <p>
          <strong>Profit preferences</strong>
        </p>
        <p>
          Once you have purchased the service, you will be able to select your profit and suppliers preferences. Please note that when we
          calculate the profit we are referring to the net profit you will have once the item is sold. (already considering PayPal and eBay
          or Amazon estimated fees).
        </p>
        <p>Profit = buying price - selling price - fees</p>
        <p>
          If you choose a high minimum profit (higher than 3) your items will be much more expensive and therefore harder to sell. We
          recommend that option for more expert drop shippers.
        </p>
        <p>
          <strong>Suppliers preferences</strong>
        </p>
        <p>
          The important thing to consider when choosing the suppliers is shipping time. Please note that this will vary from one supplier to
          another. Some suppliers also need you to have a dropshipping account to get started, so please bear that in mind when setting up
          your preferences.
        </p>
      </div>

      <Modal
        title="Start Listing"
        open={isModalVisible}
        onOk={HandleDoSubmit}
        okText="Yes, start listing!"
        onCancel={HandleCancelSubmit}
        cancelText="Review Preferences"
      >
        <p>Please make sure you have set your listing preferences. Once the team starts the listings they can NOT be modified.</p>
      </Modal>
    </div>
  );
};
