import { Input } from 'antd';
import { useState } from 'react';
import { useAppDispatch } from '../../../../custom-hooks/reduxCustomHooks';
import { Channel } from '../../../../redux/channels/channelsSlice';
import { deleteChannel, getChannels } from '../../../../redux/channels/channelsThunk';
import { DangerBtn } from '../../../../small-components/ActionBtns';
import { QuestionModal } from '../../../../small-components/modals/question-modal';
import { T } from '../../../../utils/transShim';

type Props = {
  channel: Channel
}
export const RemoveChannelSetting = (props: Props) => {
  const { channel } = props;

  const dispatch = useAppDispatch();

  const [popupOpen, SetPopupOpen] = useState<boolean>(false);
  const [nameWritten, SetNameWritten] = useState<string>('');

  const HandleButtonClick = () => {
    SetPopupOpen(true);
    SetNameWritten('');
  };
  const HandleCancelModal = () => SetPopupOpen(false);
  const HandleRemoveChannel = async () => {
    await dispatch(deleteChannel(channel.id));
    dispatch(getChannels());
  };
  const HandleNameWritten = (e: React.ChangeEvent<HTMLInputElement>) => {
    SetNameWritten(e.currentTarget.value);
  };

  return <>
    <DangerBtn onClick={HandleButtonClick}>
      <T k='Channel.Setting.Button.RemoveChannel' />
    </DangerBtn>
    <QuestionModal
      okText={<T k='Stores.Delete' />}
      visible={popupOpen}
      onOk={HandleRemoveChannel}
      onCancel={HandleCancelModal}
      okDisabled={nameWritten != channel.name}
    >
      <T k='Stores.Delete.Question' values={{ name: channel.name }} />
      <Input value={nameWritten} onChange={HandleNameWritten} />
    </QuestionModal>
  </>;
};