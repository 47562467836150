import { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { T } from '../../utils/transShim';
import { useHistory, useParams } from 'react-router';
import { DataTable, DataTableKey } from '../../small-components/tables/data-table';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { GetAutoOrderingConfiguration } from '../../redux/auto-ordering/autoOrderingThunk';
import { Links } from '../../links';
import { getSources } from 'src/redux/sources/sourcesThunk';
import { Source } from 'src/redux/sources/sourceSlice';
import { eCountry } from '../../data/countries';
import { ReactUtils } from '../../utils/react-utils';
import { GetComputedConfiguration, getSourceConfiguration } from '../../redux/source-configuration/sources.coonfiguration-thunk';
import { ComputedSettingsData } from '../../redux/source-configuration/types';
import { AutoOrderingConfiguration } from './auto-ordering-configuration';
import Sources from '../../data/sources';

export const AutoOrderingList = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const channel = ReactUtils.GetSelectedChannel();

  const { id: selectedSource } = useParams<{ id?: string }>();
  const supplierId = parseInt(selectedSource ?? '0');

  //const { loading, suppliers } = useAppSelector((state) => state.autoOrdering);
  const { sources, loading } = useAppSelector((state) => state.sources);
  const { computedConfiguration } = useAppSelector((state) => state.sourcesConfiguration);
  const settings = computedConfiguration?.settings;
  const [selectedRowKeys, setSelectedRowKeys] = useState<DataTableKey[]>([]);

  useEffect(() => {
    dispatch(GetAutoOrderingConfiguration());
    dispatch(GetComputedConfiguration());
  }, [1]);

  useEffect(() => {
    if (!!sources && sources.length > 0 && sources[0].site == channel?.isoCountry?.toString())
      return;
    if (loading)
      return;
    dispatch(getSources());
  }, [channel?.id]);

  useEffect(() => {
    if (settings == null) dispatch(getSourceConfiguration());
  }, [settings]);

  const onSelectChange = (selectedRowKeys: DataTableKey[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    hideSelectAll: true,
    getCheckboxProps: () => {
      return {
        style: { display: 'none' }
      };
    }
  };

  // To not show the duplicated suppliers and to sort autoOrders data alphabetically
  // const uniqueData = Array.from(newSources.reduce((map:Source, obj:Source) => map.set(obj.id, obj), new Map()).values()) as Source[];

  const uniqueElements = (sources
    ?.map((s: Source) => {
      if (s.site === eCountry[channel?.isoCountry ?? 1] && (s.hasAutoOrder === true || s.hasAutoOrderHGRAccount === true)) {
        return s;
      }
      return undefined;
    })
    ?.filter((v) => v != null) ?? []) as Source[];

  uniqueElements.sort((a, b) => a.name.localeCompare(b.name));

  const GetStatus = (id: number) => {
    let status = 'Disabled';
    if (settings) {
      Object.entries(settings).length > 0 &&
        Object.entries(settings).map(([key, value]) => {
          if ((key as unknown as number) == id) {
            const val: ComputedSettingsData = value as unknown as ComputedSettingsData;
            status = val.autoOrdering ? 'Enabled' : 'Disabled';
          }
        });
    }

    return status;
  };

  const tableColumns = [
    {
      title: <T k='AutoOrderingConfiguration.Supplier'/>,
      dataIndex: '',
      key: 'name',
      render: (render: Source) => <p>{render?.name}</p>
    },
    {
      title: <T k='AutoOrderingConfiguration.FeePercentage'/>,
      dataIndex: '',
      key: 'feepercentage',
      render: (render: Source) => <p>
        {render?.hasAutoOrderHGRAccount && (Sources[render.id]?.autoorder?.hgrAccountDiscount ?? 0) > 0 ?
          <span className='discount-cell'><T k='AutoOrderingConfiguration.Discount' values={{ d: Sources[render.id].autoorder.hgrAccountDiscount }}/></span>
          :
          render?.autoOrderingFee === 1 ?
            ` ${render?.autoOrderingFee}%`
            : <span className='free-cell'><T k='AutoOrderingConfiguration.Free' /></span>
        }
      </p>
    },
    {
      title: <T k='AutoOrderingConfiguration.Status' />,
      dataIndex: '',
      key: 'status',
      render: (render: Source) => {
        const status = GetStatus(render?.id);
        return <span className={status === 'Enabled' ? 'enabled-cell' : 'disabled-cell'}>{render?.id && <T k={'AutoOrderingConfiguration.' + status} />}</span>;
      }
    }
  ];

  if (supplierId) {
    return <AutoOrderingConfiguration sourceId={supplierId} />;
  }

  return (
    <div className="sources-ao-container">
      {loading ? (
        <Spin />
      ) : (
        <>
          {uniqueElements.length > 0 ? (
            <div className="sources-table-container">
              <DataTable
                productNamePlural={<T k='Orders' />}
                columns={tableColumns}
                dataSource={uniqueElements.map(x => ({ ...x, key: x.id }))}
                totalItems={uniqueElements.length}
                rowClassName="table-row"
                rowSelection={rowSelection}
                pageSizes={[9999]}
                hidePagination={true}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      history.push({
                        pathname: Links.Configuration.AutoOrdering.Produce(record.id),
                        state: { id: record.id }
                      });
                    }
                  };
                }}
              />
            </div>
          ) : (
            <div className="no-sources-ao">
              <h3>We currently have no suppliers with auto ordering for this country</h3>
            </div>
          )}
        </>
      )}
    </div>
  );
};
