import { CheckCircleFilled } from '@ant-design/icons';
import { Spin, Tabs } from 'antd';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/custom-hooks/reduxCustomHooks';
import { getDispatch } from 'src/redux/DispatchOrders/DispatchOrdersThunk';
import { DataTable } from 'src/small-components/tables/data-table';
import '../sass/dispatch-orders.scss';
import { Order } from 'src/redux/DispatchOrders/DispatchOrdersSlice';
import { ReactUtils } from 'src/utils/react-utils';

import { A } from '../small-components/A';

export const DispatchedOrders = () => {
  const dispatch = useAppDispatch();
  const { dispatchOrders, loading } = useAppSelector((state) => state.DispatchOrders);

  useEffect(() => {
    dispatch(getDispatch());
  }, [getDispatch]);

  const tableColumns = [
    {
      title: 'Due on',
      dataIndex: 'dispatchDue',
      render: (dispatchDue: string | Date) => ReactUtils.GetFormattedDateTime(dispatchDue)
    },
    {
      title: 'Item',
      render: (record: Order) => (
        <div>
          <A target={'_blank'} rel="noreferrer" to={record?.ebayUrl ?? ''}>
            {record?.ebayItemId}
          </A>
          {' - '}
          {record?.title}
        </div>
      )
    },
    {
      title: 'Paid on',
      dataIndex: 'datePaid',
      render: (datePaid: string | Date) => ReactUtils.GetFormattedDateTime(datePaid)
    },
    {
      title: 'Source',
      render: (record: Order) => (
        <A target={'_blank'} rel="noreferrer" to={record?.sourceUrl ?? ''}>
          {record?.sourceName}
        </A>
      )
    },
    {
      title: 'Username',
      dataIndex: 'buyerUsername',
      render: (buyerUsername: string) => <>{buyerUsername} </>
    },
    {
      title: 'Postcode',
      dataIndex: 'postCode',
      render: (postCode: string) => <>{postCode} </>
    }
  ];

  const noOrders = () => (
    <div className="no-orders">
      <div className="check-section">
        <CheckCircleFilled />
        <h4>No orders due today!</h4>
      </div>
      <p>
        Only orders for listings monitored by Hustle Got Real will show up here. If you want to monitor listings that were not created by
        Hustle Got Real please contact us and we will get them imported.
      </p>
    </div>
  );

  return (
    <div className="dispatched-orders">
      <h2>Orders awaiting dispatch</h2>
      <div className="dispatch-orders-container">
        {dispatchOrders?.dispatchToday?.length == 0 && noOrders()}
        <Tabs>
          <Tabs.TabPane tab="Late" key="dispatchLate">
            {loading ? <Spin /> : <DataTable dataSource={dispatchOrders?.dispatchLate ?? []} columns={tableColumns} />}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Today" key="dispatchToday">
            {loading ? <Spin /> : <DataTable dataSource={dispatchOrders?.dispatchToday ?? []} columns={tableColumns} />}
          </Tabs.TabPane>
          <Tabs.TabPane tab={dispatchOrders?.nextWorkingDay} key="dispatchNextWorkingDay">
            {loading ? <Spin /> : <DataTable dataSource={dispatchOrders?.dispatchNextWorkingDay ?? []} columns={tableColumns} />}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Soon" key="dispatchFuture">
            {loading ? <Spin /> : <DataTable dataSource={dispatchOrders?.dispatchFuture ?? []} columns={tableColumns} />}
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};
