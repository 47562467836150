type ConnectInfo = {
  name?: string | undefined;
  includeTlsChannelId?: boolean | undefined;
}
//eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
interface Event<T extends Function>/* extends BaseEvent<T>*/ {
  addListener(callback: T): void;
}
//eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
type SendMessage = <M = any, R = any>(extensionId: string, message: M, responseCallback: (response: R) => void) => Promise<R>;
//eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
type ExtensionMessageEvent = Event<(message: any, sender: MessageSender, sendResponse: (response?: any) => void) => void>;
interface MessageSender {
  id?: string | undefined;
  nativeApplication?: string | undefined;
  frameId?: number | undefined;
  url?: string | undefined;
  tlsChannelId?: string | undefined;
  origin?: string | undefined;
}
type Connect = (tabId: string, connectInfo?: ConnectInfo) => { onMessage: ExtensionMessageEvent };

type RuntimeType = {
  sendMessage: SendMessage;
  connect: Connect;
};
type BrowserType = {
  runtime?: RuntimeType;
  extension?: RuntimeType;
};
export const BrowserExtension = window?.chrome?.runtime ?? window?.browser?.runtime ?? window?.chrome?.extension ?? window?.browser?.extension;
declare global {
  interface Window {
    chrome?: BrowserType;
    browser?: BrowserType;
    gtag?: (a: string, b: string, c: { [key:string]: string | number }) => void;
  }
}