import { loadStripe } from '@stripe/stripe-js';
import { Spin } from 'antd';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../custom-hooks/reduxCustomHooks';
import { Links } from '../../../links';
import { CreateCheckoutSession } from '../../../redux/payment/paymentThunk';
import { GetPaymentConfig } from '../../../redux/subscriptions/subsThunk';
import { ReactUtils } from '../../../utils/react-utils';
import { CreateCheckoutSessionRequest, PaymentPlatform } from '../models/types';
import { Cart, SelectedPrice } from './data/cart';

type Props = {
  cart: Cart
}
export const ExecutingPayment = (props: Props) => {
  const { cart } = props;

  const dispatch = useAppDispatch();
  const selectedChannel = ReactUtils.GetSelectedChannel();
  const { paymentConfiguration } = useAppSelector((state) => state.paymentMethodConfiguration);

  useEffect(() => {
    dispatch(GetPaymentConfig());
  }, [GetPaymentConfig]);

  const GetSuccesUrl = () => {
    const l = window.location;
    const baseUrl = l.protocol + '//' + l.host;
    const url = new URL(paymentConfiguration?.successUrl ?? baseUrl + Links.Payment.Success);
    url.searchParams.append('bp', cart.billingPeriodId.toString());
    url.searchParams.append('pid', cart.lines[0].id.toString());
    url.searchParams.append('returnUrl', url.toString());
    return url.toString();
  };

  const GetCancelUrl = () => {
    const l = window.location;
    const baseUrl = l.protocol + '//' + l.host;
    const url = new URL(paymentConfiguration?.cancelUrl ?? baseUrl + Links.Payment.Error);
    url.searchParams.append('bp', cart.billingPeriodId.toString() ?? '');
    url.searchParams.append('pid', cart.lines[0].id.toString() ?? '');
    url.searchParams.append('returnUrl', url.toString());
    return url.toString();
  };

  const HandleStripe = async () => {
    if (!paymentConfiguration) return;
    const request: CreateCheckoutSessionRequest = {
      lineItems: cart.lines.map(x => ({
        quantity: 1,
        platformProductId: SelectedPrice(x, cart).platformProductId.toString()
      })),
      mode: (cart.billingPeriodId < 3 ? 'subscription' : 'payment'),
      successUrl: GetSuccesUrl(),
      cancelUrl: GetCancelUrl(),
      upgradingSubscription: paymentConfiguration.upgrade
    };

    const rp = await dispatch(CreateCheckoutSession(request));
    const stripe = await loadStripe(paymentConfiguration.stripeConfig.publishableKey);
    await stripe?.redirectToCheckout({ sessionId: rp.payload.responseObject.checkoutSessionId });
  };

  const HandlePayPal = () => {
    if (!paymentConfiguration) return;
    window.location.href =
      'https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=' +
      SelectedPrice(cart.lines[0], cart).platformProductId +
      '&custom=' +
      paymentConfiguration.payPalConfig.userId +
      ',' +
      (selectedChannel?.id ?? '');
  };

  const FinishPayment = () => {
    if (cart.platform === PaymentPlatform.PayPal) {
      HandlePayPal();
    } else if (cart.platform === PaymentPlatform.Stripe) {
      HandleStripe();
    } 
  };

  useEffect(FinishPayment, [FinishPayment]);

  return <Spin />;

};