import { eCountry } from '../../../data/countries';
import { T } from '../../../utils/transShim';

export interface FlagProps {
  name: string;
  flag: string;
  code: eCountry;
  onChangeLocation: (locationCode: eCountry) => void;
  suppliers?: number;
}
export const Flag = (props: FlagProps) => {

  const { onChangeLocation, flag, name, code, suppliers } = props;

  const HandleCLick = () => {
    onChangeLocation(code);
  };

  return (
    <div className="flag-container" onClick={HandleCLick}>
      <img
        src={flag}
        className="flag-img"
        alt="flag"
      />
      <span><T k={name} /></span>
      {suppliers && <span className="desc"><T k='NewChannel.Country.Suppliers' values={{ q: suppliers }} /></span>}
    </div>
  );
};
