import { Divider, Tooltip } from 'antd';

import { Links } from 'src/links';
import { Product, Subscription } from '../../../../redux/subscriptions/subsSlice';
import '../../../../sass/subscriptions/checkout.scss';
import { A } from '../../../../small-components/A';
import { eCurrency } from '../../../../types/eCurrency';
import { ReactUtils } from '../../../../utils/react-utils';
import { T } from '../../../../utils/transShim';
import { BillingPeriod, PaymentPlatform } from '../../models/types';
import { Cart, SelectedPrice } from '../data/cart';
import { CheckoutUtils } from '../data/checkout-utils';

interface props {
  cart: Cart
}

export const OrderSummary = (props: props) => {
  const { cart } = props;

  const RenderPriceLine = (prd: Subscription, currency: eCurrency) => {
    if (prd.billingPeriodId === BillingPeriod.Monthly) return <>{ReactUtils.DrawPrice(prd.price, currency)} /<T k='Checkout.perMonth' /></>;
    else if (prd.billingPeriodId === BillingPeriod.Biannually) return <>{ReactUtils.DrawPrice(prd.price / 6, currency)} /<T k='Checkout.perMonth' /></>;
    else if (prd.billingPeriodId === BillingPeriod.Yearly) return <>{ReactUtils.DrawPrice(prd.price / 12, currency)} /<T k='Checkout.perMonth' /></>;
    return <>{ReactUtils.DrawPrice(prd.price, currency)}</>;
  };

  const RenderLine = (cart: Cart, sb: Product, i: number) => {
    const moreExp = sb.prices?.find(x =>
      x.currencyId == cart.currency &&
      cart.platform == x.platformId &&
      x.billingPeriodId === BillingPeriod.Monthly &&
      (
        cart.billingPeriodId === BillingPeriod.Biannually || 
        cart.billingPeriodId === BillingPeriod.Yearly 
      )
    );
    const selectedPrice = SelectedPrice(sb, cart);
    const priceByMonth = CheckoutUtils.PriceByMonth(selectedPrice);

    return <div className="product-order-line" key={i}>
      <div className="price-extra">
        <h5 className="title-orderdetails"><T k={'Checkout.pname.' + sb.name} /></h5>
        {moreExp != null && moreExp.price > priceByMonth && (
          <div className="discounts">
            <h5 className="old-price">{ReactUtils.DrawPrice(moreExp.price, moreExp.currencyId)} /<T k='Checkout.perMonth' /></h5> &nbsp;
            <h5 className="twenty-off">{Math.round(100 * (1 - (priceByMonth / moreExp.price)))}% off</h5>
          </div>
        )}
        <div className="order-products-price">
          <h5>
            {RenderPriceLine(selectedPrice, cart.currency)}
          </h5>
        </div>
      </div>
    </div>;
  };

  const RenderContent = () => {
    if (cart?.lines == null || cart?.lines.length == 0)
      return <></>;

    let totalMonth = 0;
    let total6month = 0;
    let totalyear = 0;
    const totalOneTime = 0;
    let totalNow = 0;
    for (const c of cart.lines) {
      const selectedPrice = SelectedPrice(c, cart);
      totalNow += selectedPrice?.price ?? 0;
      switch (selectedPrice?.billingPeriodId) {
        case 0://per Month
          totalMonth += selectedPrice.price;
          break;
        case 1://per 6 months
          total6month += selectedPrice.price;
          break;
        case 2://per year
          totalyear += selectedPrice.price;
          break;
        //case -1:
          //totalOneTime += c.selectedPrice.price;
          //break;
      }
    }

    return <div>
      <div className="order-products">
        <div className="order-products-lines">
          {cart.lines.map((x, i) => RenderLine(cart, x, i))}
        </div>
      </div>
      <Divider />
      <div className="order-sum">
        <h2 className="total-title"><T k='Checkout.Totaltopay'/></h2>
        <div className="total-topay">
          {totalMonth > 0 && <h4>{ReactUtils.DrawPrice(totalMonth, cart.currency)} /<T k='Checkout.perMonth' /></h4>}
          {total6month > 0 && <h4>{ReactUtils.DrawPrice(total6month, cart.currency)} /<T k='Checkout.per6months' /></h4>}
          {totalyear > 0 && <h4>{ReactUtils.DrawPrice(totalyear, cart.currency)} /<T k='Checkout.per1year' /></h4>}
          {totalOneTime > 0 && <h4>{ReactUtils.DrawPrice(totalOneTime, cart.currency)}/<T k='Checkout.onetime' /></h4>}
          <h1>
            <T k='Checkout.Now'/>: {ReactUtils.DrawPrice(totalNow, cart.currency)}
          </h1>
          {cart.platform == PaymentPlatform.PayPal && (
            <Tooltip title="Due to the high commissions of PayPal we must charge a 10% commission on all payments with this platform.">
              <div className="fees-container">
                <p><T k='Checkout.PaypalPercent' /></p>
                <p><T k='Checkout.PaypalEur' /></p>
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </div>;
  };

  const RenderNoProduct = () => {
    return <p>
      Please <A to={Links.Checkout.Main}>go back</A> and select a service
    </p>;
  };

  return (
    <div className="order-summary">
      <h3 className="title-ordersum"><T k='Checkout.YourOrder'/></h3>
      {cart.lines?.length > 0 && RenderContent() }
      {(cart.lines?.length ?? 0) == 0 && RenderNoProduct() }
    </div>
  );
};
