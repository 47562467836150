import { Radio, RadioChangeEvent, Space, Spin } from 'antd';

import '../../../sass/subscriptions/checkout.scss';

import { OrderSummary } from './parts/order-summary';
import { useState } from 'react';

import { Product } from 'src/redux/subscriptions/subsSlice';
import { PrimaryBtn } from '../../../small-components/ActionBtns';
import { ArrowRightOutlined } from '@ant-design/icons';
import { T } from '../../../utils/transShim';
import { Cart } from './data/cart';
import { eCurrency } from '../../../types/eCurrency';
import { CheckoutSection } from './data/checkout-section';
import { Selector, SelectorValue } from '../../../small-components/form/selector';
import { FormItemLayout } from '../../../small-components/form/form-item-layout';
import { FormLayout } from '../../../small-components/form/form-layout';
import { ReactUtils } from '../../../utils/react-utils';
import { CheckoutUtils } from './data/checkout-utils';
import { BillingPeriod } from '../models/types';

interface props {
  cart: Cart;
  products: Product[];
  onNavigate: (cart: Cart, section: CheckoutSection) => void;
}

export const CheckoutSubscription = (props: props) => {
  const { cart, products, onNavigate } = props;

  const [productId, SetProductId] = (() => {
    const [productId, SetProductId] = useState<number | undefined>(cart.lines[0]?.id);
    return [productId ?? products?.[0]?.id, SetProductId];
  })();
  const [billingId, SetBillingId] = useState<BillingPeriod | undefined>(cart.billingPeriodId);
  const [currencyId, SetCurrencyId] = useState<eCurrency>(cart?.currency ?? eCurrency.EUR);

  const HandleProductChange = (value: SelectorValue) => {
    SetProductId(value as number);
  };

  const HandleBillingChange = (e: RadioChangeEvent) => {
    SetBillingId(e.target.value as number);
  };

  const HandleCurrencyChange = (value: SelectorValue) => {
    SetCurrencyId(value as eCurrency);
  };

  const currency = [
    { label: 'EUR \u20AC', value: eCurrency.EUR },
    { label: 'USD \u0024', value: eCurrency.USD },
    { label: 'GBP \u00A3', value: eCurrency.GBP }
  ];

  const prd = products.find((x) => x.id == productId) ?? products[0];
  if (!prd) return <Spin />;

  const tempCart: Cart = {
    ...cart,
    billingPeriodId: billingId ?? cart.billingPeriodId,
    lines: [prd, ...(cart.lines?.slice(1, cart.lines.length) ?? [])],
    currency: currencyId
  };

  const HandleNavigate = () => {
    onNavigate(tempCart, CheckoutSection.PAYMENT_METHOD);
  };

  const priceMonth = prd?.prices.find(
    (x) => x.billingPeriodId == BillingPeriod.Monthly && x.platformId == tempCart.platform && x.currencyId == currencyId
  );
  const price6months = prd?.prices.find(
    (x) => x.billingPeriodId == BillingPeriod.Biannually && x.platformId == tempCart.platform && x.currencyId == currencyId
  );
  const priceYear = prd?.prices.find(
    (x) => x.billingPeriodId == BillingPeriod.Yearly && x.platformId == tempCart.platform && x.currencyId == currencyId
  );

  const Panel1 = () => {
    return <div className="section-container">
      <FormLayout className="bulk-form" layout="vertical">
        <div className="top-section">
          <FormItemLayout className="listings-item" label={<T k="Checkout.selectProductAmount" />}>
            <Selector value={productId} onChange={HandleProductChange}>
              {products
                ?.filter((x) => x.type == 1)
                .map(({ name: label, id: value }: Product) => ({ value: value, label: <T k={'Checkout.pname.' + label} /> }))}
            </Selector>
          </FormItemLayout>
          <FormItemLayout className="currency-item" label=" ">
            <Selector value={currencyId} loading={false} onChange={HandleCurrencyChange} className="selector-currency">
              {currency}
            </Selector>
          </FormItemLayout>
        </div>
        <FormItemLayout className="billing-period-container" label={<T k="Checkout.SelectBillingCycle" />}>
          {priceMonth && (
            <Radio.Group value={billingId ?? 2} onChange={HandleBillingChange} className="options-billing">
              <Space direction="vertical" className="spacer">
                <Radio value={0}>
                  <div className="billing-container">
                    <h4>
                      <T k="Checkout.BillMonthly" />
                    </h4>
                    <p className="cost">
                      {ReactUtils.DrawPrice(priceMonth.price, currencyId)}/<T k="Checkout.perMonth" />
                    </p>
                  </div>
                </Radio>
                {price6months && (
                  <Radio value={1}>
                    <div className="billing-container">
                      <h4>
                        <T k="Checkout.BillBiyearly" />
                      </h4>
                      <p className="discount">
                        <T k="Checkout.Save" />
                        &nbsp;{ReactUtils.DrawPrice(priceMonth.price * 6 - price6months.price, currencyId)}
                      </p>
                      <p className="cost">
                        {ReactUtils.DrawPrice(CheckoutUtils.PriceByMonth(price6months), currencyId)}/<T k="Checkout.perMonth" />
                      </p>
                    </div>
                  </Radio>
                )}
                {priceYear && (
                  <Radio value={2}>
                    <div className="billing-container">
                      <h4>
                        <T k="Checkout.BillYearly" />
                      </h4>
                      <p className="discount">
                        <T k="Checkout.Save" />
                        &nbsp;{ReactUtils.DrawPrice(priceMonth.price * 12 - priceYear.price, currencyId)}
                      </p>
                      <p className="cost">
                        {ReactUtils.DrawPrice(CheckoutUtils.PriceByMonth(priceYear), currencyId)}/<T k="Checkout.perMonth" />
                      </p>
                    </div>
                  </Radio>
                )}
              </Space>
            </Radio.Group>
          )}
        </FormItemLayout>
      </FormLayout>
    </div>;
  };

  const Panel2 = () => {
    return <div className="section-container">
      <OrderSummary cart={{ ...tempCart }} />
      <div className="button-container">
        <PrimaryBtn htmlType="submit" onClick={HandleNavigate}>
          <T k="Checkout.Continue" /> <ArrowRightOutlined />
        </PrimaryBtn>
      </div>
    </div>;
  };

  return (
    <div className="checkout-content">
      <div className="checkout-sections-container">
        {Panel1()}
        {Panel2()}
      </div>
    </div>
  );
};
