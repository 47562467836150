import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Alert, Button, Col, Row, Spin } from 'antd';
import { T } from '../../../utils/transShim';

import { Links } from '../../../links';
import { ConfigurePaymentMethodRequest, PaymentMethodsDataMethod } from '../../../redux/payment/paymentSlice';
import { useAppDispatch, useAppSelector } from '../../../custom-hooks/reduxCustomHooks';
import {
  ConfigurePaymentMethod,
  GetPaymentMethods,
  GetPendingPayments,
  SaveCurrencies,
  RetryPayment,
  PostRemovePayment,
  SendCheckoutInformation
} from '../../../redux/payment/paymentThunk';
import { useEffect } from 'react';
import { eCurrency } from '../../../types/eCurrency';
import { PopupModal } from '../../../small-components/modals/popup-modal';
import { PlusCircleFilled } from '@ant-design/icons';
import { PrimaryBtn } from '../../../small-components/ActionBtns';
import { PaymentMethods } from './payment/payment-method';
import { A } from '../../../small-components/A';

export interface Props {
  display: boolean;
}
export const Payment = (props: Props) => {
  const dispatch = useAppDispatch();

  const { display } = props;

  const { methods, pendingPayments, loading } = useAppSelector((state) => state.payment);

  const [loadings, setLoadings] = useState(false);
  const [loadingSessionId, setLoadingSessionId] = useState(false);

  const [currencyList, setCurrencyList] = useState<Map<eCurrency, number>>();
  const [currencyChanged, setCurrencyChanged] = useState<boolean>(false);
  const [savingCurrencies, setSavingCurrencies] = useState<boolean>(false);
  const [paymentToDelete, setPaymentToDelete] = useState<PaymentMethodsDataMethod>();
  const [removingPayment, setRemovingPayment] = useState(false);
  const [showModal, setShowModal] = useState(false);


  const [pendingPayment, setPendingPayment] = useState<Map<eCurrency, number>>();

  const LoadPaymentMethods = async () => {
    const dicCurrencies = new Map<eCurrency, number>();

    for (const m of methods) {
      for (const c of m?.currencies ?? []) {
        dicCurrencies.set(c, m.id);
      }
    }
    setCurrencyList(dicCurrencies);
    setCurrencyChanged(false);
  };
  const LoadPendingPayments = async () => {
    const l = new Map<eCurrency, number>();
    for (const b in pendingPayments) {
      l.set(parseInt(eCurrency[b as unknown as number]) as eCurrency, pendingPayments[b as unknown as number]);
    }
    setPendingPayment(l);
  };

  const handleStripe = async (data?: PaymentMethodsDataMethod) => {
    setLoadings(true);
    const successUrl = new URL(document.URL);
    successUrl.searchParams.set('sessionId', '{CHECKOUT_SESSION_ID}');
    const successUrlStr = successUrl.toString().replace('%7BCHECKOUT_SESSION_ID%7D', '{CHECKOUT_SESSION_ID}');
    const request: ConfigurePaymentMethodRequest = {
      successUrl: successUrlStr,
      cancelUrl: document.URL,
      previousId: data?.id
    };
    const rp = await dispatch(ConfigurePaymentMethod(request));
    const stripe = await loadStripe(rp.payload?.response_data?.responseObject?.publishableKey);
    await stripe?.redirectToCheckout({ sessionId: rp.payload?.response_data?.responseObject?.checkoutId });
  };

  const ProcessSessionId = async () => {
    if (loadingSessionId) {
      return;
    }
    const sessionId = new URLSearchParams(window.location.search).get('sessionId');
    if (!sessionId) {
      return;
    }
    setLoadingSessionId(true);
    await dispatch(
      SendCheckoutInformation({
        sessionId: sessionId,
        customerName: ''
      })
    );
    const url = new URL(document.URL);
    url.searchParams.delete('sessionId');
    window.history.pushState({}, document.title, url.toString());
    setLoadingSessionId(false);
    await dispatch(GetPaymentMethods());
  };

  ProcessSessionId();

  const ChangeCurrency = (currency: eCurrency, methodId: number) => {
    const c = new Map<eCurrency, number>(currencyList);
    c.set(currency, methodId);
    setCurrencyList(c);
    setCurrencyChanged(true);
  };

  const RevertDictionary = <T1, T2>(dic: Map<T1, T2> | undefined): Map<T2, T1[]> => {
    const revertCurrencyDic = new Map<T2, T1[]>();
    if (!dic) return revertCurrencyDic;
    for (const c of dic.keys() ?? []) {
      const id = dic.get(c);
      if (!id) continue;
      let l: T1[] | undefined = revertCurrencyDic.get(id);
      if (revertCurrencyDic.has(id)) {
        l = revertCurrencyDic.get(id);
      } else {
        l = [];
        revertCurrencyDic.set(id, l);
      }
      l?.push(c);
    }
    return revertCurrencyDic;
  };

  const revertCurrencyDic = RevertDictionary(currencyList);

  const SaveCurrencyChanges = async () => {
    setSavingCurrencies(true);
    const revertCurrencyDic = RevertDictionary(currencyList);
    const l: { id: number; currencies: eCurrency[] }[] = [];
    for (const id of revertCurrencyDic.keys()) {
      l.push({ id: id, currencies: revertCurrencyDic.get(id) ?? [] });
    }
    await dispatch(SaveCurrencies(l));
    setSavingCurrencies(false);
  };

  const RetryPayments = async (data: PaymentMethodsDataMethod) => {
    await dispatch(RetryPayment(data.id));
    await dispatch(GetPaymentMethods());
  };
  const RemovePayment = (data: PaymentMethodsDataMethod) => {
    setPaymentToDelete(data);
    setShowModal(true);
  };

  const ExecuteRemovePayment = async () => {
    setRemovingPayment(true);
    setShowModal(false);
    if (paymentToDelete?.id) {
      await dispatch(PostRemovePayment({ id: paymentToDelete.id }));
      await dispatch(GetPaymentMethods());
    }
    setPaymentToDelete(undefined);
    setRemovingPayment(false);
  };

  useEffect(() => {
    dispatch(GetPaymentMethods());
    dispatch(GetPendingPayments());
    LoadPaymentMethods();
    LoadPendingPayments();
  }, [GetPaymentMethods, GetPendingPayments]);

  useEffect(() => {
    LoadPaymentMethods();
    LoadPendingPayments();
  }, [methods, pendingPayments]);

  const needToConfigure: { currency: eCurrency; fees: number }[] = [];
  const goingToBePaid: { currency: eCurrency; fees: number }[] = []; //by payment id
  for (const c of pendingPayment?.keys() ?? []) {
    const v = pendingPayment?.get(c);
    let lastPaymentFailed = false;
    for (const f of methods ?? []) {
      if (revertCurrencyDic.has(f.id)) {
        const cs = revertCurrencyDic.get(f.id);
        let found = false;
        if (!cs) continue;
        for (const cu of cs) {
          if (cu == c) {
            found = true;
            lastPaymentFailed = f.lastPaymentFailed;
            break;
          }
        }
        if (found) break;
      }
    }
    if (v) {
      if (currencyList?.has(c) && !lastPaymentFailed) {
        goingToBePaid.push({ currency: c, fees: v });
      } else {
        needToConfigure.push({ currency: c, fees: v });
      }
    }
  }

  if (!display)
    return <></>;

  if (loadings || loading || savingCurrencies || removingPayment)
    return <Spin />;

  return (
    <>
      <div className="payment-configuration-auto-ordering">
        <Row>
          <h2>
            <T k="Account.PaymentMethod.SubscriptionPaymentMethod" />
          </h2>
        </Row>
        <Row>
          <p>
            <T k="Account.PaymentMethod.Description.SubscriptionPaymentMethod" />
            <A to="/subscriptions">
              <T k="Account.PaymentMethod.Description.subscriptionplans" />
            </A>
            .
          </p>
        </Row>
        <h2> &nbsp; </h2>
        <Row>
          <h2>
            <T k="Account.PaymentMethod.AutoOrderingPM" />
          </h2>
        </Row>
        <Row>
          <p>
            <T k="Account.PaymentMethod.Description.AutoOrderingWOF" />
            <T k="Account.PaymentMethod.PremiumSuppliers" />. <T k="Account.PaymentMethod.Description.AutoOrderingFSP" />
          </p>
          <A to={Links.AOCommissions}>
            <T k="Account.PaymentMethod.Description.VAOCP" />
          </A>
        </Row>
        <Row className="payment-method-container">
          <h4 className="title-payments">{'Payment method:' + (methods?.length == 0 ? ' Not set' : '')}</h4>
          {needToConfigure?.map(
            (x, i) =>
              needToConfigure.length > 0 && (
                <Alert
                  key={i}
                  type="error"
                  message={
                    <>
                      Amount due: {Math.ceil(x.fees * 100) / 100} {eCurrency[x.currency]}. Please set up a payment method.
                    </>
                  }
                />
              )
          )}
          {goingToBePaid?.map((x, i) => (
            <Alert
              key={i}
              type="info"
              message={
                <>
                  A payment of {Math.ceil(x.fees * 100) / 100} {eCurrency[x.currency]} will be charged soon
                </>
              }
            />
          ))}
          <div className="paymentListRow">
            <div className="methods">
              {methods?.length > 0 &&
                methods.map((x: PaymentMethodsDataMethod) => (
                  <PaymentMethods
                    key={x.id}
                    relink={handleStripe}
                    changeCurrency={ChangeCurrency}
                    currencies={revertCurrencyDic.get(x.id) ?? []}
                    removePayment={RemovePayment}
                    retryPayment={RetryPayments}
                    data={x}
                  />
                ))}
            </div>
            <div className="add-payment-container">
              <PrimaryBtn onClick={() => handleStripe()}>
                <PlusCircleFilled className="plus-icon" />
                <T k="Account.PaymentMethod.AddPaymentMentod" />
              </PrimaryBtn>
            </div>
          </div>
          <Col sm={24}>
            {currencyChanged && (
              <PrimaryBtn className="currency-changes" onClick={SaveCurrencyChanges}>
                Save currency changes
              </PrimaryBtn>
            )}
          </Col>
          <PopupModal
            title={'Do you want to delete **** **** **** ' + paymentToDelete?.last4 + '?'}
            footer={[
              <Button
                key="back"
                onClick={() => {
                  setPaymentToDelete(undefined);
                  setShowModal(false);
                }}
              >
                NO
              </Button>,
              <Button key="submit" type="primary" loading={loading} onClick={() => ExecuteRemovePayment()}>
                YES
              </Button>
            ]}
            open={showModal}
          >
            <Button />
          </PopupModal>
        </Row>
      </div>
    </>
  );
};
