import { useEffect, useState } from 'react';
import { T } from '../../../utils/transShim';
import { eShop } from './eShop';
import { Links } from '../../../links';
import { PrimaryBtn, SecondaryBtn } from '../../../small-components/ActionBtns';
import { eApiType } from '../../../redux/new-channel/newChannelThunk';

import { A } from '../../../small-components/A';

interface props {
  onChangeApi: (newApi: eApiType) => void;
  shop: eShop;
  api: eApiType | null;
}

export const AccountTypeConnect = (props: props) => {
  const { onChangeApi, shop, api } = props;

  const [showNoApi, SetShowNoApi] = useState<boolean>(false);

  const HandleSelectApi = () => onChangeApi(eApiType.API);
  const HandleSelectNoApi = () => onChangeApi(eApiType.NO_API);
  const HandleOpenNoApi = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    SetShowNoApi(true);
  };

  useEffect(() => {
    localStorage.setItem('newChannelSuccess', 'false');
  }, [api]);

  return (
    <form className="account-connect">
      <h2 className="title">
        <T k="NewChannel.ConnectionType.Title" values={{ n: eShop[shop] }} />
      </h2>
      <div className="connect-api" >
        <p><T k='NewChannel.ConnectionType.Description'/></p>
        <PrimaryBtn onClick={HandleSelectApi}><T k="NewChannel.ConnectType.UsingApi" values={{ n: eShop[shop] }}/></PrimaryBtn>
        <A to="#" onClick={HandleOpenNoApi} className="other-connections">
          <T k="NewChannel.ConnectionType.QuestionNoApi" />
        </A>
      </div>
      {showNoApi &&
        <div className="connect-noapi">
          <A
            rel="noreferrer"
            target={'_blank'}
            to={
              shop == eShop.eBay
                ? 'https://www.youtube.com/watch?v=esayxH6bzs8'
                : shop == eShop.Amazon
                  ? 'https://www.youtube.com/watch?v=p920COtjL2o'
                  : Links.HGRNoApiExtension
            }>
            <T k='NewChannel.ConnectType.NoApiTutorial' />
          </A>
          <SecondaryBtn onClick={HandleSelectNoApi}><T k="NewChannel.ConnectType.UsingNoApi" values={{ n: eShop[shop] }} /></SecondaryBtn>
        </div>
      }
    </form>
  );
};
