import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { eCountry } from '../../data/countries';
import { GetScrapingConfig, NullableString, ScrapingConfiguration, ScrapingDataField, ScrapingDataFields, ScrapingDictionary, ScrapingField } from '../../redux/listings/manual-listing-thunk';

export const ProcessHTML = async (
  //I put <ScrapingConfiguration, because any can't be put, and writting any type it works so... 
  dispatch: ThunkDispatch<ScrapingConfiguration, undefined, AnyAction> & Dispatch<AnyAction>,
  url: string,
  site: eCountry | undefined,
  html: string | null
) => {
  try {
    const downloadedConfig = (await dispatch(GetScrapingConfig({ url, site }))).payload as ScrapingConfiguration | null;
    if (downloadedConfig == null)
      return downloadedConfig;

    const parser = new DOMParser();
    const page = parser.parseFromString(html ?? '', 'text/html');

    const GetScrapingArray = (page: Document, config: ScrapingField<NullableString[]>) => {
      const tempArray: (string | undefined | null)[] = [];
      const elements = page.querySelectorAll(config.selector ?? '');
      const attribute = config.selectorAttribute;

      elements?.forEach((element:Element) => {
        if (attribute) {
          tempArray.push(element.getAttribute(attribute));
        } else {
          tempArray.push(element.textContent?.trim());
        }
      });
      return tempArray;
    };
    const GetRegexMatches = (selector: string, html: string) => {
      const matches = [];
      const re = new RegExp(selector, 'g');
      let m;
      do {
        m = re.exec(html);
        if (m) {
          matches.push(m[1]);
        }
      } while (m);
      return matches;
    };

    const ProcessCultureInfoCode = (value: NullableString): NullableString => value;
    const ProcessScrapingField = (cc: ScrapingField<NullableString>): ScrapingField<NullableString> => {
      try {
        if (cc == null)
          return cc;

        switch (cc.type) {
          case 'text': {
            if (!cc.selector)
              return cc;
            const attribute = cc.selectorAttribute;
            if (attribute) {
              return { ...cc, value: page.querySelector(cc.selector)?.getAttribute?.(attribute) };
            } else {
              return { ...cc, value: page.querySelector(cc.selector)?.textContent?.trim() };
            }
          }
          case 'value': {
            if (!cc.selector)
              return cc;
            const attribute = cc.selectorAttribute;
            if (attribute) {
              return { ...cc, value: page.querySelector(cc.selector)?.getAttribute?.(attribute) };
            } else {
              return { ...cc, value: (page.querySelector(cc.selector) as HTMLInputElement | undefined)?.value };
            }
          }
          case 'html':
            if (!cc.selector)
              return cc;
            return { ...cc, value: page.querySelector(cc.selector)?.innerHTML };
        }
      } catch (ex) {
        console.log(ex);
      }

      return cc;
    };
    const ProcessDataFields = (cc: ScrapingDataFields): ScrapingDataFields => {
      try {
        if (cc == null || cc.data == null)
          return cc;

        return {
          ...cc,
          data: cc.data?.map((d: ScrapingDataField) => {
            if (d.values.type === 'array') {
              return { ...d, values: { ...d.values, value: GetScrapingArray(page, d.values) } } as ScrapingDataField;
            } else if (d.values.type === 'regex') {
              return { ...d, values: { ...d.values, value: GetRegexMatches(d.values.selector ?? '', html ?? '') } } as ScrapingDataField;
            }
            return d;
          }) ?? []
        };
      } catch (ex) {
        console.log(ex);
      }

      return cc;
    };
    //const ProcessScrapingFieldB = (cc: ScrapingField<boolean | null | undefined>): ScrapingField<boolean | null | undefined> => {
    //  try {
    //    if (!cc.selector)
    //      return cc;
    //    return { ...cc, value: page.querySelectorAll(cc.selector).length > 0 };
    //  } catch (ex) {
    //    console.log(ex);
    //  }
    //  return cc;
    //};
    const ProcessScrapingDictionary = (cc: ScrapingDictionary): ScrapingDictionary => {
      if (cc == null || !cc.keySelector || !cc.valueSelector)
        return cc;

      const tempObjects: { [id: string]: NullableString } = {};
      const keyElements = page.querySelectorAll(cc.keySelector);
      const valueElements = page.querySelectorAll(cc.valueSelector);

      keyElements.forEach((element:Element, idx: number) => {
        const valueElement = valueElements[idx];

        const key = element?.textContent?.trim() ?? '';
        const value = valueElement?.textContent?.trim() ?? '';

        tempObjects[key] = value;
      });

      return { ...cc, value: tempObjects };
    };
    const ProcessScrapingFieldA = (cc: ScrapingField<NullableString[]>): ScrapingField<NullableString[]> => {
      if (cc == null)
        return cc;

      try {
        switch (cc.type) {
          case 'array':
            return { ...cc, value: GetScrapingArray(page, cc) };
          case 'regex':
            return { ...cc, value: GetRegexMatches(cc.selector ?? '', html ?? '') };
          //case 'json':
          //  if (!html)
          //    return cc;
          //  page.querySelectorAll(cc.selector ?? '').forEach((element: Element) => {

          //    let idxStart = html.indexOf(element.begin);
          //    if (idxStart === -1)
          //      return;
          //    idxStart += element.begin.length;
          //    const idxEnd = html.indexOf(element.end, idxStart);
          //    const str = html.substring(idxStart, idxEnd);
          //    element.value = str;
          //  });
          //  break;
        }
      } catch (ex) {
        console.log(ex);
      }
      return cc;
    };

    const dataResponse = { ...downloadedConfig };

    dataResponse.brand = ProcessScrapingFieldA(dataResponse.brand);
    dataResponse.categories = ProcessScrapingFieldA(dataResponse.categories);
    dataResponse.cultureInfoCode = ProcessCultureInfoCode(downloadedConfig.cultureInfoCode);
    dataResponse.dataFields = ProcessDataFields(dataResponse.dataFields);
    dataResponse.ean = ProcessScrapingFieldA(dataResponse.ean);
    dataResponse.features = ProcessScrapingFieldA(dataResponse.features);
    dataResponse.images = ProcessScrapingFieldA(dataResponse.images);
    //dataResponse.json = ProcessScrapingFieldA(dataResponse.json);
    dataResponse.links = ProcessScrapingFieldA(dataResponse.links);
    dataResponse.mpn = ProcessScrapingFieldA(dataResponse.mpn ); 
    dataResponse.paragraphs = ProcessScrapingFieldA(dataResponse.paragraphs); 
    dataResponse.price = ProcessScrapingFieldA(dataResponse.price); 
    dataResponse.productId = ProcessScrapingFieldA(dataResponse.productId); 
    dataResponse.rows = ProcessScrapingDictionary(dataResponse.rows); 
    dataResponse.title = ProcessScrapingField(dataResponse.title);

    return dataResponse;
  } catch (ex) {
    console.log(ex);
  }

  return null;
};