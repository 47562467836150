export enum ErrorType {
  Unknown = 0,
  MissingField = 1,
  WrongId = 2,
  WrongChannel = 3,
  WrongStatus = 4,
  ListedSoon = 5,
  ListedNotThisAccount = 6,
  SubLimit = 7,
  InternalServerError = 8,
  ChannelDisabled = 9,
  UnknownProduct = 10,
  UnknownSource = 11
}
