import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../client';
import { MissingListing } from './importListingSlice';

export const monitorListing = createAsyncThunk(
  'importListings/monitorExistingListing',
  async ({ toRelist, oAuthId, listings }: {
    oAuthId: number, toRelist: boolean,
    listings: Record<string, unknown>[],
  }, thunkAPI) => {
    try {
      const res = await client.post('/ChannelListing/MonitorExistingListing', { toRelist, oAuthId, listings });
      return res.data.response_data;
    }
    catch (error) {
      return thunkAPI.rejectWithValue('Sorry ! Something went wrong');
    }
  }
);

export const GetMissingListings = createAsyncThunk(
  'listings/getMissingListings',
  async (_, { rejectWithValue }) => {
    try {
      const res = await client.get('/SearchProduct/getMissingListings');
      const data = res?.data?.response_data?.listings;
      return data as MissingListing[];
    } catch (error) {
      return rejectWithValue('Sorry! Something went wrong');
    }
  }
);