import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../client';

export const getDispatch = createAsyncThunk('Dispatch/Get', async (_, { rejectWithValue } /* destructured thunkAPI's prop */) => {
  try {
    const res = await client.get('/Dispatch/Get');
    return res.data.response_data;
  } catch (error) {
    return rejectWithValue('Sorry! Something went wrong');
  }
});
