import { CommonTypeValue } from '../components/listings/Listings/components/edit/types';
import { eCountry } from '../data/countries';

export const Sleep = (ms:number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
export const StringOrNull = (value: unknown) => {
  if (value == null)
    return null;
  return String(value);
};
export const NumberOrNull = (value: unknown) => {
  if (value == null)
    return null;
  return Number(value);
};
export const BooleanOrNull = (value: unknown) => {
  if (value == null)
    return null;
  if (value == '0')
    return false;
  return Boolean(value);
};
export const DateOrNull = (value: unknown) => {
  if (value == null)
    return null;

  if (value instanceof Date)
    return value;

  if (typeof value !== 'string' && typeof value !== 'number')
    return null;

  return new Date(value);
};
export const ValueIfEqual = (values: (Record<string, unknown> | number | boolean | string | null | undefined)[]): CommonTypeValue => {
  if (!values || values.length == 0)
    return null;
  const firstValue = values[0];
  for (let i = 1; i < values.length; i++) {
    if (values[i] != firstValue)
      return { equal: false };
  }
  return { equal: true, value: firstValue };
};
export const TopLevelDomainByCountry = (country: eCountry) => {
  switch (country) {
    case eCountry.AU:
      return 'com.au';
    case eCountry.DE:
      return 'de';
    case eCountry.FR:
      return 'fr';
    case eCountry.ES:
      return 'es';
    case eCountry.IT:
      return 'it';
    default:
      return eCountry[country];
    case eCountry.UK:
      return 'co.uk';
    case eCountry.US:
      return 'com';
  }
};

export const DownloadFile = (url: string, filename: string) => Object.assign(document.createElement('a'), { href: url, download: filename }).click();

export const HasDecimals = (num: number) => num % 1 !== 0;