import { Form, Spin } from 'antd';
import '../../sass/subscriptions/checkout.scss';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { getServices } from 'src/redux/subscriptions/subsThunk';
import { useHistory } from 'react-router-dom';
import { Product, Subscription } from 'src/redux/subscriptions/subsSlice';
import { Selector, SelectorValue } from '../../small-components/form/selector';
import { PrimaryBtn } from '../../small-components/ActionBtns';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Links } from '../../links';
import { OrderSummary } from './OrderSummary';

const { Item } = Form;

export const ListingServiceCheckout = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const selectedBillingPeriod = queryParams.get('selectedBillingPeriod');
  const selectedCurrency = queryParams.get('selectedCurrency');
  const selectedProduct = queryParams.get('selectedProduct');

  const billings = [
    { label: 'Per Month', value: 0 },
    { label: 'Per 6 Months - 20% OFF', value: 1 },
    { label: 'Per Year - 20% OFF', value: 2 },
    { label: 'One Time Payment', value: 3 }
  ];
  const currency = [
    { label: 'EUR \u20AC', value: 1 },
    { label: 'USD \u0024', value: 2 },
    { label: 'GBP \u00A3', value: 3, disabled: true }
  ];
  const [form] = Form.useForm();
  const [filteredBilling, setFilteredBilling] = useState(billings);
  const [filteredCurrency, setFilteredCurrency] = useState(currency);

  useEffect(() => {
    dispatch(getServices());
  }, []);

  const routeChange = (route: string) => {
    history.push(route);
  };
  const [productId, setProductId] = useState(selectedProduct);
  const [billingId, setBillingId] = useState<string | null | undefined>(selectedBillingPeriod);
  const [currencyId, setCurrencyId] = useState<string | null | undefined>(selectedCurrency);
  const { services, loading } = useAppSelector((state) => state.services);
  let sortedService = services?.filter((x: { type: number }) => {
    if (x.type !== 10 && x.type !== 11) return x;
  });
  sortedService = sortedService.sort((a: { type: number }, b: { type: number }) => a.type - b.type);

  const onProductLoad = (value: SelectorValue) => {
    const service = services?.find((x: Product) => x.id == value);
    const duration: { label: string; value: number }[] = [];
    const currencies: { label: string; value: number }[] = [];
    service?.prices.map((x: Subscription) => {
      if (
        !duration.find((d) => {
          return d.value == x.billingPeriodId;
        })
      ) {
        switch (x.billingPeriodId) {
          case 0:
            duration.push({ label: 'Per Month', value: 0 });
            break;
          case 1:
            duration.push({ label: 'Per 6 Months - 20% OFF', value: 1 });
            break;
          case 2:
            duration.push({ label: 'Per Year - 20% OFF', value: 2 });
            break;
          case 3:
            duration.push({ label: 'One Time Payment', value: 3 });
            break;
          default:
            break;
        }
      }

      if (
        !currencies.find((d) => {
          return d.value == x.currencyId;
        })
      ) {
        switch (x.currencyId) {
          case 1:
            currencies.push({ label: 'EUR \u20AC', value: 1 });
            break;
          case 2:
            currencies.push({ label: 'USD \u0024', value: 2 });
            break;
          case 3:
            currencies.push({ label: 'GBP \u00A3', value: 3 });
            break;
          default:
            break;
        }
      }
    });

    setFilteredBilling(duration);
    setFilteredCurrency(currencies);
    const billing = !duration?.find((x) => x.value.toString() == billingId) ? duration[0]?.value.toString() : billingId;
    const currency = !currencies?.find((x) => x.value.toString() == currencyId) ? currencies[0]?.value.toString() : currencyId;
    form.setFieldsValue({
      billing: billing,
      currency: currency
    });
    localStorage.setItem('productId', value as string);
    localStorage.setItem('billing', billing as string);
    localStorage.setItem('currencyId', currency as string);
    setBillingId(billing);
    setCurrencyId(currency ?? selectedCurrency);
  };

  const HandleProductChange = (value: SelectorValue) => {
    setProductId(value as string);
    localStorage.setItem('productId', value as string);
    onProductLoad(value);
  };

  const HandleBillingChange = (value: SelectorValue) => {
    setBillingId(value as string);
    localStorage.setItem('billing', value as string);
  };

  const HandleCurrencyChange = (value: SelectorValue) => {
    setCurrencyId(value as string ?? selectedCurrency);
    localStorage.setItem('currencyId', value as string);
  };

  //const [noApiServices, setNoApiServices] = services.map((x: Product) => { return x.type == 2; });
  //const tokenServices = services.map((x: Product) => { return x.type == 3 });
  //const listingServiceskc = services.map((x: Product) => { return x.type == 5; });
  //const privateSupplierServices = services.map((x: Product) => { return x.type == 8 });
  //const templateServices = services.map((x: Product) => { return x.type == 10 });
  //const [serviceList, setServiceList] = useState<Product[]>([]);

  //useEffect(() => {
  //  setServiceList(services.map((x: Product) => { return (x.type == 2 || x.type == 5); }));
  //}, [services]);
  useEffect(() => {
    onProductLoad(productId);
  }, [services]);

  return loading ? (
    <Spin />
  ) : (
    <div className="general-checkout-section">
      <div className="general-checkout-section-wrap">
        <div className="general-checkout-section-inner">
          <div className="title-container">
            <h2>Checkout</h2>
            <h3>Select a service</h3>
          </div>
          <div className="checkout-content">
            <div className="checkout-sections-container">
              <div className="section-container">
                <Form
                  className="bulk-form"
                  layout={'vertical'}
                  form={form}
                  initialValues={{ sourceId: productId, billing: billingId, currency: currencyId }}
                >
                  <Item label="Select the service" name="sourceId">
                    <Selector defaultValue={productId?.toString()} loading={loading} onChange={HandleProductChange}>
                      {sortedService?.map(({ name: label, id: value }: Product) => ({ value, label }))}
                    </Selector>
                  </Item>
                  <Item label="Select your Billing Cycle" name="billing">
                    <Selector value={billingId?.toString()} loading={loading} onChange={HandleBillingChange}>
                      {filteredBilling}
                    </Selector>
                  </Item>
                  <Item label="Select your Preferred Currency" name="currency">
                    <Selector value={currencyId?.toString()} loading={loading} onChange={HandleCurrencyChange}>
                      {filteredCurrency}
                    </Selector>
                  </Item>
                </Form>
                {/* NOT FOR NOW */}
                {/*     <div className="extras">
                    <div className="extra-container">
                      <div className="extra-explanation">
                        <Checkbox className="checkbox">
                          <h4>Add no api server</h4>
                          <p>Your store will be connected to our servers always</p>
                        </Checkbox>
                      </div>
                      <div className="price-extra">
                        <h5>+£12.99</h5>
                      </div>
                    </div>
                    <div className="extra-container">
                      <div className="extra-explanation">
                        <Checkbox className="checkbox">
                          <h4>Add 10 listings service</h4>
                          <p>10 hot selling products will be added to your store</p>
                        </Checkbox>
                      </div>
                      <div className="price-extra">
                        <h5>+£9.99</h5>
                      </div>
                    </div>
                  </div> */}
              </div>
              <div className="section-container">
                <OrderSummary productId={productId} billingId={billingId} currencyId={currencyId} />
                <div className="button-container" onClick={() => routeChange(Links.PaymentsMethod)}>
                  <PrimaryBtn htmlType="submit">
                      Payment method <ArrowRightOutlined />
                  </PrimaryBtn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
