import '../../sass/orders.scss';
import '../../sass/medium-button.scss';
import { useState, useEffect } from 'react';
import { OrderData } from '../../redux/orders/orderSlice';
import { GetOrders } from 'src/redux/orders/orderThunk';
import { PopupModal } from '../../small-components/modals/popup-modal';
import { OrderContent } from 'src/small-components/OrderContent';
import { Alert, Spin, Tooltip } from 'antd';
import { useAppSelector, useAppDispatch } from '../../custom-hooks/reduxCustomHooks';
import OrderDetailsContent from 'src/small-components/OrderDetailsContent';
import { ComplexTable } from '../../small-components/tables/complex-table';
import { ColumnsVisibleByDefault } from './orders/active-columns';
import { OrderColumnId, OrdersColumns } from './orders/columns';
import { ReactUtils } from '../../utils/react-utils';
import Sources from '../../data/sources';
import moment from 'moment';
import { AutoOrderingState } from './data/auto-ordering-state';
import { getSources } from '../../redux/sources/sourcesThunk';
import { Source } from '../../redux/sources/sourceSlice';
import { T } from '../../utils/transShim';
import { AdvancedFilterData } from '../../small-components/tables/complex-table-components/advanced-search';
import { CheckCircleOutlined, ExclamationCircleOutlined, ReloadOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { A } from '../../small-components/A';


export const Orders = () => {
  const dispatch = useAppDispatch();

  const channel = ReactUtils.GetSelectedChannel();

  const { orders, loading } = useAppSelector((state) => state.orders);
  const { sources: sourcesList, loading: loadingSources } = useAppSelector((state) => state.sources);

  const [currentPage, SetCurrentPage] = useState<number>(1);
  const [order, setOrder] = useState<OrderData[]>([]);
  const [selectedOrder, SetSelectedOrder] = useState<OrderData>();
  //For Modal
  const [singleEditOpen, SetSingleEditOpen] = useState<boolean>(false);
  const [orderDetailsOpen, SetOrderDetailsOpen] = useState<boolean>(false);
  const HandleSingleOrderModal = () => SetSingleEditOpen(!singleEditOpen);
  const HandleSingleOrderDetailModal = () => SetOrderDetailsOpen(!orderDetailsOpen);

  const HandleOrderDetailsOpen = () => {
    HandleSingleOrderModal();
    SetOrderDetailsOpen(!orderDetailsOpen);
  };
  const HandleOrderContentOpen = () => {
    HandleSingleOrderModal();
    SetOrderDetailsOpen(!orderDetailsOpen);
  };

  const HandlePageChange = (currentPage: number) => SetCurrentPage(currentPage);

  useEffect(() => {
    if (!!sourcesList && sourcesList.length > 0 && !!sourcesList[0]?.site && sourcesList[0].site == channel?.isoCountry?.toString())
      return;
    if (loadingSources)
      return;
    dispatch(getSources());
  }, [channel?.id]);

  const sources: {[id:number]:Source} = {};
  for (const s of sourcesList) {
    sources[s.id] = s;
  }

  useEffect(() => {
    const orderList: OrderData[] = orders?.orders?.map((l: OrderData) => {
      const marg = 100 * (l.channelPrice * l.quantity + l.channelShipping - l.sourcePrice - l.fees) / l.sourcePrice;
      let margin = '-';
      if (!isNaN(marg) && isFinite(marg)) {
        margin = marg.toFixed(2) + '%';
      }

      let item: OrderData = {
        ...l,
        key: l.id,
        profit: !l.sourcePrice? '-' : (l.channelPrice * l.quantity + l.channelShipping - l.sourcePrice - l.fees).toFixed(2),
        sourceAOConfigured: orders?.sourcesEnabled?.includes(l.sourceId),
        margin: margin
      };

      //Assuming channel and source uses same currency
      //const totalTaxes = (l.channelTax ?? 0) + (l.channelVAT ?? 0) + (l.channelPaymentTaxes ?? 0) + l.fees;
      //l.profit = l.channelPrice * l.quantity + l.channelShipping - l.sourcePrice - totalTaxes;
      //l.sourceAOConfigured = orders.orders.sourcesEnabled.includes(l.sourceId);

      const source = sources[l.sourceId];
      if (source) {
        item = {
          ...item,
          sourceUrl: 'https://' + source.baseUrl + '/' + l.sourcePath,
          sourceName: source.name,
          sourceAOEnabled: source.hasAutoOrder || source.hasAutoOrderHGRAccount
        };
      }
      return item;
    }) ?? [];
    setOrder(orderList);
  }, [orders, sourcesList?.[0]?.id]);

  useEffect(() => {
    dispatch(GetOrders({ channelOAuthIds: [channel?.id ?? 0] }));
  }, [GetOrders, channel?.id ?? 0]);

  const [advancedFilter, SetAdvancedFilter] = useState<AdvancedFilterData[]>([]);

  const ShowAll = () => SetAdvancedFilter([]);

  const ShowProcessing = () => SetAdvancedFilter([{
    columnId: OrderColumnId.Status,
    value: [2]
  }]);

  const ShowCompleted = () => SetAdvancedFilter([{
    columnId: OrderColumnId.Status,
    value: [8,3]
  }]);

  const ShowError = () => SetAdvancedFilter([{
    columnId: OrderColumnId.Status,
    value: [1]
  }]);

  const dataBrief = (() => {
    let procesing = 0;
    let completed = 0;
    let errored = 0;
    let totalToday = 0;
    let maxS = 0;

    if (order && order.length > 0) {
      const proSMap = new Map<number, number>();
      const now = moment(new Date()); //todays date

      for (const o of order) {
        const end = moment(o.date); // order date
        const today = (moment.duration(now.diff(end))).asDays() <= 30;
        if (today) {
          totalToday++;
        }

        if (
          (
            o.status >= AutoOrderingState.AutoorderingPrepared
            && o.status <= AutoOrderingState.LastSteps
            && o.status != AutoOrderingState.CompletedAutoOrderPending
          )
          || o.status == AutoOrderingState.TemporaryError
        ) {
          proSMap.set(o.sourceId, (proSMap.get(o.sourceId) ?? 0) + 1);
          procesing++;
          continue;
        }
        if (!today) {
          continue;
        }
        if (
          o.status == AutoOrderingState.GoingToBuyError
          || o.status == AutoOrderingState.PermanentError
        ) {
          errored++;
        } else if (
          o.status == AutoOrderingState.Completed
          || o.status == AutoOrderingState.ManuallyDispatched
          || o.status == AutoOrderingState.CompletedAutoOrderPending
        ) {
          completed++;
        }
      }
      let max = 0;
      proSMap.forEach((c, s) => {
        if (c > max) {
          maxS = s;
          max = c;
        }
      });
      if (max < 10) {
        maxS = 0;
      }
    }

    return {
      totalToday,
      procesing,
      maxS,
      completed,
      errored
    };
  })();

  const BriefTable = () => {
    const { totalToday, procesing, completed, errored } = dataBrief;
    const isTotalFiltered = (advancedFilter?.length ?? 0) == 0;
    const isCompleteFiltered = (advancedFilter?.find(x => x.columnId == OrderColumnId.Status && !!(x.value?.find(x => (x == 8 || x == 3)))));
    const isErroreFiltered = (advancedFilter?.find(x => x.columnId == OrderColumnId.Status && !!(x.value?.find(x => (x == 1)))));
    const isProcesingFiltered = (advancedFilter?.find(x => x.columnId == OrderColumnId.Status && !!(x.value?.find(x => (x == 2)))));
    return (
      <div className="briefLine">
        {order && <>
          <Tooltip title={<T k="Orders.Button.OrdersMN" values={{ n: totalToday }} />}>
            <div onClick={ShowAll} className={'briefButton briefTotal' + (isTotalFiltered ? ' active' : '')}>
              <ShoppingCartOutlined />
              <div className='label'><T k="Orders.Button.OrdersM"/></div>
              <div className="briefNumber">{totalToday}</div>
            </div>
          </Tooltip>
          <Tooltip title={<T k="Orders.Button.ProcessingNowN" values={{ n: procesing }} />}>
            <div onClick={ShowProcessing} className={'briefButton briefProcesing' + (isProcesingFiltered ? ' active' : '')}>
              <ReloadOutlined />
              <div className='label'><T k="Orders.Button.ProcessingNow"/></div>
              <div className="briefNumber">{procesing}</div>
            </div>
          </Tooltip>
          <Tooltip title={<T k="Orders.Button.CompletedMN" values={{ n: completed }} />}>
            <div onClick={ShowCompleted} className={'briefButton briefCompleted' + (isCompleteFiltered ? ' active' : '')}>
              <CheckCircleOutlined />
              <div className='label'><T k="Orders.Button.CompletedM" /></div>
              <div className="briefNumber">{completed}</div>
            </div>
          </Tooltip>
          <Tooltip title={<T k="Orders.Button.ErrorMN" values={{ n: errored }} />}>
            <div onClick={ShowError} className={'briefButton briefError' + (isErroreFiltered ? ' active' : '')}>
              <ExclamationCircleOutlined />
              <div className='label'><T k="Orders.Button.ErrorM" /></div>
              <div className="briefNumber">{errored}</div>
            </div>
          </Tooltip>
        </>}
      </div>
    );
  };

  return (
    <div className="orders-container">
      {loading ? (
        <Spin />
      ) : (
        <>
          <PopupModal open={singleEditOpen} maxWidth={900} onClose={HandleSingleOrderModal} key={selectedOrder?.id}>
            <OrderContent
              key={selectedOrder?.id}
              order={selectedOrder}
              channelOAuthId={[channel?.id ?? 0]}
              OrderDetailsModalOpen={HandleOrderDetailsOpen}
              HandleSingleOrderModal={HandleSingleOrderModal}
            />
          </PopupModal>

          <PopupModal open={orderDetailsOpen} maxWidth={1100} onClose={HandleSingleOrderDetailModal}>
            <OrderDetailsContent data={selectedOrder} OrderContentModalOpen={HandleOrderContentOpen} />
          </PopupModal>
          <BriefTable />
          {dataBrief.maxS > 0 && <Alert type='warning' message={<>
            <A to={'/configuration/auto-ordering/?s=' + dataBrief.maxS}
              className="manyProcessing"
              title={'Adding another ' + Sources[dataBrief.maxS].name + ' account will reduce the processing time of your orders'}
            >
              <i className="fas fa-exclamation-triangle"></i>
            </A>
          </>}/>}
          <ComplexTable
            uiIdentifier={'order'}
            data={order}
            allColumnData={OrdersColumns(channel?.channelId)}
            defaultVisibleColumns={ColumnsVisibleByDefault(channel?.channelId)}
            hideWhenEmpty={false}
            loadingData={loading}
            currentPage={currentPage}
            hideTotal={true}
            onPageChange={HandlePageChange}
            onRow={(record) => {
              return {
                onClick: () => {
                  SetSelectedOrder(record as OrderData);
                  HandleSingleOrderModal();
                }
              };
            }}
            forceFilter={advancedFilter}
            onChangeFilter={SetAdvancedFilter}
            preSearch={BriefTable()}
          />
        </>
      )}
    </div>
  );
};
