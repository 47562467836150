import { CSSProperties, ReactNode } from 'react';
import { Divider, Modal } from 'antd';
import '../../sass/small-components/popup-modal.scss';
import { CrossModalIcon } from '../../components/common/Icons';


interface Props {
  open?: boolean;
  children: React.ReactNode;
  maxWidth?: number;
  bodyStyle?: CSSProperties;
  style?: CSSProperties;
  onClose?: () => void;
  title?: ReactNode;
  showCloseX?: boolean;
  footer?: React.ReactNode
  maskClosable?: boolean;
}

export const PopupModal = (props: Props) => {
  const { open, children, maxWidth, style, bodyStyle, title, showCloseX, footer, maskClosable, onClose } = props;
  return (
    <Modal
      open={open}
      width={maxWidth}
      style={style}
      bodyStyle={bodyStyle}
      onCancel={onClose}
      closable={showCloseX ?? true}
      maskClosable={maskClosable ?? true}
      footer={null}
      title={null}
      closeIcon={<CrossModalIcon /> }
    >
      <div className="popup-modal-content">
        {title && <>
          <h1>{title}</h1>
          <Divider />
        </>}
        {children}
        {!!footer && <div className="modal-footer">
          {footer}
        </div>}
      </div>
    </Modal>
  );
};
