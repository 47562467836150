import { Row, Col, Form, InputNumber, Input } from 'antd';

import { useTranslation, T } from '../../../../../utils/transShim';
import { SettingList } from '../../../../../small-components/settings/setting-list';
import { ListingsUtils } from '../../../../../utils/listings-utils';
import { ePlatform, GetPlatform } from '../../../../../data/platforms';
import { useState } from 'react';
import { Links } from '../../../../../links';
import { A } from '../../../../../small-components/A';

interface Props {
  platform: ePlatform | undefined;

  onMarkupChange: (value: number | undefined) => void;
  onQuantityChange: (value: number) => void;
  onNotesChange: (value: string) => void;
  onTitleChange: (value: string) => void;
  onPriceChange: (value: number) => void;

  valueMarkup: number | undefined;
  valueQuantity: number;
  valueNotes: string;
  valueTitle: string;
  valuePrice: number;

  currentMarkup?: number;
  settingsMarkup: number;
  channelPrice: number;
  sourcePrice: number | undefined;
  feePercentage: number;
  ending99: boolean;
  currentProfit?: number;

  //listingId: number;
  //productSourceId: number;
}

export const EditSingleListingMain = (props: Props) => {
  const {
    platform,
    valueMarkup, valueQuantity, valueNotes, valueTitle, valuePrice,
    currentMarkup, sourcePrice, feePercentage, ending99, currentProfit, channelPrice, settingsMarkup,
    onMarkupChange, onQuantityChange, onNotesChange, onTitleChange, onPriceChange
  } = props;

  const [calculatedProfit, SetCalculatedProfit] = useState<number | undefined>(sourcePrice ? ListingsUtils.CalculateProfitFromSellPrice(sourcePrice, feePercentage, valuePrice) : currentProfit);

  const labelDefSet = useTranslation('Setting.FinalDefinedBySettings', { 'value': settingsMarkup }) as string;
  const labelCustom = useTranslation('Setting.Custom') as string;

  //Se new settings-------------------
  const SetNewMarkup = (value: number | null | undefined) => {
    onMarkupChange(value != null ? Math.max(0, value) : value ?? undefined);
  };
  const SetNewPrice = (value: number) => {
    onPriceChange(Math.max(sourcePrice ?? 0, value));
  };
  const HandleChangeQuantity = (value: number | null | undefined) => {
    const newQuantity = Math.max(value ?? valueQuantity, 0);
    onQuantityChange(newQuantity);
  };
  const HandleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let newNotes = e.currentTarget.value;
    if (newNotes.length > 500)
      newNotes = newNotes.substring(0, 500);
    onNotesChange(newNotes);
  };
  const HandleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let newTitle = e.currentTarget.value;
    const maxLength = GetPlatform(platform).titleLength;
    if (newTitle.length > maxLength)
      newTitle = newTitle.substring(0, maxLength);
    onTitleChange(newTitle);
  };
  const HandleCustomMarkupChange = (v: string | null) => {
    const newMarkup = v == '1' ? currentMarkup : null;
    SetNewMarkup(newMarkup);
    if (sourcePrice) {
      const newPrice = sourcePrice ? ListingsUtils.CalculateSellPriceFromMarkup(sourcePrice, newMarkup ?? settingsMarkup ?? 0, ending99) : channelPrice;
      SetNewPrice(newPrice);
      SetCalculatedProfit(ListingsUtils.CalculateProfitFromSellPrice(sourcePrice, feePercentage, newPrice));
    }
  };
  const HandleChangePrice = (newPrice: number | null) => {
    const sellPrice = Math.round((newPrice ?? 0) * 100) / 100;
    if (sourcePrice) {
      const markup = ListingsUtils.CalculateMarkupFromSellPrice(sourcePrice, sellPrice);
      SetNewMarkup(markup);
      SetNewPrice(sellPrice);
      SetCalculatedProfit(ListingsUtils.CalculateProfitFromSellPrice(sourcePrice, feePercentage, sellPrice));
    }
  };
  const HandleChangeMarkup = (newMarkup: number | null) => {
    SetNewMarkup(newMarkup ?? undefined);
    if (sourcePrice) {
      const newPrice = ListingsUtils.CalculateSellPriceFromMarkup(sourcePrice, newMarkup ?? settingsMarkup ?? 0, ending99);
      SetNewPrice(newPrice);
      SetCalculatedProfit(ListingsUtils.CalculateProfitFromSellPrice(sourcePrice, feePercentage, newPrice));
    }
  };
  const HandleChangeProfit = (newProfit: number | null) => {
    const profit = Math.round((newProfit ?? 0) * 100) / 100;
    SetCalculatedProfit(profit);
    const newPrice = sourcePrice ? ListingsUtils.CalculateSellPriceFromProfit(sourcePrice, profit, feePercentage, ending99) : channelPrice;
    const markup =
      sourcePrice
        ? ListingsUtils.CalculateMarkupFromSellPrice(sourcePrice, newPrice)
        : valueMarkup;
    SetNewMarkup(markup);
    SetNewPrice(newPrice);
  };
  //-------------------------------

  return (<>
    <Form layout="vertical" className="form">
      <Row gutter={[40, 0]}>
        <Col span={16} >
          <Form.Item label={<T k="Listings.Column.Title" />}>
            <Input.TextArea rows={2} className="blue-input" value={valueTitle} style={{ resize: 'none' }} onChange={HandleTitleChange} />
          </Form.Item>
          <Form.Item label={<T k="Listings.Column.Stock" />}>
            <InputNumber className="blue-input" value={valueQuantity} onChange={HandleChangeQuantity} />
          </Form.Item>
          <Form.Item label={<T k="Listings.Column.Notes" />}>
            <Input.TextArea className="blue-input" rows={6} onChange={HandleNotesChange} value={valueNotes} />
          </Form.Item>
        </Col>
        <Col span={8} >
          <Form.Item label={<T k="Setting.Markup" />}>
            <SettingList
              listData={[
                {
                  label: labelDefSet,
                  value: '0'
                },
                {
                  label: labelCustom,
                  value: '1'
                }
              ]}
              value={valueMarkup != null ? '1' : '0'}
              onChange={HandleCustomMarkupChange}
              loadingData={false}
            />
          </Form.Item>
          <Form.Item label={<T k="Setting.YourPrice" />}>
            <InputNumber className="blue-input" value={valuePrice} min={sourcePrice} onChange={HandleChangePrice} />
          </Form.Item>
          <Form.Item label={<T k="Setting.Markup%" />}>
            <InputNumber className="blue-input" value={valueMarkup ?? settingsMarkup} min={0} onChange={HandleChangeMarkup} />
          </Form.Item>
          <Form.Item label={<T k="Setting.Profit" />}>
            <InputNumber className="blue-input" value={calculatedProfit} onChange={HandleChangeProfit} />
          </Form.Item>

          <p>
            <strong>Your</strong> fee percentage <strong>is set to { feePercentage }%</strong>. <A to={ Links.Configuration.Channel.Channel }> Change</A>
          </p>
        </Col>
      </Row>
    </Form>
  </>);
};