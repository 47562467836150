import { FontSizeOutlined } from '@ant-design/icons';
import React from 'react';
import { CreatableMultiple } from '../../../small-components/form/creatable-multiple';
import { SelectorValue } from '../../../small-components/form/selector';
import { Input } from 'antd';
import { PrimaryBtn } from 'src/small-components/ActionBtns';

export enum TitleSuggestionsGroup {
  MainWords,
  ExtraWords,
  Titles,
  OriginalTitle
}

export interface SuggestedTitleType {
  group: TitleSuggestionsGroup;
  label: string;
  value?: string;
}

export interface SuggestedTitleGroup {
  label: string;
  value?: string; //Never used
  options: SuggestedTitleType[];
}

export type TitleEditorProps = {
  onChange: (newTitle: string) => void;
  suggestedTitles: SuggestedTitleGroup[];
  titleMaxLength: number;
  value: string;
  inputValue: string;
  name: string;
  textMode: boolean;
};

export type TitleEditorState = {
  value: string;
  inputValue: string;
  textMode: boolean;
};

//eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
function arrayMove(array: any[], from: number, to: number) {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

export default class TitleEditor extends React.Component<TitleEditorProps, TitleEditorState> {
  public static defaultProps = {
    onChange: () => null,
    titleMaxLength: 80,
    name: 'title',
    textMode: false
  };

  constructor(props: TitleEditorProps) {
    super(props);

    this.state = {
      value: this.props.value,
      inputValue: this.props.inputValue,
      textMode: this.props.textMode
    };
  }

  onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const newValue = arrayMove(this.state.value.split(' '), oldIndex, newIndex);
    this.OnChange(newValue.join(' '));
  };

  render() {
    return (
      <div className="title-section">
        <PrimaryBtn>
          <FontSizeOutlined
            className="button-change-text"
            onClick={() =>
              this.setState((prevState) => {
                return { ...prevState, textMode: !prevState.textMode };
              })
            }
          />
        </PrimaryBtn>
        <div style={{ width: 'calc(100% - 63px)', display: 'inline-block', marginLeft: 10 }}>
          {this.state.textMode && (
            <Input
              className="form-control"
              type="text"
              name={this.props.name}
              defaultValue={this.state.value}
              onChange={(a) => this.OnChange(a.target.value)}
            />
          )}
          {!this.state.textMode && (
            <CreatableMultiple
              sortable={true}
              isValidNewOption={this.IsAValidNewOption}
              value={this.FromStringToOptions(this.state.value)}
              onChange={(value) => {
                if (value === null) this.OnChange('');
                else this.OnChange(this.OptionsToString(value));
              }}
            >
              {this.props.suggestedTitles.map((x, i) => ({
                label: <span>{x.label}</span>,
                value: i,
                options: x.options.filter((x) => x && x.label.length > 0).map((x) => ({ label: <span>{x.label}</span>, value: x.label }))
              }))}
            </CreatableMultiple>
          )}
        </div>
        <span style={{ width: 30, textAlign: 'right', display: 'inline-block' }}>{this.props.value.length}</span>
      </div>
    );
  }

  IsAValidNewOption = (inputValue: string) => {
    return (
      inputValue !== '' &&
      inputValue !== ' ' &&
      this.OptionsToString(this.props.value).length + inputValue.length <= this.props.titleMaxLength
    );
  };
  //IsAValidNewOption = (inputValue: string, value: SuggestedTitleGroup[], options: SuggestedTitleGroup[]) => {
  //    return inputValue !== "" && inputValue !== " " && this.OptionsToString(value).length + inputValue.length <= this.props.titleMaxLength;
  //}

  UNSAFE_componentWillReceiveProps(nextProps: TitleEditorProps) {
    if (nextProps.value != this.props.value) {
      this.setState((prvState) => {
        return { ...prvState, value: nextProps.value };
      });
    }
  }

  OnChange = (title: string) => {
    this.props.onChange(title);
    this.setState((prevState) => {
      return { ...prevState, value: title, inputValue: '' };
    });
  };

  FromStringToOptions = (str: string): SelectorValue[] => {
    if (str === '' || str === null) {
      return [];
    }
    return str
      .split(' ')
      .map((s) => {
        return s;
      })
      .filter((x) => x.length > 0);
  };

  OptionsToString = (options: SelectorValue | SelectorValue[]): string => {
    if (Array.isArray(options)) return options.join(' ');

    return options as string;
  };
}
