import { Spin } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { GetHistoryResponseListing } from '../../redux/smart-lister/data/get-history-response-listing';
import { SmartListerHistoryGet } from '../../redux/smart-lister/smart-thunk';
import { ComplexTable } from '../../small-components/tables/complex-table';
import { ColumnData } from '../../small-components/tables/types/columns';
import { T } from '../../utils/transShim';
import { BulkListingErrorDetail } from '../list-now/bulk-listing';
import { RenderDate } from '../listings/Listings/columns-renders';

export const SmartListerFilterHistory = () => {
  const dispatch = useAppDispatch();

  const { id: ids } = useParams<{ id?: string }>();
  const id = parseInt(ids ?? '');

  const { history } = useAppSelector(state => state.smartLister);
  const listData = history?.[id];

  useEffect(() => {
    if (!id)
      return;

    if (isNaN(id))
      return;

    if (listData) {
      if (listData.loading)
        return;
    }

    dispatch(SmartListerHistoryGet({ id: id}));
  }, [id]);

  if (!listData || history[id].loading) {
    return <Spin />;
  }

  const IsErrorRow = (row: GetHistoryResponseListing) => row.title == null && row.channelItem == null;

  const RenderChannelItem = (value: string | undefined | null, row: GetHistoryResponseListing) => {
    if (IsErrorRow(row)) {
      return <>{BulkListingErrorDetail(row.errorCode)}</>;
    }

    return value;
  }; 

  const Columns: ColumnData<GetHistoryResponseListing>[] = [
    {
      id: 0,
      title: <T k='Listings.Column.CreatedOn' />,
      dataIndex: 'createdOn',
      render: RenderDate
    },
    {
      id:1,
      title: <T k='Listings.Column.ChannelItem'/>,
      dataIndex: 'channelItem',
      render: RenderChannelItem,
      onCell: (row: GetHistoryResponseListing) => ({
        colSpan: IsErrorRow(row) ? 2 : 1
      })
    },
    {
      id:2,
      title: <T k='Listings.Column.Title' />,
      dataIndex: 'title'
    }
  ];

  return <ComplexTable
    uiIdentifier={'aiassistant'}
    data={[...(listData?.data ?? [])].sort((b, a) => a.createdOn.localeCompare(b.createdOn))}
    allColumnData={Columns}
    hideWhenEmpty={false}
    hideTotal={true}
    advancedSearch={false}
    smartSearch={false}
  />;
};