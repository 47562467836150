import { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Switch, Route, withRouter, Redirect, RouteComponentProps } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ProtectedRoute } from './ProtectedRoute';
import { Links } from './links';
import { ReactUtils } from './utils/react-utils';
import { WinningDownload } from './components/winning/download';
import { useAppDispatch, useAppSelector } from './custom-hooks/reduxCustomHooks';
import { AutoOrderDebt } from './components/subscriptions/autoorder-debt';
import { getAccountConfiguration } from './redux/account-configuration/account-configuration-thunk';
import { eAccountSettings } from './redux/account-configuration/account-configuration-slice';
import { SmartLister } from './components/smart-lister/smart-lister';
import { Topbar } from './components/topbar/Topbar';
import { Sidebar } from './components/sidebar/Sidebar';
import { ClearAll } from './components/user/clear-all';
import { Subscriptions } from './components/subscriptions/Subscriptions';
import { Checkout } from './components/subscriptions/checkout/checkout';
import { PaymentsMethodServices } from './components/listing-service/PaymentsMethod';
import { AccountConfiguration } from './components/account-settings/account/account-configuration';
import { Success } from './components/subscriptions/Success';
import { Error } from './components/subscriptions/Error';
import { AffiliateDashboard } from './components/dashboard/AffiliateDashboard';
import { Blank } from './components/extension/blank';
import { AOCommissions } from './components/auto-ordering/auto-order-commissions';
import { UnImpersonate } from './components/user/UnImpersonate';
import { Impersonate } from './components/user/Impersonate';
import { Dashboard } from './components/dashboard/Dashboard';
import { DispatchedOrders } from './components/DispatchOrders';
import { Listings } from './components/listings/view-listings';
import { ListNow } from './components/list-now/list-now';
import { ManualPublish } from './components/list-now/manual-publish';
import { SmartListerNewFilter } from './components/smart-lister/smart-lister-new-filter';
import { NewListingExtension } from './components/extension/new-listing-extension';
import { BulkListing } from './components/list-now/bulk-listing';
import { Orders } from './components/orders/Orders';
import { BuyNow } from './components/orders/BuyNow';
import { SourcesConfiguration } from './components/sources/sources-configuration';
import { ChannelConfiguration } from './components/chanel/channel-configuration';
import { Templates } from './components/templates/templates';
import { EditTemplate } from './components/templates/edit-template';
import { AutoOrderingList } from './components/auto-ordering/auto-ordering-list';
import { ListingServiceCheckout } from './components/listing-service/ListingServiceCheckout';
import { NewChannelCallback } from './components/chanel/new-channel/new-channel-callback';
import { RelinkStore } from './components/chanel/new-channel/relink-store';
import { PriceWarrior } from './components/services/PriceWarrior';
import { Catalog } from './components/catalog/Catalog';
import { GetStarted } from './components/help/GetStarted';
import { ConfigureListingService } from './components/listing-service/configure-listing-service';
import { ConfigureNoapi } from './components/NoApi/ConfigureNoapi';
import { BrowserExtensions } from './components/browser-extensions/BrowserExtensions';
import { OurServices } from './components/subscriptions/OurServices';
import { SingleService } from './components/services/SingleService';
import { Services } from './components/services/Services';
import { NewChannel } from './components/chanel/new-channel';
import { PricingRules } from './components/pricing-rules/PricingRules';
import { VaProfiles } from './components/va-profiles/VaProfiles';
import { ChatContainer, ChatContainerActionsRef } from './small-components/chat-container';
import { SmartListerFilterHistory } from './components/smart-lister/smart-lister-filter-history';
import { Layout } from 'antd';
import { Data } from './components/data/data';
import { UserAuth } from './components/user/user-auth';
import { UserConfirm } from './components/user/register/user-confirm';
import { getUserQuota, getUserToken, RefreshUser } from './redux/user/userThunk';
import { ePlatform } from './data/platforms';

export const App = withRouter(({ history }: { history: RouteComponentProps['history'] }) => {
  const dispatch = useAppDispatch();

  const { pathname } = history.location;

  const channels = ReactUtils.GetAllChannels();
  const selectedChannel = ReactUtils.GetSelectedChannel();
  const isStick = ReactUtils.stringToBoolean(localStorage.getItem('isStick'));

  const isAuthenticated = localStorage.getItem('isAuthenticated');

  const { quota, user } = useAppSelector((state) => state.user);
  const { settings } = useAppSelector((state) => state.accountConfiguration);

  const [visible, setVisible] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState(!isStick);
  const [staticValue, setStaticValue] = useState<boolean>(isStick);

  const toggleStaticValue = () => {
    const val = !staticValue;
    setStaticValue(val);
    localStorage.setItem('isStick', val.toString());
  };

  useEffect(() => {
    if (!settings)
      dispatch(getAccountConfiguration());
  }, [getAccountConfiguration]);

  useEffect(() => {
    dispatch(getUserQuota());
    dispatch(getUserToken());
    if (
      user != null &&
      (
        user.updatedOn == null
        || ((new Date()).getTime() - new Date(user.updatedOn).getTime()) > 1000 * 3600 * 24
      )
    ) {//update every 24h
      dispatch(RefreshUser());
    }
  }, [getUserToken, getUserQuota]);

  const showMobileSider = () => {
    setStaticValue(!staticValue);
    setVisible(!visible);
    setCollapsed(!collapsed);
  };
  const closeMobileSider = () => setVisible(!visible);
  const isAffiliateDomain = window.location.href.replace('https://', '').replace('http://', '').startsWith('affiliates');
  const dontShowMenus =
    pathname.startsWith(Links.NewChannel.Link) ||
    pathname.startsWith(Links.PublishExtension) ||
    pathname.startsWith(Links.Blank) ||
    !isAuthenticated ||
    channels.length == 0 ||
    pathname.indexOf(Links.NewChannelCallBack.Base.substring(0, Links.NewChannelCallBack.Base.length - 1)) >= 0;

  const dontShowSidebar =
    pathname.startsWith(Links.PublishExtension) ||
    pathname.startsWith(Links.Blank) ||
    !isAuthenticated ||
    (!isAffiliateDomain && (
      pathname.indexOf(Links.NewChannelCallBack.Base.substring(0, Links.NewChannelCallBack.Base.length - 1)) >= 0 ||
      pathname.startsWith(Links.NewChannel.Link) ||
      channels.length == 0
    ));

  const accountBusinessNotConfigured = (() => {
    if (settings == null)
      return false;
    const kind = settings.find(x => x.key == eAccountSettings.IAmBusiness)?.value;
    if (kind == null)
      return true;
    if (kind.toLowerCase() == 'false' || kind == '0')//personal
      return false;

    const bType = settings.find(x => x.key == eAccountSettings.BusinessType)?.value;
    if (bType == null || bType.trim() == '')
      return true;
    const bName = settings.find(x => x.key == eAccountSettings.Name)?.value;
    if (bName == null || bName.trim() == '')
      return true;
    const bCountry = settings.find(x => x.key == eAccountSettings.Country)?.value;
    if (bCountry == null || bCountry.trim() == '')
      return true;

    return false;
  })();

  const timeSinceCreation = user?.user?.createdOn != null ? (new Date().getTime() - new Date(user?.user?.createdOn).getTime()) / (1000 * 3600 * 24) : null;

  const [chatEnabled, SetChatEnabled] = useState<boolean>(false);
  const [chatActions, _] = useState<ChatContainerActionsRef>({});

  const isImpersonated = ReactUtils.stringToBoolean(localStorage.getItem('isImpersonated'));

  return (
    <>
      <ChatContainer
        actionsRef={chatActions}
        onStatusChanged={SetChatEnabled}
        hidden={!dontShowSidebar}
      />
      {dontShowMenus ? null : <Topbar showMobileSider={showMobileSider} chatEnabled={chatEnabled} chatActions={chatActions} />}
      {dontShowSidebar ? null : (
        <Sidebar
          className="sider"
          mobileSiderVisible={visible}
          closeMobileSider={closeMobileSider}
          setCollapsed={setCollapsed}
          staticValue={staticValue}
          togglestatic={toggleStaticValue}
          collapsed={collapsed}
          chatEnabled={chatEnabled}
          chatActions={chatActions}
          affiliates={isAffiliateDomain}
        />
      )}
      <Layout className={
        dontShowMenus ?
          dontShowSidebar ?
            'content-area no-topbar no-sidebar'
            :
            'content-area no-topbar'
          :
          staticValue ?
            'content-area content-area-resized'
            :
            'content-area'
      }>
        <Switch>
          <Route exact path="/">
            <Redirect to={Links.Dashboard} />
          </Route>
          <Route path={Links.Credentials.ClearAll} component={ClearAll} />
          <Route path={Links.Winning.Download.Slug} component={WinningDownload} />
          <Route path={Links.Credentials.ConfirmLink} component={UserConfirm} />
          <Route
            path={[
              Links.Credentials.Login,
              Links.Credentials.Register.Slug,
              Links.Credentials.ResetPassword,
              Links.Credentials.ForgetPassword
            ]}
          >
            {!isAuthenticated && (
              <Switch>
                <Route path={Links.Credentials.Login} component={UserAuth} />
                <Route path={Links.Credentials.Register.Slug} component={UserAuth} />
                <Route path={Links.Credentials.ResetPassword} component={UserAuth} />
                <Route path={Links.Credentials.ForgetPassword} component={UserAuth} />
              </Switch>
            )}
            {isAuthenticated && <Redirect to={Links.Dashboard} />}
          </Route>
          <ProtectedRoute path={Links.Subscriptions} component={Subscriptions} />
          <ProtectedRoute path={Links.Checkout.Slug} component={Checkout} />
          <ProtectedRoute path={Links.PaymentsMethod} component={PaymentsMethodServices} />
          <ProtectedRoute path={Links.Configuration.Account.Slug} component={AccountConfiguration} />
          <ProtectedRoute path={Links.Payment.Success} component={Success} />
          <ProtectedRoute path={Links.Payment.Error} component={Error} />
          <ProtectedRoute path={Links.Impersonate} component={Impersonate} />
          <ProtectedRoute path={Links.UnImpersonate} component={UnImpersonate} />
          <ProtectedRoute path={Links.AOCommissions} component={AOCommissions} />
          <ProtectedRoute path={Links.Blank} component={Blank} />
          <ProtectedRoute path={Links.AffiliateDashboard} component={AffiliateDashboard} />
          {isAffiliateDomain && <ProtectedRoute component={AffiliateDashboard} />}
          {quota?.quota == 0 && <ProtectedRoute component={Subscriptions} />}
          {isImpersonated && quota?.automaticallyRestricted && <ProtectedRoute path={Links.Orders} component={Orders} />}
          {quota?.automaticallyRestricted && <ProtectedRoute component={AutoOrderDebt} />}
          {accountBusinessNotConfigured && timeSinceCreation != null && timeSinceCreation > 30 && <ProtectedRoute component={AccountConfiguration} />}
          <ProtectedRoute path={Links.RelinkStore.Slug} component={RelinkStore} />
          <ProtectedRoute path={Links.NewChannel.Slug} component={NewChannel} />
          <ProtectedRoute path={Links.NewChannelCallBack.Slug} component={NewChannelCallback} />
          {(ReactUtils.AreChannelsLoaded() && (channels == null || channels.length == 0)) && <ProtectedRoute component={NewChannel} />}
          <ProtectedRoute path={Links.Dashboard} component={Dashboard} />
          <ProtectedRoute path={Links.DispatchedOrders} component={DispatchedOrders} />
          <ProtectedRoute path={Links.Products.Slug} component={Listings} />

          <Route path={[Links.PublishNow,
            Links.ManualPublish,
            Links.SmartLister.OrderHistory.Slug,
            Links.SmartLister.OrderCreator.Slug,
            Links.SmartLister.Base,
            Links.PublishExtension,
            Links.BulkPublish,
            Links.Catalog]}
          >
            {selectedChannel?.channelId != ePlatform.Amazon && <Switch>
              <ProtectedRoute path={Links.PublishNow} component={ListNow} />
              <ProtectedRoute path={Links.ManualPublish} component={ManualPublish} />
              <ProtectedRoute path={Links.SmartLister.OrderHistory.Slug} component={SmartListerFilterHistory} />
              <ProtectedRoute path={Links.SmartLister.OrderCreator.Slug} component={SmartListerNewFilter} />
              <ProtectedRoute path={Links.SmartLister.Base} component={SmartLister} />
              <ProtectedRoute path={Links.PublishExtension} component={NewListingExtension} />
              <ProtectedRoute path={Links.BulkPublish} component={BulkListing} />
              <ProtectedRoute path={Links.Catalog} component={Catalog} />
            </Switch>}
            {selectedChannel?.channelId == ePlatform.Amazon && <Redirect to={Links.Products.Missing} />}
          </Route>

          <ProtectedRoute path={Links.Orders} component={Orders} />
          <ProtectedRoute path={Links.BuyNow} component={BuyNow} />
          <ProtectedRoute path={Links.Data} component={Data} />
          <ProtectedRoute path={Links.Configuration.Sources.Slug} component={SourcesConfiguration} />
          <ProtectedRoute path={Links.Configuration.Channel.Slug} component={ChannelConfiguration} />
          <ProtectedRoute path={Links.Configuration.Templates.Main} component={Templates} exact />
          <ProtectedRoute path={Links.Configuration.Templates.Edit} component={EditTemplate} />
          <ProtectedRoute path={Links.Configuration.AutoOrdering.Slug} component={AutoOrderingList} />
          <ProtectedRoute path={Links.Configuration.VaProfiles} component={VaProfiles} />
          <ProtectedRoute path={Links.Configuration.PricingRules} component={PricingRules} />
          <ProtectedRoute path={Links.Services.Main} component={Services} exact />
          <ProtectedRoute path={Links.Services.Slug} component={SingleService} />
          <ProtectedRoute path={Links.OurServices} component={OurServices} />
          <ProtectedRoute path={Links.BrowserExtension} component={BrowserExtensions} />
          <ProtectedRoute path={Links.ConfigureNoapi} component={ConfigureNoapi} />
          <ProtectedRoute path={Links.ConfigureListingService} component={ConfigureListingService} />
          <ProtectedRoute path={Links.GetStarted} component={GetStarted} />
          <ProtectedRoute path={Links.PriceWarriorConfig} component={PriceWarrior} />
          <ProtectedRoute path={Links.ListingServiceCheckout} component={ListingServiceCheckout} />



        </Switch>
      </Layout>
      <ToastContainer closeButton={true} theme="colored" />
    </>
  );
});