import { EditOutlined, PauseCircleOutlined } from '@ant-design/icons';
import { Modal, Spin } from 'antd';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../custom-hooks/reduxCustomHooks';
import { eCountry } from '../../../../../data/countries';
import { Channel } from '../../../../../redux/channels/channelsSlice';
import { eChannelListingStatus, PendingListing } from '../../../../../redux/listings/listings-slice';
import {
  GetPendingListings,
  ListPendingNow,
  LoadEditPendingListing,
  PauseToReview,
  RemovePendingListing
} from '../../../../../redux/listings/listings-thunk';
import { PrimaryBtn, DangerBtn, WarningBtn } from '../../../../../small-components/ActionBtns';
import { LoadingModal } from '../../../../../small-components/modals/loading-modal';
import { ListingsUtils } from '../../../../../utils/listings-utils';
import { ReactUtils } from '../../../../../utils/react-utils';
import { T } from '../../../../../utils/transShim';
import { ProcessOrderIcon, TrashIcon } from '../../../../common/Icons';
import { NewListingForm } from '../../../../extension/new-listing-form';
import { ExtraData } from '../../types';
import { ErrorSolution } from './error-solution';
import '../../../../../sass/listings/status-pending-modal.scss';
import { PopupModal } from '../../../../../small-components/modals/popup-modal';
import { A } from '../../../../../small-components/A';


type Props = {
  channel: Channel | undefined;
  listing?: PendingListing & ExtraData;
  visible: boolean;
  onClose: () => void;

  //In case we don't want to put listing
  channelListingId?: number;
  sourceBaseUrl?: string;
  path?: string;
  errorMessage?: string;
  errorSourceInfo?: string;
  status?: eChannelListingStatus;
  dontListUntil?: string | Date;
};

export enum SimplifiedPendingStatus {
  Unknown,
  Error,
  Listing,
  Review,
  Scheduled
}
const errorStaus =
  eChannelListingStatus.PermanentFailure |
  eChannelListingStatus.TemporaryFailure |
  eChannelListingStatus.ExceptionThrown |
  eChannelListingStatus.InvalidUserCredentials;
const bulkStatus =
  eChannelListingStatus.PendingForScraping |
  eChannelListingStatus.BulkApiCreated |
  eChannelListingStatus.BULK |
  eChannelListingStatus.BulkScraping;
const listingStatus =
  eChannelListingStatus.PreparedForFirstListing |
  eChannelListingStatus.QueuedForWork |
  eChannelListingStatus.Retrying |
  eChannelListingStatus.ListingInStore |
  eChannelListingStatus.RetryingTwice |
  eChannelListingStatus.RetryingFinal |
  eChannelListingStatus.CreatingListing;

export const GetSimplifiedPendingStatus = (status: eChannelListingStatus, dontListUntil?: Date | string) => {
  if (!status) return SimplifiedPendingStatus.Unknown;

  if (status & eChannelListingStatus.PendingToReview) return SimplifiedPendingStatus.Review;

  if (status & errorStaus) return SimplifiedPendingStatus.Error;

  if (status & (listingStatus | bulkStatus)) {
    if (dontListUntil && (new Date(dontListUntil).getTime() - new Date().getTime()) / 60000 > 5) {
      return SimplifiedPendingStatus.Scheduled;
    } else {
      return SimplifiedPendingStatus.Listing;
    }
  }
};

export const StatusPendingModal = (props: Props) => {
  const {
    listing,
    onClose,
    channel,
    visible,
    channelListingId,
    sourceBaseUrl,
    path,
    errorMessage,
    errorSourceInfo,
    status,
    dontListUntil
  } = props;

  const dispatch = useAppDispatch();
  const { saving, loadEditPending } = useAppSelector((state) => state.listings);
  const [listingNow, SetListingNow] = useState<boolean>();
  const [removingListing, SetRemovingListing] = useState<boolean>();
  const [pausingListing, SetPausingListing] = useState<boolean>();
  const [editing, SetEditing] = useState<boolean>();

  const HandleOnSave = () => {
    dispatch(GetPendingListings());
    SetEditing(false);
    onClose?.();
  };
  const HandleEditClick = () => {
    if (!listing && !channelListingId) return;
    dispatch(LoadEditPendingListing(listing?.id ?? channelListingId ?? 0));
    SetEditing(true);
  };
  const HandleListNowClick = () => {
    if (!listing && !channelListingId) return;
    dispatch(ListPendingNow(listing?.channelListingId ?? channelListingId ?? 0));
    SetListingNow(true);
  };
  const HandleDeleteClick = () => {
    if (!listing && !channelListingId) return;
    dispatch(RemovePendingListing(listing?.channelListingId ?? channelListingId ?? 0));
    SetRemovingListing(true);
  };
  const HandlePauseClick = () => {
    if (!listing && channelListingId == null) return;
    dispatch(PauseToReview(listing?.channelListingId ?? channelListingId ?? 0));
    SetPausingListing(true);
  };

  const Body = () => {
    return (
      <>
        {(listing || (path != null && sourceBaseUrl != null)) && (
          <p>
            <A
              target="_blank"
              rel="noreferrer"
              to={ReactUtils.GetOutUrl(
                'https://' + (listing?.source?.baseUrl ?? sourceBaseUrl ?? '') + '/' + (listing?.path ?? path ?? '')
              ) ?? ''}
            >
              <T k="Listings.Pending.ViewSource" />
            </A>
          </p>
        )}
        {(listing || errorMessage != null) && (
          <ErrorSolution
            channelId={channel?.channelId}
            country={channel?.isoCountry ?? eCountry.UK}
            errorMessage={listing?.errorMessage ?? errorMessage ?? ''}
            errorSourceInfo={listing?.errorSourceInfo ?? errorSourceInfo}
          />
        )}
      </>
    );
  };

  const EditButton = (
    <PrimaryBtn key={1} onClick={HandleEditClick}>
      <EditOutlined />
      <span>
        <T k="Listings.Button.Edit" />
      </span>
    </PrimaryBtn>
  );
  const RetryButton = (
    <PrimaryBtn key={2} onClick={HandleListNowClick}>
      <ProcessOrderIcon />
      <T k="Listings.Button.Retry" />
    </PrimaryBtn>
  );
  const DeleteButton = (
    <DangerBtn className="mr-3" key={3} onClick={HandleDeleteClick}>
      <TrashIcon />
      <span>
        {' '}
        <T k="Listings.Button.Delete" />
      </span>
    </DangerBtn>
  );
  const PauseButton = (
    <WarningBtn className="mr-3" key={4} onClick={HandlePauseClick}>
      <PauseCircleOutlined />
      <span>
        {' '}
        <T k="Listings.Button.Pause" />
      </span>
    </WarningBtn>
  );
  const PublishNowButton = (
    <PrimaryBtn key={5} onClick={HandleListNowClick}>
      <ProcessOrderIcon />
      <T k="Listings.Button.ListNow" />
    </PrimaryBtn>
  );

  const NoButtons: React.ReactNode[] = [];
  const ProcessingButtons = NoButtons;
  const ScheduledButtons: React.ReactNode[] = [PublishNowButton, PauseButton];
  const ErrorButtons = [EditButton, DeleteButton, RetryButton];
  const ReviewButtons: React.ReactNode[] = [EditButton, DeleteButton, PublishNowButton];

  const Buttons = () => {
    const statusS = listing?.status ?? status;
    if (statusS == null) return NoButtons;

    switch (GetSimplifiedPendingStatus(statusS, listing?.dontListUntil ?? dontListUntil)) {
      default:
        return NoButtons;
      case SimplifiedPendingStatus.Error:
        return ErrorButtons;
      case SimplifiedPendingStatus.Review:
        return ReviewButtons;
      case SimplifiedPendingStatus.Scheduled:
        return ScheduledButtons;
      case SimplifiedPendingStatus.Listing:
        return ProcessingButtons;
    }
  };
  const Footer = () => {
    const bs = Buttons();
    const bLines: React.ReactNode[][] = [];
    let line = 0;
    for (let i = 0; i < bs.length; i++) {
      if (i % 2 == 0) {
        bLines.push([]);
        if (i > 0) line++;
      }
      bLines[line].push(bs[i]);
    }
    return (
      <>
        <div className="editing-pending-footer">{bLines}</div>
      </>
    );
  };

  const RenderLoadingModal = () => {
    if (saving == null) return <></>;

    const HandleSavingClose = () => {
      if (saving != null && !saving.loading && saving.success == false) {
        //Error
        location.reload();
        return;
      }

      SetListingNow(false);
      SetRemovingListing(false);
      SetPausingListing(false);
      onClose();
    };

    const title = (() => {
      if (listingNow) return <T k="Listings.Pending.ListingNow" />;
      else if (removingListing) return <T k="Listings.Pending.RemovingListing" />;
      else return <T k="Listings.Pending.PausingListing" />;
    })();
    const result = (() => {
      if (!saving.success) return <T k="Setting.ErrorSavingUnknownError" />;
      else if (listingNow) return <T k="Listings.Pending.ListedNow" />;
      else if (removingListing) return <T k="Listings.Pending.RemovedListing" />;
      else return <T k="Listings.Pending.PausedListing" />;
    })();

    return (
      <LoadingModal
        visible={listingNow || removingListing || pausingListing}
        loading={saving.loading}
        title={title}
        onClose={HandleSavingClose}
        result={result}
      />
    );
  };

  const RenderEditListingModal = () => {
    if (!channel || (!listing && !channelListingId)) return <Spin />;

    const d = loadEditPending?.[listing?.id ?? channelListingId ?? 0];
    if (!d || d.loading || !d.data) return <Spin />;

    return (
      <Modal className="editing-pending-modal" onCancel={() => SetEditing(false)} open={editing} footer={null}>
        <NewListingForm selectedChannel={channel} data={d.data} onSave={HandleOnSave} />
      </Modal>
    );
  };

  return (
    <>
      <PopupModal
        showCloseX={false}
        open={visible}
        footer={Footer()}
        onClose={onClose}
        title={ListingsUtils.StatusToUI(
          listing?.status ?? status ?? eChannelListingStatus.ListingInStore,
          channel?.channelId ?? channelListingId,
          listing?.dontListUntil ?? dontListUntil
        )}
      >
        {Body()}
      </PopupModal>
      {RenderLoadingModal()}
      {editing && RenderEditListingModal()}
    </>
  );
};
