import { DateRangeInput } from '../../../form/date-range-input';
import { AdvancedFilterValueType } from '../../types/columns';
import { Range } from 'react-date-range';
import { TimerPrecision } from '../../../form/time-picker';

type Props = {
  onChange: (value: AdvancedFilterValueType) => void;
}

export const DateField = (props: Props) => {
  const { onChange } = props;

  const HandleChange = (value: Range | null) => {
    onChange(value != null ? [value.startDate, value.endDate] : null);
  };

  return <DateRangeInput onChange={HandleChange} className="blue-input" timePicker={TimerPrecision.Seconds} clearable />;
};