import { Form, InputNumber, Spin } from 'antd';
import { Selector, SelectorValue } from 'src/small-components/form/selector';
import { SimpleTable } from 'src/small-components/tables/simple-table';
import { PrimaryBtn } from 'src/small-components/ActionBtns';
import { useEffect, useState } from 'react';
import { getPriceWarrior, UpdateSettings } from '../../redux/price-warrior/PriceWarriorThunk';
import { PWListing } from '../../redux/price-warrior/priceWarriorSlice';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import '../../sass/services/price-warrior-configuration.scss';
import '../../sass/services/single-service.scss';
import { A } from '../../small-components/A';


const { Item } = Form;

export const PriceWarrior = () => {
  const dispatch = useAppDispatch();

  const { priceWarrior, loading } = useAppSelector((state) => state.priceWarrior);

  useEffect(() => {
    dispatch(getPriceWarrior());
  }, [getPriceWarrior]);

  const [newMarkup, SetNewMarkup] = useState<number | null>(null);
  const [newUndercutBy, SetNewUndercutBy] = useState<number | null>(null);
  const [newThreshold, SetNewThreshold] = useState<number | null>(null);
  const [newRepricing, SetNewRepricing] = useState<boolean | null>(null);

  const OnSave = async () => {
    await dispatch(
      UpdateSettings({
        active: true,
        markup: newMarkup ?? priceWarrior?.settings?.markup ?? 0,
        undercutBy: newUndercutBy ?? priceWarrior?.settings?.undercutBy ?? 0,
        threshold: newThreshold ?? priceWarrior?.settings?.threshold ?? 0,
        repricing: newRepricing ?? priceWarrior?.settings?.repricing ?? true
      })
    );
    dispatch(getPriceWarrior());
  };

  const noPWService = (
    <div className="price-warrior-container">
      <div className="no-price-warrior">
        <h4>Ops! Currently you don&apos;t have any price warrior service to configure.</h4>
        <A to={'/listingservice?selectedCurrency=3&selectedProduct=26&selectedBillingPeriod=0'}>
          <PrimaryBtn>Contract this service</PrimaryBtn>
        </A>
      </div>
    </div>
  );
  const columns = [
    {
      title: 'Listing',
      dataIndex: 'title',
      key: 'id',
      render: (_: string, record: PWListing) => {
        return (
          <p className=" ">
            <strong>{record.ebayItemId}</strong> - {record.title}
          </p>
        );
      }
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (_: number, record: PWListing) => {
        return (
          <p className=" ">
            <A to={record.ebayUrl} rel="noreferrer" target="_blank">
              {record.price}
            </A>
          </p>
        );
      }
    },
    {
      title: 'Item Copied',
      dataIndex: 'competitorPrice',
      key: 'competitorPrice',
      render: (_: number, record: PWListing) => {
        return (
          <p className=" ">
            <A to={record.competitorItemUrl} rel="noreferrer" target="_blank">
              {record.competitorPrice == 0 ? '' : record.competitorPrice.toString()}
            </A>
          </p>
        );
      }
    },
    {
      title: 'Source Price',
      dataIndex: 'sourcePrice',
      key: 'sourcePrice'
    },
    {
      title: 'Status',
      dataIndex: 'lost',
      key: 'lost',
      render: (_: boolean, record: PWListing) => {
        return (
          <p className=" ">
            {record.lost ? (
              <img src="~/images/lost.png" width="24" height="24" title="We can't beat that price!" />
            ) : (
              <img src="~/images/won.png" width="24" height="24" title="Your price is better than your competitor's" />
            )}
          </p>
        );
      }
    },
    {
      title: 'Sold',
      dataIndex: 'sold',
      key: 'sold'
    },
    {
      title: 'Checked',
      dataIndex: 'updated',
      key: 'updated',
      render: (_: string, record: PWListing) => {
        return <p className=" ">{record.updated?.toLocaleString()}</p>;
      }
    },
    {
      title: 'Price Updated',
      dataIndex: 'priceUpdated',
      key: 'priceUpdated',
      render: (_: string, record: PWListing) => {
        return <p className=" ">{record.priceLastUpdated?.toLocaleString()}</p>;
      }
    }
  ];

  const genericOptions = [
    { value: 'true', label: 'Enabled' },
    { value: 'false', label: 'Disabled' }
  ];

  const repricing = newRepricing ?? priceWarrior?.settings?.repricing ?? true;
  const markup = newMarkup ?? priceWarrior?.settings?.markup ?? 0;
  const undercutBy = newUndercutBy ?? priceWarrior?.settings?.undercutBy ?? 0;
  const threshold = newThreshold ?? priceWarrior?.settings?.threshold ?? 0;

  const OnRepricingChange = (value: SelectorValue) => SetNewRepricing(value == 'true');
  const OnMarkupChange = (value: SelectorValue) => SetNewMarkup(parseInt(value?.toString() ?? '0'));
  const OnUndercutByChange = (value: SelectorValue) => SetNewUndercutBy(parseFloat(value?.toString() ?? '0'));
  const OnThresholdChange = (value: SelectorValue) => SetNewThreshold(parseInt(value?.toString() ?? '0'));

  return loading ? (
    <Spin />
  ) : priceWarrior ? (
    <div className="price-warrior-container">
      <h2>Price Warrior configuration</h2>
      <div className="options-container">
        <Form className="options-form" layout={'vertical'}>
          <Item className="form-item" label="Repricing">
            <p>If enabled, it will automatically update the price to beat the competitor.</p>
            <Selector placeholder="Enabled" value={repricing ? 'true' : 'false'} onChange={OnRepricingChange}>
              {genericOptions}
            </Selector>
          </Item>
          <Item className="form-item" label="Minimum Markup (%)">
            <p>Minimum % to apply to the supplier{"'"}s price. Price Warrior will not sell below this value.</p>
            <InputNumber placeholder="Min" value={markup} onChange={OnMarkupChange} className="blue-input" />
          </Item>
          <Item className="form-item" label="Undercut by">
            <p>How much Price Warrior can undercut your competitors every time they beat your price.</p>
            <InputNumber placeholder="Min" defaultValue={undercutBy} onChange={OnUndercutByChange} className="blue-input" />
          </Item>
          <Item className="form-item" label="Threshold">
            <p>Number of changes allowed to consider both titles the same. Min: 0 (Exact Title), Max: 20.</p>
            <InputNumber placeholder="Min" min={5} max={20} defaultValue={threshold} onChange={OnThresholdChange} className="blue-input" />
          </Item>
        </Form>
        <div onClick={OnSave}>
          <PrimaryBtn>Save configuration</PrimaryBtn>
        </div>
      </div>
      <div className="table-container">
        <SimpleTable columns={columns} dataSource={priceWarrior.listings} />
      </div>
    </div>
  ) : (
    noPWService
  );
};
