import { ReactNode, useState } from 'react';
import { Tabs } from 'antd';
import { T } from '../../../utils/transShim';
import '../../../sass/account-settings.scss';
import '../../../sass/settings/settings.scss';
import { AccountSettingSections, AccountSettingSection } from '../configuration/sections';
import { ReactUtils } from '../../../utils/react-utils';
import { Payment } from './payment';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Links } from '../../../links';
import { Billing } from './billing';
import { Withdrawal } from './withdrawal';

export const AccountConfiguration = () => {
  const selectedChannel = ReactUtils.GetSelectedChannel();

  const tab = () => {
    const isBA = useRouteMatch(Links.Configuration.Account.BillingAddress);
    const isPM = useRouteMatch(Links.Configuration.Account.PaymentMethod);
    const isWm = useRouteMatch(Links.Configuration.Account.WithdrawalMethod);
    if (isBA)
      return AccountSettingSection.BillingAddress;
    if (isPM)
      return AccountSettingSection.PaymentMethod;
    if (isWm)
      return AccountSettingSection.WithdrawalMethod;
    return AccountSettingSection.BillingAddress;
  };

  const [activeTab, SetActiveTab] = useState<AccountSettingSection>(tab());

  const sections = AccountSettingSections.filter(
    (x) => !x.ChannelIds || x.ChannelIds.includes(selectedChannel?.channelId ?? 0)
  );

  const RenderSettings = (section: AccountSettingSection): ReactNode => {
    return <>
      <Billing display={section == AccountSettingSection.BillingAddress} />
      <Payment display={section == AccountSettingSection.PaymentMethod} />
      <Withdrawal display={section == AccountSettingSection.WithdrawalMethod} />
    </>;
    
  };

  const RenderContent = (index: AccountSettingSection): ReactNode => RenderSettings(index);

  const history = useHistory();

  const HandleChangeTab = (vs: string): void => {
    const v = parseInt(vs);
    SetActiveTab(v);
    switch (v) {
      default:
      case AccountSettingSection.BillingAddress:
        history.push(Links.Configuration.Account.BillingAddress);
        break;
      case AccountSettingSection.PaymentMethod:
        history.push(Links.Configuration.Account.PaymentMethod);
        break;
      case AccountSettingSection.WithdrawalMethod:
        history.push(Links.Configuration.Account.WithdrawalMethod);
        break;
    }
  };

  return (
    <div className="account-settings">
      <Tabs
        activeKey={activeTab.toString()}
        onChange={HandleChangeTab}
        items={
          sections.map((x) => (
            {
              label: <T k={x.Label} />,
              key: x.Type.toString()
            }
          ))
        }
      />
      <div className="content">{RenderContent(activeTab)}</div>
    </div>
  );
};
