/* eslint-disable no-extra-boolean-cast */
import { Spin, Tabs } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { getAutoOrderFees } from 'src/redux/auto-ordering/autoOrderingThunk';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import '../../sass/ao-commissions.scss';
import { ReactUtils } from '../../utils/react-utils';
import { SimpleTable } from 'src/small-components/tables/simple-table';
import { AutoOrderingFee } from '../../redux/auto-ordering/autoOrderingSlice';
import { eCountry } from '../../data/countries';
import { FeeTab } from './auto-order-commissions/fee-tabs';
import { T } from '../../utils/transShim';
import { FeesByMonth, FeesTable } from './auto-order-commissions/fees-table';
import { eCurrency } from '../../types/eCurrency';

type FeeChannel = {
  channelOAuthId: number;
  isoCountry: number;
  name: string;
  removed: boolean;
  feesByMonth: FeesByMonth[];
  key: number;
};

export const AOCommissions = () => {
  const dispatch = useAppDispatch();
  const { AOFees, loading } = useAppSelector((state) => state.AOFees);

  useEffect(() => {
    dispatch(getAutoOrderFees());
  }, [getAutoOrderFees]);

  const [feesData, SetFeesData] = useState<FeesByMonth[] | undefined>();
  const [ccurrency, SetCurrency] = useState<eCurrency | undefined>();
  const [tab, SetTab] = useState<FeeTab>(FeeTab.ByCurrency);

  const feesByChannel = useMemo(() => {
    if (AOFees?.feesByChannel == null)
      return null;

    const feesByChannel: FeeChannel[] = [];

    for (const fc of AOFees.feesByChannel) {
      const ds: { [date: string]: AutoOrderingFee[] } = {};
      for (const f of fc.fees ?? []) {
        const d = new Date(f.date ?? '');
        const year = d.getUTCFullYear();
        const month = d.getUTCMonth();
        const kdate = year + '_' + month;
        ds[kdate] = ds[kdate] ?? [];
        ds[kdate].push(f);
      }

      const feesByMonth: FeesByMonth[] = [];
      for (const dk in ds) {
        const dp = dk.split('_');
        feesByMonth.push({
          fees: ds[dk],
          month: parseInt(dp[1]),
          year: parseInt(dp[0]),
          total: ds[dk].map(x => x.fee).reduce((partialSum, a) => (partialSum ?? 0) + (a ?? 0), 0) as number
        });
      }

      feesByChannel.push({
        channelOAuthId: fc.channelOAuthId,
        isoCountry: fc.isoCountry,
        name: fc.name,
        removed: fc.removed,
        feesByMonth: feesByMonth,
        key: fc.channelOAuthId
      });
    }

    return feesByChannel;
  }, [AOFees?.feesByChannel]);

  const feesByCurrency = useMemo(() => {
    if (AOFees?.feesByChannel == null)
      return null;

    const feesByCurrency: {
      currency: eCurrency;
      feesByMonth: FeesByMonth[];
    }[] = [];

    const ds: { [currency: string]: { [date: string]: AutoOrderingFee[] } } = {};
    for (const fs of AOFees.feesByChannel) {
      const currency = eCurrency[ReactUtils.GetCurrencyByCountry(fs.isoCountry)];
      for (const f of fs.fees ?? []) {
        const d = new Date(f.date ?? '');
        const year = d.getUTCFullYear();
        const month = d.getUTCMonth();
        const kdate = year + '_' + month;
        ds[currency] = ds[currency] ?? {};
        ds[currency][kdate] = ds[currency][kdate] ?? [];
        ds[currency][kdate].push(f);
      }
    }
    for (const k in ds) {
      const fees: FeesByMonth[] = [];
      for (const dk in ds[k]) {
        const dp = dk.split('_');
        const fs = ds[k][dk];
        fees.push({
          fees: fs,
          month: parseInt(dp[1]),
          year: parseInt(dp[0]),
          total: fs.map(x => x.fee).reduce((partialSum, a) => (partialSum ?? 0) + (a ?? 0), 0) as number
        });
      }

      feesByCurrency.push({
        currency: eCurrency[k as keyof typeof eCurrency],
        feesByMonth: fees
      });
    }
    return feesByCurrency;
  }, [AOFees?.feesByChannel]);

  const ChannelColumns = [
    {
      title: 'Channel',
      dataIndex: 'name'
    },
    {
      title: 'Country',
      dataIndex: 'isoCountry',
      render: (isoCountry: number) => {
        return eCountry[isoCountry];
      }
    },
    {
      title: 'Currency',
      dataIndex: 'isoCountry',
      render: (isoCountry: number) => {
        return eCurrency[ReactUtils.GetCurrencyByCountry(isoCountry)];
      }
    }
  ];

  const HandleChangeTab = (id: string) => {
    SetTab(parseInt(id));
    SetFeesData(undefined);
    SetCurrency(undefined);
  };

  if (loading)
    return <div className="ao-commission-container"><Spin /></div>;

  const TabL = () => {
    return <Tabs
      activeKey={tab.toString()}
      onChange={HandleChangeTab}
      items={[
        {
          label: <T k='AutoOrderingCommisions.Tab.ByCurrency' />,
          key: FeeTab.ByCurrency.toString()
        },
        {
          label: <T k='AutoOrderingCommisions.Tab.ByChannel' />,
          key: FeeTab.ByChannel.toString()
        }
      ]}
    />;
  };

  const ByChannel = () => {

    const OnChannelRow = (record: FeeChannel) => ({
      onClick: () => {
        SetFeesData(record.feesByMonth);
        SetCurrency(ReactUtils.GetCurrencyByCountry(record.isoCountry));
      }
    });

    return <>
      <h4><T k='AutoOrderingCommisions.ByChannel.Title' /></h4>
      <SimpleTable columns={ChannelColumns} onRow={OnChannelRow} dataSource={feesByChannel ?? []}></SimpleTable>
    </>;
  };

  const currency = (ccurrency ?? feesByCurrency?.[0]?.currency ?? eCurrency.GBP);
  if (!loading && feesByCurrency && !feesData && tab == FeeTab.ByCurrency) {
    SetFeesData(feesByCurrency?.find(x => x.currency == currency)?.feesByMonth ?? []);
  }

  const ByCurrency = () => {

    const HandleSetCurrency = (ids: string) => {
      const id = parseInt(ids) as eCurrency;
      SetCurrency(id);
      SetFeesData(feesByCurrency?.find(x => x.currency == id)?.feesByMonth ?? []);
    };

    const ts = (feesByCurrency ?? []).map(ff => ({
      label: eCurrency[ff.currency],
      key: ff.currency.toString()
    }));

    return <>
      <Tabs
        activeKey={currency.toString()}
        onChange={HandleSetCurrency}
        items={ts}
      />
    </>;
  };

  const Content = () => {
    if (loading)
      return <Spin />;

    return <>
      {TabL()}
      {tab == FeeTab.ByChannel && ByChannel()}
      {tab == FeeTab.ByCurrency && ByCurrency()}
      <FeesTable fees={feesData} currency={currency} />
    </>;
  };

  return <div className="ao-commission-container">
    {Content()}
  </div>;
};