import { LeftOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';

import { Links } from 'src/links';
import { A } from '../../small-components/A';
import { SourceSettings } from './configuration/source-settings';
import { SourcesTable } from './configuration/sources-table';

export const SourcesConfiguration = () => {
  const { id: selectedSource } = useParams<{ id?: string }>();
  const sourceId = parseInt(selectedSource ?? '');

  if (selectedSource != undefined && !isNaN(sourceId)) {
    return (
      <div className="sources-settings-container">
        <A to={Links.Configuration.Sources.Main}>
          <span>
            <LeftOutlined style={{ fontSize: '19px' }} />
          </span>
          Back to sources
        </A>
        <SourceSettings sourceId={sourceId} />
      </div>
    );
  }
  return <SourcesTable />;
};
