import '../../../sass/subscriptions/cancellation-modal.scss';
import { PopupModal } from 'src/small-components/modals/popup-modal';
import { useEffect, useState } from 'react';
import { T } from '../../../utils/transShim';
import { BoldButton, PrimaryBtn, DangerBtn } from 'src/small-components/ActionBtns';
import salesGraph from '../../../assets/graphs/graph-sales.png';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Links } from '../../../links';
import { useAppDispatch, useAppSelector } from '../../../custom-hooks/reduxCustomHooks';
import { getSubscriptions } from '../../../redux/subscriptions/subsThunk';
import { eCurrency } from '../../../types/eCurrency';
import { A } from '../../../small-components/A';

export const CancellationModal = () => {
  enum eCancellationSteps {
    StepOne = 0,
    StepTwo = 1,
    StepThree = 2
  }
  const { products } = useAppSelector((state) => state.subscriptions);
  const { quota } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (products == null) dispatch(getSubscriptions());
  }, [getSubscriptions]);

  const [step, setStep] = useState(eCancellationSteps.StepOne);
  const handleNextStep = () => {
    const nextStep = Math.min(step + 1, eCancellationSteps.StepThree);
    setStep(nextStep);
  };
  const handleBackStep = () => {
    const backStep = Math.min(step - 1);
    setStep(backStep);
  };
  const PanelStepOne = () => {
    return (
      <div className="step-one-container">
        <p>
          We are sorry that you wish to cancel your subscription. If your problem is that <b>you are not having enough sales</b>, don{"'"}t
          get demotivated, it{"'"}s normal! <b>90%</b> of our users start their biggest growth 3 or 4 months after starting their store.
        </p>
        <p>
          This graph represents an average of the orders that users usually have on a monthly basis, the most important thing is to be
          consistent to reach the top.
        </p>
        <p>
          Click on
          <b>
            {" '"}Read guide{"' "}
          </b>
          button to find out why 90% of online sellers fail on their way to success.
        </p>
        <img className="image-sales" src={salesGraph}></img>
      </div>
    );
  };

  const PanelStepTwo = () => {
    return (
      <div className="step-two-container">
        <p>
          <strong>Maybe we can do something for you?</strong>
        </p>
        <ul>
          <li>
            <strong>Transfer old listings</strong> from your one of your accounts to a brand new eBay account, even if it was banned!
          </li>
          <li>
            <strong>Migrate your account to NO API</strong> to avoid bans, in just a matter of minutes.
          </li>
          <li>
            Help you <strong>listing new products</strong> until you reach your subscription limit, with fully optimised titles.
          </li>
          <li>
            Copy your listings to <strong>your own website</strong> (
            <A rel="noreferrer" to="https://www.shopify.com/free-trial?ref=hustle-got-real-ltd" target="_blank">
              using Shopify
            </A>{' '}
            or Wordpress )
          </li>
          <li>
            Do you need anything else?{' '}
            <A rel="noreferrer" target={'_blank'} to="https://hustlegotreal.com/en/contact-us/">
              {' '}
              Just let us know!{' '}
            </A>{' '}
            We are sure that we can help you!
          </li>
        </ul>
      </div>
    );
  };

  const history = useHistory();
  const GetOfferHandleClick = () => {
    localStorage.setItem('billing', '1');
    const pid: number | undefined = products
      ?.filter((x) => x.productOrder >= (quota?.quota ?? 9999999999))
      .sort((x) => x.productOrder)?.[0]?.id;
    if (pid == undefined) localStorage.removeItem('productId');
    else localStorage.setItem('productId', pid.toString());
    localStorage.setItem('currencyId', eCurrency[(quota?.currency ?? 'GBP') as unknown as number].toString());

    history.push(Links.Checkout.Main);
  };

  const PanelStepThree = () => {
    return (
      <div className="step-three-container">
        <h2>We have a great offer for you!</h2>
        <p>
          If you stay with us, you will be able to access an exclusive offer of <b>20% discount</b> on your subscription for 6 months!
        </p>
        <div className="button-offer">
          <PrimaryBtn className="renewal-button" onClick={GetOfferHandleClick}>
            GET EXCLUSIVE OFFER
          </PrimaryBtn>
        </div>
      </div>
    );
  };

  const [open, setOpen] = useState<boolean>(false);
  const handleOpenModal = () => setOpen(!open);

  const RenderPanel = () => {
    switch (step) {
      default:
      case eCancellationSteps.StepOne:
        return PanelStepOne();
      case eCancellationSteps.StepTwo:
        return PanelStepTwo();
      case eCancellationSteps.StepThree:
        return PanelStepThree();
    }
  };

  const RenderButtons = () => {
    switch (step) {
      default:
      case eCancellationSteps.StepOne:
        return (
          <div className="buttons-footer">
            <DangerBtn onClick={handleNextStep}>Not interested</DangerBtn>
            <A rel="noreferrer" target={'_blank'} to="https://hustlegotreal.com/en/why-do-90-of-online-sellers-fail/">
              <PrimaryBtn>Read guide</PrimaryBtn>
            </A>
          </div>
        );
      case eCancellationSteps.StepTwo:
        return (
          <div className="buttons-footer">
            <DangerBtn onClick={handleNextStep}>No, thank you</DangerBtn>
            <A rel="noreferrer" target={'_blank'} to="https://hustlegotreal.com/en/contact-us/">
              <PrimaryBtn>Contact us</PrimaryBtn>
            </A>
          </div>
        );
      case eCancellationSteps.StepThree:
        return (
          <div className="buttons-footer">
            <A
              rel="noreferrer"
              target={'_blank'}
              to="https://docs.google.com/forms/d/e/1FAIpQLSemmgSbu-9Ic3eax6d4R5SeArQHjH3op48Ktth5FUUoDmJr_A/viewform"
            >
              <DangerBtn onClick={handleNextStep}>Cancell my subscription</DangerBtn>
            </A>
          </div>
        );
    }
  };

  return (
    <>
      <BoldButton onClick={handleOpenModal}>
        <T k="Subscription.RequestCancellation" />
      </BoldButton>
      <div className="cancellation-modal">
        <PopupModal
          title={<h3>Before you go...</h3>}
          open={open}
          maxWidth={800}
          footer={RenderButtons()}
          showCloseX={true}
          style={{ top: 20 }}
          onClose={handleOpenModal}
        >
          {step != eCancellationSteps.StepOne && (
            <A>
              <ArrowLeftOutlined onClick={handleBackStep} />
            </A>
          )}
          <div className="cancellation-modal-body">{RenderPanel()}</div>
        </PopupModal>
      </div>
    </>
  );
};
