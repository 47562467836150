import { Product } from '../../../../redux/subscriptions/subsSlice';
import { eCurrency } from '../../../../types/eCurrency';
import { BillingPeriod, PaymentPlatform } from '../../models/types';

export type Cart = {
  platform: PaymentPlatform;
  currency: eCurrency;
  billingPeriodId: BillingPeriod;
  lines: Product[];
};

export const SelectedPrice = (p: Product, c: Cart) => {
  return p.prices.find(x => x.billingPeriodId == c.billingPeriodId && x.currencyId == c.currency && c.platform == x.platformId) ?? p.prices.find(x => x.billingPeriodId == BillingPeriod.Unique && x.currencyId == c.currency && c.platform == x.platformId) ?? p.prices[0];
};