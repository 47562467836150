import { Form, Drawer } from 'antd';
import { useState } from 'react';
import { isNullOrUndefined } from 'util';
import '../../../sass/advanced-search.scss';
import { T } from '../../../utils/transShim';
import { AdvancedFilterValueArrayContent, AdvancedFilterValueType, ColumnData, Size } from '../types/columns';
import { AdvancedSearchField } from './advanced-search-fields/advanced-search-field';
export type AdvancedFilterData = {
  columnId: number;
  value: AdvancedFilterValueType;
}

interface Props<RecordType> {
  visible: boolean;
  onClose: () => void;
  closable: boolean;
  columns: ColumnData<RecordType>[];
  onChangeFilter: (f: AdvancedFilterData[]) => void;
}

//eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
export const AdvancedSearch = <RecordType extends object = any>(props: Props<RecordType>) => {
  const { visible, onClose, closable, columns, onChangeFilter } = props;

  const [currentFilter, setCurrentFilter] = useState<AdvancedFilterData[]>([]);

  const RenderField = (column: ColumnData<RecordType>) => {
    const { advancedSearch, dataIndex } = column;
    const title = <T k={column.title?.toString() ?? ''} />;
    const name = dataIndex?.toString() ?? '';

    const HandleChange = (newValue: AdvancedFilterValueType) => {
      const newFilter = [...currentFilter];

      const Remove = () => {
        for (let i = 0; i < newFilter.length; i++) {
          const c = newFilter[i];
          if (c.columnId == column.id) {
            newFilter.splice(i, 1);
            return;
          }
        }
        return;
      };

      const Add = (newValue: AdvancedFilterValueArrayContent[]) => {
        for (let i = 0; i < newFilter.length; i++) {
          const c = newFilter[i];
          if (c.columnId == column.id) {
            newFilter[i] = {
              ...c, value: newValue
            };
            return;
          }
        }
        newFilter.push({
          columnId: column.id,
          value: newValue
        });
      };

      if (isNullOrUndefined(newValue))
        Remove();
      else
        Add(newValue);

      setCurrentFilter(newFilter);
      onChangeFilter(newFilter);
    };

    const HandleDateChange = (newValue: AdvancedFilterValueType) => {
      const newFilter = [...currentFilter];

      const Remove = () => {
        for (let i = 0; i < newFilter.length; i++) {
          const c = newFilter[i];
          if (c.columnId == column.id) {
            newFilter.splice(i, 1);
            return;
          }
        }
        return;
      };

      const Add = (newValue: AdvancedFilterValueArrayContent[]) => {
        for (let i = 0; i < newFilter.length; i++) {
          const c = newFilter[i];
          if (c.columnId == column.id) {
            newFilter[i] = {
              ...c, value: newValue
            };
            return;
          }
        }
        newFilter.push({
          columnId: column.id,
          value: newValue
        });
      };

      if (newValue)
        Add(newValue);
      else
        Remove();

      setCurrentFilter(newFilter);
      onChangeFilter(newFilter);
    };

    return (
      <Form.Item label={title} htmlFor={name} key={name} className={(advancedSearch?.size == Size.Double ? 'double' : '')}>
        {<AdvancedSearchField name={name} onChange={HandleChange} type={advancedSearch?.type} onDateChange={HandleDateChange} values={advancedSearch?.values} />}
      </Form.Item>
    );
  };

  return (
    <Drawer
      className="advanced-search"
      title="Advanced Search"
      open={visible}
      placement="right"
      onClose={onClose}
      closable={closable}
    >
      <div className="advanced-form-container">
        <Form layout="vertical" className="advanced-search-form">
          <div className="search-inputs">
            {columns.filter(c => !c.advancedSearch?.ignore).map(c => RenderField(c))}
          </div>
        </Form>
      </div>
    </Drawer>
  );
};
