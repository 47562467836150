import { ePlatform } from '../../../data/platforms';
import { eApiType } from '../../../redux/new-channel/newChannelThunk';

export enum eShop {
  eBay = 1,
  Shopify = 2,
  Amazon = 3,
  Facebook = 4,
  WooComerce = 5,
  TikTok = 6
}

export const ShopToPlatform = (shop: eShop | null, api: eApiType | null) => {
  switch (shop) {
    default:
    case eShop.eBay:
      if (api == eApiType.NO_API || api == null)
        return ePlatform.eBayNoApi;
      return ePlatform.eBay;
    case eShop.Shopify:
      return ePlatform.Shopify;
    case eShop.Amazon:
      return ePlatform.Amazon;
    case eShop.Facebook:
      return ePlatform.Facebook;
    case eShop.WooComerce:
      return ePlatform.WooCommerce;
    case eShop.TikTok:
      return ePlatform.TikTok;
  }
};

export const PlatformToShop = (shop: ePlatform | null) => {
  switch (shop) {
    default:
    case ePlatform.eBay:
      return eShop.eBay;
    case ePlatform.Shopify:
      return eShop.Shopify;
    case ePlatform.Amazon:
      return eShop.Amazon;
    case ePlatform.Facebook:
      return eShop.Facebook;
    case ePlatform.WooCommerce:
      return eShop.WooComerce;
    case ePlatform.TikTok:
      return eShop.TikTok;
  }
};