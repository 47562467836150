import { createSlice } from '@reduxjs/toolkit';
import { BillingPeriod, PaymentPlatform } from '../../components/subscriptions/models/types';
import { eCurrency } from '../../types/eCurrency';
import { GetPaymentConfig, getServices, getSubscriptions } from './subsThunk';

export type Subscription = {
  id: number;
  platformId: PaymentPlatform;
  billingPeriodId: BillingPeriod;
  currencyId: eCurrency;
  price: number;
  platformProductId: string;
  productId: number;
}

export type Product = {
  id: number;
  name: string;
  prices: Subscription[];
  productOrder: number;
  type: number;
}






export type SubscriptionsState = {
  products: Product[];
  loading: boolean;
  error: string;
}
const initialState: SubscriptionsState = {
  products: [],
  loading: false,
  error: ''
};

export const subscriptionsSlice = createSlice({
  name: 'products',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSubscriptions.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getSubscriptions.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.products = payload;
    });
    builder.addCase(getSubscriptions.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = String(payload);
    });
  }
});





export interface ServiceState {
  services: Product[];
  loading: boolean;
  error: string;
}

const initialsState: ServiceState = {
  services: [] as Product[],
  loading: false,
  error: ''
};

export const servicesSlice = createSlice({
  name: 'services',
  initialState: initialsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getServices.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getServices.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.services = payload;
    });
    builder.addCase(getServices.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = String(payload);
    });
  }
});





export interface PayPalConfig {
  userId: string;
}

export interface StripeConfig {
  publishableKey: string;
}

export interface SubscriptionConfiguration {
  payPalConfig: PayPalConfig;
  stripeConfig: StripeConfig;
  usedQuota: number;
  limit: number;
  totalQuota: number;
  currentSubscriptionProduct: string;
  upgrade: boolean;
  successUrl: string;
  cancelUrl: string;
}
export interface PaymentConfigurationState {
  loading: boolean;
  paymentConfiguration: SubscriptionConfiguration | null;
  error: string;
}
const initialStateConfig: PaymentConfigurationState = {
  loading: false,
  paymentConfiguration: null,
  error: ''
};

export const getConfigSlice = createSlice({
  name: 'subscriptionConfiguration',
  initialState: initialStateConfig,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetPaymentConfig.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(GetPaymentConfig.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.paymentConfiguration = payload;
    });
    builder.addCase(GetPaymentConfig.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = String(payload);
    });
  }
});

export const subscriptionsReducer = subscriptionsSlice.reducer;
export const servicesReducer = servicesSlice.reducer;
export const paymentMethodConfiguration = getConfigSlice.reducer;
