import React, { useEffect, useMemo, useState } from 'react';
import { Divider, Tabs, Tooltip } from 'antd';
import { T } from '../../utils/transShim';
import { useAppSelector, useAppDispatch } from '../../custom-hooks/reduxCustomHooks';
import {
  DefaultSettingsMultiple,
  GetActiveListings,
  GetActiveListingsImages,
  GetPendingListings,
  GetTerminatedListings,
  ListPendingNow,
  PauseToReview,
  Relist,
  RemovePendingListing
} from 'src/redux/listings/listings-thunk';
import {
  ActiveListing,
  ActiveListingsImagesDictionary,
  eChannelListingStatus,
  PendingListing,
  ProductsData,
  TerminatedListing
} from 'src/redux/listings/listings-slice';
import '../../sass/listings/listings.scss';
import '../../sass/tables/complex-table.scss';
import { ReactUtils } from '../../utils/react-utils';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Links } from '../../links';
import { ActiveListingsColumns, ActiveListingsColumnsVisibleByDefault } from './Listings/active-columns';
import { GenerateListingsColumns, ListingColumnId } from './Listings/columns';
import { PendingListingsColumns, PendingListingsColumnsVisibleByDefault } from './Listings/pending-columns';
import { TerminatedListingsColumns } from './Listings/terminated-columns';

import { ComplexTable } from '../../small-components/tables/complex-table';
import { getSources } from '../../redux/sources/sourcesThunk';
import { Source } from '../../redux/sources/sourceSlice';
import { ActiveListingExtended, ExtraData, ListingT } from './Listings/types';
import { GetComputedConfiguration } from '../../redux/source-configuration/sources.coonfiguration-thunk';
import { Channel } from '../../redux/channels/channelsSlice';
import { ePlatform, IsEbay } from '../../data/platforms';
import { ImportListings } from './import-listings';
import { EditActiveListings } from './Listings/components/edit/edit-active-listings';
import { OptimiseModal } from '../../small-components/modals/optimise-modal';
import {
  GetSimplifiedPendingStatus,
  SimplifiedPendingStatus,
  StatusPendingModal
} from './Listings/components/pending/status-pending-modal';
import { LoadingModal } from '../../small-components/modals/loading-modal';
import { Catalog } from '../catalog/Catalog';
import { GetMissingListings } from '../../redux/import-listings/importListingThunk';
import { ListingsUtils } from '../../utils/listings-utils';
import { getRules } from '../../redux/pricing-rules/rulesThunk';
import { AdvancedFilterData } from '../../small-components/tables/complex-table-components/advanced-search';
import { Mode } from './Listings/mode';
import { WarningOutlined } from '@ant-design/icons';
import { ActionMenuKind } from '../../small-components/tables/types/action-menu-type';
import { A } from '../../small-components/A';

enum ListingTab {
  active,
  pending,
  terminated,
  import
}
enum SavingProcess {
  none,
  savingPending,
  relisting
}
type Selection = {
  listings: ListingT[];
  keys: number[];
};

export const Listings = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  //ADDITIONAL DATA--------------------------------------------------------------------------
  const selectedChannel = ReactUtils.GetSelectedChannel();
  const channels = ReactUtils.GetAllChannels();
  const { sources, loading: loadingSources } = useAppSelector((state) => state.sources);
  const sourcesDic = sources ? new Map<number, Source>(sources.map((x) => [x.id, x])) : null;
  const { rules } = useAppSelector((state) => state.pricingRules);
  const { settings: computedConfiguration, loading: loadingComputedConfiguration } = useAppSelector(
    (state) => (state.sourcesConfiguration)?.computedConfiguration ?? {}
  );

  useEffect(() => {
    if (!!sources && sources.length > 0 && sources[0].site == selectedChannel?.isoCountry?.toString())
      return;
    if (loadingSources)
      return;
    dispatch(getSources());
  }, [selectedChannel?.id]);
  useEffect(() => {
    dispatch(GetComputedConfiguration());
  }, [GetComputedConfiguration, selectedChannel?.id]);
  useEffect(() => {
    dispatch(getRules());
  }, [getRules, selectedChannel?.id]);
  //-----------------------------------------------------------------------------------------
  //TAB & Mode-------------------------------------------------------------------------------
  const [tab, mode] = (() => {
    //this routeMatch has to be done always or react will cry
    //stupid design I know it, but... react
    const isPp = useRouteMatch(Links.Products.Pending);
    const ispT = useRouteMatch(Links.Products.Terminated);
    const isIm = useRouteMatch(Links.Products.Missing);
    const isIO = useRouteMatch(Links.Products.Optimize);
    if (isPp) return [ListingTab.pending, Mode.Normal];
    if (ispT) return [ListingTab.terminated, Mode.Normal];
    if (isIm) return [ListingTab.import, Mode.Normal];
    if (isIO) {
      return [ListingTab.active, Mode.Optimize];
    }
    return [ListingTab.active, Mode.Normal];
  })();
  //------------------------------------------------------------------------------------------
  const IsOptimizedFiltered = (filters: AdvancedFilterData[]) => {
    return filters.find(x => x.columnId == ListingColumnId.Optimized && x.value?.find(x => x == 0) != null) != null;
  };
  //DATA--------------------------------------------------------------------------------------
  const {
    activeListings,
    loadingActive,
    terminatedListings,
    pendingListings,
    loadingPending,
    loadingTerminated,
    activeListingsImages:activeListingsImagesX
  } = useAppSelector((state) => state.listings);

  const [lastRefreshTime, SetLastRefreshTime] = useState<Date>(new Date());

  //

  useEffect(() => {
    if (selectedChannel?.id == null)
      return;

    switch (tab) {
      case ListingTab.active:
        if (!activeListings || activeListings.channelOAuthId != selectedChannel.id) {
          SetLastRefreshTime(new Date());
          dispatch(GetActiveListings());
        }
        break;
      case ListingTab.pending:
        if (!pendingListings || pendingListings.channelOAuthId != selectedChannel.id) {
          SetLastRefreshTime(new Date());
          dispatch(GetPendingListings());
        }
        break;
      case ListingTab.terminated:
        if (!terminatedListings || terminatedListings.channelOAuthId != selectedChannel.id) {
          SetLastRefreshTime(new Date());
          dispatch(GetTerminatedListings());
        }
        break;
      case ListingTab.import:
        dispatch(GetMissingListings());
        break;
    }
  }, [GetPendingListings, GetTerminatedListings, GetActiveListings, selectedChannel?.id, tab]);

  const HandleRefresh = () => {
    if (selectedChannel?.id == null)
      return;

    switch (tab) {
      case ListingTab.active:
        SetLastRefreshTime(new Date());
        dispatch(GetActiveListings());
        break;
      case ListingTab.pending:
        SetLastRefreshTime(new Date());
        dispatch(GetPendingListings());
        break;
      case ListingTab.terminated:
        SetLastRefreshTime(new Date());
        dispatch(GetTerminatedListings());
        break;
    }
  };

  const HandleChangeTab = (v: string): void => {
    switch (parseInt(v)) {
      default:
      case ListingTab.active:
        history.push(Links.Products.Active);
        break;
      case ListingTab.pending:
        history.push(Links.Products.Pending);
        break;
      case ListingTab.terminated:
        history.push(Links.Products.Terminated);
        break;
      case ListingTab.import:
        history.push(Links.Products.Missing);
        break;
    }
  };

  const { defaultVisibleColumns, hideWhenEmpty, listings, loadingListings, columnList, activeListingsImages } = (() => {
    //This first only return informatinio depending of the active tab
    const data1 = (() => {
      //const {
      //  activeListings,
      //  loadingActive,
      //  terminatedListings,
      //  pendingListings,
      //  loadingPending,
      //  loadingTerminated,
      //  activeListingsImages
      //} = useAppSelector((state) => state.listings);

      switch (tab) {
        default:
        case ListingTab.active:
          return {
            defaultVisibleColumns: ActiveListingsColumnsVisibleByDefault[selectedChannel?.channelId ?? ePlatform.eBay],
            columnList: ActiveListingsColumns[selectedChannel?.channelId ?? ePlatform.eBay],
            hideWhenEmpty: true,
            listings: activeListings as unknown as ProductsData<ListingT>,
            loadingListings: loadingActive || loadingComputedConfiguration,
            activeListingsImages: activeListingsImagesX
          };
        case ListingTab.pending:
          return {
            defaultVisibleColumns: PendingListingsColumnsVisibleByDefault,
            columnList: PendingListingsColumns,
            hideWhenEmpty: false,
            listings: pendingListings as unknown as ProductsData<ListingT>,
            loadingListings: loadingPending,
            activeListingsImages: undefined
          };
        case ListingTab.terminated:
          return {
            defaultVisibleColumns: TerminatedListingsColumns,
            columnList: TerminatedListingsColumns,
            hideWhenEmpty: false,
            listings: terminatedListings as unknown as ProductsData<ListingT>,
            loadingListings: loadingTerminated,
            activeListingsImages: undefined
          };
      }
    })();

    const listings = useMemo(() => {
      const AddExtraData = (data: ListingT[] | null | undefined) => {
        if (!data || !sourcesDic) return data;
        return data.map(
          (x) =>
            ({
              ...x,
              source: sourcesDic.get(x.sourceId),
              channel: selectedChannel,
              sourceSettings: computedConfiguration?.[x.sourceId],
              key: x.id
            } as ListingT)
        );
      };

      const ExtendActive = (listings: ListingT[] | null | undefined, activeListingsImages?: ActiveListingsImagesDictionary) => {
        if (!listings) return listings;

        const channelsDic: { [id: number]: Channel } | null = channels ? {} : null;
        if (channelsDic)
          for (const c of channels) {
            channelsDic[c.id] = c;
          }

        for (const al of listings) {
          const l = al as ActiveListingExtended; //Actually it is not, it is a ActiveListing, but... javascript. It will work without needing of creating a new object
          const settings = l.sourceSettings;
          //Recalculating fields like profit
          if (settings) {
            l.profit = l.channelPrice - l.sourcePrice - (l.channelPrice * settings.feePercentage) / 100;
            l.markup = l.overrides.markup ?? settings.markup;
            l.monitorStock = l.overrides.monitorStock ?? settings.monitorStock;
            l.monitorPrice = l.overrides.monitorPrice ?? settings.monitorPrice;
            l.monitorPriceDecrease = l.overrides.monitorPriceDecrease ?? settings.monitorPriceDecrease;
            l.monitorPriceDecreasePercentage = l.overrides.monitorPriceDecreasePercentage ?? settings.monitorPriceDecreasePercentage;
            l.ignoreRules = l.overrides.ignoreRules ?? settings.ignoreRules;
            l.variationsText = (() => {
              const GetVariationSKU = (data: ActiveListingExtended) => {
                const cis = (data as { channelItem: string }).channelItem.split('#');
                return cis.length == 2 ? cis[1] : '';
              };
              const value = l.variationAtributes;
              if (!value || value.length == 0) return GetVariationSKU(l);
              const ops = value.map((x) => x.option).join(', ');
              return ops && ops.trim().length > 0 ? ops : GetVariationSKU(l);
            })();
            l.unsoldDays = (() => {
              const d = l.lastTimeSold ?? l.createdOn;
              const ld = typeof d === 'string' ? new Date(d) : d;
              return Math.floor((new Date().getTime() - ld.getTime()) / 86400000);
            })();
            l.outOfStockDays = (() => {
              const d = l.lastTimeInStock ?? l.createdOn;
              const ld = typeof d === 'string' ? new Date(d) : d;
              return l.sourceQuantity > 0 ? 0 : Math.floor((new Date().getTime() - ld.getTime()) / 86400000);
            })();
            l.dispatchDays = l.overrides.dispatchDays ?? settings.dispatchDays;
            l.pricingRules = rules.filter(x => x.sourceId == null || x.sourceId == l.sourceId);
            l.markupPricingRule = ListingsUtils.GetMarkupFromPricingRules(l.sourcePrice, rules) ?? undefined;
            l.linksVersion = settings.linksVersion;
            if (channelsDic) {
              l.otherChannels = [];
              for (const o of l.otherChannelOAuthsIds ?? []) {
                const c = channelsDic[o];
                if (c) l.otherChannels.push(c);
              }
            }
          }

          //Images-------------------
          if (activeListingsImages) {
            const ud = activeListingsImages[l.id];
            if (ud && !ud.loading && ud.url) {
              l.imageUrl = ud.url;
            }
          }
        }

        return listings;
      };

      switch (tab) {
        default:
        case ListingTab.active:
          return ExtendActive(AddExtraData(data1.listings?.products), data1.activeListingsImages);
        case ListingTab.pending:
          return AddExtraData(data1.listings?.products);
        case ListingTab.terminated:
          return AddExtraData(data1.listings?.products);
      }
    }, [tab, data1.listings, data1.activeListingsImages, computedConfiguration, rules]) as ListingT[] | null | undefined;

    return { ...data1, listings };
  })();


  //Row Selection-------------------------------------------------------------
  const [selectedRows, SetSelectedRows] = useState<Selection>({ listings: [], keys: [] });
  const OnSelectChange = (keys: React.Key[], rows: ListingT[]) => SetSelectedRows({ listings: rows, keys: keys as number[] });

  //Filter--------------------------------------------------------------------
  const [advancedFilter, SetAdvancedFilter] = (() => {
    const [advancedFilter, SetAdvancedFilter] = useState<{ [id: number]: AdvancedFilterData[] }>([]);
    return [advancedFilter[tab] ?? [], (d: AdvancedFilterData[]) => {
      if (tab == ListingTab.active) {
        if (IsOptimizedFiltered(d) && mode != Mode.Optimize) {
          history.push(Links.Products.Optimize);
        }
      }
      SetAdvancedFilter({ ...advancedFilter, [tab]: d });
    }];
  })();
  //We modify filter in case of optimise mode
  //Also we select all of them for optimize by default
  const [prvMode, SetPrvMode] = useState<Mode>(Mode.Normal);
  useEffect(() => {
    if (!listings || listings.length == 0)
      return;
    if (prvMode != mode) {
      switch (mode) {
        case Mode.Normal:
          SetAdvancedFilter([]);
          break;
        case Mode.Optimize:
          {
            SetAdvancedFilter([{
              columnId: ListingColumnId.Optimized,
              value: [0]
            }]);
            const listingsF = listings?.filter(x => !(x as ActiveListing).optimized) ?? [];
            SetSelectedRows({ listings: listingsF, keys: listingsF.map(x => x.id) as number[] });
          }
          break;
      }
      SetPrvMode(mode);
    }
  }, [mode, listings?.length]);
  
  //-------------------------------------------------------------------------

  useEffect(() => {
    if (listings) return;
    switch (tab) {
      case ListingTab.active:
        dispatch(GetActiveListings());
        break;
      case ListingTab.pending:
        dispatch(GetPendingListings());
        break;
      case ListingTab.terminated:
        dispatch(GetTerminatedListings());
        break;
    }
  }, [tab]);
  //--------------------------------------------------------------------------
  const ListingsColuns = GenerateListingsColumns();
  const filteredColumns = useMemo(() => ListingsColuns.filter((x) => columnList.includes(x.id)), [ListingsColuns, columnList]);

  //--------------------------------------------------------------------------
  //--------------------------------------------------------------------------
  //Edit Active Listings------------------------------------------------------
  const [editingActiveListings, SetEditingActiveListings] = useState<ActiveListingExtended[] | null>(null);
  //const [copyingActiveListings, SetCopyingActiveListings] = useState<ActiveListingExtended[] | null>(null);
  const HandleClickActiveListing = (listing: ActiveListingExtended) => SetEditingActiveListings([listing]);
  const HandleCloseEditActiveListing = () => SetEditingActiveListings(null);
  //const HandleCloseCopyActiveListing = () => SetCopyingActiveListings(null);

  const [editPendingListing, SetEditPendingListing] = useState<(PendingListing & ExtraData) | null>(null);
  const HandleClickEditPendingListing = (listing: PendingListing & ExtraData) => SetEditPendingListing(listing);
  const HandleCloseEditPendingListing = () => SetEditPendingListing(null);
  useEffect(() => {
    //When updating listings, we want the modal to get the new values so we use this to update the editing listing
    if (!listings) return;
    if (editingActiveListings) {
      const newEditingListings = [...editingActiveListings];
      for (let i = 0; i < newEditingListings.length; i++) {
        const e = newEditingListings[i];
        const s = listings.find((x) => x.channelListingId == e.channelListingId);
        if (s) {
          //This should be always true
          newEditingListings[i] = s as ActiveListingExtended;
        }
      }
      SetEditingActiveListings(newEditingListings);
    }
    //if (!copyingActiveListings) return;
    //const newCopyingListings = [...copyingActiveListings];
    //for (let i = 0; i < newCopyingListings.length; i++) {
    //  const e = newCopyingListings[i];
    //  const s = listings.find((x) => x.channelListingId == e.channelListingId);
    //  if (s) {
    //    //This should be always true
    //    newCopyingListings[i] = s as ActiveListingExtended;
    //  }
    //}
    //SetCopyingActiveListings(newCopyingListings);
  }, [listings]);
  const HandleOnRow = (listing: ListingT) => {
    switch (tab) {
      case ListingTab.active:
        return {
          onClick: () => HandleClickActiveListing(listing as ActiveListingExtended)
        };
      case ListingTab.pending:
        return {
          onClick: () => HandleClickEditPendingListing(listing as PendingListing & ExtraData)
        };
      default:
        return {};
    }
  };
  //--------------------------------------------------------------------------
  //Bulk Menu-----------------------------------------------------------------
  const [savingChanges, SetSavingChanges] = useState<boolean>(false);
  const [savingProgress, SetSavingProgress] = useState<SavingProcess>(SavingProcess.none);
  const [bulkSuccess, SetBulkSuccess] = useState<number>(0);
  const [savingProcess, SetSavingProcess] = useState<number>(0);
  const [blockSaving, SetBlockSaving] = useState<boolean>(false);
  const { saving } = useAppSelector((state) => state.listings);

  const [optimising, SetOptimising] = useState<boolean>(false);
  const HandleBulkOptimise = () => {
    if (selectedRows.listings?.length > 0) SetOptimising(true);
  };
  const HandleBulkEdit = () => SetEditingActiveListings((selectedRows.listings ?? []) as ActiveListingExtended[]);
  //const HandleBulkCopy = () => SetCopyingActiveListings((selectedRows.listings ?? []) as ActiveListingExtended[]);
  const HandleBulkListNowPending = async () => {
    SetSavingChanges(true);
    SetBlockSaving(true);
    SetSavingProgress(0);

    const ls: PendingListing[] = [];
    for (const sl of selectedRows.listings) {
      const l = sl as PendingListing;
      switch (GetSimplifiedPendingStatus(l?.status, l?.dontListUntil)) {
        case SimplifiedPendingStatus.Error:
        case SimplifiedPendingStatus.Review:
        case SimplifiedPendingStatus.Scheduled:
          ls.push(l);
          break;
        default:
          break;
      }
    }

    for (let i = 0; i < ls.length; i++) {
      SetSavingProgress((100 * i) / ls.length);
      await dispatch(ListPendingNow(ls[i].channelListingId));
    }

    SetBlockSaving(false);
  };
  const HandleBulkPausePending = async () => {
    SetSavingChanges(true);
    SetBlockSaving(true);
    SetSavingProgress(0);

    const ls: PendingListing[] = [];
    for (const sl of selectedRows.listings) {
      const l = sl as PendingListing;
      switch (GetSimplifiedPendingStatus(l?.status, l?.dontListUntil)) {
        case SimplifiedPendingStatus.Scheduled:
          ls.push(l);
          break;
        default:
          break;
      }
    }
    for (let i = 0; i < ls.length; i++) {
      SetSavingProgress((100 * i) / ls.length);
      await dispatch(PauseToReview(ls[i].channelListingId));
    }

    SetBlockSaving(false);
  };
  const HandleBulkRemovePending = async () => {
    SetSavingChanges(true);
    SetBlockSaving(true);
    SetSavingProgress(0);
    const ls: PendingListing[] = [];
    for (const sl of selectedRows.listings) {
      const l = sl as PendingListing;
      switch (GetSimplifiedPendingStatus(l?.status, l?.dontListUntil)) {
        case SimplifiedPendingStatus.Review:
        case SimplifiedPendingStatus.Error:
          ls.push(l);
          break;
        default:
          break;
      }
    }
    for (let i = 0; i < ls.length; i++) {
      SetSavingProgress((100 * i) / ls.length);
      await dispatch(RemovePendingListing(ls[i].channelListingId));
    }
    SetBlockSaving(false);
  };
  const HandleDefaultPolicies = async () => {
    SetSavingChanges(true);
    SetBlockSaving(true);
    SetSavingProgress(0);

    const chunk = 10;
    const ls: number[] = [];
    for (const sl of selectedRows.listings) {
      const l = sl as PendingListing;
      switch (GetSimplifiedPendingStatus(l?.status, l?.dontListUntil)) {
        case SimplifiedPendingStatus.Review:
        case SimplifiedPendingStatus.Error:
          ls.push(l.channelListingId);
          break;
        default:
          break;
      }
    }
    for (let i = 0; i < ls.length; i += chunk) {
      SetSavingProgress((100 * i) / ls.length);
      const sListings = ls.slice(i, i + chunk);
      await dispatch(DefaultSettingsMultiple(sListings));
    }

    SetBlockSaving(false);
  };
  const HandleBulkRelist = async () => {
    SetSavingProcess(SavingProcess.relisting);
    SetSavingChanges(true);
    SetBlockSaving(true);
    SetSavingProgress(0);
    const ls: number[] = [];
    for (const sl of selectedRows.listings) {
      const l = sl as TerminatedListing;
      if ((l?.status & eChannelListingStatus.Relisted) == 0 && (l.status & eChannelListingStatus.PendingForRelist) == 0) ls.push(l.id);
    }
    let success = 0;
    for (let i = 0; i < ls.length; i++) {
      SetSavingProgress((100 * i) / ls.length);
      const id = ls[i];
      const rs = (await dispatch(Relist(id))) as { payload?: { success: boolean; error: string } };
      if (rs.payload?.success) {
        success++;
      }
    }

    SetBulkSuccess(success);
    SetBlockSaving(false);
  };
  const actionsDropdownMenu = useMemo(() => {
    const quantityRows = selectedRows?.listings?.length ?? 0;
    if (quantityRows == 0) return undefined;

    switch (tab) {
      case ListingTab.active:
        return [
          {
            key: 'edit',
            onClick: HandleBulkEdit,
            label: (
              <>
                <T k="Listings.Button.Edit" />
                &nbsp;<strong>{quantityRows}</strong>
              </>
            )
          },
          {
            key: 'optimize',
            onClick: HandleBulkOptimise,
            type: ActionMenuKind.Important,
            label: (
              <>
                <T k="Listings.Button.OptimizeDespl" />
                &nbsp;<strong>{quantityRows}</strong>
              </>
            )
          }
        ];
      case ListingTab.pending: {
        let quantityRowsListNow = 0;
        let quantityRowsPause = 0;
        let quantityRowsRemove = 0;
        let quantityDefaltPolicies = 0;
        for (const sl of selectedRows.listings) {
          const l = sl as PendingListing;
          switch (GetSimplifiedPendingStatus(l?.status, l?.dontListUntil)) {
            default:
              break;
            case SimplifiedPendingStatus.Error:
              quantityRowsListNow++;
              quantityRowsRemove++;
              quantityDefaltPolicies++;
              break;
            case SimplifiedPendingStatus.Review:
              quantityRowsListNow++;
              quantityRowsRemove++;
              quantityDefaltPolicies++;
              break;
            case SimplifiedPendingStatus.Scheduled:
              quantityRowsPause++;
              quantityRowsListNow++;
              break;
            case SimplifiedPendingStatus.Listing:
              break;
          }
        }
        const items = [
          {
            key: 'b',
            onClick: HandleBulkListNowPending,
            label: (
              <>
                <T k="Listings.Button.ListNow" />
                &nbsp;<strong>{quantityRowsListNow}</strong>
              </>
            )
          },
          {
            key: 'c',
            onClick: HandleBulkPausePending,
            label: (
              <>
                <T k="Listings.Button.Pause" />
                &nbsp;<strong>{quantityRowsPause}</strong>
              </>
            )
          },
          {
            key: 'd',
            onClick: HandleBulkRemovePending,
            label: (
              <>
                <T k="Listings.Button.Delete" />
                &nbsp;<strong>{quantityRowsRemove}</strong>
              </>
            )
          }
        ];
        if (IsEbay(selectedChannel?.channelId)) {
          items.push({
            key: 'e',
            onClick: HandleDefaultPolicies,
            label: (
              <>
                <T k="Listings.Button.DefaultPolicies" />
                &nbsp;<strong>{quantityDefaltPolicies}</strong>
              </>
            )
          });
        }
        return items;
      }
      case ListingTab.terminated: {
        if (!IsEbay(selectedChannel?.channelId)) return undefined;

        let quantityRelist = 0;
        for (const sl of selectedRows.listings) {
          const l = sl as PendingListing;
          if ((l?.status & eChannelListingStatus.Relisted) == 0 && (l.status & eChannelListingStatus.PendingForRelist) == 0)
            quantityRelist++;
        }
        return [
          {
            key: 'a',
            onClick: HandleBulkRelist,
            label: (
              <>
                <T k="Listings.Button.Relist" />
                &nbsp;<strong>{quantityRelist}</strong>
              </>
            )
          }
        ];
      }
      default:
        return undefined;
    }
  }, [tab, selectedRows?.keys?.length]);
  //--------------------------------------------------------------------------
  //Load images for active listings-------------------------------------------
  const [imagesAlreadyLoaded, setImagesAlreadyLoaded] = useState<Set<number>>(new Set());
  const onChangeVisibleRows = (rows: ListingT[]) => {
    if (tab != ListingTab.active) return;

    const imgToLoad: number[] = [];
    for (const r of rows) {
      if (imagesAlreadyLoaded.has(r.id)) continue;
      if (!r.imageUrl && !(activeListingsImages ?? {})[r.id]?.loading) {
        imgToLoad.push(r.id);
      }
    }
    if (imgToLoad.length > 0) {
      dispatch(GetActiveListingsImages(imgToLoad));
      for (const id of imgToLoad) {
        imagesAlreadyLoaded.add(id);
      }
      setImagesAlreadyLoaded(imagesAlreadyLoaded);
    }
  };

  const RenderSaveChangesModal = () => {
    if (saving == null) return <></>;

    const HandleClose = () => {
      if (saving != null && !saving.loading && savingProcess != SavingProcess.relisting && saving.success == false) {
        //Error
        location.reload();
        return;
      }

      SetSavingChanges(false);
    };

    let message: React.ReactNode = '';
    switch (savingProcess) {
      default:
        message = saving.success ? <T k="Setting.Saved" /> : <T k="Setting.ErrorSavingUnknownError" />;
        break;
      case SavingProcess.relisting:
        message = saving.success ? 'Successfully relisted ' + bulkSuccess + ' product(s).' : 'Error. Any product has been relisted.';
        break;
    }

    return (
      <LoadingModal
        visible={savingChanges}
        loading={saving.loading || blockSaving}
        title={<T k="Setting.SavingChanges" />}
        onClose={HandleClose}
        progress={savingProgress}
        result={message}
      />
    );
  };

  const selection = (() => {
    if (tab == ListingTab.terminated && !IsEbay(selectedChannel?.channelId))
      return undefined;

    return {
      selectedRowKeys: selectedRows?.keys,
      onChange: OnSelectChange
    };
  })();

  const HandleOptimizeMode = () => {
    if (tab != ListingTab.active)
      return;

    switch (mode) {
      default:
      case Mode.Normal:
        history.push(Links.Products.Optimize);
        break;
      case Mode.Optimize:
        history.push(Links.Products.Active);
        break;
    }
  };
  const CustomFilters = () => {
    if (tab != ListingTab.active)
      return undefined;

    if (!listings || listings.length == 0)
      return undefined;

    const notOptimizedQuantity = listings.filter(x => !(x as ActiveListing).optimized).length;
    const isOptimizedFiltered = IsOptimizedFiltered(advancedFilter);
    return (
      <div className="briefLine">
        {notOptimizedQuantity &&
          <Tooltip title={<T k="Listings.Button.Tooltip.Unoptimmized" values={{ n: notOptimizedQuantity }} />}>
            <>
              <div onClick={HandleOptimizeMode} className={'briefButton briefTotal' + (isOptimizedFiltered ? ' active' : '')}>
                <WarningOutlined />
                <div className='label'><T k="Listings.Button.Unoptimmized" /></div>
                <div className="briefNumber">{notOptimizedQuantity}</div>
              </div>
            </>
          </Tooltip>
        }
      </div>
    );
  };

  const RenderTable = () => {
    switch (tab) {
      case ListingTab.import:
        return <></>;
      default:
        return <>
          <ComplexTable
            uiIdentifier={'listings_' + tab}
            data={(listings ?? []) as ListingT[]}
            allColumnData={filteredColumns}
            defaultVisibleColumns={defaultVisibleColumns}
            hideWhenEmpty={hideWhenEmpty}
            loadingData={loadingListings || loadingSources}
            onChangeVisibleRows={onChangeVisibleRows}
            actionsMenu={actionsDropdownMenu}
            onRow={HandleOnRow}
            rowSelection={selection}
            forceFilter={advancedFilter}
            onChangeFilter={SetAdvancedFilter}
            refresh={{
              show: true,
              minTime: 5,
              loading: (loadingListings || loadingSources),
              autoTime: {
                time: 120,
                show: tab == ListingTab.pending
              },
              onRefresh: HandleRefresh,
              lastRefreshTime: lastRefreshTime
            }}
            preSearch={CustomFilters()}
          />
          {(tab == ListingTab.active) && listings?.length == 0 && (
            <div className="catalog-recomendation">
              <h2>It looks like you don{"'"}t have any active product!</h2>
              {selectedChannel?.channelId != ePlatform.Amazon && <>
                <p>Here are a few products we think would be a great addition to your store. Add them now and start making sales!, you can also see our <A to={Links.PublishNow}> other listing options.</A></p>
                <Divider />
                <Catalog />
              </>}
              {selectedChannel?.channelId == ePlatform.Amazon && <>
                <Divider />
                <ImportListings />
              </>}
            </div>
          )}
        </>;
    }
  };
  const RenderExtras = () => {
    switch (tab) {
      case ListingTab.import:
        return <ImportListings />;
      case ListingTab.active:
        return <>
          <EditActiveListings
            onClose={HandleCloseEditActiveListing}
            platform={selectedChannel?.channelId}
            listings={editingActiveListings}
          />
          {/*<CopyActiveListings
            onClose={HandleCloseCopyActiveListing}
            platform={selectedChannel?.channelId}
            listings={copyingActiveListings}
          />*/}
          <OptimiseModal
            key="xo"
            visible={optimising}
            listings={selectedRows?.listings?.map((x) => ({
              listingId: x.channelListingId,
              productSourceId: (x as ActiveListing).productSourceId
            }))}
            onClose={() => SetOptimising(false)}
          />
        </>;
      case ListingTab.pending:
        return <StatusPendingModal
          onClose={HandleCloseEditPendingListing}
          channel={selectedChannel}
          listing={editPendingListing ?? undefined}
          visible={!!editPendingListing}
        />;
    }
  };
  const RenderContent = () => {
    return <>
      {tab == ListingTab.active && CustomFilters()}
      {RenderTable()}
      {RenderExtras()}
      {RenderSaveChangesModal()}
    </>;
  };

  return (
    <div className="listings-container">
      <Tabs
        activeKey={tab.toString()}
        onChange={HandleChangeTab}
        items={[
          {
            label: <T k="ActiveListingsShort" />,
            key: ListingTab.active.toString()
          },
          {
            label: <T k="PendingListingsShort" />,
            key: ListingTab.pending.toString()
          },
          {
            label: <T k="TerminatedListingsShort" />,
            key: ListingTab.terminated.toString()
          },
          {
            label: <T k="ImportListingsShort" />,
            key: ListingTab.import.toString()
          }
        ]}
      />
      {RenderContent()}
    </div>
  );
};
