import { Tooltip } from 'antd';
import { CSSProperties, useState } from 'react';
import coinIcon from '../assets/icons/token2_0.png';
import { BuyTokensModal } from './modals/buy-tokens-modal';
import { T } from '../utils/transShim';
import '../sass/small-components/tokens.scss';

interface props {
  onClick?: () => void;
  tokens: number;
  disableModal?: boolean;
  includeText?: boolean;
  tooltip?: React.ReactNode;
  style?: CSSProperties | undefined;
}

export const Tokens = (props: props) => {
  const { onClick, tokens, disableModal, includeText, tooltip, style} = props;

  const [openModal, setOpenModal] = useState<boolean>(false);

  const HandleOpenModal = () => {
    onClick?.();
    if (!disableModal) {
      setOpenModal(!openModal);
    }
  };

  return <>
    <Tooltip title={tooltip}>
      <div className="tokens-container" onClick={HandleOpenModal} style={style}>
        <img src={coinIcon} className="token-icon" alt="coinIcon" />
        <span className="token-number">{tokens}</span>
        {includeText && <span className="tokens">tokens </span>}
      </div>
    </Tooltip>
    <BuyTokensModal
      open={openModal}
      onClose={HandleOpenModal}
    />
  </>;
};

interface propsCost {
  cost: number;
  style?: CSSProperties | undefined;
}
export const TokensCosts = (props: propsCost) => <Tokens tokens={props.cost} style={props.style} tooltip={<><T k='Tokens.Cost' />&nbsp;{props.cost} </>} />;