import { ePlatform } from '../../../data/platforms';
import { OrderColumnId } from './columns';


const ColumnsVisibleByDefaultGeneric = [
  OrderColumnId.Image,
  //OrderColumnId.ChannelItem,
  //OrderColumnId.Source,
  OrderColumnId.Title,
  OrderColumnId.Quantity,
  OrderColumnId.Sold,
  OrderColumnId.Cost,
  OrderColumnId.Fees,
  OrderColumnId.Profit,
  OrderColumnId.Margin,
  OrderColumnId.DateOfOrder,
  OrderColumnId.Status
];

const ColumnsVisibleByDefaultEBay = [
  OrderColumnId.Image,
  //OrderColumnId.ChannelItem,
  //OrderColumnId.Source,
  OrderColumnId.Title,
  OrderColumnId.Quantity,
  OrderColumnId.Sold,
  OrderColumnId.Cost,
  OrderColumnId.Fees,
  OrderColumnId.Profit,
  OrderColumnId.Margin,
  OrderColumnId.DateOfOrder,
  OrderColumnId.Status
];

export const ColumnsVisibleByDefault = (platform: ePlatform | undefined | null) => {
  switch (platform) {
    default:
      return ColumnsVisibleByDefaultGeneric;
    case ePlatform.eBayNoApi:
      return ColumnsVisibleByDefaultEBay;
  }
};