import { Form, Input, Spin, Dropdown, Space, Menu } from 'antd';
import React from 'react';
import { IsValidEan } from './ean-generator';
import { DownOutlined } from '@ant-design/icons';
import { A } from '../../../small-components/A';


export enum EanType {
  EAN,
  ASIN
}
export type EanFieldChangeEvt = (type: EanType, value: string, isValid: boolean) => void;

export interface EanFieldProps {
  allowAsin: boolean;
  value: string;
  loading: boolean;
  type: EanType;
  whiteList: string[];

  onChangeValue: EanFieldChangeEvt;

  //allowRandomEan: boolean;
}

export default class EanField extends React.Component<EanFieldProps> {
  constructor(props: EanFieldProps) {
    super(props);

    this.state = {};
  }

  render() {
    let labelName;
    switch (this.props.type) {
      default:
        labelName = 'EAN';
        break;
      case EanType.ASIN:
        labelName = 'ASIN';
        break;
    }

    let typeChooser = <></>;
    if (this.props.allowAsin) {
      typeChooser = this.renderDropdown(labelName);
    } else {
      typeChooser = <>{labelName}</>;
    }

    const validationState = EanField.IsValidValue(this.props.type, this.props.value, this.props.whiteList ?? []) ? undefined : 'error';

    return (
      <Form.Item className="eanField" validateStatus={validationState}>
        <div className="ean">
          <label className="main-label">{typeChooser}</label>
          {this.props.loading && <Spin />}
          {!this.props.loading && this.renderBody()}
        </div>
      </Form.Item>
    );
  }
  renderBody() {
    return (
      <>
        <Input
          type="text"
          value={this.props.value}
          name="ean"
          onChange={(e) => {
            const val = e.target.value;
            this.props.onChangeValue(this.props.type, val, EanField.IsValidValue(this.props.type, val, this.props.whiteList));
          }}
        />
      </>
    );
  }

  static IsValidValue(type: EanType, value: string | null, whiteList: string[]) {
    if (value == null || value === '') return false;

    switch (type) {
      case EanType.EAN:
        if (IsValidEan(value)) {
          return true;
        } else {
          const vLc = value.toLowerCase();
          for (const w of whiteList) {
            if (w.toLowerCase() == vLc) return true;
          }
          return false;
        }
    }

    return true;
  }

  renderDropdown(labelName: string) {
    const items = [
      {
        key: EanType.EAN,
        label: (
          <A to="#" onClick={(_) => this.EanSelected()}>
          EAN
          </A>
        )
      },
      {
        key: EanType.ASIN,
        label: (
          <A to="#" onClick={(_) => this.AsinSelected()}>
            ASIN
          </A>
        )
      }
    ];
    return (
      <>
        <Dropdown overlay={(<Menu items={items}/>)} >
          <A to="#" onClick={(e) => e.preventDefault()}>
            <Space>
              {labelName}
              <DownOutlined />
            </Space>
          </A>
        </Dropdown>
      </>
    );
  }

  EanSelected() {
    if (this.props.type != EanType.EAN) this.props.onChangeValue(EanType.EAN, '', false);
  }

  AsinSelected() {
    if (this.props.type != EanType.ASIN) this.props.onChangeValue(EanType.ASIN, '', false);
  }

  /*
  {this.props.allowRandomEan &&
      <span
          style={{ cursor: 'pointer', float: 'right', margin: '0 4px' }}
          className='glyphicon glyphicon-refresh'
          title='Generate a new EAN randomly'
          onClick={e => {
              e.preventDefault();
              const newEan = GenerateRandomEan(sp.site);
              this.setState((prv) => {
                  var nl = { ...this.state.listings };
                  nl[OAuthId] = { ...nl[OAuthId], randomEAN: true };
                  nl[OAuthId].product = { ...nl[OAuthId].product };
                  nl[OAuthId].product.ean = newEan;

                  return { ...prv, loadingEan: false, listings: nl };
              });
              this.InformEanChanged(OAuthId, channelId, this.state.listings[OAuthId].site, newEan);
          }}
      ></span>
  }
  {sp.randomEAN &&
      <span
          style={{ cursor: 'pointer', float: 'right', margin: '0 4px' }}
          className='glyphicon glyphicon-exclamation-sign'
          title='This EAN was randomly generated'>
      </span>
  }
*/
}
