import { useState } from 'react';
import { T } from '../../utils/transShim';
import { Selector, SelectorData, SelectorValue } from './selector';

export enum TimerPrecision {
  Hours = 0,
  Minuts = 1,
  Seconds = 2
}

type Props = {
  label?: React.ReactNode;
  defaultValue?: Date;
  value?: Date;
  precision?: TimerPrecision;
  onChange?: (newDate: Date) => void;
}

const fillRange = (start: number, end: number): SelectorData[] => {
  return Array(end - start + 2)
    .fill(0, 0, end + 1)
    .map((item, index) => start + index)
    .map(itm => ({ value: itm, label: itm.toString() }));
};
const hourOptions = fillRange(0, 23);
const minuteOptions = fillRange(0, 59);

export const TimePicker = (props: Props) => {
  const { label, defaultValue, value, precision, onChange } = props;
  const [cVal, SetCurrentValue] = useState<Date>(value ?? defaultValue ?? new Date(0));

  const currentValue = value ?? cVal;

  const hour = currentValue.getHours();
  const minutes = currentValue.getMinutes();
  const seconds = currentValue.getSeconds();

  const showMinuts = (precision ?? 0) >= TimerPrecision.Minuts;
  const showSeconds = (precision ?? 0) >= TimerPrecision.Seconds;

  const HandleHourChange = (value: SelectorValue) => {
    const baseDate = new Date(currentValue);
    baseDate.setHours(parseInt(value?.toString() ?? '0'));
    SetCurrentValue(baseDate);
    onChange?.(baseDate);
  };
  const HandleMinuteChange = (value: SelectorValue) => {
    const baseDate = new Date(currentValue);
    baseDate.setMinutes(parseInt(value?.toString() ?? '0'));
    SetCurrentValue(baseDate);
    onChange?.(baseDate);
  };
  const HandleSecondChange = (value: SelectorValue) => {
    const baseDate = new Date(currentValue);
    baseDate.setSeconds(parseInt(value?.toString() ?? '0'));
    SetCurrentValue(baseDate);
    onChange?.(baseDate);
  };

  return (
    <div className='timePicker'>
      <label>{label || <T k='Setting.Time' />}</label>
      <Selector className='form-control' value={hour} onChange={HandleHourChange}>{hourOptions}</Selector>
      {showMinuts && <>
        {' : '}
        <Selector className='form-control' value={minutes} onChange={HandleMinuteChange}>{minuteOptions}</Selector>
      </>}
      {showSeconds && <>
        {' : '}
        <Selector className='form-control' value={seconds} onChange={HandleSecondChange}>{minuteOptions}</Selector>
      </>}
    </div>
  );
};