import { ePlatform } from '../../../../../data/platforms';
import { PopupModal } from '../../../../../small-components/modals/popup-modal';
import { ActiveListingExtended } from '../../types';
import { EditMultipleListing } from './edit-multiple-listing';
import { EditSingleListing } from './edit-single-listing';

interface Props {
  platform: ePlatform | undefined;
  listings?: ActiveListingExtended[] | null;
  onClose: () => void;
}
export const EditActiveListings = (props: Props) => {
  const { platform, listings, onClose } = props;

  if (!listings || listings.length == 0)
    return <></>;

  if (listings.length == 1) {
    return (
      <PopupModal open={true} maxWidth={900} onClose={onClose}>
        <EditSingleListing
          platform={platform}
          listing={listings[0]}
          onClose={onClose}
        />
      </PopupModal>
    );
  }

  return <PopupModal open={true} maxWidth={900} onClose={onClose}>
    <EditMultipleListing
      platform={platform}
      listings={listings}
      onClose={onClose}
    />
  </PopupModal>;
};