import { Col, Row, Spin } from 'antd';
import { CancelBtn, SuccessBtn } from '../../small-components/ActionBtns';
import '../../sass/product-details.scss';
import { FullProductSource } from './Types';
import { T } from '../../utils/transShim';
import { ReactUtils } from '../../utils/react-utils';
import { GetProductSource } from '../../redux/catalog/catalogThunk';
import { useAppDispatch } from '../../custom-hooks/reduxCustomHooks';
import { useEffect, useState } from 'react';
import { CatalogProduct } from '../../redux/catalog/catalogSlice';
import { eCurrency } from '../../types/eCurrency';

interface Props {
  imageUrl?: string;
  channelPrice?: number;
  profit?: number;
  sourcePrice?: number;
  onClose: () => void;
  product: CatalogProduct;
  onSelectProduct: (id: number) => void;
  selected: boolean;
  currency: eCurrency;
  sourceName: string;
}
export const ProductDetails = (props: Props) => {
  const { imageUrl, profit, sourcePrice, channelPrice, onClose, product, onSelectProduct, selected, currency, sourceName } = props;
  const dispatch = useAppDispatch();

  const [description, SetDescription] = useState<FullProductSource | undefined>(undefined);

  useEffect(() => {
    SetDescription(undefined);
    (async () => {
      const rp = await dispatch(GetProductSource({ id: product.id }));
      SetDescription(rp.payload as FullProductSource | undefined);
    })();
  }, [product.id, GetProductSource]);

  const AddToSelection = () => {
    onSelectProduct(product.id);
  };

  const RemoveToSelection = () => {
    onSelectProduct(product.id);
  };

  const RenderDescription = () => {
    if (!description)
      return <Spin />;
    return <>
      {(description.paragraphs ?? []).map((x, i) => <p key={i}>{x}</p>)}
      <ul>
        {(description.properties ?? []).map((x, i) => <li key={i}>{x}</li>)}
      </ul>
      <table>
        {(description.tableValues ?? []).map((x, i) => <tr key={i}><td>{x.key}</td><td>{x.value}</td></tr>)}
      </table>
    </>;
  };

  return (
    <div className="product-details">
      <div className="modal-title">
        <h1>{product.title}</h1>
        <h5 className="source"><T k='Catalog.By' values={{ name: sourceName }} /></h5>
      </div>
      <Row className='details-content' gutter={[32, 0]}>
        <Col lg={8} className="transaction-img-col">
          <img src={imageUrl} alt="" className="product-img" />
          <div className="transaction-details">
            <div className="transaction-type">
              <p>
                <strong><T k='Catalog.Sell' /></strong>
                <strong>
                  <span>&pound;</span> {channelPrice != null ? ReactUtils.DrawPrice(channelPrice, currency) : ''}
                </strong>
              </p>
              <p>
                <strong><T k='Catalog.Cost' /></strong>
                <strong>
                  <span>&pound;</span> {sourcePrice != null ? ReactUtils.DrawPrice(sourcePrice, currency) : ''}
                </strong>
              </p>
              <p>
                <strong><T k='Catalog.Profit' /></strong>
                <strong>{profit != null ? ReactUtils.DrawPrice(profit, currency) : ''}</strong>
              </p>
            </div>
          </div>
        </Col>
        <Col lg={16} className="transaction-col-details">
          <div className="description">
            {RenderDescription()}
          </div>
          <div className="action-btns">
            {selected ? (
              <SuccessBtn onClick={RemoveToSelection}>
                <T k="Catalog.RemoveFromSelection" />
              </SuccessBtn>
            ) : (
              <SuccessBtn onClick={AddToSelection}>
                <T k="Catalog.AddToSelection" />
              </SuccessBtn>
            )}
            <CancelBtn onClick={onClose}>
              <T k="Button.Cancel" />
            </CancelBtn>
          </div>
        </Col>
      </Row>
    </div>
  );
};
