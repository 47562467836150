import { ReactNode, useContext } from 'react';

import { Channel } from 'src/redux/channels/channelsSlice';
import { AppContext } from '../contexts/AppContext';
import { Selector, SelectorData, SelectorValue } from './form/selector';
import { PlusCircleOutlined } from '@ant-design/icons';
import { ReactUtils } from '../utils/react-utils';
import { ChannelLabel } from './channel-label';
import { T } from '../utils/transShim';
import { Links } from '../links';
import { A } from './A';

export const StoreList = () => {
  const channels = ReactUtils.GetAllChannels();
  const { channelId, setChannelId } = useContext(AppContext);

  if (channels.length > 0 && !channels.find((x) => x.id == channelId)) {
    setChannelId(channels[0].id);
  }

  const CreateLabel = (c: Channel) => <ChannelLabel channel={c} />;

  const CreateValue = (c: Channel) => {
    return {
      value: c.id,
      label: <>{CreateLabel(c)}</>,
      title: c.name
    } as SelectorData;
  };

  const options = channels.map(CreateValue);
  options.sort((a, b) => a.title?.localeCompare(b.title ?? '') ?? 0);

  const OnChange = (value: SelectorValue) => {
    setChannelId(value as number);
  };

  return (
    <div className="store-list-container">
      <Selector
        className="selector-store"
        size="large"
        showSearch={true}
        value={channelId}
        onChange={OnChange}
        virtualScroll={false}
        popupClassName="store-list-dropdown"
        dropdownRender={(menu: ReactNode) => (
          <>
            <div className="menu">{menu}</div>
            <A to={Links.NewChannel.Link} className="redirect-link">
              <PlusCircleOutlined style={{ fontSize: '19px' }} />
              <span>
                <T k="AddNewChannel" />
              </span>
            </A>
          </>
        )}
      >
        {options}
      </Selector>
    </div>
  );
};
