import { InputNumber, Spin } from 'antd';
import { useState } from 'react';
import '../../sass/settings/settings.scss';
import { T } from '../../utils/transShim';
import { NumberOrNull } from '../../utils/utils';
import { SettingDefaultCustomWrap } from './setting-default-custom-wrap';

interface Props {
  defaultValue?: string | null;
  loading?: boolean;
  delayToSave?: number;
  onChange?: (value: string | null) => void;
  onChangeNoDelay?: (value: string | null) => void;
  disabled?: boolean;
  placeholder?: string;
  min?: string | null;
  max?: string | null;

  value?: string | null;

  superiorValue?: string;
}

export const SettingNumber = (props: Props) => {
  const { delayToSave, onChange, loading, disabled, onChangeNoDelay, placeholder, defaultValue, min, max} = props;
  const value = NumberOrNull(props.value) ?? undefined;
  const superiorValue = NumberOrNull(props.superiorValue) ?? undefined;

  const [delayTimer, setDelayTimer] = useState<number>(-1);

  const HandleChangeValue = (newValue: string | null) => {
    onChangeNoDelay?.(newValue);

    clearTimeout(delayTimer);
    setDelayTimer(
      window.setTimeout(
        () => {
          if (newValue != defaultValue) {
            onChange?.(newValue);
          }
        },
        delayToSave ?? 1000
      )
    );
  };
  const HandleChange = (value: number | null) => {
    const newValue = value?.toString() ?? defaultValue ?? null;
    onChangeNoDelay?.(newValue);

    clearTimeout(delayTimer);
    setDelayTimer(
      window.setTimeout(
        () => {
          if (newValue != defaultValue) {
            onChange?.(newValue);
          }
        },
        delayToSave ?? 1000
      )
    );
  };

  const input =  <div className="setting setting-number">
    <InputNumber
      defaultValue={NumberOrNull(defaultValue) ?? superiorValue}
      className='blue-input'
      onChange={HandleChange}
      disabled={loading || disabled}
      value={value}
      placeholder={placeholder}
      min={NumberOrNull(min) ?? undefined}
      max={NumberOrNull(max) ?? undefined}
    />
    {loading && <Spin />}
  </div>;

  if (superiorValue != null) {
    return <SettingDefaultCustomWrap
      key={0}
      defautlSelectedValue={superiorValue?.toString()}
      defaultValue={defaultValue}
      onChangeNoDelay={HandleChangeValue}
      loading={loading}
      disabled={disabled}
      label1={<T k='Setting.DefinedBySettings' values={{ value: superiorValue }} />}
      label2={<T k='Setting.Custom' />}
    >
      {input}
    </SettingDefaultCustomWrap>;
  }

  return input;
};