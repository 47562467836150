import { useHistory } from 'react-router';
import { Links } from '../../../../links';
import { Channel } from '../../../../redux/channels/channelsSlice';
import { PrimaryBtn } from '../../../../small-components/ActionBtns';
/*import { toastAlert } from '../../../../utils/toastAlert';*/
import { T } from '../../../../utils/transShim';

type Props = {
  channel: Channel;
};
export const RelinkChannelSetting = (props: Props) => {
  const { channel } = props;

  const history = useHistory();

  //const OpenPopupWindow = async (url: string, win: Window & typeof globalThis, w: number, h: number) => {
  //  if (win?.top == null) return;
  //  const t = win.top.outerHeight / 2 + win.top.screenY - h / 2;
  //  const l = win.top.outerWidth / 2 + win.top.screenX - w / 2;
  //  const newWindow = win.open(
  //    url,
  //    '_blank',
  //    `toolbar=no, location=yes, directories=no, status=no, menubar=no,
  //                    scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${t}, left=${l}`
  //  );

  //  localStorage.setItem('newChannelSuccess', 'false');
  //  const timer = window.setInterval(() => {
  //    if (newWindow?.closed) {
  //      window.clearInterval(timer);
  //      if (localStorage.getItem('newChannelSuccess') !== 'true') {
  //        toastAlert('Unknown error', 'error');
  //      } else {
  //        toastAlert('Success', 'success');
  //      }
  //    }
  //  }, 1000);
  //};

  const HandleClick = () => {
    history.push(Links.RelinkStore.Produce(channel.channelId, channel.isoCountry, channel.name));
    //OpenPopupWindow(Links.RelinkStore.Produce(channel.channelId, channel.isoCountry, channel.name), window, 800, 600);
  };

  return (
    <PrimaryBtn onClick={HandleClick}>
      <T k="Channel.Setting.Button.RelinkChannel" />
    </PrimaryBtn>
  );
};
