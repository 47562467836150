import { T } from '../../utils/transShim';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { useDispatch } from 'react-redux';
import { LoadingModal } from './loading-modal';
import { getUserToken } from '../../redux/user/userThunk';
import { Optimize } from '../../redux/listings/listings-thunk';
import { Checkbox, Divider } from 'antd';
import { TokensCosts } from '../Tokens';
import { HelpTooltip } from '../help-tooltip';
import { PrimaryBtn } from '../ActionBtns';
import { BuyTokensModal } from './buy-tokens-modal';
import { A } from '../A';


type Props = {
  onClose: () => void;
  visible?: boolean;
  listings: { listingId: number; productSourceId: number }[];
};
export const OptimiseModal = (props: Props) => {
  const { onClose, visible, listings } = props;

  const { tokens: currentTokens } = useAppSelector((state) => state.user);

  const [optimising, SetOptimising] = useState<boolean>(false);
  const [done, SetDone] = useState<boolean>(false);
  const [progress, SetProgress] = useState<number>(0);

  const [optimizeTitle, setOptimizeTitle] = useState<boolean>(true);
  const [optimizeDescription, setOptimizeDescription] = useState<boolean>(true);
  const [openBuy, SetOpenBuy] = useState<boolean>(false);

  useEffect(() => {
    SetOptimising(false);
    SetDone(false);
    SetProgress(0);
    setOptimizeTitle(true);
    setOptimizeDescription(true);
  }, [visible]);

  const dispatch = useDispatch();

  const HandleOnOk = async () => {
    if (done) {
      onClose();
      return;
    }
    SetOptimising(true);
    for (let i = 0; i < listings.length; i++) {
      SetProgress((100 * i) / listings.length);
      await dispatch(
        Optimize({ listingId: listings[i].listingId, productSourceId: listings[i].productSourceId, optimizeTitle, optimizeDescription })
      );
    }
    SetProgress(100);
    SetDone(true);
    await dispatch(getUserToken());
  };

  const HandleTitle = () => {
    setOptimizeDescription(true);
    setOptimizeTitle(!optimizeTitle);
  };

  const HandleDescription = () => {
    setOptimizeDescription(!optimizeDescription);
    setOptimizeTitle(true);
  };

  const HandleOpenBuy = () => {
    SetOpenBuy(!openBuy);
  };

  const totalCost = (optimizeTitle ? listings.length : 0) + (optimizeDescription ? 2 * listings.length : 0);
  const needMoreTokens = (currentTokens ?? 0) < totalCost;
  return (
    <>
      <LoadingModal
        key="om"
        onCancel={onClose}
        onClose={onClose}
        onAccept={HandleOnOk}
        progress={progress}
        acceptText={done ? <T k="Button.Close" /> : <T k="Listings.Button.Optimize" values={{ quantity: listings.length }} />}
        visible={visible}
        okDisabled={needMoreTokens}
        loading={!done}
        cancelable={false}
        confirmating={!optimising && !done}
        closeOnAccept={false}
        result={<T k="Listings.Edit.Optimised" />}
        messageLoading={
          <div className="under-sections">
            <div className="title-section">
              <h3>
                <T k="Listings.Edit.Optimize" />
              </h3>
              <A to="https://hustlegotreal.com/en/hustle-got-real-ai-powered-tokens/" target="_blank" rel="noreferrer">
                <HelpTooltip>Click to read more about AI Optimization</HelpTooltip>
              </A>
            </div>
            <Divider />
            <div className="section-option-container">
              <div className="section-option">
                <Checkbox checked={optimizeTitle} onChange={HandleTitle} disabled={optimising} />
                <T k="Publish.Optimize.Title" />
                <TokensCosts cost={1} />
              </div>
              <div className="section-option">
                <Checkbox checked={optimizeDescription} onChange={HandleDescription} disabled={optimising} />
                <T k="Publish.Optimize.Description" />
                <TokensCosts cost={2} />
              </div>
              <div className="section-text">
                <T
                  k="Publish.Optimize.TotalCost"
                  values={{ quantity: totalCost }}
                />
                {needMoreTokens && <div className="center">
                  <T k='Listings.Edit.Optimize.NotEnought'/>
                  <PrimaryBtn onClick={HandleOpenBuy}>
                    <T k='BuyTokens.Title' />
                  </PrimaryBtn>
                  <BuyTokensModal open={openBuy} onClose={HandleOpenBuy} />
                </div>}
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};
