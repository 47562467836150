import { Col,  Row } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../custom-hooks/reduxCustomHooks';
import { SaveSourceSetting } from '../../../redux/source-configuration/sources.coonfiguration-thunk';
import { Source } from '../../../redux/sources/sourceSlice';
import { SettingBoolean } from '../../../small-components/settings/setting-boolean';
import { SettingBooleanNumberNull } from '../../../small-components/settings/setting-boolean-number-null';
import { SourceSettingKey } from '../../../types/settings';
import { T } from '../../../utils/transShim';

type props = {
  sourceData: Source;
}

export const Settings = (props: props) => {
  const dispatch = useAppDispatch();
  const { sourceData } = props;

  const { get: sourceSettings, saving: savingSourceSettings } = useAppSelector(state => state.sourcesConfiguration);

  const GetSourcesetting = (s: SourceSettingKey) => sourceSettings?.settings?.find((x) => x.key == s && x.sourceId == sourceData.id)?.value;
  const GetSavingSourceSetting = (s: SourceSettingKey) => savingSourceSettings?.find((x) => x?.data?.key == s);

  const HandleSourceSettingChange = (value: string | null, key: SourceSettingKey) => {
    dispatch(SaveSourceSetting({ key: key, sourceId: sourceData.id, value: value }));
  };

  const autoorderingSettingEnabled = GetSourcesetting(SourceSettingKey.AutoOrdering) as string | undefined;

  return (<>
    <Row className='description-and-controls'>
      <Col span={12} className='description-area'>
        <h2><T k="AutoOrderingConfiguration.Markasshippedonyourstore" /></h2>
        <T k="AutoOrderingConfiguration.Markasshippedonyourstore.Description" />
      </Col>
      <Col span={8} className='input-container'>
        <SettingBoolean
          loading={GetSavingSourceSetting(SourceSettingKey.AutoOrderingMarkShipped)?.loading}
          onChange={(x) => HandleSourceSettingChange(x, SourceSettingKey.AutoOrderingMarkShipped)}
          value={(GetSourcesetting(SourceSettingKey.AutoOrderingMarkShipped) as string | undefined) ?? autoorderingSettingEnabled}
        />
      </Col>
    </Row>
    {GetSourcesetting(SourceSettingKey.AutoOrderingMarkShipped) == '1' && (
      <Row className='description-and-controls'>
        <Col span={12} className='description-area'>
          <h2><T k="AutoOrderingConfiguration.Settrackingnumber" /></h2>
          <T k="AutoOrderingConfiguration.Settrackingnumber.Description" />
        </Col>
        <Col span={8} className='input-container'>
          <SettingBoolean
            loading={GetSavingSourceSetting(SourceSettingKey.AutoOrderingTrackingNumber)?.loading}
            onChange={(x) => HandleSourceSettingChange(x, SourceSettingKey.AutoOrderingTrackingNumber)}
            value={GetSourcesetting(SourceSettingKey.AutoOrderingTrackingNumber) as string | undefined}
          />
        </Col>
      </Row>
    )}
    <Row className='description-and-controls'>
      <Col span={12} className='description-area'>
        <h2><T k="AutoOrderingConfiguration.ForceBuy" /></h2>
        <T k="AutoOrderingConfiguration.ForceBuy.Description" />
      </Col>
      <Col span={8} className='input-container'>
        <SettingBoolean
          loading={GetSavingSourceSetting(SourceSettingKey.AutoOrderingIgnoreErrors)?.loading}
          onChange={(x) => HandleSourceSettingChange(x, SourceSettingKey.AutoOrderingIgnoreErrors)}
          value={GetSourcesetting(SourceSettingKey.AutoOrderingIgnoreErrors) as string | undefined}
        />
      </Col>
    </Row>
    {GetSourcesetting(SourceSettingKey.AutoOrderingIgnoreErrors) == '1' && (
      <Row className='description-and-controls'>
        <Col span={12} className='description-area'>
          <h2><T k="AutoOrderingConfiguration.ForceBuyMaxCost" /></h2>
          <T k="AutoOrderingConfiguration.ForceBuyMaxCost.Description" />
        </Col>
        <Col span={8} className='input-container'>
          <SettingBooleanNumberNull
            defaultNumberValue="20"
            falseValue="0"
            loading={GetSavingSourceSetting(SourceSettingKey.AutoOrderingIgnoreErrorsMaxCost)?.loading ?? false}
            onChange={(x) => HandleSourceSettingChange(x, SourceSettingKey.AutoOrderingIgnoreErrorsMaxCost)}
            defaultValue={(
              (GetSourcesetting(SourceSettingKey.AutoOrderingIgnoreErrorsMaxCost) as number | undefined) ?? 0
            ).toString()}
          />
        </Col>
      </Row>
    )}
  </>);
};