import { Col, Row, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { CKEditor, CKEditorEventHandler, CKEditorInstance } from 'ckeditor4-react';
import * as React from 'react';
import { DescriptionType, GetPlatform } from '../../../data/platforms';
import { Template } from '../../../redux/templates/templatesSlice';
import { ComListing, FeaturesTableRow } from './com-listing';
import { GetTemplateHtml } from './description-functions';
import coinIcon from '../../../assets/icons/token2_0.png';
import { QuestionModal } from '../../../small-components/modals/question-modal';
import { TokensCosts } from '../../../small-components/Tokens';
import { OptimiseDescriptionPlain } from '../../../redux/listings/manual-listing-thunk';
import { Channel } from '../../../redux/channels/channelsSlice';
import { A } from '../../../small-components/A';


type Props = {
  rawHtml?: string;
  onChange?: (newHtmlValue: string) => void;
  rawData?: ComListing;
  type: DescriptionType;
  descriptionLength: number | undefined;
  templates: Template[] | null | undefined;
  onOptimizedDescription: (data: { paragraphs: string[], properties: string[], tableValues: FeaturesTableRow[] }) => void;
  selectedChannel: Channel;
  optimizedDescription: boolean;
};
type IHtmlEditorState = {
  templateId?: number;
  rawHtml?: string;
  editor: CKEditorInstance | null;
  showOptimiseModal: boolean;
  optimising: boolean;
};

const DescriptionLength = (props: { length: number; maxLength: number | undefined }) => {
  if (props.maxLength == undefined) return <></>;
  return (
    <span>
      Total description length: {props.length}(max {props.maxLength})
    </span>
  );
};

class HtmlEditorCore extends React.Component<Props, IHtmlEditorState> {
  config = {};

  constructor(props: Props) {
    super(props);

    this.state = {
      templateId: props.rawData?.settings ? props.rawData.settings.templateId : undefined,
      rawHtml: props.rawHtml,
      editor: null,
      showOptimiseModal: false,
      optimising: false
    };

    switch (props.type) {
      default:
      case DescriptionType.FullHTML:
        this.config = {
          //: IJodit = {
          readonly: false, // all options from https://xdsoft.net/jodit/doc/ || https://xdsoft.net/jodit/play.html?defaultMode=1
          //defaultMode: "3", // split source/code
          iframe: true,
          defaultActionOnPaste: 'insert_clear_html',
          beautyHTML: true,
          toolbarAdaptive: false,
          buttons:
            'source,|,bold,underline,italic,|,superscript,subscript,|,ul,ol,|,outdent,indent,|,font,fontsize,brush,paragraph,|,image,file,video,table,link,|,align,undo,redo,cut,hr,eraser,copyformat,|,symbol,fullsize,selectall,print'
        };
        break;
      case DescriptionType.OnlyBR:
        this.config = {
          //: IJodit = {
          enter: 'BR',
          defaultMode: '1',
          iframe: true,
          readonly: false, // all options from https://xdsoft.net/jodit/doc/ || https://xdsoft.net/jodit/play.html?defaultMode=1
          defaultActionOnPaste: 'insert_only_text',
          beautyHTML: false,
          toolbarAdaptive: false
        };
        break;
    }
  }

  componentDidMount = () => {
    // No point loading it here, has to be loaded within the context of the shadow dom.
    //var css = window.document.createElement('link');
    //css.rel = 'stylesheet';
    //css.type = 'text/css';
    //css.href = '/css/jodit.min.css';
    //document.head.appendChild(css);
    //console.log('adding stylesheet.');
  };

  applyTemplate = (newHtml: string) => {
    //if (this.elementRef?.current) {
    //    if (!this.elementRef.current.shadowRoot) {
    //        const shadow = this.elementRef.current.attachShadow({ mode: 'open' });
    //        this.elementRef.current = shadow;
    //    }
    //}

    this.setState({ rawHtml: newHtml });
    this.state.editor?.setData(newHtml);
    if (this.timeOutChange) clearTimeout(this.timeOutChange);

    this.timeOutChange = window.setTimeout(() => {
      this.props.onChange?.(newHtml);
    }, 500);
  };

  ChangeTemplate = async (templateId: number, onlyBr: DescriptionType) => {
    const newHtml = GetTemplateHtml(this.props.templates ?? [], this.props.rawData, templateId, onlyBr);
    this.applyTemplate(newHtml);
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      !this.props.rawData?.listingId &&
      nextProps.rawData?.settings &&
      ((this.props.rawData?.settings && nextProps.rawData?.settings.templateId !== this.props.rawData?.settings.templateId) ||
        !this.props.rawData?.settings)
    ) {
      const tid = nextProps.rawData.settings?.templateId || 0;

      this.setState({ templateId: tid });
      this.ChangeTemplate(tid, this.props.type);
    }
  }

  timeOutChange: number | undefined = undefined;

  selectTemplateOnChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const tid = parseInt(ev.target.value, 10);
    //this.state.templates.findIndex
    this.setState({ templateId: tid }); //this.state.templates[tid].id });
    return this.ChangeTemplate(tid, this.props.type);
  };

  onChange = (newHtmlValue: string) => {
    if (this.props.onChange) this.props.onChange(newHtmlValue);
    this.setState({ rawHtml: newHtmlValue });
  };

  OnBeforeLoad = (editor: CKEditorInstance | null) => {
    if (editor != null) {
      this.setState({ editor: editor });
    }
  };

  OnOptimiseClick = () => {
    this.setState({ showOptimiseModal: true });
  };
  ExecuteOptimiseDescription = async () => {
    this.setState({ showOptimiseModal: false });
    if (this.state.optimising || !this.props.rawData?.productSourceId) return;
    this.setState({ optimising: true });

    const rp = await OptimiseDescriptionPlain({
      listingId: this.props.rawData?.listingId,
      productSourceId: this.props.rawData?.productSourceId
    });

    if (rp.success) {
      this.props.onOptimizedDescription({
        paragraphs: rp.paragraphs,
        properties: rp.properties,
        tableValues: rp.tableValues
      });
    }

    this.setState({
      showOptimiseModal: false,
      optimising: false
    });

    if (this.state.templateId && this.props.rawData?.product) {
      const newHtml = GetTemplateHtml(this.props.templates ?? [], { ...this.props.rawData, product: { ...this.props.rawData.product, paragraphs: rp.paragraphs, properties: rp.properties, tableValues: rp.tableValues } }, this.state.templateId, this.props.type);
      this.applyTemplate(newHtml);
    }
  };

  public render() {
    const { templates } = this.props;
    const { templateId } = this.state;

    const PlainEditor = () => {
      return <TextArea className="plain-editor" value={this.state.rawHtml} onChange={(v) => this.onChange(v.currentTarget.value)} />;
    };

    const HtmlEditor = () => {
      return (
        <CKEditor<{ onChange: CKEditorEventHandler<'change'> }>
          initData={this.state.rawHtml}
          config={{
            height: 420,
            allowedContent: true,
            versionCheck: false//to avoid messages like "you need to update, this is not a safe version"
          }}
          onLoaded={({ editor }) => this.OnBeforeLoad(editor)}
          onChange={({ editor }) => {
            const newData = editor.getData();
            if (newData != this.state.rawHtml) this.onChange?.(newData);
          }}
          onKey={({ editor }) => {
            if (editor.mode == 'source')
              setTimeout((_) => {
                const newData = editor.getData();
                if (newData != this.state.rawHtml) this.onChange?.(newData);
              }, 100);
          }}
        />
      );
    };

    const Editor = () => {
      switch (this.props.type) {
        default:
          return HtmlEditor();
        case DescriptionType.Plain:
          return PlainEditor();
      }
    };

    const canOptimise = GetPlatform(this.props.selectedChannel.channelId).canOptimizeTitle[this.props.selectedChannel.isoCountry];
    const OPT = (
      <div>
        {this.props.type == DescriptionType.FullHTML && (
          <Row>
            <Col>
              <label>Template:</label>
              <select className="form-control" value={templateId} onChange={this.selectTemplateOnChange}>
                <option value={-1}>None</option>
                {templates?.map((f, ix) => (
                  <option key={ix} value={f.id}>
                    {f.name}
                  </option>
                ))}
              </select>
            </Col>
            {canOptimise && <Col style={{marginLeft:20}}>
              {!this.props.optimizedDescription && !this.state.optimising &&
                <div>
                  <A to="#" onClick={this.OnOptimiseClick}>
                    Optimize description automatically
                  </A>
                  <img className="coinicon" src={coinIcon} alt="" />
                </div>
              }
              {this.props.optimizedDescription && !this.state.optimising && 'Description optimised'}
              {this.state.optimising && 'Optimising...'}
            </Col>}
          </Row>
        )}
        <br />
        <DescriptionLength length={this.state.rawHtml?.length ?? 0} maxLength={this.props.descriptionLength} />

        {Editor()}
        <QuestionModal
          visible={this.state.showOptimiseModal}
          onOk={this.ExecuteOptimiseDescription}
          onCancel={() => {
            this.setState({ showOptimiseModal: false });
          }}
        >
          <h4>Do you want to optimise the description?</h4>
          <div className="text-center">
            <span>Cost:</span><TokensCosts cost={2} style={{ display: 'inline-block' }} />
          </div>
        </QuestionModal>
      </div>
    );

    return OPT;
  }
}
type S = Record<string, never>;
export class HtmlEditor extends React.Component<Props, S> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    if (!this.props.templates) return <Spin />;

    return <HtmlEditorCore {...this.props} />;
  }
}
