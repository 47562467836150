import { Popover, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../custom-hooks/reduxCustomHooks';
import { UserSupplierSettingsKeys } from '../../../../data/autoorder-fields';
import { AutoOrderingConfigurationAccountData,  DeleteAutoOrderingAccount,  GetAutoOrderingConfiguration, SaveAutoOrderingAccountConfiguration, UserSupplierType } from '../../../../redux/auto-ordering/autoOrderingThunk';
import { GetComputedConfiguration, SaveSourceSetting } from '../../../../redux/source-configuration/sources.coonfiguration-thunk';
import { Source } from '../../../../redux/sources/sourceSlice';
import { AddButton} from '../../../../small-components/ActionBtns';
import { SettingBoolean } from '../../../../small-components/settings/setting-boolean';
import { SourceSettingKey } from '../../../../types/settings';
import { ReactUtils } from '../../../../utils/react-utils';
import { toastAlert } from '../../../../utils/toastAlert';
import { T } from '../../../../utils/transShim';
import { AmazonSourceIds } from '../../../sources/configuration/settings-list';
import { tipsAmazon } from '../tips-amazon';
import { AOAccountEdit } from './ao-account-edit';
import { AOAccountsTable } from './ao-accounts-table';

type props = {
  sourceData: Source;
  accounts: AutoOrderingConfigurationAccountData[];
  loading: boolean;
};
export const AOAccounts = (props: props) => {
  const dispatch = useAppDispatch();
  const channel = ReactUtils.GetSelectedChannel();

  const { sourceData, accounts, loading } = props;

  const { get: sourceSettings, saving: savingSourceSettings } = useAppSelector(state => state.sourcesConfiguration);

  const GetSourcesetting = (s: SourceSettingKey) => sourceSettings?.settings?.find((x) => x.key == s && x.sourceId == sourceData.id)?.value;
  const GetSavingSourceSetting = (s: SourceSettingKey) => savingSourceSettings?.find((x) => x?.data?.key == s);

  const [editingAccount, SetEdittingAccount] = useState<AutoOrderingConfigurationAccountData | undefined>();
 
  useEffect(() => {
    dispatch(GetAutoOrderingConfiguration());
  }, [GetAutoOrderingConfiguration]);

  useEffect(() => {
    dispatch(GetComputedConfiguration());
  }, [GetComputedConfiguration]);

  const OpenNewAccount = () => {
    if (!channel) return;
    SetEdittingAccount({
      channelOAuthId: channel.id,
      sourceId: sourceData.id,
      createdOn: new Date(),
      id: -1,
      rawSettings: [{ key: UserSupplierSettingsKeys.ACTIVE, value: '1' }]
    });
  };
  const hgrAccountSetting = (() => {
    const g = GetSourcesetting(SourceSettingKey.AutoOrderingHGRAccount) as string | undefined;
    return g == null ? null : g == '1' || g?.toLowerCase() == 'true';
  })();
  const both = sourceData.hasAutoOrder && sourceData.hasAutoOrderHGRAccount;
  const autoOrderingHGR = (!both && sourceData.hasAutoOrderHGRAccount) || hgrAccountSetting == true;

  useEffect(() => {
    if (!loading && accounts.length == 0 && editingAccount == null) {
      OpenNewAccount();
    }
    if (loading)
      SetEdittingAccount(undefined);
  }, [accounts.length, accounts?.[0]?.id, accounts?.[accounts.length]?.id, loading]);

  const userAccounts = accounts.filter(x => x.type != UserSupplierType.HGR);

  useEffect(() => {
    if (autoOrderingHGR) {
      if (channel) {
        const c = accounts.find(x => x.type == UserSupplierType.HGR);
        if (editingAccount?.type != UserSupplierType.HGR || (c && editingAccount?.id != c.id)) {
          if (c)
            SetEdittingAccount(c);
          else
            SetEdittingAccount({
              channelOAuthId: channel.id,
              sourceId: sourceData.id,
              createdOn: new Date(),
              id: -1,
              rawSettings: [{ key: UserSupplierSettingsKeys.ACTIVE, value: '1' }],
              type: UserSupplierType.HGR
            });
        }
      }
    } else {
      if (!editingAccount || editingAccount.type == UserSupplierType.HGR) {
        if (!loading && userAccounts.length == 0) {
          OpenNewAccount();
        } else if (!loading) {
          SetEdittingAccount(undefined);
        }
        if (loading)
          SetEdittingAccount(undefined);
      }
    }
  }, [autoOrderingHGR, channel, editingAccount?.id, accounts.length, accounts?.[0]?.id, accounts?.[accounts.length]?.id, loading]);

  const HandleSourceSettingChange = (value: string | null, key: SourceSettingKey) => {
    dispatch(SaveSourceSetting({ key: key, sourceId: sourceData.id, value: value }));
  };

  const HandleAccountSelected = (data: AutoOrderingConfigurationAccountData | undefined) => {
    if (!channel) return;
    SetEdittingAccount(data);
  };

  const HandleNewAccount = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    OpenNewAccount();
  };

  const HandleSaveAutoOrderAccount = async (account: AutoOrderingConfigurationAccountData) => {
    if (!channel) return;

    const rp = await dispatch(
      SaveAutoOrderingAccountConfiguration({
        channelOAuthId: channel.id,
        sourceId: sourceData.id,
        supplierId: account.id,
        rawSettings: account.rawSettings,
        type: account.type
      })
    );
    if (rp.payload?.success) {
      SetEdittingAccount({ ...account, id: rp.payload.id }); //In case it is new, it will get the correct id
    } else {
      toastAlert('Something went wrong. Please try again.', 'error');
      dispatch(GetAutoOrderingConfiguration());
    }
  };

  const HandleRemoveAccount = async (account: AutoOrderingConfigurationAccountData) => {
    if (!channel) return;

    let success = false;
    if (account && account.id > 0) {
      success = ((await dispatch(DeleteAutoOrderingAccount(account.id))).payload as { success: boolean }).success ?? false;
    } else {
      success = true;
    }

    if (success) {
      SetEdittingAccount(undefined);
    } else {
      toastAlert('Something went wrong. Please try again.', 'error');
    }
  };



  const isAmazon = AmazonSourceIds.find((x) => x == sourceData.id);

  const RenderAccountTypeSwitch = () => {
    return <Row>
      <label><T k='AutoOrderingConfiguration.AccountType.HGR.Label'/>&nbsp;</label>
      <SettingBoolean
        loading={GetSavingSourceSetting(SourceSettingKey.AutoOrderingHGRAccount)?.loading}
        onChange={_ => hgrAccountSetting != true ? HandleSourceSettingChange('1', SourceSettingKey.AutoOrderingHGRAccount) : undefined}
        value={hgrAccountSetting == true ? '1' : '0'}
      />
      <label><T k='AutoOrderingConfiguration.AccountType.User.Label' />&nbsp;</label>
      <SettingBoolean
        loading={GetSavingSourceSetting(SourceSettingKey.AutoOrderingHGRAccount)?.loading}
        onChange={_ => hgrAccountSetting != false ? HandleSourceSettingChange('0', SourceSettingKey.AutoOrderingHGRAccount) : undefined}
        value={hgrAccountSetting == false ? '1' : '0'}
      />
    </Row>;
  };

  return (
    <div className="auto-ordering-accounts">
      {both && RenderAccountTypeSwitch()}
      {(!both || hgrAccountSetting == false) && <>
        {isAmazon && (
          <Popover placement="right" content={tipsAmazon}>
            <div className="popover-container">
              <h5 className="tips-title">
                <T k="AutoOrderingConfiguration.AvoidBans" values={{ name: sourceData?.name }} />
              </h5>
            </div>
          </Popover>
        )}
        {(userAccounts?.length ?? 0) > 0 && <>
          <AOAccountsTable accounts={userAccounts} onEditAccount={HandleAccountSelected} />
          <AddButton onClick={HandleNewAccount}> <T k="AutoOrderingConfiguration.AddNewAccount" /></AddButton>
        </>}
      </>}
      {editingAccount && <AOAccountEdit account={editingAccount} sourceData={sourceData} onRemove={HandleRemoveAccount} onSave={HandleSaveAutoOrderAccount} onChange={SetEdittingAccount} />}
    </div>
  );
};