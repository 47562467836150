import { Spin } from 'antd';
import { useState } from 'react';
import '../../sass/settings/settings.scss';
import { useTranslation } from '../../utils/transShim';
import { Selector, SelectorValue } from '../form/selector';

export interface ListData {
  value: string | null;
  label: React.ReactNode;
}

interface Props {
  defaultValue?: string;
  value?: string;
  loading?: boolean;//saving
  loadingData?: boolean;
  onChange?: (value: string | null) => void;
  disabled?: boolean;
  listData: ListData[];
  placeholder?: string;
  showSearch?: boolean;
}

export const SettingList = (props: Props) => {
  const { defaultValue, value, onChange, loadingData, disabled, listData, loading, placeholder, showSearch } = props;

  const [_cv, setCurrentValue] = useState<string | undefined>(defaultValue);
  const currentValue = value ?? _cv;
  const [wasLoadingData, setWasLoadingData] = useState<boolean>(loadingData ?? false);

  const OnChange = (value: SelectorValue) => {
    setCurrentValue(value as string);
    onChange?.(value as string);
  };

  if (wasLoadingData && !loadingData) {
    setWasLoadingData(false);
    setCurrentValue(defaultValue);
  }

  const loadingT = loading || loadingData;

  const tagPlaceHolder = useTranslation('Setting.Select') as string;//If we don't do here, if placeholder cahnges from null to non null it react will cry

  return <div className="setting setting-list">
    <Selector
      value={currentValue}
      disabled={disabled || loadingT || loadingData}
      onChange={OnChange}
      loading={loadingData}
      size="large"
      placeholder={loadingT ? '' : placeholder ?? tagPlaceHolder}
      key={loadingData?.toString()}
      showSearch={showSearch}
    >
      {listData}
    </Selector>
    {(loadingT || loadingData) && <Spin/>}
  </div>;
};