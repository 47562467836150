import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../client';
import { CheckNewFilterResponse } from './data/check-new-filter-response';
import { GetFiltersResponse } from './data/get-filters-response';
import { GetHistoryRequest } from './data/get-history-request';
import { GetHistoryResponse } from './data/get-history-response';
import { NewFiltersRequest } from './data/new-filters-request';
import { NewFiltersResponse } from './data/new-filters-response';
import { SmartListerFiltersChangeStatusRequest } from './data/smart-lister-filters-change-status-request';
import { SmartListerFiltersGetRequest } from './data/smart-lister-filters-get-request';

export const SmartListerFiltersGet = createAsyncThunk('SmartLister/Filters/Get', async (rq: SmartListerFiltersGetRequest , thunkAPI) => {
  try {
    const res = await client.post('/SmartLister/Filters/Get', rq);
    return res.data.response_data as GetFiltersResponse;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});

export const SmartListerFiltersNew = createAsyncThunk('SmartLister/Filters/New', async (rq: NewFiltersRequest, thunkAPI) => {
  try {
    const res = await client.post('/SmartLister/Filters/New', rq);
    return res.data.response_data as NewFiltersResponse;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});

export const SmartListerCheckNew = createAsyncThunk('SmartLister/Filters/CheckNew', async (rq: NewFiltersRequest, thunkAPI) => {
  try {
    const res = await client.post('/SmartLister/Filters/CheckNew', rq);
    return res.data.response_data as CheckNewFilterResponse;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});

export const SmartListerFiltersChangeStatus = createAsyncThunk('SmartLister/Filters/ChangeStatus', async (rq: SmartListerFiltersChangeStatusRequest, thunkAPI) => {
  try {
    const res = await client.post('/SmartLister/Filters/ChangeStatus', rq);
    return res.data.response_data as { success: boolean };
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});

export const SmartListerHistoryGet = createAsyncThunk('SmartLister/History/Get', async (rq: GetHistoryRequest, thunkAPI) => {
  try {
    const res = await client.post('/SmartLister/History/Get', rq);
    return res.data.response_data as GetHistoryResponse;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});