export const SuperLogout = (redirect = true) => {
  //const keysToRemove = ['root', 'Authorization', 'globalTheme', 'isAuthenticated', 'initialDate'];
  //keysToRemove.forEach((key) => localStorage.removeItem(key));
  const keysTokeep = ['UI.Preferences.', 'referral', 'referralDate', 'UI.Closed_'];
  const dataToKeep: { k: string, v: string }[] = [];
  for (let i = 0, len = localStorage.length; i < len; ++i) {

    const key = localStorage.key(i);
    if (!key)
      continue;

    for (const k of keysTokeep) {
      if (!key.startsWith(k))
        continue;

      const value = localStorage.getItem(key);
      if (!value)
        break;

      dataToKeep.push({ k: key, v: value });
      break;
    }
  }
  localStorage.clear();

  for (const kv of dataToKeep) {
    localStorage.setItem(kv.k, kv.v);
  }

  if (redirect)
    Response.redirect('/login');
};