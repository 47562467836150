import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import '../sass/small-components/help-tooltip.scss';

interface props {
  children: React.ReactNode;
  mode?: 'help' |'warning';
}

export const HelpTooltip = (props: props) => {
  const { children, mode } = props;

  const icon = () => {
    switch (mode) {
      default:
        return <QuestionCircleOutlined style={{ fontSize: '19px' }} />;
      case 'warning':
        return <ExclamationCircleOutlined style={{ fontSize: '19px' }} />;
    }
  };

  return <Tooltip title={children} className='help-tooltip' overlayClassName='help-tooltip-tooltip'>
    {icon()}
  </Tooltip>;

};