import { Button } from 'antd';

interface Props {
  errorMessage: string
}

export const PendingErrorCell = (props: Props) => {
  const { errorMessage } = props;

  if (!errorMessage)
    return <></>;

  return (
    <>
      <span className='errorF'>
        <span className='errorFM'>{errorMessage}</span>
      </span>
      <br />
      <Button className='progress-btn'>
        Click for details and how to fix it
      </Button>
    </>
  );
};