import '../../sass/subscriptions/subscriptions.scss';
import errorLogo from '../../assets/error.svg';
import { PrimaryBtn } from 'src/small-components/ActionBtns';
import { Links } from 'src/links';
import { A } from '../../small-components/A';


export const Error = () => {
  return (
    <div className="payment-container">
      <div className="payment-box">
        <img className="payment-logo" src={errorLogo} alt="" />
        <div className="box-logo">
          <h1 className="title-payment">Oops! something went wrong</h1>
          <h5 className="description-payment">There was a problem creating the payment correctly, please try again.</h5>
          <div className="button-container">
            <A to={Links.Subscriptions}>
              <PrimaryBtn className="payment-button">Try again</PrimaryBtn>
            </A>
          </div>
        </div>
      </div>
    </div>
  );
};
