import { createSlice } from '@reduxjs/toolkit';
import { getNotifications, NotificationList } from './notificationsThunk';

export type NotificationsState = {
  notifications: NotificationList;
  loading: boolean;
  error: string;
}

const initialState: NotificationsState = {
  notifications: {},
  loading: false,
  error: ''
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getNotifications.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.notifications = payload;
    });
    builder.addCase(getNotifications.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = String(payload);
    });
  }
});

export const { reducer: notificationsReducer } = notificationsSlice;
