import { Progress } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { StoreList } from '../../small-components/StoreList';
import Logo from '../../assets/logoHGR.png';
import { T } from 'src/utils/transShim';
import { useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import '../../sass/top-bar.scss';
import { Links } from '../../links';
import { ReactUtils } from '../../utils/react-utils';
import { Notifications } from './Notifications';
import { Tokens } from '../../small-components/Tokens';
import { HelpChatButton } from './help-chat-button';
import { ChatContainerActionsRef } from '../../small-components/chat-container';
import { PrimaryBtn } from '../../small-components/ActionBtns';
import { A } from '../../small-components/A';

interface Props extends RouteComponentProps {
  showMobileSider: () => void;

  chatEnabled: boolean;
  chatActions: ChatContainerActionsRef;
}

export const Topbar = withRouter((props: Props) => {
  const { showMobileSider, history, chatEnabled, chatActions } = props;

  const { tokens, quota } = useAppSelector((state) => state.user);
  const isImpersonated = ReactUtils.stringToBoolean(localStorage.getItem('isImpersonated'));

  const routeChange = (route: string) => history.push(route);

  const qoutaPercentage = (partial: number, total: number) => {
    return Math.round((100 * partial) / total);
  };

  return (
    <div className="top-bar">
      <div className="logo-container">
        <A className="logo-link" to="/">
          <img className="logo" src={Logo} alt="logo" />
        </A>
      </div>

      <div className="menu-burger" onClick={showMobileSider}>
        <span className="first"></span>
        <span className="second"></span>
        <span className="thrid"></span>
      </div>
      <div className="quota-container-topbar">
        <div className="quota-container">
          <div className="quota">
            <strong className="quota-text">
              <T k="Topbar.Quota" />:
            </strong>
            <span className="quota-progress">
              {quota && (
                <>
                  {quota.quota == 0 ? '' : qoutaPercentage(quota.used, quota.quota) + '%'} ({quota.used}/{quota.quota})
                </>
              )}
            </span>
          </div>
          {quota && <Progress percent={qoutaPercentage(quota.used, quota.quota)} showInfo={false} className="progress-bar" />}
          <PrimaryBtn onClick={() => routeChange(Links.Subscriptions)} className="upgrade-btn"><T k="Topbar.Upgrade" /></PrimaryBtn>
        </div>
      </div>
      {isImpersonated && (
        <div className="top-bar-item">
          <A to={Links.UnImpersonate}>UnImpersonate</A>
        </div>
      )}
      <div className="top-bar-item"></div>
      <HelpChatButton chatEnabled={chatEnabled} chatActions={chatActions} />
      <Tokens tokens={tokens ?? 0} includeText={false} />
      <Notifications />
      <StoreList />
    </div>
  );
});
