import { createSlice } from '@reduxjs/toolkit';
import { ClaimPrize, PendingPrizesResponse, TaskPrizes } from './prizesThunk';

export type PendingPrizesResponseS = {
  data?: PendingPrizesResponse,
  loading: boolean,
  error?: string
}
export type PrizesSliceState= {
  available?: PendingPrizesResponseS;
  claiming?: {
    [id: number]: { loading: boolean, done?: boolean, error?: string }
  }
}

const initialState: PrizesSliceState = {};

export const prizesSlice = createSlice({
  name: 'prizes',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Prizes availables
    builder.addCase(TaskPrizes.pending, (state) => {
      state.available = {
        loading: true
      };
    });
    builder.addCase(TaskPrizes.fulfilled, (state, { payload }) => {
      state.available = {
        loading: false,
        data: payload
      };
    });
    builder.addCase(TaskPrizes.rejected, (state, { payload }) => {
      state.available = {
        loading: false,
        error: String(payload)
      };
    });
    //Claiming prize
    builder.addCase(ClaimPrize.pending, (state, { meta }) => {
      if (!state.claiming)
        state.claiming = {};
      state.claiming[meta.arg.id] = {
        loading: true
      };
    });
    builder.addCase(ClaimPrize.fulfilled, (state, { payload, meta }) => {
      if (!state.claiming)
        state.claiming = {};
      state.claiming[meta.arg.id] = {
        loading: false,
        done: payload?.success ?? false
      };
    });
    builder.addCase(ClaimPrize.rejected, (state, { payload, meta }) => {
      if (!state.claiming)
        state.claiming = {};
      state.claiming[meta.arg.id] = {
        loading: false,
        error: String(payload)
      };
    });
  }
});

export const { reducer: prizesReducer } = prizesSlice;