import { ProductInChannelData } from './product-in-channel-data';
import { SelectedProductContent } from './selected-product-content';

export interface SelectedProductPanelProps {
  product?: ProductInChannelData;
  editProductInChannel: boolean;
  OnEditProductInChannelChange?: (editing: boolean) => void;
}

export const SelectedProductPanel = (props: SelectedProductPanelProps) => {

  const prd = props.product;
  return (
    <div className="productInChannel panel panel-primary">
      <div className="panel-heading">
        <h3 className="panel-title">Selected Product</h3>
      </div>
      {prd && (
        <SelectedProductContent
          editProductInChannel={props.editProductInChannel}
          OnEditProductInChannelChange={props.OnEditProductInChannelChange}
          product={prd}
        />
      )}
    </div>
  );
};
