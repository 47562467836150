import aus_flag from '../../../assets/channel/flags/AU.png';
import ger_flag from '../../../assets/channel/flags/DE.png';
import sp_flag from '../../../assets/channel/flags/ES.png';
import fr_flag from '../../../assets/channel/flags/FR.png';
import it_flag from '../../../assets/channel/flags/IT.png';
import uk_flag from '../../../assets/channel/flags/UK.png';
import us_flag from '../../../assets/channel/flags/US.png';
import { eCountry } from '../../../data/countries';
import { HelpTooltip } from '../../../small-components/help-tooltip';
import { T } from '../../../utils/transShim';
import { eShop } from './eShop';

import { Flag } from './flag';

export interface StoreLocationProps {
  shop: eShop;
  step: number;
  onChangeLocation: (newLocation: eCountry) => void;
  selectedLocation: eCountry | null;
}

const allFlags = [
  {
    name: 'UK',
    path: uk_flag,
    slug: 'uk',
    code: eCountry.UK,
    suppliers: 70
  },
  {
    name: 'USA',
    path: us_flag,
    slug: 'us',
    code: eCountry.US,
    suppliers: 20
  },
  {
    name: 'Spain',
    path: sp_flag,
    slug: 'es',
    code: eCountry.ES
  },
  {
    name: 'France',
    path: fr_flag,
    slug: 'fr',
    code: eCountry.FR
  },
  {
    name: 'Germany',
    path: ger_flag,
    slug: 'de',
    code: eCountry.DE
  },
  {
    name: 'Australia',
    path: aus_flag,
    slug: 'au',
    code: eCountry.AU
  },
  {
    name: 'Italy',
    path: it_flag,
    slug: 'it',
    code: eCountry.IT
  }
];

export const StoreLocation = (props: StoreLocationProps) => {
  const { shop, onChangeLocation } = props;

  const FlagFilter = (
    x: {
      code: eCountry
    }
  ) => {
    switch (shop) {
      case eShop.Amazon:
      case eShop.TikTok:
        return x.code == eCountry.UK || x.code == eCountry.US;
      default:
        return true;
    }
  };
  const flags = allFlags.filter(FlagFilter);

  return (
    <div className="container-info">
      <form className="location-form">
        <h2 className="title">
          <T k="NewChannel.Country.Title" />
          <HelpTooltip>
            <T k="NewChannel.Country.Help" />
          </HelpTooltip>
        </h2>
        <div className={(flags.length > 2 ? 'flags' : 'flags flags2')}>
          {flags.map((i) => (
            <Flag
              key={i.name}
              flag={i.path}
              name={i.name}
              code={i.code}
              suppliers={i.suppliers}
              onChangeLocation={onChangeLocation}
            />
          ))}
        </div>
      </form>
    </div>
  );
};
