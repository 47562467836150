import { createSlice } from '@reduxjs/toolkit';
import { eSmartListerFilterStatus } from './data/e-smart-lister-filter-status';
import { GetFiltersResponse } from './data/get-filters-response';
import { GetHistoryResponse } from './data/get-history-response';
import { GetHistoryResponseListing } from './data/get-history-response-listing';
import { NewFiltersResponse } from './data/new-filters-response';
import { SmartListerFilter } from './data/smart-lister-filter';
import { SmartListerFiltersChangeStatus, SmartListerFiltersGet, SmartListerFiltersNew, SmartListerHistoryGet } from './smart-thunk';


export interface SmartListerState {
  filters?: {
    loading: boolean;
    data?: SmartListerFilter[];
    saving: boolean;
    updating: { [id: number]: boolean };
  };
  history?: {
    [id: number]: {
      loading: boolean;
      data?: GetHistoryResponseListing[];
    }
  };
}

const filtersDefault = {
  loading: false,
  saving: false,
  updating: {}
};
const historyDefault = {};
const historyElDefault = { loading: false };

const initialState: SmartListerState = {

};


export const smartListerSlice = createSlice({
  name: 'sales',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {

    //Get Filters
    builder.addCase(SmartListerFiltersGet.pending, (state) => {
      state.filters = state.filters ?? filtersDefault;
      state.filters.loading = true;
    });
    builder.addCase(SmartListerFiltersGet.fulfilled, (state, { payload }) => {
      state.filters = state.filters ?? filtersDefault;
      state.filters.loading = false;
      state.filters.data = (payload as GetFiltersResponse)?.filters;
    });
    builder.addCase(SmartListerFiltersGet.rejected, (state) => {
      state.filters = state.filters ?? filtersDefault;
      state.filters.loading = false;
    });

    //New Filters
    builder.addCase(SmartListerFiltersNew.pending, (state) => {
      state.filters = state.filters ?? filtersDefault;
      state.filters.saving = true;
    });
    builder.addCase(SmartListerFiltersNew.fulfilled, (state, { payload, meta }) => {
      state.filters = state.filters ?? filtersDefault;
      state.filters.data = state.filters.data ?? [];
      state.filters.saving = false;

      if ((payload as NewFiltersResponse)?.id != 0)
        state.filters.data.push({
          ...meta.arg.filter,
          id: (payload as NewFiltersResponse)?.id
        });
    });
    builder.addCase(SmartListerFiltersNew.rejected, (state) => {
      state.filters = state.filters ?? filtersDefault;
      state.filters.saving = false;
    });

    //Change Status
    builder.addCase(SmartListerFiltersChangeStatus.pending, (state, { meta }) => {
      state.filters = state.filters ?? filtersDefault;
      state.filters.updating = state.filters.updating ?? {};
      state.filters.updating[meta.arg.id] = true;
    });
    builder.addCase(SmartListerFiltersChangeStatus.fulfilled, (state, { payload, meta }) => {
      state.filters = state.filters ?? filtersDefault;
      state.filters.updating = state.filters.updating ?? {};
      state.filters.data = state.filters.data ?? [];

      state.filters.updating[meta.arg.id] = false;
      if ((payload as { success: boolean })?.success) {
        if (meta.arg.status == eSmartListerFilterStatus.Archived) {
          state.filters.data = state.filters.data.filter(x => x.id != meta.arg.id);
        } else {
          const g = state.filters.data.find(x => x.id == meta.arg.id);
          if (g) {
            g.status = meta.arg.status;
          }
        }
      }
    });
    builder.addCase(SmartListerFiltersChangeStatus.rejected, (state, { meta }) => {
      state.filters = state.filters ?? filtersDefault;
      state.filters.updating = state.filters.updating ?? {};
      state.filters.updating[meta.arg.id] = false;
    });

    //History
    builder.addCase(SmartListerHistoryGet.pending, (state, { meta }) => {
      state.history = state.history ?? historyDefault;
      state.history[meta.arg.id] = state.history[meta.arg.id] ?? historyElDefault;
      state.history[meta.arg.id].loading = true;
    });
    builder.addCase(SmartListerHistoryGet.fulfilled, (state, { payload, meta }) => {
      state.history = state.history ?? historyDefault;
      state.history[meta.arg.id] = state.history[meta.arg.id] ?? historyElDefault;
      state.history[meta.arg.id].loading = false;
      state.history[meta.arg.id].data = (payload as GetHistoryResponse)?.listings;
    });
    builder.addCase(SmartListerHistoryGet.rejected, (state, { meta }) => {
      state.history = state.history ?? historyDefault;
      state.history[meta.arg.id] = state.history[meta.arg.id] ?? historyElDefault;
      state.history[meta.arg.id].loading = false;
    });
  }
});

export const { reducer: smartListerReducer } = smartListerSlice;
