import { CategoryScale, Chart, LinearScale, LineElement, PointElement, Tooltip, Filler, Decimation, ScriptableContext } from 'chart.js';
import { Line } from 'react-chartjs-2';
Chart.register(LineElement, PointElement, LinearScale, Tooltip, CategoryScale, Filler, Decimation);

export type ChartAreaProps = {
  className?: string,
  data: { y: number, x: string, [key: string]: unknown }[],
  color?: string,
  tooltip?: {
    label?: string
  } | false,
};

export const ChartArea = (props: ChartAreaProps) => {
  const { className, data, color, tooltip } = props;

  //const minValue = Math.min(...data?.map(v => v.y ?? 0));
  //const maxValue = Math.max(...data?.map(v => v.y ?? 0));
  //const empty = data.length == null || data.length == 0;

  //const difValue = (() => {
  //  const v = maxValue - minValue;
  //  if (v == null || typeof v !== 'number' || isNaN(v) || !isFinite(v))
  //    return 0;
  //  return v;
  //})();

  //ChartData<'line', (number | ScatterDataPoint | null)[], unknown>

  const HexToRGBA = (hex: string, alpha = 0.5) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const config = {
    data: {
      labels: data.map(v => v.x),
      datasets: [{
        data: data.map(v => v.y),
        label: '',
        borderColor: color,
        backgroundColor: (context: ScriptableContext<'line'>) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, context.chart.height);
          gradient.addColorStop(0, HexToRGBA(color ?? '#00ff00', 1));
          gradient.addColorStop(1, HexToRGBA(color ?? '#00ff00', 0));
          return gradient;
        },
        fill: 'start',
        tension: 0.4
      }]
    },
    options: {
      plugins: {
        tooltip: {
          enabled: !!tooltip,
          displayColors: false,
          callbacks: (tooltip !== false) ? {
            title: () => '',
            label: (t: { datasetIndex: number, dataIndex: number }) => (tooltip?.label ?? '') + ' ' + data[t.dataIndex].y
          } : undefined
        }
      }
    }
    //xField: 'x',
    //yField: 'y',
    //style: {
    //  fill: 'linear-gradient(-90deg, white 0%, ' + (color ?? 'darkgreen') + ' 100%)',
    //  opacity: empty ? 0 : 1
    //},
    //axis: {
    //  y: {
    //    tickCount: Math.max(1,Math.min(4, Math.ceil(difValue))),
    //    labelFormatter: y?.labelFormatter ?? (difValue < 10 ? '.1f' : '.0f'),
    //    min: minValue,
    //    max: empty ? 1 : maxValue,
    //    labelOpacity: y?.labelOpacity
    //  },
    //  x: {
    //    labelAutoRotate: false,
    //    labelFormatter: x?.labelFormatter,
    //    labelOpacity: x?.labelOpacity ?? (empty ? 0 : 1)
    //  }
    //},
    //line: {
    //  shapeField: 'smooth',
    //  style: {
    //    stroke: color ?? 'darkgreen',
    //    strokeWidth: 5,
    //    opacity: empty ? 0 : 1,
    //    ...(line?.style ?? {}),
    //  },
    //  tooltip: false
    //},
    //tooltip: tooltip === false ? false : empty ? false : (v: { y: number }) => ({ name: tooltip?.y?.label ?? 'value', value: v.y })
  };
  return <Line className={className} {...config} />;

};