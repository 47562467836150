import { BulkListing } from '../../list-now/bulk-listing';

interface props {
  urls: string[];
}

export const NewListingBulkLinks = (props: props) => {
  const { urls } = props;

  return <BulkListing urls={urls} />;
};