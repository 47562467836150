import { Button, Modal, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../../custom-hooks/reduxCustomHooks';
import { eChannelListingHistoryAction, GetStockChanges } from '../../../../../redux/listings/listings-thunk';
import { SimpleTable } from '../../../../../small-components/tables/simple-table';
import { ReactUtils } from '../../../../../utils/react-utils';
import { T } from '../../../../../utils/transShim';

type Props = {
  listingId: number;
  visible: boolean;
  onClose: () => void;
}
export const StockChangesModal = (props: Props) => {
  const { listingId, visible, onClose } = props;
  const { stockChanges } = useAppSelector((state) => state.listings);

  const dispatch = useDispatch();
  if (!visible)
    return <Modal open={false}></Modal>;

  if (stockChanges?.loading) {
    return <Modal
      onOk={onClose}
      onCancel={onClose}
      open={visible}>
      <Spin />
    </Modal>;
  }

  if (stockChanges?.listingId != listingId) {
    dispatch(GetStockChanges(listingId));
    return <Modal
      onOk={onClose}
      onCancel={onClose}
      open={visible}><Spin /></Modal>;
  }

  const RenderStatus = (value: eChannelListingHistoryAction) => {
    if (value == eChannelListingHistoryAction.MonitorInStock) {
      return <span style={{ color: 'forestgreen' }}><T k='Listings.BackStock' /></span>;
    } else {
      return <span style={{ color: 'red' }}><T k='Listings.OutOfStock' /></span>;
    }
  };

  const columns = [
    {
      title: <T k='Listings.Date' />,
      dataIndex: 'date',
      render: (date: string | Date) => ReactUtils.GetFormattedDateTime(date)
    },
    {
      title: <T k='Listings.StockStatus' />,
      dataIndex: 'action',
      render: RenderStatus
    }
  ];

  //id: number;
  //channelListing: number;
  //action: number;
  //date: string | Date;
  //oldValue ?: string;
  //newValue ?: string;
  //extra ?: string;

  return <Modal
    onOk={onClose}
    onCancel={onClose}
    open={visible}
    footer={<Button key='submit' type='primary' onClick={() => onClose()}><T k='Button.Close' /></Button>}
  >
    <SimpleTable
      columns={columns}
      dataSource={stockChanges?.history ?? []}
    />
  </Modal>;
};