import { Alert, Col, List } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../custom-hooks/reduxCustomHooks';
import { Links } from '../../../links';
import { TaskPendingAutoOrder } from '../../../redux/auto-ordering/autoOrderingThunk';
import { Prizes, PrizeType, TaskPrizes } from '../../../redux/dashboard/prizesThunk';
import { A } from '../../../small-components/A';
import { T } from '../../../utils/transShim';
import { TokenTask } from './tasks/token-task';

export const TasksPanel = () => {
  const dispatch = useAppDispatch();
  const { available } = useAppSelector((state) => state.prizes);
  useEffect(() => {
    if (!(available?.loading ?? false)) {
      dispatch(TaskPendingAutoOrder());
      dispatch(TaskPrizes());
    }
  }, []);

  const RenderFreeQuota = () => {
    return (
      <A to={Links.Subscriptions}>
        <Alert message={<T k="Dashboard.Tasks.FreeQuota" />} type="error" />
      </A>
    );
  };
  const RenderSubscriptionEnding = () => {
    return (
      <A to={Links.Subscriptions}>
        <Alert message={<T k="Dashboard.Tasks.Cancelled" />} type="error" />
      </A>
    );
  };
  const RenderAutoOrder = () => {
    return (
      <A to={Links.Configuration.AutoOrdering.Main}>
        <Alert message={<T k="Dashboard.Tasks.Autoorder" />} type="error" />
      </A>
    );
  };
  const RenderFeedback = () => {
    return (
      <A to={Links.FeedbackForm} target="_blank" rel="noreferrer">
        <Alert message={<T k="Dashboard.Tasks.Feedback" />} type="warning" />
      </A>
    );
  };
  const RenderPrizes = (p: Prizes) => {
    switch (p.type) {
      case PrizeType.TOKENS:
        return <Alert message={<TokenTask prize={p} />} type="success" />;
      default:
        return <></>;
    }
  };

  const Empty = () => <></>;
  const RenderRow = (x: React.ReactNode) => <List.Item>{x}</List.Item>;

  const data: React.ReactNode[] = [];
  (() => {
    const { quota } = useAppSelector((state) => state.user);
    if (quota != null) {
      const mendsOn = quota != null ? moment(quota.endsOn) : moment.utc();
      if (quota.quota <= 10) {
        data.push(RenderFreeQuota());
      } else if (quota.cancelled && mendsOn.diff(moment.utc(), 'days') < 7) {
        data.push(RenderSubscriptionEnding());
      }
    }

    const { taskPendingAutoOrder } = useAppSelector((state) => state.autoOrdering);
    if (taskPendingAutoOrder?.data?.listingsWithSourcesNotSet) {
      data.push(RenderAutoOrder());
    }

    data.push(RenderFeedback());

    const { available } = useAppSelector((state) => state.prizes);
    if (available?.data?.prizes) {
      for (const p of available?.data?.prizes) data.push(RenderPrizes(p));
    }
  })();

  return (
    <Col className="white-box tasks" xs={24} lg={10}>
      <h3>
        <T k="Dashboard.Tasks.Title" />
        {data.length > 0 && <span className="alert"> ({data.length})</span>}
      </h3>
      <List locale={{ emptyText: Empty() }} dataSource={data} renderItem={RenderRow} />
    </Col>
  );
};
