import { useState } from 'react';
import { T } from '../../../../../utils/transShim';
import { EditSingleListingMain } from './edit-single-listing-main';
import '../../../../../sass/edit-single-listing.scss';
import { CheckIcon, RefreshIcon, TrashIcon } from '../../../../common/Icons';
import { ActiveListingExtended } from '../../types';
import { DeleteBtn, SuccessBtn, WarningBtn } from '../../../../../small-components/ActionBtns';
import { ePlatform } from '../../../../../data/platforms';
import { useDispatch } from 'react-redux';
import { ForceRefresh, SaveListingChanges, SaveProductChangesRequest, TerminateListing } from '../../../../../redux/listings/listings-thunk';
import { useAppSelector } from '../../../../../custom-hooks/reduxCustomHooks';
import { LoadingModal } from '../../../../../small-components/modals/loading-modal';
import { ListingDetails } from './edit-single-listing-details';
import { ListingOptions } from './edit-single-listing-options';
import { ListingBusiness } from './edit-single-listing-business';
import { QuestionModal } from '../../../../../small-components/modals/question-modal';
import { PriceChangesModal } from './price-changes-modal';
import { StockChangesModal } from './stock-changes-modal';
import { OptimiseModal } from '../../../../../small-components/modals/optimise-modal';
import { Tabs } from 'antd';
import { A } from '../../../../../small-components/A';


interface Props {
  platform: ePlatform | undefined;
  listing?: ActiveListingExtended | null;
  onClose: () => void;
}

enum Tab {
  Main, Description, Details, Options,
  Business//This was avaialble only for eBay API, but not for ebay no api
}

export const EditSingleListing = (props: Props) => {
  const { listing, platform, onClose } = props;

  if (listing == null)
    return <></>;

  const settings = listing.sourceSettings;

  const [activeTab, SetActiveTab] = useState<number>(Tab.Main);
  const [viewPriceChanges, SetViewPriceChanges] = useState<boolean>(false);
  const [viewStockChanges, SetViewStockChanges] = useState<boolean>(false);
  const dispatch = useDispatch();

  //Changes------------------------------------------------------------------------------------------------
  const { saving } = useAppSelector((state) => state.listings);

  const [savingChanges, SetSavingChanges] = useState<boolean>(false);
  const [terminating, SetTerminating] = useState<boolean>(false);
  const [confirmatingTerminate, SetConfirmatingTerminate] = useState<boolean>(false);
  const [refreshing, SetRefreshing] = useState<boolean>(false);
  //Main------------
  const [newMarkup, SetNewMarkup] = useState<number | undefined>(listing.overrides.markup);
  const [newQuantity, SetNewQuantity] = useState<number>(listing.channelQuantity);
  const [newNotes, SetNewNotes] = useState<string>(listing.productNotes);
  const [newTitle, SetNewTitle] = useState<string>(listing.title);
  const [newPrice, SetNewPrice] = useState<number>(listing.channelPrice);
  //Options----------
  const [newMonitorStock, SetNewMonitorStock] = useState<boolean | undefined>(listing.overrides.monitorStock);
  const [newMonitorPrice, SetNewMonitorPrice] = useState<boolean | undefined>(listing.overrides.monitorPrice);
  const [newIgnoreRules, SetNewIgnoreRules] = useState<boolean | undefined>(listing.overrides.ignoreRules);
  const [newMonitorPriceDecrease, SetMonitorPriceDecrease] = useState<boolean | undefined>(listing.overrides.monitorPriceDecrease);
  const [newPriceDecreasePercentage, SetNewPriceDecreasePercentage] = useState<number | undefined>(listing.overrides.monitorPriceDecreasePercentage);
  const [newMinQuantity, SetNewMinQuantity] = useState<number | undefined>(listing.overrides.minQuantity);
  const [newOnlyPrime, SetNewOnlyPrime] = useState<boolean | undefined>(listing.overrides.primeOnly);
  const [newDispatchDays, SetDispatchDays] = useState<number | undefined>(listing.overrides.dispatchDays);
  //Business policies-------
  const [paymentProfileId, SetPaymentProfileId] = useState<number | undefined>(listing.overrides.paymentProfileId);
  const [shippingProfileId, SetShippingProfileId] = useState<number | undefined>(listing.overrides.shippingProfileId);
  const [returnProfileId, SetReturnProfileId] = useState<number | undefined>(listing.overrides.returnProfileId);
  //Details---------------
  const originalSourceUrl = 'https://' + (listing.source?.baseUrl ?? '') + '/' + listing.path;
  const [sourceUrl, SetSourceUrl] = useState<string>(originalSourceUrl);

  //Main--------------------
  const changedMarkup = newMarkup != listing.overrides.markup;
  const changedQuantity = newQuantity != listing.channelQuantity;
  const changedNotes = newNotes != listing.productNotes;
  const changedTitle = newTitle != listing.title;
  const changedPrice = newPrice != listing.channelPrice;
  //Options-----------------
  const changedMonitorStock = newMonitorStock != listing.overrides.monitorStock;
  const changedMonitorPrice = newMonitorPrice != listing.overrides.monitorPrice;
  const changedIgnoreRules = newIgnoreRules != listing.overrides.ignoreRules;
  const changedMonitorPriceDecrase = newMonitorPriceDecrease != listing.overrides.monitorPriceDecrease;
  const changedPriceDecrasePercentage = newPriceDecreasePercentage != listing.overrides.monitorPriceDecreasePercentage;
  const changedMinQuantity = newMinQuantity != listing.overrides.minQuantity;
  const changedOnlyPrime = newOnlyPrime != listing.overrides.primeOnly;
  const changedDispatchDays = newDispatchDays != listing.overrides.dispatchDays;
  //Business policies-------
  const changedPaymentProfileId = paymentProfileId != listing.overrides.paymentProfileId;
  const changedShippingProfileId = shippingProfileId != listing.overrides.shippingProfileId;
  const changedReturnProfileId = returnProfileId != listing.overrides.returnProfileId;
  //Details---------------
  const changedSourceUrl = sourceUrl != originalSourceUrl;


  const somethingToChange =
    changedMarkup || changedQuantity || changedNotes || changedTitle || changedPrice
    || changedMonitorStock || changedMonitorPrice || changedIgnoreRules || changedMonitorPriceDecrase || changedPriceDecrasePercentage || changedMinQuantity || changedOnlyPrime || changedDispatchDays
    || changedPaymentProfileId || changedShippingProfileId || changedReturnProfileId
    || changedSourceUrl
    ;

  const HandleSave = () => {
    const changes: SaveProductChangesRequest = {
      //Main
      channelListingId: listing.channelListingId,
      markup: changedMarkup ? { value: newMarkup } : undefined,
      quantity: changedQuantity ? newQuantity : undefined,
      notes: changedNotes ? newNotes : undefined,
      title: changedTitle ? newTitle : undefined,
      price: changedPrice ? newPrice : undefined,
      //Options
      monitorStock: changedMonitorStock ? { value: newMonitorStock } : undefined,
      monitorPrice: changedMonitorPrice ? { value: newMonitorPrice } : undefined,
      ignoreRules: changedIgnoreRules ? { value: newIgnoreRules } : undefined,
      monitorPriceDecrease: changedMonitorPriceDecrase ? { value: newMonitorPriceDecrease } : undefined,
      priceDecreasePercentage: changedPriceDecrasePercentage ? { value: newPriceDecreasePercentage } : undefined,
      minQuantity: changedMinQuantity ? { value: newMinQuantity } : undefined,
      primeOnly: changedOnlyPrime ? { value: newOnlyPrime } : undefined,
      dispatchTimeDays: changedDispatchDays ? { value: newDispatchDays } : undefined,
      //Business policies
      shippingPolicy: changedShippingProfileId ? { value: shippingProfileId } : undefined,
      returnPolicy: changedReturnProfileId ? { value: returnProfileId } : undefined,
      paymentPolicy: changedPaymentProfileId ? { value: paymentProfileId } : undefined,
      //Details
      sourceUrl: changedSourceUrl ? sourceUrl : undefined
    };
    dispatch(SaveListingChanges(changes));
    SetSavingChanges(true);
  };

  const HandleTerminate = () => SetConfirmatingTerminate(true);
  const HandleTerminateConfirmed = () => {
    SetConfirmatingTerminate(false);
    SetTerminating(true);
    dispatch(TerminateListing({ id: listing.channelListingId }));
  };
  const HandleTerminatedCanceled = () => SetConfirmatingTerminate(false);
  const HandleRefresh = () => {
    dispatch(ForceRefresh({
      ids: [listing.channelListingId]
    }));
    SetRefreshing(true);
  };
  const HandleViewPriceChanges = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    SetViewPriceChanges(true);
    e.preventDefault();
    e.stopPropagation();
  };
  const HandleViewStockChanges = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    SetViewStockChanges(true);
    e.preventDefault();
    e.stopPropagation();
  };

  //-------------------------------------------------------------------------------------------------------
  const RenderContent = (index: Tab): React.ReactNode => {
    if (!settings)//This should never happen
      return <>Error loading settings</>;
    switch (index) {
      case Tab.Main:
        return <EditSingleListingMain
          platform={platform}

          channelPrice={listing.channelPrice}
          currentMarkup={listing.markup}
          currentProfit={listing.profit}
          ending99={settings.ending99}
          feePercentage={settings.feePercentage}
          settingsMarkup={settings.markup}
          sourcePrice={listing.sourcePrice}

          valueQuantity={newQuantity}
          valueNotes={newNotes}
          valueMarkup={newMarkup}
          valueTitle={newTitle}
          valuePrice={newPrice}

          onMarkupChange={SetNewMarkup}
          onNotesChange={SetNewNotes}
          onQuantityChange={SetNewQuantity}
          onTitleChange={SetNewTitle}
          onPriceChange={SetNewPrice}
        />;
      //case Tab.Description:
      //  return <ListingDescription />;
      case Tab.Details:
        return <ListingDetails
          asin={listing.asin}
          channel={listing.channel}
          channelItem={listing.channelItem}
          createdByName={listing.createdByName}
          createdOn={listing.createdOn}
          listingId={listing.channelListingId}
          updatedOn={listing.updatedOn}
          originalSourceUrl={originalSourceUrl}
          valueSourceUrl={sourceUrl}
          onChangeSourceUrl={SetSourceUrl}
        />;
      case Tab.Options:
        return <ListingOptions
          sourceId={listing.sourceId}
          platform={listing.channel?.channelId ?? ePlatform.eBay}

          valueMonitorStock={newMonitorStock}
          valueMonitorPrice={newMonitorPrice}
          valueIgnoreRules={newIgnoreRules}
          valueMonitorPriceDecrease={newMonitorPriceDecrease}
          valuePriceDecreasePercentage={newPriceDecreasePercentage}
          valueMinQuantity={newMinQuantity}
          valueOnlyPrime={newOnlyPrime}
          valueDispatchDays={newDispatchDays}

          settingMonitorStock={settings.monitorStock}
          settingMonitorPrice={settings.monitorPrice}
          settingMonitorPriceDecrase={settings.monitorPriceDecrease}
          settingPriceDecreasePercentage={settings.monitorPriceDecreasePercentage}
          settingMinQuantity={settings.minQuantity}
          settingOnlyPrime={settings.primeOnly}
          settingDispatchDays={settings.dispatchDays ?? 0}

          onChangeIgnoreRules={SetNewIgnoreRules}
          onChangeMinQuantity={SetNewMinQuantity}
          onChangeMonitorPrice={SetNewMonitorPrice}
          onChangeMonitorStock={SetNewMonitorStock}
          onChangeOnlyPrime={SetNewOnlyPrime}
          onChangePriceDecreasePercentage={SetNewPriceDecreasePercentage}
          onChangeMonitorPriceDecrease={SetMonitorPriceDecrease}
          onChangeDispatchDays={SetDispatchDays}
        />;
      case Tab.Business:
        return <ListingBusiness
          valuePaymentProfileId={paymentProfileId}
          valueShippingProfileId={shippingProfileId}
          valueReturnProfileId={returnProfileId}

          onChangePaymentProfileId={SetPaymentProfileId}
          onChangeReturnProfileId={SetReturnProfileId}
          onChangeShippingProfileId={SetShippingProfileId}
        />;
      default:
        return <></>;
    }
  };

  const RenderLoadingModal = () => {
    if (saving == null)
      return <></>;

    const HandleClose = () => {
      if (saving != null && !saving.loading && saving.success == false) {//Error
        location.reload();
        return;
      }

      SetSavingChanges(false);
      SetRefreshing(false);
      if (terminating)
        onClose();
      SetTerminating(false);
    };

    const title = (() => {
      if (refreshing)
        return <T k="Setting.RefreshingListing" />;
      else if (terminating)
        return <T k="Setting.TerminatingListing" />;
      else
        return <T k="Setting.SavingChanges" />;
    })();
    const result = (() => {
      if (!saving.success)
        return <T k="Setting.ErrorSavingUnknownError" />;
      else if (refreshing)
        return <T k="Setting.Refreshed" />;
      else if (terminating)
        return <T k="Setting.Terminated" />;
      else
        return <T k="Setting.Saved" />;
    })();

    return (
      <LoadingModal
        visible={savingChanges || terminating || refreshing}
        loading={saving.loading}
        title={title}
        onClose={HandleClose}
        result={result}
      />
    );
  };

  const RenderConfirmationModal = () => {
    return (
      <QuestionModal
        visible={confirmatingTerminate}
        onOk={HandleTerminateConfirmed}
        onCancel={HandleTerminatedCanceled}
      >
        <T k="Setting.ConfirmationTerminate" />
      </QuestionModal>
    );
  };


  const tabs = [
    {
      label: <T k='Listings.Edit.Main' />,
      key: Tab.Main.toString()
    },
    //{
    //  label: <TTag lKey='Listings.Edit.Description' />,
    //  key: Tab.Description
    //},
    {
      label: <T k='Listings.Edit.Details' />,
      key: Tab.Details.toString()
    },
    {
      label: <T k='Listings.Edit.Options' />,
      key: Tab.Options.toString()
    }
  ];
  if (platform == ePlatform.eBay && settings?.useBusinessPolicies) {
    tabs.push({
      label: <T k='Listings.Edit.BusinessPolicies' />,
      key: Tab.Business.toString()
    });
  }
  const [optimising, SetOptimising] = useState<boolean>(false);
  const HandelCancelOptimise = () => {
    SetOptimising(false);
  };

  const RenderOptimising = () => {
    return <OptimiseModal
      onClose={HandelCancelOptimise}
      visible={optimising}
      listings={[{ listingId: listing.channelListingId, productSourceId: listing.productSourceId }]}
    />;
  };

  const HandleOptimise = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => { SetOptimising(true); e.preventDefault(); e.stopPropagation(); };

  return (
    <div className="edit-listing">
      {RenderOptimising()}
      <div className="upper-section">
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: 20 }}><h3>Editing product: {listing.channelItem}</h3><A to="#" onClick={HandleOptimise}><T k="Listings.Edit.Optimize" /></A></div>
        <div className="external-links">
          <A to="#" onClick={HandleViewPriceChanges}>
            <T k='Listings.ViewPriceChanges' />
          </A>
          <A to="#" onClick={HandleViewStockChanges}>
            <T k='Listings.ViewStockChanges' />
          </A>
        </div>
      </div>

      <Tabs
        activeKey={activeTab.toString()}
        onChange={(v) => SetActiveTab(parseInt(v))}
        items={tabs}
      />

      <div className="content">{RenderContent(activeTab)}</div>
      <div className="btns-container">
        <DeleteBtn onClick={HandleTerminate}>
          <TrashIcon />
          <T k='Listings.Edit.Terminate' />
        </DeleteBtn>
        <WarningBtn onClick={HandleRefresh}>
          <RefreshIcon />
          <T k='Listings.Edit.ForceRefresh' />
        </WarningBtn>
        <SuccessBtn onClick={HandleSave} disabled={!somethingToChange}>
          <CheckIcon />
          <T k='Listings.Edit.Save' />
        </SuccessBtn>
      </div>
      {RenderLoadingModal()}
      {RenderConfirmationModal()}
      <PriceChangesModal listingId={listing.channelListingId} visible={viewPriceChanges} onClose={() => SetViewPriceChanges(false)} />
      <StockChangesModal listingId={listing.channelListingId} visible={viewStockChanges} onClose={() => { console.log('false modal'); SetViewStockChanges(false); }} />
    </div>
  );
};
