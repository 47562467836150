import { Checkbox, Col, Progress, Row, Spin } from 'antd';
import { GetOrderLineLastProcessStatesResponseData, OrderData } from 'src/redux/orders/orderSlice';
import {
  LastStepOrderIcon,
  OrderCheckoutIcon,
  OrderProcessRoundedIcon,
  RoundCircleCycleIcon,
  LeftBackArrowIcon
} from '../components/common/Icons';
import { useState } from 'react';
import '../sass/order-state-modal.scss';
import { ProcessOrderIcon, HandStopOrderIcon, TrashIcon, CheckIcon } from '../components/common/Icons';
import { PrimaryBtn, WarningBtn, DangerBtn, SuccessBtn } from './ActionBtns';
import { useAppDispatch, useAppSelector } from '../custom-hooks/reduxCustomHooks';
import {
  GetOrderImage,
  loadAddressFromOrderLine,
  ProcessOrders,
  GenerateTrackingId,
  ForceProcessOrders,
  Fixed
} from '../redux/orders/orderThunk';
import { ManuallyDispatch } from '../redux/orders/orderThunk';
import { StopOrder } from '../redux/orders/orderThunk';
import { loadProgressOfOrder } from '../redux/orders/orderThunk';
import { useEffect } from 'react';
import { OrderStatus } from '../utils/determineStatus';
import { AutoOrderingError } from '../components/orders/data/auto-ordering-error';
import { ReactUtils } from '../utils/react-utils';
import { useTranslation, T } from '../utils/transShim';
import { Links } from '../links';

import { HelpTooltip } from './help-tooltip';
import { QuestionModal } from './modals/question-modal';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { DateOrNull } from '../utils/utils';
import { AutoOrderingState } from '../components/orders/data/auto-ordering-state';
import { A } from './A';

interface Props {
  order: OrderData | undefined;
  channelOAuthId: number[];
  OrderDetailsModalOpen: () => void;
  HandleSingleOrderModal: () => void;
}

export const OrderContent = (props: Props) => {
  const { order, OrderDetailsModalOpen, HandleSingleOrderModal } = props;
  const dispatch = useAppDispatch();

  const { orderProgress } = useAppSelector((state) => state.orderProgress);
  const { notifications, loading: loadingNotifications } = useAppSelector((x) => x.notifications);

  const [generateTrackingOpen, SetGenerateTrackingOpen] = useState<boolean>();

  const notAvailableYet = useTranslation('NotAvailableYet') as string;

  const HandleProcessOrders = async () => {
    await dispatch(ProcessOrders({ orderLineIds: [order?.id ?? 0] }));
    HandleSingleOrderModal();
  };
  const HandleManuallyDispatch = async () => {
    if (order?.status == AutoOrderingState.CompletedAutoOrderPending) {
      await dispatch(Fixed({ orderLineIds: [order?.id ?? 0] }));
    } else {
      await dispatch(ManuallyDispatch({ orderLineIds: [order?.id ?? 0] }));
    }
    HandleSingleOrderModal();
  };
  const HandleStopOrder = async () => {
    await dispatch(StopOrder({ orderLineIds: [order?.id ?? 0] }));
    HandleSingleOrderModal();
  };
  const [dontShowAgain, SetDontShowAgain] = useState<boolean>(true);
  const HandleDontShowAgainTracking = async (e: CheckboxChangeEvent) => {
    SetDontShowAgain(e.target.checked);
  };
  const HandleConfirmedGenerateTrackingId = async () => {
    await dispatch(GenerateTrackingId({ orderLineIds: [order?.id ?? 0] }));
    HandleSingleOrderModal();
  };
  const ModalHandleConfirmedGenerateTrackingId = () => {
    SetGenerateTrackingOpen(false);
    localStorage.setItem('DontShowAgainTracking', dontShowAgain ? '1' : '0');
    HandleConfirmedGenerateTrackingId();
  };
  const HandleGenerateTrackingId = async () => {
    const st = localStorage.getItem('DontShowAgainTracking');
    if (st == null || st == '0') {
      SetGenerateTrackingOpen(true);
    } else {
      HandleConfirmedGenerateTrackingId();
    }
  };
  const HandleForceBuy = async () => {
    await dispatch(ForceProcessOrders({ orderLineIds: [order?.id ?? 0] }));
    HandleSingleOrderModal();
  };
  const { loading } = useAppSelector((state) => state.orderProgress);
  const { updating } = useAppSelector((state) => state.orders);
  const notConfigured = !order?.sourceAOConfigured;

  const btnProcessDisabled =
    !order ||
    notConfigured ||
    ((order.status === null || order.status === undefined) &&
      order?.storeStatus != OrderStatus.Shipped &&
      order.storeStatus != OrderStatus.Cancelled) ||
    (order?.status != AutoOrderingState.AutoorderingDisabled &&
      order.status != AutoOrderingState.GoingToBuyError &&
      order.status != AutoOrderingState.PermanentError &&
      (order.status != AutoOrderingState.ManuallyDispatched ||
        order.storeStatus == OrderStatus.Shipped ||
        order.storeStatus == OrderStatus.Cancelled));

  const btnDispatchDisabled =
    (!order || order.sourceAOEnabled) &&
    (notConfigured ||
      ((order.status === null || order.status === undefined) &&
        order?.storeStatus != OrderStatus.Shipped &&
        order.storeStatus != OrderStatus.Cancelled) ||
      (order?.status != AutoOrderingState.AutoorderingDisabled &&
        order.status != AutoOrderingState.GoingToBuyError &&
        order.status != AutoOrderingState.PermanentError &&
        order.status != AutoOrderingState.CompletedAutoOrderPending));

  const cantBeStoped =
    !order ||
    notConfigured ||
    order?.storeStatus == OrderStatus.Shipped ||
    order?.storeStatus == OrderStatus.Cancelled ||
    order?.status < AutoOrderingState.AutoorderingPrepared ||
    (order?.status >= AutoOrderingState.CompletedAutoOrder && order.status != AutoOrderingState.TemporaryError);

  useEffect(() => {
    // dispatch(loadProgressOfOrder(iddd));
    dispatch(loadProgressOfOrder(order?.id));
    dispatch(loadAddressFromOrderLine(order?.id));
  }, [order?.id]);

  let OrderProgress = 1;
  let states = orderProgress?.states;
  let lastState: GetOrderLineLastProcessStatesResponseData | undefined = undefined;
  if (states != null && states.length > 0) {
    lastState = states[states.length - 1];
  }
  const dlu = orderProgress?.lastStatusUpdate ?? new Date(orderProgress?.lastStatusUpdate ?? '');
  if (!!orderProgress?.lastStatus && (!lastState || lastState.date <= dlu)) {
    const nls: GetOrderLineLastProcessStatesResponseData = {
      id: lastState?.id ?? 9999999,
      date: dlu,
      status: orderProgress?.lastStatus,
      error: lastState?.error,
      errorMessage: lastState?.errorMessage
    };
    lastState = nls;
    states = [...(states ?? []), nls];
  }

  let hasError = false;
  const lastStatusUpdate = orderProgress?.lastStatusUpdate;
  let statusText: React.ReactNode = '';

  if (!lastState || lastState.status == AutoOrderingState.AutoorderingDisabled) {
    //Paused
    OrderProgress = 0;
    statusText = <T k="Orders.Status.Paused" />;
  } else if (lastState.status == AutoOrderingState.ManuallyDispatched) {
    OrderProgress = 0;
    statusText = <T k="Orders.Status.ManuallyDispatched" />;
  } else if (lastState.status == AutoOrderingState.AutoorderingPrepared) {
    //Starting
    OrderProgress = 1;
    statusText = <T k="Orders.Status.WaitingStart" />;
  } else if (
    (lastState.status > AutoOrderingState.AutoorderingPrepared && lastState.status < AutoOrderingState.CompletedAutoOrder) ||
    lastState.status == AutoOrderingState.TemporaryError
  ) {
    //Processing
    OrderProgress = 2;
    statusText = <T k="Orders.Status.InProgress" />;
  } else if (lastState.status == AutoOrderingState.CompletedAutoOrderPending) {
    //LastSteps but pending
    OrderProgress = 3;
    statusText = <T k="Orders.Status.AwaitingUser" />;
  } else if (lastState.status >= AutoOrderingState.CompletedAutoOrder && lastState.status < AutoOrderingState.Completed) {
    //LastSteps
    OrderProgress = 3;
    statusText = <T k="Orders.Status.LastSteps" />;
  } else if (lastState.status >= AutoOrderingState.Completed && lastState.status < AutoOrderingState.TemporaryError) {
    //Completed
    OrderProgress = 4;
    statusText = <T k="Orders.Status.Completed" />;
  } /*if (lastState.status > AutoOrderingState.TemporaryError)*/ else {
    //Error
    OrderProgress = 0;
    statusText = <T k="Orders.Status.Error" />;
    hasError = true;
  }

  const [imgBase64, SetImgBase64] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (order?.id == null) return;
    (async () => {
      const res = await dispatch(GetOrderImage({ orderId: order?.id, lastState: lastState?.id ?? 0 }));
      SetImgBase64(res.payload);
    })();
  }, [order?.id, lastState?.id]);

  const percent = OrderProgress * 25;

  let dateStart: Date | undefined = order?.date;
  let dateProgress: Date | undefined;
  let dateFinish: Date | undefined;
  if (states)
    for (let i = 0; i < states.length; i++) {
      const ls = states[i];
      if (!ls || ls.status == AutoOrderingState.AutoorderingDisabled) {
        //Paused
        dateStart = DateOrNull(lastStatusUpdate) ?? undefined;
      } else if (ls.status == AutoOrderingState.AutoorderingPrepared) {
        //Starting
        dateStart = DateOrNull(lastStatusUpdate) ?? undefined;
      } else if (
        (ls.status > AutoOrderingState.AutoorderingPrepared && ls.status < AutoOrderingState.CompletedAutoOrder) ||
        ls.status == AutoOrderingState.TemporaryError
      ) {
        //Processing
        dateProgress = DateOrNull(ls.date) ?? undefined;
      } else if (ls.status >= AutoOrderingState.CompletedAutoOrder && ls.status < AutoOrderingState.Completed) {
        //LastSteps
        dateFinish = DateOrNull(ls.date) ?? undefined;
      } else if (ls.status >= AutoOrderingState.Completed && ls.status < AutoOrderingState.TemporaryError) {
        //Completed
        dateFinish = DateOrNull(ls.date) ?? undefined;
      } /*if (lastState.status > AutoOrderingState.TemporaryError)*/ else {
        //Error
        dateStart = DateOrNull(lastStatusUpdate) ?? undefined;
      }
    }
  else {
    dateFinish = DateOrNull(lastStatusUpdate) ?? undefined;
  }
  const configurableButNotConfigured = !!order?.sourceAOEnabled && notConfigured;

  const ErrorToMessage = (error: AutoOrderingError, status: AutoOrderingState) => {
    if (status == AutoOrderingState.GoingToBuyError) {
      return <T k="Orders.Error.GoingToBuyError" values={{ source: order?.sourceName }} />;
    }
    switch (error) {
      default:
      case AutoOrderingError.Unknown:
        return <T k="Orders.Error.Unknown" />;
      case AutoOrderingError.Login:
        return <T k="Orders.Error.Login" />;
      case AutoOrderingError.TwoFA:
        return (
          <>
            <T k="Orders.Error.TwoFA" />{' '}
            <A to="https://hustlegotreal.com/en/amazon-key-auto-ordering" target="_blank" rel="noreferrer">
              <T k="Orders.Error.TwoFA.Link" />
            </A>
          </>
        );
      case AutoOrderingError.Verification:
        return <T k="Orders.Error.Verification" />;
      case AutoOrderingError.UserActionRequired:
        return <T k="Orders.Error.UserActionRequired" />;
      case AutoOrderingError.Captcha:
        return <T k="Orders.Error.Captcha" />;
      case AutoOrderingError.Suspended:
        return <T k="Orders.Error.Suspended" />;
      case AutoOrderingError.HighPrice:
        return (
          <>
            <T k="Orders.Error.HighPrice" />
            <br />
            <PrimaryBtn onClick={HandleForceBuy}>
              <T k="Orders.Error.HighPrice.Button" />
            </PrimaryBtn>
          </>
        );
      case AutoOrderingError.OutOfStock:
        return <T k="Orders.Error.OutOfStock" />;
      case AutoOrderingError.Timeout:
        return <T k="Orders.Error.Timeout" />;
      case AutoOrderingError.UnknownJavascriptError:
        return <T k="Orders.Error.UnknownJavascriptError" />;
      case AutoOrderingError.InvalidShippingAddress:
        return <T k="Orders.Error.InvalidShippingAddress" />;
      case AutoOrderingError.Payment:
        return <T k="Orders.Error.Payment" />;
      case AutoOrderingError.NoGiftCards:
        return <T k="Orders.Error.NoGiftCards" />;
      case AutoOrderingError.InvalidCard:
        return <T k="Orders.Error.InvalidCard" />;
      case AutoOrderingError.NoCard:
        return <T k="Orders.Error.NoCard" />;
      case AutoOrderingError.CardVerification:
        return <T k="Orders.Error.InvalidCard" />;
      case AutoOrderingError.NoBillingAddress:
        return (
          <>
            <T k="Orders.Error.NoBillingAddress.1" values={{ source: order?.sourceName }} />
            {(() => {
              switch (order?.sourceId) {
                /*Saleyee*/
                case 221:
                case 222:
                case 223:
                case 224:
                  return (
                    <>
                      {' '}
                      <T k="Orders.Error.NoBillingAddress.2" />{' '}
                      <A to="https://www.saleyee.com/user/addresses/billaddresses.html" target="_blank" rel="noreferrer">
                        <T k="Orders.Error.NoBillingAddress.3" />
                      </A>
                    </>
                  );

                /*Dropshiptraders UK*/
                case 185:
                  return (
                    <>
                      {' '}
                      <T k="Orders.Error.NoBillingAddress.2" />{' '}
                      <A to="https://www.dropship-traders.co.uk/my-account/edit-address/billing/" target="_blank" rel="noreferrer">
                        <T k="Orders.Error.NoBillingAddress.3" />
                      </A>
                    </>
                  );

                /*Robert Dyas UK*/
                case 10:
                  return (
                    <>
                      {' '}
                      <T k="Orders.Error.NoBillingAddress.2" />{' '}
                      <A to="https://www.robertdyas.co.uk/customer/address/" target="_blank" rel="noreferrer">
                        <T k="Orders.Error.NoBillingAddress.3" />
                      </A>
                    </>
                  );

                /*Costway UK*/
                case 59:
                  return (
                    <>
                      {' '}
                      <T k="Orders.Error.NoBillingAddress.2" />{' '}
                      <A to="https://www.costway.co.uk/customer/address/" target="_blank" rel="noreferrer">
                        <T k="Orders.Error.NoBillingAddress.3" />
                      </A>
                    </>
                  );

                default:
                  return (
                    <>
                      {' '}
                      <T k="Orders.Error.NoBillingAddress.4" />{' '}
                      <A to={Links.Configuration.AutoOrdering.Produce(order?.sourceId)} target="_blank" rel="noreferrer">
                        {' '}
                        <T k="Orders.Error.NoBillingAddress.5" />
                      </A>{' '}
                      <T k="Orders.Error.NoBillingAddress.6" values={{ source: order?.sourceName }} />
                    </>
                  );
              }
            })()}
          </>
        );
      case AutoOrderingError.ImportedWithError:
        return <T k="Orders.Error.ImportedWithError" />;
      case AutoOrderingError.MaxBuyLimit:
        return <T k="Orders.Error.MaxBuyLimit" />;
      case AutoOrderingError.MinBuyLimit:
        return <T k="Orders.Error.MinBuyLimit" />;
      case AutoOrderingError.WrongGiftFrom:
        return <T k="Orders.Error.WrongGiftFrom" />;
      case AutoOrderingError.WrongGiftMessage:
        return <T k="Orders.Error.WrongGiftMessage" />;
      case AutoOrderingError.InvalidBillingAddress:
        return (
          <>
            <T k="Orders.Error.InvalidBillingAddress.1" values={{ source: order?.sourceName }} />
            {(() => {
              switch (order?.sourceId) {
                /*Saleyee*/
                case 221:
                case 222:
                case 223:
                case 224:
                  return (
                    <>
                      {' '}
                      <T k="Orders.Error.NoBillingAddress.2" />{' '}
                      <A to="https://www.saleyee.com/user/addresses/billaddresses.html" target="_blank" rel="noreferrer">
                        <T k="Orders.Error.NoBillingAddress.3" />
                      </A>
                    </>
                  );

                /*Dropshiptraders UK*/
                case 185:
                  return (
                    <>
                      {' '}
                      <T k="Orders.Error.NoBillingAddress.2" />{' '}
                      <A to="https://www.dropship-traders.co.uk/my-account/edit-address/billing/" target="_blank" rel="noreferrer">
                        <T k="Orders.Error.NoBillingAddress.3" />
                      </A>
                    </>
                  );

                /*Robert Dyas UK*/
                case 10:
                  return (
                    <>
                      {' '}
                      <T k="Orders.Error.NoBillingAddress.2" />{' '}
                      <A to="https://www.robertdyas.co.uk/customer/address/" target="_blank" rel="noreferrer">
                        <T k="Orders.Error.NoBillingAddress.3" />
                      </A>
                    </>
                  );

                /*Costway UK*/
                case 59:
                  return (
                    <>
                      {' '}
                      <T k="Orders.Error.NoBillingAddress.2" />{' '}
                      <A to="https://www.costway.co.uk/customer/address/" target="_blank" rel="noreferrer">
                        <T k="Orders.Error.NoBillingAddress.3" />
                      </A>
                    </>
                  );

                default:
                  return (
                    <>
                      {' '}
                      <T k="Orders.Error.NoBillingAddress.4" />{' '}
                      <A to={'/AutoOrderingConfiguration?s=' + order?.sourceId} target="_blank" rel="noreferrer">
                        {' '}
                        <T k="Orders.Error.NoBillingAddress.5" />
                      </A>{' '}
                      <T k="Orders.Error.NoBillingAddress.6" values={{ source: order?.sourceName }} />
                    </>
                  );
              }
            })()}
          </>
        );
      case AutoOrderingError.NoPaypal:
        return <T k="Orders.Error.NoPaypal" />;
      case AutoOrderingError.NoWallet:
        return <T k="Orders.Error.NoWallet" />;
      case AutoOrderingError.PlatformWeirdBehaviour:
        return <T k="Orders.Error.PlatformWeirdBehaviour" values={{ source: order?.sourceName }} />;
      case AutoOrderingError.VATNotConfigured:
        return <T k="Orders.Error.VATNotConfigured" values={{ source: order?.sourceName }} />;
      case AutoOrderingError.PaymentPassword:
        return (
          <>
            <T k="Orders.Error.PaymentPassword.1" />{' '}
            <A to={Links.Configuration.AutoOrdering.Produce(order?.sourceId)}>
              <T k="Orders.Error.PaymentPassword.2" values={{ source: order?.sourceName }} />
            </A>
            <T k="Orders.Error.PaymentPassword.3" />
          </>
        );
      case AutoOrderingError.QuotedOrder:
        return <T k="Orders.Error.QuotedOrder" values={{ source: order?.sourceName }} />;
    }
  };

  const OpenBase64 = (evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>, base64URL: string | undefined) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (!base64URL) return;
    const win = window.open();
    win?.document?.write(
      '<iframe src="' +
        base64URL +
        '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
    );
  };

  return (
    <div className="order-state-progress-modal">
      <div className="order-state-header">
        <div className="section-top" style={{ flexGrow: 1 }}>
          <div className="button-title">
            <h2 className="title-modal">
              <T k="Orders.Title.Status" />
            </h2>
            {configurableButNotConfigured && (
              <A to={Links.Configuration.AutoOrdering.Produce(order?.sourceId)}>
                <PrimaryBtn className="configure-button">
                  <T k="Orders.Button.Configure" />
                </PrimaryBtn>
              </A>
            )}
          </div>
          {!!order?.hgrTrackingNumber && (
            <div className="tracking-container">
              <h3 className="source-url" style={{ display: 'inline-block', marginRight: 5 }}>
                <T k="Orders.Label.TrackingId" />:
              </h3>
              <A to={'https://www.velocipacker.com/tracking/' + order.hgrTrackingNumber} target="_blank" rel="noreferrer">
                {order.hgrTrackingNumber}
              </A>
            </div>
          )}
          {!order?.hgrTrackingNumber && (lastState?.status == AutoOrderingState.ManuallyDispatched || lastState?.status == AutoOrderingState.AutoorderingDisabled || lastState == null) && !(notifications?.pendingPaymentProblems ?? false) && (
            <div className="tracking-container">
              {!loadingNotifications && (
                <>
                  <h3 className="source-url" style={{ display: 'inline-block', marginRight: 5 }}>
                    <T k="Orders.Label.TrackingId" />:
                  </h3>
                  <PrimaryBtn onClick={HandleGenerateTrackingId}>
                    <T k="Orders.Button.GenerateTracking" />
                  </PrimaryBtn>
                  <HelpTooltip>
                    <T k="Orders.Tooltip.PriceTracking" />
                  </HelpTooltip>
                </>
              )}
              {loadingNotifications && <Spin />}
            </div>
          )}
        </div>
      </div>
      <div className="order-state-body">
        {loading || updating ? (
          <Spin />
        ) : (
          <Row>
            <Col md={12} span={24}>
              <h2 className="history-text">
                <T k="Orders.Label.History" />
              </h2>
              <div className="time-line">
                {/* START ORDER  */}
                <div className="step-order-container">
                  <span className="step-order">
                    <span className="start-order-active-svg">
                      <RoundCircleCycleIcon />
                    </span>
                    <span className={`${OrderProgress > 1 ? 'h-blue-line' : 'disabled-line'}`}></span>
                  </span>
                  <div className="order-step-heading">
                    <h4>
                      {hasError && <T k="Orders.Status.Error" />}
                      {!hasError && OrderProgress < 1 && lastState?.status == AutoOrderingState.ManuallyDispatched && (
                        <T k="Orders.Status.ManuallyDispatched" />
                      )}
                      {!hasError && OrderProgress < 1 && lastState?.status != AutoOrderingState.ManuallyDispatched && (
                        <T k="Orders.Status.Paused" />
                      )}
                      {!hasError && OrderProgress >= 1 && <T k="Orders.Label.StartOrder" />}
                      {OrderProgress === 1 ? (
                        <span>
                          <OrderProcessRoundedIcon />
                        </span>
                      ) : (
                        ''
                      )}
                    </h4>
                    <p>{ReactUtils.GetFormattedDateTime(dateStart)}</p>
                    <span>
                      {hasError &&
                        ErrorToMessage(
                          lastState?.error ?? AutoOrderingError.Unknown,
                          lastState?.status ?? AutoOrderingState.AutoorderingPrepared
                        )}
                    </span>
                  </div>
                </div>
                {/* CHECKOUT  */}
                <div className="step-order-container">
                  <span className="step-order">
                    <span className={`${OrderProgress > 1 ? 'order-checkout-icon' : ''}`}>
                      <OrderCheckoutIcon />
                    </span>
                    <span className={`${OrderProgress > 2 ? 'h-blue-line' : 'disabled-line'}`}></span>
                  </span>
                  <div className="order-step-heading">
                    <h4 className={OrderProgress < 2 ? ' disabled' : ''}>
                      <T k="Orders.Status.Checkout" />{' '}
                      {OrderProgress == 2 && (
                        <span>
                          <OrderProcessRoundedIcon />
                        </span>
                      )}
                    </h4>
                    <p>{OrderProgress > 1 && dateProgress !== undefined && ReactUtils.GetFormattedDateTime(dateProgress)}</p>
                    <span></span>
                  </div>
                </div>
                {/* LAST STEP  */}
                <div className="step-order-container">
                  <span className="step-order">
                    <span className={OrderProgress > 2 ? 'last-step-order-icon' : ''}>
                      <LastStepOrderIcon />
                    </span>
                  </span>
                  <div className="order-step-heading">
                    <h4 className={OrderProgress < 3 ? ' disabled' : ''}>
                      {OrderProgress <= 3 && <T k="Orders.Status.LastSteps" />}
                      {OrderProgress > 3 && <T k="Orders.Status.Completed" />}{' '}
                      {OrderProgress == 3 && lastState?.status != AutoOrderingState.CompletedAutoOrderPending && (
                        <span>
                          <OrderProcessRoundedIcon />
                        </span>
                      )}
                    </h4>
                    <p>{OrderProgress > 2 && ReactUtils.GetFormattedDateTime(dateFinish)}</p>
                  </div>
                </div>
                <div className="progress-order">
                  <span>
                    <T k="Orders.Label.Progress" />: {statusText}
                  </span>
                  {/* Add progress bar from ant design*/}
                  <Progress percent={percent} status={percent == 100 ? 'success' : 'active'} />
                </div>
              </div>
            </Col>
            <Col md={12} span={24}>
              <div className="order-image">
                <A onClick={(e) => OpenBase64(e, imgBase64)} key={imgBase64 ?? -1} to={imgBase64} target="_blank" rel="noreferrer">
                  <img
                    key={imgBase64 ?? -1}
                    src={imgBase64 ?? ''}
                    alt="Progress image"
                    onError={(event) => (event.currentTarget.style.display = 'none')}
                  />
                </A>
              </div>
              {orderProgress?.lastAccountAlias && (
                <div>
                  <T k="Orders.Label.AccountUsed" />: {orderProgress?.lastAccountAlias}
                </div>
              )}
            </Col>
            <QuestionModal
              okText={<T k="Question.Continue" />}
              visible={generateTrackingOpen}
              onOk={ModalHandleConfirmedGenerateTrackingId}
              onCancel={() => SetGenerateTrackingOpen(false)}
              maskClosable
            >
              <p>
                <T k="Orders.Tooltip.PriceTracking" />
              </p>
              <Checkbox onChange={HandleDontShowAgainTracking} checked={dontShowAgain}>
                <p>
                  <T k="Dont show this again" />
                </p>
              </Checkbox>
            </QuestionModal>
          </Row>
        )}
      </div>
      {!updating && (
        <Row className="footer-modal">
          <Col md={24}>
            <Row>
              <Col md={12} className="col-go-back">
                <div className="go-back-details-container">
                  <div onClick={OrderDetailsModalOpen} className="go-back-details">
                    <T k="Orders.Title.OrderDetails" />
                    <LeftBackArrowIcon />
                  </div>
                </div>
              </Col>
              {/* Buttons to stop,process,dispatch,delete an order */}
              <Col md={12}>
                <div className="buttons-block">
                  <Row className="buttons-row">
                    <WarningBtn onClick={HandleStopOrder} disabled={cantBeStoped || order?.cancelRequested}>
                      <HandStopOrderIcon />
                      {!cantBeStoped && order?.cancelRequested ? <T k="Orders.Buttons.StopRequested" /> : <T k="Orders.Buttons.Stop" />}
                    </WarningBtn>
                    <SuccessBtn onClick={HandleManuallyDispatch} disabled={btnDispatchDisabled}>
                      <CheckIcon />
                      <T k="Orders.Buttons.MarkAsPurchased" />
                    </SuccessBtn>
                    <PrimaryBtn onClick={HandleProcessOrders} disabled={btnProcessDisabled}>
                      <ProcessOrderIcon />
                      <T k="Orders.Buttons.Process" />
                    </PrimaryBtn>
                    <DangerBtn className="mr-3" disabled={true} title={notAvailableYet}>
                      <TrashIcon />
                      <T k="Orders.Buttons.Delete" />
                    </DangerBtn>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};
