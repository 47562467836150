import { Row, Col } from 'antd';
import '../../sass/list-now/manual-listing.scss';

import { SuppliersList } from 'src/small-components/SuppliersList';
import { ReactUtils } from 'src/utils/react-utils';
import { ePlatform } from '../../data/platforms';
import { A } from '../../small-components/A';
import { Links } from '../../links';

export const ManualPublish = () => {
  const selectedChannel = ReactUtils.GetSelectedChannel();

  const ebayVideo = (
    <iframe
      className="intro-vid"
      src={'https://www.youtube.com/embed/P-CjSHtd4mQ'}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
  const ebayNoapiVideo = (
    <iframe
      className="intro-vid"
      src={'https://www.youtube.com/embed/paqPCAwj1lg'}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
  const amazonVideo = (
    <iframe
      className="intro-vid"
      src={'https://www.youtube.com/embed/5lz8hStFAzQ'}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
  const shopifyVideo = (
    <iframe
      className="intro-vid"
      src={'https://www.youtube.com/embed/B074HHdXZXo'}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
  const facebookVideo = ebayVideo;

  const videosForChannel = () => {
    switch (selectedChannel?.channelId) {
      case ePlatform.eBay:
        return ebayVideo;
      case ePlatform.eBayNoApi:
        return ebayNoapiVideo;
      case ePlatform.Amazon:
        return amazonVideo;
      case ePlatform.Shopify:
        return shopifyVideo;
      case ePlatform.Facebook:
        return facebookVideo;
      default:
        return ebayVideo;
    }
  };

  return (
    <div className="manual-list-content">
      <div className="container-manual-listing">
        <div className="list-card">
          <Row>
            <Col md={12} xs={24}>
              <div className="card-info">
                <h4>
                  <li>
                    Download our <A to={Links.BrowserExtension}>listing extension.</A>
                  </li>
                  <li>Choose your favourite supplier.</li>
                  <li>Choose the product you want to list.</li>
                  <li>Click on the extension on your browser.</li>
                  <li>Edit the information as you wish to list it and click on ‘List’.</li>
                  <li>You are done! Your item will soon appear under your Active listing section.</li>
                </h4>

                <p>
                  NOTE: If your listing comes up with an error you can check your{' '}
                  <A to={Links.Products.Pending}>Products{' > '}Pending products</A> and see further detail.
                </p>
              </div>
            </Col>
            <Col md={12} xs={24}>
              <div className="manual-listing-vid">{videosForChannel()}</div>
            </Col>
          </Row>
        </div>
        <div className="section-sources">
          <h2>Suported suppliers</h2>
          <SuppliersList loadSources={true} />
        </div>
      </div>
    </div>
  );
};
