import { ReactUtils } from '../../../../utils/react-utils';
import { ChannelLabel } from '../../../channel-label';
import { MultipleSelector } from '../../../form/multiple-selector';

type Props = {
  onChange: (value: number[] | null) => void;
}

export const OtherChannelsField = (props: Props) => {
  const { onChange } = props;

  const selectedChannel = ReactUtils.GetSelectedChannel();
  const allChannels = ReactUtils.GetAllChannels();

  if(selectedChannel == null)
    return <></>;

  const options = allChannels.map(x => (
    {
      label: <ChannelLabel channel={x} />,
      value: x.id
    }
  ));

  return (
    <MultipleSelector
      placeholder="Select your sources"
      style={{ width: '100%' }}
      className="blue-input"
      onChange={(x) => onChange(x as number[])}
    >
      {options}
    </MultipleSelector >
  );
};