import { useState } from 'react';
import { Selector, SelectorValue } from './selector';

type Props = Omit<React.ComponentProps<typeof Selector>,'showsearch'> & {
  isValidOption?: (v: string) => boolean
};

export const Creatable = (props: Props) => {
  const { isValidOption, onSearch } = props;
  const [addedOption, SetAddedOption] = useState<string>('');

  const [searchCount, SetSearchCount] = useState<number>(0);
  const [searchedTerm, SetSearchedTerm] = useState<string>('');

  const [value, SetInnerValue] = (() => {
    const [innerValue, SetInnerValue] = useState<SelectorValue | undefined>();
    return [props.value ?? innerValue, SetInnerValue];
  })();

  let children = Array.isArray(props.children) ? props.children : [props.children];
  if (addedOption && !children.find(x => x?.label == addedOption))
    children = [{ label: addedOption, value: addedOption }, ...children];

  if (value && addedOption != value && children.find(x => x.value == value) == null) {
    children = [{ label: value, value: value }, ...children];
  }

  const HandelOnChange = (v: SelectorValue) => {
    SetInnerValue(v);
    props.onChange?.(v);
  };

  const HandleOnInputKeyDown = () => {
    if (searchCount != 0)
      SetSearchCount(0);
  };
  const HandleOnBlur = () => {
    if (searchCount == 1 && !searchedTerm) {
      HandelOnChange('');
    }
  };

  return <Selector
    {...props}
    value={value}
    onBlur={HandleOnBlur}
    onChange={HandelOnChange}
    onInputKeyDown={HandleOnInputKeyDown}
    onSearch={(e: string) => {
      SetSearchCount(searchCount + 1);
      SetSearchedTerm(e);

      if (isValidOption && !isValidOption(e)) {
        onSearch?.(e);
        return;
      }

      if (e.length > 0) {
        SetAddedOption(e);
        HandelOnChange(e);
      }
      onSearch?.(e);
    }}
    showSearch={true}
  >
    {children}
  </Selector>;
};