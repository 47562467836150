import { Checkbox } from 'antd';
import '../../../sass/subscriptions/checkout.scss';
import { OrderSummary } from './parts/order-summary';
import { PrimaryBtn } from '../../../small-components/ActionBtns';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Cart, SelectedPrice } from './data/cart';
import { CheckoutSection } from './data/checkout-section';
import { T } from '../../../utils/transShim';
import { Product, Subscription } from '../../../redux/subscriptions/subsSlice';
import { BillingPeriod, PaymentPlatform } from '../models/types';
import { ReactUtils } from '../../../utils/react-utils';
import { eCurrency } from '../../../types/eCurrency';
import { useEffect, useState } from 'react';
import { Selector, SelectorValue } from '../../../small-components/form/selector';

type Props = {
  cart: Cart;
  products: Product[];
  onNavigate: (cart: Cart, section: CheckoutSection) => void;
};

export const AddServicesCheckout = (props: Props) => {
  const { cart, onNavigate, products } = props;
  const [tempCart, setTempCart] = useState<Cart>(cart);
  const [wlfyQ, setWlfyQ] = useState<number>(50);

  const HandleNavigate = () => {
    onNavigate(
      {
        ...tempCart
      },
      CheckoutSection.EXECUTE_PAYMENT
    );
  };

  useEffect(() => {
    if (cart.platform == PaymentPlatform.PayPal) HandleNavigate();
  }, []);

  const ServiceAdd = (products: Product[], prd: Subscription) => {
    const prdMaster = products.find((x) => x.id == prd?.productId);
    if (!prdMaster) return;

    setTempCart({
      ...cart,
      lines: [...tempCart.lines.filter((x) => x.id != prd.productId && (prdMaster.type != 5 || x.type != 5)), prdMaster]
    });
  };

  const ServiceRemove = (products: Product[], prd: Subscription) => {
    const prdMaster = products.find((x) => x.id == prd?.productId);
    if (!prdMaster) return;

    setTempCart({
      ...cart,
      lines: tempCart.lines.filter((x) => x.id != prd.productId && (prdMaster.type != 5 || x.type != 5))
    });
  };

  const ServiceHandleClick = (prd: Subscription | undefined) => {
    return (/*e: CheckboxChangeEvent*/ e: { target: { checked: boolean } }) => {
      if (!prd) return;

      if (e.target.checked) {
        ServiceAdd(products, prd);
      } else {
        ServiceRemove(products, prd);
      }
    };
  };

  const WeListForYouChangeHandle = (prds: Product[]) => {
    return (q: SelectorValue) => {
      setWlfyQ(q as number);
      const selectedPrice = SelectedPrice(prds.find((x) => x.productOrder == (q as number)) ?? prds[0], cart);
      ServiceAdd(prds, selectedPrice);
    };
  };

  const WeListForYouQuantityClickHandle = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const RenderPriceLine = (prd: Subscription | undefined, currency: eCurrency) => {
    if (!prd) return <></>;
    if (prd.billingPeriodId === BillingPeriod.Monthly)
      return (
        <>
          {ReactUtils.DrawPrice(prd.price, currency)} /<T k="Checkout.perMonth" />
        </>
      );
    else if (prd.billingPeriodId === BillingPeriod.Biannually)
      return (
        <>
          {ReactUtils.DrawPrice(prd.price / 6, currency)} /<T k="Checkout.perMonth" />
        </>
      );
    else if (prd.billingPeriodId === BillingPeriod.Yearly)
      return (
        <>
          {ReactUtils.DrawPrice(prd.price / 12, currency)} /<T k="Checkout.perMonth" />
        </>
      );
    return <>{ReactUtils.DrawPrice(prd.price, currency)}</>;
  };

  const DrawExtraLine = (cart: Cart, prd: Product, key: number) => {
    const selectedPrice = SelectedPrice(prd, cart);
    return (
      <div className="extra-container" key={key}>
        <div className="extra-explanation">
          <Checkbox
            className="checkbox"
            onChange={ServiceHandleClick(selectedPrice)}
            checked={!!tempCart.lines.find((x) => x.id == prd.id)}
          >
            <div className="title-area">
              <h4>
                <T k={'Checkout.service.pname.' + prd.name} />
              </h4>
            </div>
            <p>
              <T k={'Checkout.service.pname.' + prd.name + '.Description'} />
            </p>
          </Checkbox>
        </div>
        <div className="price-extra">
          <h4>{RenderPriceLine(selectedPrice, tempCart.currency)}</h4>
        </div>
      </div>
    );
  };

  const DrawExtraLineWeListForYou = (cart: Cart, prds: Product[]) => {
    const selectedPrice = SelectedPrice(prds.find((x) => x.productOrder == wlfyQ) ?? prds[0], cart);
    return (
      <div className="extra-container" key="wlfy">
        <div className="extra-explanation">
          <Checkbox className="checkbox" onChange={ServiceHandleClick(selectedPrice)} checked={!!tempCart.lines.find((x) => x.type == 5)}>
            <div className="title-area">
              <h4>
                <T k={'Checkout.service.pname.We list for you'} />
              </h4>
              <Selector onChange={WeListForYouChangeHandle(prds)} value={wlfyQ} onClick={WeListForYouQuantityClickHandle}>
                {prds.map((x) => ({
                  label: <T k="Checkout.service.sname.nProducts" values={{ q: x.productOrder }} />,
                  value: x.productOrder
                }))}
              </Selector>
            </div>
            <p>
              <T k={'Checkout.service.pname.We list for you.Description'} />
            </p>
          </Checkbox>
        </div>
        <div className="price-extra">
          <h4>{RenderPriceLine(selectedPrice, tempCart.currency)}</h4>
        </div>
      </div>
    );
  };

  const extras = products.filter((x) => x.type != 1 && x.type != 5);
  const wlfy = products.filter((x) => x.type == 5);

  return (
    <div className="checkout-content">
      <div className="checkout-sections-container">
        <div className="section-container">
          <h4>Add extra services</h4>
          <div className="extras">
            {extras.map((x, i) => DrawExtraLine(tempCart, x, i))}
            {wlfy?.length > 0 &&
              DrawExtraLineWeListForYou(
                tempCart,
                wlfy.filter((x) => x.type == 5)
              )}
          </div>
        </div>
        <div className="section-container">
          <OrderSummary cart={tempCart} />
          <div className="button-container" onClick={HandleNavigate}>
            <PrimaryBtn htmlType="submit">
              <T k="Checkout.FinishPayment" /> <ArrowRightOutlined />
            </PrimaryBtn>
          </div>
        </div>
      </div>
    </div>
  );
};
