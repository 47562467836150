import { useState } from 'react';
import { Product, Subscription } from '../../../redux/subscriptions/subsSlice';
import { eCurrency } from '../../../types/eCurrency';
import { ReactUtils } from '../../../utils/react-utils';
import { T } from '../../../utils/transShim';
import { BillingPeriod, PaymentPlatform } from '../../subscriptions/models/types';

interface Props {
  currency: eCurrency;
  period: BillingPeriod;
  onChangeCurrency: (currency: eCurrency) => void;
  onChangePeriod: (billing: BillingPeriod) => void;
  onChangePlan: (prd: Product) => void;
  products: Product[];
}

export const UserRegisterPlan = (props: Props) => {
  const { currency, onChangeCurrency, onChangePlan, products, period, onChangePeriod } = props;

  const [showAll, SetShowAll] = useState<boolean>(false);

  const SelectPrice = (x: Subscription) => x.billingPeriodId == period && x.platformId == PaymentPlatform.Stripe && x.currencyId == currency;

  const RenderPriceLine = (product: Product | null | undefined) => {
    if (product == null)
      return <></>;

    let price = product.prices.find(SelectPrice)?.price;
    if (price == null)
      return <></>;

    if (period == BillingPeriod.Yearly)
      price = price/12;

    return <div className="plan-line" key={product.id} onClick={_ => onChangePlan(product)}>
      <div className="plan-name">
        <span className="plan-quantity">{product.productOrder}</span>
        <span className="plan-text">&nbsp;<T k="Register.PlanLineQuantity" /></span>
      </div>
      <div className="plan-price">
        {ReactUtils.DrawPrice(price, currency, { decimals: { force: false } })}&nbsp;<T k='Checkout.perMonth.short' />
      </div>
    </div>;
  };

  const CurrencyButton = (c: eCurrency) => {
    //if (currency === c) {
    //  return <PrimaryBtn id={c.toString()} key={c} onClick={_ => onChangeCurrency(c)}>{eCurrency[c]}</PrimaryBtn>;
    //}
    //return <SecondaryBtn id={c.toString()} key={c} onClick={_ => onChangeCurrency(c)}>{eCurrency[c]}</SecondaryBtn>;
    return <div key={c} className={'period' + (c == currency ? ' active' : '')} onClick={_ => onChangeCurrency(c)}>
      {eCurrency[c]}
    </div>;
  };

  const PeriodButton = (b: BillingPeriod) => {
    return <div key={b} className={'period' + (b == period ? ' active' : '')} onClick={_ => onChangePeriod(b)}>
      {b == BillingPeriod.Monthly && <T k='Register.Monthly' />}
      {b == BillingPeriod.Yearly && <T k='Register.Yearly'/>}
    </div>;
  };

  let productsOrderd = products.sort(x => x.prices.find(SelectPrice)?.price ?? 999999);
  if (!showAll)
    productsOrderd = productsOrderd.slice(0, 3);


  return <div className="register-plans">
    <>
      <div className="title">
        <h1><T k='Register.ChooseYourPlan' /></h1>
      </div>
      <div className="plan-options">
        <div className="periods">
          {PeriodButton(BillingPeriod.Monthly)}
          {PeriodButton(BillingPeriod.Yearly)}
        </div>
        <div className="currencies">
          {CurrencyButton(eCurrency.EUR)}
          {CurrencyButton(eCurrency.USD)}
          {CurrencyButton(eCurrency.GBP)}
        </div>
      </div>
      <div className="plan-lines">
        {productsOrderd.map(RenderPriceLine)}
        {!showAll && <div className="plan-showmore" onClick={_ => SetShowAll(true)}><T k='Register.ShowAllPlans' /></div>}
      </div>
      <div className="refund"><T k='Register.Refund' /></div>
    </>
  </div>;
};