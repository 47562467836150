import { Link, LinkProps } from 'react-router-dom';

interface Props extends Omit<LinkProps,'to'> {
  to?: LinkProps['to'];
}

export const A = (props: Props) => {
  const isExternal = props.to && typeof props.to === 'string' && props.to.startsWith('http');

  if (isExternal)
    return <a href={props.to as string} {...props} >{props.children}</a>;

  const linkProps = { ...props, to: props.to ??  '#' };
  return <Link {...linkProps} />;
};