import { combineReducers } from 'redux';
import { pricingRulesReducer } from '../pricing-rules/rulesSlice';
import { subscriptionsReducer } from '../subscriptions/subsSlice';
import { servicesReducer } from '../subscriptions/subsSlice';
import { paymentMethodConfiguration } from '../subscriptions/subsSlice';
import { userAssistantsReducer } from '../va-profiles/vaProfilesSlice';
import { dispatchOrdersReducer } from '../DispatchOrders/DispatchOrdersSlice';
import { sourcesConfigReducer } from '../source-configuration/source-configuration-slice';
import { sourcesReducer } from '../sources/sourceSlice';
import { channelsReducer } from '../channels/channelsSlice';
import { listingsReducer } from '../listings/listings-slice';
import { notificationsReducer } from '../notifications/notificationsSlice';
import { templatesReducer } from '../templates/templatesSlice';
import { paymentReducer } from '../payment/paymentSlice';
import { catalogProductsReducer, catalogListProductsReducers } from '../catalog/catalogSlice';
import { ordersReducer } from '../orders/orderSlice';
import { orderAddressReducer } from '../orders/orderSlice';
import { orderProgressReducer } from '../orders/orderSlice';
import { salesReducer } from '../sales/salesSlice';
import { getAutoOrderingReducer } from '../auto-ordering/autoOrderingSlice';
import { listingServicesReducer } from '../dashboard/listingServicesSlice';
import { noApiServersReducer, getManagedServersReducer } from '../dashboard/noApiServersSlice';
import { channelConfigurationReducer } from '../channel-configuration/channels-configuration-slice';
import { accountConfigurationReducer } from '../account-configuration/account-configuration-slice';
import { affiliatesStatsReducer } from '../dashboard/affiliatesStatsSlice';
import { affiliatesDashboardReducer } from '../dashboard/affiliatesStatsSlice';
import { getAutoOrderFeesReducer } from '../auto-ordering/autoOrderingSlice';
import { newChannelReducer } from '../new-channel/newChannelSlice';
import { PriceWarriorReducer } from '../price-warrior/priceWarriorSlice';
import { autoListReducer } from '../listings/auto-list-slice';
import { UIPreferences } from '../ui-preferences/ui-preferences-state-slice';
import { importListingReducer } from '../import-listings/importListingSlice';
import { manualListingReducer } from '../listings/manual-listing-slice';
import { prizesReducer } from '../dashboard/prizesSlice';
import { checkoutReducer } from '../checkout/checkoutSlice';
import { userReducer } from '../user/userSlice';
import { smartListerReducer } from '../smart-lister/smart-slice';

export const allReducers = combineReducers({
  user: userReducer,
  pricingRules: pricingRulesReducer,
  subscriptions: subscriptionsReducer,
  services: servicesReducer,
  priceWarrior: PriceWarriorReducer,
  paymentMethodConfiguration: paymentMethodConfiguration,
  userAssistants: userAssistantsReducer,
  DispatchOrders: dispatchOrdersReducer,
  sources: sourcesReducer,
  sourcesConfiguration: sourcesConfigReducer,
  channels: channelsReducer,
  channelConfiguration: channelConfigurationReducer,
  accountConfiguration: accountConfigurationReducer,
  payment: paymentReducer,
  autoList: autoListReducer,
  listings: listingsReducer,
  notifications: notificationsReducer,
  templates: templatesReducer,
  catalogProducts: catalogProductsReducer,
  catalogListProducts: catalogListProductsReducers,
  orders: ordersReducer,
  orderAddress: orderAddressReducer,
  orderProgress: orderProgressReducer,
  sales: salesReducer,
  autoOrdering: getAutoOrderingReducer,
  listingServices: listingServicesReducer,
  noApiServers: noApiServersReducer,
  affiliatesStats: affiliatesStatsReducer,
  affiliatesDashboard: affiliatesDashboardReducer,
  AOFees: getAutoOrderFeesReducer,
  managedServers: getManagedServersReducer,
  newChannel: newChannelReducer,
  UIPreferences: UIPreferences,
  importLists: importListingReducer,
  manualListing: manualListingReducer,
  prizes: prizesReducer,
  checkout: checkoutReducer,
  smartLister: smartListerReducer
});
