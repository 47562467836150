import catalog_icon from '../../assets/channel/list/Group 2.png';
import manual_icon from '../../assets/channel/list/Group 147.png';
import bulk_icon from '../../assets/channel/list/Group 4.png';
import we_icon from '../../assets/channel/list/Group 148.png';
import { T } from '../../utils/transShim';
import { Button, Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import '../../sass/list-now/list-now.scss';
import { Links } from '../../links';
import { A } from '../../small-components/A';

interface channel {
  id: number;
  channelId: number;
}

export const ListNow = (/*props: props*/) => {
  const history = useHistory();
  const allChannels = JSON.parse(JSON.parse(localStorage.getItem('persist:root') ?? '')['channels'])[
    'channels'
  ] as channel[];
  const selectedChannel = localStorage.getItem('channelId');
  const channel = allChannels.filter(function (data) {
    return data.id.toString() == selectedChannel;
  })[0];
  const weList4u = channel?.channelId != 4;

  const routeChange = (route: string) => {
    history.push(route);
  };

  return (
    <div className="listnow-container">
      <h2 className="title"><T k='ListNow.Header' /></h2>
      <div className="choose-list">
        <Row>
          <Col md={8} xs={24}>
            <div className="list-card" onClick={() => routeChange(Links.Catalog)}>
              <img src={catalog_icon} alt="icon" className={'w-md-100 filter-white'} />
              <div className="card-info">
                <h5><T k='Catalog.Name' /></h5>
                <p>
                  <T k='ListNow.Catalog.Description' />{' '}
                  <span>
                    <A>
                      <ArrowRightOutlined style={{fontSize: '19px'}}/>
                    </A>
                  </span>
                </p>
              </div>
            </div>
          </Col>

          <Col md={8} xs={24}>
            <div className="list-card" onClick={() => routeChange(Links.ManualPublish)}>
              <img src={manual_icon} alt="icon" />

              <div className="card-info">
                <h5><T k='ListNow.ManualListing.Name'/></h5>
                <p>
                  <T k='ListNow.ManualListing.Description' />{' '}
                  <span>
                    <A to="#">
                      <ArrowRightOutlined style={{fontSize: '19px'}}/>
                    </A>
                  </span>
                </p>
              </div>
            </div>
          </Col>

          <Col md={8} xs={24}>
            <div className="list-card" onClick={() => routeChange(Links.BulkPublish)}>
              <img src={bulk_icon} alt="icon" className={'w-md-100 filter-white'} />

              <div className="card-info">
                <h5><T k='BulkListing.Name' /></h5>
                <p>
                  <T k='ListNow.BulkListing.Description' />{' '}
                  <span>
                    <A to="#">
                      <ArrowRightOutlined style={{fontSize: '19px'}}/>
                    </A>
                  </span>
                </p>
              </div>
            </div>
          </Col>
        </Row>

        {weList4u && (
          <Row>
            <Col span={24}>
              <div className="list-card" onClick={() => window.open(Links.ListingService)}>
                <h3 className="title"><T k='Listnow.WeListForYou.Title' /></h3>
                <img src={we_icon} alt="icon" />

                <div className="card-info">
                  <h5><T k='WeListForYou.Name' /></h5>
                  <p>
                    <T k='Listnow.WeListForYou.Description' />
                    <span>
                      <A to="#">
                        <ArrowRightOutlined style={{fontSize: '19px'}}/>
                      </A>
                    </span>
                  </p>
                  <Button className="success-btn"><T k='Listnow.WeListForYou.Button' /> </Button>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};
