import { createSlice } from '@reduxjs/toolkit';
import { ComListing } from '../../components/extension/new-listing-form/com-listing';
import { ErrorType } from '../error-type';
import { Problem, ProcessListData } from './manual-listing-thunk';

export enum ListingMethod {
  Unknown = 0,
  CSS = 1,
  ProductSource = 2,
  Scraping = 3
}
export type DebugData = {
  cssConfig: string;
  method: ListingMethod;
  details: string[];
  errorMessage: string;
}

export type ManualListingState = {
  procesingList: {
    [url:string]: {
      loading: boolean;
      success?: boolean;
      errorType?: ErrorType;
      problems?: Problem[] | Problem;
      comListing?: ComListing;
      debug?: DebugData;
    }
  };
}
const initialState: ManualListingState = {
  procesingList: {}
};

export const manualListing = createSlice({
  name: 'ManualListing',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ProcessListData.pending, (state, { meta }) => {
      if (!state.procesingList)
        state.procesingList = {};
      state.procesingList[meta.arg.url ?? ''] = { loading: true };
    });
    builder.addCase(ProcessListData.fulfilled, (state, { payload, meta }) => {
      const d = {
        loading: false,
        success: payload.success,
        comListing: payload.comListing,
        problems: payload.problems,
        errorType: payload.errorType,
        debug: payload.debug
      };
      state.procesingList[meta.arg.url ?? ''] = d;
    });
    builder.addCase(ProcessListData.rejected, (state, { meta }) => {
      state.procesingList[meta.arg.url ?? ''] = {
        loading: false,
        errorType: ErrorType.Unknown
      };
    });
  }
});

export const { reducer: manualListingReducer } = manualListing;
