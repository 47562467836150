import { Collapse, Spin } from 'antd';
import { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { Links } from '../../links';
import { ErrorType } from '../../redux/error-type';
import { DebugData, ListingMethod } from '../../redux/listings/manual-listing-slice';
import { Problem, ProcessListData } from '../../redux/listings/manual-listing-thunk';
import { ReactUtils } from '../../utils/react-utils';
import { NewListingForm } from './new-listing-form';
import '../../sass/listings/new-listing-extension.scss';
import { GetPlatform } from '../../data/platforms';
import { CountriesRelation, eCountry } from '../../data/countries';
import { NewListingBulkLinks } from './bulkLinks/new-listing-bulk-links';
import { ProcessHTML } from './process-html';
import { A } from '../../small-components/A';

export const NewListingExtension = () => {
  const channel = ReactUtils.GetSelectedChannel();
  const platform = GetPlatform(channel?.channelId);
  const [dataSet, SetDataSet] = useState<boolean>(false);
  const [url, SetUrl] = useState<string | null>(null);
  const [p, SetP] = useState<string | null>(null);
  const [html, SetHTML] = useState<string | null>(null);

  const dispatch = useAppDispatch();
  const { procesingList } = useAppSelector((state) => state.manualListing);
  const data = procesingList?.[url ?? ''];

  const HandleClick = async () => {
    let rp = p;
    if ((rp == null || rp == '') && html) {
      rp = JSON.stringify({ scrapingData: await ProcessHTML(dispatch, url ?? '', channel?.isoCountry, html) });
    }
    await dispatch(ProcessListData({
      url,
      p: rp ?? '{}'
    }));
    SetDataSet(true);
  };

  const HandleChangeUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    SetUrl(e.currentTarget.value);
  };

  const HandleChangeHTML = (e: React.ChangeEvent<HTMLInputElement>) => {
    SetHTML(e.currentTarget.value);
  };
  const HandleChangeP = (e: React.ChangeEvent<HTMLInputElement>) => {
    SetP(e.currentTarget.value);
  };


  const HandleChangeJSON = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = JSON.parse(e.currentTarget.value);
    const rp = JSON.stringify({ scrapingData: await ProcessHTML(dispatch, data.url ?? '', channel?.isoCountry, data.html) });
    SetUrl(data.url);
    SetHTML(data.html);
    await dispatch(ProcessListData({
      url: data.url,
      p: rp ?? '{}'
    }));
    SetDataSet(true);
  };

  if (!channel || !dataSet || !data) {
    //if (!url && !idT) {
    //  const nidT = window.setTimeout(() => {
    //    if (!(document.querySelector('#ext_json') as HTMLInputElement | undefined | null)?.value) {
    //      location.reload();
    //    }
    //  }, 10000);
    //  setIdT(nidT);
    //}
    return (
      <>
        <Spin />
        <input type="hidden" id="ext_url" onChange={HandleChangeUrl} onInput={HandleChangeUrl} /> {/* obsolete */}
        <input type="hidden" id="ext_p" onChange={HandleChangeP} onInput={HandleChangeP} /> {/* obsolete */}
        <input type="hidden" id="ext_html" onChange={HandleChangeHTML} onInput={HandleChangeHTML} /> {/* obsolete */}
        <input type="hidden" id="ext_json" onChange={HandleChangeJSON} onInput={HandleChangeJSON} />
        <button style={{ display: 'none' }} id="ext_data_received" onClick={HandleClick}> {/* obsolete */}
          x
        </button>
      </>
    );
  }

  const RenderError = (e: ErrorType, otherCountry: eCountry | undefined, otherLinks: string[] | undefined) => {
    switch (e) {
      case ErrorType.ChannelDisabled:
        return <span>This channel is disabled.</span>;
      case ErrorType.UnknownProduct:
        return (
          <>
            <h2 className="text-warning">Unable to obtain the details of this product.</h2>
            <p>There is something about this particular product that is preventing our system from obtaining its details.</p>
            <p>Our team has already been notified about this and will investigate it.</p>
          </>
        );
      case ErrorType.UnknownSource:
      {
        const oc = (otherCountry != null) ? CountriesRelation.find(x => x.id == (eCountry[otherCountry]).replace('UK', 'GB'))?.name : null;
        if (otherCountry != null && oc != null) {
          return <>
            <h3>You are currently in a channel for the following country {CountriesRelation.find(x => x.id == (eCountry[channel.isoCountry]).replace('UK', 'GB'))?.name}<br />
              The website you are trying to access is only availalbe for the following country {oc}</h3>
          </>;
        }
        if (otherLinks != null && otherLinks.length > 0) {
          return <NewListingBulkLinks urls={otherLinks}/>;
        }
        return (
          <>
            <h3>Oops!We don&apos;t know how to obtain the information from this page yet!</h3>
            <div className="infoContent">
              <h3 style={{ marginTop: 0 }}>
                      Please <strong>make sure</strong> that:
              </h3>
              <ul>
                <li>
                  <h4>You are on a product page when you click on the list extension.</h4>
                </li>
                <li>
                  <h4>You have selected the product variants before clicking on the extension.</h4>
                </li>
                <li>
                  <h4>
                    The supplier you are trying to source from is supported.{' '}
                    <A to="https://hustlegotreal.com/en/suppliers/" rel="noreferrer" target="_blank">
                      Please check our supported suppliers here.
                    </A>
                  </h4>
                </li>
              </ul>
              <A
                to={Links.Services.PrivateSupplier}
                target="_blank"
                rel="noreferrer"
                style={{ marginTop: 10 }}
                className="btn btn-primary"
              >
                      Request a private supplier
              </A>
            </div>
          </>
        );
      }
      case ErrorType.WrongChannel:
        return (
          <>
            <h2 className="text-warning">You need to add a channel before start listing</h2>
            <p>
              <A to={Links.NewChannel.Link}>Please click here to add a channel to your account</A>
            </p>
          </>
        );
      default:
      case ErrorType.Unknown:
        return <>Unknown error</>;
    }
  };
  const RenderProblem = (p: Problem) => {
    switch (p) {
      case Problem.DuplicatedItem:
        return (
          <>
            <h2 className="text-warning">This product is already in your account.</h2>
            <p>You can&apos;t add the same product two times to an {platform.storeName} account.</p>
          </>
        );
      case Problem.ForbiddenWordsInUrl:
        return (
          <>
            <h2 className="text-warning">This url contains forbidden words.</h2>
            <p>
              The url that you are trying to list contains forbidden words. This forbidden words can be changed in your{' '}
              <A to={Links.Configuration.Channel.Product}>
                channel settings
              </A>
              .
            </p>
          </>
        );
      case Problem.SubscriptionLimit:
        return (
          <>
            <h2 className="text-warning">You have reached your subscription limit!</h2>
            <p>
              <A to={Links.Subscriptions}>Please visit your account subscription page to select a new subscription plan</A>
            </p>
          </>
        );
      default:
        return <></>;
    }
  };
  const RenderProblems = (p: Problem[] | Problem) => {
    if (Array.isArray(p))
      return <>{p.map(x => RenderProblem(x))}</>;
    return RenderProblem(p);
  };

  const Debug = (d?: DebugData) => {
    if (!d)
      return <></>;
    return (
      <Collapse defaultActiveKey={[]}>
        <Collapse.Panel header="View Debug" key={1}>
          <table className="table table-condensed">
            <tbody>
              <tr>
                <td>User Logs</td>
                <td>
                  <A to='#'>View</A>
                </td>
              </tr>
              <tr>
                <td>Listing Method</td>
                <td>{ListingMethod[d.method]}</td>
              </tr>
              <tr>
                <td>Debug</td>
                <td>
                  {(d.details?.length ?? 0) == 0 && <p>The CSS Configuration seems to be correct.</p>}
                  {d.details?.length > 0 && d.details.map((x, i) => <p key={i}>{x}</p>)}
                </td>
              </tr>
              <tr>
                <td>Css Configuration</td>
                <td>
                  <div style={{ whiteSpace: 'pre' }}>
                    {!!d.cssConfig && JSON.stringify(JSON.parse(d.cssConfig), null, 2)}
                  </div>
                </td>
              </tr>
              <tr>
                <td>Error message</td>
                <td>{d.errorMessage}</td>
              </tr>
            </tbody>
          </table>
        </Collapse.Panel>
      </Collapse>
    );
  };

  if (data.errorType != null)
    return <>
      {RenderError(data.errorType, data?.comListing?.sourceFromOtherSite, data?.comListing?.otherUrls)}
      {Debug(data?.debug)}
    </>;

  if (data.problems != null)
    return <>
      {RenderProblems(data.problems)}
      {Debug(data?.debug)}
    </>;

  if (data?.comListing == null || !data.comListing.validProduct)
    return <>
      {RenderError(ErrorType.Unknown, data?.comListing?.sourceFromOtherSite, data?.comListing?.otherUrls)}
      {Debug(data?.debug)}
    </>;

  return <>
    <NewListingForm key={channel.id} selectedChannel={channel} data={data.comListing} />
    {Debug(data?.debug)}
  </>;
};

