import { createAsyncThunk } from '@reduxjs/toolkit';
import { FullProductSource } from '../../components/catalog/Types';
import { client } from '../client';
export const GetDashboardSuggestedProducts = createAsyncThunk(
  'Catalog/GetCatalogDashboardProducts',
  async (_, thunkAPI ) => {
    try {
      const res = await client.get('/Catalog/GetCatalogDashboardProducts');
      return res.data.response_data.products;
    } catch (error) {
      return thunkAPI.rejectWithValue('Sorry! Something went wrong');
    }
  }
);
export const GetCatalogProducts = createAsyncThunk(
  'Catalog/GetProducts',
  async (
    {
      sessionId,
      titleContains,
      priceFrom,
      priceTo,
      profitFrom,
      profitTo,
      options,
      order,
      suppliersCount,
      warehouse
    }: {
      sessionId: number;
      titleContains?: string | null;
      priceFrom?: number | null;
      priceTo?: number | null;
      profitFrom?: number | null;
      profitTo?: number | null;
      options?: number | null;
      order: number;
      suppliersCount: number[],
      warehouse?: string | null
    },
    thunkAPI
  ) => {
    try {
      const res = await client.post('/Catalog/GetProducts', {
        sessionId,
        titleContains,
        priceFrom,
        priceTo,
        profitFrom,
        profitTo,
        options,
        order,
        sourceIds: suppliersCount,
        warehouses: warehouse ? [ warehouse ] : undefined
      });
      return res.data.response_data.products;
    } catch (error) {
      return thunkAPI.rejectWithValue('Sorry! Something went wrong');
    }
  }
);

export const GetBulkListingStatus = createAsyncThunk('catalog/NewGetListingStatus', async (_, thunkAPI) => {
  try {
    const res = await client.get('/Catalog/NewGetListingStatus');
    return res.data.response_data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export enum CatalogOrigin {
  CATALOG = 0,
  FIRST_STEPS = 1 
}
export const ListProducts = createAsyncThunk(
  'catalog/listProducts',
  async (
    {
      productsToList,
      needsReview,
      optimizeTitle,
      optimizeDescription,
      publishVariations,
      delaysMap,
      origin
    }: {
      productsToList?: { id: number; title: string; publishNow?: Date; }[], 
      needsReview: boolean
      optimizeTitle: boolean,
      optimizeDescription: boolean,
      publishVariations: boolean,
      delaysMap: { [id: number]: Date },
      origin: CatalogOrigin
    },
    thunkAPI
  ) => {
    try {
      const res = await client.post('/Catalog/SubmitCatalog', { needsReview, productsToList, optimizeTitle, optimizeDescription, publishVariations, delaysMap, origin });
      return res.data.response_data;
    } catch (error) {
      return thunkAPI.rejectWithValue('Sorry! Something went wrong');
    }
  }
);

export const GetProductSource = createAsyncThunk(
  'Catalog/GetProductSource',
  async (
    { id } :  {id:number},
    thunkAPI
  ) => {
    try {
      const res = await client.get('/Catalog/GetProductSource?id=' + id);
      return res.data.response_data as FullProductSource | undefined;
    } catch (error) {
      return thunkAPI.rejectWithValue('Sorry! Something went wrong');
    }
  }
);