import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import '../../sass/listing-service/configure-listing-service.scss';
import { useEffect } from 'react';
import { getSources } from '../../redux/sources/sourcesThunk';
import { ConfigureListingServiceCore } from './configure-listing-service-core';
import { Spin } from 'antd';
import { GetListingServices } from '../../redux/dashboard/listingServicesThunk';
import { ReactUtils } from '../../utils/react-utils';

export const ConfigureListingService = () => {
  const dispatch = useAppDispatch();
  const { serviceSlice } = useAppSelector((state) => state.listingServices);
  const { sources, loading: loadingSources } = useAppSelector((state) => state.sources);
  const channel = ReactUtils.GetSelectedChannel();

  useEffect(() => {
    if (!!sources && sources.length > 0 && sources[0].site == channel?.isoCountry?.toString())
      return;
    if (loadingSources)
      return;
    dispatch(getSources());
  }, [channel?.id]);

  useEffect(() => {
    dispatch(GetListingServices());
  }, [GetListingServices]);

  if (serviceSlice == null || serviceSlice.loading || loadingSources || !sources || !serviceSlice.data || sources.length == 0)
    return <Spin />;

  return <ConfigureListingServiceCore listingServicesResult={serviceSlice.data} loading={false} sources={sources}/>;
};
