import { Col, Row } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../custom-hooks/reduxCustomHooks';
import { UserUtil } from '../../../utils/user';
import '../../../sass/unlock-bar.scss';
import { T } from '../../../utils/transShim';
import star from '../../../assets/icons/star.png';
import coinIcon from '../../../assets/icons/token2_0.png';
import { CheckOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { ClaimPrize, TaskPrizes } from '../../../redux/dashboard/prizesThunk';
import { getUserToken } from '../../../redux/user/userThunk';
import { ReactUtils } from '../../../utils/react-utils';
import { ePlatform } from '../../../data/platforms';

export const UnlockBar = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { available } = useAppSelector((state) => state.prizes);
  const channel = ReactUtils.GetSelectedChannel();

  useEffect(() => {
    if (!(available?.loading ?? false)) {
      dispatch(TaskPrizes());
    }
  }, []);

  const timeSince = UserUtil.TimeSinceCreation(user.user);
  if (timeSince == null || timeSince > 35) {
    return <></>;
  }

  const steps = Array.from({ length: 30 }, (_, i) => i + 1);


  const RenderStep = (x: number) => {
    let isReward = false;
    let tokenDay = false;
    let lastDay = false;
    switch (x) {
      case 10:
      case 20:
      case 29:
        isReward = true;
        tokenDay = true;
        break;
      case 30:
        isReward = channel?.channelId != ePlatform.Amazon;//Amazon last day will be like any other day
        lastDay = channel?.channelId != ePlatform.Amazon;//Amazon last day will be like any other day
        break;
    }
    const isActive = timeSince >= x;

    let toClaim = false;
    if (available?.data?.prizes != null)
      if (
        (x == 10 && timeSince >= 10 && available.data.prizes.find(y => y.id == 2) != null)
        || (x == 20 && timeSince >= 20 && available.data.prizes.find(y => y.id == 3) != null)
        || (x == 29 && timeSince >= 29 && available.data.prizes.find(y => y.id == 4) != null)
      )
        toClaim = true;

    let className = 'ant-progress-steps-item';
    if (isActive)
      className += ' ant-progress-steps-item-active';
    if (isReward)
      className += ' reward-step';
    if (toClaim)
      className += ' toClaim';

    const RenderClaimed = () => {
      if (available?.data?.prizes == null)
        return <></>;
      if (
        (x == 10 && timeSince >= 10 && available.data.prizes.find(y => y.id == 2) == null)
        || (x == 20 && timeSince >= 20 && available.data.prizes.find(y => y.id == 3) == null)
        || (x == 29 && timeSince >= 29 && available.data.prizes.find(y => y.id == 4) == null)
      )
        return <CheckOutlined />;
      return <></>;
    };

    const HandleClick = async () => {
      if (toClaim)
        return;

      let id = 0;
      switch (x) {
        case 10:
          id = 2; break;
        case 20:
          id = 3; break;
        case 29:
          id = 4; break;
      }

      if (id == 0)
        return;

      await dispatch(ClaimPrize({ id: id }));
      dispatch(getUserToken());
    };

    return <div key={x} className={className} onClick={() => HandleClick()}>
      <Row>
        <Col span={24} className="day">
          {x}
        </Col>
      </Row>
      <Row>
        <Col span={24} className={(!isReward && isActive ? 'tick' : '')}>
          {tokenDay && <img src={coinIcon} className="token-icon" alt="coinIcon" />}
          {lastDay && <img src={star} />}
          {!isReward && isActive && <CheckOutlined/>}
        </Col>
      </Row>
      {isReward && <Row className="text">
        <Col span={24}>
          {tokenDay && RenderClaimed()}
          {lastDay && <T k="Dashboard.unlockbar.30days" />}
        </Col>
      </Row>
      }
    </div>;
  };


  let daysToReward = 0;
  if (timeSince < 10)
    daysToReward = 10 - Math.floor(timeSince);
  else if (timeSince < 20)
    daysToReward = 20 - Math.floor(timeSince);
  else if (timeSince < 29)
    daysToReward = 29 - Math.floor(timeSince);
  else if (timeSince < 30)
    daysToReward = 1;

  return <div className="UnlockBar">
    <Row>
      <Col span={24}>
        {daysToReward > 0 && <T k="Dashboard.unlockbar.xdaysto" values={{ x: daysToReward }} />}
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <div className="ant-progress-steps-outer">
          {steps.map(RenderStep)}
        </div>
      </Col>
    </Row>
  </div>;
};
