import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '../../../../custom-hooks/reduxCustomHooks';
import { SaveListingChanges } from '../../../../redux/listings/listings-thunk';
import { A } from '../../../../small-components/A';
import { LoadingModal } from '../../../../small-components/modals/loading-modal';
import { ListingsUtils } from '../../../../utils/listings-utils';
import { ReactUtils } from '../../../../utils/react-utils';
import { T } from '../../../../utils/transShim';
import { ActiveListingExtended } from '../types';

type Props = {
  listing: ActiveListingExtended;
}

export const LowestPriceCell = (props: Props) => {
  const { listing } = props;
  const { isLowestPrice, channelPrice, asin, sourcePrice, sourceId, ignoreRules, pricingRules, lowestPrice, channelListingId } = listing;

  const { saving } = useAppSelector((state) => state.listings);
  const [savingChanges, SetSavingChanges] = useState<boolean>(false);

  const RenderIsLowestPrice = () => {
    return (
      <div className='bestPrice'>
        <A
          to={'https://www.amazon.co.uk/gp/offer-listing/' + asin}
          target='_blank'
          rel='noreferrer'
          onClick={ReactUtils.OnClickNoPropagate}
        >
          <span className='glyphicon glyphicon-ok'></span>
          <T k='Listings.Value.Lowest' />
        </A>
      </div>
    );
  };

  const dispatch = useDispatch();
  const SetPrice = (newPrice: number) => {
    SetSavingChanges(true);
    dispatch(SaveListingChanges({
      channelListingId: channelListingId,
      markup: { value: ListingsUtils.CalculateMarkupFromSellPrice(sourcePrice, newPrice) },
      price: newPrice
    }));
  };

  const BeatPrice = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    SetPrice((lowestPrice ?? channelPrice) - 0.01);
  };

  const MatchPrice = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    SetPrice(lowestPrice ?? channelPrice);
  };

  const RenderLoadingModal = () => {
    if (saving == null)
      return <></>;

    const HandleClose = () => {
      if (saving != null && !saving.loading && saving.success == false) {//Error
        location.reload();
        return;
      }

      SetSavingChanges(false);
    };

    return (
      <LoadingModal
        visible={savingChanges}
        loading={saving.loading}
        title={<T k='Setting.SavingChanges' />}
        onClose={HandleClose}
        result={saving.success ? <T k='Setting.Saved' /> : <T k='Setting.ErrorSavingUnknownError' />}
      />
    );
  };

  const RenderDropDown = () => {
    let hasRules = false;
    if (sourcePrice && sourceId && !ignoreRules) {
      hasRules = ListingsUtils.GetMarkupFromPricingRules(sourcePrice, pricingRules) != null;
    }

    const menu = (<Menu
      items={[
        {
          key: '1',
          label: (
            <A to='#' onClick={(e) => BeatPrice(e)}>
              {hasRules ? <T k='Listings.Value.BeatIgnoreRules' /> : <T k='istings.Value.Beat' />}
            </A>
          )
        },
        {
          key: '2',
          label: (
            <A to='#' onClick={(e) => MatchPrice(e)}>
              {hasRules ? <T k='Listings.Value.MatchIgnoreRules' /> : <T k='Listings.Value.Match' />}
            </A>
          )
        }
      ]}
    />);

    return (<>
      <Dropdown arrow overlay={menu}>
        <span>
          {' '}
          <DownOutlined />
        </span>
      </Dropdown>
      {RenderLoadingModal()}
    </>);
  };

  const RenderIsNotLowestPrice = () => {
    return (
      <div className='bestPrice'>
        <A
          to={
            'https://www.amazon.co.uk/gp/offer-listing/' + asin + '?ref=myi_lowprice_offer'
          }
          target='_blank'
          rel='noreferrer'
          onClick={ReactUtils.OnClickNoPropagate}
        >
          {lowestPrice}
        </A>
        {RenderDropDown()}
      </div>
    );
  };

  if (isLowestPrice || (channelPrice ?? 0) <= (lowestPrice ?? -1)) {
    return RenderIsLowestPrice();
  } else if (lowestPrice) {
    return RenderIsNotLowestPrice();
  } else {
    return <>-</>;
  }
};