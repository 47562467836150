import { client } from '../client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserSupplierSettingsKeys } from '../../data/autoorder-fields';

export type SettingsValue = string | undefined;
export type RawSettingData = {
  key: UserSupplierSettingsKeys;
  value?: SettingsValue;
}
export enum UserSupplierType {
  USER = 0,
  HGR = 1
}
export type AutoOrderingConfigurationAccountData = {
  id: number
  channelOAuthId: number;
  sourceId: number;
  createdOn: Date | string;
  rawSettings: RawSettingData[];
  type?: UserSupplierType;
}
export type autoOrderingType = Date | string | null | number | boolean | RawSettingData[];


export const SaveAutoOrderingAccountConfiguration = createAsyncThunk('sales/saveAutoOrder', async (
  {
    supplierId,
    sourceId,
    channelOAuthId,
    rawSettings,
    type
  }: {
    channelOAuthId: number;
    supplierId: number;
    sourceId: number;
    rawSettings: RawSettingData[];
    type?: UserSupplierType;
  },
  thunkAPI
) => {
  try {
    const res = await client.post('/AutoOrderingConfiguration/SaveAutoOrdering', {
      supplierId,
      sourceId,
      channelOAuthId,
      rawSettings,
      type
    });
    return res.data.response_data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
}
);

export const GetAutoOrderingConfiguration = createAsyncThunk('AutoOrderingConfiguration/GetAutoOrdering', async () => {
  try {
    const res = await client.get('/AutoOrderingConfiguration/GetAutoOrdering');
    return (res.data.response_data?.suppliers ?? []) as AutoOrderingConfigurationAccountData[];
  } catch (error) {
    console.log('The error is ', error);
  }
});

export const DeleteAutoOrderingAccount = createAsyncThunk('AutoOrderingConfiguration/RemoveAutoordering', async (id: number) => {
  try {
    const res = await client.post('/AutoOrderingConfiguration/RemoveAutoordering', { id });
    return res.data.response_data as { success: boolean };
  } catch (error) {
    return { success: false };
  }
});

export const getAutoOrderFees = createAsyncThunk('/Billing/GetAutoOrderFees', async (_, { rejectWithValue }) => {
  try {
    const res = await client.get('/Billing/GetAutoOrderFees');
    return res.data.response_data;
  } catch (error) {
    return rejectWithValue('Sorry! Something went wrong');
  }
});

export const TaskPendingAutoOrder = createAsyncThunk('/Dashboard/TaskPendingAutoOrder', async (_, { rejectWithValue }) => {
  try {
    const res = await client.get('/Dashboard/TaskPendingAutoOrder');
    return res.data.response_data;
  } catch (error) {
    return rejectWithValue('Sorry! Something went wrong');
  }
});