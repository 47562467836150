import { useEffect, useState } from 'react';
import { Col, Row, List /* Need it for TimeAndMoneySaved Popover*/ } from 'antd';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';

import { SocialIcon } from 'react-social-icons';
import moment from 'moment';
import { getChannels } from '../../redux/channels/channelsThunk';
import { getSales } from '../../redux/sales/salesThunk';
import { Sale, ePeriod } from 'src/redux/sales/salesSlice';
import { getNoApiServers } from 'src/redux/dashboard/noApiServersThunk';
import { GetListingServices } from 'src/redux/dashboard/listingServicesThunk';
import { ListingService } from 'src/redux/dashboard/listingServicesSlice';
import { NoApiServer } from 'src/redux/dashboard/noApiServersSlice';
import { ArrowRightOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import '../../sass/dashboard.scss';
import '../../sass/modal-datepicker.scss';
import '../../sass/action-btns.scss';
import { BuyTokensModal } from '../../small-components/modals/buy-tokens-modal';
import { Range } from 'react-date-range';
import { Links } from '../../links';
import { ReactUtils } from '../../utils/react-utils';
import { DateRangeInput } from '../../small-components/form/date-range-input';
import { ShopLogo } from '../../utils/shopLogo';
import { Countries } from '../../data/countries';
import bannerAi2 from '../../../src/assets/banners/banner_ai_2_0.png';
import tokenCoin from '../../assets/icons/token2_0.png';
import { PopupModal } from 'src/small-components/modals/popup-modal';
import { AffiliatesPanel } from './panels/affiliates-panel';
import { QuotaPanel } from './panels/quota-panel';
import { SuccessBtn } from '../../small-components/ActionBtns';
import { TasksPanel } from './panels/tasks-panel';
import { A } from '../../small-components/A';
import { ChartArea } from '../../small-components/charts/chart-area';
import { ePlatform } from '../../data/platforms';

export const DashboardList = () => {
  const dispatch = useAppDispatch();
  const { noApiServersResult } = useAppSelector((state) => state.noApiServers);
  const { serviceSlice } = useAppSelector((state) => state.listingServices);
  const { sales } = useAppSelector((state) => state.sales);
  const [selectedPeriod, setSelectedPeriod] = useState<number>(3);
  const [open, setOpen] = useState<boolean>(false);
  const handleOpenModal = () => setOpen(!open);
  const [openOptimization, setOpenOptimization] = useState<boolean>(false);
  const handleOpenOptimization = () => setOpenOptimization(!openOptimization);

  const channel = ReactUtils.GetSelectedChannel();

  const GetFirstDayOfMonth = (year: number, month: number) => new Date(year, month, 1);

  const date = new Date();
  const firstDayCurrentMonth = GetFirstDayOfMonth(date.getFullYear(), date.getMonth());

  useEffect(() => {
    dispatch(getChannels());
    dispatch(getNoApiServers());
    dispatch(GetListingServices());
  }, []);

  useEffect(() => {
    dispatch(
      getSales({
        period: selectedPeriod,
        from: firstDayCurrentMonth.toISOString(),
        to: new Date().toISOString(),
        timeDiff: new Date().getTimezoneOffset()
      })
    );
  }, [getSales]);

  const salesDateChange = async (value: number, dateString: [string, string]) => {
    if (Array.isArray(dateString)) {
      await dispatch(
        getSales({
          period: value,
          from: dateString[0],
          to: dateString[1],
          timeDiff: new Date().getTimezoneOffset()
        })
      );
    }
  };

  const totalProfit = sales?.reduce((total: number, sale: Sale) => {
    return (total += (sale.revenue ?? 0) - ((sale.sourcePrice ?? 0) + (sale.totalTax ?? 0)));
  }, 0);

  const totalOrders = sales?.reduce((total: number, sale: Sale) => total + (sale.quantitySold ?? 0), 0);

  const noApiServerSubscription = (
    <div className="subscribe">
      <p>Do you want to keep your NO API extension running 24/7?</p>
      <p>We can do it for you for only 9GBP/month.</p>
      <A to="https://hustlegotreal.com/en/no-api-server/" rel="noreferrer" target={'_blank'}>
        <SuccessBtn>Subscribe</SuccessBtn>
      </A>
    </div>
  );

  const ListingServicesSubscription = () => {
    if (channel?.channelId == ePlatform.Amazon)
      return <></>;

    return <div className="list-permission">
      <p>Not sure what to list? We can help you find good selling items.</p>
      <p> We choose the best products and list them for you</p>
      <A to="https://hustlegotreal.com/en/listing-service/" rel="noreferrer" target={'_blank'}>
        <SuccessBtn>
          Yes! List for me
        </SuccessBtn>
      </A>
    </div>;
  };

  const xLabels = (() => {
    if (selectedPeriod) {
      switch (selectedPeriod) {
        case ePeriod.Hours:
          return [
            ...new Set(
              sales?.map((d: Sale) => {
                const date = new Date(d.date ? d.date : new Date());
                const hours = (date.getUTCHours() % 12 || 12) + (date.getUTCHours() < 12 ? 'AM' : 'PM');
                return hours + '-' + date.getUTCDate();
              })
            )
          ];
        case ePeriod.Days: {
          return [...new Set(sales?.map((d: Sale) => moment(d.date).format('DD-MMM')))];
        }
        case ePeriod.Weeks: {
          return [...new Set(sales?.map((d: Sale) => moment(d.date).format('DD-MMM')))];
        }
        case ePeriod.Months: {
          return [...new Set(sales?.map((d: Sale) => moment(d.date).format('MMM-YY')))];
        }
        case ePeriod.Year: {
          return [...new Set(sales?.map((d: Sale) => moment(d.date).format('YYYY')))];
        }
        default:
          break;
      }
    }
  })();

  const saleData: number[] = sales?.map((s: Sale) => s.quantitySold ?? 0);

  const profitData: number[] = sales?.map((s: Sale) => {
    const profit = (s.revenue ?? 0) - ((s.sourcePrice ?? 0) + (s.totalTax ?? 0));
    return Math.round((profit * 100) * (1 + Number.EPSILON)) / 100;
  });

  //const GenerateChartConfig = (data: number[], color: string, maxValue: number, yLabelTitle: string): AreaConfig => {
  //  return {
  //    data: data.map((x, i) => ({ date: xLabels?.[i] ?? '', value: x })),
  //    xField: 'date',
  //    yField: 'value',
  //    style: {
  //      fill: 'linear-gradient(-90deg, white 0%, ' + color + ' 100%)',
  //    },
  //    axis: {
  //      y: {
  //        tickCount: Math.min(4, Math.ceil(maxValue)),
  //        labelFormatter: maxValue < 10 ? '.1f' : '.0f'
  //      },
  //      x: {
  //        labelAutoRotate: false
  //      }
  //    },
  //    shapeField: 'smooth',
  //    line: {
  //      shapeField: 'smooth',
  //      style: {
  //        stroke: color,
  //        strokeWidth: 5,
  //      },
  //      tooltip: false
  //    },
  //    tooltip: (v: { value: number }) => ({ name: yLabelTitle, value: v.value })
  //  };
  //};

  const HandleSalesDateChange = (state: Range | null) => {
    if (state) {
      const startDate: Date = state.startDate ? state.startDate : moment.utc().month(-12).toDate();
      const endDate: Date = state.endDate ? state.endDate : moment.utc().toDate();
      const diff = Math.abs(startDate.getTime() - endDate.getTime());
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      const from = moment.utc(startDate).local().format('YYYY-MM-DD') + 'T00:00:00.000Z';
      const to = moment.utc(endDate).local().format('YYYY-MM-DD') + 'T00:00:00.000Z';

      if (diffDays < 3) {
        setSelectedPeriod(6);
        salesDateChange(6, [from, to]);
      } else if (diffDays > 2 && diffDays < 31) {
        setSelectedPeriod(3);
        salesDateChange(3, [from, to]);
      } else if (diffDays > 30 && diffDays < 400) {
        setSelectedPeriod(4);
        salesDateChange(4, [from, to]);
      } else {
        setSelectedPeriod(5);
        salesDateChange(5, [from, to]);
      }
    }
  };

  //Quick wins

  //Reactivate with new charts (Ask Dani)

  // const TimeAndMoneySaved = () => {
  //   const minutesSpend = (totalOrders ?? 0) * 5;
  //   const costVA = (minutesSpend / 60) * 5;
  //   const date = new Date(0);
  //   date.setMinutes(minutesSpend);
  //   const timeString = date.toISOString().substring(11, 19);

  //   const popOverAO = (
  //     <div className="popover-content">
  //       <p>
  //         This is a rough estimate but we calculate that with <A to={Links.Configuration.AutoOrdering.Main}>Auto Ordering enabled</A>{' '}
  //         you would have saved a total of time of {timeString} , so if you have a person hired to do the orders it would have been a total
  //         of{' '}
  //         <strong>
  //           {ReactUtils.GetCurrencySymbol()}
  //           {costVA.toLocaleString()}
  //         </strong>{' '}
  //         saved.
  //       </p>
  //     </div>
  //   );

  //   return (
  //     <Popover content={popOverAO}>
  //       <div className="ao-stats">
  //         <div className="field">
  //           <h4 className="title-ao">Time consumed:</h4>
  //           <h4 className="value-ao-time"> {timeString}</h4>
  //         </div>
  //         <div className="field">
  //           <h4 className="title-ao">Would save with auto ordering:</h4>
  //           <h4 className="value-ao">
  //             {ReactUtils.GetCurrencySymbol()}
  //             {costVA.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
  //           </h4>
  //         </div>
  //       </div>
  //     </Popover>
  //   );
  // };

  return (
    <div className="dashboard-container">
      <div className="dashboard-banner">
        <div className="dashboard-banner-content white-box">
          {/*<A to={Links.Products.Optimize}>*/}
          <A to="https://bit.ly/3RepZFn">
            <img className="banner" src={bannerAi2} />
          </A>
          {/*</A>*/}
        </div>
      </div>
      <div className="general-section">
        <h1>General</h1>
        <Row className="general-cols" gutter={[0, 15]}>
          <QuotaPanel />
          <TasksPanel />
        </Row>
      </div>
      <div className="general-section general-section">
        <h1>
          <A to={Links.Orders}>Auto Order</A> Processed Sales
        </h1>
        <div className="charts-sales white-box">
          <DateRangeInput
            onChange={HandleSalesDateChange}
            defaultRange={{
              startDate: firstDayCurrentMonth,
              endDate: new Date()
            }}
          />
          <Row className="general-cols" gutter={[0, 15]}>
            <Col className="white-box" xs={24} lg={10}>
              <Row>
                <Col xs={24} md={11}>
                  <h3>Total orders</h3>
                  <h2>{totalOrders ? totalOrders.toLocaleString(undefined, { maximumFractionDigits: 0 }) : '0'}</h2>
                </Col>
              </Row>
              <ChartArea
                data={saleData.map((x, i) => ({ x: xLabels?.[i] ?? '', y: x }))}
                color='#5e84db'
                tooltip={{
                  label: 'Quantity'
                }}
              />
            </Col>
            <Col className="white-box" xs={24} lg={10}>
              <h3>Total profit</h3>
              <h2>
                {ReactUtils.GetCurrencySymbol()}
                {(totalProfit ?? 0).toLocaleString(undefined,{ maximumFractionDigits: 0 })}
              </h2>
              <ChartArea
                data={profitData.map((x, i) => ({ x: xLabels?.[i] ?? '', y: x }))}
                color='#228b22'
                tooltip={{
                  label: 'Profit'
                }}
              />
              {/*<Chart options={profitChartData} series={profitChartData.series} type="line" width="100%" />*/}
            </Col>
          </Row>
        </div>
      </div>
      <div className="other-services general-section">
        <h1>Other Services</h1>
        <Row className="other-services-cols" gutter={[0, 15]}>
          <Col className="listing-service  white-box" xs={24} lg={10}>
            <div className="listing-service-title">
              <h3>Listing Service</h3>
              <A to="https://hustlegotreal.com/en/listing-service/" target="_blank" rel="noreferrer">
                <QuestionCircleOutlined style={{ fontSize: '19px' }} />
              </A>
            </div>
            {serviceSlice?.data?.length ? (
              <List
                itemLayout="horizontal"
                dataSource={serviceSlice?.data?.filter(x => x.endedOn == null)}
                header={<h4>Active services</h4>}
                renderItem={(l: ListingService) => (
                  <div key={l.id}>
                    <div className="item-description row">
                      <div className="service-quantity col-6">
                        <p>{l.listings} listing service</p>
                      </div>
                      <div className="col-6">
                        <p>
                          <A to={Links.ConfigureListingService}>Set up your preferences</A>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              />
            ) : (
              ListingServicesSubscription()
            )}
          </Col>

          <Col className="no-api-server white-box" xs={24} lg={10}>
            <div className="no-api-server-title">
              <h3>No API Server</h3>
              <A to="https://hustlegotreal.com/en/no-api-server/" target="_blank" rel="noreferrer">
                <QuestionCircleOutlined style={{ fontSize: '19px' }} />
              </A>
            </div>
            {noApiServersResult?.length > 0 ? (
              <List
                itemLayout="horizontal"
                header={
                  <Row className="no-api-title">
                    <Col sm={12}>
                      <h4>Connected channels</h4>
                    </Col>
                    <Col sm={6}>
                      <h4>Next Payment</h4>
                    </Col>
                    <Col sm={6}></Col>
                  </Row>
                }
                footer={
                  <div className="add-servers mt-5">
                    <A to="https://hustlegotreal.com/en/no-api-server/" rel="noreferrer" target="_blank" className="footer-link">
                      <PlusCircleOutlined style={{ fontSize: '16px' }} />
                      <h4>Add more servers</h4>
                    </A>
                  </div>
                }
                dataSource={noApiServersResult}
                renderItem={(s: NoApiServer) => {
                  if (!s.id)
                    return (
                      <Row>
                        <Col xs={12}>
                          <p>
                            <A to={Links.ConfigureNoapi}>Choose your channel</A>
                          </p>
                        </Col>
                        <Col xs={8} className="next-payment">
                          {(s.cancelled ? 'Cancelled. Ends on ' : ReactUtils.DrawPrice(s.price, s.currencyId) + ' on ') +
                            moment(s.nextPayment).add(-2, 'days').format('L')}
                        </Col>
                      </Row>
                    );
                  return (
                    <Row key={s.id} className="RowNoAPI">
                      <Col xs={12} className="store-item">
                        <div style={{ width: 20 }}>
                          <ShopLogo channelId={s.channelId} />
                        </div>
                        <div className="countryFlag">{Countries[s.isoCountry].Flag}</div>
                        {s.displayName}
                      </Col>

                      <Col xs={8} className="next-payment">
                        {(s.cancelled ? 'Cancelled. Ends on ' : ReactUtils.DrawPrice(s.price, s.currencyId) + ' on ') +
                          moment(s.nextPayment).add(-2, 'days').format('L')}
                      </Col>
                      <Col xs={4}>
                        <p>
                          <A to={Links.ConfigureNoapi}>Manage</A>
                        </p>
                      </Col>
                    </Row>
                  );
                }}
              />
            ) : (
              noApiServerSubscription
            )}
          </Col>

          <Col className="tokens white-box" xs={24} lg={10}>
            <BuyTokensModal open={open} onClose={handleOpenModal} />
            <PopupModal onClose={handleOpenOptimization} open={openOptimization}>
              <h3>Description to optimize:</h3>
              <p>Steam Hair Straightener & Styling Tool, For All Hair Types</p>
              <h3>Optimized description:</h3>
              <p>
                Introducing the Steam Hair Straightener & Styling Tool - the ultimate hair styling solution for all hair types. This
                innovative tool not only straightens hair, but also adds moisture and shine, leaving you with salon-quality results in the
                comfort of your own home.
              </p>
              <p>Some of the key benefits of this product are:</p>
              <ul>
                <li>
                  Infused with steam technology, it helps to add moisture to your hair, which is particularly beneficial for dry and damaged
                  hair.
                </li>
                <li>The unique design of the plates allows for even heat distribution and prevents hair from getting damaged.</li>
                <li>Suitable for all hair types, whether your hair is thick, curly, or straight.</li>
                <li>Multiple heat settings allow you to customize the styling experience to your specific hair type and desired look.</li>
                <li>The ergonomic design and easy-to-use interface make it simple for anyone to use, even for a novice.</li>
                <li>Can be used for more than just straightening, it can also be used to create curls, waves and other styles.</li>
                <li>Provides results that are comparable to salon-quality treatments.</li>
                <li>Faster styling time and saves time and money on salon visits</li>
                <li>Sleek and Portable, easily carried while traveling.</li>
              </ul>
              <p>
                Upgrade your hair styling game and achieve salon-worthy locks with our Steam Hair Straightener & Styling Tool - the ultimate
                styling solution for all hair types.
              </p>
            </PopupModal>
            <div className="ai-tokens-title">
              <h3 className="tokens-title">AI-Tokens - Title and description optimization</h3>
              <A to="https://hustlegotreal.com/en/hustle-got-real-ai-powered-tokens/" target="_blank" rel="noreferrer">
                <QuestionCircleOutlined style={{ fontSize: '19px' }} />
              </A>
            </div>
            <div className="tokens-count">
              <p>
                Unlock the power of AI to optimize your product titles and descriptions. We use AI to analyze and select the best keywords
                for your product.
              </p>
              <div className="tokens-example-container" onClick={handleOpenOptimization}>
                <div>
                  <p className="optimized">Try it!</p>
                </div>
                <div className="tokens-example">
                  <p className="no-optimized">Steam Hair Straightener & Styling Tool, For All Hair Types</p>
                  <ArrowRightOutlined className="arrow" />
                  <img src={tokenCoin} alt="" className="tokencoin" />
                </div>
              </div>

              <SuccessBtn onClick={handleOpenModal}>Get more tokens</SuccessBtn>
              <A to="/products" className="alternative-link">
                <p>Optimize your existing products</p>
              </A>
            </div>
          </Col>

          <Col className="auto-ordering white-box" xs={24} lg={10}>
            <div className="auto-ordering-title">
              <h3>Auto Ordering</h3>
              <A to="https://hustlegotreal.com/en/auto-ordering/" target="_blank" rel="noreferrer">
                <QuestionCircleOutlined style={{ fontSize: '19px' }} />
              </A>
            </div>
            <div className="use-auto-ordering">
              <p>
                Forget about processing your orders manually. They will now be processed automatically and you will be able to configure and
                manage your auto ordering settings directly from your HGR account.
              </p>
              <A to="/configuration/auto-ordering/" className="alternative-link">
                <SuccessBtn>Configure Auto Ordering</SuccessBtn>
              </A>
            </div>
          </Col>
        </Row>
      </div>
      <AffiliatesPanel />
      <div className="social-media">
        <A to={Links.Social.Facebook} rel='noreferrer' target='_blank'>
          <SocialIcon network="facebook" style={{ height: 30, width: 30 }} />
        </A>
        <A to={Links.Social.Instagram} rel='noreferrer' target='_blank'>
          <SocialIcon network="instagram" style={{ height: 30, width: 30 }} />
        </A>
        <A to={Links.Social.Youtube} rel='noreferrer' target='_blank'>
          <SocialIcon network="youtube" style={{ height: 30, width: 30 }} />
        </A>
      </div>
    </div>
  );
};
