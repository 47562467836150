import { Button, Modal } from 'antd';
import { useState } from 'react';
import { Range, RangeKeyDict } from 'react-date-range';
import { T } from '../../../utils/transShim';
import { DateRange, DateRangeProps } from './date-range';

export interface DateRangeModalProps extends Omit<DateRangeProps, 'ranges'> {
  visible: boolean;
  onOk?: (e: React.MouseEvent<HTMLElement>) => void;
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
  onClear?: (e: React.MouseEvent<HTMLElement>) => void;
  okText?: React.ReactNode;
  cancelText?: React.ReactNode;
  clearText?: React.ReactNode;
  defaultRanges: Range[];
  clearable?: boolean;
}

export const DateRangeModal = (props: DateRangeModalProps) => {
  const { visible, onOk, onCancel, okText, className, onChange, defaultRanges, clearable, cancelText, clearText, onClear } = props;

  const ToDic = (ranges: Range[]) => {
    const ret = {} as RangeKeyDict;
    if (ranges == null) return ret;
    for (const r of ranges) {
      ret[r.key ?? '0'] = r;
    }
    return ret;
  };
  const FromDic = (dic: RangeKeyDict): Range[] => Object.entries(dic).map(x => x[1]);
  const defaultDic = ToDic(defaultRanges);
  const [editingValue, SetEditingValue] = useState<RangeKeyDict>(defaultDic);
  const [previousValue, SetPreviousValue] = useState<RangeKeyDict>(defaultDic);

  const HandleOk = (e: React.MouseEvent<HTMLElement>) => {
    onOk?.(e);
    onChange?.(editingValue);
    SetPreviousValue(editingValue);
  };
  const HandleCancel = (e: React.MouseEvent<HTMLElement>) => {
    onCancel?.(e);
    SetEditingValue(previousValue);
  };
  const HandleClear = (e: React.MouseEvent<HTMLElement>) => {
    onClear?.(e);
  };

  const okLabel = okText ?? <T k='Setting.Apply' />;
  const cancelLabel = cancelText ?? <T k='Setting.Cancel' />;

  const CustomFooter = () => {
    const buttons = [];
    if (clearable) {
      buttons.push(<Button key="clear" onClick={HandleClear}>{clearText ?? <T k='Setting.Clear' />}</Button>);
    }
    return [
      ...buttons,
      <Button key="back" onClick={HandleCancel}>
        {cancelLabel}
      </Button>,
      <Button key="submit" type="primary" onClick={HandleOk}>
        {okLabel}
      </Button>
    ];
  };

  return (
    <Modal
      className={className + ' modal-datepicker'}
      title=''
      open={visible}
      onOk={HandleOk}//This probably won't be never fired
      onCancel={HandleCancel}//This will be fired when user closes the modal without using button cancel
      footer={CustomFooter()}
    >
      <DateRange
        {...props}
        onChange={SetEditingValue}
        ranges={FromDic(editingValue)}
      />
    </Modal>
  );
};