import { Divider, Spin } from 'antd';
import { useEffect, useState } from 'react';

import { Links } from 'src/links';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { getServices } from '../../redux/subscriptions/subsThunk';
import '../../sass/subscriptions/checkout.scss';
import { A } from '../../small-components/A';
import { T } from '../../utils/transShim';

interface props {
  productId: string | null;
  billingId: string | null | undefined;
  currencyId: string | null | undefined;
  platform?: number | null;
}

export const OrderSummary = (props: props) => {
  const dispatch = useAppDispatch();

  const { platform } = props;
  const { services, loading } = useAppSelector((state) => state.services);

  useEffect(() => {
    if ((services?.length ?? 0) == 0) dispatch(getServices());
  }, []);

  const [currency, setCurrency] = (() => {
    const [currency, setCurrency] = useState('\u20AC');
    if (platform === 1) return ['\u20AC', setCurrency];
    return [currency, setCurrency];
  })();

  const [currencyId, setCurrencyId] = (() => {
    const [currencyId, setCurrencyId] = useState(props.currencyId);
    if (platform === 1) return [1, setCurrencyId];
    return [currencyId, setCurrencyId];
  })();

  const [productId, setProductId] = useState(props.productId);
  const [billingId, setBillingId] = useState(props.billingId);
  let offerFlag = false;
  let priceFlag = false;

  const setPlatformProductId = (value: string) => {
    localStorage.setItem('platformProductId', value);
  };

  const setStripeProductId = (value: string) => {
    localStorage.setItem('stripePlatformProductId', value);
  };

  const loadOrder = () => {
    if (currencyId?.toString() === '1') {
      setCurrency('\u20AC');
    } else if (currencyId?.toString() === '2') {
      setCurrency('\u0024');
    } else if (currencyId?.toString() === '3') {
      setCurrency('\u00A3');
    }
  };

  useEffect(() => {
    setCurrencyId(props.currencyId);
    setBillingId(props.billingId);
    setProductId(props.productId);
    loadOrder();
    offerFlag = false;
    priceFlag = false;
  }, [props.productId, props.billingId, props.currencyId, loadOrder]);

  const sP = services?.find((p) => p.id.toString() === productId?.toString());

  if (sP) {
    for (const prc of sP.prices) {
      if (prc.platformId === 1 && prc.currencyId.toString() == '1' && prc.billingPeriodId.toString() === billingId?.toString()) {
        setPlatformProductId(prc.platformProductId);
      } else if (
        prc.platformId === 2 &&
        prc.currencyId.toString() === currencyId?.toString() &&
        prc.billingPeriodId.toString() === billingId?.toString()
      ) {
        setStripeProductId(prc.platformProductId);
      }
    }
  }

  return (
    <div className="">
      <h3 className="title-ordersum"><T k='Checkout.YourOrder' /></h3>
      {loading ? (
        <Spin />
      ) : sP != null ? (
        <div>
          <div className="order-products">
            <div className="order-products-lines">
              <div className="product-order-line">
                <h4 className="title-orderdetails">{sP.name}</h4>
              </div>
            </div>
            <div className="order-products-price">
              <div className="price-extra">
                {sP.prices.map((prc) => {
                  if (!offerFlag) {
                    if (
                      billingId?.toString() === '1' &&
                      prc.billingPeriodId.toString() === '0' &&
                      prc.currencyId.toString() === currencyId?.toString() &&
                      prc.platformId === 1
                    ) {
                      offerFlag = true;
                      return (
                        <div className="discounts" key={prc.id}>
                          <h4 className="old-price">{currency?.toString() + prc.price} /month</h4> &nbsp;
                          <p className="twenty-off"> 20% off</p>
                        </div>
                      );
                    } else if (
                      billingId?.toString() === '2' &&
                      prc.billingPeriodId.toString() === '0' &&
                      prc.currencyId.toString() === currencyId?.toString() &&
                      prc.platformId === 1
                    ) {
                      offerFlag = true;
                      return (
                        <div className="discounts" key={prc.id}>
                          <h4 className="old-price">{currency?.toString() + prc.price} /month </h4> &nbsp;
                          <p className="forty-off"> 20% off</p>
                        </div>
                      );
                    } else if (
                      billingId?.toString() === '1' &&
                      prc.billingPeriodId.toString() === '0' &&
                      prc.currencyId.toString() === currencyId?.toString() &&
                      prc.platformId === 2
                    ) {
                      offerFlag = true;
                      return (
                        <div className="discounts" key={prc.id}>
                          <h4 className="old-price">{currency?.toString() + prc.price} /month</h4> &nbsp;
                          <p className="twenty-off"> 20% off</p>
                        </div>
                      );
                    } else if (
                      billingId?.toString() === '2' &&
                      prc.billingPeriodId.toString() === '0' &&
                      prc.currencyId.toString() === currencyId?.toString() &&
                      prc.platformId === 2
                    ) {
                      offerFlag = true;
                      return (
                        <div className="discounts" key={prc.id}>
                          <h4 className="old-price">{currency?.toString() + prc.price} /month </h4> &nbsp;
                          <p className="forty-off"> 20% off</p>
                        </div>
                      );
                    }
                  }
                  if (!priceFlag) {
                    if (
                      prc.currencyId.toString() === currencyId?.toString() &&
                      prc.platformId === 2 &&
                      prc.billingPeriodId.toString() === billingId?.toString()
                    ) {
                      priceFlag = true;
                      if (prc.billingPeriodId === 0) return <h4 key={prc.id}>{currency?.toString() + prc.price} /month</h4>;
                      else if (prc.billingPeriodId === 1) return <h4 key={prc.id}>{currency?.toString() + (prc.price / 6).toFixed(1)} /month</h4>;
                      else if (prc.billingPeriodId === 2) return <h4 key={prc.id}>{currency?.toString() + (prc.price / 12).toFixed(1)} /month</h4>;
                    }
                  }
                })}
              </div>
            </div>
          </div>
          <Divider />
          <div className="order-sum">
            <div className="total-topay">
              <h2><T k='Checkout.Totaltopay' /></h2>
            </div>
            <>
              <h1>
                {sP.prices.map((prc) => {
                  if (
                    prc.currencyId.toString() === currencyId?.toString() &&
                    prc.platformId === (platform ?? 2) &&
                    prc.billingPeriodId.toString() === billingId?.toString()
                  ) {
                    return (
                      <>
                        {currency?.toString()} {prc.price}
                      </>
                    );
                  }
                })}
              </h1>
            </>
          </div>
        </div>
      ) : (
        <>
          <p>
            Please <A to={Links.ListingServiceCheckout}>go back</A> and select a service
          </p>
        </>
      )}
    </div>
  );
};
