import { useAppDispatch } from '../../../custom-hooks/reduxCustomHooks';
import { eSmartListerFilterStatus } from '../../../redux/smart-lister/data/e-smart-lister-filter-status';
import { SmartListerFilter } from '../../../redux/smart-lister/data/smart-lister-filter';
import { SmartListerFiltersChangeStatus } from '../../../redux/smart-lister/smart-thunk';
import { CancelBtn, SuccessBtn, WarningBtn } from '../../../small-components/ActionBtns';
import { PopupModal } from '../../../small-components/modals/popup-modal';
import { T } from '../../../utils/transShim';

interface Props {
  row: SmartListerFilter | undefined;
  open: boolean;
  onClose: () => void;
}


export const SmartListerFilterEdit = (props: Props) => {
  const { row, open, onClose } = props;

  const dispatch = useAppDispatch();

  const Buttons = () => {
    if (!row)
      return [];

    const ChangeStatus = (status: eSmartListerFilterStatus) => {
      dispatch(SmartListerFiltersChangeStatus({
        id: row.id,
        status: status
      }));
      onClose();
    };
    const HandleActivateClick = () => ChangeStatus(eSmartListerFilterStatus.Active);
    const HandlePauseClick = () => ChangeStatus(eSmartListerFilterStatus.Paused);
    const HandleArchiveClick = () => ChangeStatus(eSmartListerFilterStatus.Archived);
    const HandleRemoveClick = () => ChangeStatus(eSmartListerFilterStatus.Removed);

    const ActivateBtn = (
      <SuccessBtn key={1} onClick={HandleActivateClick} >
        <span><T k='SmartLister.Table.Status.SetActive' /></span>
      </SuccessBtn>
    );
    const PauseBtn = (
      <WarningBtn key={2} onClick={HandlePauseClick} >
        <span><T k='SmartLister.Table.Status.SetPaused' /></span>
      </WarningBtn>
    );
    const ArchiveBtn = (
      <CancelBtn key={3} onClick={HandleArchiveClick} >
        <span><T k='SmartLister.Table.Status.SetArchived' /></span>
      </CancelBtn>
    );
    const RemoveBtn = (
      <CancelBtn key={4} onClick={HandleRemoveClick} >
        <span><T k='SmartLister.Table.Status.SetRemoved' /></span>
      </CancelBtn>
    );

    switch (row?.status) {
      case eSmartListerFilterStatus.Active:
        return [PauseBtn, ArchiveBtn];
      case eSmartListerFilterStatus.Archived:
        return [RemoveBtn];
      case eSmartListerFilterStatus.Paused:
        return [ActivateBtn, ArchiveBtn];
    }
    return [];
  };

  const Footer = () => {
    const bs = Buttons();
    const bLines: React.ReactNode[][] = [];
    let line = 0;
    for (let i = 0; i < bs.length; i++) {
      if (i % 2 == 0) {
        bLines.push([]);
        if (i > 0) line++;
      }
      bLines[line].push(bs[i]);
    }
    return <>
      <div className="editing-filter-footer">
        {bLines}
      </div>
    </>;
  };

  return <PopupModal
    open={open}
    showCloseX={false}
    footer={Footer()}
    onClose={onClose}
  >
  </PopupModal>;
};