import { Radio } from 'antd';
import { OrderSummary } from './parts/order-summary';
import { useState } from 'react';
import { PrimaryBtn } from '../../../small-components/ActionBtns';
import { ArrowRightOutlined } from '@ant-design/icons';
import { PaymentPlatform } from '../models/types';
import '../../../sass/subscriptions/checkout.scss';
import { Cart } from './data/cart';
import { CheckoutSection } from './data/checkout-section';
import { T } from '../../../utils/transShim';
import { eCurrency } from '../../../types/eCurrency';

type Props = {
  cart: Cart;
  onNavigate: (cart: Cart, section: CheckoutSection) => void;
};

export const PaymentMethodSubscriptions = (props: Props) => {
  const { cart, onNavigate } = props;

  const [tempCart, setTempCart] = useState<Cart>(cart);

  const ChangeMethod = (p: PaymentPlatform) => {
    if (p == PaymentPlatform.PayPal) {
      setTempCart({
        ...cart,
        platform: p,
        currency: eCurrency.EUR //We only allow EUR with Paypal
      });
    } else {
      setTempCart({
        ...cart,
        platform: p,
        currency: cart.currency//We restore selected currency
      });
    }
  };

  const HandleNavigate = () => {
    onNavigate(tempCart, CheckoutSection.EXTRA_SERVICES);
  };

  const Panel1 = () => {
    return <div className="section-container">
      <Radio.Group name="paymentMethod" defaultValue={tempCart.platform.toString()}>
        <div className="card-section-options">
          <h3><T k='Checkout.SelectMethod' /></h3>
          <p><T k='Checkout.PaypalOnlyEur' /></p>
          <div className="cards-payments">
            <Radio
              className="card-payment-section"
              value="2"
              name="paymentMethod"
              onClick={() => ChangeMethod(PaymentPlatform.Stripe)}
            >
              <h3><T k='Checkout.CreditCard' /></h3>
            </Radio>
            <Radio
              className="card-payment-section"
              value="1"
              name="paymentMethod"
              onClick={() => ChangeMethod(PaymentPlatform.PayPal)}
            >
              <h3>Paypal</h3>
            </Radio>
          </div>
        </div>
      </Radio.Group>
    </div>;
  };

  const Panel2 = () => {
    return <div className="section-container">
      <OrderSummary cart={tempCart} />
      <div className="button-container">
        <PrimaryBtn htmlType="submit" onClick={HandleNavigate}>
          <T k="Checkout.Continue" /> <ArrowRightOutlined />
        </PrimaryBtn>
      </div>
    </div>;
  };

  return (
    <div className="checkout-content">
      <div className="checkout-sections-container">
        {Panel1()}
        {Panel2()}
      </div>
    </div>
  );
};
