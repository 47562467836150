import { ColumnType } from 'antd/lib/table';
import { useMemo, useState } from 'react';
import { AutoOrderingFee } from '../../../redux/auto-ordering/autoOrderingSlice';
import { SimpleTable } from '../../../small-components/tables/simple-table';
import { eCurrency } from '../../../types/eCurrency';
import { ReactUtils } from '../../../utils/react-utils';


export type FeesByMonth = {
  fees: AutoOrderingFee[];
  month: number;
  year: number;
  total: number;
};

type Props = {
  fees?: FeesByMonth[];
  currency: eCurrency;
}

export const FeesTable = (props: Props) => {

  const { fees, currency } = props;

  const FeesDateColumns: ColumnType<FeesByMonth>[] = [
    {
      title: 'Date',
      dataIndex: 'date',
      render: (_, record: FeesByMonth) => {
        return (record.month + 1).toLocaleString(undefined, { minimumIntegerDigits:2 }) + '/' + record.year;
      }
    },
    {
      title: 'Total',
      dataIndex: 'total',
      render: (total: number) => {
        return total.toLocaleString(undefined, {maximumFractionDigits:2});
      }
    },
  ];
  const FeeColumns: ColumnType<AutoOrderingFee>[] = [
    {
      title: 'Reference',
      dataIndex: 'reference'
    },
    {
      title: 'Product id',
      dataIndex: 'productId'
    },
    {
      title: 'Price',
      dataIndex: 'price',
      render: (price: number) => {
        return ReactUtils.DrawPrice(price, currency ?? eCurrency.GBP);
      }
    },
    {
      title: 'Fee',
      dataIndex: 'fee',
      render: (fee: number) => {
        return ReactUtils.DrawPrice(fee, currency ?? eCurrency.GBP);
      }
    },
    {
      title: 'Paid',
      dataIndex: 'paid',
      render: (paid: boolean) => {
        return paid ? 'Yes' : 'No';
      }
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (date: string) => {
        return ReactUtils.GetFormattedDate(date);
      }
    }
  ];

  if (fees == null)
    return <></>;

  const feesDateSorted = useMemo(() => {
    if (fees == null)
      return null;
    return fees.sort((a, b,) => ((a.year*12) + a.month - (b.year*12) + b.month));
  }, fees);

  const [feesData, SetFeesData] = useState<AutoOrderingFee[]>();

  const OnDateRow = (record: FeesByMonth) => ({
    onClick: () => {
      SetFeesData(record.fees);
    }
  });

  return <>
    <SimpleTable columns={FeesDateColumns} dataSource={feesDateSorted ?? []} onRow={OnDateRow} />
    {feesData && <SimpleTable columns={FeeColumns} dataSource={feesData} />}
  </>;
};