import { Button, Modal, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../../custom-hooks/reduxCustomHooks';
import { eChannelListingHistoryAction, GetPriceChanges } from '../../../../../redux/listings/listings-thunk';
import { SimpleTable } from '../../../../../small-components/tables/simple-table';
import { ReactUtils } from '../../../../../utils/react-utils';
import { T } from '../../../../../utils/transShim';

type Props = {
  listingId: number;
  visible: boolean;
  onClose?: () => void;
}
export const PriceChangesModal = (props: Props) => {
  const { visible, listingId, onClose } = props;
  const { priceChanges } = useAppSelector((state) => state.listings);

  const dispatch = useDispatch();

  if (!visible)
    return <Modal open={false}></Modal>;

  if (priceChanges?.loading) {
    return <Modal open={visible}><Spin /></Modal>;
  }

  if (priceChanges?.listingId != listingId) {
    dispatch(GetPriceChanges(listingId));
    return <Modal open={visible}><Spin /></Modal>;
  }

  const RenderRemarks = (value: eChannelListingHistoryAction) => {
    switch (value) {
      case eChannelListingHistoryAction.ManualPrice:
        return <T k='Listings.ManualChange' />;
      case eChannelListingHistoryAction.MonitorPrice:
      default:
        return <T k='Listings.AutomaticMonitoring' />;
    }
  };

  const columns = [
    {
      title: <T k='Listings.Date'/>,
      dataIndex: 'date',
      render: (date: string | Date) => ReactUtils.GetFormattedDateTime(date)
    },
    {
      title: <T k='Listings.OldPrice'/>,
      dataIndex: 'oldValue',
      render: (value: string) => value != null ? parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null
    },
    {
      title: <T k='Listings.NewPrice' />,
      dataIndex: 'newValue',
      render: (value: string) => value != null ? parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null
    },
    {
      title: <T k='Listings.Remarks' />,
      dataIndex: 'action',
      render: RenderRemarks
    }
  ];

  //id: number;
  //channelListing: number;
  //action: number;
  //date: string | Date;
  //oldValue ?: string;
  //newValue ?: string;
  //extra ?: string;

  return <Modal
    open={true}
    onCancel={onClose}
    footer={<Button key="submit" type="primary" onClick={onClose}><T k='Button.Close'/></Button>}
  >
    <SimpleTable
      columns={columns}
      dataSource={priceChanges?.history ?? []}
    />
  </Modal>;
};