import { QuestionCircleOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';

import { Links } from '../../../links';
import { PrimaryBtn } from '../../../small-components/ActionBtns';
import { DateRangeInput } from '../../../small-components/form/date-range-input';
import { T } from '../../../utils/transShim';
import { Range } from 'react-date-range';
import { useEffect, useState } from 'react';
import { client } from '../../../redux/client';
import { getAffiliatesStats } from '../../../redux/dashboard/affiliatesStatsThunk';
import { useAppDispatch, useAppSelector } from '../../../custom-hooks/reduxCustomHooks';
import moment from 'moment';
import { ePeriod } from '../../../redux/sales/salesSlice';
import miniAlert from 'mini-alert';
import { addDays } from 'date-fns';
import { AffiliatesStats } from '../../../redux/dashboard/affiliatesStatsSlice';
import { A } from '../../../small-components/A';
import { ChartArea } from '../../../small-components/charts/chart-area';

export const AffiliatesPanel = () => {
  const [affiliate, setAffiliate] = useState<string>('');
  const [percentageCommission, setPercentageCommission] = useState(10);
  const { affiliatesStats } = useAppSelector((state) => state.affiliatesStats);
  const [affiliatePeriod, SetAffiliatePeriod] = useState<number>(3);
  const dispatch = useAppDispatch();

  function getFirstDayOfMonth(year: number, month: number) {
    return new Date(year, month, 1);
  }
  const date = new Date();
  const firstDayCurrentMonth = getFirstDayOfMonth(date.getFullYear(), date.getMonth());

  useEffect(() => {
    (async () => {
      const affiliateRes = await client.get('/Dashboard/Affiliate'); //TODO: CHANGE THIS TO REDUX
      setAffiliate('https://app.hustlegotreal.com/Register/Landing?src=' + affiliateRes.data.response_data.affiliate);
      setPercentageCommission(affiliateRes.data.response_data.percentageCommission);
    })();
  }, []);

  useEffect(() => {
    dispatch(
      getAffiliatesStats({
        period: affiliatePeriod,
        from: firstDayCurrentMonth.toISOString(),
        to: new Date().toISOString(),
        timeDiff: new Date().getTimezoneOffset()
      })
    );
  }, [getAffiliatesStats]);

  const AffiliatesDateChange = async (value: number, dateString: [string, string]) => {
    if (Array.isArray(dateString)) {
      await dispatch(
        getAffiliatesStats({
          period: value,
          from: dateString[0],
          to: dateString[1],
          timeDiff: new Date().getTimezoneOffset()
        })
      );
    }
  };

  const totalAffiliates = affiliatesStats?.reduce((total: number, sale: AffiliatesStats) => (total = total + sale.quantity), 0);

  const affiliateChartLabels = (() => {
    if (affiliatePeriod) {
      switch (affiliatePeriod) {
        case ePeriod.Days:
          return [...new Set(affiliatesStats?.map((d) => moment(d.date).format('DD-MMM')))];
        case ePeriod.Months:
          return [...new Set(affiliatesStats?.map((d) => moment(d.date).format('MMM-YY')))];
        default:
          break;
      }
    }
  })();

  const affiliateData: number[] = affiliatesStats?.map((s: { quantity: number }) => s.quantity);

  const HandleAffiliatesDateChange = (state: Range | null) => {
    if (state) {
      const startDate: Date = state.startDate ? state.startDate : moment.utc().month(-12).toDate();
      const endDate: Date = state.endDate ? state.endDate : moment.utc().toDate();
      const diff = Math.abs(startDate.getTime() - endDate.getTime());
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      const from = moment.utc(startDate).local().format('YYYY-MM-DD') + 'T00:00:00.000Z';
      const to = moment.utc(endDate).local().format('YYYY-MM-DD') + 'T00:00:00.000Z';

      if (diffDays < 31) {
        SetAffiliatePeriod(3);
        AffiliatesDateChange(3, [from, to]);
      } else if (diffDays > 30 && diffDays < 400) {
        SetAffiliatePeriod(4);
        AffiliatesDateChange(4, [from, to]);
      } else {
        SetAffiliatePeriod(5);
        AffiliatesDateChange(5, [from, to]);
      }
    }
  };

  const HandleCopyText = () => {
    miniAlert({
      overflow: true,
      autoremove: true,
      time: 500,
      size: 'small',
      cartoon: false,
      text: 'Copied!'
    });
  };

  return (
    <div className="affiliates-main-container general-section">
      <h1>
        <T k="Affiliates.Title" />
      </h1>
      <div className="affiliates-contents white-box">
        <div className="affiliates-title">
          <h3>
            <T k="Affiliates.Link.Title" />
          </h3>
          <A to="https://hustlegotreal.com/en/affiliate-program/" target="_blank" rel="noreferrer">
            <QuestionCircleOutlined style={{ fontSize: '19px' }} />
          </A>
        </div>
        <div className="affiliates-benefits">
          <p>
            <T k="Affiliates.Link.Description" />
          </p>
          <div className="copy-actions">
            <Input type="text" value={affiliate} className="text-input" />
            <CopyToClipboard text={affiliate} onCopy={HandleCopyText}>
              <PrimaryBtn>
                <T k="copy" />
              </PrimaryBtn>
            </CopyToClipboard>
          </div>
          <h4>
            <strong>
              <T k="Affiliates.Percentaje" />: {percentageCommission}%
            </strong>
          </h4>
          <A to={Links.AffiliateDashboard}>
            <PrimaryBtn>
              <T k="Affiliats.Dashboard.Title" />
            </PrimaryBtn>
          </A>
        </div>
        <div className="general-section">
          <div className="charts-sales">
            <DateRangeInput
              onChange={HandleAffiliatesDateChange}
              defaultRange={{
                startDate: new Date(),
                endDate: addDays(new Date(), 7)
              }}
            />
            <h3>
              <T k="Affiliates.Graph.Title" />
            </h3>
            <h2>{totalAffiliates ? totalAffiliates.toLocaleString() : '0'}</h2>
            <ChartArea
              data={affiliateData.map((v, i) => ({ y: v, x: affiliateChartLabels?.[i] ?? '' }))}
              //color={ }
              //tooltip={ }
              //series={affiliateChartData.series} type="line" width="100%" height={400}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
