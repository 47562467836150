import { useContext } from 'react';
import { AppContext } from '../contexts/AppContext';
import { useAppSelector } from '../custom-hooks/reduxCustomHooks';
import moment from 'moment';
import { Countries, eCountry } from '../data/countries';
import { ePlatform, GetPlatform } from '../data/platforms';
import { API_URL as ApiURL } from '../redux/client';
import { eCurrency } from '../types/eCurrency';
import { HasDecimals } from './utils';

interface DrawPriceDecimals {
  decimals?: {
    force?: boolean,
    number?: number;
  }
}

export const ReactUtils = {

  AreChannelsLoaded: () => useAppSelector((state) => state.channels).channelsLoaded,
  GetAllChannels: () => useAppSelector((state) => state.channels).channels,

  GetSelectedChannel: () => {
    const channels = ReactUtils.GetAllChannels() ?? [];
    const { channelId: selectedChannelId } = useContext(AppContext);
    return channels.find(x => x.id == selectedChannelId);
  },

  GetCurrencySymbol: () => {
    const selectedChannel = ReactUtils.GetSelectedChannel();
    return Countries[selectedChannel?.isoCountry ?? eCountry.UK].CurrencySymbol;
  },

  GetFormattedDateTime: (date: Date | string | undefined) => {
    return moment(date).format('L LT');
  },

  GetFormattedDate: (date: Date | string | undefined) => {
    return moment(date).format('L');
  },

  OnClickNoPropagate: (e: React.MouseEvent) => {
    e.stopPropagation();
    return false;
  },

  DrawPrice: (value: number, currency: eCurrency | string, options?: DrawPriceDecimals) => {
    let front = false;
    let symbol = currency?.toString();
    switch (currency) {
      case eCurrency.EUR:
      case 'EUR':
        symbol = '€';
        break;
      case eCurrency.GBP:
      case 'GBP':
        symbol = '£';
        front = true;
        break;
      case eCurrency.USD:
      case 'USD':
        symbol = '$';
        front = true;
        break;
      case eCurrency.CNY:
      case 'CNY':
        symbol = '元';
        break;
      case eCurrency.AUD:
      case 'AUD':
        symbol = 'AUD';
        break;
      case eCurrency.CAD:
      case 'CAD':
        symbol = 'C$';
        break;
      case eCurrency.CHF:
      case 'CHF':
        symbol = 'CHF';
        break;
      case eCurrency.JPY:
      case 'JPY':
        symbol = '円';
        break;
      case eCurrency.NZD:
      case 'NZD':
        symbol = 'NZ$';
        break;
      default:
        symbol = '';
    }

    let inter = '';
    if (symbol.length > 1) {
      inter = ' ';
    }

    const valueS = (!(options?.decimals?.force ?? true) && !HasDecimals(value)) ?
      value.toFixed(0)
      :
      value.toFixed(options?.decimals?.number ?? 2)
    ;
    
    if (front) {
      return symbol + inter + valueS;
    } else {
      return valueS + inter + symbol;
    }
  },

  GetCurrencyByCountry: (country: eCountry | number | undefined) => Countries[country ?? eCountry.UK]?.Currency,

  GetOutUrl: (url: string | null | undefined, isoCountry?: eCountry, listingId?: number) => {
    if (!url)
      return undefined;
    return ApiURL + '/api/Sources/GoOut?sourceUrl=' + encodeURI(url)
      + (listingId != null ? '&channelListingId=' + listingId : '')
      + (isoCountry != null ? '&isoCountry=' + isoCountry : '');
  },
  
  GetItemUrl: (platformId: ePlatform, isoCountry: eCountry, channelItem: string, channelIdentifier?: string, asin?: string) => {
    const platform = GetPlatform(platformId);

    if (!platform)
      return null;

    if (platform.useInternalInList) {
      let url: string;
      if (platform.internalUrl instanceof Object) url = platform.internalUrl[isoCountry];
      else url = platform.internalUrl;

      if (!asin)
        return null;

      url = url
        .replace('{asin}', asin)
        .replace('{id}', channelItem)
        .replace('{shopName}', channelIdentifier ?? '');
      return url;
    } else {
      let url: string;
      if (platform.itemUrl instanceof Object) {
        url = platform.itemUrl[isoCountry]
          .replace('{id}', channelItem)
          .replace('{shopName}', channelIdentifier ?? '');
      } else {
        url = platform.itemUrl
          .replace('{id}', channelItem)
          .replace('{shopName}', channelIdentifier ?? '');
      }
      return url;
    }
  },

  stringToBoolean: (value: string | undefined | null) => {
    switch (value?.toLowerCase().trim()) {
      case 'true': case 'yes': case '1': return true;
      case 'false': case 'no': case '0': case null: case undefined: return false;
      default: return Boolean(value);
    }
  }
};