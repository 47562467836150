import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../client';

export enum PrizeType {
  UNKNOWN = 0,
  TOKENS = 1
}
export type Prizes = {
  id: number;
  label?: string;
  type: PrizeType;
  quantity?: number;
  minDate?: string;
  maxDate?: string;
}
export type PendingPrizesResponse = {
  prizes: Prizes[];
}

export const TaskPrizes = createAsyncThunk('/Dashboard/TaskPrizes', async (_, { rejectWithValue }) => {
  try {
    const res = await client.get('/Dashboard/TaskPrizes');
    return res.data.response_data as PendingPrizesResponse;
  } catch (error) {
    return rejectWithValue('Sorry! Something went wrong');
  }
});
export const ClaimPrize = createAsyncThunk('/Dashboard/ClaimPrize', async ({ id }:  { id: number }, { rejectWithValue }) => {
  try {
    const res = await client.post('/Dashboard/ClaimPrize', {id});
    return res.data.response_data as {success: boolean};
  } catch (error) {
    return rejectWithValue('Sorry! Something went wrong');
  }
});