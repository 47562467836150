import '../sass/small-components/animated-component-list.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const AnimatedComponentList = (props: Props) => {
  const { children, className } = props;

  const RenderLine = (c: React.ReactNode, i: number) => {
    return <div key={i} style={{ animationDelay: i + 's' }}>{c}</div>;
  };

  const RenderContent = () => {
    if (!Array.isArray(children))
      return RenderLine(children, 0);

    return children.map(RenderLine);
  };

  return <div className={'animated-component-list ' + (className ?? '')}>
    {RenderContent()}
  </div>;
};