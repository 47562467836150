import { Alert, Checkbox, Col, Collapse, DatePicker, Form, Input, Row, Spin, Tabs } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React from 'react';
import { eCountry } from '../../data/countries';
import { ePlatform, GetPlatform, IsApi, IsEbay } from '../../data/platforms';
import { ComputedSettingsData } from '../../redux/source-configuration/types';
import { MiniSettings } from '../../types/mini-settings';
import { Category } from './new-listing-form/category';
import {
  ChannelSpecificField,
  ChannelSpecificFieldBasicValue,
  ChannelSpecificFieldValue,
  SuggestionTitle,
  SuggestionWord
} from './new-listing-form/channel-specific-field';
import { ComListing, ComProduct, FeaturesTableRow } from './new-listing-form/com-listing';
import EanField, { EanFieldChangeEvt, EanType } from './new-listing-form/ean-field';
import { GenerateRandomEan, IsValidEan } from './new-listing-form/ean-generator';
import { HGRFormulaFunction, ShouldBeFieldHidden, StringToFormula } from './new-listing-form/hgr-formula';
import { LastMessage } from './new-listing-form/last-message';
import { ProductInChannelData } from './new-listing-form/product-in-channel-data';
import { SimpleOnChange } from './new-listing-form/simple-on-change';
import { AttributeOption, ListingVariationsResponse, VariationListingInfo } from './new-listing-form/variation-listing-info';
import coinIcon from '../../assets/icons/token2_0.png';
import { ImagesView, ReCalculateUrls } from './new-listing-form/images-view';
import { VariationsPanel } from './new-listing-form/variations-panel';
import { ProductPanelInChannel } from './new-listing-form/product-panel-in-channel';
import { Selector, SelectorValue } from '../../small-components/form/selector';
import TitleEditor, { SuggestedTitleGroup, TitleSuggestionsGroup } from './new-listing-form/title-editor';
import { eListingErrorFlags } from './new-listing-form/eListingErrorFlags';
import { CountryCodeSelector } from '../../small-components/form/country-code-selector';
import { Channel } from '../../redux/channels/channelsSlice';
import { CreatableMultiple } from '../../small-components/form/creatable-multiple';
import {
  CategorySuggestionsResponse,
  ClearOneVariationPlain,
  ClearVariationsPlain,
  GetCategoriesByTextPlain,
  GetCategoryInfoPlain,
  GetCategorySpecificsPlain,
  GetCustomCategoriesPlain,
  GetSettingsPlain,
  GetSuggestedCategoriesPlain,
  GetSuggestedTitlesPlain,
  GetTemplatesPlain,
  IsBrandInVeRoListPlain,
  OptimiseTitlePlain,
  SubmitListingPlain
} from '../../redux/listings/manual-listing-thunk';
import { Links } from '../../links';
import { HtmlEditor } from './new-listing-form/html-editor';
import '../../sass/listings/new-listing-extension.scss';
import { QuestionCircleOutlined, SyncOutlined } from '@ant-design/icons';
import moment from 'moment';

import { BrowserExtension } from './browser-declarations';
import { Template } from '../../redux/templates/templatesSlice';
import { GetTemplateHtml } from './new-listing-form/description-functions';
import { PrimaryBtn } from '../../small-components/ActionBtns';
import { QuestionModal } from '../../small-components/modals/question-modal';
import { TokensCosts } from '../../small-components/Tokens';
import { BulkListing } from '../list-now/bulk-listing';
import { A } from '../../small-components/A';

const cacheFormula: { [formula: string]: HGRFormulaFunction } = {};

const allowRandomEan = false;
type SendMessageToBackgroundData =
  | string
  | {
      id?: string;
      text?: string;
      ean?: string;
      site: string;
      channelId: ePlatform;
    };
type ResponseType = (d: { data: string | null }) => void;
const SendMessageToBackground = (action: string, data: SendMessageToBackgroundData) => {
  return new Promise((response: ResponseType) => {
    if (BrowserExtension?.sendMessage) {
      BrowserExtension.sendMessage(
        'fakikmhjpdbdilplbjipceklhdglocmk',
        {
          action,
          data
        },
        response
      );
    }
  });
};
const SendMessageToExtension = (action: string, message: SendMessageToBackgroundData) => {
  return SendMessageToBackground(action, message);
};
const IsSendMessageToBackgroundDefined = () => {
  return new Promise((response) => {
    if (document.readyState === 'complete') {
      response(typeof SendMessageToBackground != 'undefined');
    } else {
      window.onload = function () {
        window.setTimeout(() => {
          response(typeof SendMessageToBackground != 'undefined');
        }, 50); //Time to inject
      };
    }
  });
};

function NotarizeFormula(input: string) {
  if (!cacheFormula[input]) cacheFormula[input] = StringToFormula(input);
}
function GetFormula(input: string) {
  const c = cacheFormula[input];
  if (!c) NotarizeFormula(input);
  return cacheFormula[input];
}

type CategoryChangedType = (
  sourceId: number,
  categoryId: string,
  originPrice: number,
  title: string,
  paragraphs: string[],
  features: string[],
  tableValues: { [name: string]: string }
) => void;

interface State {
  submiting: boolean;
  submitEnabled: boolean;
  resultSubmition?: { status: number; error?: string };
  oauthId: number;
  listing: ComListing;
  suggestedTitles: SuggestedTitleGroup[];
  //profit: number;
  searchTerm: string;
  loadingSpecifics: boolean;
  loadingCategories: boolean;
  veroBrand: string;
  editing: boolean; //If true we are not doing a new listing but editing
  loadingEan: boolean;
  eanType: EanType;
  eanValid: boolean;
  productInChannel: ProductInChannelData | null;
  possibleProductsInChannel: ProductInChannelData[] | null;
  editProductInChannel: boolean;
  selectedVariation: number;
  definitiveSubmit: boolean;
  connected: boolean;
  dontListUntil?: Date;
  showOptimiseModal: boolean;
  optimising: boolean;
  templates?: Template[];
  subtmitErrorMessage?: string;
  bulkMode: boolean;
  productUrl: string;
  optimizedDescription: boolean;
}

enum eFieldType {
  None,
  Currency,
  Checkbox,
  String,
  Number,
  Percentage,
  Date
  //Autocomplete
}

//#endregion

//#region functions
function HaveForbiddenWords(settings: ComputedSettingsData | undefined, text: string): boolean {
  if (!settings?.forbiddenWords) {
    return false;
  }

  const forbiddenWords = settings.forbiddenWords.split(',').map((x) => x.trim().toLowerCase());
  const tt =
    new DOMParser()
      ?.parseFromString(text ?? '', 'text/html')
      .querySelector('html')
      ?.innerText?.toLowerCase() ?? '';
  for (const ind in forbiddenWords) {
    if (tt.includes(forbiddenWords[ind])) {
      return true;
    }
  }

  return false;
}

function isTypeSuggestionWord(value: SuggestionWord | SuggestionTitle): value is SuggestionWord {
  return (value as { word: string }).word != null;
}

function RepeatedWords(phrase: string): string[] {
  const words = phrase.replace(',', ' ').split(' ');
  const rep: string[] = [];
  for (let i = 0; i < words.length; i++) {
    const w = words[i];

    if (w.length == 0) continue;

    let found = false;
    for (let j = words.length - 1; j > i; j--) {
      if (words[j] == w) {
        if (!found) rep.push(w);
        found = true;
        words.splice(j, 1);
      }
    }
  }
  return rep;
}

function setValue<T, K extends keyof T>(obj: T, key: K, value: T[K]) {
  obj[key] = value;
}

function GetProfit(price: number, sourcePrice: number, feePercentage: number) {
  const grossProfit = price - sourcePrice;
  return Math.round((grossProfit - price * (feePercentage / 100)) * 100) / 100;
}

//#endregion

//#region functions v2

function EmptyValue(value: ChannelSpecificFieldValue | undefined) {
  if (typeof value === 'string')
    //Array
    return value == null || value === '';
  else if (typeof value === 'number') return value == null || isNaN(value);
  else if (typeof value === 'boolean') return value == null;
  else return value == null || value.length === 0;
}

function FieldValidation(item: ChannelSpecificField, successValue?: 'success' | 'error' | 'warning') {
  const valueEmpty = EmptyValue(item.value);
  switch (typeof item.value) {
    case 'string':
      if (
        (item.validationRules && item.validationRules.required && valueEmpty) ||
        (item.validationRules && item.validationRules.maxValueLength && item.value.length > item.validationRules.maxValueLength)
      )
        return 'error';
      break;
    default:
    case 'number':
      if (item.validationRules && item.validationRules.required && valueEmpty) return 'error';
      break;
  }
  return successValue;
}
function YesNoSelect(keyName: string, value: boolean, onChange: (evt: SimpleOnChange) => void) {
  const val = value ? '1' : '2';
  return (
    <select
      name={keyName}
      className="form-control input-sm"
      value={val}
      onChange={(a) => {
        let fV = false;
        switch (a.target.value) {
          case '1':
            fV = true;
            break;
          case '2':
            fV = false;
            break;
        }
        onChange({ value: fV, keyName: keyName, type: 'select', specific: false });
      }}
    >
      <option value="1">Yes</option>
      <option value="2">No</option>
    </select>
  );
}

function CreateSelectOptions(possibleValues: { [key: string]: string } | null): { label: string; value: string }[] {
  if (!possibleValues) return [];

  const options = [];
  for (const val in possibleValues) {
    options.push({ label: possibleValues[val], value: val });
  }
  return options;
}

//function ToSelectValue(value: ChannelSpecificFieldValue | undefined, possibleValues: { [key: string]: string } | undefined): { label: ChannelSpecificFieldBasicValue, value: ChannelSpecificFieldBasicValue } | { label: ChannelSpecificFieldBasicValue, value: ChannelSpecificFieldBasicValue }[] | undefined {
//  if (!value) return undefined;

//  if (value instanceof Array) {
//    return value.map((v) => ToSelectValue(v, possibleValues) as { label: ChannelSpecificFieldBasicValue, value: ChannelSpecificFieldBasicValue });
//  } else {
//    let optionVal;
//    if (possibleValues)
//      if (typeof value === 'number' || typeof value === 'string') {
//        if (possibleValues[value] !== undefined) optionVal = { label: possibleValues[value], value: value };
//      }
//    if (!optionVal) optionVal = { label: value, value: value };

//    return optionVal;
//  }
//}

function DrawField(
  item: ChannelSpecificField,
  onChange: (evt: SimpleOnChange) => void,
  allSpecificFields: ChannelSpecificField[],
  req: boolean
) {
  if (!item) return;

  let key = 0;
  const children = [];

  const isVisible = item.hideFormula == null || !ShouldBeFieldHidden(GetFormula(item.hideFormula), allSpecificFields);
  if (!isVisible) return;

  children.push(
    <label key={key++}>
      <span title={item.notes}>{item.name}</span>
    </label>
  );

  if ((item.possibleValues && Object.keys(item.possibleValues).length > 0) || item.multipleValues) {
    let possibleValuesCount = 0;
    for (const _ in item.possibleValues) {
      possibleValuesCount++;
    }

    if (item.keyName == 'feed_product_type' && item.possibleValues && possibleValuesCount == 1 && item.validationRules?.forceExistingValues)
      return;

    const options = CreateSelectOptions(item.possibleValues);

    let value = item.value;

    const isMulti = item.multipleValues; //item.validationRules.maxValues > 1;

    const isValidNewOption = (newValue: string, completeValue: SelectorValue[]): boolean => {
      if (!req && newValue.length == 0) return true;

      return (
        newValue.length > 0 &&
        (!item.validationRules || !item.validationRules.maxValueLength || newValue.length < item.validationRules.maxValueLength) &&
        (!isMulti || !item.validationRules || !item.validationRules.maxValues || completeValue.length < item.validationRules.maxValues) &&
        (!item.validationRules || !item.validationRules.forceExistingValues || item.possibleValues?.[newValue] != undefined)
      );
    };

    const onChangeS = (value: SelectorValue[] | null) => {
      if (value === undefined || value === null)
        return onChange({
          keyName: item.keyName,
          type: 'creatable',
          value: null,
          specific: true
        });

      let val: SelectorValue | SelectorValue[] | null;
      if (isMulti) {
        val = value;
      } else {
        if (value.length > 0) val = value[0];
        else val = null;
      }

      return onChange({
        keyName: item.keyName,
        type: 'creatable',
        value: val as ChannelSpecificFieldValue | null,
        specific: true
      });
    };

    if (item.validationRules?.forceExistingValues) {
      if (options != null && options.length > 0) {
        if (!isMulti) {
          if (options.find(x => x.value == value) == null) {
            value = options[0].value;
            if (value != null)
              onChangeS([value]);
            else
              onChangeS(null);
          }
        } else {
          if (value != null && Array.isArray(value)) {
            const nv = value.filter(y => options.find(x => x.value == y) == null);
            if (nv?.length != value.length) {
              value = nv;
              if (value != null)
                onChangeS(value as SelectorValue[]);
              else
                onChangeS(null);
            }
          }
        }
      } else {
        if (value != null) {
          onChangeS(null);
        }
      }
    }

    children.push(
      <CreatableMultiple
        key={key++}
        multiple={isMulti}
        isValidNewOption={isValidNewOption}
        value={(Array.isArray(value) ? value : [value]) as SelectorValue[]}
        allowClear={!req}
        onChange={onChangeS}
      >
        {options}
      </CreatableMultiple>
    );
  } else {
    const onChangeToOnChangeText = () => {
      let timer = 0;
      return function (evt: React.ChangeEvent<HTMLInputElement>) {
        const tValue = evt.target.value;
        clearTimeout(timer);
        timer = window.setTimeout(() => onChange({ keyName: item.keyName, value: tValue, type: 'text', specific: true }), 500);
      };
    };
    const onChangeToOnChangeCheckBox = (evt: CheckboxChangeEvent) =>
      onChange({
        keyName: item.keyName,
        value: evt.target.checked,
        type: 'checkbox',
        specific: true
      });
    const onChangeToOnChangeNumber = (evt: React.ChangeEvent<HTMLInputElement>) => {
      const value = EmptyValue(evt.target.value) ? '0' : evt.target.value;
      onChange({ keyName: item.keyName, value: value, type: 'number', specific: true });
    };

    switch (item.type) {
      case eFieldType.Number:
        children.push(
          <Input
            key={key++}
            type="number"
            value={EmptyValue(item.value) ? '0' : item.value?.toString() ?? ''}
            name={item.keyName}
            onChange={onChangeToOnChangeNumber}
          />
        );
        break;
      case eFieldType.Checkbox:
        children.push(
          <Checkbox
            key={key++}
            type="checkbox"
            checked={(item.value as boolean) ?? false}
            name={item.keyName}
            onChange={onChangeToOnChangeCheckBox}
          />
        );
        break;
      case eFieldType.String:
      default:
        children.push(
          <Input
            key={key++}
            type="text"
            defaultValue={EmptyValue(item.value) ? '' : item.value + ''}
            name={item.keyName}
            onChange={onChangeToOnChangeText()}
          />
        );
        break;
    }
  }
  return (
    <Col sm={item.columns * 2 || 8} md={item.columns || 8} key={item.keyName + '_' + key++}>
      <Form.Item key={key++} validateStatus={FieldValidation(item)}>
        {children}
      </Form.Item>
    </Col>
  );
}

const DrawCategory = (cat: Category): string => {
  if (cat.parent == null) return cat.name;
  return DrawCategory(cat.parent) + ' > ' + cat.name;
};

function SetChannelSpecificFieldValue(field: ChannelSpecificField, value: ChannelSpecificFieldValue | null) {
  let sVal: ChannelSpecificFieldValue | undefined = undefined;
  switch (field.type) {
    case 0:
    case 1:
    case 3:
    case 6:
      sVal = value ?? undefined;
      break;
    case 2:
      sVal = value === true || value === 'true' || value === 'on';
      break;
    case 4:
    case 5:
      sVal = parseFloat((value ?? '0') as string);
      break;
  }
  if (sVal != null) {
    field.value = field.multipleValues ? (Array.isArray(sVal) ? sVal : [sVal]) : sVal;
  } else {
    field.value = undefined;
  }
}

//#endregion

const GetTemplates = async () => {
  let retries = 0;
  while (retries++ < 5) {
    try {
      return await GetTemplatesPlain();
    } catch (err) {
      console.log('error downloading templates, retrying...');
    }
  }
  if (retries >= 5) {
    console.error('Could not download templates, giving up.');
  }
  return null;
};

type Props = {
  selectedChannel: Channel;
  data: ComListing;
  onSave?: () => void;
};

export class NewListingForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const K = props.data;

    const selChn = props.selectedChannel;
    const dataI = {
      ...K,
      oAuthId: selChn.id,
      channelId: selChn.channelId
    };

    const editing = dataI.listingId != null;
    const previousVariations = dataI.variations != null;

    const infoChannel = GetPlatform(selChn.channelId);

    const stitle = dataI.title;
    dataI.title = stitle.slice(0, Math.min(stitle.length, infoChannel.titleLength));

    const sourcePrice = dataI.product?.price ?? 0;

    if (previousVariations && !editing) {
      const newVariations = this.GenerateNewVariations(dataI);
      dataI.variations = newVariations;
      this.InitializeVariations(dataI.variations.variations);
    }

    const prEan = dataI.product?.ean;
    const channelId = selChn.channelId;

    this.state = {
      submiting: false,
      submitEnabled: false,
      oauthId: selChn.id,
      listing: dataI,
      suggestedTitles: [],
      //profit: sourcePrice,
      searchTerm: '',
      loadingSpecifics: false,
      loadingCategories: false,
      veroBrand: '',
      editing: editing,
      loadingEan: !editing && (!prEan || prEan == ''),
      productInChannel: null,
      possibleProductsInChannel: null,
      editProductInChannel: false,
      eanType: EanType.EAN,
      eanValid: EanField.IsValidValue(EanType.EAN, prEan ?? null, IsEbay(channelId) ? ['Does not apply'] : []),
      selectedVariation: previousVariations ? (dataI.variations?.variations?.length ?? 0) - 1 : 0,
      definitiveSubmit: false,
      connected: true,
      showOptimiseModal: false,
      optimising: false,
      dontListUntil: editing ? dataI.dontListUntil : undefined,
      bulkMode: false,
      productUrl: K.product?.url ?? '',
      optimizedDescription: false
    };

    if (K.product?.tableValues && K.product.tableValues.length > 0) {
      const brand = K.product.tableValues.find((value) => value.key.toLowerCase() === 'brand');
      if (brand && brand.value && !(brand.value === '' || brand.value.toLowerCase() === 'unbranded'))
        IsBrandInVeRoListPlain(brand.value.toString()).then((isInVero) => {
          if (isInVero)
            this.setState((prvstate) => {
              return { ...prvstate, veroBrand: brand.value };
            });
        });
    }

    GetSettingsPlain(K.sourceId, K.product?.price ?? 0).then((sett) => {
      if (!editing && (!dataI.product?.ean || dataI.product.ean == '')) {
        this.LoadEan(K.title, selChn.isoCountry, sett.eanAction == 2);
      } else {
        this.InformEanAsinChanged(props.selectedChannel.channelId, props.selectedChannel.isoCountry, dataI.product?.ean ?? '', EanType.EAN);
      }

      this.setState((prvstate) => {
        let newSpe: ComListing;

        if (prvstate.editing) {
          //Editing
          newSpe = {
            ...prvstate.listing,
            settings: sett
          };
        } else {
          //New
          let price = Math.round((K.product?.price ?? 0) * 100 * (1 + sett.markup / 100)) / 100;
          if (sett.ending99) {
            if (price > 20) price = Math.round(price) - 0.01;
            //Ends in .99
            else price = Math.ceil(price) - 0.01; //Ends in .99
          }
          newSpe = {
            ...prvstate.listing,
            settings: sett,
            price: price,
            markup: sett.markup,
            monitorStock: sett.monitorStock,
            monitorPrice: sett.monitorPrice,
            monitorPriceDecrease: sett.monitorPriceDecrease,
            monitorPriceDecreasePercentage: sett.monitorPriceDecreasePercentage,
            ignoreRules: sett.ignoreRules,
            minQuantity: sett.minQuantity,
            gsp: sett.gsp ?? false
          };
          if (this.HasVariations() && newSpe.variations) {
            newSpe.variations = { ...newSpe.variations };
            newSpe.variations.variations = [...newSpe.variations.variations];
            for (let i = 0; i < newSpe.variations.variations.length; i++) {
              const ss = newSpe.variations.variations[i].settings;
              newSpe.variations.variations[i] = {
                ...newSpe.variations.variations[i],
                settings: {
                  ...ss,
                  markup: ss.markup ?? sett.markup,
                  monitorStock: ss.monitorStock ?? sett.monitorStock,
                  monitorPrice: ss.monitorPrice ?? sett.monitorPrice,
                  monitorPriceDecrease: ss.monitorPriceDecrease ?? sett.monitorPriceDecrease,
                  monitorPriceDecreasePercentage: ss.monitorPriceDecreasePercentage ?? sett.monitorPriceDecreasePercentage,
                  ignoreRules: ss.ignoreRules ?? sett.ignoreRules,
                  minQuantity: ss.minQuantity ?? sett.minQuantity,
                  gsp: ss.gsp ?? sett.gsp
                }
              };
            }
          }
        }

        return {
          ...prvstate,
          listing: newSpe /*, profit: GetProfit(newSpe.price, K.product.price, sett.feePercentage)*/
        };
      });

      if (infoChannel.categories.has) {
        this.SearchCategory(K.product?.url ?? '', K.title, K.categoryId ?? '');
      } else {
        this.AskSpecificFields(
          K.sourceId,
          '',
          sourcePrice,
          this.state.listing.product?.title ?? '',
          this.state.listing.product?.paragraphs ?? [],
          this.state.listing.product?.properties ?? [],
          this.state.listing.channelSpecificFields
            ? this.SpecificFieldsToTVDictionary(this.state.listing.channelSpecificFields)
            : this.TableValuesToTVDictionary(this.state.listing.product?.tableValues ?? [])
        );
      }
    });

    if (selChn.channelId == ePlatform.eBay) {
      //Ebay
      GetCustomCategoriesPlain().then((cats) => {
        this.setState((prvstate) => {
          return {
            ...prvstate,
            listing: { ...prvstate.listing, customCategories: cats }
          };
        });
      });
    }

    window.setInterval(() => this.ValidateSubmit(), 1000);
  }

  componentDidMount = () => {
    // No point loading it here, has to be loaded within the context of the shadow dom.
    //var css = window.document.createElement('link');
    //css.rel = 'stylesheet';
    //css.type = 'text/css';

    //css.href = '/css/jodit.min.css';
    //document.head.appendChild(css);
    //console.log('adding stylesheet.');

    GetTemplates().then((rs) => {
      const currentChannel = this.props.selectedChannel;
      const platform = GetPlatform(currentChannel.channelId);
      this.setState({ templates: rs ?? [] });
      this.changeHtml(
        GetTemplateHtml(rs ?? [], this.state.listing, this.state.listing?.settings?.templateId ?? 0, platform.descriptionType)
      );
    });
  };

  LoadEan = async (title: string, isoCountry: eCountry, generateItRandom: boolean) => {
    let newEan: string | null = null;
    let randomEan = false;
    try {
      newEan = await this.GetEAN(title);
      if (!newEan || newEan == '') {
        if (allowRandomEan && generateItRandom) {
          newEan = GenerateRandomEan(eCountry[isoCountry]);
          randomEan = true;
        } else {
          newEan = null;
          randomEan = false;
        }
      }
    } catch (ex) {
      console.error(ex);
    }
    this.setState((prv: State) => {
      return {
        ...prv,
        loadingEan: false,
        listing: {
          ...this.state.listing,
          randomEAN: randomEan,
          product: { ...this.state.listing.product, ean: newEan }
        }
      } as State;
    });

    const l = this.state.listing;
    this.InformEanAsinChanged(
      this.props.selectedChannel.channelId,
      this.props.selectedChannel.isoCountry,
      l.product?.ean ?? '',
      EanType.EAN
    );
  };

  submitButton: HTMLButtonElement | null = null;

  //#region Generic Change Handlers
  changeByKey = (key: keyof ComListing, val: ChannelSpecificFieldBasicValue) => {
    this.setState((prvState: State) => {
      const newListing = { ...prvState.listing };
      setValue(newListing, key, val);
      return { ...prvState, listing: newListing };
    });
  };

  onChangeSetting = (evt: SimpleOnChange) => this.onChangeField(evt, true);

  onChangeField = (evt: SimpleOnChange, isSetting?: boolean) => {
    if (!isSetting || this.IsMainProductSelected()) {
      this.setState((prvState: State) => {
        if (evt.specific) {
          const spList = [...(prvState.listing.channelSpecificFields ?? [])];
          const i = spList.findIndex((x) => x.keyName == evt.keyName);
          spList[i] = { ...spList[i] };

          SetChannelSpecificFieldValue(spList[i], evt.value);
          return {
            ...prvState,
            listing: { ...prvState.listing, channelSpecificFields: spList }
          };
        } else {
          const newListing: ComListing = { ...prvState.listing };
          newListing[evt.keyName as keyof ComListing] = evt.value as never;
          return { ...prvState, listing: newListing };
        }
      });
    } else {
      this.setState((prvState) => {
        if (!prvState.listing.variations) return prvState;
        if (evt.specific) {
          const v = prvState.listing.variations.variations[this.state.selectedVariation];
          const newDic = [...v.settingSpecifics];
          const i = newDic.findIndex((x) => x.keyName == evt.keyName);
          newDic[i] = { ...newDic[parseInt(evt.keyName)] };

          const va = [...prvState.listing?.variations?.variations];
          va[this.state.selectedVariation] = {
            ...va[this.state.selectedVariation],
            settingSpecifics: newDic
          };

          SetChannelSpecificFieldValue(newDic[i], evt.value);
          return {
            ...prvState,
            listing: {
              ...prvState.listing,
              variations: {
                ...prvState.listing.variations,
                va
              }
            }
          };
        } else {
          const va = [...prvState.listing.variations.variations];
          va[this.state.selectedVariation] = { ...va[this.state.selectedVariation] };
          const ss: MiniSettings = { ...va[this.state.selectedVariation].settings };
          ss[evt.keyName as keyof MiniSettings] = evt.value as never;

          va[this.state.selectedVariation] = {
            ...va[this.state.selectedVariation],
            settings: ss
          };

          return {
            ...prvState,
            listing: {
              ...prvState.listing,
              variations: {
                ...prvState.listing.variations,
                variations: va
              }
            }
          };
        }
      });
    }
  };

  onChangeA = (name: string, type: string, val: ChannelSpecificFieldValue | null, isSetting?: boolean) => {
    this.onChangeField({ keyName: name, value: val, type: type, specific: false }, isSetting);
  };
  onChange = (evt: React.ChangeEvent<HTMLInputElement>, isSetting?: boolean) => {
    const name = evt.currentTarget.name || evt.currentTarget.id;
    const val = evt.currentTarget.type == 'checkbox' ? evt.currentTarget.checked : evt.currentTarget.value;
    this.onChangeField({ keyName: name, value: val, type: evt.currentTarget.type, specific: false }, isSetting);
  };
  onChange2 = (val: string | boolean, name: string, type: string, isSetting?: boolean) =>
    this.onChangeField({ keyName: name, value: val, type: type, specific: false }, isSetting);

  changeHtml = (newHtml: string) => this.changeByKey('description', newHtml);
  //#endregion

  //#region Form submission
  SingleSubmitFormClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    this.SubmitFormClick(evt, true);
  };
  VariationSubmitFormClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    this.SubmitFormClick(evt, true);
  };
  VariationContinueFormClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    this.SubmitFormClick(evt, false);
  };
  SubmitFormClick = (evt: React.MouseEvent<HTMLButtonElement>, submit: boolean) => {
    //if submit is false, --> continue

    if (!submit) {
      //Continue adding variations
      this.doSubmit(false);
      return;
    }

    if (!this.ValidateSubmit()) {
      evt.preventDefault();
      return;
    }

    const l = this.state.listing;

    const IsAlreadyInChannel = this.state.productInChannel && !this.state.editProductInChannel;

    if (!IsAlreadyInChannel && this.props.selectedChannel.channelId == ePlatform.Amazon && (l.description ?? '').length > 2000) {
      alert('Your description is too long (max 2000 characters)');
      return;
    }

    if (!IsAlreadyInChannel && HaveForbiddenWords(l.settings, l.title)) {
      alert("Can't be submitted because title contains forbidden words");
      return;
    }
    if (!IsAlreadyInChannel && HaveForbiddenWords(l.settings, l.description ?? '')) {
      alert("Can't be submitted because description contains forbidden words");
      return;
    }

    if (!IsAlreadyInChannel) {
      const rWords = RepeatedWords(l.title);
      let sWords = '';
      for (const i in rWords) {
        sWords += '\n' + rWords[i];
      }
      if (rWords.length > 0 && !confirm('You have repeated words in your title. Do you want to continue?' + sWords)) return;
    }

    if (l.duplicated && !confirm('This product is already listed in your store. Do you want to list again?')) return;

    if (this.state.templates == null) {
      return;
    }

    if (!IsAlreadyInChannel) {
      const hasBrandField = l.channelSpecificFields && l.channelSpecificFields.find((element) => element.name.toLowerCase() === 'brand');

      this.setState({ submiting: true, submitEnabled: false });
      this.submitButton?.setAttribute('disabled', 'disabled');
      if (!hasBrandField) {
        this.doSubmit(true);
        return;
      }

      try {
        IsBrandInVeRoListPlain(hasBrandField?.value?.toString() ?? '').then((isInVero) => {
          if (
            !isInVero ||
            confirm(
              hasBrandField?.value?.toString() +
                ' is in the VeRo list. This item should not be listed. Do you want to list it anyways? See details at https://pages.ebay.com/seller-center/listing/create-effective-listings/vero-program.html'
            )
          ) {
            this.doSubmit(true);
            if (evt.currentTarget) evt.currentTarget.disabled = true;
            return;
          }
        });
      } catch {
        this.setState({ submiting: false, submitEnabled: true });
        this.submitButton?.removeAttribute('disabled');
      }
    } else {
      this.doSubmit(true);
      return;
    }
  };

  doSubmit = (definitiveSubmit: boolean) => {
    this.setState({ submiting: true, submitEnabled: false, definitiveSubmit: definitiveSubmit });
    const sL = this.state.listing;
    const listing: ComListing = {
      ...sL,
      monitorStock: sL.monitorStock == sL.settings?.monitorStock ? undefined : sL.monitorStock,
      monitorPrice: sL.monitorPrice == sL.settings?.monitorPrice ? undefined : sL.monitorPrice,
      monitorPriceDecrease: sL.monitorPriceDecrease == sL.settings?.monitorPriceDecrease ? undefined : sL.monitorPriceDecrease,
      monitorPriceDecreasePercentage:
        sL.monitorPriceDecreasePercentage == sL.settings?.monitorPriceDecreasePercentage ? undefined : sL.monitorPriceDecreasePercentage,
      ignoreRules: sL.ignoreRules == sL.settings?.ignoreRules ? false : sL.ignoreRules,
      minQuantity: sL.minQuantity == sL.settings?.minQuantity ? undefined : sL.minQuantity,
      markup: sL.markup == sL.settings?.markup ? undefined : sL.markup,

      definitive: definitiveSubmit,

      //We don't need to send this so we delete it:
      suggestedCategories: undefined,
      customCategories: undefined,
      settings: undefined,
      dontListUntil: this.state.dontListUntil
    };

    if (this.HasVariations() && listing.variations) {
      listing.variations = { ...listing.variations };
      listing.variations.variations = [...listing.variations.variations];
      for (const i in listing.variations.variations) {
        const sO = listing.variations.variations[i].settings;
        listing.variations.variations[i] = {
          ...listing.variations.variations[i],
          settings: {
            ...sO,
            monitorStock: sO.monitorStock == sL.settings?.monitorStock ? undefined : sO.monitorStock,
            monitorPrice: sO.monitorPrice == sL.settings?.monitorPrice ? undefined : sO.monitorPrice,
            monitorPriceDecrease: sO.monitorPriceDecrease == sL.settings?.monitorPriceDecrease ? undefined : sO.monitorPriceDecrease,
            monitorPriceDecreasePercentage:
              sO.monitorPriceDecreasePercentage == sL.settings?.monitorPriceDecreasePercentage
                ? undefined
                : sO.monitorPriceDecreasePercentage,
            ignoreRules: sO.ignoreRules == sL.settings?.ignoreRules ? false : sO.ignoreRules,
            minQuantity: sO.minQuantity == sL.settings?.minQuantity ? undefined : sO.minQuantity,
            markup: sO.markup == sL.settings?.markup ? undefined : sO.markup
          }
        };
      }
    }

    if (this.state.productInChannel && !this.state.editProductInChannel) {
      const pChoosed = this.state.productInChannel;
      listing.title = pChoosed.title;
      listing.channelSpecificFields = [];
      if (listing.product) listing.product.imageUrls = [pChoosed.mainImage];
      listing.description = '';
      listing.storeCatalog = true;
      listing.storeCatalogId = pChoosed.id + '';
    } else {
      listing.storeCatalog = false;
    }

    for (const csf of listing?.channelSpecificFields ?? []) {
      csf.possibleValues = null;
      csf.validationRules = null;
    }

    if (listing?.product?.document) listing.product.document = null;

    SubmitListingPlain(listing)
      .then((r) => {
        this.setState({ resultSubmition: { status: r.success ? 1 : 0, error: r.error } });
        if (r.success) {
          this.props.onSave?.();
        }
      })
      .catch((ex) => {
        this.setState({ resultSubmition: { status: 0, error: ex } });
      });
  };
  //#endregion

  //<CommonNewListingElements {...this.state} />

  render(): JSX.Element {
    if (this.state.bulkMode) {
      return (
        <>
          <Alert type="info" message={<PrimaryBtn onClick={(_) => this.setState({ bulkMode: false })}>Return</PrimaryBtn>} />
          <BulkListing urls={[this.state.productUrl, ...(this.state.listing.otherUrls ?? [])]} />
        </>
      );
    }

    const currentChannel = this.props.selectedChannel;
    const variationsOn = this.HasVariations();
    const platform = GetPlatform(currentChannel.channelId);

    let foundOneNotInChannel = false;
    let emptyProductInChannel = true;
    if (this.state.productInChannel) {
      emptyProductInChannel = false;
      if (!this.state.productInChannel) {
        foundOneNotInChannel = true;
      }
    }

    const UpdateDescription = (data: { paragraphs: string[]; properties: string[]; tableValues: FeaturesTableRow[] }) => {
      this.setState((prv) => {
        return {
          ...prv,
          optimizedDescription: true,
          listing: {
            ...prv.listing,
            product: { ...prv.listing.product, data }
          } as ComListing
        };
      });
    };

    const IsAlreadyInChannel = !emptyProductInChannel && !foundOneNotInChannel && !this.state.editProductInChannel;
    const tabItems = (() => {
      const items = [];
      if (variationsOn) {
        items.push({
          label: 'Variations',
          key: 'variations',
          children: <div className="tab-container variations-container">{this.RenderVariations()}</div>
        });
      } else {
        items.push({
          label: 'Product',
          key: 'product',
          children: (
            <div className="tab-container product-container"> {this.RenderShopFields(this.state.listing, this.AskSpecificFields)} </div>
          )
        });
        if (!IsAlreadyInChannel) {
          items.push({
            label: 'Description',
            key: 'description',
            children: (
              <div className="tab-container description-container">
                <React.Suspense fallback={<div>loading...</div>}>
                  <HtmlEditor
                    descriptionLength={platform.descriptionLength}
                    type={platform.descriptionType}
                    rawHtml={this.state.listing.description}
                    onChange={this.changeHtml}
                    rawData={this.state.listing}
                    templates={this.state.templates}
                    selectedChannel={this.props.selectedChannel}
                    onOptimizedDescription={UpdateDescription}
                    optimizedDescription={this.state.optimizedDescription}
                  />
                </React.Suspense>
              </div>
            )
          });
        }
      }
      if (!IsAlreadyInChannel) {
        items.push({
          label: variationsOn ? 'Variation Images' : 'Images',
          key: 'images',
          children: (
            <div className="tab-container images-container">
              <ImagesView
                images={this.state.listing.product?.imageUrls ?? []}
                imagesChanged={this.ProductImagesChanged} //Without variations
                hasVariations={variationsOn}
                variations={this.state.listing.variations}
                onVariationsChanged={this.OnVariationsChanged}
              />
            </div>
          )
        });
      }

      items.push({
        label: variationsOn ? 'Variation Settings' : 'Settings',
        key: 'settings',
        children: <div className="tab-container">{this.RenderSettings()}</div>
      });
      if (variationsOn) {
        items.push({
          label: variationsOn ? 'Common Specifics' : 'Settings',
          key: 'product2',
          children: <div className="tab-container">{this.RenderShopFields(this.state.listing, this.AskSpecificFields)}</div>
        });
        if (!IsAlreadyInChannel) {
          items.push({
            label: 'Common Description',
            key: 'description2',
            children: (
              <div className="tab-container">
                <React.Suspense fallback={<div>loading...</div>}>
                  <HtmlEditor
                    descriptionLength={platform.descriptionLength}
                    type={platform.descriptionType}
                    rawHtml={this.state.listing.description}
                    onChange={this.changeHtml}
                    rawData={this.state.listing}
                    templates={this.state.templates}
                    selectedChannel={this.props.selectedChannel}
                    onOptimizedDescription={UpdateDescription}
                    optimizedDescription={this.state.optimizedDescription}
                  />
                </React.Suspense>
              </div>
            )
          });
        }
      }
      return items;
    })();

    return (
      <div className="listing-extension-container">
        {!this.state.resultSubmition && (
          <>
            {!this.state.submiting && (
              <>
                {this.state.listing.otherUrls != null && this.state.listing.otherUrls.length > 0 && (
                  <Alert
                    type="info"
                    message={
                      <>
                        Detected {this.state.listing.otherUrls.length} additional products on this page.&nbsp;
                        <PrimaryBtn style={{ display: 'inline-block' }} onClick={(_) => this.setState({ bulkMode: true })}>
                          View links
                        </PrimaryBtn>
                      </>
                    }
                  />
                )}
                {this.state.listing.editingErrorMessage && (
                  <Alert type="error" message={'Previous Error Message: ' + this.state.listing.editingErrorMessage} />
                )}
                {this.state.veroBrand && (
                  <>
                    <Alert
                      type="info"
                      message={
                        this.state.veroBrand +
                        ' is in the VeRo list. This item should not be listed. Do you want to list it anyways? See details at https://pages.ebay.com/seller-center/listing/create-effective-listings/vero-program.html'
                      }
                    />
                  </>
                )}
                <div className="variations-mode-container">
                  {!variationsOn && (currentChannel.channelId == ePlatform.eBay || currentChannel.channelId == ePlatform.eBayNoApi) && (
                    <PrimaryBtn className="variationsButton" onClick={this.ActivateVariationMode}>
                      Variations mode <SyncOutlined />
                    </PrimaryBtn>
                  )}
                  {variationsOn && (
                    <PrimaryBtn className="variationsButton" onClick={this.OnClearVariations}>
                      Single listing mode <SyncOutlined />
                    </PrimaryBtn>
                  )}
                </div>

                <Tabs defaultActiveKey={variationsOn ? 'variations' : 'product'} items={tabItems} />
                <div className="button-container">
                  {(!variationsOn || this.state.editing) && (
                    <PrimaryBtn
                      refC={(x) => (this.submitButton = x)}
                      disabled={!this.state.submitEnabled}
                      className="big-btn"
                      onClick={this.SingleSubmitFormClick}
                    >
                      {this.state.subtmitErrorMessage
                        ? this.state.subtmitErrorMessage
                        : this.state.templates == null
                          ? 'Composing description...'
                          : this.state.editing
                            ? 'Save and retry'
                            : 'List on ' + GetPlatform(currentChannel.channelId).storeName}
                    </PrimaryBtn>
                  )}

                  {variationsOn && !this.state.editing && (
                    <Row className="buttons-variations-row">
                      <Col sm={24} md={10}>
                        <button type="button" className="btn-block success-btn" onClick={this.VariationContinueFormClick}>
                          {'Continue adding variations'}
                        </button>
                      </Col>
                      <Col sm={24} md={10}>
                        <button
                          type="button"
                          ref={(x) => (this.submitButton = x)}
                          disabled={!this.state.submitEnabled || this.state.listing.variations?.variations?.length === 1}
                          className="btn-block"
                          onClick={this.VariationSubmitFormClick}
                        >
                          {'Finish and list these variations on ' + GetPlatform(currentChannel.channelId).storeName}
                        </button>
                      </Col>
                    </Row>
                  )}
                </div>
              </>
            )}
            {this.state.submiting && (
              <div>
                <div className="centerLoading">
                  <Spin />
                </div>
                <span className="submitingText">Submiting listing...</span>
              </div>
            )}
          </>
        )}
        {this.state.resultSubmition && (
          <LastMessage
            definitive={this.state.definitiveSubmit}
            success={this.state.resultSubmition.status != 0}
            urlCatalog={Links.Catalog}
            site={this.props.selectedChannel.isoCountry}
            channelId={this.props.selectedChannel.channelId}
          />
        )}
        <QuestionModal
          visible={this.state.showOptimiseModal}
          onOk={this.ExecuteOptimiseTitle}
          onCancel={() => {
            this.setState({ showOptimiseModal: false });
          }}
        >
          <h4>Do you want to optimise the title?</h4>
          <div className="text-center">
            <span>Cost:</span>
            <TokensCosts cost={1} style={{ display: 'inline-block' }} />
          </div>
        </QuestionModal>
      </div>
    );
  }

  ProductImagesChanged = (urls: string[]) => {
    this.setState((prv) => {
      return {
        ...prv,
        listing: {
          ...prv.listing,
          product: { ...prv.listing.product, imageUrls: urls }
        } as ComListing
      };
    });
  };

  GenerateNewVariations = (l: ComListing) => {
    const oldVariations = l.variations;
    const newVariations = { ...oldVariations } as ListingVariationsResponse;
    if (!oldVariations) {
      newVariations.groupId = -1;
      newVariations.variationImages = {
        attribute: 'Color',
        imagesByOption: [
          {
            option: 'Green',
            urls: [...(l.product?.imageUrls ?? [])]
          }
        ]
      };
    }

    const attributes: AttributeOption[] = [];
    if (oldVariations?.variations && oldVariations.variations.length > 0) {
      newVariations.variations = [...oldVariations.variations];
      for (const ao of oldVariations.variations[0].attributeOptions) {
        attributes.push({ attribute: ao.attribute, option: '' });
      }
    } else {
      newVariations.variations = [];
      attributes.push({ attribute: 'Color', option: 'Green' });
    }

    //const img = l.product.imageUrls && l.product.imageUrls.length > 0 ? l.product.imageUrls[0] : "";
    newVariations.variations.push({
      attributeOptions: attributes,
      url: l.product?.url ?? '',
      listingId: l.listingId ?? 0,
      images: l.product?.imageUrls ?? [],
      //This data will be not used so we can put random data:
      sourcePrice: l.product?.price ?? 0,
      price:
        Math.round(
          100 * (((this.state?.listing?.markup ?? this.state?.listing?.settings?.markup ?? 40) + 100) / 100) * (l.product?.price ?? 0)
        ) / 100,
      createdBy: this.state?.listing?.createdBy ?? 0,
      ean: this.state?.listing?.product?.ean ?? this.state?.listing?.settings?.defaultEAN ?? 'Does not apply',
      quantity: 1,
      settings: {},
      settingSpecifics: []
    });
    return ReCalculateUrls(newVariations);
  };

  ActivateVariationMode = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    const newVariations = this.GenerateNewVariations(this.state.listing);
    this.setState({
      listing: {
        ...this.state.listing,
        variations: newVariations
      },
      selectedVariation: 0
    });
  };

  OnVariationSelected = (v: VariationListingInfo, i: number) => {
    this.setState({ selectedVariation: i });
  };

  RenderVariations() {
    const listing = this.state.listing;
    const mainProduct = this.IsMainProductSelected();
    const currentVar =
      mainProduct || !this.state.listing.variations ? null : this.state.listing.variations.variations[this.state.selectedVariation];
    if (listing.settings) {
      return (
        <>
          <VariationsPanel
            variations={this.state.listing.variations}
            onVariationsChanged={this.OnVariationsChanged}
            onSelected={this.OnVariationSelected}
            selectedVariationIndex={this.state.selectedVariation}
            attributePanel={true}
            onClearVariations={this.OnClearVariations}
            onDeleteOneVariation={this.OnDeleteOneVariation}
            canClearVariations={!this.state.editing}
            mainVariationId={this.state.listing.listingId ?? 0}
          >
            <Form layout="vertical">
              <Row className="options-fields-row">
                {this.RenderQuantity(mainProduct ? listing?.quantity ?? 0 : currentVar?.quantity ?? 0, this.ChangeQuantity)}

                {this.RenderPrice(
                  mainProduct ? listing.price ?? 0 : currentVar?.price ?? 0,
                  mainProduct ? listing.product?.price ?? 0 : currentVar?.sourcePrice ?? 0,
                  this.state.listing.settings?.feePercentage ?? 0,
                  this.ChangeMarkup
                )}
                {this.RenderMarkup(
                  mainProduct ? listing.markup ?? 0 : currentVar?.settings?.markup ?? listing.settings?.markup ?? 0,
                  mainProduct ? listing.product?.price ?? 0 : currentVar?.sourcePrice ?? 0,
                  this.state.listing.settings?.feePercentage ?? 0,
                  mainProduct ? listing.price ?? 0 : currentVar?.price ?? 0,
                  this.ChangeMarkup ?? 0
                )}

                {this.RenderEAN(mainProduct ? listing.product?.ean ?? listing.settings?.defaultEAN : currentVar?.ean ?? '', this.ChangeEan)}
                {this.RenderCreatedBy(
                  mainProduct ? listing?.createdBy ?? 0 : currentVar?.createdBy ?? 0,
                  listing.configuration?.assistants ?? {},
                  (v, f) => this.onChange2(v as string, f, 'text')
                )}
              </Row>
            </Form>
          </VariationsPanel>
        </>
      );
    } else {
      return <Spin />;
    }
  }

  //#region ChannelSpecifics
  RenderShopFields(comListing: ComListing, AskSpecificFields: CategoryChangedType) {
    const kids: React.ReactElement[] = [];
    const selChn = this.props.selectedChannel;
    const platform = GetPlatform(selChn.channelId);

    const requiredFields: React.ReactElement[] = [];
    const optionalFields: React.ReactElement[] = [];

    if (comListing.channelSpecificFields) {
      for (const field of comListing.channelSpecificFields) {
        if (field.keyName == 'quantity') continue;
        if (field.setting === 0) {
          let req = false;
          if (field.validationRules && field.validationRules.required) req = true;
          const f = DrawField(field, this.onChangeField, comListing.channelSpecificFields, req);
          if (f)
            if (req) requiredFields.push(f);
            else optionalFields.push(f);
        }
      }
    }

    const IsAlreadyInChannel = this.state.productInChannel && !this.state.editProductInChannel;

    let category = <></>;
    if (platform.categories.has && !IsAlreadyInChannel) {
      //Ebay & Amazon
      category = this.RenderCategoryField(comListing, AskSpecificFields);
    }

    kids.push(
      <fieldset key="specfields" className="specfields">
        {this.RenderFixedSpecificFields(comListing)}
        {!IsAlreadyInChannel && (
          <>
            {category}
            {comListing.channelSpecificFields && comListing.channelSpecificFields.length > 0 && (
              <>
                <Row>{requiredFields}</Row>
                {optionalFields && optionalFields.length > 0 && (
                  <>
                    <Collapse className="optional-fields" bordered={false} defaultActiveKey={0}>
                      <Collapse.Panel header="Optional fields" key={1}>
                        {optionalFields}
                      </Collapse.Panel>
                    </Collapse>
                  </>
                )}
              </>
            )}
            {!comListing.channelSpecificFields && comListing.suggestedCategories && comListing.categoryId && <Spin />}
          </>
        )}
      </fieldset>
    );

    return <>{kids}</>;
  }

  RenderFixedSpecificFields(listing: ComListing) {
    const channelId = this.props.selectedChannel.channelId;
    const possibles = this.state.possibleProductsInChannel;
    const prdInCh = this.state.productInChannel;
    const variationMode = listing.variations && listing.variations.variations && listing.variations.variations.length > 0;
    if (listing.settings) {
      return (
        <>
          {(prdInCh || possibles) && ( //Amazon
            <ProductPanelInChannel
              editProductInChannel={this.state.editProductInChannel}
              product={prdInCh ?? undefined}
              possibles={possibles ?? undefined}
              channelId={channelId}
              OnEditProductInChannelChange={(editing: boolean) => {
                this.setState((prv) => {
                  return { ...prv, editProductInChannel: editing };
                });
              }}
              OnSelectProduct={(prd: ProductInChannelData) => {
                this.onChangeProductN('ean', prd.id);
                this.setState((prv) => {
                  return {
                    ...prv,
                    productInChannel: { ...prd, selectedBy: EanType.ASIN },
                    eanType: EanType.ASIN
                  };
                });
              }}
            />
          )}
          {this.RenderTitle()}
          {!variationMode && (
            <Row className="options-fields-row">
              {this.RenderQuantity(listing.quantity, this.ChangeQuantity)}
              {this.RenderPrice(listing.price ?? 0, listing.product?.price ?? 0, listing.settings?.feePercentage, this.ChangeMarkup)}
              {this.RenderMarkup(
                listing.markup ?? 0,
                listing.product?.price ?? 0,
                listing.settings?.feePercentage,
                listing.price ?? 0,
                this.ChangeMarkup
              )}
              {this.RenderEAN(
                listing.settings && listing.product?.ean == null ? listing.settings?.defaultEAN ?? '' : listing.product?.ean ?? '',
                this.ChangeEan
              )}
              {this.RenderCreatedBy(listing.createdBy ?? 0, listing.configuration?.assistants ?? {}, (v, f) =>
                this.onChange2(v as string, f, 'text')
              )}
            </Row>
          )}
        </>
      );
    } else {
      return <Spin />;
    }
  }

  RenderEAN(value: string, onChange: EanFieldChangeEvt) {
    const channelId = this.props.selectedChannel.channelId;
    const platform = GetPlatform(channelId);
    if (!platform.EAN.needed) return <></>;
    return (
      <Col className="form-option" sm={4} md={4}>
        <EanField
          whiteList={platform.EAN.canBeEmpty ? [] : ['Does not apply']}
          allowAsin={platform.EAN.allowAsin} //amazon
          loading={this.state.loadingEan}
          value={value}
          type={this.state.eanType}
          onChangeValue={onChange}
        />
      </Col>
    );
  }

  RenderPrice(value: number, originalPrice: number, feePercentage: number, onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void) {
    return (
      <Col className="form-option" sm={4} md={4}>
        <Form.Item validateStatus={value ? undefined : 'error'}>
          <div className="label-special-container">
            <label className="main-label">Price</label>
            <span className="span-info" title="This price includes shipping fees and taxes when applicable">
              <QuestionCircleOutlined /> <p>Cost: {originalPrice.toFixed(2)}</p>
            </span>
          </div>
          <Input type="number" value={value ?? 0} min="0" name="price" onChange={onChange} />
        </Form.Item>
      </Col>
    );
  }

  RenderMarkup(
    value: number,
    originalPrice: number,
    feePercentage: number,
    newPrice: number,
    onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void
  ) {
    const profit = GetProfit(newPrice, originalPrice, feePercentage);
    return (
      <Col className="form-option" sm={4} md={4}>
        <Form.Item validateStatus={value ? undefined : 'error'}>
          <div className="label-special-container">
            <label className="main-label">Markup</label>
            <span className="span-info" title={this.ProfitTitleInfo(profit, newPrice, originalPrice, feePercentage)}>
              <QuestionCircleOutlined /> <p>Profit: {profit}</p>
            </span>
          </div>
          <Input type="number" value={value ?? 0} min="0" max="5000" name="markupV" onChange={onChange} />
        </Form.Item>
      </Col>
    );
  }

  ProfitTitleInfo(profit: number, newPrice: number, originalPrice: number, fees: number) {
    const grossMargin = newPrice - originalPrice;
    const grossMarginRounded = Math.round(100 * grossMargin) / 100;
    const feesInMoney = Math.round(newPrice * fees) / 100;
    const profitRounded = Math.round(100 * profit) / 100;
    return (
      'Gross Margin: ' +
      grossMarginRounded +
      '\nFee: ' +
      fees +
      '% (' +
      feesInMoney +
      ')\nNet Margin: ' +
      grossMarginRounded +
      ' - ' +
      feesInMoney +
      ' = ' +
      profitRounded
    );
  }

  RenderQuantity(value: number, onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void) {
    return (
      <Col className="form-option" sm={4} md={4}>
        <Form.Item validateStatus={value ? undefined : 'error'}>
          <label className="main-label">Quantity</label>
          <Input type="number" value={value ?? 1} min="1" max="250" name="quantity" onChange={onChange} />
        </Form.Item>
      </Col>
    );
  }

  RenderCreatedBy(value: number, assistants: { [key: number]: string }, onChange: (v: SelectorValue, fieldName: string) => void) {
    return (
      <Col className="form-option" sm={4} md={4}>
        <Form.Item>
          <label className="main-label">Created by</label>
          <Selector value={value} onChange={(v) => onChange(v, 'createdBy')}>
            {Object.keys(assistants).map((v) => ({ value: v, label: assistants[parseInt(v)] }))}
          </Selector>
        </Form.Item>
      </Col>
    );
  }

  RenderTitle() {
    const poa = this.props.selectedChannel;
    const prdInCh = this.state.productInChannel;
    const listing = this.state.listing;
    if (!prdInCh || this.state.editProductInChannel) {
      const canOptimise = GetPlatform(this.props.selectedChannel.channelId).canOptimizeTitle[this.props.selectedChannel.isoCountry];

      return (
        <div className="section-form">
          <Form layout="horizontal">
            <Form.Item validateStatus={this.titleLengthValidation()}>
              <Col sm={24}>
                <h4 className="main-title-form">Title</h4>
              </Col>
              <Col sm={22}>
                <TitleEditor
                  name="title"
                  suggestedTitles={this.state.suggestedTitles}
                  value={listing.title}
                  inputValue={''}
                  titleMaxLength={GetPlatform(this.props.selectedChannel.channelId).titleLength}
                  textMode={!this.state.listing.settings?.titleSuggestions}
                  onChange={(value) => {
                    this.onChangeField({
                      keyName: 'title',
                      type: 'creatable',
                      value: value,
                      specific: false
                    });
                    this.GetProductByText(poa.channelId, poa.isoCountry, value);
                  }}
                />
                {canOptimise && (
                  <div style={{ marginTop: '20px' }}>
                    {!this.state.listing.optimisedTitle && !this.state.optimising && (
                      <div>
                        <A to="#" onClick={this.OnOptimiseClick}>
                          Optimize title automatically
                        </A>
                        <img className="coinicon" src={coinIcon} alt="" />
                      </div>
                    )}
                    {this.state.listing.optimisedTitle && !this.state.optimising && 'Title optimised'}
                    {this.state.optimising && 'Optimising...'}
                  </div>
                )}
              </Col>
            </Form.Item>
          </Form>
        </div>
      );
    }
    return <></>;
  }

  RenderCategoryField(sp: ComListing, AskSpecificFields: CategoryChangedType) {
    const selChn = this.props.selectedChannel;
    const platform = GetPlatform(selChn.channelId);

    return (
      <div className="section-form">
        {platform.categories.canSearch && (
          <Form
            onSubmitCapture={(e) => {
              e.preventDefault();
              this.SearchCategory(null, this.state.searchTerm, sp.categoryId);
            }}
          >
            <Col md={24} className="category-container">
              <Col sm={24}>
                <h4 className="main-title-form">Category</h4>
              </Col>

              <div className="search">
                <Col sm={21} key="categorysI">
                  <Input
                    className="category-search"
                    type="text"
                    name="sCategory"
                    onChange={(event) => {
                      event.persist();
                      this.setState((prvstate) => {
                        return { ...prvstate, searchTerm: event.target.value };
                      });
                    }}
                  />
                </Col>
                <Col sm={2} md={2} style={{ paddingLeft: 10, paddingRight: 5 }} key="categorysB">
                  <PrimaryBtn htmlType="submit">Search</PrimaryBtn>
                </Col>
              </div>

              {!this.state.connected && IsApi(selChn.channelId) && (
                <Col sm={7} md={7} style={{ paddingLeft: 5, paddingRight: 5, color: 'red' }} key="categorysC">
                  You need to refresh your {platform.storeName} account to HGR from <A to={Links.Dashboard}>here</A>. Why did this
                  happen? The authorisation to connect to your {platform.storeName} account can expire or be revoked when you change your
                  eBay password
                </Col>
              )}
              {this.state.connected && sp.suggestedCategories && sp.suggestedCategories.length == 0 && (
                <Col sm={14} md={14} style={{ paddingLeft: 5, paddingRight: 5 }} key="categorysC">
                  We couldn’t find a suitable category for this product, search and select one
                </Col>
              )}
            </Col>
          </Form>
        )}
        {sp.suggestedCategories && sp.suggestedCategories.length > 0 && (
          <Row style={{ marginBottom: 30, marginTop: 10 }}>
            <Col md={24} key={'category'}>
              <Selector
                defaultValue={sp.categoryId}
                onChange={(value) => {
                  this.setState((prvstate: State) => {
                    return {
                      ...prvstate,
                      listing: { ...prvstate.listing, categoryId: value as string }
                    };
                  });
                  AskSpecificFields(
                    sp.sourceId,
                    value as string,
                    this.state.listing.product?.price ?? 0,
                    this.state.listing.product?.title ?? '',
                    this.state.listing.product?.paragraphs ?? [],
                    this.state.listing.product?.properties ?? [],
                    (this.state.listing.channelSpecificFields
                      ? this.SpecificFieldsToTVDictionary(this.state.listing.channelSpecificFields)
                      : this.TableValuesToTVDictionary(this.state.listing.product?.tableValues ?? [])) ?? {}
                  );
                }}
              >
                {sp.suggestedCategories.map((itm) => ({ label: DrawCategory(itm), value: itm.id }))}
              </Selector>
            </Col>
          </Row>
        )}
        {!sp.suggestedCategories && <Spin />}
      </div>
    );
  }

  /**
   * CategoryId is only needed if we need that information (for example, Shopify doesn't need it)
   */
  AskSpecificFields = (
    sourceId: number,
    categoryId = '',
    originPrice = 0,
    title: string,
    paragraphs: string[],
    features: string[],
    tableValues: { [name: string]: string } | null = null
  ) => {
    const prevFields = this.state.listing.channelSpecificFields;

    this.setState((prvstate: State) => {
      return {
        ...prvstate,
        listing: { ...prvstate.listing, channelSpecificFields: undefined },
        loadingSpecifics: true
      };
    });
    GetCategorySpecificsPlain(sourceId, categoryId, originPrice, title, paragraphs, features, tableValues).then((response) => {
      this.setState((prvstate) => {
        this.OverwriteSpecificFieldsValues(response, prevFields ?? []);
        return {
          ...prvstate,
          listing: { ...prvstate.listing, channelSpecificFields: response },
          loadingSpecifics: false
        };
      });
    });
  };

  OverwriteSpecificFieldsValues = (fields: ChannelSpecificField[], valuedFields: ChannelSpecificField[]) => {
    if (!fields || !valuedFields) return;

    const dic: { [keyName: string]: ChannelSpecificField } = {};
    for (const g of valuedFields) {
      dic[g.keyName] = g;
    }

    for (const f of fields) {
      const dfk = dic[f.keyName];
      if (dfk) {
        if (f.multipleValues == dfk.multipleValues) {
          f.value = dfk.value;
        } else if (dfk.value) {
          if (f.multipleValues) {
            f.value = [dfk.value] as ChannelSpecificFieldBasicValue[];
          } else {
            f.value = Array.isArray(dfk.value) ? dfk.value[0] : dfk.value;
          }
        }
      }
    }
  };

  SpecificFieldsToKeyDictionary = (fields: ChannelSpecificField[]): { [name: string]: ChannelSpecificField } | null => {
    if (fields) {
      const tvDic: { [name: string]: ChannelSpecificField } = {};
      for (const ind in fields) {
        const f = fields[ind];
        tvDic[f.keyName] = f;
        if (f.hideFormula) {
          NotarizeFormula(f.hideFormula);
        }
      }
      return tvDic;
    }

    return null;
  };

  SpecificFieldsToTVDictionary = (fields: ChannelSpecificField[]): { [name: string]: string } | null => {
    if (fields) {
      const tvDic: { [name: string]: string } = {};
      for (const ind in fields) {
        const f = fields[ind];
        tvDic[f.keyName] = f.value + '';
      }
      return tvDic;
    }

    return null;
  };

  TableValuesToTVDictionary = (tableValue: FeaturesTableRow[]): { [name: string]: string } | null => {
    if (tableValue) {
      const tvDic: { [name: string]: string } = {};
      for (const ind in tableValue) {
        const tv = tableValue[ind];
        tvDic[tv.key] = tv.value + '';
      }
      return tvDic;
    }

    return null;
  };

  SetCategories = (categories: Category[], connected: boolean) => {
    this.setState((prvstate: State) => {
      return {
        ...prvstate,
        listing: {
          ...prvstate.listing,
          suggestedCategories: categories,
          categoryId: categories.length > 0 ? categories[0].id : null
        },
        loadingCategories: false,
        connected: connected
      } as State;
    });
    if (categories.length > 0) {
      this.AskSpecificFields(
        this.state.listing.sourceId,
        categories[0].id,
        this.state.listing.product?.price ?? 0,
        this.state.listing.product?.title ?? '',
        this.state.listing.product?.paragraphs ?? [],
        this.state.listing.product?.properties ?? [],
        this.state.listing.channelSpecificFields
          ? this.SpecificFieldsToTVDictionary(this.state.listing.channelSpecificFields)
          : this.TableValuesToTVDictionary(this.state.listing.product?.tableValues ?? [])
      );

      //if (platform.categories.has && this.state.listing.product) {
      if (IsEbay(this.props.selectedChannel.channelId) && this.state.listing.product) {
        //Only in Ebay and EbayNoApi
        GetSuggestedTitlesPlain(categories[0].id + '', this.state.listing.product).then((suggestedTitles) => {
          this.setState((prvstate) => {
            const options = prvstate.suggestedTitles;
            if (suggestedTitles?.mainWords?.length > 0) {
              options.push({
                label: 'Main keywords',
                options: this.SuggestionToOptions(TitleSuggestionsGroup.MainWords, suggestedTitles?.mainWords ?? [])
              });
            }
            if (suggestedTitles?.extraWords?.length > 0) {
              options.push({
                label: 'Extra keywords',
                options: this.SuggestionToOptions(TitleSuggestionsGroup.ExtraWords, suggestedTitles?.extraWords)
              });
            }
            if (suggestedTitles?.titles?.length > 0) {
              const titlesOptions = this.SuggestionToOptions(TitleSuggestionsGroup.Titles, suggestedTitles?.titles);
              titlesOptions.push({
                group: TitleSuggestionsGroup.Titles,
                label: this.state.listing.title,
                value: 'From original title'
              });
              options.push({
                label: 'Suggested titles',
                options: titlesOptions
              });
            }
            options.push({
              label: 'From original title',
              options: this.state.listing.title.split(' ').map((s) => {
                return { group: TitleSuggestionsGroup.OriginalTitle, label: s, value: '' };
              })
            });

            return { ...prvstate, suggestedTitles: options };
          });
        });
      }
    }
  };

  SuggestionToOptions = (group: TitleSuggestionsGroup, suggestion: (SuggestionWord | SuggestionTitle)[]) => {
    return suggestion.map((element) => {
      return {
        group: group,
        label: isTypeSuggestionWord(element) ? element.word : element.title,
        value: element.count + ' sold'
      };
    });
  };

  SearchCategory = async (url: string | null, text: string, selectedCategoryId: string | undefined) => {
    this.setState((prvstate: State) => {
      return {
        ...prvstate,
        listing: {
          ...prvstate.listing,
          suggestedCategories: undefined,
          categoryId: undefined,
          //channelSpecificFields: this.state.listing.listingId ? this.state.listing.channelSpecificFields : null
          channelSpecificFields: this.state.listing.channelSpecificFields
        } as ComListing,
        loadingCategories: true
      } as State;
    });

    let prevCat: Category | undefined = undefined;
    if (selectedCategoryId) prevCat = await GetCategoryInfoPlain(selectedCategoryId);

    let catsR: CategorySuggestionsResponse;
    if (url) catsR = await GetSuggestedCategoriesPlain(url, text);
    else catsR = await GetCategoriesByTextPlain(text);

    const cats = catsR.categories;

    if (prevCat) {
      for (let i = 0; i < cats.length; i++) {
        if (cats[i].id == prevCat.id) {
          cats.splice(i, 1);
          break;
        }
      }
      cats.unshift(prevCat);
    }

    this.SetCategories(cats, (catsR.errorFlag & eListingErrorFlags.InvalidToken) == 0);
  };

  HasVariations = (): boolean => {
    const vList = this.state.listing.variations?.variations;
    return vList != null && vList.length > 0;
  };
  IsMainProductSelected = () =>
    !this.HasVariations() ||
    !this.state.listing.variations ||
    this.state.listing.variations.variations[this.state.selectedVariation].listingId == this.state.listing.listingId; //Last product will always be the main

  ChangeEan = (type: EanType, value: string, isValid: boolean) => {
    if (this.IsMainProductSelected()) {
      this.onChangeProductN('ean', value);
      this.setState((prv) => {
        return { ...prv, loadingEan: false, eanType: type, eanValid: isValid };
      });
    } else {
      //Variation
      this.setState((prv: State) => {
        if (!prv.listing.variations) return prv;
        const va = [...prv.listing.variations.variations];
        va[prv.selectedVariation] = {
          ...prv.listing.variations.variations[prv.selectedVariation],
          ean: value
        };

        return {
          ...prv,
          loadingEan: false,
          eanType: type,
          eanValid: isValid,
          listing: { ...prv.listing, variations: { ...prv.listing.variations, variations: va } }
        };
      });
    }
  };

  ChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nVal = parseInt(e.currentTarget.value);
    if (this.IsMainProductSelected()) {
      this.setState((prv) => {
        return { ...prv, listing: { ...this.state.listing, quantity: nVal } };
      });
    } else {
      this.setState((prvstate: State) => {
        if (!prvstate.listing.variations) return prvstate;
        const va = [...prvstate.listing.variations.variations];
        va[prvstate.selectedVariation] = {
          ...prvstate.listing.variations.variations[prvstate.selectedVariation],
          quantity: nVal
        };
        return {
          ...prvstate,
          listing: {
            ...prvstate.listing,
            variations: { ...prvstate.listing.variations, variations: va }
          }
        };
      });
    }
  };

  ChangeMarkup = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isMainProductSelected = this.IsMainProductSelected();
    const sourcePrice = isMainProductSelected
      ? this.state.listing.product?.price ?? 0
      : this.state.listing.variations?.variations?.[this.state.selectedVariation]?.sourcePrice ?? 1;

    const t = e.currentTarget;
    const target = t.name;
    const newValue = t.value;

    let newPrice: number;
    let newMarkup: number;
    if (target == 'markupV') {
      // we need to calculate price, and set markup
      newMarkup = parseInt(newValue);
      newPrice = Math.round(sourcePrice * (1 + newMarkup / 100) * 100) / 100;
    } else {
      newPrice = parseFloat(newValue);
      newMarkup = Math.round((100 * (newPrice - sourcePrice)) / sourcePrice);
    }

    if (isMainProductSelected) {
      this.setState((prvstate) => {
        return {
          ...prvstate,
          listing: {
            ...prvstate.listing,
            markup: newMarkup,
            price: newPrice
          }
          //,profit: GetProfit(newPrice, this.state.listing.product?.price, this.state.listing.settings?.feePercentage)
        };
      });
    } else {
      //Variation
      this.setState((prvstate: State) => {
        if (!prvstate.listing.variations) return prvstate;
        const va = [...prvstate.listing.variations.variations];
        va[prvstate.selectedVariation] = {
          ...prvstate.listing.variations.variations[prvstate.selectedVariation],
          settings: {
            ...prvstate.listing.variations.variations[prvstate.selectedVariation].settings,
            markup: newMarkup
          },
          price: newPrice
        };

        return {
          ...prvstate,
          listing: {
            ...prvstate.listing,
            variations: { ...prvstate.listing.variations, variations: va }
          },
          profit: GetProfit(newPrice, this.state.listing.product?.price ?? 0, this.state.listing.settings?.feePercentage ?? 0)
        };
      });
    }
  };

  titleLengthValidation() {
    const length = this.state.listing.title.length;

    const infoChannel = GetPlatform(this.props.selectedChannel.channelId);
    if (length > 10 && length <= infoChannel.titleLength) return 'success';
    else if (length <= 10) return 'warning';
    else if (length > infoChannel.titleLength) return 'error';
    return undefined;
  }

  //onChangeProduct = (e) => {
  //  const t = e.currentTarget;
  //  return this.onChangeProductN(t.name, t.value);
  //};

  onChangeProductN = (name: string, value: string | boolean | number) => {
    const p = { ...this.state.listing.product } as ComProduct;
    p[name as keyof ComProduct] = value as never;
    this.setState({ listing: { ...this.state.listing, product: p } });
  };

  ValidateSubmit = (): boolean => {
    if (!this.ValidateVariations()) {
      this.setState({ submitEnabled: false, subtmitErrorMessage: 'Invalid variation attribute' });
      return false;
    }

    let spValid = true;
    let productInChannelB = true;
    if (!this.state.productInChannel || this.state.editProductInChannel) {
      const channel = this.props.selectedChannel;
      if (!this.state.editing && channel.channelId == ePlatform.Amazon && !this.state.eanValid) {
        this.setState((prvState) => {
          return { ...prvState, submitEnabled: false, subtmitErrorMessage: 'Invalid specific' };
        });
        return false;
      }

      productInChannelB = false;
      const listing = this.state.listing;

      if (listing.channelSpecificFields)
        listing.channelSpecificFields.forEach((field) => {
          let b = true;
          if (!field.hideFormula) {
            //No formula
            b = FieldValidation(field, undefined) === undefined;
          } else {
            if (ShouldBeFieldHidden(GetFormula(field.hideFormula), this.state.listing.channelSpecificFields ?? [])) {
              b = true;
            } else {
              b = FieldValidation(field, undefined) === undefined;
            }
          }
          spValid = spValid && b;
        });

      spValid =
        spValid &&
        (listing.markup ?? 0) >= 0 &&
        (listing.channelId == ePlatform.Shopify ||
          listing.channelId == ePlatform.WooCommerce ||
          (listing.categoryId !== null && listing.categoryId !== undefined));
    }

    if (productInChannelB) {
      if (!this.state.submitEnabled)
        this.setState((prvState) => {
          return { ...prvState, submitEnabled: true, subtmitErrorMessage: undefined };
        });
      return true;
    }

    const newVal = !this.state.loadingSpecifics && !this.state.loadingCategories && spValid && this.titleLengthValidation() === 'success';
    if (newVal !== this.state.submitEnabled) {
      this.setState((prvState) => {
        return { ...prvState, submitEnabled: newVal, subtmitErrorMessage: undefined };
      });
    }

    return newVal;
  };

  ValidateVariations = (): boolean => {
    const vs = this.state.listing.variations;
    if (vs?.variations == null || vs.variations.length == 0) return true;

    //Empty variations/attributes
    for (const vv of vs.variations) {
      for (const ao of vv.attributeOptions) {
        if (!ao.attribute || !ao.option) return false;
      }
    }

    //Two or more attributes with the same name --> false
    const already = new Set<string>();
    for (const ao of vs.variations[0].attributeOptions) {
      if (already.has(ao.attribute)) return false;
      already.add(ao.attribute);
    }
    return true;
  };
  //#endregion

  //#region RenderSettings
  RenderSettings = () => {
    const variationsOn = this.HasVariations();
    const isMainProduct = this.IsMainProductSelected();
    let variationInfo: VariationListingInfo | undefined = undefined;
    if (variationsOn) {
      variationInfo = this.state.listing.variations?.variations?.[this.state.selectedVariation];
    }

    const listing = this.state.listing;
    const settings = listing.settings;
    let channelSpecificSettingsFields;
    if (settings) {
      let spmonitorStock: boolean,
        spmonitorPrice: boolean,
        spmonitorPriceDecrease: boolean,
        spmonitorPriceDecreasePercentage: number,
        spignoreRules: boolean,
        spminQuantity: number,
        spcustomCategory: number,
        spnotes: string,
        spCity: string,
        spCountry: string,
        spPostcode: string;
      if (isMainProduct) {
        spmonitorStock = listing.monitorStock ?? false;
        spmonitorPrice = listing.monitorPrice ?? false;
        spmonitorPriceDecrease = listing.monitorPriceDecrease ?? false;
        spmonitorPriceDecreasePercentage = listing.monitorPriceDecreasePercentage ?? 0;
        spignoreRules = listing.ignoreRules;
        spminQuantity = listing.minQuantity ?? 0;
        spcustomCategory = listing.customCategory ?? 0;
        spnotes = listing.notes ?? '';
        spCity = listing.city;
        spCountry = listing.country;
        spPostcode = listing.postcode;
      } else {
        spmonitorStock = variationInfo?.settings?.monitorStock ?? false;
        spmonitorPrice = variationInfo?.settings?.monitorPrice ?? false;
        spmonitorPriceDecrease = variationInfo?.settings.monitorPriceDecrease ?? false;
        spmonitorPriceDecreasePercentage = variationInfo?.settings.monitorPriceDecreasePercentage ?? 0;
        spignoreRules = variationInfo?.settings.ignoreRules ?? false;
        spminQuantity = variationInfo?.settings.minQuantity ?? 0;
        spcustomCategory = variationInfo?.settings.customCategory ?? 0;
        spnotes = variationInfo?.settings.notes ?? '';
        spCity = variationInfo?.settings.locationCity ?? '';
        spCountry = variationInfo?.settings.locationCountry ?? '';
        spPostcode = variationInfo?.settings.locationPostcode ?? '';
      }

      const monitorStock = spmonitorStock ?? settings.monitorStock;
      const monitorPriceDec = spmonitorPriceDecrease ?? settings.monitorPriceDecrease;

      const channelSpecificSettings =
        (isMainProduct ? listing.channelSpecificFields?.filter((value) => value.setting > 0) : variationInfo?.settingSpecifics) ?? [];
      for (const field of channelSpecificSettings) {
        //let req = false;
        //if (field.validationRules && field.validationRules.required) req = true;

        channelSpecificSettingsFields = DrawField(field, this.onChangeSetting, channelSpecificSettings, false);
      }

      return (
        <div className="render-settings-container">
          {variationsOn && (
            <VariationsPanel
              variations={this.state.listing.variations}
              onVariationsChanged={this.OnVariationsChanged}
              onSelected={this.OnVariationSelected}
              selectedVariationIndex={this.state.selectedVariation}
              attributePanel={false}
              onClearVariations={this.OnClearVariations}
              onDeleteOneVariation={this.OnDeleteOneVariation}
              canClearVariations={!this.state.editing}
              mainVariationId={this.state.listing.listingId ?? 0}
            />
          )}
          <Row>
            <Col xs={6}>
              <label htmlFor="monitorStock">Don&apos;t list until</label>
            </Col>
            <Col xs={10}>
              <p className="input-help">If specified, it will not be listed immediately if not on the indicated date and time.</p>
            </Col>
            <Col xs={8}>
              <DatePicker
                showTime={true}
                allowClear={true}
                value={moment(this.state.dontListUntil)}
                onChange={(value) => this.setState({ dontListUntil: value?.toDate() ?? undefined })}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <label htmlFor="monitorStock">Monitor Stock</label>
            </Col>
            <Col xs={10}>
              <p className="input-help">
                If the supplier is out of stock of a product, we will prevent people from buying it on your store. When it is available
                again, we will automatically update your store again.
              </p>
            </Col>
            <Col xs={8}>{YesNoSelect('monitorStock', spmonitorStock, this.onChangeSetting)}</Col>
          </Row>
          {monitorStock && (
            <>
              <Row>
                <Col xs={6}>
                  <label htmlFor="monitorPrice">Monitor Price</label>
                </Col>
                <Col xs={10}>
                  <p className="input-help">
                    If the supplier changes the price of a product, we will automatically update accordingly to keep your profit with the
                    corresponding markup.
                  </p>
                </Col>
                <Col xs={8}>{YesNoSelect('monitorPrice', spmonitorPrice, this.onChangeSetting)}</Col>
              </Row>
              <Row style={{ borderBottom: 'none' }}>
                <Col xs={6}>
                  <label htmlFor="monitorPriceDecrease">Monitor Price Decrease</label>
                </Col>
                <Col xs={10}>
                  <p className="input-help">
                    If the supplier reduces the price of a product, we will also reduce it in your store. If you turn this off, we will only
                    update the price when it goes up in the supplier&apos;s catalog.
                  </p>
                </Col>
                <Col xs={8}>{YesNoSelect('monitorPriceDecrease', spmonitorPriceDecrease, this.onChangeSetting)}</Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <label htmlFor="monitorPriceDecreasePercentage"></label>
                </Col>
                <Col xs={10}>
                  {monitorStock && (
                    <p className="input-help">
                      No limit: If the price on the supplier goes down, your price will go down too.
                      <br />
                      <br />
                      Example: (limit 20%) Your price will be updated only if the price at the source is reduced less thank 20%. If the
                      price at the source lowers 21% or more, your price will not be updated.
                    </p>
                  )}
                </Col>
                <Col xs={8}>
                  {monitorPriceDec && (
                    <>
                      <div className="radio">
                        <label>
                          <input
                            type="radio"
                            name="monitorPriceDecreasePercentage"
                            defaultValue="0"
                            defaultChecked={!spmonitorPriceDecreasePercentage || spmonitorPriceDecreasePercentage <= 0}
                            onClick={(x) =>
                              this.onChangeField(
                                {
                                  keyName: 'monitorPriceDecreasePercentage',
                                  value: 0,
                                  type: x.currentTarget.type,
                                  specific: false
                                },
                                true
                              )
                            }
                          />
                          No limit
                        </label>
                      </div>
                      <div className="radio">
                        <label>
                          <input
                            type="radio"
                            name="monitorPriceDecreasePercentage"
                            defaultValue="30"
                            defaultChecked={spmonitorPriceDecreasePercentage > 0}
                            onClick={(x) =>
                              this.onChangeField(
                                {
                                  keyName: 'monitorPriceDecreasePercentage',
                                  value: spmonitorPriceDecreasePercentage ? spmonitorPriceDecreasePercentage : 30,
                                  type: x.currentTarget.type,
                                  specific: false
                                },
                                true
                              )
                            }
                          />
                          Limit %
                          <Input
                            type="number"
                            step="1"
                            min="0"
                            max="5000"
                            name="monitorPriceDecreasePercentage"
                            style={{ width: 90, display: 'inline', marginLeft: 10 }}
                            disabled={spmonitorPriceDecreasePercentage <= 0}
                            value={spmonitorPriceDecreasePercentage ? spmonitorPriceDecreasePercentage : 30}
                            onChange={(x) => this.onChange(x, true)}
                          />
                        </label>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col xs={6}>
              <label>Ignore pricing rules</label>
            </Col>
            <Col xs={10}></Col>
            <Col xs={8}>{YesNoSelect('ignoreRules', spignoreRules, this.onChangeSetting)}</Col>
          </Row>
          <Row>
            <Col xs={6}>
              <label>Min. Quantity when in Stock</label>
            </Col>
            <Col xs={10}>
              <p className="input-help">HGR will maintain this minimun quantity while the item is in stock.</p>
            </Col>
            <Col xs={8}>
              <Input
                type="number"
                step="1"
                min="1"
                max="5000"
                name="minQuantity"
                value={spminQuantity != null ? spminQuantity : settings.minQuantity}
                onChange={(x) => this.onChange(x, true)}
              />
            </Col>
          </Row>
          {IsEbay(this.props.selectedChannel.channelId) && (
            <>
              <Row>
                <Col xs={6}>
                  <label htmlFor="locationPostcode">Location Postcode</label>
                </Col>
                <Col xs={10}>
                  <p className="input-help">Postal code to use as the location of your items.</p>
                </Col>
                <Col xs={8}>
                  <Input
                    type="text"
                    name="postcode"
                    value={spPostcode != null ? spPostcode : settings.locationPostcode}
                    onChange={(x) => this.onChange(x, true)}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <label htmlFor="locationCity">Location City</label>
                </Col>
                <Col xs={10}>
                  <p className="input-help">
                    City to use as the location of your items..<strong>Required</strong>.
                  </p>
                </Col>
                <Col xs={8}>
                  <Input
                    type="text"
                    name="city"
                    value={spCity != null ? spCity : settings.locationCity}
                    onChange={(x) => this.onChange(x, true)}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <label>Overwrite Item Country Code</label>
                </Col>
                <Col xs={10}></Col>
                <Col xs={8}>
                  <CountryCodeSelector
                    defaultValue={spCountry != null ? spCountry : settings.locationCountry}
                    onChange={(value) => this.onChangeA('country', 'select', value, true)}
                    name="country"
                    className="react-select"
                  />
                </Col>
              </Row>
            </>
          )}
          {this.props.selectedChannel.channelId == ePlatform.Shopify && (
            <>
              <Row>
                <Col xs={6}>
                  <label>Weight (Kg)</label>
                </Col>
                <Col xs={10}></Col>
                <Col xs={8}>
                  <Input type="number" step="0.1" min="1" max="5000" name="weight" onChange={(x) => this.onChange(x, true)} />
                </Col>
              </Row>
            </>
          )}
          {listing.customCategories && listing.customCategories?.length > 0 && (
            <Row>
              <Col xs={6}>
                <label>Store Category</label>
              </Col>
              <Col xs={10}></Col>
              <Col xs={8}>
                <select
                  value={spcustomCategory}
                  name="customCategory"
                  className="form-control"
                  onChange={(a) =>
                    this.onChangeSetting({
                      keyName: a.target.name,
                      type: 'select',
                      value: parseInt(a.target.value),
                      specific: false
                    })
                  }
                >
                  <option />
                  {listing.customCategories.map((c) => (
                    <option key={c.id} value={c.id}>
                      {c.name}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
          )}
          {channelSpecificSettingsFields && (
            <>
              <Row>{channelSpecificSettingsFields}</Row>
            </>
          )}
          <Row>
            <Col sm={24}>
              <label>Notes</label>
              <textarea
                value={spnotes}
                name="notes"
                className="form-control"
                style={{ width: '100%', minHeight: 100 }}
                onChange={(a) =>
                  this.onChangeSetting({
                    keyName: a.target.name,
                    value: a.target.value,
                    type: 'textarea',
                    specific: false
                  })
                }
              />
            </Col>
          </Row>
          <div>
            <p
              style={{
                color: '#808488',
                fontSize: '.75em',
                margin: 0,
                textAlign: 'left',
                fontWeight: 300,
                lineHeight: 1.8,
                float: 'left'
              }}
            >
              {isMainProduct ? (listing.product?.url ?? '') + ' (' + (listing.product?.sourceProductId ?? '') + ')' : ''}
            </p>
          </div>
          <hr />
        </div>
      );
    } else {
      return <Spin />;
    }
  };
  //#endregion

  async GetEAN(title: string) {
    try {
      if (await IsSendMessageToBackgroundDefined()) {
        return null;
      }

      return (await new Promise((result) => {
        try {
          let c = false;
          window.setTimeout(() => {
            c = true;
            result(null);
          }, 10 * 1000); //max 10s

          if (!this.state.editing)
            (async () => {
              const r = await SendMessageToExtension('GET_EAN', title);
              if (!c) {
                result(r?.data as string | null);
              } else {
                result(null);
              }
            })();
        } catch {
          return null;
        }
      })) as string | null;
    } catch (ex) {
      console.error(ex);
    }

    return null;
  }

  timeoutEanUpdated = 0;
  InformEanAsinChanged(channelId: ePlatform, site: eCountry, newValue: string, type: EanType) {
    clearInterval(this.timeoutEanUpdated);
    this.timeoutEanUpdated = window.setTimeout(async () => {
      switch (type) {
        case EanType.EAN:
          return await this.InformEanChanged(channelId, site, newValue);
        case EanType.ASIN:
          return await this.InformAsinChanged(channelId, site, newValue);
      }
    }, 1000);
  }
  async InformEanChanged(channelId: ePlatform, site: eCountry, newValue: string) {
    if (!newValue || newValue == '' || !IsValidEan(newValue)) {
      this.setState({ productInChannel: null });
      this.GetProductByText(channelId, site, this.state.listing.title);
      return;
    }

    if (!(await IsSendMessageToBackgroundDefined())) {
      return;
    }
    if (this.state.editing) return;

    const resp = await SendMessageToExtension('SEARCH_BY_EAN_IN_CHANNEL', {
      ean: newValue,
      site: eCountry[site],
      channelId: channelId
    });
    this.setState((prv: State) => {
      let prC = { ...prv.productInChannel } as ProductInChannelData | null;
      if (resp?.data) prC ? (prC.selectedBy = EanType.EAN) : ({ selectedBy: EanType.EAN } as ProductInChannelData);
      else prC = null;
      return { ...prv, productInChannel: prC };
    });
    if (!resp?.data) {
      this.GetProductByText(channelId, site, this.state.listing.title);
    }
    window.setTimeout(this.ValidateSubmit, 200);
  }

  async InformAsinChanged(channelId: ePlatform, site: eCountry, newValue: string) {
    if (!(await IsSendMessageToBackgroundDefined())) {
      return;
    }

    if (!newValue || newValue == '') {
      this.setState((prv) => {
        return { ...prv, productInChannel: null };
      });
      return;
    }

    if (this.state.possibleProductsInChannel) {
      for (const prd of this.state.possibleProductsInChannel) {
        if (prd.id == newValue) {
          this.setState((prv: State) => {
            return {
              ...prv,
              productInChannel: { ...prv.productInChannel, selectedBy: EanType.ASIN }
            } as State;
          });
        }
        return;
      }
    }
    if (this.state.editing) return;

    const resp = await SendMessageToExtension('SEARCH_BY_ID_IN_CHANNEL', {
      id: newValue,
      site: eCountry[site],
      channelId: channelId
    });
    this.setState((prv: State) => {
      let prC;
      if (resp?.data) prC = { ...prv.productInChannel, selectedBy: EanType.ASIN };
      else prC = null;
      return { ...prv, productInChannel: prC } as State;
    });

    window.setTimeout(this.ValidateSubmit, 200);
  }

  timeoutTitleUpdated = 0;
  GetProductByText = async (channelId: ePlatform, site: eCountry, text: string) => {
    if (this.state.editing) return;
    clearInterval(this.timeoutTitleUpdated);
    this.timeoutTitleUpdated = window.setTimeout(async () => {
      if (!(await IsSendMessageToBackgroundDefined())) {
        return;
      }
      if (this.state.editing) return;

      const resp = await SendMessageToExtension('SEARCH_BY_TEXT_IN_CHANNEL', {
        text: text,
        site: eCountry[site],
        channelId: channelId
      });

      if (resp) {
        this.setState((prv: State) => {
          return {
            ...prv,
            possibleProductsInChannel: resp?.data as ProductInChannelData | null
          } as State;
        });
      }

      window.setTimeout(this.ValidateSubmit, 200);
    }, 1000);
  };

  InitializeVariations = (vars: VariationListingInfo[]) => {
    const allAttributesSet = new Set<string>();
    for (const v of vars) {
      for (const oa of v.attributeOptions) {
        allAttributesSet.add(oa.attribute);
      }
    }
    const list = Array.from(allAttributesSet);

    //for (const v of vars) {
    for (let i = 0; i < vars.length; i++) {
      const v = { ...vars[i] };
      vars[i] = v;
      v.attributeOptions = [...(v?.attributeOptions ?? [])];
      const thisVAttrs = new Set<string>();
      for (const ao of v.attributeOptions) {
        thisVAttrs.add(ao.attribute);
      }
      for (const la of list) {
        if (!thisVAttrs.has(la)) {
          v.attributeOptions.push({ attribute: la, option: '' });
        }
      }
      v.attributeOptions.sort((a, b) => a.attribute.localeCompare(b.attribute));
    }
  };

  OnVariationsChanged = (newData: ListingVariationsResponse) => {
    const ls: ComListing = { ...this.state.listing, variations: newData };
    this.setState({ listing: ls });
  };

  OnClearVariations = () => {
    if (
      (this.state.listing.variations?.variations?.length ?? 0) <= 1 ||
      window.confirm("Do you want to remove all the variations? This can't be reverted")
    ) {
      this.setState({
        listing: { ...this.state.listing, variations: null }
      });
      ClearVariationsPlain();
    }
  };

  OnDeleteOneVariation = (info: VariationListingInfo) => {
    if (!this.state.listing.variations) return;
    const vars = [...this.state.listing.variations.variations];
    let newSelectedVariation = this.state.selectedVariation;
    for (let i = 0; i < vars.length; i++) {
      const v = vars[i];
      if (v.listingId == info.listingId) {
        vars.splice(i, 1);
        if (newSelectedVariation >= i) {
          newSelectedVariation--;
        }
        break;
      }
    }
    const vM = ReCalculateUrls({ ...this.state.listing.variations, variations: vars });
    this.setState({
      listing: {
        ...this.state.listing,
        variations: vM
      },
      selectedVariation: newSelectedVariation
    });
    ClearOneVariationPlain(info.listingId);
  };

  OnOptimiseClick = () => {
    this.setState({ showOptimiseModal: true });
  };

  ExecuteOptimiseTitle = async () => {
    this.setState({ showOptimiseModal: false });
    if (this.state.optimising || !this.state.listing.productSourceId) return;
    this.setState({ optimising: true });

    const optimised = await OptimiseTitlePlain({
      listingId: this.state.listing.listingId,
      productSourceId: this.state.listing.productSourceId
    });

    this.setState({
      showOptimiseModal: false,
      listing: { ...this.state.listing, title: optimised.title, optimisedTitle: optimised.title },
      optimising: false
    });
  };
}
