import { createAsyncThunk } from '@reduxjs/toolkit';
import { Channel } from '../channels/channelsSlice';
import { client } from '../client';

export type NotificationList = {
  noApiServerStatusProblems?: boolean;
  noApiDisconnected?: Channel[];
  disabled?: Channel[];
  customMessages?: string[];
  pendingPaymentProblems?: boolean;
  notActivated?: boolean;
  userCreated?: string;
}

export const getNotifications = createAsyncThunk('notifications/getNotifications', async (_, thunkAPI) => {
  try {
    const res = await client.get('/Notifications/Get');
    return res.data.response_data as NotificationList;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
