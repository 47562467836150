import { createAsyncThunk } from '@reduxjs/toolkit';
import { StoredCheckout } from './data/stored-ids';

//export const LoadCart = createAsyncThunk('UI/GetCart', async (_) => {
//  //try {
//  //  return JSON.parse(localStorage.getItem('Checkout.Cart') ?? 'x') as StoredCheckout;
//  //} catch (error) {
//  //  return undefined;
//  //}
//});

//eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SaveCart = createAsyncThunk('UI/SaveCart', async (data: StoredCheckout, rejectWithValue) => {
  //try {
  //  localStorage.setItem('Checkout.Cart', JSON.stringify(data));
  //} catch (error) {
  //  return rejectWithValue.rejectWithValue('Sorry! Something went wrong');
  //}
});