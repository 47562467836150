import { Radio, Spin } from 'antd';
import { loadStripe } from '@stripe/stripe-js';
import { OrderSummary } from './OrderSummary';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { GetPaymentConfig } from 'src/redux/subscriptions/subsThunk';
import { PrimaryBtn } from '../../small-components/ActionBtns';
import { ArrowRightOutlined } from '@ant-design/icons';
import { CreateCheckoutSession } from 'src/redux/payment/paymentThunk';
import { toastAlert } from '../../utils/toastAlert';
import '../../sass/subscriptions/checkout.scss';
import { CreateCheckoutSessionRequest } from '../subscriptions/models/types';
import { Links } from '../../links';
import { eNewChannelStep } from '../chanel/new-channel/step';
import { ReactUtils } from '../../utils/react-utils';
import { T } from '../../utils/transShim';
import { eShop } from '../chanel/new-channel/eShop';
import { NumberOrNull } from '../../utils/utils';

export const PaymentsMethodServices = () => {
  const dispatch = useAppDispatch();
  const selectedChannel = ReactUtils.GetSelectedChannel();

  const [productId] = useState(localStorage.getItem('productId'));
  const [billingId] = useState(localStorage.getItem('billing'));
  const [currencyId] = useState(localStorage.getItem('currencyId'));

  const [platformProductId] = useState(localStorage.getItem('platformProductId'));
  const [stripePlatformProductId] = useState(localStorage.getItem('stripePlatformProductId'));
  const [loadings, setLoadings] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState<number>(2);
  const { loading, paymentConfiguration } = useAppSelector((state) => state.paymentMethodConfiguration);

  useEffect(() => {
    dispatch(GetPaymentConfig());
  }, [GetPaymentConfig]);

  const GetSuccesUrl = () => {
    const location = window.location;
    const baseUrl = location.protocol + '//' + location.host;

    let successUrl = paymentConfiguration?.successUrl ?? baseUrl + Links.Payment.Success;
    const sh = new URLSearchParams(location.search).get('sh');
    if (sh) {
      successUrl = baseUrl + Links.NewChannel.Produce(eNewChannelStep.Country, NumberOrNull(sh) ?? undefined as eShop |undefined);
    }

    const url = new URL(successUrl);
    url.searchParams.append('bp', billingId ?? '');
    url.searchParams.append('pid', productId ?? '');
    url.searchParams.append('returnUrl', url.toString());
    return url.toString();
  };

  const GetCancelUrl = () => {
    const l = window.location;
    const baseUrl = l.protocol + '//' + l.host;
    const url = new URL(paymentConfiguration?.cancelUrl ?? baseUrl + Links.Payment.Error);
    url.searchParams.append('bp', billingId ?? '');
    url.searchParams.append('pid', productId ?? '');
    url.searchParams.append('returnUrl', url.toString());
    return url.toString();
  };

  const setValue = (arg0: number) => {
    setSelectedMethod(arg0);
  };

  const HandleStripe = async () => {
    if (!paymentConfiguration) return;
    setLoadings(true);
    const request: CreateCheckoutSessionRequest = {
      lineItems: [{ platformProductId: stripePlatformProductId ?? '', quantity: 1 }],
      mode: (billingId as unknown as number) < 3 ? 'subscription' : 'payment',
      successUrl: GetSuccesUrl(),
      cancelUrl: GetCancelUrl(),
      upgradingSubscription: paymentConfiguration.upgrade as unknown as boolean
    };

    const rp = await dispatch(CreateCheckoutSession(request));
    const stripe = await loadStripe(paymentConfiguration.stripeConfig.publishableKey);
    await stripe?.redirectToCheckout({ sessionId: rp.payload.responseObject.checkoutSessionId });
  };

  const HandlePayPal = () => {
    if (!paymentConfiguration) return;
    setLoadings(true);
    window.location.href =
      'https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=' +
      platformProductId +
      '&custom=' +
      paymentConfiguration.payPalConfig.userId +
      ',' +
      (selectedChannel?.id ?? '');
  };

  const finishPayment = () => {
    if (selectedMethod === 1) {
      HandlePayPal();
    } else if (selectedMethod === 2) {
      HandleStripe();
    } else {
      toastAlert('Please select a payment method', 'error');
    }
  };

  return loading || loadings ? (
    <Spin />
  ) : (
    <div className="general-checkout-section">
      <div className="general-checkout-section-wrap">
        <div className="general-checkout-section-inner">
          <div className="title-container">
            <h2>Checkout</h2>
            <h3>Payment method</h3>
          </div>
          <div className="checkout-content">
            <div className="checkout-sections-container">
              <div className="section-container">
                <Radio.Group name="paymentMethod" defaultValue={selectedMethod.toString()}>
                  <div className="card-section-options">
                    <h3><T k='Checkout.SelectMethod'/></h3>
                    <p><T k='Checkout.PaypalOnlyEur'/></p>
                    <div className="cards-payments">
                      <Radio className="card-payment-section" value="2" name="paymentMethod" onClick={() => setValue(2)}>
                        <h3><T k='Checkout.CreditCard'/></h3>
                      </Radio>
                      <Radio className="card-payment-section" value="1" name="paymentMethod" onClick={() => setValue(1)}>
                        <h3>Paypal</h3>
                      </Radio>
                    </div>
                  </div>
                </Radio.Group>
                {/*Future functionality*/}
                {/*
                <div className="section-payment">
                  <h3 className="title-card">Billing details</h3>
                  <div className="section-container">
                    <div className="billing-details">
                      <div className="address-details">
                        <h3>Address</h3>
                        <h4>John McGregor</h4>
                        <h4>7 Queensway</h4>
                        <h4>WC17 8BQ, London</h4>
                        <h4>United Kingdom</h4>
                      </div>
                      <div className="address-details">
                        <h3>VAT Number</h3>
                        <h4>466345544</h4>
                      </div>
                    </div>
                  </div>
                </div>
                */}
              </div>
              <div className="section-container">
                <OrderSummary productId={productId} billingId={billingId} currencyId={currencyId} />
                <div className="button-container">
                  <PrimaryBtn htmlType="submit" onClick={finishPayment}>
                    <T k="Checkout.FinishPayment" /> <ArrowRightOutlined />
                  </PrimaryBtn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
