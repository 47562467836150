import { CloseOutlined } from '@ant-design/icons';
import { MouseEventHandler } from 'react';

interface Props {
  className?: string;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  id?: string;
}
export const CloseIcon = ({ className, onClick, id }: Props) => (
  <CloseOutlined className={className} onClick={onClick} id={id} style={{fontSize: '19px'}}/>
);
