import { ePlatform } from '../../../data/platforms';
import { ProductInChannelData } from './product-in-channel-data';
import { ProductList } from './product-list';
import { SelectedProductPanel } from './selected-product-panel';

export interface ProductInChannelProps {
  product?: ProductInChannelData;
  possibles?: ProductInChannelData[];
  editProductInChannel: boolean;
  channelId: ePlatform;

  OnEditProductInChannelChange?: (editing: boolean) => void;
  OnSelectProduct?: (product: ProductInChannelData) => void;
}
export const ProductPanelInChannel = (props: ProductInChannelProps) => {
  const { product: prdInCh, possibles } = props;

  return (
    <div className="productPanelInChannel">
      {possibles && possibles.length > 0 && (
        <ProductList
          possibles={props.possibles}
          channelId={props.channelId}
          onSelected={props.OnSelectProduct}
        />
      )}
      {prdInCh && (
        <SelectedProductPanel
          editProductInChannel={props.editProductInChannel}
          OnEditProductInChannelChange={props.OnEditProductInChannelChange}
          product={props.product}
        />
      )}
    </div>
  );
};