import { OrderData } from '../../../redux/orders/orderSlice';
import { AdvancedFilterValueType } from '../../../small-components/tables/types/columns';
import { AutoOrderingState } from '../data/auto-ordering-state';
import { OrderStatus } from '../data/order-status';

export const AOStatusFilter = (fieldValue: unknown, filter: AdvancedFilterValueType, row: OrderData) => {
  if (filter == null)
    return true;

  if (!Array.isArray(filter))
    return true;

  if (filter.length == 0)
    return true;

  const value = fieldValue as number;

  for (const n of filter) {
    switch (n) {
      case 1://Error
        switch (value) {
          case AutoOrderingState.GoingToBuyError:
          case AutoOrderingState.PermanentError:
            return true;
        }
        break;
      case 2://Procesing
        if (
          (value > AutoOrderingState.AutoorderingDisabled && value < AutoOrderingState.CompletedAutoOrder)
          || value == AutoOrderingState.TemporaryError
        )
          return true;
        break;
      case 3://Purchased
        if (value >= AutoOrderingState.CompletedAutoOrder && value <= AutoOrderingState.Completed && value != AutoOrderingState.CompletedAutoOrderPending && !row.markedAsDispatched)
          return true;
        break;
      case 8://Dispatched
        if (value >= AutoOrderingState.CompletedAutoOrder && value <= AutoOrderingState.Completed && value != AutoOrderingState.CompletedAutoOrderPending && row.markedAsDispatched)
          return true;
        break;
      case 7://Pending
        if (value == AutoOrderingState.CompletedAutoOrderPending)
          return true;
        break;
      case 4://ManuallyDispatched
        if (value == AutoOrderingState.ManuallyDispatched)
          return true;
        if (!value || value == AutoOrderingState.AutoorderingDisabled)
          switch (row.storeStatus) {
            case OrderStatus.Shipped:
              return true;
          }
        break;
      case 5://Paused
        if (!value || value == AutoOrderingState.AutoorderingDisabled)
          switch (row.storeStatus) {
            case OrderStatus.Cancelled:
            case OrderStatus.Shipped:
              break;
            default:
              return true;
          }
        break;
      case 6://Cancelled
        if ((!value || value == AutoOrderingState.AutoorderingDisabled))
          switch (row.storeStatus) {
            case OrderStatus.Cancelled:
              return true;
          }
        break;
    }
  }

  return false;

  /*
   switch (statusValue) {
    case AutoOrderingState.AutoorderingDisabled:
      switch (order.storeStatus) {
        default:
          if (order.sourceAOEnabled) {
            if (order.sourceAOConfigured) {
              return (
                <WarningBtn>
                  <PauseCircleOutlined />&nbsp;
                  <T k='Orders.Status.Paused'/>
                </WarningBtn>
              );
            } else {
              return (
                <WarningBtn>
                  <PauseCircleOutlined />&nbsp;
                  <T k='Orders.Status.AODisabled' />
                </WarningBtn>
              );
            }
          } else {
            return (
              <WarningBtn>
                <ExclamationCircleOutlined />&nbsp;
                <T k='Orders.Status.NoAutoordering' />
              </WarningBtn>
            );
          }
        case OrderStatus.Shipped:
          return (
            <SuccessBtn>
              <CheckCircleOutlined />&nbsp;
              <T k='Orders.Status.Dispatched'/>
            </SuccessBtn>
          );

        case OrderStatus.Cancelled:
          return (
            <CancelBtn>
              <ExclamationCircleOutlined />&nbsp;
              <T k='Orders.Status.Cancelled' />
            </CancelBtn>
          );
      }
    default: //Including case OrderStatus.TemporaryError
      return (
        <ProgressBtn>
          <LoadingOutlined />&nbsp;
          <T k='Orders.Status.InProgress' />
        </ProgressBtn>
      );
    case AutoOrderingState.CompletedAutoOrderPending:
      return (
        <WarningBtn>
          <CheckCircleOutlined />&nbsp;
          <T k='Orders.Status.Pending' />
        </WarningBtn>
      );
    case AutoOrderingState.Completed:
      if (order.markedAsDispatched) {
        return (
          <SuccessBtn>
            <CheckCircleOutlined />&nbsp;
            <T k='Orders.Status.Dispatched' />
          </SuccessBtn>
        );
      } else {
        return (
          <SuccessBtn>
            <CheckCircleOutlined />&nbsp;
            <T k='Orders.Status.Purchased' />
          </SuccessBtn>
        );
      }
    case AutoOrderingState.ManuallyDispatched:
      return (
        <SuccessBtn>
          <CheckCircleOutlined />&nbsp;
          <T k='Orders.Status.Manually' />
        </SuccessBtn>
      );
    case AutoOrderingState.GoingToBuyError:
    case AutoOrderingState.PermanentError:
      return (
        <DangerBtn>
          <ExclamationCircleOutlined />&nbsp;
          <T k='Orders.Status.ViewError' />
        </DangerBtn>
      );
  }
  */

};