import { CheckoutSubscription } from './checkout-subscription';
import { AddServicesCheckout } from './add-services-checkout';
import { PaymentMethodSubscriptions } from './payment-method';
import '../../../sass/subscriptions/checkout.scss';
import { T } from '../../../utils/transShim';
import { useHistory, useParams } from 'react-router';
import { Links } from '../../../links';

import { getSubscriptions } from '../../../redux/subscriptions/subsThunk';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../custom-hooks/reduxCustomHooks';
import { CheckoutSection } from './data/checkout-section';
import { SaveCart } from '../../../redux/checkout/checkoutThunk';
import { Cart } from './data/cart';
import { StoredCheckout } from '../../../redux/checkout/data/stored-ids';
import { eCurrency } from '../../../types/eCurrency';
import { Spin } from 'antd';
import { BillingPeriod, PaymentPlatform } from '../models/types';
import { Product } from '../../../redux/subscriptions/subsSlice';
import { ExecutingPayment } from './executing-payment';
import { A } from '../../../small-components/A';
import { QuoteSlider } from '../../user/quote-slider';
import { GetQuotes } from '../../user/quotes-list';

export const Checkout = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  //DATA FROM URL---------------------------------------------------------
  const { step } = useParams<{ step: string | undefined }>();
  const activeRouteId = (() => {
    switch (step) {
      case 'add-services': return CheckoutSection.EXTRA_SERVICES;
      case 'payment-method': return CheckoutSection.PAYMENT_METHOD;
      case 'execute-payment': return CheckoutSection.EXECUTE_PAYMENT;
    }
    return CheckoutSection.SELECT_PRODUCT;
  })();
  //---------------------------------------------------------------------
  //PRODUCT LIST---------------------------------------------------------
  const { products, loading } = (() => {
    const { products, loading } = useAppSelector((state) => state.subscriptions);
    //Extra services
    const extraServices: Product[] = [
      {
        id: 20,
        name: 'No API Server',
        type: 2,
        productOrder: 20,
        prices: [
          {
            id: 398,
            platformId: PaymentPlatform.Stripe,
            productId: 20,
            platformProductId: 'price_1KWeVUBVFd6MOE2Rw6LeXNuS',
            currencyId: eCurrency.EUR,
            billingPeriodId: BillingPeriod.Monthly,
            price: 15.99,
          },
          {
            id: 416,
            platformId: PaymentPlatform.Stripe,
            productId: 20,
            platformProductId: 'price_1N8J2dBVFd6MOE2R3mK7QNAg',
            currencyId: eCurrency.EUR,
            billingPeriodId: BillingPeriod.Biannually,
            price: 85.99,
          },
          {
            id: 399,
            platformId: PaymentPlatform.Stripe,
            productId: 20,
            platformProductId: 'price_1KWeWHBVFd6MOE2RxaaYZq6w',
            currencyId: eCurrency.EUR,
            billingPeriodId: BillingPeriod.Yearly,
            price: 152.99,
          },

          {
            id: 400,
            platformId: PaymentPlatform.Stripe,
            productId: 20,
            platformProductId: 'price_1KWeTwBVFd6MOE2RaYvZNBP5',
            currencyId: eCurrency.USD,
            billingPeriodId: BillingPeriod.Monthly,
            price: 17.99,
          },
          {
            id: 418,
            platformId: PaymentPlatform.Stripe,
            productId: 20,
            platformProductId: 'price_1N8J4OBVFd6MOE2Ro73UKYWs',
            currencyId: eCurrency.USD,
            billingPeriodId: BillingPeriod.Biannually,
            price: 96.99,
          },
          {
            id: 401,
            platformId: PaymentPlatform.Stripe,
            productId: 20,
            platformProductId: 'price_1KWeV3BVFd6MOE2R7Z34P0lx',
            currencyId: eCurrency.USD,
            billingPeriodId: BillingPeriod.Yearly,
            price: 172.99,
          },

          {
            id: 402,
            platformId: PaymentPlatform.Stripe,
            productId: 20,
            platformProductId: 'price_1KWe4CBVFd6MOE2RrH4kNqLf',
            currencyId: eCurrency.GBP,
            billingPeriodId: BillingPeriod.Monthly,
            price: 12.99,
          },
          {
            id: 417,
            platformId: PaymentPlatform.Stripe,
            productId: 20,
            platformProductId: 'price_1N8J3eBVFd6MOE2RQwqzGKDH',
            currencyId: eCurrency.GBP,
            billingPeriodId: BillingPeriod.Biannually,
            price: 69.99,
          },
          {
            id: 403,
            platformId: PaymentPlatform.Stripe,
            productId: 20,
            platformProductId: 'price_1KWe9eBVFd6MOE2RMTORZiee',
            currencyId: eCurrency.GBP,
            billingPeriodId: BillingPeriod.Yearly,
            price: 124.99,
          }
        ],
      },
      {
        id: 21,
        name: '30 Catalog tokens',
        type: 3,
        productOrder: 30,
        prices: [
          {
            id: 319,
            platformId: PaymentPlatform.Stripe,
            productId: 21,
            platformProductId: 'price_1N9SEKBVFd6MOE2RI7vBc2ex',
            currencyId: eCurrency.GBP,
            billingPeriodId: BillingPeriod.Unique,
            price: 9.99
          },
          {
            id: 320,
            platformId: PaymentPlatform.Stripe,
            productId: 21,
            platformProductId: 'price_1N9SE2BVFd6MOE2Rqoz73F4F',
            currencyId: eCurrency.EUR,
            billingPeriodId: BillingPeriod.Unique,
            price: 11.99
          }
        ],
      },
      //{
      //  id: 25,
      //  name: 'We list for you - 10 Products',
      //  type: 5,
      //  productOrder: 10,
      //  prices: [
      //    {
      //      id: 325,
      //      platformId: PaymentPlatform.Stripe,
      //      productId: 25,
      //      platformProductId: 'sku_FUfZntIa83mdpu',
      //      currencyId: eCurrency.GBP,
      //      billingPeriodId: BillingPeriod.Unique,
      //      price: 9.99
      //    },
      //    {
      //      id: 345,
      //      platformId: PaymentPlatform.Stripe,
      //      productId: 25,
      //      platformProductId: 'sku_I9srzouNTAYExZ',
      //      currencyId: eCurrency.EUR,
      //      billingPeriodId: BillingPeriod.Unique,
      //      price: 12.99
      //    }
      //  ]
      //},
      //{
      //  id: 32,
      //  name: 'We list for you - 50 Products',
      //  type: 5,
      //  productOrder: 50,
      //  prices: [
      //    {
      //      id: 326,
      //      platformId: PaymentPlatform.Stripe,
      //      productId: 32,
      //      platformProductId: 'sku_I3zQOAuLlo2MvI',
      //      currencyId: eCurrency.GBP,
      //      billingPeriodId: BillingPeriod.Unique,
      //      price: 15.99
      //    },
      //    {
      //      id: 346,
      //      platformId: PaymentPlatform.Stripe,
      //      productId: 32,
      //      platformProductId: 'sku_I9srFD6CJKSiJa',
      //      currencyId: eCurrency.EUR,
      //      billingPeriodId: BillingPeriod.Unique,
      //      price: 19.99
      //    }
      //  ]
      //},
      //{
      //  id: 33,
      //  name: 'We list for you - 100 Products',
      //  type: 5,
      //  productOrder: 100,
      //  prices: [
      //    {
      //      id: 329,
      //      platformId: PaymentPlatform.Stripe,
      //      productId: 33,
      //      platformProductId: 'sku_I3zQgWRHEw5JmS',
      //      currencyId: eCurrency.GBP,
      //      billingPeriodId: BillingPeriod.Unique,
      //      price: 29.99
      //    },
      //    {
      //      id: 347,
      //      platformId: PaymentPlatform.Stripe,
      //      productId: 33,
      //      platformProductId: 'sku_I9sscFJUDNsmoE',
      //      currencyId: eCurrency.EUR,
      //      billingPeriodId: BillingPeriod.Unique,
      //      price: 33.99
      //    }
      //  ]
      //},
      //{
      //  id: 34,
      //  name: 'We list for you - 200 Products',
      //  type: 5,
      //  productOrder: 200,
      //  prices: [
      //    {
      //      id: 330,
      //      platformId: PaymentPlatform.Stripe,
      //      productId: 34,
      //      platformProductId: 'sku_I3zQGI0tKTdVU4',
      //      currencyId: eCurrency.GBP,
      //      billingPeriodId: BillingPeriod.Unique,
      //      price: 59.99
      //    },
      //    {
      //      id: 348,
      //      platformId: PaymentPlatform.Stripe,
      //      productId: 34,
      //      platformProductId: 'sku_I9sslPKt3EGIsm',
      //      currencyId: eCurrency.EUR,
      //      billingPeriodId: BillingPeriod.Unique,
      //      price: 66.99
      //    }
      //  ]
      //},
      //{
      //  id: 36,
      //  name: 'We list for you - 300 Products',
      //  type: 5,
      //  productOrder: 300,
      //  prices: [
      //    {
      //      id: 331,
      //      platformId: PaymentPlatform.Stripe,
      //      productId: 36,
      //      platformProductId: 'sku_I3zRmG2UYqG6iD',
      //      currencyId: eCurrency.GBP,
      //      billingPeriodId: BillingPeriod.Unique,
      //      price: 89.99
      //    },
      //    {
      //      id: 349,
      //      platformId: PaymentPlatform.Stripe,
      //      productId: 36,
      //      platformProductId: 'sku_I9ssiwY5Hwk9Fn',
      //      currencyId: eCurrency.EUR,
      //      billingPeriodId: BillingPeriod.Unique,
      //      price: 99.99
      //    }
      //  ]
      //},
      //{
      //  id: 39,
      //  name: 'We list for you - 500 Products',
      //  type: 5,
      //  productOrder: 500,
      //  prices: [
      //    {
      //      id: 361,
      //      platformId: PaymentPlatform.Stripe,
      //      productId: 39,
      //      platformProductId: 'sku_IT5FK38QtBU8gT',
      //      currencyId: eCurrency.GBP,
      //      billingPeriodId: BillingPeriod.Unique,
      //      price: 149.99
      //    },
      //    {
      //      id: 363,
      //      platformId: PaymentPlatform.Stripe,
      //      productId: 39,
      //      platformProductId: 'sku_IT5QhRxTQJLynb',
      //      currencyId: eCurrency.EUR,
      //      billingPeriodId: BillingPeriod.Unique,
      //      price: 167.99
      //    }
      //  ]
      //},
      //{
      //  id: 40,
      //  name: 'We list for you - 1000 Products',
      //  type: 5,
      //  productOrder: 1000,
      //  prices: [
      //    {
      //      id: 364,
      //      platformId: PaymentPlatform.Stripe,
      //      productId: 40,
      //      platformProductId: 'sku_ITM4Bm4OO04NaG',
      //      currencyId: eCurrency.GBP,
      //      billingPeriodId: BillingPeriod.Unique,
      //      price: 259.99
      //    },
      //    {
      //      id: 365,
      //      platformId: PaymentPlatform.Stripe,
      //      productId: 40,
      //      platformProductId: 'sku_ITM51NaIYmQQvF',
      //      currencyId: eCurrency.EUR,
      //      billingPeriodId: BillingPeriod.Unique,
      //      price: 329.99
      //    }
      //  ]
      //}
    ];
    return {
      products: [...products, ...extraServices],
      loading: loading
    };
  })();
  useEffect(() => {
    dispatch(getSubscriptions());
  }, [getSubscriptions]);
  //---------------------------------------------------------------------
  //SELECTED PRODUCT-----------------------------------------------------
  const { cart: cartIds } = useAppSelector((state) => state.checkout);
  const SetCart = (cart: Cart) => {
    const st: StoredCheckout = {
      products: cart.lines.map(x => x.id),
      currency: cart.currency,
      period: cart.billingPeriodId,
      platform: cart.platform
    };
    dispatch(SaveCart(st));
  };
  const cart = (() => {
    try {
      if (loading || !products) {
        return undefined;
      }
      return {
        platform: cartIds?.platform ?? PaymentPlatform.Stripe,
        currency: cartIds?.currency ?? eCurrency.EUR,
        billingPeriodId: cartIds?.period ?? BillingPeriod.Monthly,
        lines: cartIds?.products?.map(productId => {
          return products.find(y => y.id == productId);
        }).filter(x => x != null) ?? [],
      } as Cart;
    } catch (ex) {
      console.log(ex);
      return {
        platform: PaymentPlatform.Stripe,
        currency: eCurrency.EUR,
        billingPeriodId: BillingPeriod.Monthly,
        lines: [],
      } as Cart;
    }
  })();
  //---------------------------------------------------------------------
  //NAVIGATE-------------------------------------------------------------
  const HandleNavigate = (cart: Cart, section: CheckoutSection) => {
    SetCart(cart);
    let link: string;
    switch (section) {
      default:
      case CheckoutSection.SELECT_PRODUCT:
        link = Links.Checkout.Main; break;
      case CheckoutSection.EXTRA_SERVICES:
        if (cart.platform == PaymentPlatform.PayPal)
          link = Links.Checkout.ExecutePayment;
        else
          link = Links.Checkout.Services;
        break;
      case CheckoutSection.PAYMENT_METHOD:
        link = Links.Checkout.PaymentMethod; break;
      case CheckoutSection.EXECUTE_PAYMENT:
        link = Links.Checkout.ExecutePayment; break;
    }
    history.push(link, location.search);
  };
  //---------------------------------------------------------------------

  if (loading || !cart)
    return <Spin />;

  const RenderPanels = () => {
    if (activeRouteId == CheckoutSection.SELECT_PRODUCT || (cart.lines?.length ?? 0) == 0) {
      return <CheckoutSubscription products={products} cart={cart} onNavigate={HandleNavigate} />;
    }
    switch (activeRouteId) {
      case CheckoutSection.EXTRA_SERVICES:
        return <AddServicesCheckout cart={cart} onNavigate={HandleNavigate} products={products} />;
      case CheckoutSection.PAYMENT_METHOD:
        return <PaymentMethodSubscriptions cart={cart} onNavigate={HandleNavigate} />;
      case CheckoutSection.EXECUTE_PAYMENT:
        return <ExecutingPayment cart={cart} />;
    }
  };

  const Title = () => {
    return <div className="title-container">
      <h2><T k='Checkout.Title' /></h2>
      <h3><T k='Checkout.Subtitle' /></h3>
    </div>;
  };
  const Breadcrump = () => {
    return <div className="breadcrumb">
      {activeRouteId > CheckoutSection.SELECT_PRODUCT && <>
        <A to={Links.Checkout.Main}>
          <h4 className="screen">
            <T k='Checkout.Subscription' />
          </h4>
        </A>
        {activeRouteId >= CheckoutSection.PAYMENT_METHOD && <>
          <h4>{'>'}</h4>
          <A to={Links.Checkout.PaymentMethod}>
            <h4 className="screen">
              <T k='Checkout.PaymentMethod' />
            </h4>
          </A>
        </>}
        {activeRouteId >= CheckoutSection.EXTRA_SERVICES && (cart?.platform ?? PaymentPlatform.Stripe) == PaymentPlatform.Stripe && <>
          <h4>{'>'}</h4>
          <A to={Links.Checkout.Services}>
            <h4 className="screen">
              <T k='Checkout.Services' />
            </h4>
          </A>
        </>}
      </>}
    </div>;
  };

  return (
    <div className="general-checkout-section">
      <div className="general-checkout-background">
        <div className="general-checkout-quotes">
          <QuoteSlider quotes={GetQuotes()} seconds={8} />
        </div>
      </div>
      <div className="general-checkout-section-wrap">
        <div className="general-checkout-section-inner">
          {Title()}
          {Breadcrump()}
          {RenderPanels()}
        </div>
      </div>
    </div>
  );
};