import { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../custom-hooks/reduxCustomHooks';
import '../../../sass/settings/sources-table.scss';
import { getSources } from '../../../redux/sources/sourcesThunk';
import { getSourceConfiguration } from '../../../redux/source-configuration/sources.coonfiguration-thunk';
import { ReactUtils } from '../../../utils/react-utils';
import {
  getChannelConfiguration,
  loadBusinessPolicies,
  loadShipping
} from '../../../redux/channel-configuration/channels-configuration-thunk';
import { ColumnChannelAncestor, Columns, ColumnStyle } from '../configuration/columns';
import { T } from '../../../utils/transShim';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { getTemplates } from '../../../redux/templates/templatesThunk';
import { SimpleTable } from '../../../small-components/tables/simple-table';
import { useHistory } from 'react-router';
import { ChannelSettingKey, SettingValue } from '../../../types/settings';
import { Links } from '../../../links';
import { SourceSettingData } from '../../../redux/source-configuration/types';
import { ePlatform, IsEbay } from '../../../data/platforms';
import { SearchInput } from '../../../small-components/tables/complex-table-components/table-action-btns';

type SourceTableData = { name: string; id: number; [key: number]: SettingValue };

export const SourcesTable = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const selectedChannel = ReactUtils.GetSelectedChannel();

  const { sources, loading: loadingSources } = useAppSelector((state) => state.sources);
  const {
    get: { loading: loadingSourceConfiguration, settings: sourceSettings }
  } = useAppSelector((state) => state.sourcesConfiguration);
  const {
    settings: channelSettings,
    loading: settingsLoading,
    businessPolicies,
    shipping
  } = useAppSelector((state) => state.channelConfiguration);
  const channelSettingsDic = new Map(channelSettings?.map((x) => [x.key, x.value]) ?? []);
  const { templates } = useAppSelector((state) => state.templates);

  const [searchFilter, setSearchFilter] = useState<string>('');

  useEffect(() => {
    dispatch(getSourceConfiguration());
    dispatch(getChannelConfiguration());
    dispatch(getTemplates());
  }, [getSourceConfiguration, getChannelConfiguration, getTemplates]);

  useEffect(() => {
    if (!!sources && sources.length > 0 && sources[0].site == selectedChannel?.isoCountry?.toString())
      return;
    if (loadingSources)
      return;
    dispatch(getSources());
  }, [selectedChannel?.id]);

  useEffect(() => {
    if (!IsEbay(selectedChannel?.channelId))
      return;
    dispatch(loadShipping());
    dispatch(loadBusinessPolicies());
  }, [selectedChannel?.channelId]);

  const settingsDic = new Map<ChannelSettingKey, SourceSettingData[]>();
  for (const ss of (sourceSettings ?? []) as SourceSettingData[]) {
    if (settingsDic.has(ss.sourceId)) {
      settingsDic.get(ss.sourceId)?.push(ss);
    } else {
      settingsDic.set(ss.sourceId, [ss]);
    }
  }

  const sourcesTableData: SourceTableData[] = sources
    ?.map((x) => {
      const settings = settingsDic?.get(x.id) ?? [];
      const toRet: SourceTableData = {
        name: x.name,
        id: x.id
      };
      for (const s of settings) {
        toRet[s.key] = s.value;
      }
      return toRet;
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const AncestorsFulfilled = (ca: ColumnChannelAncestor[]) => {
    for (const se of ca) {
      if (channelSettingsDic.has(se.Field)) {
        if (channelSettingsDic.get(se.Field) == se.Value) {
          return true;
        }
      }
    }
    return false;
  };

  const columns = [
    {
      title: <T k='Sources.Table.Name.Provider'/>,
      dataIndex: 'name',
      key: 'name'
    },
    ...Columns.filter(
      (x) =>
        (!x.Channels || x.Channels?.length == 0 || x.Channels?.includes(selectedChannel?.channelId ?? ePlatform.eBay)) &&
        (!x.ChannelSettingAncestors || x.ChannelSettingAncestors?.length == 0 || AncestorsFulfilled(x.ChannelSettingAncestors))
    ).map((x) => {
      return {
        title: <T k={x.Title}/>,
        dataIndex: x.Key.toString(),
        key: x.Key.toString(),
        render: (v: string) => {
          let value = v;
          if (!v && x.ChannelSetting != undefined) {
            value = channelSettingsDic.get(x.ChannelSetting) as string;
          }
          switch (x.Style) {
            default:
              return value;
            case ColumnStyle.Outlined:
              return value == '1' ? <CheckOutlined /> : value == '0' ? <CloseOutlined /> : '';
            case ColumnStyle.BusinessPayment:
            case ColumnStyle.BusinessReturn:
            case ColumnStyle.BusinessShipping:
              if (!value) return '';
              return businessPolicies?.find((x) => x.id.toString() == value)?.name;
            case ColumnStyle.PolicyReturns:
              switch (value) {
                default:
                  return value;
                case 'Days_30':
                  return <T k='Channel.Setting.Option.Days30'/>;
                case 'Days_14':
                  return <T k = 'Channel.Setting.Option.Days14' />;
                case 'Days_60':
                  return <T k = 'Channel.Setting.Option.Days60' />;
              }
            case ColumnStyle.Template:
              if (!value) return '';
              return templates?.find((x) => x.id.toString() == value)?.name;
            case ColumnStyle.PolicyDelivery:
              if (!value) return '';
              return shipping?.find((x) => x.value == value)?.text;
          }
        }
      };
    })
  ];

  const onSearch = (value: string) => {
    if (value == searchFilter) return;
    setSearchFilter(value);
  };

  const loading = loadingSources || loadingSourceConfiguration || settingsLoading;
  const filteredData = (sourcesTableData ?? []).filter((x) => x.name.toLocaleLowerCase().indexOf(searchFilter.toLocaleLowerCase()) >= 0).map(x => ({...x, key: x.id}));

  const OnRow = (record: SourceTableData) => ({
    onClick: () => {
      history.push(Links.Configuration.Sources.Produce(record.id));
    }
  });

  const Body = () => {
    if (loading)
      return <Spin />;
    return <div className="sources-table-container">
      <SimpleTable columns={columns} dataSource={filteredData} onRow={OnRow} />
    </div>;
  };

  return (
    <div className="sources-container">
      <div className="search-options-area">
        <SearchInput onSearch={onSearch} />
      </div>
      {Body()}
    </div>
  );
};
