/* eslint-disable no-var */
/* eslint-disable indent */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../client';
//import { Source } from '../sources/sourceSlice';
import { OrderData } from './orderSlice';

export type LoadResponse = {
  orders: OrderData[];
  sourcesEnabled: number[];
  //sources: { [key: number]: Source };
}

export type orderDataType = Date | string | number | null | boolean | undefined;

export const GetOrderImage = createAsyncThunk('sales/GetOrderImage',
  async ({ orderId, lastState }:{orderId: number, lastState:number}, thunkAPI) => {
    try {
      const res = await client.get('/sales/GetOrderImage/' + orderId + '/' + lastState + '.png');
      return res.data.response_data;
    } catch (error) {
      return thunkAPI.rejectWithValue('Sorry! Something went wrong');
    }
  }
);

export const GetOrders = createAsyncThunk(
  'search/getSearch',
  async ({ channelOAuthIds }: { channelOAuthIds: OrderData['channelOAuthIds'] }, thunkAPI) => {
    try {
      const res = await client.post('/Sales/Search', { channelOAuthIds });
      return res.data.response_data as LoadResponse;
    } catch (error) {
      return thunkAPI.rejectWithValue('Sorry! Something went wrong');
    }
  }
);

export const ProcessOrders = createAsyncThunk(
  'sales/processOrder',
  async ({ orderLineIds }: { orderLineIds: number[] }, thunkAPI) => {
    try {
      const res = await client.post('/Sales/ProcessOrderLine', { orderLineIds });
      return res?.data?.response_data as LoadResponse;
    } catch (error) {
      return thunkAPI.rejectWithValue('Sorry! Something went wrong');
    }
  }
);

export const ForceProcessOrders = createAsyncThunk(
  'sales/ForceProcessOrder',
  async ({ orderLineIds }: { orderLineIds: number[] }, thunkAPI) => {
    try {
      const res = await client.post('/Sales/ForceProcessOrderLine', { orderLineIds });
      return res?.data?.response_data as LoadResponse;
    } catch (error) {
      return thunkAPI.rejectWithValue('Sorry! Something went wrong');
    }
  }
);

export const GenerateTrackingId = createAsyncThunk(
  'sales/GenerateTrackingId',
  async ({ orderLineIds }: { orderLineIds: number[]}, thunkAPI) => {
    try {
      const res = await client.post('/Sales/GenerateTrackingId', { orderLineIds });
      return res?.data?.response_data as LoadResponse;
    } catch (error) {
      return thunkAPI.rejectWithValue('Sorry! Something went wrong');
    }
  }
);

export const ManuallyDispatch = createAsyncThunk(
  'sales/manuallyDispatch',
  async ({ orderLineIds }: { orderLineIds: number[] }, thunkAPI) => {
    try {
      const res = await client.post('/Sales/ManuallyDispatchOrderLine', { orderLineIds });
      return res.data.response_data as LoadResponse;
    } catch (error) {
      return thunkAPI.rejectWithValue('Sorry! Something went wrong');
    }
  }
);

export const Fixed = createAsyncThunk(
  'sales/fixed',
  async ({ orderLineIds }: { orderLineIds: number[] }, thunkAPI) => {
    try {
      const res = await client.post('/Sales/FixedOrderLine', { orderLineIds });
      return res.data.response_data as LoadResponse;
    } catch (error) {
      return thunkAPI.rejectWithValue('Sorry! Something went wrong');
    }
  }
);

export const StopOrder = createAsyncThunk('sales/stopOrder', async ({ orderLineIds }: { orderLineIds: number[] }, thunkAPI) => {
  try {
    const res = await client.post('/Sales/StopOrderLine', { orderLineIds});
    return res.data.response_data as LoadResponse;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});

export const loadAddressFromOrderLine = createAsyncThunk(
  'sales/loadAddressFromOrderLine',
  async (orderLineId: orderDataType, thunkAPI) => {
    try {
      const res = await client.post('/Sales/LoadAddressesFromOrderLine', { orderLineId });
      return res.data.response_data;
    } catch (error) {
      return thunkAPI.rejectWithValue('Sorry! Something went wrong');
    }
  }
);

export const loadProgressOfOrder = createAsyncThunk(
  'salessss/loadProgressOfTheOrder',
  async (id: OrderData | number | undefined, thunkAPI) => {
    try {
      const res = await client.post('/Sales/LoadProgress', { id });
      return res.data.response_data;
    } catch (error) {
      return thunkAPI.rejectWithValue('Sorry! Something went wrong');
    }
  }
);
