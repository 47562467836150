import { ColumnId } from './columns-id';

export const ColumnsVisibleByDefault = [
  ColumnId.Status,
  ColumnId.CreatedOn,
  ColumnId.QuantityByTime,
  ColumnId.OptimizeTitles,
  ColumnId.OptimizeDescriptions,
  ColumnId.ListVariations,
  ColumnId.Price,
  ColumnId.Profit,
  ColumnId.TitleContains,
  ColumnId.OnlyPrime,
  ColumnId.Sources,
  ColumnId.QuantityListed,
  ColumnId.CheckHistoryButton
];