import { Countries, eCountry } from '../data/countries';
import { ePlatform } from '../data/platforms';
import { Channel } from '../redux/channels/channelsSlice';
import { ShopLogo } from '../utils/shopLogo';

type Props = {
  channel?: Channel;
  channelId?: ePlatform;
  name?: string;
  country?: eCountry;
}

export const ChannelLabel = (props: Props) => {
  const channelId = props.channelId ?? props.channel?.channelId;
  const name = props.name ?? props.channel?.name;
  const country = props.country ?? props.channel?.isoCountry ?? eCountry.UK;

  return <span className="channel-label">
    <ShopLogo channelId={channelId} />
    {Countries[country].Flag}
    {name}
  </span>;
};