import { eShop } from './components/chanel/new-channel/eShop';
import { eNewChannelStep } from './components/chanel/new-channel/step';
import { eCountry } from './data/countries';
import { ePlatform } from './data/platforms';
import { eApiType } from './redux/new-channel/newChannelThunk';

export const Links = {
  Dashboard: '/dashboard',
  AffiliateDashboard: '/affiliate-dashboard',
  DispatchedOrders: '/dispatch-orders',
  Products: {
    Slug: '/products/:slug?',
    Active: '/products',
    Pending: '/products/pending',
    Terminated: '/products/terminated',
    Missing: '/products/import',
    Optimize: '/products/optimize'
  },
  PublishNow: '/list-now',
  ManualPublish: '/manual-publish',
  PublishExtension: '/extension/publish',
  Blank: '/blank',
  BulkPublish: '/bulk-publish',
  Orders: '/orders',
  BuyNow: '/BuyNow',
  Data: '/Data',
  Configuration: {
    Account: {
      Slug: '/configuration/account/:slug?',
      Main: '/configuration/account',
      BillingAddress: '/configuration/account/billing-address',
      PaymentMethod: '/configuration/account/payment-method',
      WithdrawalMethod: '/configuration/account/withdrawal-method'
    },
    AutoOrdering: {
      Slug: '/configuration/auto-ordering/:id?',
      Main: '/configuration/auto-ordering/',
      GeneralSlug: '/configuration/auto-ordering/:id/general',
      AccountSlug: '/configuration/auto-ordering/:id/account',
      SettingSlug: '/configuration/auto-ordering/:id/settings',
      Produce: (id?: number) => (id ? Links.Configuration.AutoOrdering.Main + id : Links.Configuration.AutoOrdering.Main),
      ProduceGeneral: (id?: number) =>
        id ? Links.Configuration.AutoOrdering.Main + id + '/general' : Links.Configuration.AutoOrdering.Main,
      ProduceAccount: (id?: number) =>
        id ? Links.Configuration.AutoOrdering.Main + id + '/account' : Links.Configuration.AutoOrdering.Main,
      ProduceSettings: (id?: number) =>
        id ? Links.Configuration.AutoOrdering.Main + id + '/settings' : Links.Configuration.AutoOrdering.Main
    },
    Channel: {
      Slug: '/configuration/channel/:slug?',
      Main: '/configuration/channel',
      Monitoring: '/configuration/channel/monitoring',
      Product: '/configuration/channel/products',
      Business: '/configuration/channel/business',
      Channel: '/configuration/channel/channel',
      Notifications: '/configuration/channel/notifications'
    },
    Sources: {
      Slug: '/configuration/suppliers/:id?',
      Main: '/configuration/suppliers/',
      Produce: (id?: number) => (id ? Links.Configuration.Sources.Main + id : Links.Configuration.Sources.Main)
    },
    PricingRules: '/configuration/pricing-rules',
    Templates: {
      Edit: '/configuration/templates/:id',
      Main: '/configuration/templates/',
      Produce: (id?: number) => (id ? Links.Configuration.Templates.Main + id : Links.Configuration.Templates.Main)
    },
    VaProfiles: '/configuration/va-profiles'
  },
  NewChannel: {
    Link: '/new-channel',
    Slug: '/new-channel/:step?/:sh?/:ct?/:api?/:nas?/:nm?',
    Produce: (step?: eNewChannelStep, shop?: eShop, site?: eCountry, api?: eApiType, noApiServer?: boolean, name?:string) => {
      let url = Links.NewChannel.Link + '/' + (step as number);
      if (shop) {
        url += '/' + shop;
        if (site) {
          url += '/' + site;
          if (api != null) {
            url += '/' + api;
            if (noApiServer != null) {
              url += '/' + (noApiServer ? 1 : 0);
            }
            if (name) {
              if (noApiServer == null) {
                url += '/_';
              }
              url += '/' + encodeURIComponent(name);
            }
          } else {
            if (noApiServer != null) {
              url += '/' + eApiType.NO_API + '/' + (noApiServer ? 1 : 0);
            }
            if (name) {
              if (noApiServer == null) {
                url += '/' + eApiType.NO_API + '/_';
              }
              url += '/' + encodeURIComponent(name);
            }
          }
        }
      }
      const params = (new URLSearchParams(window.location.search))?.toString();
      if (params != null && params.length > 1) {
        return url + '?' + params;
      }
      return url;
    }
  },
  Services: {
    Main: '/services',
    Slug: '/services/:slug',
    PriceWarrior: '/services/price-warrior',
    WinningProductsList: '/services/winning-products-list',
    PrivateSupplier: '/services/private-supplier',
    NoApiServer: '/services/no-api-server',
    AutoOrdering: '/services/auto-ordering',
    VeRoChecker: '/services/vero-checker',
    ListingService: '/services/listing-service',
    AiTokens: '/services/ai-tokens'
  },
  Subscriptions: '/subscriptions',
  //FreeExpired: '/free-trial-expired',
  Payment: {
    Success: '/payment/success',
    Error: '/payment/error'
  },
  OurServices: '/our-services',
  Checkout: {
    Main: '/checkout',
    Services: '/checkout/add-services',
    PaymentMethod: '/checkout/payment-method',
    ExecutePayment: '/checkout/execute-payment',
    Slug: '/checkout/:step?'
  },
  PaymentsMethod: '/payments-method',
  BrowserExtension: '/browser-extensions',
  GetStarted: '/get-started',
  Catalog: '/catalog',
  SmartLister: {
    Base: '/assistant',
    OrderCreator: {
      Simple: '/assistant/list',
      Advanced: '/assistant/list/advanced',
      Slug: '/assistant/list/:mode?'
    },
    OrderHistory: {
      Slug: '/assistant/history/:id',
      Create: (id: number) => '/assistant/history/' + id
    }
  },
  ConfigureNoapi: '/configure-no-api-server',
  ConfigureListingService: '/setup-preferences',
  Credentials: {
    Login: '/login',
    Register: {
      Base: '/register',
      Slug: '/register/:step?',
      Plan: '/register/plan',
      Form: '/register/user',
    },
    ConfirmLink: '/confirmEmail/:userId/:code',
    ClearAll: '/clearAll',
    ForgetPassword: '/forget-password',
    ResetPassword: '/reset-password',
  },
  PriceWarriorConfig: '/price-warrior-configuration',
  ListingService: 'https://hustlegotreal.com/en/listing-service/',
  HGRNoApiExtension: 'https://chromewebstore.google.com/detail/hgr-non-api/gmdcbkoddgblpnemlconaekpmhgapbcb?snuoi',
  FeedbackForm: 'https://docs.google.com/forms/d/e/1FAIpQLSf-PUeyMRUoPnZ5q8DARpiJ8HAj3yRck1AIwC5muGwW10eilg/viewform?usp=sf_link',
  NoAPIServer: 'https://hustlegotreal.com/en/no-api-server/',
  AOCommissions: '/auto-ordering-commissions',
  RelinkStore: {
    Slug: '/RelinkStore/:platform/:isocountry/:name',
    Produce: (channel: ePlatform, site: eCountry, displayName: string) =>
      '/RelinkStore/' + channel + '/' + (site as number) + '/' + encodeURI(displayName)
  },
  Faq: 'https://hustlegotreal.com/en/frequently-asked-questions/',
  Impersonate: '/impersonate',
  UnImpersonate: '/unimpersonate',
  NewChannelCallBack: {
    Slug: '/auth/callback/:channel?/:site?',
    Base: '/auth/callback/',
    Produce: (channel: ePlatform, site?: eCountry) =>
      Links.NewChannelCallBack.Base + (channel as number) + (site != null ? '/' + (site as number) : '')
  },
  Social: {
    Facebook: 'https://www.facebook.com/hustlegotreal',
    Youtube: 'https://www.youtube.com/channel/UCwerZrzF7vHghxTLuNrcgfg',
    Instagram: 'https://www.instagram.com/hustlegotreal'
  },
  ListingServiceCheckout: '/listingservice',
  Winning: {
    Download: {
      Slug: '/winning/download/:filename?',
      Base: '/winning/download/'
    }
  }
};
