import { Form, Spin, Tooltip } from 'antd';
import { Row, Col, Collapse } from 'antd';
import '../../sass/list-now/manual-listing.scss';
import '../../sass/list-now/bulk-listing.scss';
import { getUserAssistants } from '../../redux/va-profiles/vaProfilesThunk';
import { eChannelListingStatus } from '../../redux/listings/listings-slice';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { useEffect, useState } from 'react';
import { StatusBar } from 'src/small-components/StatusBar';
import Spreadsheet, { Matrix } from 'react-spreadsheet';
import { Selector, SelectorValue } from '../../small-components/form/selector';
import { UserAssistant } from '../../redux/va-profiles/vaProfilesSlice';
import { SimpleTable } from '../../small-components/tables/simple-table';
import { Key } from 'antd/es/table/interface';
import { BulkListingError, BulkListingLog, eBulkListingStatus, BulkListingsDataToSave } from '../../redux/listings/auto-list-slice';
import { GetAutolist, SaveAutolist } from '../../redux/listings/auto-list-thunk';
import { getSources } from '../../redux/sources/sourcesThunk';
import { CheckOutlined, ExclamationCircleOutlined, LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { ReactUtils } from '../../utils/react-utils';
import { SuppliersList } from 'src/small-components/SuppliersList';
import { T } from '../../utils/transShim';
import { PrimaryBtn, SuccessBtn } from '../../small-components/ActionBtns';
import { ePlatform, IsEbay } from '../../data/platforms';
import { MasivePublishModal } from '../../small-components/modals/masive-publish-modal';

import { Links } from '../../links';
import { HelpTooltip } from '../../small-components/help-tooltip';
import { PopupModal } from '../../small-components/modals/popup-modal';
import { getChannelConfiguration } from '../../redux/channel-configuration/channels-configuration-thunk';
import { ChannelSettingKey } from '../../types/settings';
import { LoadingModal } from '../../small-components/modals/loading-modal';
import { Sleep } from '../../utils/utils';
import { A } from '../../small-components/A';

const { Item } = Form;
const { Panel } = Collapse;

type props = {
  urls?: string[];
};

export const BulkListingErrorDetail = (errorCode: BulkListingError | undefined) => {
  switch (errorCode) {
    case BulkListingError.UNKOWN:
    case BulkListingError.INVALID_ORDER:
      return <T k="BulkListing.Error.0" />;
    case BulkListingError.INVALID_TOKEN:
      return <T k="BulkListing.Error.3" />;
    case BulkListingError.SCRAPING:
      return <T k="BulkListing.Error.2" />;
    case BulkListingError.NO_CATEGORY:
      return <T k="BulkListing.Error.4" />;
    case BulkListingError.VERO:
      return <T k="BulkListing.Error.5" />;
    case BulkListingError.ZERO_TOKENS:
      return <T k="BulkListing.Error.6" />;
    case BulkListingError.OOS:
      return <T k="BulkListing.Error.7" />;
    case BulkListingError.FORBIDDEN_WORDS:
      return (
        <>
          <T k="BulkListing.Error.8.1" />{' '}
          <A to={Links.Configuration.Channel.Product} target="_blank">
            <T k="BulkListing.Error.8.2" />
          </A>
        </>
      );
    default:
      return errorCode;
  }
};

export const BulkListing = (props: props) => {
  const { urls } = props;

  const dispatch = useAppDispatch();
  const selectedChannel = ReactUtils.GetSelectedChannel();

  const { autoList } = useAppSelector((state) => state.autoList);
  const { sources, loading: loadingSources } = useAppSelector((state) => state.sources);
  const { settings, loading: settingsLoading } = useAppSelector((state) => state.channelConfiguration);

  const { userAssistants } = useAppSelector((state) => state.userAssistants);

  const labels = ['Source URL', 'Product Title (Optional)'];

  // props for form
  const [createdBy, setCreatedBy] = useState<number>();
  const [ignoreVero, setIgnoreVero] = useState<string>('false');
  const [ignoreOOS /*, setIgnoreOOS*/] = useState<string>('true');
  const [savingProgress, SetSavingProgress] = useState<number>(0);
  const [savingProgressVisible, SetSavingProgressVisible] = useState<boolean>(false);
  const [listing, setListing] = useState<string[][]>([]);
  const [listProductsModal, SetListProductsModal] = useState<boolean>(false);
  const [saving, SetSaving] = useState(false);
  /*  const [reviewBeforePublishing, setReviewBeforePublishing] = useState<string>('false');*/

  //Other states
  const [advancedMode, SetAdvancedMode] = useState<boolean>(false);

  const [wlfym, setWlfym] = (() => {
    const [wlfym, setWlfym] = useState<boolean>();
    return [wlfym, setWlfym];
  })();

  const isImpersonated = ReactUtils.stringToBoolean(localStorage.getItem('isImpersonated'));

  useEffect(() => {
    dispatch(GetAutolist({ weListForYou: isImpersonated && (wlfym ?? false) }));
  }, [GetAutolist, wlfym]);

  useEffect(() => {
    dispatch(getUserAssistants());
  }, [getUserAssistants]);

  useEffect(() => {
    if (!!sources && sources.length > 0 && sources[0].site == selectedChannel?.isoCountry?.toString()) return;
    if (loadingSources) return;
    dispatch(getSources());
  }, [selectedChannel?.id]);

  useEffect(() => {
    dispatch(getChannelConfiguration());
  }, [getChannelConfiguration]);

  const [data, SetData] = (() => {
    const [data, setData] = useState<Matrix<{ value: string }>>(
      urls?.map((url) => [{ value: url }, { value: '' }]) ?? [
        [{ value: '' }, { value: '' }],
        [{ value: '' }, { value: '' }],
        [{ value: '' }, { value: '' }],
        [{ value: '' }, { value: '' }],
        [{ value: '' }, { value: '' }],
        [{ value: '' }, { value: '' }],
        [{ value: '' }, { value: '' }],
        [{ value: '' }, { value: '' }],
        [{ value: '' }, { value: '' }],
        [{ value: '' }, { value: '' }]
      ]
    );
    return [
      data,
      (value: Matrix<{ value: string }>) => {
        if (value[value.length - 1][0]?.value)
          setData([
            ...value,
            [{ value: '' }, { value: '' }],
            [{ value: '' }, { value: '' }],
            [{ value: '' }, { value: '' }],
            [{ value: '' }, { value: '' }],
            [{ value: '' }, { value: '' }],
            [{ value: '' }, { value: '' }],
            [{ value: '' }, { value: '' }],
            [{ value: '' }, { value: '' }],
            [{ value: '' }, { value: '' }],
            [{ value: '' }, { value: '' }]
          ]);
        else setData(value);
      }
    ];
  })();

  const OnPreListItems = () => SetListProductsModal(true);

  const OnListItems = async (
    checkDate: boolean,
    listFrequencyMinutes: number | undefined,
    reviewBeforePublishing: boolean,
    optimizeTitle: boolean,
    optimizeDescription: boolean,
    publishVariations: boolean,
    dontListUntil: Date | undefined
  ) => {
    SetSavingProgressVisible(true);
    SetSaving(true);
    SetListProductsModal(false);
    let _ignoreVero;
    if (ignoreVero === 'true') {
      _ignoreVero = true;
    } else if (ignoreVero === 'false') {
      _ignoreVero = false;
    }
    let _ignoreOOS;
    if (ignoreOOS === 'true') {
      _ignoreOOS = true;
    } else if (ignoreOOS === 'false') {
      _ignoreOOS = false;
    }

    setListing([]);
    data.map((item) => {
      if (item[0]?.value || item[1]?.value) {
        const val1 = item[0]?.value ? item[0]?.value : '';
        const val2 = item[1]?.value ? item[1]?.value : '';
        listing.push([val1, val2]);
      }
    });

    const value: BulkListingsDataToSave = {
      createdBy: createdBy as number,
      ignoreVero: _ignoreVero,
      ignoreOOS: _ignoreOOS,
      dontListUntil: checkDate ? dontListUntil : undefined,
      reviewBeforePublishing: reviewBeforePublishing,
      listFrequencyMinutes: listFrequencyMinutes ?? 0,
      listings: listing,
      optimizeTitle: optimizeTitle,
      optimizeDescription: optimizeDescription,
      publishVariations: publishVariations,
      weListForYou: isImpersonated && (wlfym ?? false)
    };
    let pFinished = false;
    const p = dispatch(SaveAutolist(value));
    p.then((_) => {
      pFinished = true;
    }).catch((_) => {
      location.reload();
    });
    const ts = 100 / listing.length;
    let sp = 0;
    while (sp < 100) {
      SetSavingProgress(Math.min(sp, 99));
      sp += ts;
      if (pFinished) break;
      await Sleep(1000); //0.6s
    }
    SetSavingProgress(99);
    while (!pFinished) await Sleep(1000);
    SetSavingProgress(100);
    SetSaving(false);
  };

  const HandleAssistantChange = (value: SelectorValue) => {
    setCreatedBy(value as number);
  };

  const HandleIgnoreVeroChange = (value: SelectorValue) => {
    setIgnoreVero(value as string);
  };

  const EmptyGuid = () => {
    return '00000000-0000-0000-0000-000000000000';
  };

  const [showError, setShowError] = useState<number | null>(null);
  const HandleShowError = (id: number) => {
    setShowError(id);
  };
  const HandleCloseError = () => {
    setShowError(null);
  };
  const columns = [
    {
      title: 'HGR Id',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (s: eBulkListingStatus) => {
        return eBulkListingStatus[s];
      }
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url'
    },
    {
      title: 'Progress',
      dataIndex: 'channelListingStatus',
      key: 'channelListingStatus',
      render: (s: eChannelListingStatus, record: BulkListingLog) => {
        if (s && (s & eChannelListingStatus.Removed) != 0) {
          return <CheckOutlined />;
        } else if (record.status == eBulkListingStatus.ERROR) {
          return (
            <>
              <PopupModal title="Error details" open={showError == record.id} onClose={HandleCloseError}>
                {BulkListingErrorDetail(record.errorCode)}
              </PopupModal>
              <div className="div-danger" onClick={() => HandleShowError(record.id)}>
                <ExclamationCircleOutlined /> Not published. Click for details.
              </div>
            </>
          );
        } else if (
          record.status == eBulkListingStatus.DONE &&
          record.channelItem == null &&
          record.channelListingStatus &&
          record.channelListingStatus != eChannelListingStatus.PendingToReview &&
          record.channelListingStatus != eChannelListingStatus.Removed
        ) {
          if ((record.channelListingStatus & (eChannelListingStatus.PermanentFailure | eChannelListingStatus.TemporaryFailure)) != 0) {
            return (
              <span>
                <A to={Links.Products.Pending} target="_blank">
                  Error publishing. Check pending products.
                </A>
              </span>
            );
          } else {
            return (
              <span>
                <A to={Links.Products.Pending} target="_blank">
                  Publishing in the store...
                </A>
              </span>
            );
          }
        } else if (record.status == eBulkListingStatus.VALIDATING) {
          return <LoadingOutlined />;
        } else if (record.status == eBulkListingStatus.PROCESSING) {
          return <LoadingOutlined />;
        } else {
          if (!record.channelItem) {
            if (
              record.status == eBulkListingStatus.DONE &&
              record.channelListingStatus &&
              record.channelListingStatus == eChannelListingStatus.PendingToReview
            ) {
              return (
                <Tooltip title="Since you selected the option to review your listings before they are published, you need to verify this product on the Listings Created section for it to be submitted">
                  <p>Pending Review</p>
                </Tooltip>
              );
            } else if (record.channelListingStatus == null) {
              return (
                <Tooltip title="This listing was removed after being pending">
                  <p>Removed by user</p>
                </Tooltip>
              );
            } else {
              return (
                <Tooltip title="Your listing will be published according to the schedule defined">
                  <p>Scheduled</p>
                </Tooltip>
              );
            }
          } else {
            if (record.channelItem) {
              return (
                <span>
                  <strong>Published</strong>
                </span>
              );
            } else {
              return (
                <>
                  <PopupModal title="Error details" open={showError == record.id} onClose={HandleCloseError}>
                    <p>
                      You can see this error on the &quot;Listings Created&quot; section, where you will also be able to edit the listing
                      and retry. <br />
                      Please note that for certain sources, listings with variations are not supported
                    </p>
                  </PopupModal>
                  <div className="div-danger" onClick={() => HandleShowError(record.id)}>
                    Error publishing
                  </div>
                </>
              );
            }
          }
        }
      }
    },
    {
      title: 'Created On',
      dataIndex: 'createdOn',
      key: 'createdOn',
      render: (s: Date) => {
        return ReactUtils.GetFormattedDateTime(s);
      }
    },
    {
      title: 'Published On',
      dataIndex: 'listedOn',
      key: 'listedOn',
      render: (s: Date, record: BulkListingLog) => {
        if (record.verifiedOn && record.channelItem) {
          return ReactUtils.GetFormattedDateTime(s);
        }
      }
    }
  ];

  const RenderOptions = () => {
    return (
      <div className="bulk-sections">
        <div className="options-container">
          <StatusBar className="bulk-options">
            <Form className="bulk-form" layout={'vertical'}>
              <Item
                label={
                  <>
                    <T k="BulkListing.Advanced.CreateAs" />
                    <HelpTooltip>VA Profile selected as the creator of the listing</HelpTooltip>
                  </>
                }
                name="createdBy"
              >
                <Selector defaultValue="select" value={createdBy ?? userAssistants?.[0]?.id} onChange={HandleAssistantChange}>
                  {userAssistants?.map(({ name: label, id: value }: UserAssistant) => ({ value, label }))}
                </Selector>
              </Item>
              {IsEbay(selectedChannel?.channelId ?? ePlatform.eBay) && (
                <>
                  <Item
                    label={
                      <>
                        List Vero Items
                        <A
                          to="https://pages.ebay.com/seller-center/listing-and-marketing/verified-rights-owner-program.html"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <QuestionCircleOutlined style={{ fontSize: '19px' }} />
                        </A>
                      </>
                    }
                    name="ignoreVero"
                  >
                    <Selector defaultValue={ignoreVero} value={ignoreVero} onChange={HandleIgnoreVeroChange}>
                      {[
                        { label: 'Yes', value: 'true' },
                        { label: 'No (Recommended)', value: 'false' }
                      ]}
                    </Selector>
                  </Item>
                </>
              )}
            </Form>
          </StatusBar>
        </div>
      </div>
    );
  };

  const RenderAutolistResult = () => {
    if (!autoList) return <Spin />;

    return (
      <Row>
        <Col>
          <div className="bulk-summary">
            <div className={autoList.summary.notDone == 0 ? 'alert-success' : 'alert-danger'}>
              <h4>
                <div className="summary-heading">
                  <strong>{autoList.summary.done} urls are successfully being listed.</strong>
                  {autoList.summary.duplicatedUrls?.length > 0 && (
                    <strong>{autoList.summary.duplicatedUrls} urls are were duplicated.</strong>
                  )}
                  {autoList.summary.existingListingUrls?.length > 0 && (
                    <>
                      <br />
                      <strong>{autoList.summary.existingListingUrls.length} urls are were already in your account.</strong>
                    </>
                  )}
                  {autoList.summary.forbiddenWordsUrls?.length > 0 && (
                    <>
                      <br />
                      <strong>{autoList.summary.forbiddenWordsUrls.length} urls had forbidden words.</strong>
                    </>
                  )}
                  {autoList.summary.invalidSourceUrls?.length > 0 && (
                    <>
                      <br />
                      <strong>{autoList.summary.invalidSourceUrls.length} urls were invalid.</strong>
                    </>
                  )}
                  {autoList.summary.noQuota > 0 && (
                    <>
                      <br />
                      <strong>{autoList.summary.noQuota} urls were ignored due to quota limit.</strong>
                    </>
                  )}
                </div>
              </h4>
              <Collapse>
                {autoList.summary.duplicatedUrls?.length > 0 ? (
                  <Panel header={'View ' + autoList.summary.duplicatedUrls.length + ' duplicated products on your store.'} key="1">
                    <p>
                      {autoList.summary.duplicatedUrls?.map((x: string, key: Key) => {
                        return <p key={key}>{x}</p>;
                      })}
                    </p>
                  </Panel>
                ) : (
                  ''
                )}

                {autoList.summary.existingListingUrls?.length > 0 ? (
                  <Panel header={'View ' + autoList.summary.existingListingUrls.length + ' duplicated products on the list.'} key="2">
                    <p>
                      {autoList.summary.existingListingUrls?.map((x: string, key: Key) => {
                        return <p key={key}>{x}</p>;
                      })}
                    </p>
                  </Panel>
                ) : (
                  ''
                )}

                {autoList.summary.forbiddenWordsUrls?.length > 0 ? (
                  <Panel header={'View ' + autoList.summary.forbiddenWordsUrls.length + ' titles contains forbidden words.'} key="3">
                    <p>
                      {autoList.summary.forbiddenWordsUrls?.map((x: string, key: Key) => {
                        return <p key={key}>{x}</p>;
                      })}
                    </p>
                  </Panel>
                ) : (
                  ''
                )}

                {autoList.summary.invalidSourceUrls?.filter((x) => !!x)?.length > 0 ? (
                  <Panel header={'View ' + autoList.summary.invalidSourceUrls.length + ' invalid urls.'} key="4">
                    <p>
                      {autoList.summary.invalidSourceUrls
                        ?.filter((x) => !!x)
                        .map((x: string, key: Key) => {
                          return <p key={key}>{x}</p>;
                        })}
                    </p>
                  </Panel>
                ) : (
                  ''
                )}
              </Collapse>

              <p>{autoList.summary.noQuota > 0 ? <span>No quota remaining by {autoList.summary.noQuota}.</span> : ''}</p>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  if (loadingSources || settingsLoading) return <Spin />;

  let qAdded = 0;
  data?.map((item) => {
    if (item[0]?.value || item[1]?.value) qAdded++;
  });
  return (
    <div className="bulk-list-content">
      <div className="content-bulk">
        <h1>
          <T k="BulkListing.Name" /> {wlfym && ' (We list for you)'}
        </h1>
        {isImpersonated && !wlfym && <PrimaryBtn onClick={() => setWlfym(true)}>Open we list for you mode</PrimaryBtn>}
        {isImpersonated && wlfym && <PrimaryBtn onClick={() => setWlfym(false)}>Return to normal mode</PrimaryBtn>}
        <div className="sections-container">
          <PrimaryBtn onClick={(_) => SetAdvancedMode(!advancedMode)}>
            <T k={advancedMode ? 'Button.SimpleMode' : 'Button.AdvancedMode'} />
          </PrimaryBtn>
          <div className="bulk-spreadsheet-section">
            {advancedMode && RenderOptions()}
            <div className="spreadsheet-container">
              <Spreadsheet data={data} onChange={SetData} columnLabels={labels} className="spreadsheet" />
              <div className="table-container">
                <div className="button-container">
                  <Item>
                    <br />
                    <SuccessBtn onClick={OnPreListItems} disabled={saving}>
                      {saving ? <T k="BulkListing.Wait" /> : <T k="BulkListing.Publish" />}
                    </SuccessBtn>
                  </Item>
                </div>
              </div>
            </div>
          </div>
          <MasivePublishModal
            onSubmit={OnListItems}
            open={listProductsModal}
            platform={selectedChannel?.channelId ?? ePlatform.eBay}
            productsInCart={qAdded}
            setOpen={SetListProductsModal}
            loading={false}
            showPublishVariants={true}
            otpimizeByDefault={(settings?.find((x) => x.key == ChannelSettingKey.OptimizeByDefault)?.value ?? '1') == '1'}
            variationsByDefault={(settings?.find((x) => x.key == ChannelSettingKey.VariationsByDefault)?.value ?? '1') == '1'}
          />
          <LoadingModal
            visible={savingProgressVisible}
            loading={saving}
            title={saving ? <T k="BulkListing.Publishing" /> : <T k="BulkListing.Published" />}
            onClose={() => SetSavingProgressVisible(false)}
            progress={savingProgress}
            result={autoList && autoList.summary && autoList.summary.requestId !== EmptyGuid() ? RenderAutolistResult() : <></>}
          />
          <Row className="bulk-log-section">
            <Col md={24}>
              <div className="bulk-log">
                {autoList?.logs && autoList.logs.length > 0 && (
                  <>
                    <h2>History</h2>
                    <SimpleTable columns={columns} dataSource={autoList.logs?.map((x) => ({ ...x, key: x.id }))} />
                  </>
                )}
                {!autoList?.logs && <Spin />}
              </div>
            </Col>
          </Row>
        </div>
        <div className="manual-list-content">
          <div className="container-manual-listing">
            <div className="section-sources">
              <h2>
                <T k="SuportedSuppliers" />
              </h2>
              <SuppliersList loadSources={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
