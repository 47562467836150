import { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { getSourceConfiguration} from 'src/redux/source-configuration/sources.coonfiguration-thunk';
import '../../sass/switch.scss';
import '../../sass/auto-ordering.scss';
import { Links } from '../../links';
import { LeftOutlined } from '@ant-design/icons';
import { T } from '../../utils/transShim';
import { getSources } from '../../redux/sources/sourcesThunk';
import { General } from './auto-ordering-configuration/general';
import { AOAccounts } from './auto-ordering-configuration/account/ao-accounts';
import { TabsConfigurationAO } from './auto-ordering-configuration/tabs-configuration-ao';
import { Row, Tabs } from 'antd';
import { ReactUtils } from '../../utils/react-utils';
import { Settings } from './auto-ordering-configuration/settings';
import { A } from '../../small-components/A';

type Props = {
  sourceId: number;
};

export const AutoOrderingConfiguration = (props: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const channel = ReactUtils.GetSelectedChannel();

  const { sourceId } = props;

  const { sources, loading: loadingSources } = useAppSelector((state) => state.sources);
  const { aoAccounts, loading } = useAppSelector((state) => state.autoOrdering);


  useEffect(() => {
    if (!!sources && sources.length > 0 && sources[0].site == channel?.isoCountry?.toString())
      return;
    if (loadingSources)
      return;
    dispatch(getSources());
  }, [channel?.id]);

  useEffect(() => {
    dispatch(getSourceConfiguration());
  }, [getSourceConfiguration]);



  const sourceData = sources?.find((x) => x.id == sourceId);
  const accounts = aoAccounts?.filter((x) => x.sourceId == sourceData?.id) ?? [];



  const HandleChangeTab = (v: TabsConfigurationAO): void => {
    switch (v) {
      default:
      case TabsConfigurationAO.general:
        history.push(Links.Configuration.AutoOrdering.ProduceGeneral(sourceId));
        break;
      case TabsConfigurationAO.account:
        history.push(Links.Configuration.AutoOrdering.ProduceAccount(sourceId));
        break;
      case TabsConfigurationAO.settings:
        history.push(Links.Configuration.AutoOrdering.ProduceSettings(sourceId));
        break;
    }
  };
  const tab = (() => {
    //this routeMatch has to be done always or react will cry
    //stupid design I know it, but... react
    const isGeneral = useRouteMatch(Links.Configuration.AutoOrdering.GeneralSlug);
    const isAccount = useRouteMatch(Links.Configuration.AutoOrdering.AccountSlug);
    const isSettings = useRouteMatch(Links.Configuration.AutoOrdering.SettingSlug);
    if (isGeneral) return TabsConfigurationAO.general;
    if (isAccount) return TabsConfigurationAO.account;
    if (isSettings) return TabsConfigurationAO.settings;
    return TabsConfigurationAO.general;
  })();

  if (!sourceData)
    return <></>;

  const RenderContent = () => {
    switch (tab) {
      default:
      case TabsConfigurationAO.general:
        return <General
          sourceData={sourceData}
          onChangeTab={HandleChangeTab}
          accounts={accounts}
        />;
      case TabsConfigurationAO.account:
        return <AOAccounts
          sourceData={sourceData}
          accounts={accounts}
          loading={loading}
        />;
      case TabsConfigurationAO.settings:
        return <Settings
          sourceData={sourceData}
        />;
    }
  };

  return (<>
    <div className='settings-panel'>
      <div className="back-supplier-title">
        <A className="back-link" to={Links.Configuration.AutoOrdering.Main}>
          <span>
            <LeftOutlined style={{ fontSize: '19px' }} />
          </span>
          <T k="AutoOrderingConfiguration.Back" />
        </A>
        <div className="title">
          <h1>{sourceData?.name}</h1>
        </div>
      </div>
      <Tabs
        activeKey={tab.toString()}
        onChange={(v) => HandleChangeTab(parseInt(v) as TabsConfigurationAO)}
        items={[
          {
            label: <T k='AutoOrderingConfiguration.Tab.General'/>,
            key: TabsConfigurationAO.general.toString()
          },
          {
            label: <T k='AutoOrderingConfiguration.Tab.Settings' />,
            key: TabsConfigurationAO.settings.toString()
          },
          {
            label: <T k='AutoOrderingConfiguration.Tab.Accounts' />,
            key: TabsConfigurationAO.account.toString()
          }
        ]}
      />
      <Row className="content">
        {RenderContent()}
      </Row>
    </div>
  </>);
};
