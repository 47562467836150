import React from 'react';
import { Selector, SelectorValue } from '../../../small-components/form/selector';
import { T } from '../../../utils/transShim';

export interface FieldSelectProp {
  label: React.ReactNode;
  selectedValue: number;
  options: { key: number; label: string }[];
  onChange: (key: number) => void;
  description?: React.ReactNode;
}
const FieldSelect = (props: FieldSelectProp) => {
  const { label, selectedValue, options, onChange, description } = props;

  const HandleOnChange = (value: SelectorValue) => {
    return onChange(value as number);
  };

  return (
    <div className="option-account">
      <div className="option-account-in">
        <div className="param-container">
          <h4 className="param-title">{label}</h4>
        </div>

        <div
          className="custom-control d-flex align-items-center switchbox custom-switch px-2"
          style={{ marginLeft: '20px' }}
        >
          <Selector onChange={HandleOnChange} value={selectedValue ?? 0} style={{ maxHeight: 32 }}>
            {options.map((x) => ({ label: <T k={x.label}/>, value: x.key }))}
          </Selector>
        </div>
      </div>
      {description && <span className="param-description">{description}</span>}
    </div>
  );
};
export default FieldSelect;
