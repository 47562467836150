import { createSlice } from '@reduxjs/toolkit';
import { getDispatch } from './DispatchOrdersThunk';

export interface Order {
  title: string;
  ebayItemId?: number;
  postCode?: string;
  price?: string;
  quantitySold?: number;
  datePaid?: Date;
  transactionId?: string;
  buyerUsername?: string;
  dispatchDue?: Date;
  sourceName?: string;
  sourceUrl?: string;
  site?: string;
  orderNumber?: string;
  dispatchNote?: string;
  name?: string;
  ebayUrl?: string;
}

export interface DispatchOrder {
  dispatchFuture?: Order[];
  dispatchLate?: Order[];
  dispatchNextWorkingDay?: Order[];
  dispatchToday?: Order[];
  error: string;
  errorMessage: string;
  errorType: string;
  nextWorkingDay: string;
  responseObject: null;
  success: boolean;
  successMessage: null;
  userId: string;
}

export interface DispatchOrderState {
  dispatchOrders?: DispatchOrder;
  loading: boolean;
  error: string;
}

const initialState: DispatchOrderState = {
  loading: false,
  error: ''
};

export const dispatchOrdersSlice = createSlice({
  name: 'DispatchOrders',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDispatch.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getDispatch.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.dispatchOrders = payload;
    });
    builder.addCase(getDispatch.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = String(payload);
    });
  }
});

export const { reducer: dispatchOrdersReducer } = dispatchOrdersSlice;
