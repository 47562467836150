import { isNumber } from 'util';
import { ChannelSpecificField, ChannelSpecificFieldBasicValue, ChannelSpecificFieldValue } from './channel-specific-field';

export interface HGRFormulaFunction {
  Name: string;
  Parameters: HGRFormulaParameter[];
}
export type HGRFormulaParameter = HGRFormulaFunction | HGRFormulaValue;
export enum HGRFormulaValueType {
  literal = 0,
  field = 1,
  literal_array = 2
}
export interface HGRFormulaValue {
  Type: HGRFormulaValueType;
  Value: string;
}
function EvaluateParameter(formula: HGRFormulaParameter, aFD: { [keyName: string]: ChannelSpecificField }): ChannelSpecificFieldValue | null | undefined {
  const fF = formula as HGRFormulaFunction;
  if (fF.Name) {
    switch (fF.Name) {
      case 'IF':
        return EvaluateParameter(fF.Parameters[0], aFD) ? EvaluateParameter(fF.Parameters[1], aFD) : EvaluateParameter(fF.Parameters[2], aFD);
      case '>':
        return EvaluateParameter(fF.Parameters[0], aFD) as number > (EvaluateParameter(fF.Parameters[1], aFD) as number);
      case '<':
        return EvaluateParameter(fF.Parameters[0], aFD) as number < (EvaluateParameter(fF.Parameters[1], aFD) as number);
      case '<=':
        return EvaluateParameter(fF.Parameters[0], aFD) as number <= (EvaluateParameter(fF.Parameters[1], aFD) as number);
      case '>=':
        return EvaluateParameter(fF.Parameters[0], aFD) as number >= (EvaluateParameter(fF.Parameters[1], aFD) as number);
      case '=':
        return (EvaluateParameter(fF.Parameters[0], aFD) ?? '') == (EvaluateParameter(fF.Parameters[1], aFD) ?? '') as boolean;
      case '!=':
        return (EvaluateParameter(fF.Parameters[0], aFD) ?? '') != (EvaluateParameter(fF.Parameters[1], aFD) ?? '') as boolean;
      case 'AND': {
        let ba = true;
        for (const p of fF.Parameters) {
          ba = ba && EvaluateParameter(p, aFD) as boolean;
        }
        return ba;
      }
      case 'OR': {
        let bo = false;
        for (const p of fF.Parameters) {
          bo = bo || EvaluateParameter(p, aFD) as boolean;
        }
        return bo;
      }
      case 'NOT':
        return !EvaluateParameter(fF.Parameters[0], aFD) as boolean;
      case '+':
        return EvaluateParameter(fF.Parameters[0], aFD) as number + (EvaluateParameter(fF.Parameters[1], aFD) as number);
      case '-':
        return EvaluateParameter(fF.Parameters[0], aFD) as number - (EvaluateParameter(fF.Parameters[1], aFD) as number);
      case '*':
        return EvaluateParameter(fF.Parameters[0], aFD) as number * (EvaluateParameter(fF.Parameters[1], aFD) as number);
      case '/':
        return EvaluateParameter(fF.Parameters[0], aFD) as number / (EvaluateParameter(fF.Parameters[1], aFD) as number);
      case 'COUNTIF': {
        const l = EvaluateParameter(fF.Parameters[0], aFD) as string[];
        const value = EvaluateParameter(fF.Parameters[1], aFD) as string | number;
        let number = 0;
        for (let i = 0; i < l.length; i++) {
          if (l[i] == value) {
            number++;
          }
        }
        return number;
      }
      case 'LEN':
        return EvaluateParameter(fF.Parameters[0], aFD)?.toString()?.length;
      default:
        console.log('Unkonwn function: ' + fF.Name);
        return null;
    }
  }

  const fP = formula as HGRFormulaValue;
  switch (fP.Type) {
    case HGRFormulaValueType.field: {
      const fieldName = fP.Value;
      let field = aFD[fieldName];
      let multipleValuesIndex = 0;
      if (!field) {
        for (const k in aFD) {
          // We are searching for "name1 - name5"
          if (k.startsWith(fieldName + '1')) {
            field = aFD[k];
            break;
          }
        }
        if (!field) {
          //It could be something like "fieldname2" so we remove the number from the name
          let fieldNameM = fieldName;
          let n = 1;
          while (isNumber(fieldName[fieldName.length - n])) {
            n++;
          }
          n--;
          fieldNameM = fieldName.slice(0, fieldName.length - n);
          if (fieldNameM != fieldName) {
            multipleValuesIndex = parseInt(fieldName.slice(fieldName.length - n)) - 1; //1 - 5 -> first index is 1 so 0 so ind - 1
            for (const k in aFD) {
              if (k.startsWith(fieldNameM + '1')) {
                field = aFD[k];
                break;
              }
            }
          }
        }
      }
      if (!field) return null;
      if (field.multipleValues) {
        return (field.value as ChannelSpecificFieldBasicValue[])[multipleValuesIndex];
      } else {
        return field.value;
      }
    }
    case HGRFormulaValueType.literal: {
      const rN = parseInt(fP.Value);
      if (isNaN(rN)) {
        switch (fP.Value.toLowerCase()) {
          case 'false':
            return false;
          case 'true':
            return true;
          default:
            return fP.Value;
        }
      } else {
        return rN;
      }
    }
    case HGRFormulaValueType.literal_array:
      return JSON.parse(fP.Value) as string[];
  }
}
export const ShouldBeFieldHidden = (formula: HGRFormulaParameter, fields: ChannelSpecificField[]) => {
  const allSpecificFieldsDic: { [keyName: string]: ChannelSpecificField } = {};
  for (const f of fields) {
    allSpecificFieldsDic[f.keyName] = f;
  }
  return EvaluateParameter(formula, allSpecificFieldsDic);
};

//eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
type ParametersType = any[];

function ProcessFunction(currentFunction: { Name: string; Parameters: ParametersType }) {
  const root: HGRFormulaFunction = {
    Name: currentFunction.Name,
    Parameters: []
  };

  for (const p of currentFunction.Parameters) {
    if (p.Name != undefined) {
      root.Parameters.push(ProcessFunction(p));
    } else {
      let type: HGRFormulaValueType = HGRFormulaValueType.field;
      switch (p[0]) {
        case 'l':
          type = HGRFormulaValueType.literal;
          break;
        case 'a':
          type = HGRFormulaValueType.literal_array;
          break;
        default:
          type = HGRFormulaValueType.field;
          break;
      }
      root.Parameters.push({
        Type: type,
        Value: p.slice(2)
      });
    }
  }
  return root;
}

export function StringToFormula(formula: string) {
  const s: { Name: string; Parameters: ParametersType } = JSON.parse(formula);
  return ProcessFunction(s);
}