import { eCountry } from '../../../data/countries';
import { ePlatform } from '../../../data/platforms';
import { ListNow } from '../../list-now/list-now';
import { DetailRow } from './detail-row';
import { MessageType } from './message-type';

export interface LastMessageProps {
  success: boolean;
  errormsg?: string;
  urlCatalog: string;
  definitive: boolean;

  channelId: ePlatform;
  site: eCountry;
}

export const LastMessage = (props: LastMessageProps) => {


  const renderRecomendations = () => {
    return <ListNow />;
  };

  const renderSuccess = () => {
    const title = props.definitive
      ? <h3>Listing submitted successfully for processing</h3>
      : <h3>Your variation has been saved. You can add more with the extension.</h3>;
    return (
      <>
        <DetailRow title={title} type={MessageType.Success} message='' />
        {renderRecomendations()}
      </>
    );
  };

  const renderFailure = () => {
    return (
      <>
        <DetailRow
          title='Unknown error. Try again later.'
          type={MessageType.Error}
          message={props.errormsg}
        />
        {renderRecomendations()}
      </>
    );
  };


  if (props.success) {
    return renderSuccess();
  }
  return renderFailure();
};
