import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../client';
import { ResetData, UserData } from './userSlice';

import dotenv from 'dotenv';

dotenv.config();

export const LoginUser = createAsyncThunk('user/userLogin',
  async (data: UserData) => {
    try {
      const res = await client.post('/Credentials/Login', data);
      if (res.status === 200) {
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('isImpersonated', 'false');
        localStorage.setItem('Authorization', res.data.response_data.token);
      }
      return { status: res.status, response_data: res.data.response_data, response_errors: res.data.response_errors };
    } catch (error) {
      return { status: 500 };
    }
  }
);

export const RegisterUser = createAsyncThunk('user/userRegister',
  async (data: UserData) => {
    try {
      const res = await client.post('/register/user', data);
      if (res.status === 201) {
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('isImpersonated', 'false');
        localStorage.setItem('Authorization', res.data.response_data.token);
      }
      return { status: res.status, response_data: res };
    } catch (error) {
      return { status: 500 };
    }
  }
);

export const RefreshUser = createAsyncThunk('user/RefreshUser', async (_, { rejectWithValue }) => {
  try {
    const res = await client.get('/Credentials/RefreshUser');
    return res.data.response_data;
  } catch (error) {
    return rejectWithValue('Sorry! Something went wrong');
  }
});

export const getForgotPassword = createAsyncThunk('user/ForgotPassword',
  async (data: ResetData) => {
    try {
      const res = await client.post('/register/ForgotPassword', data);
      return res;
    } catch (error) {
      return {
        status: 500, error: { code: 'Status500InternalServerError' }
      };
    }
  }
);

export const SetResetPassword = createAsyncThunk('user/setResetPassword',
  async (data: ResetData) => {
    try {
      const res = await client.post('/register/ResetPassword', data);
      return res;
    } catch (error) {
      return {
        status: 500, error: { code: 'Status500InternalServerError' }
      };
    }
  }
);

export const ConfirmUser = createAsyncThunk('user/ConfirmEmail',
  async ({ userId, code }: { userId: string, code: string }) => {
    try {
      const res = await client.get('/register/ConfirmEmail?userId=' + userId + '&code=' + code);
      return res.status == 200;
    } catch (error) {
      return false;
    }
  }
);

export const getUserToken = createAsyncThunk('user/userToken', async (_, { rejectWithValue }) => {
  try {
    const res = await client.get('/User/GetTokenBalance');
    return res.data.response_data.tokens;
  } catch (error) {
    return rejectWithValue('Sorry! Something went wrong');
  }
});


export const getUserQuota = createAsyncThunk('user/userQuota', async (_, thunkAPI) => {
  try {
    const res = await client.get('/Dashboard/GetProductQuotaSummary');
    return res.data.response_data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});

export const ImpersonateUser = createAsyncThunk('user/ImpersonateUser', async (userId: string) => {
  try {
    const res = await client.post('/Credentials/ImpersonateUser', { userId: userId });
    return res.data.response_data;
  } catch (error) {
    return error;
  }
});

export const UnImpersonateUser = createAsyncThunk('user/UnImpersonateUser', async () => {
  try {
    const res = await client.post('/Credentials/UnImpersonateUser', {});
    return res.data.response_data;
  } catch (error) {
    return error;
  }
});

export const GetExtensionTokenFromApi = createAsyncThunk('user/GetExtensionToken', async (_, thunkAPI) => {
  try {
    const res = await client.get('/Credentials/GetExtensionToken?incChannel=true');
    return res.data.response_data as { success: boolean; userId: string, token: string, channelId: number };
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});

export const ResendConfirmationEmail = createAsyncThunk('register/ResendConfirmationEmail', async (_, thunkAPI) => {
  try {
    const res = await client.get('/register/ResendConfirmationEmail');
    return res.data.response_data as { success: boolean; };
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});

