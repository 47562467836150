import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { getChannelConfiguration, saveChannelSetting } from '../../redux/channel-configuration/channels-configuration-thunk';
import { getChannels } from '../../redux/channels/channelsThunk';
import { ChannelSettingKey, SettingIncodeComponent, SettingValue } from '../../types/settings';
import { SettingsPanel } from '../../small-components/settings/settings-panel';
import { ChannelSettingsList } from './configuration/settings-list';
import { ChannelSettingSection, ChannelSettingsSections } from './configuration/sections';
import { T } from '../../utils/transShim';
import { ReactUtils } from '../../utils/react-utils';
import { GetPlatform, IsApi } from '../../data/platforms';
import { RemoveChannelSetting } from './configuration/custom-components/remove-channel-setting';
import { Spin } from 'antd';
import { RelinkChannelSetting } from './configuration/custom-components/relink-channel-setting';

export const ChannelConfiguration = () => {
  //Load from api------------------------------------------------------------
  const dispatch = useAppDispatch();
  const {
    settings,
    loading: settingsLoading,
    savingSettings: savingSettingsState
  } = useAppSelector((state) => state.channelConfiguration);

  useEffect(() => {
    dispatch(getChannelConfiguration());
  }, []);


  //Incode Components---------------------------------------------------------------------
  const channel = ReactUtils.GetSelectedChannel();
  if (!channel)
    return <Spin />;
  const incodeComponents: SettingIncodeComponent[] = [];
  if (IsApi(channel?.channelId)) {
    incodeComponents.push({
      section: ChannelSettingSection.Channel,
      component: <RelinkChannelSetting channel={channel} />,
      name: <T k='Channel.Setting.Name.RelinkChannel' />,
      description: <T k='Channel.Setting.Description.RelinkChannel' values={{ name: GetPlatform(channel.channelId).storeName }} />,
    });
  }
  incodeComponents.push({
    section: ChannelSettingSection.Channel,
    component: <RemoveChannelSetting channel={channel} />,
    name: <T k='Channel.Setting.Name.RemoveChannel' />,
    description: <T k='Channel.Setting.Description.RemoveChannel' />,
  });

  //---------------------------------------------------------------------

  const SaveSetting = async (key: ChannelSettingKey, value: SettingValue) => {
    const rp = await dispatch(saveChannelSetting({ key: key, value: value }));
    if (!rp.payload?.success) {
      dispatch(getChannelConfiguration());
    } else {
      if (key == ChannelSettingKey.NoApiName) {
        await dispatch(getChannels());
      }
    }
  };

  const settingsBeingSaved = new Set<ChannelSettingKey>(savingSettingsState?.filter(x => x.loading)?.map(x => x.data.key));

  return <SettingsPanel
    Loading={settingsLoading}
    OnSaveSetting={SaveSetting}
    SettingsBeingSaved={settingsBeingSaved}
    SettingsData={settings}
    SettingsInfo={ChannelSettingsList}
    Sections={ChannelSettingsSections}
    IncodeComponents={incodeComponents}
  />;

};
