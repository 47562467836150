import { ePlatform } from '../../../data/platforms';
import { ProductInChannelData } from './product-in-channel-data';
import { ProductListProduct } from './product-in-list';

export interface ProductListProps {
  possibles?: ProductInChannelData[];
  onSelected?: (product: ProductInChannelData) => void;
  channelId: ePlatform;
}

export const ProductList = (props: ProductListProps) => {

  if (!props.possibles) return <></>;

  const rows = [];
  for (const prd of props.possibles) {
    rows.push(
      <ProductListProduct
        key={prd.id}
        product={prd}
        channelId={props.channelId}
        onSelected={props.onSelected}
      />
    );
  }

  return (
    <div className="productListInChannel panel panel-info">
      <div className="panel-heading">
        <h3 className="panel-title">Does your product match any of the below?</h3>
      </div>
      <div className="list">
        <div className="listContent">{rows}</div>
      </div>
    </div>
  );
};
