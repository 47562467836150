import { createSlice } from '@reduxjs/toolkit';
import { eCountry } from '../../data/countries';
import { ePlatform } from '../../data/platforms';
import { getNoApiServers, getManagedServers, getRequestCode } from './noApiServersThunk';

export enum NoApiServerConnectionStatus {
  None = 0,
  Opening = 1,
  Connecting = 2,
  CaptchaAttempt = 4,
  Retrying = 0x20,
  Connected = 0x40,
  EbayErrorPage = 0x100000,
  ChromeError = 0x200000,
  RequiresOTP = 0x400000,
  AccountSuspended = 0x800000,
  BadUsername = 0x1000000,
  BadPassword = 0x2000000,
  Unknown = 0x4000000,
  Broken = 0x8000000,
  CaptchaFailed = 0x10000000,
  UserActionRequired = 0x20000000,
  RequiresSoftOTP = 0x40000000,
  RequiresThirdPartyLogin = -2147483648,
  NotASeller = 8,
  InSomeStateOfProgress = 103,
  ConnectingProgress = 39,
  IsFailed = -1048568
}

export type Credentials = {
  subscriptionId: number;
  channelOauthId: number;
  password: string;
  validUntil: Date;
  twoStepSecret: string;
  loginUsername: string | null;
  state: number;
  noApiServerId: number;
  solutionState: number | null;
  solutionData: NoApiServerSolutionData;
  lastUpdated: Date;
  isoCountry: eCountry;
  channelItem: number;
  errorRetries: number;
  stealth: boolean;
};

export type CtrlCredentials = {
  subscriptionId: string;
  channelOauthId: number;
  password: string;
  validUntil: Date;
  twoStepSecret: string;
  loginUsername: string;
  state: number;
  noApiServerId: number;
  solutionState: number;
  solutionData: NoApiServerSolutionData;
  lastUpdated: Date;
  isoCountry: eCountry;
  channelItem: number;
  errorRetries: number;
  requestCodeHidden: boolean;
  sendCodeHidden: boolean;
  sendAnswerHidden: boolean;
  confirmButtonHidden: boolean;
  codeInputHidden: boolean;
  answerInputHidden: boolean;
  errorCHidden: boolean;
  timerHidden: boolean;
  infoHidden: boolean;
  questionHidden: boolean;
};

export enum NoApiServerVerifySolutionStatus {
  InitialState = 1,
  WrongCode,
  WaitingSecurityAnswer,
  WaitingCode,
  WrongAnswer,
  SecurityAnswer24H,
  AnswerSent,
  CodeSent,
  WaitingNotification
}

export interface NoApiServer {
  isoCountry: number;
  id: null;
  channelId: number;
  displayName: null;
  nextPayment: Date;
  price: number;
  currencyId: number;
  cancelled: boolean;
  stealth: boolean;
}

export type NoApiServersSliceType = {
  noApiServersResult: NoApiServer[];
  loading: boolean;
  loadingGetRequestCode: boolean;
  error: string;
};

export type NoApiServerSolutionData = {
  question: string;
};

const initialState: NoApiServersSliceType = {
  noApiServersResult: [] as NoApiServer[],
  loading: false,
  loadingGetRequestCode: false,
  error: ''
};

export const noApiServersSlice = createSlice({
  name: 'dashboard-no-api-servers',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNoApiServers.pending, (state) => {
      state.loading = true;
      state.loadingGetRequestCode = false;
      state.error = '';
    });
    builder.addCase(getNoApiServers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.noApiServersResult = payload;
    });
    builder.addCase(getNoApiServers.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = String(payload);
    });

    builder.addCase(getRequestCode.pending, (state) => {
      state.loadingGetRequestCode = true;
    });
    builder.addCase(getRequestCode.fulfilled, (state) => {
      state.loadingGetRequestCode = false;
    });
    builder.addCase(getRequestCode.rejected, (state) => {
      state.loadingGetRequestCode = false;
    });
  }
});

export type ManagedServerStateType = {
  manageServerResult: {
    credentials?: Credentials[];
    channels?: MiniOauth[];
  };
  loading: boolean;
  error: string;
};
export type MiniOauth = {
  id: number;
  password: string;
  twoStepSecret: string;
  loginUsername: string;
  displayName: string;
  channelId: ePlatform;
}
const initialStateManagedServer: ManagedServerStateType = {
  manageServerResult: {},
  loading: false,
  error: ''
};

export const getManagedServersSlice = createSlice({
  name: 'get-managed-servers',
  initialState: initialStateManagedServer,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getManagedServers.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getManagedServers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.manageServerResult = payload;
    });
    builder.addCase(getManagedServers.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = String(payload);
    });
  }
});

export const { reducer: noApiServersReducer } = noApiServersSlice;
export const { reducer: getManagedServersReducer } = getManagedServersSlice;
