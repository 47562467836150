import { createSlice } from '@reduxjs/toolkit';
import { AutoOrderingError } from '../../components/orders/data/auto-ordering-error';
import { AutoOrderingState } from '../../components/orders/data/auto-ordering-state';
import {
  GetOrders,
  ProcessOrders,
  ForceProcessOrders,
  GenerateTrackingId,
  ManuallyDispatch,
  StopOrder,
  loadAddressFromOrderLine,
  loadProgressOfOrder,
  LoadResponse
} from './orderThunk';

export type OrderData = {
  channelOAuthIds: [number];
  date: Date;
  imageUrl: string;
  sourceId: number;
  sourceItem: string;
  channelItem: string;
  channelShipping: number;
  channelCurrency: string;
  sourceCurrencyId: null;
  shippingAddressId: number;
  billingAddressId: number;
  channelTax: number;
  channelVAT: number;
  channelPaymentTaxes: number;
  sourceVAT: null;
  sourceShipping: null;
  sourcePath: string;
  storeStatus: number;
  hgrTrackingNumber: null;
  buyReference: string;
  cancelRequested: boolean;
  orderLineId: number;
  id: number;
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  phone: number;
  city: string;
  zip: string;
  province: string;
  country: string;
  countryCode: string;
  provinceCode: string;
  //Added for advance search
  reference: string;
  quantity: number;
  title: string;
  sold: string;
  cost: string;
  fees: number;
  profit?: number | string; //Calculated in local
  margin?: number | string;  // Calculated in local
  status: number | string;
  sourcePrice: number;
  channelPrice: number;
  key: number; //Calculated in local
  //Calculated in client:
  sourceAOConfigured: boolean;
  sourceAOEnabled: boolean;
  sourceUrl: string;
  sourceName: string;
  markedAsDispatched?: boolean;
};

export type OrderSliceState = {
  orders: LoadResponse | undefined;
  loading: boolean;
  updating: boolean;
  error: string;
};

const initialState: OrderSliceState = {
  orders: undefined,
  loading: false,
  updating: false,
  error: ''
};

export const orderSlice = createSlice({
  name: 'orders',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(GetOrders.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(GetOrders.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.orders = payload;
    });
    builder.addCase(GetOrders.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = String(payload);
    });

    builder.addCase(ProcessOrders.pending, (state) => {
      state.updating = true;
      state.error = '';
    });
    builder.addCase(ProcessOrders.fulfilled, (state, { payload }) => {
      state.updating = false;
      state.orders = payload;
    });
    builder.addCase(ProcessOrders.rejected, (state, { payload }) => {
      state.updating = false;
      state.error = String(payload);
    });

    builder.addCase(ManuallyDispatch.pending, (state) => {
      state.updating = true;
      state.error = '';
    });
    builder.addCase(ManuallyDispatch.fulfilled, (state, { payload }) => {
      state.updating = false;
      state.orders = payload;
    });
    builder.addCase(ManuallyDispatch.rejected, (state, { payload }) => {
      state.updating = false;
      state.error = String(payload);
    });

    builder.addCase(StopOrder.pending, (state) => {
      state.updating = true;
      state.error = '';
    });
    builder.addCase(StopOrder.fulfilled, (state, { payload }) => {
      state.updating = false;
      state.orders = payload;
    });
    builder.addCase(StopOrder.rejected, (state, { payload }) => {
      state.updating = false;
      state.error = String(payload);
    });

    builder.addCase(ForceProcessOrders.pending, (state) => {
      state.updating = true;
      state.error = '';
    });
    builder.addCase(ForceProcessOrders.fulfilled, (state, { payload }) => {
      state.updating = false;
      state.orders = payload;
    });
    builder.addCase(ForceProcessOrders.rejected, (state, { payload }) => {
      state.updating = false;
      state.error = String(payload);
    });

    builder.addCase(GenerateTrackingId.pending, (state) => {
      state.updating = true;
      state.error = '';
    });
    builder.addCase(GenerateTrackingId.fulfilled, (state, { payload }) => {
      state.updating = false;
      state.orders = payload;
    });
    builder.addCase(GenerateTrackingId.rejected, (state, { payload }) => {
      state.updating = false;
      state.error = String(payload);
    });
  }
});

export interface Address {
  id: number;
  firstName?: string;
  lastName?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  city?: string;
  zip?: string;
  province?: string;
  country?: string;
  countryCode?: string;
  provinceCode?: string;
}

export interface GetAddressesFromOrderLineResponse {
  billingAddress: Address;
  shippingAddress: Address;
}

export interface AddressOrderLineSliceType {
  ordersAddress?: GetAddressesFromOrderLineResponse;
  loading?: boolean;
  updating?: boolean;
  error?: string;
}

const initiallState: AddressOrderLineSliceType = {
  loading: false,
  updating: false,
  error: ''
};

export const loadAddressOrderLineSlice = createSlice({
  name: 'loadAddress',
  initialState: initiallState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadAddressFromOrderLine.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(loadAddressFromOrderLine.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.ordersAddress = payload;
    });
    builder.addCase(loadAddressFromOrderLine.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = String(payload);
    });
  }
});

export type GetOrderLineLastProcessStatesResponseData = {
  id: number;
  date: string | Date;
  status: AutoOrderingState;
  error?: AutoOrderingError;
  errorMessage?: string;
}

export type OrderProgress = {
  states: GetOrderLineLastProcessStatesResponseData[];
  lastStatus?: AutoOrderingState;
  lastStatusUpdate: string;
  lastAccountAlias?: string;
}

export type LoadOrderProgressSliceType = {
  orderProgress?: OrderProgress;
  loading?: boolean;
  error?: string;
}

const initialllState: LoadOrderProgressSliceType = {
  loading: false,
  error: ''
};

export const loadOrderProgressSlice = createSlice({
  name: 'stopOrder',
  initialState: initialllState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadProgressOfOrder.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(loadProgressOfOrder.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.orderProgress = payload;
    });
    builder.addCase(loadProgressOfOrder.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = String(payload);
    });
  }
});

export const { reducer: ordersReducer } = orderSlice;
export const { reducer: orderAddressReducer } = loadAddressOrderLineSlice;
export const { reducer: orderProgressReducer } = loadOrderProgressSlice;
