import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Input, Row } from 'antd';
import { A } from '../../../small-components/A';

import { AttributeOption } from './variation-listing-info';

type AttributeOptionsPanelProps = {
  attributeOptions: AttributeOption[];
  onOptionUpdated: (i: number, value: string) => void;
  onAttributeUpdated: (i: number, name: string) => void;
  onAttributeRemoved: (i: number) => void;
  onAttributeAdded: () => void;
};

export const AttributeOptionsPanel = (props: AttributeOptionsPanelProps) => {
  const OnAttributeChange = (i: number, e: React.ChangeEvent<HTMLInputElement>) => {
    props.onAttributeUpdated(i, e.currentTarget.value);
  };

  const OnOptionChange = (i: number, e: React.ChangeEvent<HTMLInputElement>) => {
    props.onOptionUpdated(i, e.currentTarget.value);
  };

  const OnAttributeAdd = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    props.onAttributeAdded();
  };

  const OnAttributeRemoved = (i: number, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    props.onAttributeRemoved(i);
  };

  const attrAlready = new Set<string>();

  return (
    <>
      <hr />
      <Row className="attributeOption">
        {props.attributeOptions.map((x, i) => {
          let redAttribute = false;
          if (attrAlready.has(x.attribute)) {
            redAttribute = true;
          }
          return (
            <Col className="attrOpG" sm={6} key={i}>
              <div>
                <label>Attribute</label>

                <A to="#" className="removeAttr" onClick={(e) => OnAttributeRemoved(i, e)}>
                  <CloseOutlined />
                </A>

                <Input
                  className={'form-control' + (redAttribute ? ' error' : '')}
                  maxLength={450}
                  type="text"
                  value={x.attribute ?? ''}
                  onChange={(e) => OnAttributeChange(i, e)}
                />
              </div>
              <div>
                <label>Option</label>
                <Input className="form-control" maxLength={450} type="text" value={x.option ?? ''} onChange={(e) => OnOptionChange(i, e)} />
              </div>
            </Col>
          );
        })}
        <Col className="addAttribute" sm={4} key="add" onClick={OnAttributeAdd}>
          <div>
            <PlusOutlined className="plusicon" />
          </div>
        </Col>
      </Row>
    </>
  );
};
