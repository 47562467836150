import { createSlice } from '@reduxjs/toolkit';
import { eCountry } from '../../data/countries';
import { getSources } from './sourcesThunk';


export interface Source {
  id: number;
  baseUrl: string;
  name: string;
  site: keyof typeof eCountry;
  recommended: boolean;
  catalogAllowed: boolean;
  manualAllowed: boolean;
  bulkAllowed: boolean;
  privateSupplier: boolean;
  listingServiceAllowed: number;
  autoOrderingFee: null | number;
  hasAutoOrder: boolean;
  status: boolean;
  hasAutoOrderHGRAccount: boolean;
}


export enum eChannelOAuthSourceSettings {
  Markup = 1,
  DispatchDays,
  MonitorStock,
  MonitorPrice,
  MonitorPriceDecrease,
  MonitorPriceDecreasePercentage,
  TemplateId,
  defaultShipping,
  returnsPolicy,
  gsp,
  shippingProfileId,
  returnProfileId,
  paymentProfileId,
  locationCity,
  locationPostcode,
  locationCountry,
  primeOnly,
  minQuantity,
  MaxDeliveryDays,
  AutoOrdering,
  AutoOrderingTrackingNumber,
  AutoOrderingMarkShipped
}


export interface SourcesSliceState {
  sources: Source[],
  loading: boolean,
  error: string
}

const initialState: SourcesSliceState = {
  sources: [],
  loading: false,
  error: ''
};

export const sourceSlice = createSlice({
  name: 'sources',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSources.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getSources.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.sources = payload?.sources;
    });
    builder.addCase(getSources.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = String(payload);
    });
  }
});

export const { reducer: sourcesReducer } = sourceSlice;
