import { Col, DatePicker, DatePickerProps, Input, InputNumber, Radio, RadioChangeEvent, Row, Spin, Switch } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { ePlatform, GetPlatform } from '../../data/platforms';
import { Links } from '../../links';
import { CheckNewCalification } from '../../redux/smart-lister/data/check-new-calification';
import { CheckNewFilterResponse } from '../../redux/smart-lister/data/check-new-filter-response';
import { eSmartListerFilterStatus } from '../../redux/smart-lister/data/e-smart-lister-filter-status';
import { SmartListerFilterType } from '../../redux/smart-lister/data/smart-lister-filter-type';
import { SmartListerFiltersNew, SmartListerCheckNew } from '../../redux/smart-lister/smart-thunk';
import { getSources } from '../../redux/sources/sourcesThunk';
import { PrimaryBtn, DeleteBtn, WarningBtn } from '../../small-components/ActionBtns';
import { Selector, SelectorValue } from '../../small-components/form/selector';
import { HelpTooltip } from '../../small-components/help-tooltip';
import { PopupModal } from '../../small-components/modals/popup-modal';
import { TokensCosts } from '../../small-components/Tokens';
import { ChannelSettingKey } from '../../types/settings';
import { ReactUtils } from '../../utils/react-utils';
import { T } from '../../utils/transShim';
import { BooleanOrNull, NumberOrNull } from '../../utils/utils';
import { CatalogSource } from '../sources/catalog-source';
import { LabelTime } from './data/label-time';
import { TimeUnit } from './data/time-unit';

export const SmartListerNewFilterAdvanced = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const channel = ReactUtils.GetSelectedChannel();

  const { filters } = useAppSelector(state => state.smartLister);
  const { sources, loading: sourcesLoading } = useAppSelector((state) => state.sources);
  const { tokens,  loading: tokensLoading } = useAppSelector((state) => state.user);
  const { settings, loading: settingsLoading } = useAppSelector((state) => state.channelConfiguration);

  const otpimizeByDefault = (settings?.find((x) => x.key == ChannelSettingKey.OptimizeByDefault)?.value ?? '1') == '1';
  const variationsByDefault = (settings?.find((x) => x.key == ChannelSettingKey.VariationsByDefault)?.value ?? '1') == '1';

  const {
    param_priceFrom,
    param_priceTo,
    param_profitFrom,
    param_profitTo,
    param_titleContains,
    param_options,
    param_sourceIds
  } = (() => {
    const searchParams = new URLSearchParams(window.location.search);

    const priceFrom = parseFloat(searchParams.get('f') ?? '');
    const priceTo = parseFloat(searchParams.get('t') ?? '');
    const profitFrom = parseFloat(searchParams.get('p') ?? '');
    const profitTo = parseFloat(searchParams.get('r') ?? '');
    const titleContains = searchParams.get('c');
    const options = parseInt(searchParams.get('i') ?? '');
    const sourceIds = searchParams.get('s') ?? '';

    return {
      param_priceFrom: isNaN(priceFrom) ? undefined : priceFrom,
      param_priceTo: isNaN(priceTo) ? undefined : priceTo,
      param_profitFrom: isNaN(profitFrom) ? undefined : profitFrom,
      param_profitTo: isNaN(profitTo) ? undefined : profitTo,
      param_titleContains: titleContains ?? undefined,
      param_options: isNaN(options) ? 1 : options,
      param_sourceIds: sourceIds.length > 0 ? (sourceIds.split(',').map(x => NumberOrNull(x)).filter(x => x != null && !isNaN(x) && x > 0) ?? []) as number[] : []
    };
  })();

  const [priceMin, SetPriceMin] = useState<number | undefined>(param_priceFrom);
  const [priceMax, SetPriceMax] = useState<number | undefined>(param_priceTo);
  const [profitMin, SetProfitMin] = useState<number | undefined>(param_profitFrom);
  const [profitMax, SetProfitMax] = useState<number | undefined>(param_profitTo);
  const [titleContains, SetTitleContains] = useState<string | undefined>(param_titleContains);
  const [onlyPrime, SetOnlyPrime] = useState<boolean>(param_options == 1);
  const [sourcesIds, SetSourcesIds] = useState<number[]>(param_sourceIds);

  const [sourcesModalOpen, SetSourcesModalOpen] = useState<boolean>();
  const [optimizeTitle, setOptimizeTitle] = useState<boolean>(otpimizeByDefault && (tokens ?? 0) >= 1);
  const [optimizeDescription, setOptimizeDescription] = useState<boolean>(otpimizeByDefault && (tokens ?? 0) >= 3);
  const [publishVariations, setPublishVariations] = useState<boolean>(variationsByDefault);
  const [needsReview, setNeedsReview] = useState<boolean>(false);
  const [dontStartUntil, setDontStartUntil] = useState<Date>(new Date());
  const [frequencyNumber, SetFrequencyNumber] = useState<number>(1);
  const [frequencyUnits, SetFrequencyUnits] = useState<number>(1440);//1 minutes, 60 hours, 1440 days
  const [quantity, SetQuantity] = useState<number>(1);
  const [maxQuantity, SetMaxQuantity] = useState<number | undefined>(undefined);

  const [creating, SetCreating] = useState<boolean>(false);
  const [calification, SetCalification] = useState<CheckNewCalification>(CheckNewCalification.UNKNOWN);

  useEffect(() => {
    if (!!sources && sources.length > 0 && sources[0].site == channel?.isoCountry?.toString())
      return;
    if (sourcesLoading)
      return;
    dispatch(getSources());
  }, [channel?.id]);

  useEffect(() => {
    if ((sources?.length ?? 0) == 0)
      return;
    const newS: number[] = [];
    for (const id of sourcesIds) {
      if (sources.find(x => x.id == id))
        newS.push(id);
    }
    SetSourcesIds(newS);
  }, [sources?.[0]?.id,channel?.id]);


  const HandleOpenSourceModal = () => SetSourcesModalOpen(true);
  const HandleCloseSourceModal = () => SetSourcesModalOpen(false);
  const HandlePriceMinChange = (e: number | null) => SetPriceMin(e ?? undefined);
  const HandlePriceMaxChange = (e: number | null) => SetPriceMax(e ?? undefined);
  const HandleProfitMinChange = (e: number | null) => SetProfitMin(e ?? undefined);
  const HandleProfitMaxChange = (e: number | null) => SetProfitMax(e ?? undefined);
  const HandleOnlyPrimeChange = (e: RadioChangeEvent) => SetOnlyPrime(BooleanOrNull(e.target.value) ?? true);
  const HandleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => SetTitleContains(e.target.value);

  const HandleOptimizeDescription = () => setOptimizeDescription(!optimizeDescription);
  const HandleptimizeTitleHandle = () => setOptimizeTitle(!optimizeTitle);
  const HandleDateOnChange: DatePickerProps['onChange'] = (dateString) => setDontStartUntil(moment.utc(dateString).toDate());
  const HandleChangeFrequencyNumber = (value: number | null) => SetFrequencyNumber(Math.max(value ?? 1,1));
  const HandleFrequencyUnitsChange = (value: SelectorValue) => SetFrequencyUnits(value as number);
  const HandleChangeQuantity = (value: number | null) => SetQuantity(Math.max(value ?? 1, 1));
  const HandleChangeMaxQuantity = (value: number | null) => SetMaxQuantity(value ? Math.max(value,0) : value ?? undefined);

  const HandleReset = () => {
    SetSourcesIds([]);
    SetPriceMin(undefined);
    SetPriceMax(undefined);
    SetProfitMin(undefined);
    SetProfitMax(undefined);
    SetTitleContains(undefined);
    SetOnlyPrime(false);

    setOptimizeTitle(otpimizeByDefault && (tokens ?? 0) >= 1);
    setOptimizeDescription(otpimizeByDefault && (tokens ?? 0) >= 3);
    setPublishVariations(variationsByDefault);
    setDontStartUntil(new Date());
    SetFrequencyNumber(1);
    SetFrequencyUnits(TimeUnit.DAY);
    SetQuantity(1);
  };

  const CreateFilter = (() => ({
    filter: {
      id: 0,
      channelOauthId: 0,
      createdOn: new Date(),
      quantityListed: 0,
      status: eSmartListerFilterStatus.Active,
      dontStartUntil: dontStartUntil,
      listVariations: publishVariations,
      optimizeTitles: optimizeTitle,
      optimizeDescriptions: optimizeDescription,
      quantityByTime: quantity,
      timeInterval: frequencyNumber * frequencyUnits,
      quantityMax: maxQuantity,
      sources: sourcesIds,
      onlyPrime: onlyPrime,
      priceMax: priceMax,
      priceMin: priceMin,
      profitMax: profitMax,
      profitMin: profitMin,
      sourcesInclude: undefined,
      titleContains: titleContains,
      needsReview: needsReview,
      type: SmartListerFilterType.Advanced
    }
  }));

  const HandleCreateOrder = async () => {
    await dispatch(SmartListerFiltersNew(CreateFilter()));
    history.push(Links.SmartLister.Base);
  };
  const HandePreCreateOrder = async () => {
    SetCreating(true);
    SetCalification(CheckNewCalification.UNKNOWN);
    const cc = await dispatch(SmartListerCheckNew(CreateFilter()));
    console.log(cc);
    const c = (cc.payload as CheckNewFilterResponse).calification;
    switch (c) {
      case CheckNewCalification.GOOD:
        HandleCreateOrder();
        break;
      default:
        SetCalification(c);
        break;
    }
  };
  const HandleReturnChange = () => {
    SetCreating(false);
    SetCalification(CheckNewCalification.UNKNOWN);
  };

  const loading = sourcesLoading || tokensLoading || settingsLoading;

  const DrawSource = (id: number) => {
    if (!sources)
      return <></>;

    return <div key={id} className="source">
      <img
        className='source-img'
        src={require('../../assets/logos/' + id + '.png').default}
      />
    </div>;
  };

  const Content = () => {
    return <>
      <div className="form-header">
        <h2 className="add-rule"><T k='SmartLister.NewFilter' /></h2>
        <p className="status-description">
          <T k='SmartLister.NewFilter.Description' />
        </p>
      </div>
      <div className="advanced-form-container">
        <h3><T k='SmartLister.NewFilter.ProductSettings' /></h3>
        <Row>
          <Col xs={16}>
            <Row>
              <Col xs={12}>
                <h4><T k='SmartLister.NewFilter.Product' /></h4>
                <div className="form-item">
                  <label><T k='Catalog.Filter.Title' /></label>
                  <Input
                    className="blue-input title"
                    name="titleContains"
                    value={titleContains}
                    onChange={HandleTitleChange}
                  />
                </div>
                <div className="form-item">
                  <label>
                    <T k='Catalog.Filter.AmazonPrime' />
                    <HelpTooltip><T k='Catalog.Filter.AmazonPrime.Help' /></HelpTooltip>
                  </label>
                  <div className="checkboxes">
                    <Radio.Group onChange={HandleOnlyPrimeChange} value={onlyPrime} name="amazonPrime">
                      <Radio value={true}><T k='Catalog.Filter.OnlyPrime' /></Radio>
                      <Radio value={false}><T k='Catalog.Filter.NotOnlyPrime' /></Radio>
                    </Radio.Group>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <h4><T k='SmartLister.NewFilter.Price' /></h4>
                <Row>
                  <Col xs={12}>
                    <div className="form-item">
                      <label><T k='Catalog.Filter.MinSourcePrice' /></label>
                      <InputNumber
                        className="blue-input"
                        name="priceFrom"
                        value={priceMin}
                        onChange={HandlePriceMinChange}
                        min={0}
                        step={1}
                      />
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="form-item">
                      <label><T k='Catalog.Filter.MaxSourcePrice' /></label>
                      <InputNumber
                        className="blue-input"
                        name="priceTo"
                        value={priceMax}
                        onChange={HandlePriceMaxChange}
                        min={0}
                        step={1}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <div className="form-item">
                      <label><T k='Catalog.Filter.MinProfit' /></label>
                      <InputNumber
                        className="blue-input"
                        name="profitFrom"
                        min={0}
                        step={1}
                        value={profitMin}
                        onChange={HandleProfitMinChange}
                      />
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="form-item">
                      <label><T k='Catalog.Filter.MaxProfit' /></label>
                      <InputNumber
                        className="blue-input"
                        name="profitTo"
                        value={profitMax} onChange={HandleProfitMaxChange}
                        min={0}
                        step={1}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <h3><T k='SmartLister.NewFilter.PublishSettings' /></h3>
            <Row>
              <Col xs={12}>
                <div className="form-item">
                  <h4>
                    <T k='SmartLister.NewFilter.Optimize' />
                    <HelpTooltip>
                      <ul className='onnly-bullets'>
                        <li><T k='Publish.Optimize.Explanation.1' /></li>
                        <li><T k='Publish.Optimize.Explanation.2' values={{ searchEngineName: GetPlatform(channel?.channelId ?? ePlatform.eBay).searchEngineName }} /></li>
                        <li><T k='Publish.Optimize.Explanation.3' /></li>
                        <li><T k='Publish.Optimize.Explanation.4' /></li>
                        <li><T k='Publish.Optimize.Explanation.5' /></li>
                        <li><T k='Publish.Optimize.Explanation.6' /></li>
                      </ul>
                    </HelpTooltip>
                  </h4>
                  <div className="form-item">
                    <div className="section-option-container">
                      <div className="section-option">
                        <div className="section-switch">
                          <Switch checked={optimizeTitle} onChange={HandleptimizeTitleHandle} />
                        </div>
                        <div className="section-title-container">
                          <h5 className="section-option">
                            <T k='SmartLister.NewFilter.Optimize.Title' />
                            <TokensCosts cost={1} />
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-item">
                    <div className="section-option-container">
                      <div className="section-option">
                        <div className="section-switch">
                          <Switch checked={optimizeDescription} onChange={HandleOptimizeDescription} />
                        </div>
                        <div className="section-title-container">
                          <h5 className="section-option">
                            <T k='SmartLister.NewFilter.Optimize.Description' />
                            <TokensCosts cost={2} />
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <T k='Publish.Optimize.TotalCostPerProduct' values={{ quantity: (optimizeTitle ? 1 : 0) + (optimizeDescription ? 2 : 0) }} />
                </div>
              </Col>
              <Col xs={12}>
                <h4><T k='SmartLister.NewFilter.Variations' /></h4>
                <div className="form-item">
                  <div className="section-option-container">
                    <div className="section-option">
                      <div className="section-switch">
                        <Switch checked={publishVariations} onChange={() => setPublishVariations(!publishVariations)} />
                      </div>
                      <div className="section-title-container">
                        <h5>
                          <T k='Publish.Variations.Question' />
                          <HelpTooltip><T k='Publish.Variations.Help' /></HelpTooltip>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-item">
                  <div className="section-option-container">
                    <div className="section-option">
                      <div className="section-switch">
                        <Switch checked={needsReview} onChange={() => setNeedsReview(!needsReview)} />
                      </div>
                      <div className="section-title-container">
                        <h5>
                          <T k='Publish.Review.Question' />
                          <HelpTooltip><T k='Publish.Review.Help' /></HelpTooltip>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <h3><T k='SmartLister.NewFilter.Schedule' /></h3>
            <Row>
              <Col xs={12}>
                <div className="form-item">
                  <label><T k='Publish.Frequency.SelectDate' /></label>
                  <p><T k='Publish.Frequency.SelectDate.Explanation' /></p>
                  <DatePicker showTime={true} className="date-picker" onChange={HandleDateOnChange} />
                </div>
              </Col>
              <Col xs={12}>
                <div className="form-item">
                  <label><T k='SmartLister.NewFilter.Quantity' values={{ c: frequencyNumber, d: LabelTime.find(x => x.value == frequencyUnits)?.label ?? '' }} /></label>
                  <InputNumber
                    className="blue-input"
                    min={1}
                    value={quantity}
                    onChange={HandleChangeQuantity}
                  />
                </div>
                <div className="form-item">
                  <label><T k='Publish.Frequency.ListFrequency' /></label>
                  <div className="frequency-n">
                    <InputNumber
                      className="blue-input"
                      value={frequencyNumber}
                      placeholder='0'
                      onChange={HandleChangeFrequencyNumber}
                      min={1}
                    />
                    <Selector value={frequencyUnits} onChange={HandleFrequencyUnitsChange}>
                      {LabelTime}
                    </Selector>
                  </div>
                </div>
                <div className="form-item">
                  <p>
                    <T k='SmartLister.NewFilter.Frequency.ExplanationQ' values={{ q: quantity, c: frequencyNumber, d: LabelTime.find(x => x.value == frequencyUnits)?.label ?? '' }} />
                  </p>
                </div>
                <div className="form-item">
                  <label>
                    <T k='SmartLister.NewFilter.MaxQuantity' />
                  </label>
                  <InputNumber
                    className="blue-input"
                    min={0}
                    value={maxQuantity}
                    onChange={HandleChangeMaxQuantity}
                  />
                </div>
                <div className="form-item">
                  <p>
                    {(maxQuantity ?? 0) <= 0 && <T k='SmartLister.NewFilter.MaxQuantity.Quota' />}
                    {(maxQuantity ?? 0) > 0 && <T k='SmartLister.NewFilter.MaxQuantity.Limit' values={{q: maxQuantity}} />}
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={8}>
            <h4><T k='SmartLister.NewFilter.Sources' /></h4>
            <div className="form-item">
              <h2><T k='Catalog.Filter.ChooseYourSuppliers' /></h2>
              <PrimaryBtn className="supplier-one" onClick={HandleOpenSourceModal} disabled={sourcesLoading}>
                {sourcesIds?.length > 0 && <T k='Catalog.Filter.SuppliersQ' values={{ q: sourcesIds?.length }} />}
                {!sourcesIds?.length && <T k='Catalog.Filter.Sources.NoP' />}
              </PrimaryBtn>
              <div className="sourcesList">
                {sourcesIds?.length > 0 && !sourcesLoading && sourcesIds.map(DrawSource)}
              </div>
            </div>
          </Col>
        </Row>
        <div className="form-buttons">
          <div>
            <DeleteBtn onClick={HandleReset} htmlType="button">
              <T k='SmartLister.NewFilter.Clear' />
            </DeleteBtn>
          </div>
          <div>
            <PrimaryBtn htmlType="submit" onClick={HandePreCreateOrder} disabled={loading}><T k='SmartLister.NewFilter.Save' /></PrimaryBtn>
          </div>
        </div>
      </div>
      <PopupModal
        key='s'
        open={sourcesModalOpen}
        onClose={HandleCloseSourceModal}
        maxWidth={800}
        title={
          <div className="modal-title">
            <h2><T k='Catalog.Filter.ChooseYourSuppliers' /></h2>
          </div>
        }
      >
        <CatalogSource onClose={HandleCloseSourceModal} onChangeSelectedSources={SetSourcesIds} sources={sources} />
      </PopupModal>
      <PopupModal
        open={creating}
        maskClosable={false}
        showCloseX={false}
        key='c'
        title={<div className="modal-title">
          <h1 className="title">{filters?.saving ? <T k='SmartLister.NewFilter.Creating' /> : <T k='SmartLister.NewFilter.Checking' />}</h1>
        </div>}
        footer={<>
          {!(filters?.saving) && calification == CheckNewCalification.INSUFFICIENT && <>
            <PrimaryBtn onClick={HandleReturnChange}><T k='SmartLister.NewFilter.Change' /></PrimaryBtn>
            <WarningBtn onClick={HandleCreateOrder}><T k='SmartLister.NewFilter.Continue' /></WarningBtn>
          </>}
          {!(filters?.saving) && calification == CheckNewCalification.BAD && <>
            <PrimaryBtn onClick={HandleReturnChange}><T k='SmartLister.NewFilter.Change' /></PrimaryBtn>
          </>}
        </>}
      >
        {!!filters?.saving && <Spin />}
        {!(filters?.saving) && <>
          {calification == CheckNewCalification.UNKNOWN && <Spin />}
          {calification == CheckNewCalification.INSUFFICIENT && <>
            <T k='SmartLister.NewFilter.Insufficient' />
          </>}
          {calification == CheckNewCalification.BAD && <>
            <T k='SmartLister.NewFilter.Bad' />
          </>}
        </>}
      </PopupModal>
    </>;
  };

  if (filters?.saving)
    return <></>;
  return Content();

};