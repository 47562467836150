import { Badge, Dropdown, Menu } from 'antd';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { getNotifications } from '../../redux/notifications/notificationsThunk';
import { ReactUtils } from '../../utils/react-utils';
import bell from '../../assets/bell-icon.svg';
import { Links } from '../../links';
import React from 'react';

import { T } from '../../utils/transShim';
import { A } from '../../small-components/A';
import { NotValidated } from './not-validated';

export const Notifications = () => {
  const dispatch = useAppDispatch();
  const selectedChannel = ReactUtils.GetSelectedChannel();

  const { notifications, loading } = useAppSelector((x) => x.notifications);

  useEffect(() => {
    dispatch(getNotifications());
  }, [getNotifications, selectedChannel?.id]);

  const ProblemNumber = () => {
    if (loading || !notifications) return 0;

    return (
      (notifications.noApiDisconnected ?? []).length +
      (notifications.noApiServerStatusProblems ? 1 : 0) +
      (notifications.pendingPaymentProblems ? 1 : 0) +
      (notifications.disabled ?? []).length +
      (notifications.customMessages ?? []).length +
      (notifications.notActivated ? 1 : 0)
    );
  };

  const GenerateEmpty = () => {
    return <div>Empty</div>;
  };

  const GenerateNoApiDisconnected = (): React.ReactNode[] => {
    const lenNoA = (notifications.noApiDisconnected ?? []).length;
    if (!notifications.noApiDisconnected || lenNoA == 0) return [];

    if (lenNoA > 0) {
      let names: React.ReactNode;
      if (lenNoA == 1) {
        names = (
          <i key="ii">
            <b>{notifications.noApiDisconnected[0].name}</b>
          </i>
        );
      } else {
        names = (
          <>
            {notifications.noApiDisconnected.slice(0, -1).map((x, i) => (
              <React.Fragment key={'rfis_' + i}>
                {i > 0 && ', '}
                <i key={'rfi_' + i}>
                  <b key={'rfib_' + i}>{x.name}</b>
                </i>
              </React.Fragment>
            ))}
            {' and '}
            <i key="rfi_l">
              <b>{notifications.noApiDisconnected[lenNoA - 1].name}</b>
            </i>
          </>
        );
      }

      return [
        <p key={1}>
          <span className="msg">Your NO API extension is not connected to {names}.</span>
          <A to={Links.HGRNoApiExtension} target="_blank" rel="noreferrer">
            Install and connect the extension
          </A>
          &nbsp;or&nbsp;
          <A to={Links.NoAPIServer} target="_blank" rel="noreferrer">
            We run it for you.
          </A>
        </p>
      ];
    }

    return [];
  };

  const GenerateNoApiServerManagement = (): React.ReactNode[] => {
    if (!notifications.noApiServerStatusProblems) return [];

    return [
      <A key={2} to={Links.ConfigureNoapi}>
        There is an alert about <b>No Api Server</b>, click here to check it
      </A>
    ];
  };

  const GeneratePendingPaymentProblems = (): React.ReactNode[] => {
    if (!notifications.pendingPaymentProblems) return [];

    return [
      <A key={5} to={Links.Configuration.Account.PaymentMethod}>
        There is a problem related with <b>Auto Order</b> payments, click here to solve it
      </A>
    ];
  };

  const GenerateRepairAccounts = (): React.ReactNode[] => {
    const lenD = (notifications.disabled ?? []).length;
    if (!notifications.disabled || lenD == 0) return [];

    let names = <></>;
    if (lenD == 1) {
      names = (
        <i key="rii">
          <b>{notifications.disabled[0].name}</b>
        </i>
      );
    } else {
      names = (
        <>
          {notifications.disabled.slice(0, -1).map((x, i) => (
            <React.Fragment key={'rrfs_' + i}>
              {i > 0 && ', '}
              <i key={'rrf_' + i}>
                <b>{x.name}</b>
              </i>
            </React.Fragment>
          ))}
          {' and '}
          <i key={'rrii'}>
            <b>{notifications.disabled[lenD - 1].name}</b>
          </i>
        </>
      );
    }

    return [
      <A key={3} className="notificationLine" to={Links.Configuration.Channel.Channel}>
        You need to relink {names}. You can do it from Settings &gt; Store &gt; Other.
      </A>
    ];
  };

  const GenerateCustomMSG = (): React.ReactNode[] => {
    const lengC = (notifications.customMessages ?? []).length;
    if (!notifications.customMessages || lengC == 0) return [];

    return notifications.customMessages.map((x, i) => <p key={'4_' + i}>{x}</p>);
  };

  const GenerateNotActivated = (): React.ReactNode[] => {
    if (!notifications.notActivated) return [];

    return [
      <p key='5_'><NotValidated userCreated={new Date(notifications.userCreated ?? '')} /></p>
    ];
  };

  const ProblemList = () => {
    if (ProblemNumber() == 0)
      return [{ key: 0, label: GenerateEmpty() }];

    const list = [];

    let id = 0;
    for (const g of GenerateNotActivated()) {
      id++;
      list.push({ key: id, label: g });
    }
    for (const g of GenerateNoApiDisconnected()) {
      id++;
      list.push({ key: id, label: g });
    }
    for (const g of GenerateNoApiServerManagement()) {
      id++;
      list.push({ key: id, label: g });
    }
    for (const g of GeneratePendingPaymentProblems()) {
      id++;
      list.push({ key: id, label: g });
    }
    for (const g of GenerateRepairAccounts()) {
      id++;
      list.push({ key: id, label: g });
    }
    for (const g of GenerateCustomMSG()) {
      id++;
      list.push({ key: id, label: g });
    }

    return list;
  };

  const notificationsMenu = <Menu items={ProblemList()} />;
  const dropDownContent = <div className="notifications-list">
    <h1><T k='Topbar.Notifications'/></h1>
    {notificationsMenu}
  </div>;

  return (
    <div className="notifications-container">
      <Dropdown
        arrow
        placement="bottom"
        overlay={dropDownContent}
        overlayStyle={{
          width: '20vw',
          position: 'fixed'
        }}

      >
        <Badge count={ProblemNumber()} className="notifications-badge">
          <img src={bell} alt="" />
        </Badge>
      </Dropdown>
    </div>
  );
};
