import { InputNumber } from 'antd';
import { SetValueNumber } from '../types';

type Props = {
  value: SetValueNumber;
  onChange: (p: SetValueNumber) => void;
  min?: number;
}

export const MultipleNumberField = (props: Props) => {
  const { value, onChange, min } = props;

  const HandleChange = (v: number | null) => {
    onChange({ value: v ?? undefined });
  };

  return <InputNumber className='blue-input' value={value?.value ?? 1} onChange={HandleChange} min={min}/>;
};