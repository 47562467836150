//This table will include search, advanced search, columns customization, etc.

import { Input, Spin } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import { useEffect, useState, useMemo } from 'react';
import { VisibleColumnsPopup } from '../../components/listings/Listings/components/visible-columns-popup';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { UIPreferencesState, UITablePreference, UITablePreferenceL } from '../../redux/ui-preferences/ui-preferences-state-slice';
import { getPreferences, savePreferences } from '../../redux/ui-preferences/ui-preferences-state-thunk';
import { T, useTranslation } from '../../utils/transShim';
import { AdvancedFilterData, AdvancedSearch } from './complex-table-components/advanced-search';
import { TableActionBtns } from './complex-table-components/table-action-btns';
import { DataTable } from './data-table';
import { SmartSearchFilter } from './complex-table-components/smart-search-filter';
import { ColumnData, ColumnId } from './types/columns';
import { AdvancedSearchFilter } from './complex-table-components/advanced-search-filter';
import { OnRow } from './simple-table';
import React from 'react';
import { CompactMode } from './types/compact-mode';
import { RefreshData } from './types/refresh-data';
import { RefreshWarning } from './complex-table-components/refresh-warning';
import { ActionMenuType } from './types/action-menu-type';

interface Props<RecordType> {
  uiIdentifier: string;
  defaultVisibleColumns?: ColumnId[];
  allColumnData: ColumnData<RecordType>[];
  data: RecordType[];
  hideWhenEmpty?: boolean;
  loadingData?: boolean;
  rowSelection?: TableRowSelection<RecordType>;
  onChangeVisibleRows?: (rows: RecordType[]) => void;
  onRow?: OnRow<RecordType>;
  actionsMenu?: ActionMenuType[];
  hideTotal?: boolean;
  currentPage?: number;
  onPageChange?: (page: number) => void;
  pageSize?: number;
  onPageSizeChanged?: (itemsPerPage: number) => void;
  pageSizes?: number[];
  advancedSearch?: boolean;
  smartSearch?: boolean;
  compactMode?: boolean;
  forceFilter?: AdvancedFilterData[];
  onChangeFilter?: (f: AdvancedFilterData[]) => void;
  refresh?: RefreshData
  preSearch?: React.ReactNode;
}
//eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
export const ComplexTable = <RecordType extends object = any>(props: Props<RecordType>) => {
  const {
    uiIdentifier,
    defaultVisibleColumns,
    allColumnData,
    data,
    hideWhenEmpty,
    loadingData,
    rowSelection,
    onChangeVisibleRows,
    onRow,
    actionsMenu,
    hideTotal,
    currentPage,
    onPageChange,
    onPageSizeChanged,
    pageSizes,
    advancedSearch: renderAdvancedSearch,
    smartSearch: renderSmartSearch,
    compactMode: renderCompactMode,
    forceFilter,
    onChangeFilter,
    preSearch,
    refresh
  } = props;
  const dispatch = useAppDispatch();

  //UI----------------------------------------------------------------------------------------}
  const uiPreferencesS = (() => {
    const preferences = useAppSelector((state) => state.UIPreferences as UIPreferencesState)?.tablePreferences?.[uiIdentifier];
    return {
      columns: preferences?.columns,
      pageSize: preferences?.pageSize ?? 10,
      pageNumber: currentPage ?? 1,
      loading: preferences?.loading ?? false,
      compactMode: preferences?.compactMode
    };
  })();
  const [uiPreferences, SetUIPreferences] = useState<UITablePreferenceL>(uiPreferencesS);

  const searchLabel = useTranslation('Table.Search') as string;

  useEffect(() => {
    dispatch(getPreferences(uiIdentifier));
  }, [uiIdentifier]);

  useEffect(() => {
    SetUIPreferences(uiPreferencesS);
  }, [uiPreferencesS?.columns, uiPreferencesS?.pageSize, uiPreferencesS?.pageNumber, uiPreferencesS?.compactMode]);

  const SaveUIPreferences = (preferences: UITablePreference) => {
    SetUIPreferences({ ...preferences, loading: false });
    dispatch(savePreferences({ uiIdentifier, data: preferences }));
  };
  //------------------------------------------------------------------------------------------
  ///POPUP VISIBLE COLUMNS--------------------------------------------------------------------
  const [visibleColumnsPopupOpened, SetVisibleColumnsPopupOpened] = useState<boolean>(false);
  const CloseVisibleColumnsPopup = () => SetVisibleColumnsPopupOpened(false);
  const OpenVisibleColumnsPopup = () => SetVisibleColumnsPopupOpened(true);
  const SaveVisibleColumns = (columns: ColumnId[]) => SaveUIPreferences({ ...{ ...uiPreferences, loading: undefined }, columns });
  //------------------------------------------------------------------------------------------
  ///COMPACT MODE-----------------------------------------------------------------------------
  const SaveCompactMode = (value: CompactMode) => SaveUIPreferences({ ...{ ...uiPreferences, loading: undefined }, compactMode: value });
  const HandleChangeCompactMode = () => SaveCompactMode(uiPreferences?.compactMode !== CompactMode.compact ? CompactMode.compact : CompactMode.normal);

  //------------------------------------------------------------------------------------------
  ///PAGE SIZE--------------------------------------------------------------------------------
  const OnPageSizeChange = (pageSize: number) => {
    SetUIPreferences((prev) => ({ ...prev, pageSize, loading: false }));
    SaveUIPreferences({ ...{ ...uiPreferences, loading: undefined }, pageSize });
    onPageSizeChanged?.(pageSize);
  };
  //------------------------------------------------------------------------------------------
  ///PAGE NUMBER------------------------------------------------------------------------------
  const OnPageNumberChange = (pageNumber: number) => {
    onPageChange?.(pageNumber);
  };
  //------------------------------------------------------------------------------------------
  //COLUMNS-----------------------------------------------------------------------------------
  const { normalColumns, visibleColumnsList, expandableColumns } = /*useMemo*/ (() => {
    const visibleColumnsList = !uiPreferences.columns || uiPreferences.columns.length == 0 ? defaultVisibleColumns : uiPreferences.columns;

    const columns = allColumnData
      .filter((x) => !visibleColumnsList || visibleColumnsList.length == 0 || visibleColumnsList.includes(x.id))
      .map((x) => ({ ...x, title: typeof x.title === 'string' ? <T k={x.title} /> : <>{x.title}</>, key: x.id.toString() }));

    const normalColumns = columns.filter((x) => !x.expandable?.expandable);
    const expandableColumns = columns.filter((x) => !!x.expandable?.expandable);

    return {
      normalColumns,
      visibleColumnsList,
      expandableColumns
    };
  })(/*, [uiPreferences, defaultVisibleColumns, allColumnData]*/);
  const RenderExpandableRowContent = (row: RecordType, x: ColumnData<RecordType>, i: number) =>
    x.render?.(row[x.dataIndex as keyof RecordType], row, i) ?? row[x.dataIndex as keyof RecordType];
  const RenderExpandableRow = (row: RecordType, x: ColumnData<RecordType>, i: number) => (
    <div key={i} className={'expandable-column' + (x.expandable?.align === 'right' ? ' right' : '')}>
      <div className="expandable-title">{x.title}</div>
      <div className="expantable-content">{RenderExpandableRowContent(row, x, i)}</div>
    </div>
  );
  const RenderExpandable = (row: RecordType) => (
    <div className="expandable-row">
      {expandableColumns
        .filter((x) => x.expandable?.showExpandable?.(row[x.dataIndex as keyof RecordType], row) ?? true)
        .map((x, i) => RenderExpandableRow(row, x, i))}
    </div>
  );
  const ExpandableCondition = (row: RecordType) =>
    expandableColumns.some((x) => x.expandable?.expandableCondition?.(row[x.dataIndex as keyof RecordType], row) ?? true);
  //------------------------------------------------------------------------------------------

  //OMNISEARCH--------------------------------------------------------------------------------
  const [smartSearch, SetSmartSearch] = useState<string | null | undefined>(null);
  const OnChangeSmartSearch = (e: React.FormEvent<HTMLInputElement>) => SetSmartSearch(e.currentTarget?.value);
  //------------------------------------------------------------------------------------------

  //ADVANCED SEARCH / SMART SEARCH------------------------------------------------------------
  const [advancedSearchVisible, SetAdvancedSearchVisible] = useState<boolean>(false);
  const [advancedFilter, SetAdvancedFilter] = (() => {
    const [a, s] = useState<AdvancedFilterData[]>([]);
    return [forceFilter ?? a, (v: AdvancedFilterData[]) => {
      onChangeFilter?.(v);
      s(v);
    }];
  })();
  
  const OpenAdvancedSearch = () => SetAdvancedSearchVisible(true);
  let filteredData = useMemo(() => AdvancedSearchFilter(advancedFilter, data, allColumnData), [advancedFilter, data, allColumnData]);
  filteredData = useMemo(() => SmartSearchFilter(smartSearch, filteredData, allColumnData), [smartSearch, filteredData, allColumnData]);
  //------------------------------------------------------------------------------------------

  const loading = loadingData || uiPreferences.loading;

  const [enabledAutoRefresh, setEnabledAutoRefresh] = useState<boolean>(true);

  const cRefresh = { ...refresh } as RefreshData;
  if (cRefresh.autoTime) {
    cRefresh.autoTime = {
      ...cRefresh.autoTime,
      enabled: refresh?.autoTime?.enabled ?? enabledAutoRefresh,
      onStopRefresh: () => {
        setEnabledAutoRefresh(false);
        refresh?.autoTime?.onStopRefresh?.();
      }
    };
  }

  return (
    <div className={'complex-table' + (uiPreferences?.compactMode == CompactMode.compact ? ' compact' : '')}>
      {visibleColumnsList && (
        <VisibleColumnsPopup
          isVisible={visibleColumnsPopupOpened}
          onClose={CloseVisibleColumnsPopup}
          allColumns={allColumnData}
          visibleColumns={visibleColumnsList}
          onChange={SaveVisibleColumns}
        />
      )}
      <div className="search-options-area">
        {!!preSearch && preSearch}
        {(renderSmartSearch ?? true) && <Input autoFocus placeholder={searchLabel + '...'} onChange={OnChangeSmartSearch} className='search-input' />}
        {!(renderSmartSearch ?? true) && <span></span>}
        {(renderAdvancedSearch ?? true) && (
          <>
            <AdvancedSearch
              visible={advancedSearchVisible}
              onClose={() => SetAdvancedSearchVisible(false)}
              closable
              columns={allColumnData}
              onChangeFilter={SetAdvancedFilter}
            />
          </>
        )}
        <TableActionBtns
          showColumns={!uiPreferences.loading}

          onShowColumns={OpenVisibleColumnsPopup}
          onSideDrawer={OpenAdvancedSearch}
          onChangeCompactMode={HandleChangeCompactMode}

          renderAdvancedSearch={renderAdvancedSearch}
          renderCompactMode={renderCompactMode}

          refresh={refresh}
        />
      </div>

      {loading && (
        <div className="listings-container">
          <Spin />
        </div>
      )}
      {cRefresh?.autoTime?.show && (cRefresh?.autoTime?.time ?? 0) > 0 && cRefresh.autoTime.enabled && !cRefresh.loading && <RefreshWarning refresh={cRefresh} />}
      {!loading && (
        <>
          {(!hideWhenEmpty || data.length > 0) && (
            <DataTable
              productNamePlural={<T k='Listings' />}
              columns={normalColumns}
              dataSource={filteredData}
              totalItems={data?.length}
              showTableInfo={true}
              rowClassName="table-row"
              pageSize={uiPreferences.pageSize}
              onPageSizeChanged={OnPageSizeChange}
              onPageChange={OnPageNumberChange}
              currentPage={currentPage}
              hideTotal={hideTotal}
              pageSizes={pageSizes}
              rowSelection={rowSelection}
              onChangeVisibleRows={onChangeVisibleRows}
              actionsMenu={actionsMenu}
              onRow={onRow}
              expandable={
                expandableColumns?.length > 0
                  ? {
                    render: RenderExpandable,
                    rowExpandable: ExpandableCondition
                  }
                  : undefined
              }
            />
          )}
        </>
      )}
    </div>
  );
};
