import { Col, Row } from 'antd';
import { ListData, SettingList } from '../../../../../small-components/settings/setting-list';
import { T } from '../../../../../utils/transShim';
import '../../../../../sass/listing-options.scss';
import { SettingBooleanTwoOptions } from '../../../../../small-components/settings/settings-boolean-two-options';
import { SettingNumber } from '../../../../../small-components/settings/setting-number';
import { ePlatform, IsEbay } from '../../../../../data/platforms';
import { AmazonSourceIds } from '../../../../sources/configuration/settings-list';

type Props = {
  sourceId: number;
  platform: ePlatform;

  valueMonitorStock: boolean | undefined;
  valueMonitorPrice: boolean | undefined;
  valueIgnoreRules: boolean | undefined;
  valueMonitorPriceDecrease: boolean | undefined;
  valuePriceDecreasePercentage: number | undefined;
  valueMinQuantity: number | undefined;
  valueOnlyPrime: boolean | undefined;
  valueDispatchDays: number | undefined;

  settingMonitorStock: boolean;
  settingMonitorPrice: boolean;
  settingMonitorPriceDecrase: boolean;
  settingPriceDecreasePercentage: number;
  settingMinQuantity: number;
  settingOnlyPrime: boolean;
  settingDispatchDays: number;

  onChangeMonitorStock: (v: boolean | undefined) => void;
  onChangeMonitorPrice: (v: boolean | undefined) => void;
  onChangeIgnoreRules: (v: boolean | undefined) => void;
  onChangeMonitorPriceDecrease: (v: boolean | undefined) => void;
  onChangePriceDecreasePercentage: (v: number | undefined) => void;
  onChangeMinQuantity: (v: number | undefined) => void;
  onChangeOnlyPrime: (v: boolean | undefined) => void;
  onChangeDispatchDays: (v: number | undefined) => void;
};

export const ListingOptions = (props: Props) => {
  const {
    sourceId,
    platform,

    valueMonitorStock,
    valueMonitorPrice,
    valueIgnoreRules,
    valueMonitorPriceDecrease,
    valuePriceDecreasePercentage,
    valueMinQuantity,
    valueOnlyPrime,
    valueDispatchDays,

    settingMonitorStock,
    settingMonitorPrice,
    settingMonitorPriceDecrase,
    settingPriceDecreasePercentage,
    settingMinQuantity,
    settingOnlyPrime,
    settingDispatchDays,

    onChangeMonitorStock,
    onChangeMonitorPrice,
    onChangeIgnoreRules,
    onChangeMonitorPriceDecrease,
    onChangePriceDecreasePercentage,
    onChangeMinQuantity,
    onChangeOnlyPrime,
    onChangeDispatchDays
  } = props;

  const CapitalizeFirstLetter = (s: React.ReactNode) => {
    if (!s) return s;

    if (typeof s != 'string') return s;

    if (s.startsWith('eBay'))
      //eBay is an exception
      return s;

    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const RenderList = (value: string | null, options: ListData[], onChange: (v: string | null) => void) => {
    return (
      <Col span={8} className="input-container">
        <SettingList defaultValue={value ?? ''} listData={options} onChange={onChange} />
      </Col>
    );
  };

  const RenderTriBoolean = (value: boolean | undefined, settingValue: boolean, onChange: (v: boolean | undefined) => void) => {
    return RenderList(
      value == null ? null : value ? '1' : '0',
      [
        {
          value: null,
          label: <T k="Setting.FinalDefinedBySettings" values={{ value: settingValue ? <T k="Setting.Yes" /> : <T k="Setting.No" /> }} />
        },
        { value: '0', label: <T k="Setting.No" /> },
        { value: '1', label: <T k="Setting.Yes" /> }
      ],
      (v) => onChange(v == null ? undefined : v == '1')
    );
  };

  const RenderBoolean = (value: boolean | undefined, onChange: (v: boolean | undefined) => void) => {
    return RenderList(
      value ? '1' : '0',
      [
        { value: '0', label: <T k="Setting.No" /> },
        { value: '1', label: <T k="Setting.Yes" /> }
      ],
      (v) => onChange(v == '1' ? true : undefined)
    );
  };

  const RenderSettingSwitchTwoOptions = (
    booleanValue: boolean | undefined,
    numberValue: number | undefined,
    settingBooleanValue: boolean,
    settingNumberValue: number,
    label1Tag: string,
    label2Tag: string,
    onChange1: (value: boolean | undefined) => void,
    onChange2: (value: number | undefined) => void,
    defaultalue1: boolean,
    defaultValue2: number
  ) => {
    return (
      <>
        <SettingBooleanTwoOptions
          defaultNumberValue1={defaultalue1 ? '1' : '0'}
          defaultNumberValue2={defaultValue2.toString()}
          defaultValue1={booleanValue != null ? (booleanValue ? '1' : '0') : null}
          defaultValue2={numberValue?.toString() ?? null}
          label1={<T k={label1Tag} />}
          label2={<T k={label2Tag} />}
          delayToSave={0}
          onChange1={(v) => onChange1(v != null ? v == '1' : undefined)}
          onChange2={(v) => onChange2(v != null ? parseFloat(v) : undefined)}
          superiorValue1={settingBooleanValue ? '1' : '0'}
          superiorValue2={settingNumberValue?.toString()}
        />
      </>
    );
  };

  const RenderNumber = (
    value: number | undefined,
    settingValue: number,
    onChange: (v: number | undefined) => void,
    min?: number,
    max?: number
  ) => {
    return (
      <SettingNumber
        defaultValue={value?.toString()}
        superiorValue={settingValue.toString()}
        min={min?.toString()}
        max={max?.toString()}
        onChangeNoDelay={(v) => onChange(v != null ? parseFloat(v) : undefined)}
      />
    );
  };

  const RenderRow = (nameTag: string, descriptionTag: string | string[], renderFunction: () => React.ReactNode) => {
    return (
      <Row className="description-and-controls" key={nameTag}>
        <Col span={12} className="description-area">
          <h2>{CapitalizeFirstLetter(<T k={nameTag} />)}</h2>
          {(Array.isArray(descriptionTag) ? descriptionTag : [descriptionTag]).map((x, i) => (
            <p key={i}>
              <T k={x} />
            </p>
          ))}
        </Col>
        <Col span={8} className="switch-container">
          {renderFunction()}
        </Col>
      </Row>
    );
  };

  return (
    <div className="listing-options">
      {RenderRow('Channel.Setting.Name.MonitorStock', 'Channel.Setting.Description.MonitorStock', () =>
        RenderTriBoolean(valueMonitorStock, settingMonitorStock, onChangeMonitorStock)
      )}
      {RenderRow('Channel.Setting.Name.MonitorPrice', 'Channel.Setting.Description.MonitorPrice', () =>
        RenderTriBoolean(valueMonitorPrice, settingMonitorPrice, onChangeMonitorPrice)
      )}
      {RenderRow('Listings.Column.IgnoreRules', 'Listings.Setting.IgnoreRules.Description', () =>
        RenderBoolean(valueIgnoreRules, onChangeIgnoreRules)
      )}
      {RenderRow(
        'Channel.Setting.Name.MonitorPriceDecrease',
        [
          'Channel.Setting.Description.MonitorPriceDecrease.1',
          'Channel.Setting.Description.MonitorPriceDecrease.2',
          'Channel.Setting.Description.MonitorPriceDecrease.3'
        ],
        () =>
          RenderSettingSwitchTwoOptions(
            valueMonitorPriceDecrease,
            valuePriceDecreasePercentage,
            settingMonitorPriceDecrase,
            settingPriceDecreasePercentage,
            'Channel.Setting.Name.MonitorPriceDecrease.NoLimit',
            'Channel.Setting.Name.MonitorPriceDecrease.Limit',
            onChangeMonitorPriceDecrease,
            onChangePriceDecreasePercentage,
            false,
            30
          )
      )}
      {RenderRow('Channel.Setting.Name.MinQuantity', 'Channel.Setting.Description.MinQuantity', () =>
        RenderNumber(valueMinQuantity, settingMinQuantity, onChangeMinQuantity, 0)
      )}
      {IsEbay(platform) &&
        RenderRow('Channel.Setting.Name.PolicyDispatchDays', 'Channel.Setting.Description.PolicyDispatchDays', () =>
          RenderNumber(valueDispatchDays, settingDispatchDays, onChangeDispatchDays, 0)
        )
      }
      {AmazonSourceIds.includes(sourceId) &&
        RenderRow('Sources.Setting.Name.OnlyPrime', 'Sources.Setting.Description.OnlyPrime', () =>
          RenderTriBoolean(valueOnlyPrime, settingOnlyPrime, onChangeOnlyPrime)
        )}
    </div>
  );
};
