import { CalendarOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { T } from '../../utils/transShim';
import { DateRangeModal, DateRangeModalProps } from './date/date-range-modal';
import { Range, RangeKeyDict } from 'react-date-range';
import moment from 'moment';
import { addDays } from 'date-fns';

interface Props extends Omit<DateRangeModalProps, 'defaultRanges' | 'onChange' | 'visible'> {
  defaultRange?: Range;
  onChange?: (value: Range | null) => void;
  clearable?: boolean;
}

const _c = new Date();
const todayStart = new Date(
  _c.getFullYear(),
  _c.getMonth(),
  _c.getDate(),
  0,
  0,
  0,
  0
);
const todayEnd = new Date(
  _c.getFullYear(),
  _c.getMonth(),
  _c.getDate(),
  23,
  59,
  59,
  99
);

export const DateRangeInput = (props: Props) => {
  const { defaultRange, onChange, clearable, className, timePicker } = props;

  const [modalOpen, SetModalOpen] = useState<boolean>(false);
  const [currentRange, SetCurrentRange] = useState<Range | undefined>(defaultRange);

  const HandleOk = () => SetModalOpen(false);
  const HandleCancel = () => SetModalOpen(false);
  const HandleChange = (rangesByKey: RangeKeyDict) => {
    const range = rangesByKey.selection;
    SetCurrentRange(range);
    onChange?.(range);
  };
  const HandleClear = () => {
    SetModalOpen(false);
    SetCurrentRange(undefined);
    onChange?.(null);
  };

  const RenderInput = () => {
    if (currentRange == null)
      return <T k='Setting.NoDatesSelected' />;

    const format = timePicker == null ? 'L' : 'L LT';
    return <T k='Setting.FromTo' values={{
      from: moment(currentRange.startDate).local().format(format),
      to: moment(currentRange.endDate).local().format(format)
    }} />;
  };

  return (<>
    <div className={'date-picker ' + className} onClick={() => SetModalOpen(true)}>
      <h4>
        {RenderInput()}
      </h4>{' '}
      <CalendarOutlined />
    </div>
    <DateRangeModal
      visible={modalOpen}
      onOk={HandleOk}
      onCancel={HandleCancel}
      onChange={HandleChange}
      onClear={HandleClear}
      clearable={clearable}
      timePicker={timePicker}
      defaultRanges={[
        {
          startDate: todayStart,
          endDate: addDays(todayEnd, 7),
          key: 'selection',
          ...currentRange
        }
      ]}
    />
  </>);

};