import { ReactNode } from 'react';
import { Select } from 'antd';
import '../../sass/selector.scss';

const { Option, OptGroup } = Select;
export type SelectorSizeType = 'large' | 'small' | 'middle';

export type SelectorLabel = ReactNode;
export type SelectorValue = React.Key | null;
export interface SelectorData {
  value: SelectorValue;
  label: SelectorLabel;
  title?: string;
  options?: SelectorData[];
}

interface Props extends Omit<React.ComponentProps<typeof Select>, 'onChange' | 'virtual' | 'labelInValue' | 'value' | 'defaultValue' | 'children'> {
  children: SelectorData | SelectorData[];
  defaultValue?: SelectorValue;
  onChange?: (value: SelectorValue) => void;
  value?: SelectorValue;
  virtualScroll?: boolean;
}

export const Selector = (props: Props) => {
  const {
    children,
    className,
    placeholder,
    defaultValue,
    value,
    onChange,
    virtualScroll
  } = props;

  const childrens = (Array.isArray(children) ? children.filter(x => x != null) : [children]);

  const RenderOption = (c: SelectorData, i: string) => {
    if (c?.options != null) {
      return (
        <OptGroup key={'g_' + i} label={c.label} title={c.title}>
          {c.options.map((x, ind) => <>{RenderOption(x, i + '_' + ind)}</>)}
        </OptGroup>
      );
    } else {
      return (
        <Option key={'o_' + i} value={c?.value} label={c.label} title={c.title}>
          {c?.label}
        </Option>
      );
    }
  };
  const options = childrens?.map((x, i) => RenderOption(x, i.toString()));

  const OnChange = (val: SelectorData) => {
    if (onChange) {
      onChange(val?.value);
    }
  };

  const dv = childrens.find(x => x?.value == defaultValue);
  const v = childrens.find(x => x?.value == value);
  const placeholderU = (!dv && !v) ? {
    value: '',
    label: placeholder
  } as SelectorData : undefined;

  const pProps = { ...props, virtualScroll: undefined };
  delete pProps['virtualScroll'];
  return (
    <Select
      {...pProps}
      virtual={virtualScroll}
      labelInValue={true}
      className={'selector ' + (className ?? '')}
      onChange={OnChange}
      placeholder={placeholder}
      defaultValue={dv ?? placeholderU}
      value={v}
      filterOption={(input, option) => (option?.title) ? (option.title?.toLowerCase()?.toString()?.indexOf(input?.toLowerCase()) > -1) : (option?.label?.toLowerCase()?.toString()?.indexOf(input?.toLowerCase()) ?? -1) > -1}
    >
      {options}
    </Select>
  );
};