import { Card, Button, Spin, Divider } from 'antd';
import tokenCoin from '../../assets/icons/token2_0.png';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import '../../sass/buy-tokens.scss';
import { T } from '../../utils/transShim';
import { ePlatform, GetPlatform } from '../../data/platforms';
import { ReactUtils } from '../../utils/react-utils';
import { PopupModal } from './popup-modal';
import { HelpTooltip } from '../help-tooltip';
import { eCurrency } from '../../types/eCurrency';
import { A } from '../A';
import { Selector, SelectorValue } from '../form/selector';
import { useEffect, useState } from 'react';
import { Subscription } from '../../redux/subscriptions/subsSlice';
import { getServices } from '../../redux/subscriptions/subsThunk';
import FormItem from 'antd/es/form/FormItem';


interface Props {
  open: boolean;
  onClose: () => void;
}
type TokenData = Subscription & {
  productOrder: number;
};
export const BuyTokensModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const channel = ReactUtils.GetSelectedChannel();

  const { services, loading } = useAppSelector((state) => state.services);

  useEffect(() => {
    if (services == null || services.length == 0)
      dispatch(getServices());
  }, [getServices]);

  const currency = [
    { label: 'EUR \u20AC', value: eCurrency.EUR },
    //{ label: 'USD \u0024', value: eCurrency.USD },
    { label: 'GBP \u00A3', value: eCurrency.GBP }
  ];
  const [currencyId, SetCurrencyId] = useState<eCurrency>(eCurrency.EUR);
  const HandleCurrencyChange = (value: SelectorValue) => {
    SetCurrencyId(value as eCurrency);
    localStorage.setItem('currencyId', value?.toString() ?? 'EUR');
  };

  const data = services?.filter(x => {
    if (x.type == 3)
      return x;
  })?.reduce((prev, x) => prev.concat(
    x.prices
      .filter(y => y.currencyId == currencyId && y.platformId == 2)
      .map(y => ({ ...y, productOrder: x.productOrder }))
  ), [] as TokenData[]) ?? [];

  return (
    <PopupModal open={props.open} maxWidth={800} style={{ top: 20 }} onClose={props.onClose}>
      <div className="buy-tokens-container">
        <div className="title-section">
          <h3>
            <T k="BuyTokens.Title" />
          </h3>
          <A to="https://hustlegotreal.com/en/hustle-got-real-ai-powered-tokens/" target="_blank" rel="noreferrer">
            <HelpTooltip>Click to read more about AI Optimization</HelpTooltip>
          </A>
        </div>
        <Divider />
        <div className="text-container">
          <p className="buy-tokens-sub-title">
            <T k="BuyTokens.Subtitle" />
          </p>

          <p className="buy-tokens-desc">
            <T k="BuyTokens.Paragraph" />
          </p>

          <ul className="benefits">
            <li>
              <T k="Publish.Optimize.Explanation.1" />
            </li>
            <li>
              <T
                k="Publish.Optimize.Explanation.2"
                values={{ searchEngineName: GetPlatform(channel?.channelId ?? ePlatform.eBay).searchEngineName }}
              />
            </li>
            <li>
              <T k="Publish.Optimize.Explanation.3" />
            </li>
            <li>
              <T k="Publish.Optimize.Explanation.4" />
            </li>
            <li>
              <T k="Publish.Optimize.Explanation.5" />
            </li>
            <li>
              <T k="Publish.Optimize.Explanation.6" />
            </li>
          </ul>
        </div>
        <FormItem>
          <Selector value={currencyId} loading={false} onChange={HandleCurrencyChange} className="selector-currency">
            {currency}
          </Selector>
        </FormItem>
        <div className="cards-container">
          {loading && <Spin tip="Please wait..." spinning={loading} style={{ position: 'absolute', left: '45%', top: '5%' }} />}
          {data.map((d) => (
            <Card key={d.id} className="card">
              <div className="card-info">
                <p className="tokens-count">
                  <strong>{d.productOrder} Tokens</strong>
                </p>
                <img src={tokenCoin} alt="coin img" className="coin" />
                <p className="euros-amount">
                  {ReactUtils.DrawPrice(d.price, currencyId)}
                </p>
                <p className="cents-amount">{(d.price / d.productOrder).toFixed(2)}/token</p>
                <A to={'https://app.hustlegotreal.com/listingservice?selectedCurrency='+currencyId+'&selectedProduct='+d.productId+'&selectedBillingPeriod=3'}>
                  <Button className="buy-btn" htmlType="submit">
                    {<T k='Buy Now' />}
                  </Button>
                </A>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </PopupModal>
  );
};