import { Checkbox, DatePicker, DatePickerProps, Divider, InputNumber, Spin, Switch } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { ePlatform, GetPlatform } from '../../data/platforms';
import { T } from '../../utils/transShim';
import { SuccessBtn } from '../ActionBtns';
import { HelpTooltip } from '../help-tooltip';
import { TokensCosts } from '../Tokens';
import { PopupModal } from './popup-modal';
import '../../sass/small-components/masive-publish-modal.scss';
import { useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { Selector, SelectorValue } from '../form/selector';

type Props = {
  open: boolean,
  setOpen: (b: boolean) => void;
  loading?: boolean;
  platform: ePlatform;
  productsInCart: number;
  showPublishVariants: boolean;
  onSubmit: (checkDate: boolean, frequencyData: number | undefined, needsReview: boolean, optimizeTitle: boolean, optimizeDescription: boolean, publishVariations: boolean, newDate: Date | undefined) => void;
  otpimizeByDefault: boolean;
  variationsByDefault: boolean;
}

export const MasivePublishModal = (props: Props) => {
  const { open, setOpen, loading, platform, productsInCart, onSubmit, showPublishVariants, otpimizeByDefault, variationsByDefault } = props;

  const { tokens } = useAppSelector((state) => state.user);

  const [optimize, setOptimize] = useState<boolean>((tokens ?? 0) >= productsInCart);
  const [optimizeTitle, setOptimizeTitle] = useState<boolean>((tokens ?? 0) >= productsInCart);
  const [optimizeDescription, setOptimizeDescription] = useState<boolean>((tokens ?? 0) >= (productsInCart * 3));

  const [frequency, setFrequency] = useState<boolean>(false);
  const [publishVariations, setPublishVariations] = useState<boolean>(variationsByDefault && showPublishVariants);
  const [newDate, setNewDate] = useState<Date>(new Date());
  const [frequencyNumber, SetFrequencyNumber] = useState<number>();
  const [frequencyUnits, SetFrequencyUnits] = useState<number>(1);//1 minutes, 60 hours, 1440 days

  const [needsReview, setNeedsReview] = useState<boolean>(false);

  const tokenCost = (optimizeTitle ? productsInCart : 0) + ((optimizeDescription ? productsInCart * 2 : 0));

  useEffect(() => {
    if (!open)
      return;
    setOptimize(otpimizeByDefault ? ((tokens ?? 0) >= productsInCart) : false);
    setOptimizeTitle(otpimizeByDefault ? ((tokens ?? 0) >= productsInCart) : false);
    setOptimizeDescription(otpimizeByDefault ? ((tokens ?? 0) >= (productsInCart * 3)) : false);
  }, [open]);

  const HandleDateOnChange: DatePickerProps['onChange'] = (dateString) => {
    setNewDate(moment.utc(dateString).toDate());
  };

  const HandleSetFrequencyNumber = (value: number | null) => {
    SetFrequencyNumber(value ?? 0);
    newDate ? setNewDate(newDate) : setNewDate(new Date());
  };

  const HandleSubmit = () => onSubmit(frequency, frequencyNumber != null ? (frequencyNumber * frequencyUnits) : undefined, needsReview, optimize && optimizeTitle, optimize && optimizeDescription, publishVariations, newDate);

  const OptimizeDescriptionHandleCheck = (e: CheckboxChangeEvent) => {
    setOptimizeDescription(e.target.checked);
  };
  const OptimizeTitleHandleCheck = (e: CheckboxChangeEvent) => {
    setOptimizeTitle(e.target.checked);
  };
  const HandleOptimize = () => {
    if (!optimize) {
      setOptimizeDescription(true);
      setOptimizeTitle(true);
    } else {
      setOptimizeDescription(false);
      setOptimizeTitle(false);
    }
    setOptimize(!optimize);
  };

  const HandleFrequencyUnitsChange = (value: SelectorValue) => SetFrequencyUnits(value as number);

  return <PopupModal
    open={open}
    onClose={() => setOpen(!open)}
    maxWidth={600}
    style={{ overflowY: 'scroll' }}
    showCloseX={false}
    footer={null}
  >
    <div className="catalog-list-modal">
      <h1><T k='Publish.Settings.Title'/></h1>
      <Divider />
      {loading ? (
        <div style={{ minWidth: '100%' }}>
          <Spin style={{ margin: '30% 50%' }} />
        </div>
      ) : (
        <>
          <div className="under-sections">
            <div className="section-option-container">
              <div className="section-option">
                <div className="section-switch">
                  <Switch checked={optimize} onChange={HandleOptimize} />
                </div>
                <div className="section-title-container">
                  <h2>
                    <T k='Publish.Optimize.Question' />
                    <HelpTooltip>
                      <ul className='onnly-bullets'>
                        <li><T k='Publish.Optimize.Explanation.1' /></li>
                        <li><T k='Publish.Optimize.Explanation.2' values={{ searchEngineName: GetPlatform(platform).searchEngineName }} /></li>
                        <li><T k='Publish.Optimize.Explanation.3' /></li>
                        <li><T k='Publish.Optimize.Explanation.4' /></li>
                        <li><T k='Publish.Optimize.Explanation.5' /></li>
                        <li><T k='Publish.Optimize.Explanation.6' /></li>
                      </ul>
                    </HelpTooltip>
                  </h2>
                </div>
              </div>
              {optimize && (
                <div className="section-explanation">
                  <div className="section-option">
                    <Checkbox checked={optimizeTitle} onChange={OptimizeTitleHandleCheck} />
                    <T k='Publish.Optimize.Title' />
                    <TokensCosts cost={1} />
                  </div>
                  <div className="section-option">
                    <Checkbox checked={optimizeDescription} onChange={OptimizeDescriptionHandleCheck} />
                    <T k='Publish.Optimize.Description' />
                    <TokensCosts cost={2} />
                  </div>
                  <T k='Publish.Optimize.TotalCost' values={{quantity: tokenCost}}/>
                </div>
              )}
            </div>
            <div className="section-option-container">
              <div className="section-option">
                <div className="section-switch">
                  <Switch checked={frequency} onChange={() => setFrequency(!frequency)} />
                </div>
                <div className="section-title-container">
                  <h2><T k='Publish.Frequency.Question'/></h2>
                </div>
              </div>
              {frequency && (
                <div className="section-explanation">
                  <div className="frequency-container">
                    <div className="select-date-container">
                      <h3><T k='Publish.Frequency.SelectDate' /></h3>
                      <p><T k='Publish.Frequency.SelectDate.Explanation' /></p>
                      <DatePicker showTime={true} className="date-picker" onChange={HandleDateOnChange} />
                    </div>
                    <Divider className="divider" type="vertical" />
                    <div className="listings-frequency-container">
                      <h3><T k='Publish.Frequency.ListFrequency'/></h3>
                      <p><T k='Publish.Frequency.Explanation' /></p>
                      <div className="frequency-n">
                        <InputNumber
                          className="blue-input"
                          value={frequencyNumber}
                          placeholder='0'
                          onChange={HandleSetFrequencyNumber}
                        />
                        <Selector value={frequencyUnits} onChange={HandleFrequencyUnitsChange}>
                          {[{ label: 'minutes', value: 1 }, { label: 'hours', value: 60 }, { label: 'days', value: 1440 }]}
                        </Selector>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="section-option-container">
              <div className="section-option">
                <div className="section-switch">
                  <Switch checked={needsReview} onChange={() => setNeedsReview(!needsReview)} />
                </div>
                <div className="section-title-container">
                  <h2>
                    <T k='Publish.Review.Question'/>
                    <HelpTooltip><T k='Publish.Review.Help'/></HelpTooltip>
                  </h2>
                </div>
              </div>
            </div>
            {showPublishVariants && <>
              <div className="section-option-container">
                <div className="section-option">
                  <div className="section-switch">
                    <Switch checked={publishVariations} onChange={() => setPublishVariations(!publishVariations)} />
                  </div>
                  <div className="section-title-container">
                    <h2>
                      <T k='Publish.Variations.Question' />
                      <HelpTooltip><T k='Publish.Variations.Help' /></HelpTooltip>
                    </h2>
                  </div>
                </div>
              </div>
            </>}
            <div className="list-button">
              <SuccessBtn onClick={HandleSubmit}>
                <T k='Publish.Button.Publish' values={{ quantity: productsInCart ?? 0 }} />
              </SuccessBtn>
            </div>
          </div>
        </>
      )}
    </div>
  </PopupModal>;
};