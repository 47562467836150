import { Card } from 'antd';

import { AllServicesData } from './ServicesData';

import '../../sass/services/all-services.scss';
import { ReactUtils } from '../../utils/react-utils';
import { ePlatform } from '../../data/platforms';
import { A } from '../../small-components/A';
export const Services = () => {
  const channel = ReactUtils.GetSelectedChannel();
  return (
    <div className="services-container">
      <div className="services-content">
        {AllServicesData(channel?.channelId ?? ePlatform.eBay)
          .filter((s) => channel?.channelId !== 4 || s.id !== 7)
          .map((s) => (
            <Card key={s.id} className="service">
              <div className="img-container">{<img src={s.image} className="service-img" />}</div>
              <div className="card-info">
                <h1 className="service-title">{s.title}</h1>
                <p>{s.shortDescription}</p>
                <A to={s.link}>Read more</A>
              </div>
            </Card>
          ))}
      </div>
    </div>
  );
};
