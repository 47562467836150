import React from 'react';
import { Switch } from 'antd';

export interface FieldCheckProp {
  labelOn: string | React.ReactNode;
  labelOff: string | React.ReactNode;
  value: boolean;
  description: React.ReactNode;
  onChange: (newValue: boolean) => void;
}
const FieldCheck = (props: FieldCheckProp) => {
  const { labelOn, labelOff, value, onChange, description } = props;

  const HandleOnChange = (checked: boolean) => {
    onChange(checked);
  };

  return (<>
    <div className="option-account">
      <div className="option-account-in">
        <div className="param-container">
          <h4 className='param-title'>{value ? labelOn : labelOff}</h4>
        </div>

        <div
          className="custom-control d-flex align-items-center switchbox custom-switch px-2"
          style={{ marginLeft: '20px' }}
        >
          <label className="switch-toggle">
            <Switch className="input-toggle-switch" onChange={HandleOnChange} checked={value ?? false} />
          </label>
        </div>
      </div>
      {description && <span className='param-description'>{description}</span>}
    </div>
  </>);
};
export default FieldCheck;
