import { ReactNode, useEffect } from 'react';
import { Divider, Row, Tabs } from 'antd';
import { T, TransUtils } from '../../utils/transShim';
import '../../sass/settings/settings-panel.scss';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { loadBusinessPolicies, loadShipping, refreshBusinessPolicies, } from '../../redux/channel-configuration/channels-configuration-thunk';
import { SettingDataBag, SettingInput } from './setting-input';
import { ReactUtils } from '../../utils/react-utils';
import { GetPlatform } from '../../data/platforms';
import { getTemplates } from '../../redux/templates/templatesThunk';
import { SettingData, SettingExtra, SettingIncodeComponent, SettingInfo, SettingKey, SettingSectionId, SettingSectionsInfo, SettingValue } from '../../types/settings';
import { ChannelSettingSection } from '../../components/chanel/configuration/sections';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Links } from '../../links';
import { SettingBase } from './setting-base';

interface Props {
  Sections?: SettingSectionsInfo[];
  SettingsData?: SettingData[] | null;
  SettingsInfo: SettingInfo[];
  OnSaveSetting: (key: SettingKey, value: SettingValue) => void;
  SettingsBeingSaved: Set<SettingKey>;
  Loading: boolean;
  SuperiorData?: SettingData[] | null;
  SuperiorRelation?: Map<SettingKey, SettingKey>;
  IncodeComponents?: SettingIncodeComponent[]
}

export const SettingsPanel = (props: Props) => {
  const { Sections, SettingsData, SettingsInfo, OnSaveSetting, SettingsBeingSaved, Loading, SuperiorData, SuperiorRelation, IncodeComponents } = props;

  const selectedChannel = ReactUtils.GetSelectedChannel();
  const platformInfo = GetPlatform(selectedChannel?.channelId);
  const translationValues = { ...TransUtils.GetLinksValues(), ...TransUtils.GetPlatformValues(platformInfo) };
  const activeTab = (() => {
    //this routeMatch has to be done always or react will cry
    //stupid design I know it, but... react
    const isPM = useRouteMatch(Links.Configuration.Channel.Monitoring);
    const ispP = useRouteMatch(Links.Configuration.Channel.Product);
    const isIB = useRouteMatch(Links.Configuration.Channel.Business);
    const isIN = useRouteMatch(Links.Configuration.Channel.Notifications);
    const isIO = useRouteMatch(Links.Configuration.Channel.Channel);
    if (isPM)
      return ChannelSettingSection.Monitoring;
    if (ispP)
      return ChannelSettingSection.Product;
    if (isIB)
      return ChannelSettingSection.Business;
    if (isIN)
      return ChannelSettingSection.Notifications;
    if (isIO)
      return ChannelSettingSection.Channel;
    return ChannelSettingSection.Monitoring;
  })();
  const sections = Sections?.filter(x => !x.ChannelIds || x.ChannelIds.includes(selectedChannel?.channelId ?? 0));

  const bag: SettingDataBag = { selectedChannel };

  const superiorDataDic = new Map<SettingKey, SettingValue>(SuperiorData?.map(x => [x.key, x.value]));
  //Load from api------------------------------------------------------------
  const dispatch = useAppDispatch();
  const {
    refreshBusinessInProgress,
    refreshBusinessLoading,
    businessPolicies,
    loadingBusiness,
    loadingShipping,
    shipping
  } = useAppSelector((state) => state.channelConfiguration);

  const LoadTemplate = () => {//We can do this inside an if because ChannelSettings doesn't change
    const {
      templates,
      loading: loadingTemplates
    } = useAppSelector((state) => state.templates);

    bag.templates = {
      loading: loadingTemplates,
      data: templates
    };

    useEffect(() => {
      dispatch(getTemplates());
    }, [getTemplates]);
  };

  const LoadPolicies = () => {
    bag.refreshBussiness = {
      loading: refreshBusinessLoading,
      data: refreshBusinessInProgress
    };
    bag.business = {
      data: businessPolicies,
      loading: loadingBusiness
    };
    bag.shipping = {
      data: shipping,
      loading: loadingShipping
    };
    useEffect(() => {
      dispatch(loadShipping());
      dispatch(loadBusinessPolicies());
    }, [loadShipping, loadBusinessPolicies]);
  };

  {
    const allExtras = ([] as SettingExtra[]).concat.apply([], SettingsInfo.filter(x => !!x.Extra).map(x => x.Extra ?? SettingExtra.BusinessPayment));
    let policies = false;
    let templates = false;
    for (const e of allExtras ?? []) {
      switch (e) {
        case SettingExtra.TemplateList:
          templates = true;
          break;
        case SettingExtra.BusinessPayment:
        case SettingExtra.BusinessReturn:
        case SettingExtra.BusinessShipping:
        case SettingExtra.PolicyDelivery:
        case SettingExtra.RefreshPolicies:
          policies = true;
          break;
      }
    }
    if (templates) {
      LoadTemplate();
    }
    if (policies) {
      LoadPolicies();
    }
  }
  //---------------------------------------------------------------------

  const OnButtonClick = async (setting: SettingInfo) => {
    for (const e of setting.Extra ?? []) {
      switch (e) {
        case SettingExtra.RefreshPolicies:
          dispatch(refreshBusinessPolicies());
          break;
      }
    }
  };

  const configuration = new Map(SettingsData?.map(x => [x.key, x.value]) ?? []);

  const RenderSetting = (setting: SettingInfo) => {
    return <SettingInput
      key={setting.Fields[0] ?? ('_' + setting.Extra?.join('_'))}
      setting={setting}
      settingsBeingSaved={SettingsBeingSaved}
      currentSettingValues={configuration}
      onSave={OnSaveSetting}
      translationValues={translationValues}
      dataBag={bag}
      onButtonClick={() => OnButtonClick(setting)}
      superiorData={superiorDataDic}
      superiorRelation={SuperiorRelation}
    />;
  };
  const RenderSubSection = (name: string, settings: SettingInfo[]) => {
    return <>
      {!!name && name != '_' && <Divider orientation="left" orientationMargin="0" plain><T k={name} /></Divider>}
      {settings.map(x => RenderSetting(x))}
    </>;
  };
  const RenderIncodeComponetns = (inComp: SettingIncodeComponent, i: number) => {
    return <SettingBase
      key={i}
      name={inComp.name}
      description={inComp.description}
      component={inComp.component}
    />;
  };

  const RenderSettings = (section: SettingSectionId): ReactNode => {
    const settings = SettingsInfo.filter(
      x => (
        (sections?.length ?? 0) == 0 || x.Section == section)
        && (!x.ChannelIds || x.ChannelIds.includes(selectedChannel?.channelId ?? 0))
    );
    const innComps = IncodeComponents?.filter(
      x => (
        (sections?.length ?? 0) == 0 || x.section == section)
    );
    const bySubSection = new Map <string, SettingInfo[]>();
    for (const ss of settings) {
      const key = ss.Subsection ?? '_';
      if (!bySubSection.has(key)) {
        bySubSection.set(key, []);
      }
      bySubSection.get(key)?.push(ss);
    }
    return <>
      {[...bySubSection.entries()].map(x => RenderSubSection(x[0],x[1]))}
      {innComps && innComps.map((x,i) => RenderIncodeComponetns(x,i))}
    </>;
  };

  const RenderContent = (index: SettingSectionId): ReactNode => RenderSettings(index);

  const loading = Loading || !SettingsData;

  const history = useHistory();

  const HandleChangeTab = (vs: string): void => {
    const v = parseInt(vs) as SettingSectionId;
    switch (v) {
      default:
      case ChannelSettingSection.Monitoring:
        history.push(Links.Configuration.Channel.Monitoring);
        break;
      case ChannelSettingSection.Product:
        history.push(Links.Configuration.Channel.Product);
        break;
      case ChannelSettingSection.Business:
        history.push(Links.Configuration.Channel.Business);
        break;
      case ChannelSettingSection.Notifications:
        history.push(Links.Configuration.Channel.Notifications);
        break;
      case ChannelSettingSection.Channel:
        history.push(Links.Configuration.Channel.Channel);
        break;
    }
  };

  return (
    <div className='settings-panel'>
      {(sections?.length ?? 0) > 0 &&
        <Tabs
          activeKey={activeTab.toString()}
          onChange={HandleChangeTab}
          items={(!loading ?
            (
              sections?.map(x => ({
                label: <T k={x.Label}/>,
                key: x.Type.toString()
              })) ?? []
            )
            :
            []
          )}
        />
      }
      <Row className="content">
        {!loading && RenderContent(activeTab)}
      </Row>
    </div>
  );
};
