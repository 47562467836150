import { ClockCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { ePlatform, GetPlatform } from '../data/platforms';
import { eChannelListingStatus } from '../redux/listings/listings-slice';
import { PriceRule } from '../redux/pricing-rules/rulesSlice';
import { ReactUtils } from './react-utils';
import { T } from './transShim';

export const ListingsUtils = {
  GetMarkupFromPricingRules: (sourcePrice: number, rules: PriceRule[]) => {
    let newMarkup: number | null = null;
    for (const pr of rules) {
      if (!pr.active) continue;

      if (pr.priceTo != null) {
        if (pr.priceFrom != null) {
          if (pr.priceFrom <= sourcePrice && sourcePrice <= pr.priceTo) {
            newMarkup = pr.markup;
          }
        } else {
          if (sourcePrice <= pr.priceTo) {
            newMarkup = pr.markup;
          }
        }
      } else {
        if (pr.priceFrom != null) {
          if (pr.priceFrom <= sourcePrice) {
            newMarkup = pr.markup;
          }
        } else {
          newMarkup = pr.markup;
        }
      }
    } //Last pricingrule will rule
    return newMarkup; //If no pricingrule has been applied, we return null
  },

  StatusToUI: (status: eChannelListingStatus, channelId: ePlatform | undefined, dontListUntil: Date | string | undefined) => {
    const errorStaus = eChannelListingStatus.PermanentFailure | eChannelListingStatus.TemporaryFailure | eChannelListingStatus.ExceptionThrown | eChannelListingStatus.InvalidUserCredentials;
    const bulkStatus = eChannelListingStatus.PendingForScraping | eChannelListingStatus.BulkApiCreated | eChannelListingStatus.BULK | eChannelListingStatus.BulkScraping;
    const listingStatus = eChannelListingStatus.PreparedForFirstListing | eChannelListingStatus.QueuedForWork | eChannelListingStatus.Retrying | eChannelListingStatus.ListingInStore | eChannelListingStatus.RetryingTwice | eChannelListingStatus.RetryingFinal | eChannelListingStatus.CreatingListing;
    const weirdStatus = eChannelListingStatus.ImportedWaitingForChannelData | eChannelListingStatus.ListingVariation;

    if (status & errorStaus)
      return <T k='Listings.Status.Failure' />;

    if (status & weirdStatus)
      return <></>;

    if (status & eChannelListingStatus.PendingForRelist)
      return <>
        <LoadingOutlined />{' '}
        <T k='Listings.Status.Relisting' />
      </>;

    if (status & eChannelListingStatus.Relisted)
      return <T k='Listings.Status.Relisted' />;

    if (status & eChannelListingStatus.Terminated)
      return <T k='Listings.Status.Terminated' />;

    if (status == eChannelListingStatus.Unknown)
      return <T k='Listings.Status.Unknown' />;

    if (status & bulkStatus) 
      return (
        <>
          <LoadingOutlined />&nbsp;
          <T k='Listings.Status.Procesing' />
        </>
      );

    if (status & listingStatus) {
      if (
        dontListUntil && (new Date(dontListUntil).getTime() - new Date().getTime()) / 60000 > 5
      ) {
        //5 minutes
        return (
          <>
            <ClockCircleOutlined />&nbsp;
            <T k='Listings.Status.Scheduled' />&nbsp;
            {ReactUtils.GetFormattedDateTime(dontListUntil)}
          </>
        );
      } else {
        return <Space>
          <LoadingOutlined />
          <T k='Listings.Status.ListingOn' values={{ channel: GetPlatform(channelId).storeName }} />
        </Space>;
      }
    }

    if (status & eChannelListingStatus.PendingToReview)
      return <T k='Listings.Status.PendingToReview' />;

    if (status & eChannelListingStatus.Removed)
      return <T k='Listings.Status.Removed' />;

    return <>{eChannelListingStatus[status]}</>;
  },

  CalculateMarkupFromProfit: (buyPrice: number, fee: number, profit: number) => {
    //SP = sellprice, M = markup/100
    //
    //SP - SP*fee - BP = profit
    //SP = BP*(1 + M)
    //-----------------------------------
    //BP * (1 + M) - BP * (1 + M) * fee - BP = profit
    //BP * (1 + M) * (1 - fee) = profit + BP
    //(1 + M) = (profit + BP) / (BP * (1 - fee))
    //M = [ (profit + BP) / (BP * (1 - fee)) ] - 1
    //---------------------------------

    return Math.round(
      100 * //M*100
      ((profit + buyPrice) / (buyPrice * (1 - fee / 100)) - 1)
    );
  },

  CalculateSellPriceFromMarkup: (buyPrice: number, markup: number, roundTo99: boolean)=> {
    let price = buyPrice * (1 + markup / 100);

    if (roundTo99) {
      if (price > 20) price = Math.round(price) - 0.01;
      //Ends in .99
      else price = Math.ceil(price) - 0.01; //Ends in .99
    } else {
      price = Math.round(price * 100) / 100;
    }

    return price;
  },
  CalculateProfitFromSellPrice: (buyPrice: number, fee: number, sellPrice: number) => {
    return Math.round((sellPrice - buyPrice - sellPrice * (fee / 100)) * 100) / 100;
  },
  CalculateMarkupFromSellPrice: (buyPrice: number, sellPrice: number) => {
    return Math.round((100 * sellPrice) / buyPrice - 100);
  },
  CalculateSellPriceFromProfit: (buyPrice: number, profit: number, fee: number, roundTo99: boolean) => {
    //sellPrice - buyprice - sellPrice * (fee / 100) = profit
    //(1 - (fee / 100)) * sellPrice = profit + buyPrice;
    let price = (profit + buyPrice) / (1 - (fee / 100));

    if (roundTo99) {
      if (price > 20) price = Math.round(price) - 0.01;
      //Ends in .99
      else price = Math.ceil(price) - 0.01; //Ends in .99
    } else {
      price = Math.round(price * 100) / 100;
    }

    return price;
  }
};