import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../client';

export const getNoApiServers = createAsyncThunk(
  'dashboard-no-api-servers/getNoApiServers',
  async (_, { rejectWithValue } /* destructured thunkAPI's prop */) => {
    try {
      const res = await client.get('/Dashboard/GetNoApiServers');
      return res.data.response_data;
    } catch (error) {
      return rejectWithValue('Sorry! Something went wrong');
    }
  }
);

export const getSolutionState = createAsyncThunk(
  'dashboard-no-api-servers/getSolutionState',
  async (id: number, { rejectWithValue } /* destructured thunkAPI's prop */) => {
    try {
      const res = await client.get('/Dashboard/SolutionState?id=' + id);
      return res.data.response_data;
    } catch (error) {
      return rejectWithValue('Sorry! Something went wrong');
    }
  }
);

export const getRequestCode = createAsyncThunk(
  'dashboard-no-api-servers/getRequestCode',
  async (id: number, { rejectWithValue } /* destructured thunkAPI's prop */) => {
    try {
      const res = await client.get('/Dashboard/RequestCode?id=' + id);
      return res.data.response_data;
    } catch (error) {
      return rejectWithValue('Sorry! Something went wrong');
    }
  }
);

export const getManagedServers = createAsyncThunk('manage/server', async (_, { rejectWithValue }) => {
  try {
    const res = await client.get('/NoApiServer/GetManagedServer');
    return res.data.response_data;
  } catch (error) {
    return rejectWithValue('Sorry! Something went wrong');
  }
});

export const SendCode = createAsyncThunk('Dashboard/SendCode', async (data: unknown, thunkAPI) => {
  try {
    const res = await client.post('/Dashboard/SendCode', data);
    return res.data.response_data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});

export const SendAnswer = createAsyncThunk('Dashboard/SendAnswer', async (data: unknown, thunkAPI) => {
  try {
    const res = await client.post('/Dashboard/SendAnswer', data);
    return res.data.response_data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});

export const UpdateManagedServers = createAsyncThunk('manage/server', async (newArray: unknown, thunkAPI) => {
  try {
    const res = await client.post('/NoApiServer/UpdateManagedServer', newArray);
    return res.data.response_data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
