import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CKEditor, CKEditorEventHandler } from 'ckeditor4-react';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { getTemplates, updateTemplate } from '../../redux/templates/templatesThunk';
import { Template } from 'src/redux/templates/templatesSlice';
import { SuccessBtn } from 'src/small-components/ActionBtns';
import '../../sass/templates.scss';
import { Links } from '../../links';
import { LeftOutlined } from '@ant-design/icons';
import { A } from '../../small-components/A';

interface Params {
  id: string | undefined;
}

export const EditTemplate = () => {
  const dispatch = useAppDispatch();
  const [currentTemplate, setCurrentTemplate] = useState<string>('');
  const { templates, loading } = useAppSelector((state) => state.templates);
  const [loaded, setLoaded] = useState<boolean>(false);
  const params: Params = useParams();
  useEffect(() => {
    dispatch(getTemplates());
  }, [getTemplates, updateTemplate]);

  useEffect(() => {
    if (!loading && templates.length > 0) {
      const id = params.id ?? window.location.pathname.split('/').pop();
      const result = templates.find((temp: Template) => temp.id === parseInt(id as string));
      setCurrentTemplate(result?.html ?? '');
      setLoaded(true);
    }
  }, [templates]);

  const handleUpdate = () => {
    dispatch(
      updateTemplate({
        id: parseInt(params.id ?? ''),
        html: currentTemplate
      })
    );
  };

  return (
    <div className="edit-templates-container">
      <A to={Links.Configuration.Templates.Main}>
        <span>
          <LeftOutlined style={{ fontSize: '19px' }} />
        </span>
        Back to Templates
      </A>
      <br />
      {!loaded ? (
        <Spin />
      ) : (
        <>
          <CKEditor<{ onChange: CKEditorEventHandler<'change'> }>
            initData={currentTemplate}
            config={{
              height: 420,
              allowedContent: true,
              versionCheck: false//to avoid messages like "you need to update, this is not a safe version"
            }}
            onChange={({ editor }) => {
              setCurrentTemplate(editor.getData());
            }}
            onKey={({ editor }) => {
              if (editor.mode == 'source') setTimeout((_) => setCurrentTemplate(editor.getData()), 100);
            }}
          />
          <div className="action-btns-container">
            <SuccessBtn onClick={handleUpdate}>Update</SuccessBtn>
          </div>
        </>
      )}
      <div className="explanation-section">
        <h2>How does the Template Editor work?</h2>
        <p>
          In order to structure the data in a user friendly way, there are different sections within the template. For example, text
          paragraphs will be displayed under the {"'"}Description{"'"} section, whereas technical details and specifications will show on a
          table.
        </p>
        <h2>What are those special tags, like _BEGIN_DESCRIPTION_SECTION_?</h2>
        <p>Our software automatically structures the data in several sections, depending on its format:</p>
        <ul>
          <li>Description</li>
          <li>Features</li>
          <li>Sections</li>
          <li>Table</li>
          <li>Images</li>
          <p>
            Each section must have an opening tag _BEGIN_sectionname_SECTION_, a closing tag _END_sectionname_SECTION_ and a tag to be
            replaced by the content itself _sectionname_CONTENT_
          </p>
          <p>
            If there is no content available for any of the sections, the software will automatically omit any code located between the
            BEGIN and END tags of the corresponding section.
          </p>
        </ul>
        <h2>Special Tags</h2>
        <p>The below tags don{"'"}t need any wrapping (unlike the above, where BEGIN and END are required) and can be used on their own.</p>
        <ul>
          <li>
            <strong>_TITLE_</strong> will be replaced with the title of your listing.
          </li>
          <li>
            <strong>_MAIN_IMAGE_</strong> contains the URL of the main image on your listing.
          </li>
        </ul>
        <h2>Can I remove an entire section?</h2>
        <p>Certain sections are mandatory: DESCRIPTION, FEATURES and SECTIONS. Other sections can be removed.</p>
      </div>
    </div>
  );
};
