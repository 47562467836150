import { createSlice } from '@reduxjs/toolkit';
import { eCurrency } from '../../types/eCurrency';
import { WithdrawalMethod } from './data/withdrawal-method';
import { GetPaymentMethods, GetPendingPayments, GetWithdrawalMethods, SaveWithdrawalMethod } from './paymentThunk';

export interface ConfigurePaymentMethodRequest {
  successUrl: string;
  cancelUrl: string;
  previousId?: number;
}

export enum ePaymentError {
  Unknown = 0,
  Declined = 1,
  Insufficient = 2,
  InvalidCard = 3,
  OtherBankError = 4
}

export interface PaymentMethodsDataMethod {
  id: number;
  last4: string;
  lastPaymentFailed: boolean;
  lastPaymentError: ePaymentError;
}

export interface GetCheckoutInformationRequest {
  sessionId: string;
  customerName: string;
}

export interface RemovePaymentRequest {
  id: number;
}

export interface SaveCurrenciesRequest {
  id: number;
  currencies: eCurrency[];
}

export interface RetryPaymentRequest {
  id: number;
  currency: eCurrency;
}

export interface CreateCheckoutSessionRequest {
  lineItems: LineItem[];
  mode: string; // payment, setup, subscription
  successUrl: string;
  cancelUrl: string;
  upgradingSubscription: boolean;
}

export interface CreateCheckoutSessionResponse {
  checkoutSessionId: string;
  alreadyDone: boolean;
}

export interface LineItem {
  platformProductId: string;
  quantity: number;
}

export interface GetPaymentMethodsResponseMethod {
  id: number;
  last4: string;
  currencies: eCurrency[];
  lastPaymentFailed: boolean;
  lastPaymentError: ePaymentError;
}

export type PaymentSliceType = {
  methods: GetPaymentMethodsResponseMethod[];
  pendingPayments?: { [id: number]: number };
  loading: boolean;
  error: string;
  widthdrawalMethods?: {
    loading?: boolean,
    saving?: boolean,
    methods?: WithdrawalMethod[]
  }
}

const initialState: PaymentSliceType = {
  methods: [],
  loading: false,
  error: '',
};


export const paymentSlice = createSlice({
  name: 'payment',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(GetPaymentMethods.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(GetPaymentMethods.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.methods = payload;
    });
    builder.addCase(GetPaymentMethods.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = String(payload);
    });

    builder.addCase(GetPendingPayments.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(GetPendingPayments.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.pendingPayments = payload;
    });
    builder.addCase(GetPendingPayments.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = String(payload);
    });

    builder.addCase(GetWithdrawalMethods.pending, (state) => {
      state.widthdrawalMethods = { ...state.widthdrawalMethods, loading:true};
    });
    builder.addCase(GetWithdrawalMethods.fulfilled, (state, { payload }) => {
      state.widthdrawalMethods = {
        ...state.widthdrawalMethods, 
        loading: false,
        methods: payload as WithdrawalMethod[]
      };
    });
    builder.addCase(GetWithdrawalMethods.rejected, (state) => {
      state.widthdrawalMethods = { ...state.widthdrawalMethods, loading: false };
    });


    builder.addCase(SaveWithdrawalMethod.pending, (state) => {
      state.widthdrawalMethods = { ...state.widthdrawalMethods, saving: true };
    });
    builder.addCase(SaveWithdrawalMethod.fulfilled, (state, { payload, meta }) => {
      const newMethods = [...(state.widthdrawalMethods?.methods ?? [])];
      const uploaded = meta.arg;
      if (payload as boolean) {
        let found = false;
        for (let i = 0; i < newMethods.length; i++) {
          if (newMethods[i].currency == uploaded.currency) {
            newMethods[i] = uploaded;
            found = true;
            break;
          }
        }
        if (!found) {
          newMethods.push(uploaded);
        }
      }
      state.widthdrawalMethods = {
        ...state.widthdrawalMethods, 
        saving: false,
        methods: newMethods
      };
    });
    builder.addCase(SaveWithdrawalMethod.rejected, (state) => {
      state.widthdrawalMethods = { ...state.widthdrawalMethods, saving: false };
    });

  }
});


export const { reducer: paymentReducer } = paymentSlice;
