import { Alert, Spin, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../custom-hooks/reduxCustomHooks';
import { eAccountSettings } from '../../../redux/account-configuration/account-configuration-slice';
import { getAccountConfiguration } from '../../../redux/account-configuration/account-configuration-thunk';
import { GetWithdrawalMethods, SaveWithdrawalMethod } from '../../../redux/payment/paymentThunk';
import { PrimaryBtn } from '../../../small-components/ActionBtns';
import { eCurrency } from '../../../types/eCurrency';
import { T, TransUtils } from '../../../utils/transShim';
import { SettingDataBag, SettingsValue, SettingType } from './generic/generic-setting-input';
import { eWithdrawalSettings, WithdrawalSetting, WithdrawalSettingInput } from './withdrawal/WithdrawalSettingInput';

interface Props {
  display: boolean;
}

type ConfigData = Map<eWithdrawalSettings, SettingsValue>;
type Configs = {
  [eCurrency.EUR]: ConfigData,
  [eCurrency.USD]: ConfigData,
  [eCurrency.GBP]: ConfigData,
};

export const Withdrawal = (props: Props) => {
  const { display } = props;

  const dispatch = useAppDispatch();

  const translationValues = { ...TransUtils.GetLinksValues() };

  const { widthdrawalMethods } = useAppSelector((state) => state.payment);
  const {
    settings: billingSettings,
    loading: settingsLoading,
  } = useAppSelector((state) => state.accountConfiguration);

  const [tab, SetTab] = useState<eCurrency>(eCurrency.GBP);
  const [configuration, SetConfiguration] = useState<Configs>({
    [eCurrency.EUR]: new Map<eWithdrawalSettings, SettingsValue>(),
    [eCurrency.USD]: new Map<eWithdrawalSettings, SettingsValue>(),
    [eCurrency.GBP]: new Map<eWithdrawalSettings, SettingsValue>(),
  });


  useEffect(() => {
    dispatch(GetWithdrawalMethods());
  }, [GetWithdrawalMethods]);

  useEffect(() => {
    if ((billingSettings?.length ?? 0) > 0)
      return;
    dispatch(getAccountConfiguration());
  }, [getAccountConfiguration]);

  useEffect(() => {
    if (!widthdrawalMethods || widthdrawalMethods.loading || widthdrawalMethods.saving)
      return;
    const newConf: Configs = {
      [eCurrency.EUR]: new Map<eWithdrawalSettings, SettingsValue>(),
      [eCurrency.USD]: new Map<eWithdrawalSettings, SettingsValue>(),
      [eCurrency.GBP]: new Map<eWithdrawalSettings, SettingsValue>(),
    };
    for (const c of (widthdrawalMethods.methods ?? [])) {
      const m = newConf[c.currency as keyof Configs];
      m.set(eWithdrawalSettings.AccountHolderName, c.accountHolderName);
      m.set(eWithdrawalSettings.AccountNumber, c.accountNumber?.toString() ?? null);
      m.set(eWithdrawalSettings.AccountType, c.accountType?.toString() ?? null);
      m.set(eWithdrawalSettings.Iban, c.iban ?? null);
      m.set(eWithdrawalSettings.RoutingNumber, c.routingNumber ?? null);
      m.set(eWithdrawalSettings.SortCode, c.sortCode?.toString() ?? null);
    }
    SetConfiguration(newConf);
  }, [widthdrawalMethods]);

  const bag: SettingDataBag = {};

  const GetConfiguration = (c: eCurrency) => configuration[c as keyof Configs];
  const currentConfiguration = GetConfiguration(tab);
  const SettingValueAsString = (i: eWithdrawalSettings) => {
    const cc = currentConfiguration.get(i);
    if (cc == null)
      return undefined;
    return cc as string;
  };
  const SettingValueAsInt = (i: eWithdrawalSettings) => {
    const cc = currentConfiguration.get(i);
    if (cc == null)
      return undefined;
    return parseInt(cc);
  };

  const disableSaving = (() => {
    if ((currentConfiguration.get(eWithdrawalSettings.AccountHolderName)?.length ?? 0) < 3)
      return true;
    switch(tab){
      case eCurrency.GBP:
        return parseInt((currentConfiguration.get(eWithdrawalSettings.SortCode) ?? '0')) < 1
          || parseInt((currentConfiguration.get(eWithdrawalSettings.AccountNumber) ?? '0')) < 1;
      case eCurrency.EUR:
        return (currentConfiguration.get(eWithdrawalSettings.Iban)?.length ?? 0) < 5;
      case eCurrency.USD:
        return parseInt((currentConfiguration.get(eWithdrawalSettings.AccountNumber) ?? '0')) < 1;
    }
  })();

  const HandleSave = () => {
    dispatch(SaveWithdrawalMethod({
      accountHolderName: SettingValueAsString(eWithdrawalSettings.AccountHolderName) ?? '',
      accountNumber: SettingValueAsInt(eWithdrawalSettings.AccountNumber),
      accountType: SettingValueAsInt(eWithdrawalSettings.AccountType),
      currency: tab,
      iban: SettingValueAsString(eWithdrawalSettings.Iban),
      routingNumber: SettingValueAsString(eWithdrawalSettings.RoutingNumber),
      sortCode: SettingValueAsInt(eWithdrawalSettings.SortCode)
    }));
  };

  const SaveSetting = (key: eWithdrawalSettings, value: SettingsValue) => {
    const newConf = new Map<eWithdrawalSettings, SettingsValue>(currentConfiguration);
    newConf.set(key, value);
    SetConfiguration({ ...configuration, [tab]: newConf });
  };

  const settings = (() => {
    const accountHolderName: WithdrawalSetting = {
      Labels: ['Account.Setting.Name.AccountHolderName'],
      Description: ['Account.Setting.Description.AccountHolderName'],
      Type: SettingType.String,
      Fields: [eWithdrawalSettings.AccountHolderName],
      Values: ['']
    };
    const iban: WithdrawalSetting = {
      Labels: ['Account.Setting.Name.Iban'],
      Description: ['Account.Setting.Description.Iban'],
      Type: SettingType.String,
      Fields: [eWithdrawalSettings.Iban],
      Values: ['']
    };
    const sortCode: WithdrawalSetting = {
      Labels: ['Account.Setting.Name.SortCode'],
      Description: ['Account.Setting.Description.SortCode'],
      Type: SettingType.Number,
      Fields: [eWithdrawalSettings.SortCode],
      Values: ['', '0', '999999']
    };
    const accountNumberGBP: WithdrawalSetting = {
      Labels: ['Account.Setting.Name.AccountNumber'],
      Description: ['Account.Setting.Description.AccountNumber'],
      Type: SettingType.Number,
      Fields: [eWithdrawalSettings.AccountNumber],
      Values: ['', '0', '99999999']
    };
    const accountNumberUSD: WithdrawalSetting = {
      ...accountNumberGBP,
      Values: ['', '0', '9999999999999']
    };
    const routingNumber: WithdrawalSetting = {
      Labels: ['Account.Setting.Name.RoutingNumber'],
      Description: ['Account.Setting.Description.RoutingNumber'],
      Type: SettingType.String,
      Fields: [eWithdrawalSettings.RoutingNumber],
      Values: ['']
    };
    const accountType: WithdrawalSetting = {
      Labels: ['Account.Setting.Name.AccountType'],
      Description: ['Account.Setting.Description.AccountType'],
      Type: SettingType.List,
      Fields: [eWithdrawalSettings.AccountType],
      Values: ['1', '1', 'Account.Setting.Option.Checking', '2', 'Account.Setting.Option.Savings']
    };

    return {
      [eCurrency.EUR]: [
        accountHolderName,
        iban
      ],
      [eCurrency.USD]: [
        accountHolderName,
        accountNumberUSD,
        routingNumber,
        accountType
      ],
      [eCurrency.GBP]: [
        accountHolderName,
        sortCode,
        accountNumberGBP
      ]
    } as { [id: number]: WithdrawalSetting[] };
  })();

  const RenderSetting = (c: eCurrency, values: ConfigData, setting: WithdrawalSetting, allSettings: WithdrawalSetting[]) => {
    return (
      <WithdrawalSettingInput
        key={c + '_' + setting.Fields[0]}
        setting={setting}
        currentSettingValues={values}
        translationValues={translationValues}
        onSave={SaveSetting}
        dataBag={bag}
        savingSetting={undefined}
        onButtonClick={undefined}
        allFieldsInfo={allSettings}
        fullWidth={true}
      />
    );
  };

  const RenderSettings = (c: eCurrency) => {
    const values = GetConfiguration(c);
    const sets = settings[c];
    return sets.map(x => RenderSetting(c, values, x, sets));
  };

  const accountBusinessNotConfigured = billingSettings != null && billingSettings.find(x => x.key == eAccountSettings.IAmBusiness)?.value == null;

  if (!display)
    return <></>;

  if (accountBusinessNotConfigured || settingsLoading)
    return <Alert type='error' message={<T k='Account.Setting.Forced' />} />;


  return <Tabs
    onChange={(s) => SetTab(parseInt(s) as eCurrency)}
    activeKey={tab.toString()}
    items={[eCurrency.GBP, eCurrency.EUR, eCurrency.USD].map(c => ({
      label: eCurrency[c],
      key: c.toString(),
      children: <div className="subsection">
        {(!widthdrawalMethods || widthdrawalMethods.loading || widthdrawalMethods.saving) && <Spin />}
        {widthdrawalMethods && !widthdrawalMethods.loading && !widthdrawalMethods.saving && <>
          {RenderSettings(c)}
          <div className="save-button-container">
            <PrimaryBtn onClick={HandleSave} disabled={disableSaving}><T k='Save' /></PrimaryBtn>
          </div>
        </>}
      </div>
    }))}
  />;
};