import { Card, Form, Input, InputNumber } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useState } from 'react';
//import { MissingListing } from '../../../../../redux/import-listings/importListingSlice';
import { ImportListingData } from '../../../import-listings';


interface Props {
  data: ImportListingData | null;
  fromOtherPlatform: boolean;
  updateItem: (data: ImportListingData) => void;
}

export const ImportItem = (props: Props) => {
  const { data, fromOtherPlatform, updateItem } = props;
  const [isReady, setReady] = useState<boolean>(false);

  const handleSourceURLChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checkValidity() && !isReady && event.target.value.length != 0)
      setReady(true);
    else if (isReady)
      setReady(false);
    if (data != null) {
      data.sourceURL = event.target.value;
      updateItem(data);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (data != null) {
      const value = event.target.value;
      switch (event.target.name) {
        case 'markup':
          data.markup = value;
          break;
        case 'sourceVariation':
          data.sourceVariation = value;
          break;
        case 'sourceWarehouse':
          data.sourceWarehouse = value;
          break;
        default:
          break;
      }
      updateItem(data);
    }
  };

  return <Card className={'import-item' + (isReady ? ' ready' : '')}>
    <div className="description">
      <Paragraph ellipsis={{ rows: 2, tooltip: true }}>
        <span className="sku">{data?.id}</span>
        <span className="item-title"> - {data?.title}</span>
      </Paragraph>
      <div className="attributes">
        {(data?.variationAttributes != undefined) && Object.keys(data?.variationAttributes).map((key, index) => {
          if (data?.variationAttributes != undefined)
            return <span key={index}>{key + ' : ' + Object.values(data?.variationAttributes)[index]}</span>;
        })}
      </div>
    </div>
    <Form.Item label="Source URL *" name="sourceURL" className="source-url-form-item">
      <Input className="blue-input" name="sourceURL" type='url' value={ data?.sourceURL } onChange={ handleSourceURLChange } />
    </Form.Item>
    <Form.Item label="Markup" name="markup">
      <InputNumber className="blue-input" name="markup" min={0} type='number'/>
    </Form.Item>
    {fromOtherPlatform && (
      <>
        <Form.Item label="Source variation" name="sourceVariation" className='fromOtherPlatform'>
          <Input className="blue-input" name="sourceVariation" type='text' value={data?.sourceVariation} onChange={handleChange} />
        </Form.Item>
        <Form.Item label="Source warehouse" name="sourceWarehouse" className='fromOtherPlatform'>
          <Input className="blue-input" name="sourceWarehouse" type='text' value={data?.sourceWarehouse} onChange={handleChange} />
        </Form.Item>
      </>
    )}
  </Card>;
};