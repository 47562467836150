import { Progress, Spin } from 'antd';
import { T } from '../../utils/transShim';
import { SuccessBtn } from '../ActionBtns';
import { PopupModal } from './popup-modal';


type Props = {
  visible?: boolean;
  loading: boolean;
  progress?: number;
  onClose?: () => void;
  onAccept?: () => void;
  onCancel?: () => void;
  acceptText?: React.ReactNode;
  title?: React.ReactNode;
  result?: React.ReactNode;
  messageLoading?: React.ReactNode;
  cancelable?: boolean;
  cancelText?: React.ReactNode;
  confirmating?: boolean;

  okDisabled?: boolean;
  cancelDisabled?: boolean;
  closeOnAccept?: boolean;
};
export const LoadingModal = (props: Props) => {
  const { visible, onClose, loading, title, progress, result, messageLoading, cancelable, onCancel, okDisabled, cancelDisabled, onAccept, closeOnAccept, confirmating } = props;
  const acceptText = props.acceptText ?? <T k='Question.Accept' />;
  const cancelText = props.cancelText ?? <T k='Question.Canel' />;

  const HandleAccept = () => {
    onAccept?.();
    if (closeOnAccept ?? true)
      onClose?.();
  };

  const footerButtons = (cancelable && loading) ?
    [
      <SuccessBtn key='submit' onClick={onCancel} disabled={okDisabled}>
        {cancelText}
      </SuccessBtn>
    ] :
    [
      <SuccessBtn key='submit' onClick={HandleAccept} disabled={okDisabled || ((loading || cancelDisabled) && !confirmating)}>
        {acceptText}
      </SuccessBtn>
    ];

  return <PopupModal
    maskClosable={!loading || confirmating}
    onClose={onClose}
    open={visible}
    title={title}
    footer={footerButtons}
  >
    {!loading && result}
    {loading && <>
      {messageLoading}
      {progress == null && <Spin />}
      {progress != null && <Progress percent={Math.floor(progress)} />}
    </>}
  </PopupModal>;
};