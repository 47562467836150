import { MutableRefObject, useEffect, useRef, useState } from 'react';

export interface UseIntervalManager {
  Cancel: () => void;
}

type callbackFunc = (x: UseIntervalManager) => void;

export const UseInterval = (callback: callbackFunc, delay: number) => {
  const savedCallback: MutableRefObject<(callbackFunc) | null> = useRef(null);
  const [ canceled, SetCanceled ] = useState<boolean>(false);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    if (canceled)
      return;
    function tick() {
      if (savedCallback.current !== null) {
        savedCallback.current({ Cancel: () => SetCanceled(true) });
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay, canceled]);
};
