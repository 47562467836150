import { Channel } from '../../../redux/channels/channelsSlice';
import { T } from '../../../utils/transShim';
import { Source } from '../../../redux/sources/sourceSlice';
import { ActiveListingExtended, ListingT } from './types';
import { CloseCircleFilled, CheckCircleFilled, ApiFilled, CheckOutlined, CloseOutlined, PictureOutlined } from '@ant-design/icons';
import { ReactUtils } from '../../../utils/react-utils';
import { ShopLogo } from '../../../utils/shopLogo';
import { eChannelListingStatus, PendingListing } from '../../../redux/listings/listings-slice';
import { ListingsUtils } from '../../../utils/listings-utils';
import { PendingErrorCell } from './components/pending-error-cell';
import { LowestPriceCell } from './components/lowest-price-cell';
import { GetPlatform } from '../../../data/platforms';
import { Tooltip } from 'antd';
import { A } from '../../../small-components/A';
import React from 'react';


export const RenderChannelItem = (channelItem: string, rowR: ListingT) => {
  const row = rowR as { channel: Channel; asin?: string; id: number };

  const channel = row.channel;
  if (!channel) return channelItem;

  const platform = GetPlatform(channel.channelId);
  if (!platform) return channelItem;

  const url = ReactUtils.GetItemUrl(channel.channelId, channel.isoCountry, channelItem, channel.channelIdentifier, row.asin);
  if (!url) return channelItem;

  const SP = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation();
  };
  return (
    <A target="_blank" rel="noreferrer" to={platform.itemUrlOutUrl ? ReactUtils.GetOutUrl(url, channel.isoCountry) : url} onClick={SP}>
      {GetPlatform(channel.channelId).useInternalInList ? row.asin : channelItem}
    </A>
  );
};

export const RenderAmazonSku = (channelItem: string, rowR: ListingT) => {
  if (channelItem == null) return '';

  const { channel, asin } = rowR as { channel: Channel; asin: string };

  const url = (() => {
    const infoChannel = GetPlatform(channel.channelId);
    if (infoChannel && infoChannel.itemUrl) {
      const baseUrl = infoChannel.internalUrl instanceof Object ? infoChannel.internalUrl[channel.isoCountry] : infoChannel.internalUrl;

      return baseUrl
        .replace('{asin}', asin ?? '')
        .replace('{sku}', channelItem)
        .replace('{shopName}', channel.channelIdentifier);
    } else {
      return '';
    }
  })();

  return (
    <A to={url} target="_blank" rel="noreferrer">
      {channelItem}
    </A>
  );
};

export const RenderSource = (path: string, rowR: ListingT) => {
  const row = rowR as { channel: Channel; source: Source; id: number; linksVersion?:number };
  const source = row.source;
  if (!source) return <T k="Listings.Value.UnknownSource" />;

  const DefaultUrl = () => 'https://' + source.baseUrl + '/' + path;

  const ComposeUrl = () => {
    switch (source.id) {
      case 64:
        if (row.linksVersion == 1 && !path.startsWith('business')) {
          return 'https://' + source.baseUrl + '/business/' + path;
        }
        return DefaultUrl();
      default:
        return DefaultUrl();
    }
  };

  const SP = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const url = ComposeUrl();
  return (
    <A target="_blank" rel="noreferrer" to={ReactUtils.GetOutUrl(url, row.channel?.isoCountry)} onClick={SP}>
      {source.name}
    </A>
  );
};


export const RenderImage = (imageUrl?: string) => {
  if (!imageUrl) {
    return (
      <div className="record-img">
        <PictureOutlined />
      </div>
    );
  }
  return (
    <div className="record-img">
      <img src={imageUrl} />
    </div>
  );
};

export const RenderCostOrProfit = (price: number, dataR: ListingT) => {
  const data = dataR as ActiveListingExtended;

  if (!data.monitorPrice || !data.monitorStock) {
    return <ApiFilled className="icon" />;
  }

  return price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const RenderPrice = (price: number) => {
  return price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};
export const RenderPriceNoDecimal = (price: number) => {
  return Math.ceil(price).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
};

export const RenderMarkup = (markup: number, dataR: ListingT) => {
  const pMarkup = (dataR as ActiveListingExtended).markupPricingRule;
  if (pMarkup != null)
    return <Tooltip title='One pricing rule being applied'>+{pMarkup}%*</Tooltip>;
  return '+' + markup + '%';
};

export const RenderStock = (sourceQuantity: number, dataR: ListingT) => {
  if (sourceQuantity === undefined || sourceQuantity === null) return '';

  const data = dataR as ActiveListingExtended;

  const Icon = (monitorStock: boolean, value: number) => {
    if (!monitorStock) {
      return <ApiFilled className="icon" />;
    }

    if (value) return <CheckCircleFilled className="inStockIcon icon" />;
    else return <CloseCircleFilled className="outStockIcon icon" />;
  };

  return (
    <span className="stock-cell">
      {Icon(data.monitorStock ?? true, sourceQuantity)} <span>({data.channelQuantity})</span>
    </span>
  );
};

export const RenderDate = (date: string | Date | undefined) => {
  return <div className="date" >{ReactUtils.GetFormattedDateTime(date)}</div>;
};

export const RenderBoolean = (value: boolean | undefined) => {
  return (
    <div style={{ textAlign: 'center' }}>
      {value && <CheckOutlined className="icon" />}
      {!value && <CloseOutlined className="icon" />}
    </div>
  );
};
export const RenderBooleanExpandable = (value: boolean | undefined) => {
  return (
    <>
      {value && <CheckOutlined className="icon" />}
      {!value && <CloseOutlined className="icon" />}
    </>
  );
};

export const RenderPercentage = (value: number | undefined) => {
  if (value == null) return '';
  return value + '%';
};

export const RenderMonitorPriceDecreasePercentage = (value: number | undefined) => {
  if (value == null) return '';
  if (value == 0)
    return (
      <div style={{ textAlign: 'center' }}>
        <T k="Listings.Value.Always" />
      </div>
    );
  return <div style={{ textAlign: 'center' }}>{value + '%'}</div>;
};

export const RenderLowestPrice = (_: number | undefined, rowR: ListingT) => <LowestPriceCell listing={rowR as ActiveListingExtended} />;

export const RenderOtherChannels = (otherChannels: Channel[]) => {
  if (!otherChannels) return '';

  return (
    <>
      {otherChannels.map((cs) => (
        <ShopLogo key={cs.id} channelId={cs.channelId} title={cs.name} />
      ))}
    </>
  );
};

export const RenderPendingStatus = (status: eChannelListingStatus, listing: ListingT) =>
  ListingsUtils.StatusToUI(status, listing.channel?.channelId, (listing as PendingListing).dontListUntil);
export const RenderError = (errorMessage: string) => <PendingErrorCell errorMessage={errorMessage} />;

export const RenderOptimized = (optimized: number) => {
  const c = () => {
    switch (optimized) {
      default:
      case 0:
        return <CloseOutlined className="icon" />;
      case 1:
        return <T k='Listings.Column.Title'/>;
      case 2:
        return <T k='Listings.Column.Description' />;
      case 3:
        return <CheckOutlined className="icon" />;
    }
  };

  return <div style={{ textAlign: 'center' }}>
    { c() }
  </div>;
};
