import { Col, Form, InputNumber, Row } from 'antd';
import { isArray } from 'util';
import { SettingList } from '../../../../../small-components/settings/setting-list';
import { useTranslation, T } from '../../../../../utils/transShim';
import { SetValueBoolean, SetValueNumber } from './types';

type Props = {
  onMonitorStockChange: (p: SetValueBoolean) => void;
  onMonitorPriceChange: (p: SetValueBoolean) => void;
  onIgnoreRulesChange: (p: SetValueBoolean) => void;
  onMonitorPriceDecreaseChange: (p: SetValueBoolean) => void;
  onPriceDecreasePercentageChange: (p: SetValueNumber) => void;
  onMinQuantityChange: (p: SetValueNumber) => void;
  onPrimeOnlyChange: (p: SetValueBoolean) => void;

  valueMonitorStock: SetValueBoolean;
  valueMonitorPrice: SetValueBoolean;
  valueIgnoreRules: SetValueBoolean;
  valueMonitorPriceDecrease: SetValueBoolean;
  valuePriceDecreasePercentage: SetValueNumber;
  valueMinQuantity: SetValueNumber;
  valuePrime: SetValueBoolean;

  showPrimeOption: boolean;
};
export const ListingMultipleOptions = (props: Props) => {
  const {
    onMonitorStockChange,
    onMonitorPriceChange,
    onIgnoreRulesChange,
    onMonitorPriceDecreaseChange,
    onPriceDecreasePercentageChange,
    onMinQuantityChange,
    onPrimeOnlyChange,

    valueMonitorStock,
    valueMonitorPrice,
    valueIgnoreRules,
    valueMonitorPriceDecrease,
    valuePriceDecreasePercentage,
    valueMinQuantity,
    valuePrime,

    showPrimeOption
  } = props;


  const labelDontChange = useTranslation('Setting.DontChange') as string;
  const labelDefSetEmpty = useTranslation('Setting.FinalDefinedBySettingsEmpty') as string;
  const labelYes = useTranslation('Setting.Yes') as string;
  const labelNo = useTranslation('Setting.No') as string;
  const labelCustom = useTranslation('Setting.Custom') as string;

  const CapitalizeFirstLetter = (s: React.ReactNode) => {
    if (!s)
      return s;

    if (typeof (s) != 'string')
      return s;

    if (s.startsWith('eBay'))//eBay is an exception
      return s;

    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const BasicField = (nameTag: string, descriptionTag: string[], renderFunction: () => React.ReactNode) => {
    return (
      <Row className='description-and-controls' key={nameTag}>
        <Col span={12} className='description-area'>
          <h2>{CapitalizeFirstLetter(<T k={nameTag} />)}</h2>
          {(isArray(descriptionTag) ? descriptionTag : [descriptionTag]).map((x, i) => <p key={i}><T k={x} /></p>)}
        </Col>
        <Col span={8} className='switch-container'>
          {renderFunction()}
        </Col>
      </Row>
    );
  };
  const BooleanFieldList = (value: SetValueBoolean, onChange: (p: SetValueBoolean) => void) => {
    const HandleChangeList = (v: string | null) => {
      let value: SetValueBoolean;
      switch (v) {
        default:
        case '-1':
          value = null; break;
        case '0':
          value = { value: undefined }; break;
        case '1':
          value = { value: true }; break;
        case '2':
          value = { value: false }; break;
      }
      onChange(value);
    };
    return <SettingList
      listData={[
        {
          label: labelDontChange,
          value: '-1'
        },
        {
          label: labelDefSetEmpty,
          value: '0'
        },
        {
          label: labelYes,
          value: '1'
        },
        {
          label: labelNo,
          value: '2'
        }
      ]}
      value={value == null ? '-1' : (value.value == null ? '0' : (value.value ? '1' : '2'))}
      onChange={HandleChangeList}
      loadingData={false}
    />;
  };
  const RenderFieldBoolean = (nameTag: string, descriptionTag: string[], value: SetValueBoolean, onChange: (p: SetValueBoolean) => void) =>
    BasicField(nameTag, descriptionTag, () => BooleanFieldList(value, onChange));
  const FieldNumberList = (value: SetValueNumber, onChange: (p: SetValueNumber) => void, min?: number) => {
    const HandleChangeList = (v: string | null) => {
      let value: SetValueNumber;
      switch (v) {
        default:
        case '-1':
          value = null; break;
        case '0':
          value = { value: undefined }; break;
        case '1':
          value = { value: 1 }; break;
      }
      onChange(value);
    };
    return (
      <>
        <SettingList
          listData={[
            {
              label: labelDontChange,
              value: '-1'
            },
            {
              label: labelDefSetEmpty,
              value: '0'
            },
            {
              label: labelCustom,
              value: '1'
            }
          ]}
          value={value == null ? '-1' : (value.value == null ? '0' : '1')}
          onChange={HandleChangeList}
          loadingData={false}
        />
        {value?.value != null && <InputNumber min={min} value={value.value} onChange={x => onChange({ value: x ?? undefined })} />}
      </>
    );
  };
  const RenderFieldNumber = (nameTag: string, descriptionTag: string[], value: SetValueNumber, onChange: (p: SetValueNumber) => void, min?: number) =>
    BasicField(nameTag, descriptionTag, () => FieldNumberList(value, onChange, min));

  const RenderFieldBooleanNumber = (nameTag: string, descriptionTag: string[], valueB: SetValueBoolean, onChangeB: (p: SetValueBoolean) => void, valueN: SetValueNumber, onChangeN: (p: SetValueNumber) => void, min?: number) => {
    return BasicField(nameTag, descriptionTag, () => {
      return (
        <>
          {BooleanFieldList(valueB, onChangeB)}
          {FieldNumberList(valueN, onChangeN, min)}
        </>
      );
    });
  };

  return (
    <Form layout='vertical' className='form'>
      {RenderFieldBoolean('Channel.Setting.Name.MonitorStock', ['Channel.Setting.Description.MonitorStock'], valueMonitorStock, onMonitorStockChange)}
      {RenderFieldBoolean('Channel.Setting.Name.MonitorPrice', ['Channel.Setting.Description.MonitorPrice'], valueMonitorPrice, onMonitorPriceChange)}
      {RenderFieldBoolean('Listings.Column.IgnoreRules', ['Listings.Setting.IgnoreRules.Description'], valueIgnoreRules, onIgnoreRulesChange)}
      {RenderFieldBooleanNumber('Channel.Setting.Name.MonitorPriceDecrease', ['Channel.Setting.Description.MonitorPriceDecrease.1', 'Channel.Setting.Description.MonitorPriceDecrease.2', 'Channel.Setting.Description.MonitorPriceDecrease.3'], valueMonitorPriceDecrease, onMonitorPriceDecreaseChange, valuePriceDecreasePercentage, onPriceDecreasePercentageChange, 0)}
      {RenderFieldNumber('Channel.Setting.Name.MinQuantity', ['Channel.Setting.Description.MinQuantity'], valueMinQuantity, onMinQuantityChange,0)}
      {showPrimeOption && RenderFieldBoolean('Sources.Setting.Name.OnlyPrime', ['Sources.Setting.Description.OnlyPrime'], valuePrime, onPrimeOnlyChange)}
    </Form>
  );
};