import { Alert, Col, Popover, Row } from 'antd';

import { useAppDispatch, useAppSelector } from '../../../custom-hooks/reduxCustomHooks';
import { UserSupplierSettingsKeys } from '../../../data/autoorder-fields';
import Sources from '../../../data/sources';
import { Links } from '../../../links';
import { AutoOrderingConfigurationAccountData } from '../../../redux/auto-ordering/autoOrderingThunk';
import { SaveSourceSetting } from '../../../redux/source-configuration/sources.coonfiguration-thunk';
import { Source } from '../../../redux/sources/sourceSlice';
import { A } from '../../../small-components/A';
import { PrimaryBtn } from '../../../small-components/ActionBtns';
import { SettingBoolean } from '../../../small-components/settings/setting-boolean';
import { SourceSettingKey } from '../../../types/settings';
import { T } from '../../../utils/transShim';
import { AmazonSourceIds } from '../../sources/configuration/settings-list';
import { TabsConfigurationAO } from './tabs-configuration-ao';
import { tipsAmazon } from './tips-amazon';

type props = {
  sourceData: Source;
  onChangeTab: (tab: TabsConfigurationAO) => void;
  accounts: AutoOrderingConfigurationAccountData[];
}

export const General = (props: props) => {
  const dispatch = useAppDispatch();
  const { sourceData, onChangeTab, accounts } = props;

  const { get: sourceSettings, saving: savingSourceSettings } = useAppSelector(state => state.sourcesConfiguration);

  const GetSourcesetting = (s: SourceSettingKey) => sourceSettings?.settings?.find((x) => x.key == s && x.sourceId == sourceData.id)?.value;
  const GetSavingSourceSetting = (s: SourceSettingKey) => savingSourceSettings?.find((x) => x?.data?.key == s);

  const HandleSourceSettingChange = (value: string | null, key: SourceSettingKey) => {
    dispatch(SaveSourceSetting({ key: key, sourceId: sourceData.id, value: value }));
  };
  const EnableDisableAO = (value: string | null) => {
    HandleSourceSettingChange(value, SourceSettingKey.AutoOrdering);
    if (value != '1')
      return;

    onChangeTab(TabsConfigurationAO.account);
  };


  const autoorderingSettingEnabled = GetSourcesetting(SourceSettingKey.AutoOrdering) as string | undefined;

  const autoorderingFee = sourceData.autoOrderingFee ?? 0;
  const staticSourceData = Sources[sourceData.id];
  const discount = staticSourceData?.autoorder?.hgrAccountDiscount ?? 0;

  const isAmazon = AmazonSourceIds.find((x) => x == sourceData.id);

  const RenderPaymentSwitchCol = () => <Col span={8} className='switch-container'>
    <A to={Links.Configuration.Account.PaymentMethod} className="configure-payment-link">
      <PrimaryBtn>
        <T k="AutoOrderingConfiguration.ConfigurePayment" />
      </PrimaryBtn>
    </A>
  </Col>;

  const RenderPaymentColsFee = () => {
    return <>
      <Col span={12} className='description-area'>
        <h2><T k="AutoOrderingConfiguration.Payments" /></h2>
        <T k="AutoOrderingConfiguration.Payments.Description" values={{ f: sourceData.autoOrderingFee }} />
      </Col>
      {RenderPaymentSwitchCol()}
    </>;
  };

  const RenderPaymentColsFree = () => {
    return <Col span={12} className='description-area'>
      <h2><T k="AutoOrderingConfiguration.Payments" /></h2>
      <T k="AutoOrderingConfiguration.Message.Free" values={{ name: sourceData?.name }} />
    </Col>;
  };


  const RenderPaymentColsHGRAccount = () => {
    const both = sourceData.hasAutoOrder && sourceData.autoOrderingFee;

    return <>
      <Col span={12} className='description-area'>
        <h2><T k="AutoOrderingConfiguration.Payments" /></h2>
        {both && <><T k="AutoOrderingConfiguration.IfHGRAccount" /><br />&nbsp;&nbsp;</>}
        {discount > 0 ?
          <T k="AutoOrderingConfiguration.HGRAccountDiscount" values={{ d: discount, name: sourceData?.name }} />
          : <T k="AutoOrderingConfiguration.Message.Free" values={{ name: sourceData?.name }} />
        }
        {both && <><br /><T k="AutoOrderingConfiguration.IfYourAccount" /><br />&nbsp;&nbsp;</>}
        {both && (autoorderingFee > 0 ?
          <T k="AutoOrderingConfiguration.Payments.Description" values={{ f: sourceData.autoOrderingFee }} />
          :
          <T k="AutoOrderingConfiguration.Message.Free" values={{ name: sourceData?.name }} />
        )}
      </Col>
      {RenderPaymentSwitchCol()}
    </>;
  };

  const RenderPaymentRow = () => {
    return <Row className='description-and-controls'>
      {sourceData.hasAutoOrderHGRAccount ?
        RenderPaymentColsHGRAccount()
        : autoorderingFee > 0 ?
          RenderPaymentColsFee()
          : RenderPaymentColsFree()
      }
    </Row>;
  };

  return (<>
    <Row className='description-and-controls'>
      <Col span={12} className='description-area'>
        <h2><T k="AutoOrderingConfiguration.EnableDisable" /></h2>
        <T k="AutoOrderingConfiguration.EnableDisable.Description" />
        {autoorderingSettingEnabled == '1' && accounts.filter((x) => x.rawSettings?.find((x) => x.key == UserSupplierSettingsKeys.ACTIVE)?.value == '1').length == 0 && (
          <Alert type="error" message={<><T k="AutoOrderingConfiguration.NeedAccount" /><A to={Links.Configuration.AutoOrdering.ProduceAccount(sourceData.id)}><T k="AutoOrderingConfiguration.NeedAccount.2" /></A></>} />
        )}
      </Col>
      <Col span={8} className='switch-container'>
        <SettingBoolean
          loading={GetSavingSourceSetting(SourceSettingKey.AutoOrdering)?.loading}
          onChange={(x) => EnableDisableAO(x)}
          value={autoorderingSettingEnabled}
        />
      </Col>
    </Row>
    {RenderPaymentRow()}
    {isAmazon && (
      <Popover placement="right" content={tipsAmazon}>
        <div className="popover-container">
          <h5 className="tips-title">
            <T k="AutoOrderingConfiguration.AvoidBans" values={{ name: sourceData?.name }} />
          </h5>
        </div>
      </Popover>
    )}
  </>);
};