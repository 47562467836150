import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useAppDispatch } from '../../custom-hooks/reduxCustomHooks';
import { Links } from '../../links';
import { getChannels } from '../../redux/channels/channelsThunk';
import { ImpersonateUser } from '../../redux/user/userThunk';
import '../../sass/top-bar.scss';
import { SuperLogout } from '../../utils/logout';
import { ReactUtils } from '../../utils/react-utils';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const userId = urlParams.get('userId');

export const Impersonate = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isImpersonated = ReactUtils.stringToBoolean(localStorage.getItem('isImpersonated'));

  const impersonateRequest = async (userId: string) => {
    const rs = await dispatch(ImpersonateUser(userId));

    if (rs.payload.token) {
      await SuperLogout(false);
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('isImpersonated', 'true');
      localStorage.setItem('Authorization', rs.payload.token);
      history.push(Links.Dashboard);
      await dispatch(getChannels());

    } else {
      await SuperLogout(true);
    }

  };

  useEffect(() => {
    if (userId && !isImpersonated) {
      impersonateRequest(userId);
    }
  }, []);

  return (
    <>
      <h3>Please wait while impersonating the user...</h3>
    </>
  );

};
