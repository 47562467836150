import { Form, Input } from 'antd';
import { PrimaryBtn } from '../../../small-components/ActionBtns';
import { useAppDispatch, useAppSelector } from '../../../custom-hooks/reduxCustomHooks';
import { SetResetPassword } from 'src/redux/user/userThunk';
import { ResetPasswordData } from '../../../redux/user/userSlice';
import logo from '../../../assets/hgr-logo.png';
import '../../../sass/user-register.scss';
import { useState } from 'react';
import { Links } from '../../../links';
import { T } from '../../../utils/transShim';
import { A } from '../../../small-components/A';

export const ResetPassword = () => {
  const [firstTime, setFirstTime] = useState<boolean>(true);

  const { loading, success, errors } = (() => {
    const { loadingReset, resetPasswordAnswer } = useAppSelector((state) => state.user);
    if (firstTime)
      return {
        loading: false,
        success: false,
        errors: []
      };

    const errors = [];
    if (resetPasswordAnswer?.data?.response_errors?.error) {
      if (Array.isArray(resetPasswordAnswer.data.response_errors?.error)) {
        for (const g of resetPasswordAnswer.data.response_errors?.error) {
          errors.push(g);
        }
      } else {
        errors.push(resetPasswordAnswer.data.response_errors?.error);
      }
    }
    if (resetPasswordAnswer?.data?.error) {
      if (Array.isArray(resetPasswordAnswer.data.error)) {
        for (const g of resetPasswordAnswer.data.error) {
          errors.push(g);
        }
      } else {
        errors.push(resetPasswordAnswer.data.error);
      }
    }
    if (resetPasswordAnswer?.data?.errors) {
      for (const n in resetPasswordAnswer.data.errors) {
        errors.push({ code: n, description: resetPasswordAnswer.data.errors[n]?.[0] });
      }
    }
    return {
      loading: loadingReset,
      success: resetPasswordAnswer?.status == 200,
      errors: errors
    };
  })();

  const dispatch = useAppDispatch();

  const queryParams = new URLSearchParams(window.location.search);
  let code: string | null | undefined = queryParams.get('code');
  code = code?.replaceAll(' ', '+');
  const statusMessage = 'Enter your Registered Email and Password & Confirm Password to reset the password';
  const successMessage = (
    <div className="m-5 p-5">
      <h3>Your password has been reset</h3>
      <h3>
        Please <A to={Links.Credentials.Login}>Click here to log in</A>.
      </h3>
    </div>
  );

  const onFinish = async (values: ResetPasswordData) => {
    setFirstTime(false);
    values.code = code ?? '';
    dispatch(SetResetPassword(values));
  };

  let confirmPasswordError: string | null = null;
  let passwordError: string | null = null;
  let otherError: string | null = null;
  if (!success && errors?.length > 0) {
    for (const re of errors) {
      switch (re.code) {
        case 'Password':
          passwordError = re.description;
          break;
        case 'PasswordRequiresDigit':
          passwordError = re.description;
          break;
        case 'PasswordRequiresUpper':
          passwordError = re.description;
          break;
        case 'PasswordRequiresLower':
          passwordError = re.description;
          break;
        case 'PasswordRequiresNonAlphanumeric':
          passwordError = re.description;
          break;
        case 'ConfirmPassword':
          confirmPasswordError = re.description;
          break;
        default:
          otherError = re.description;
          break;
      }
    }
  }

  const FormContent = () => {
    return (
      <Form className="register-form" layout="vertical" name="basic" onFinish={onFinish} autoComplete="on">
        <Form.Item
          label={<T k="Register.EmailAddress" />}
          name="email"
          rules={[
            { required: true, message: 'Please input your email!' },
            { type: 'email', message: 'Invalid e-mail address' }
          ]}
        >
          <Input className="auth-input" />
        </Form.Item>

        <Form.Item
          label={<T k="Register.NewPassword" />}
          name="password"
          validateStatus={passwordError ? 'error' : undefined}
          help={passwordError}
          rules={[{ required: true, message: 'Please provide your password!' }]}
        >
          <Input.Password className="auth-input" />
        </Form.Item>

        <Form.Item
          label={<T k="Register.ConfirmPassword" />}
          name="confirmPassword"
          validateStatus={confirmPasswordError ? 'error' : undefined}
          help={confirmPasswordError}
          rules={[{ required: true, message: 'Please confirm your password!' }]}
        >
          <Input.Password className="auth-input" />
        </Form.Item>

        <Form.Item validateStatus={otherError ? 'error' : undefined} help={otherError}>
          <PrimaryBtn htmlType="submit" disabled={loading}>
            {loading ? <T k="Register.Waiting" /> : <T k="Register.Change" />}
          </PrimaryBtn>
        </Form.Item>
      </Form>
    );
  };

  const SuccessContent = () => successMessage;

  const Content = () => {
    if (success)
      return SuccessContent();
    else
      return FormContent();
  };

  return <>
    <div className="register-form-header">
      <div className="text-section">
        <h2 className="register-form-title">Forgot Password</h2>
        <p>{success == true ? '' : statusMessage}</p>
      </div>
      <div>
        <img src={logo} alt="" className="logo" />
      </div>
    </div>
    {Content()}
    <div className="m-4">
      <p>
        Don&apos;t have account yet?{' '}
        <A className="alternative-link" to="/register">
          Register
        </A>
      </p>
    </div>
  </>;
};
