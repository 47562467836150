import { useEffect, useState } from 'react';
import { Col, Form, Input, Row, Typography } from 'antd';
import { IconArrowModal, IconEdit } from '../components/common/Icons';
import { T } from '../utils/transShim';
import '../sass/order-details-modal.scss';
import { useAppDispatch, useAppSelector } from '../custom-hooks/reduxCustomHooks';
import { Spin } from 'antd';
import { OrderData } from '../redux/orders/orderSlice';
import { SuccessBtn } from './ActionBtns';
import { ReactUtils } from '../utils/react-utils';
import { SetShippingAddress } from '../redux/sales/salesThunk';
import { toastAlert } from '../utils/toastAlert';
import { A } from './A';


interface Props {
  data: OrderData | undefined;
  OrderContentModalOpen: () => void;
}

const OrderDetailsContent = (props: Props) => {
  const { data, OrderContentModalOpen } = props;
  const { ordersAddress, loading } = useAppSelector((state) => state.orderAddress);
  const { Text } = Typography;
  const isImpersonated = ReactUtils.stringToBoolean(localStorage.getItem('isImpersonated'));
  const dispatch = useAppDispatch();

  const [orderBillingAddress, setOrderBillingAddress] = useState(ordersAddress?.billingAddress ?? { id: 0 });
  const [orderShippingAddress, setOrderShippingAddress] = useState(ordersAddress?.shippingAddress ?? { id: 0 });
  const [saving, setSaving] = useState(false);

  const setAddress = () => {
    setOrderBillingAddress(ordersAddress?.billingAddress ?? { id: 0 });
    setOrderShippingAddress(ordersAddress?.shippingAddress ?? { id: 0 });
  };

  const SaveShippingAddress = async () => {
    setSaving(true);
    const rs = await dispatch(
      SetShippingAddress({
        orderLineId: data?.id,
        shippingAddress: orderShippingAddress
      })
    );
    if (rs.payload.success == true) {
      toastAlert('Changes saved successfully', 'success');
    }
    setSaving(false);
  };

  const firstNameHandle = (v: string) => {
    setOrderShippingAddress({ ...orderShippingAddress, firstName: v });
  };

  const lastNameHandle = (v: string) => {
    setOrderShippingAddress({ ...orderShippingAddress, lastName: v });
  };

  const phoneHandle = (v: string) => {
    setOrderShippingAddress({ ...orderShippingAddress, phone: v });
  };

  const address1Handle = (v: string) => {
    setOrderShippingAddress({ ...orderShippingAddress, address1: v });
  };

  const address2Handle = (v: string) => {
    setOrderShippingAddress({ ...orderShippingAddress, address2: v });
  };

  const cityHandle = (v: string) => {
    setOrderShippingAddress({ ...orderShippingAddress, city: v });
  };

  const zipHandle = (v: string) => {
    setOrderShippingAddress({ ...orderShippingAddress, zip: v });
  };

  const stateHandle = (v: string) => {
    setOrderShippingAddress({ ...orderShippingAddress, province: v });
  };

  const countryHandle = (v: string) => {
    setOrderShippingAddress({ ...orderShippingAddress, country: v });
  };

  const [editForm, toggleEdit] = useState(false);

  useEffect(() => {
    setAddress();
  }, [data?.id]);

  return (
    <div className="order-details-container">
      <div className="order-details-header">
        <Row>
          <Col span={6}>
            <h2 className="title-modal">
              <T k="Orders.Title.OrderDetails" />
            </h2>
          </Col>
        </Row>
      </div>
      {loading || saving ? (
        <Spin className="ant-spin-spinnings" />
      ) : (
        <>
          <Row className="fields">
            <Col md={11} style={{ backgroundColor: '#f2f8ff', padding: '10px' }}>
              <Row>
                <Col span={12}>
                  <h3 className="heading-details">
                    <T k="Orders.Label.ShippingAddress" />
                    <A onClick={() => toggleEdit(!editForm)}>
                      <IconEdit />
                    </A>
                  </h3>
                </Col>
                <Col span={12} hidden={editForm}>
                  <h3 className="heading-details">
                    <T k="Orders.Label.Billingaddress" />
                  </h3>
                </Col>
              </Row>
              {editForm == false ? (
                <Row key="view" className="shipping-billing-container">
                  <Row>
                    <Col span={12}>
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.Name" />
                        </p>
                        <Form.Item>
                          <Input
                            className="white-bg"
                            type="text"
                            value={orderShippingAddress?.firstName + ' ' + orderShippingAddress?.lastName}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.Name" />
                        </p>
                        <Form.Item>
                          <Input
                            className="white-bg"
                            type="text"
                            value={orderShippingAddress?.firstName + ' ' + orderShippingAddress?.lastName}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.Phone" />
                        </p>
                        <Form.Item>
                          <Input className="white-bg" type="text" value={orderShippingAddress?.phone} />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.Phone" />
                        </p>
                        <Form.Item>
                          <Input className="white-bg" type="text" value={orderBillingAddress?.phone} />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.AddressLineOne" />
                        </p>
                        <Form.Item>
                          <Input className="white-bg" type="text" value={orderShippingAddress?.address1} />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.AddressLineOne" />
                        </p>
                        <Form.Item>
                          <Input className="white-bg" type="text" value={orderBillingAddress?.address1} />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.AddressLineTwo" />
                        </p>
                        <Form.Item>
                          <Input className="white-bg" type="text" value={orderShippingAddress?.address2} />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.AddressLineTwo" />
                        </p>
                        <Form.Item>
                          <Input className="white-bg" type="text" value={orderBillingAddress?.address2} />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.City" />
                        </p>
                        <Form.Item>
                          <Input className="white-bg" type="text" value={orderShippingAddress?.city} />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.City" />
                        </p>
                        <Form.Item>
                          <Input className="white-bg" type="text" value={orderBillingAddress?.city} />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.PostalCode" />
                        </p>
                        <Form.Item>
                          <Input className="white-bg" type="text" value={orderShippingAddress?.zip} />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.PostalCode" />
                        </p>
                        <Form.Item>
                          <Input className="white-bg" type="text" value={orderBillingAddress?.zip} />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.State" />
                        </p>
                        <Form.Item>
                          <Input className="white-bg" type="text" value={orderShippingAddress?.province} />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.State" />
                        </p>
                        <Form.Item>
                          <Input className="white-bg" type="text" value={orderBillingAddress?.province} />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.Country" />
                        </p>
                        <Form.Item>
                          <Input className="white-bg" type="text" value={orderShippingAddress?.country} />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.Country" />
                        </p>
                        <Form.Item>
                          <Input className="white-bg" type="text" value={orderBillingAddress?.country} />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </Row>
              ) : (
                <Row key="edit" className="row shipping-editing-container">
                  <Row key="10">
                    <Col span={12} key="first">
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.FirstName" />
                        </p>
                        <Form.Item>
                          <Input
                            className="white-bg"
                            type="text"
                            defaultValue={orderShippingAddress?.firstName}
                            onChange={(e) => firstNameHandle(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12} key="second">
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.LastName" />
                        </p>
                        <Form.Item>
                          <Input
                            className="white-bg"
                            type="text"
                            defaultValue={orderShippingAddress?.lastName}
                            onChange={(e) => lastNameHandle(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row key="2">
                    <Col span={24}>
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.Phone" />
                        </p>
                        <Form.Item>
                          <Input
                            className="white-bg"
                            type="text"
                            defaultValue={orderShippingAddress?.phone}
                            onChange={(e) => phoneHandle(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row key="3">
                    <Col span={24}>
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.AddressLineOne" />
                        </p>
                        <Form.Item>
                          <Input
                            className="white-bg"
                            type="text"
                            value={orderShippingAddress?.address1}
                            onChange={(e) => address1Handle(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row key="4">
                    <Col span={24}>
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.AddressLineTwo" />
                        </p>
                        <Form.Item>
                          <Input
                            className="white-bg"
                            type="text"
                            value={orderShippingAddress?.address2}
                            onChange={(e) => address2Handle(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row key="5">
                    <Col span={24}>
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.City" />
                        </p>
                        <Form.Item>
                          <Input
                            className="white-bg"
                            type="text"
                            value={orderShippingAddress?.city}
                            onChange={(e) => cityHandle(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row key="6">
                    <Col span={24}>
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.PostalCode" />
                        </p>
                        <Form.Item>
                          <Input
                            className="white-bg"
                            type="text"
                            value={orderShippingAddress?.zip}
                            onChange={(e) => zipHandle(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row key="7">
                    <Col span={24}>
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.State" />
                        </p>
                        <Form.Item>
                          <Input
                            className="white-bg"
                            type="text"
                            value={orderShippingAddress?.province}
                            onChange={(e) => stateHandle(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row key="8">
                    <Col span={24}>
                      <div className="large-input">
                        <p className="heading-addresses">
                          <T k="Orders.Label.Country" />
                        </p>
                        <Form.Item>
                          <Input
                            className="white-bg"
                            type="text"
                            value={orderShippingAddress?.country}
                            onChange={(e) => countryHandle(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row key="9">
                    <Col span={24}>
                      <div className="large-input text-right">
                        <Form.Item>
                          <SuccessBtn onClick={() => SaveShippingAddress()}>Save Changes</SuccessBtn>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </Row>
              )}
            </Col>
            {/* This is next */}
            <Col md={11} className="product-details">
              <Row>
                <Col span={24}>
                  <h3 className="heading-details">
                    <T k="Orders.Label.NameOfProduct" />
                  </h3>
                  <Form.Item>
                    <Input className="blue-input" type="text" value={data?.title} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <div className="large-input mt-2">
                    <h3 className="heading-addresses">
                      <T k="Orders.Label.Reference" />
                    </h3>
                    <Form.Item>
                      <Input className="blue-input" type="text" value={data?.reference} />
                    </Form.Item>
                  </div>
                  <div className="large-input mt-2">
                    <h3 className="heading-details">
                      <T k="Orders.Label.DateOfOrder" />
                    </h3>
                    <Form.Item>
                      <Input className="blue-input" type="text" value={ReactUtils.GetFormattedDate(data?.date)} />
                    </Form.Item>
                  </div>

                  <div className="large-input mt-2">
                    <h3 className="heading-details mt-4 mt-xl-0">
                      <T k="Orders.Label.ChannelItem" />
                    </h3>
                    <Form.Item>
                      <Input className="blue-input" type="text" value={data?.channelItem} />
                    </Form.Item>
                  </div>

                  <div className="large-input mt-2">
                    <h3 className="heading-details mt-4 mt-xl-0">
                      <T k="Orders.Label.Quantity" />
                    </h3>
                    <Form.Item>
                      <Input className="blue-input" type="text" value={data?.quantity} />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12} className="d-flex justify-content-center">
                  <img src={data?.imageUrl} className="product-img-order" />
                </Col>
                <Col span={12} className="mt-2">
                  <div className="sourceurl">
                    <h3 className="heading-details">
                      <T k="Orders.Label.SourceURL" />
                    </h3>
                    <A to={data?.sourceUrl ?? ''} target="_blank" rel="noreferrer">
                      <T k='Orders.Label.NewTab'/>
                    </A>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="prices-container">
                    <div className="small-input ">
                      <h3 className="heading-details">
                        <T k="Orders.Label.Sold" />
                      </h3>
                      <Form.Item>
                        <Input className="blue-input" type="text" value={(data?.channelPrice ?? 0) * (data?.quantity ?? 0)} /> {/*ChannelPrice in ebay is per product. It's the same for other platforms?*/}
                      </Form.Item>
                    </div>
                    &nbsp;
                    <div className="small-input ">
                      <h3 className="heading-details">
                        <T k="Orders.Label.Cost" />
                      </h3>
                      <Form.Item>
                        <Input className="blue-input" type="text" value={data?.sourcePrice ? data?.sourcePrice : ' - '} />
                      </Form.Item>
                    </div>
                    &nbsp;
                    <div className="small-input ">
                      <h3 className="heading-details">
                        <T k="Orders.Label.Fees" />
                      </h3>
                      <Form.Item>
                        <Input className="blue-input" type="text" value={data?.fees} />
                      </Form.Item>
                    </div>
                    &nbsp;
                    <div className="small-input ">
                      <h3 className="heading-details">
                        <T k="Orders.Label.Profit" />
                      </h3>
                      <Form.Item>
                        <Input className="blue-input" type="text" value={data?.profit} />
                      </Form.Item>
                    </div>
                    &nbsp;
                    <div className="small-input ">
                      <h3 className="heading-details">
                        <T k="Orders.Label.Margin" />
                      </h3>
                      <Form.Item>
                        <Input className="blue-input" type="text" value={data?.margin} />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
              </Row>
              {isImpersonated && (
                <Row justify="end">
                  <Col>
                    <Text type="secondary">HGR Id :{data?.id}</Text>
                  </Col>
                </Row>
              )}
            </Col>
            <Row>
              <Col className="go-back-orders-container">
                <div onClick={OrderContentModalOpen} className="go-back-details">
                  <IconArrowModal />
                  <span>
                    <T k="Orders.Label.OrderState" />
                  </span>
                </div>
              </Col>
            </Row>
          </Row>
        </>
      )}
    </div>
  );
};
export default OrderDetailsContent;
