import { createSlice } from '@reduxjs/toolkit';
import { KeyValuePair } from '../../components/catalog/Types';
import { GetMissingListings, monitorListing } from './importListingThunk';

export type MissingListing = {
  id: number;
  channelOAuthId: number;
  channelItem: string;
  channelTitle: string
  lastUpdated: Date | string;
  sku: string;
  variationAttributes: KeyValuePair[];
  //Calculated in client
  channelListingId: number;
}

export type ImportListingsState = {
  missingListings: MissingListing[] | null;
  loadingMissing: boolean;
  loading: boolean;
  error: string;
  responseMsg: string;
}

const intialState: ImportListingsState = {
  missingListings: null,
  loadingMissing: false,
  loading: false,
  error: '',
  responseMsg: '',
};

export interface AddExistingListingResponse {
  inserted: string[];
  wereInYourHGR: string[];
  repeated: string[];
  emptyUrl: string[];
  notValidUrl: string[];
  ok: boolean;
  error: string;
}

export const importListingSlice = createSlice({
  name: 'monitorListing',
  initialState: intialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(monitorListing.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(monitorListing.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.responseMsg = payload;
    });
    builder.addCase(monitorListing.rejected, (state, {
      payload }) => {
      state.loading = false;
      state.error = String(payload);
    });

    //MISSING
    builder.addCase(GetMissingListings.pending, (state) => {
      state.loadingMissing = true;
    });
    builder.addCase(GetMissingListings.fulfilled, (state, { payload }) => {
      state.loadingMissing = false;
      state.missingListings = payload;
    });
    builder.addCase(GetMissingListings.rejected, (state) => {
      state.loadingMissing = false;
    });
  }
});


export const { reducer: importListingReducer } = importListingSlice;