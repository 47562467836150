import { SearchOutlined } from '@ant-design/icons';
import { Card, Progress } from 'antd';
import { CatalogProduct, CatalogStatusData } from '../../redux/catalog/catalogSlice';
import { CatalogStatus } from '../../small-components/CatalogStatus';
import { T } from '../../utils/transShim';
import { green } from '@ant-design/colors';
import { eCurrency } from '../../types/eCurrency';

interface Props {
  data: CatalogProduct;
  onClick?: (id: number) => void;
  selected: boolean;
  className?: string;
  status?: CatalogStatusData;
  sourceName: string;
  onOpenDetails?: (id: number) => void;
  currency: eCurrency;
  showCompetition?: boolean;
}

export const CatalogCard = (props: Props) => {
  const { onClick, data, selected, className, status, sourceName, onOpenDetails, currency, showCompetition } = props;

  const HandleSelectProduct = () => {
    onClick?.(data.id);
  };

  const HandleProductModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    onOpenDetails?.(data.id);
  };

  const RenderCompetition = () => {
    return <div>
      <span>
        Sell probability: <span style={{ color: green[4] }}>{data.competition}%</span>
      </span>
      <Progress percent={data.competition} showInfo={false} strokeColor={[green[4]]} />
    </div>;
  };

  return <Card
    className={(className ?? '') + (selected ? ' selected-product-card' : '')}
    onClick={HandleSelectProduct}
  >
    <Card.Meta
      description={
        <>
          <div className="product-description">
            {showCompetition && RenderCompetition()}
            <div className="img-container">
              <img src={data.imageUrl} className="product-img" />
            </div>
            <div className="product-info-area">
              <div className="header">
                <p className="product-title">{data?.title}</p>
                <div className="source-details">
                  <p className="source">
                    <T k='Catalog.By' values={{ name: sourceName }} />
                  </p>
                  <SearchOutlined onClick={HandleProductModal} className="search-child" />
                </div>
              </div>
              <CatalogStatus data={data} status={status} currency={currency} />
            </div>
          </div>
        </>
      }
    />
  </Card>;
};