import { Checkbox, Form, Input } from 'antd';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';
import { PrimaryBtn } from '../../../small-components/ActionBtns';
import { useAppDispatch, useAppSelector } from '../../../custom-hooks/reduxCustomHooks';
import { LoginUser } from 'src/redux/user/userThunk';
import { LocationState, UserData } from '../../../redux/user/userSlice';
import logo from '../../../assets/logo-blanco.svg';
import '../../../sass/user-register.scss';
import { useState } from 'react';
import { Links } from '../../../links';
import { A } from '../../../small-components/A';
import { T } from '../../../utils/transShim';
import { getChannels } from '../../../redux/channels/channelsThunk';

export const UserLogin = withRouter(({ history }: { history: RouteComponentProps['history'] }) => {
  const dispatch = useAppDispatch();

  const { userLoading: usrL } = useAppSelector((state) => state.user);
  const { state } = useLocation<LocationState>();

  const [firstTime, SetFirstTime] = useState<boolean>(true);
  const [error, SetError] = useState<string | null | undefined>(null);

  const userLoading = !firstTime && usrL;
  
  const onFinish = async (values: UserData) => {
    if (firstTime) SetFirstTime(false);
    const rp = await dispatch(LoginUser(values));
    const d = rp.payload as {
      status: number;
      response_errors?: {
        error?: {
          code: string
        }
      }
    };
    if (d?.status == 200) {
      await dispatch(getChannels());
      history.push(state?.from.pathname || Links.Dashboard);
    } else {
      SetError(d?.response_errors?.error?.code);
    }
  };

  return <>
    <div className="register-form-header">
      <div className="text-section">
        <h2 className="register-form-title">
          <T k="Register.LoginTitle" /></h2>
        <p>
          <T k="Register.NotRegistered"/>{' '}
          <A to="/register">
            <T k="Register.Register" />
          </A>
        </p>
      </div>
      <div>
        <img src={logo} alt="" className="logo" />
      </div>
    </div>
    <Form
      className="register-form"
      layout="vertical"
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="on"
    >
      <Form.Item
        label={<T k="Register.EmailAddress" />}
        name="email"
        rules={[
          { required: true, message: <T k="Register.InputEmail" /> },
          { type: 'email', message: <T k="Register.InputEmail" /> }
        ]}
        normalize={(value) => value.trim()}
      >
        <Input className="auth-input" />
      </Form.Item>

      <Form.Item
        label={
          <div className="password-input-label">
            {<T k="Register.Password" />}
            <A to={Links.Credentials.ForgetPassword}>
              <T k="Register.ForgetPassword" />
            </A>
          </div>
        }
        name="password"
        rules={[{ required: true, message: <T k="Register.InputPassword" /> }]}
      >
        <Input.Password className="auth-input" />
      </Form.Item>
      <Form.Item className="alternative">
        <Checkbox><T k="Register.RememberMe" /></Checkbox>
      </Form.Item>
      {error && <div>{<T k={'Notifications.Validation.Error.' + error}/>}</div>}
      <Form.Item>
        <PrimaryBtn htmlType="submit" disabled={userLoading}>
          {userLoading ? 'Please wait...' : 'Log In'}
        </PrimaryBtn>
      </Form.Item>
    </Form>
  </>;
});
