export enum eCurrency {
    Unknown = 0,
    EUR = 1,
    USD = 2,
    GBP = 3,
    CNY = 4,
    JPY = 5,
    CHF = 6,
    AUD = 7,
    NZD = 8,
    CAD = 9
}