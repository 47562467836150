import { createSlice } from '@reduxjs/toolkit';
import { getLinkAccountURL } from '../channels/channelsThunk';
import { CreateNewChannel, getAuthRequest } from './newChannelThunk';

export type LinkAccount = {
  shop: number;
  site: number;
}

export type NewChannelSState = {
  shopURL: string,
  getLinkLoading: boolean,
  newChannelLoading: boolean,
  statusCode: number,
  success: boolean,
  ChannelCreated: boolean,
  error: string
}

const initialState: NewChannelSState = {
  shopURL: '',
  getLinkLoading: false,
  newChannelLoading: false,
  statusCode: 0,
  success: false,
  ChannelCreated: false,
  error: ''
};

export const newChannelSlice = createSlice({
  name: 'new-channel',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get the eBay link account
    builder.addCase(getLinkAccountURL.pending, (state) => {
      state.getLinkLoading = true;
      state.error = '';
    });
    builder.addCase(getLinkAccountURL.fulfilled, (state, { payload }) => {
      state.getLinkLoading = false;
      state.shopURL = payload;
    });
    builder.addCase(getLinkAccountURL.rejected, (state, { payload }) => {
      state.getLinkLoading = false;
      state.error = String(payload);
    });

    // Create new channel 
    builder.addCase(CreateNewChannel.pending, (state) => {
      state.newChannelLoading = true;
      state.error = '';
      state.statusCode = 0;
    });
    builder.addCase(CreateNewChannel.fulfilled, (state, { payload }) => {
      state.newChannelLoading = false;
      state.success = payload?.data?.response_data?.success;
      state.statusCode = payload.status;

    });
    builder.addCase(CreateNewChannel.rejected, (state, { payload }) => {
      state.newChannelLoading = false;
      state.success = false;
      state.error = String(payload);
      state.statusCode = 0;
    });

    // Create new channel 
    builder.addCase(getAuthRequest.pending, (state) => {
      state.newChannelLoading = true;
      state.error = '';
      state.statusCode = 0;
    });
    builder.addCase(getAuthRequest.fulfilled, (state, { payload }) => {
      state.newChannelLoading = false;
      state.ChannelCreated = payload?.data;
      state.statusCode = payload?.status;
    });
    builder.addCase(getAuthRequest.rejected, (state, { payload }) => {
      state.newChannelLoading = false;
      state.ChannelCreated = false;
      state.error = String(payload);
      state.statusCode = 0;
    });
  }
});

export const { reducer: newChannelReducer } = newChannelSlice;
