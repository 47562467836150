import { createAsyncThunk } from '@reduxjs/toolkit';
import { toastAlert } from 'src/utils/toastAlert';
import { eCountry } from '../../data/countries';
import { client } from '../client';

type NewChanel = {
  isoCountry: number;
  channel: number;
  channelStoreIdentifier: string;
}
export enum eApiType {
  NO_API = 0,
  API = 1,
}

export const getAuthRequest = createAsyncThunk('new-channel/receiveAuthRequest', async (params: { [id: string]: string | number}, thunkAPI) => {
  try {
    const res = await client.get('/ChannelOAuth/ProcessReceiveAuthRequest', { params });
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong.');
  }
});

export const CreateNewChannel = createAsyncThunk('new-channel/createNewNoApiChannel', async (data: NewChanel, thunkAPI) => {
  try {
    const res = await client.post('/ChannelOAuth/CreateNoApiChannel', data);
    if (res.status === 200) {
      toastAlert('New Channel successfully created.', 'success');
    }
    return res;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong.');
  }
});

export const paidHostExtension = createAsyncThunk('new-channel/hostExtension', async (_, thunkAPI) => {
  try {
    await client.get('/Onboarding/SendHostExtensionEmail');
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong.');
  }
});

export const CheckPreviousInstallation = createAsyncThunk('new-channel/checkPreviousInstallation', async (data: NewChanel, thunkAPI) => {
  try {
    const rpo = await client.post('/ChannelOAuth/checkPreviousInstallation', data);
    return rpo;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong.');
  }
});

export const SaveStats = createAsyncThunk(
  'Onboarding/SaveStats',
  async (data: {
    token: string;
    external: boolean;
    panel: string;

    channel?: string;
    country?: eCountry;
    hasAccount?: boolean;
    api?: eApiType;
    storeName?: string;
    alreadyExists?: boolean;
    finished?: boolean;
    hostExtension?: boolean;
    //finalChoice?: EOnboardingNewChannelFinalChoice;
    IAmExpert?: boolean;
  },
  thunkAPI
  ) => {
    try {
      const rpo = await client.post('/Onboarding/SaveStats', data);
      return rpo;
    } catch (error) {
      return thunkAPI.rejectWithValue('Sorry! Something went wrong.');
    }
  });