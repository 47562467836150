import { Spin } from 'antd';
import { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Links } from '../../links';

export const ClearAll = withRouter(({ history }: { history: RouteComponentProps['history'] }) => {
  useEffect(() => {
    localStorage.clear();
    history.push(Links.Credentials.Login);
  }, []);

  return <Spin />;
});
