import { createSlice } from '@reduxjs/toolkit';
import { SaveCart } from './checkoutThunk';
import { StoredCheckout } from './data/stored-ids';

export type CheckoutSliceState = {
  cart?: StoredCheckout
};
const initialState: CheckoutSliceState = {

};

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: initialState,
  reducers: {
    logout: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(SaveCart.pending, (state, { meta }) => {
      state.cart = meta.arg;
    });
  }
});
export const { reducer: checkoutReducer, actions } = checkoutSlice;