import { useRouteMatch } from 'react-router';
import { Links } from '../../links';
import '../../sass/user-register.scss';
import { UserLogin } from './login/user-login';
import { QuoteSlider } from './quote-slider';
import { ResetPassword } from './login/reset-password';
import { ForgetPassword } from './login/forget-password';
import amazon from '../../assets/channel/amazon-white-80.png';
import ebay from '../../assets/channel/ebay-white-80.png';
import facebook from '../../assets/channel/facebook-white-80.png';
import woocommerce from '../../assets/channel/woocommerce-white-80.png';
import shopify from '../../assets/channel/shopify-white-80.png';
import { AnimatedComponentList } from '../../small-components/animated-component-list';
import { UserRegister } from './register/user-register';
import { GetQuotes } from './quotes-list';


export const UserAuth = () => {

  const Content = () => {
    //const matchLogin = useRouteMatch(Links.Credentials.Login);
    const matchRegister = useRouteMatch(Links.Credentials.Register.Base);
    const matchForgetPassword = useRouteMatch(Links.Credentials.ForgetPassword);
    const matchResetPassword = useRouteMatch(Links.Credentials.ResetPassword);

    if (matchRegister) {
      return <UserRegister />;
    }
    if (matchForgetPassword) {
      return <ForgetPassword />;
    }
    if (matchResetPassword) {
      return <ResetPassword />;
    }
    return <UserLogin />;
  };

  const channelLogos = [[facebook, 'Facebook'], [shopify, 'Shopify'], [woocommerce, 'Woocommerce'], [amazon, 'Amazon'], [ebay, 'eBay']];

  return (
    <div className="login-area">
      <div className="register-main-container">
        <div className="register-form-container">
          {Content()}
        </div>
        <div className="sell-online-container">
          <div className="sell-online-contents">
            <QuoteSlider quotes={GetQuotes()} seconds={8} />
            <AnimatedComponentList>
              {channelLogos.map((x, i) => <img src={x[0]} title={x[1]} alt={x[1]} key={i} />)}
            </AnimatedComponentList>
          </div>
        </div>
      </div>
    </div>
  );
};
