import { useState } from 'react';
import { Creatable } from './creatable';
import { MultipleSelector } from './multiple-selector';
import { SelectorData, SelectorValue } from './selector';

type Props = Omit<React.ComponentProps<typeof MultipleSelector>, 'allowNotContainedInOptions' | 'showSearch'>  & {
  isValidNewOption?: (value: string, previousValues: SelectorValue[], options: SelectorData[]) => boolean;
  multiple?: boolean;
};

export const CreatableMultiple = (props: Props) => {
  const { isValidNewOption, onSearch } = props;
  const multiple = props.multiple ?? true;
  const [addedOption, SetAddedOption] = useState<string | undefined>(undefined);

  if (!multiple) {
    const cProps = {
      ...props,
      value: props.value ? props.value[0] : props.value,
      defaultValue: props.defaultValue ? props.defaultValue[0] : props.defaultValue,
      onChange: (v: SelectorValue | null) => props.onChange?.(v != null ? [v] : []),
    };
    delete cProps['isValidNewOption'];
    return <Creatable {...cProps} />;
  }

  let children = Array.isArray(props.children) ? props.children : [props.children];
  if (addedOption)
    children = [{ label: addedOption, value: addedOption }, ...children];

  const valuesToAdd: SelectorData[] = [];
  if (props.value != null) {
    for (const v of props.value) {
      if (v && addedOption != v && children.find(x => x.value == v) == null) {
        valuesToAdd.push({ label: v, value: v });
      }
    }
    children = [...valuesToAdd, ...children];
  }
  
  const pProps = { ...props };
  delete pProps['isValidNewOption'];
  return <MultipleSelector
    {...pProps}
    onSearch={(e: string) => {
      if (isValidNewOption) {
        if (!isValidNewOption(e, props.value ?? props.defaultValue ?? [], Array.isArray(props.children) ? props.children : [props.children])) {
          onSearch?.(e);
          return;
        }
      }
      if (e.length > 0) {
        SetAddedOption(e);
      }
      onSearch?.(e);
    }}
    showSearch={true}
  >
    {children}
  </MultipleSelector>;
};