import { eCountry } from './countries';

export enum ePlatform {
  eBay = 1,
  Shopify = 2,
  eBayNoApi = 3,
  Amazon = 4,
  Facebook = 5,
  WooCommerce = 6,
  //Wallapop = 7,
  TikTok = 8
}

export enum DescriptionType {
  FullHTML = 1,
  OnlyBR = 2,
  Plain = 3
}

export type PlatformInfo = {
  name: string;//Example: "eBay No Api"
  storeName: string;//Real name, example "eBay"
  searchEngineName: string;//Usually the marketplace, google if it is not a marketplace
  domain: { [iso: number]: string };
  itemUrl: string | { [iso: number]: string };
  internalUrl: string | { [iso: number]: string };
  useInternalInList: boolean,//For products page
  //identifierName: string;
  //identifierLabel: string | { [iso: number]: string };
  titleLength: number;
  usesAsin: boolean;
  canOptimizeTitle: { [site: number]: boolean };
  canWeListForYou: { [site: number]: boolean };
  categories: {
    has: boolean;
    canSearch: boolean;
  };
  descriptionLength: number | undefined;
  descriptionType: DescriptionType;
  EAN: {
    needed: boolean,
    allowAsin: boolean,
    canBeEmpty: boolean;
  }
  isNoApi: boolean;
  noApi?: {
    customUsername: boolean;
    verifyServer: boolean;
  },
  itemUrlOutUrl: boolean;
}

const Platforms: {
  [id: number]: PlatformInfo
} = {
  [ePlatform.eBay]: {
    name: 'eBay',
    storeName: 'eBay',
    searchEngineName: 'eBay',
    itemUrlOutUrl:true,
    domain: {
      [eCountry.UK]: 'co.uk',
      [eCountry.US]: 'com',
      [eCountry.ES]: 'es',
      [eCountry.DE]: 'de',
      [eCountry.FR]: 'fr',
      [eCountry.AU]: 'com.au',
      [eCountry.IT]: 'it'
    },
    itemUrl: {
      [eCountry.UK]: 'https://www.ebay.co.uk/itm/{id}',
      [eCountry.US]: 'https://www.ebay.com/itm/{id}',
      [eCountry.ES]: 'https://www.ebay.es/itm/{id}',
      [eCountry.DE]: 'https://www.ebay.de/itm/{id}',
      [eCountry.FR]: 'https://www.ebay.fr/itm/{id}',
      [eCountry.AU]: 'https://www.ebay.com.au/itm/{id}',
      [eCountry.IT]: 'https://www.ebay.it/itm/{id}'
    },
    //identifierName: 'id',
    //identifierLabel: {
    //  [eCountry.UK]: 'https://www.ebay.co.uk/itm/{id}',
    //  [eCountry.US]: 'https://www.ebay.com/itm/{id}',
    //  [eCountry.ES]: 'https://www.ebay.es/itm/{id}',
    //  [eCountry.DE]: 'https://www.ebay.de/itm/{id}',
    //  [eCountry.FR]: 'https://www.ebay.fr/itm/{id}',
    //  [eCountry.AU]: 'https://www.ebay.com.au/itm/{id}',
    //  [eCountry.IT]: 'https://www.ebay.it/itm/{id}'
    //},
    useInternalInList: false,
    internalUrl: {},
    titleLength: 80,
    descriptionLength: undefined,
    descriptionType: DescriptionType.FullHTML,
    usesAsin: false,
    canOptimizeTitle: {
      [eCountry.UK]: true,
      [eCountry.US]: true,
      [eCountry.ES]: true,
      [eCountry.DE]: true,
      [eCountry.FR]: true,
      [eCountry.AU]: true,
      [eCountry.IT]: true
    },
    isNoApi: false,
    canWeListForYou: {
      [eCountry.UK]: true,
      [eCountry.US]: true,
      [eCountry.ES]: true,
      [eCountry.DE]: true,
      [eCountry.FR]: true,
      [eCountry.AU]: true,
      [eCountry.IT]: true
    },
    categories: {
      has: true,
      canSearch: true
    },
    EAN: {
      needed: true,
      allowAsin: false,
      canBeEmpty: false,
    }
  },
  [ePlatform.Shopify]: {
    name: 'Shopify',
    storeName: 'Shopify',
    searchEngineName: 'Google',
    itemUrlOutUrl: true,
    domain: {
      [eCountry.UK]: 'co.uk',
      [eCountry.US]: 'com',
      [eCountry.ES]: 'es',
      [eCountry.DE]: 'de',
      [eCountry.FR]: 'fr',
      [eCountry.AU]: 'com.au',
      [eCountry.IT]: 'it'
    },
    itemUrl: 'https://{shopName}/admin/products/{id}',
    useInternalInList: false,
    internalUrl: {},
    //identifierName: 'id',
    //identifierLabel: 'https://{shopName}/admin/products/{id}',
    titleLength: 255,
    descriptionLength: undefined,
    descriptionType: DescriptionType.FullHTML,
    usesAsin: false,
    canOptimizeTitle: {
      [eCountry.UK]: true,
      [eCountry.US]: true,
      [eCountry.ES]: true,
      [eCountry.DE]: true,
      [eCountry.FR]: true,
      [eCountry.AU]: true,
      [eCountry.IT]: true
    },
    isNoApi: false,
    canWeListForYou: {
      [eCountry.UK]: true,
      [eCountry.US]: true,
      [eCountry.ES]: true,
      [eCountry.DE]: true,
      [eCountry.FR]: true,
      [eCountry.AU]: true,
      [eCountry.IT]: true
    },
    categories: {
      has: false,
      canSearch: false
    },
    EAN: {
      needed: true,
      allowAsin: false,
      canBeEmpty: true
    }
  },
  [ePlatform.eBayNoApi]: {
    name: 'eBay NO API',
    storeName: 'eBay',
    searchEngineName: 'eBay',
    itemUrlOutUrl: true,
    //identifierName: 'id',
    domain: {
      [eCountry.UK]: 'co.uk',
      [eCountry.US]: 'com',
      [eCountry.ES]: 'es',
      [eCountry.DE]: 'de',
      [eCountry.FR]: 'fr',
      [eCountry.AU]: 'com.au',
      [eCountry.IT]: 'it'
    },
    itemUrl: {
      [eCountry.UK]: 'https://www.ebay.co.uk/itm/{id}',
      [eCountry.US]: 'https://www.ebay.com/itm/{id}',
      [eCountry.ES]: 'https://www.ebay.es/itm/{id}',
      [eCountry.DE]: 'https://www.ebay.de/itm/{id}',
      [eCountry.FR]: 'https://www.ebay.fr/itm/{id}',
      [eCountry.AU]: 'https://www.ebay.com.au/itm/{id}',
      [eCountry.IT]: 'https://www.ebay.it/itm/{id}'
    },
    useInternalInList: false,
    internalUrl: {},
    //identifierLabel: {
    //  [eCountry.UK]: 'https://www.ebay.co.uk/itm/{id}',
    //  [eCountry.US]: 'https://www.ebay.com/itm/{id}',
    //  [eCountry.ES]: 'https://www.ebay.es/itm/{id}',
    //  [eCountry.DE]: 'https://www.ebay.de/itm/{id}',
    //  [eCountry.FR]: 'https://www.ebay.fr/itm/{id}',
    //  [eCountry.AU]: 'https://www.ebay.com.au/itm/{id}',
    //  [eCountry.IT]: 'https://www.ebay.it/itm/{id}'
    //},
    titleLength: 80,
    descriptionLength: undefined,
    descriptionType: DescriptionType.FullHTML,
    usesAsin: false,
    canOptimizeTitle: {
      [eCountry.UK]: true,
      [eCountry.US]: true,
      [eCountry.ES]: true,
      [eCountry.DE]: true,
      [eCountry.FR]: true,
      [eCountry.AU]: true,
      [eCountry.IT]: true
    },
    isNoApi: true,
    canWeListForYou: {
      [eCountry.UK]: true,
      [eCountry.US]: true,
      [eCountry.ES]: true,
      [eCountry.DE]: true,
      [eCountry.FR]: true,
      [eCountry.AU]: true,
      [eCountry.IT]: true
    },
    categories: {
      has: true,
      canSearch: true
    },
    noApi: {
      customUsername: false,
      verifyServer: true
    },
    EAN: {
      needed: true,
      allowAsin: false,
      canBeEmpty: false
    }
  },
  [ePlatform.Amazon]: {
    name: 'Amazon NO API',
    storeName: 'Amazon',
    searchEngineName: 'Amazon',
    //identifierName: 'sku',
    itemUrlOutUrl: true,
    domain: {
      [eCountry.UK]: 'co.uk',
      [eCountry.US]: 'com',
      [eCountry.ES]: 'es',
      [eCountry.DE]: 'de',
      [eCountry.FR]: 'fr',
      [eCountry.AU]: 'com.au',
      [eCountry.IT]: 'it'
    },
    itemUrl: {
      [eCountry.UK]: 'https://www.amazon.co.uk/dp/{id}',
      [eCountry.US]: 'https://www.amazon.com/dp/{id}',
      [eCountry.ES]: 'https://www.amazon.es/dp/{id}',
      [eCountry.DE]: 'https://www.amazon.de/dp/{id}',
      [eCountry.FR]: 'https://www.amazon.fr/dp/{id}',
      [eCountry.AU]: 'https://www.amazon.com.au/dp/{id}',
      [eCountry.IT]: 'https://www.amazon.it/dp/{id}'
    },
    useInternalInList: true,
    internalUrl: {
      [eCountry.UK]: 'https://sellercentral.amazon.co.uk/inventory/ref=xx_invmgr_dnav_xx?asin={asin}&sku={sku}',
      [eCountry.US]: 'https://sellercentral.amazon.com/inventory/ref=xx_invmgr_dnav_xx?asin={asin}&sku={sku}',
      [eCountry.ES]: 'https://sellercentral.amazon.es/inventory/ref=xx_invmgr_dnav_xx?asin={asin}&sku={sku}',
      [eCountry.DE]: 'https://sellercentral.amazon.de/inventory/ref=xx_invmgr_dnav_xx?asin={asin}&sku={sku}',
      [eCountry.FR]: 'https://sellercentral.amazon.fr/inventory/ref=xx_invmgr_dnav_xx?asin={asin}&sku={sku}',
      [eCountry.AU]: 'https://sellercentral.amazon.au/inventory/ref=xx_invmgr_dnav_xx?asin={asin}&sku={sku}',
      [eCountry.IT]: 'https://sellercentral.amazon.it/inventory/ref=xx_invmgr_dnav_xx?asin={asin}&sku={sku}'
    },
    //identifierLabel: {
    //  [eCountry.UK]: 'https://sellercentral.amazon.co.uk',
    //  [eCountry.US]: 'https://sellercentral.amazon.com',
    //  [eCountry.ES]: 'https://sellercentral.amazon.es',
    //  [eCountry.DE]: 'https://sellercentral.amazon.de',
    //  [eCountry.FR]: 'https://sellercentral.amazon.fr',
    //  [eCountry.AU]: 'https://sellercentral.amazon.au',
    //  [eCountry.IT]: 'https://sellercentral.amazon.it'
    //},
    titleLength: 500,
    descriptionLength: 2000,
    descriptionType: DescriptionType.OnlyBR,
    usesAsin: true,
    canOptimizeTitle: {
      [eCountry.UK]: false,
      [eCountry.US]: false,
      [eCountry.ES]: false,
      [eCountry.DE]: false,
      [eCountry.FR]: false,
      [eCountry.AU]: false,
      [eCountry.IT]: false
    },
    isNoApi: true,
    canWeListForYou: {
      [eCountry.UK]: false,
      [eCountry.US]: false,
      [eCountry.ES]: false,
      [eCountry.DE]: false,
      [eCountry.FR]: false,
      [eCountry.AU]: false,
      [eCountry.IT]: false
    },
    categories: {
      has: true,
      canSearch: true
    },
    noApi: {
      customUsername: true,
      verifyServer: false
    },
    EAN: {
      needed: true,
      allowAsin: true,
      canBeEmpty: true
    }
  },
  [ePlatform.Facebook]: {
    name: 'Facebook NO API',
    storeName: 'Facebook',
    searchEngineName: 'Facebook',
    itemUrlOutUrl: true,
    domain: {
      [eCountry.UK]: 'com',
      [eCountry.US]: 'com',
      [eCountry.ES]: 'com',
      [eCountry.DE]: 'com',
      [eCountry.FR]: 'com',
      [eCountry.AU]: 'com',
      [eCountry.IT]: 'com'
    },
    itemUrl: 'https://www.facebook.com/marketplace/item/{id}',
    useInternalInList: false,
    internalUrl: {},
    //identifierName: 'id',
    //identifierLabel: 'https://www.facebook.com/marketplace/item/{id}',
    titleLength: 99,
    descriptionLength: undefined,
    descriptionType: DescriptionType.Plain,
    usesAsin: false,
    canOptimizeTitle: {
      [eCountry.UK]: true,
      [eCountry.US]: true,
      [eCountry.ES]: true,
      [eCountry.DE]: true,
      [eCountry.FR]: true,
      [eCountry.AU]: true,
      [eCountry.IT]: true
    },
    isNoApi: true,
    canWeListForYou: {
      [eCountry.UK]: true,
      [eCountry.US]: true,
      [eCountry.ES]: true,
      [eCountry.DE]: true,
      [eCountry.FR]: true,
      [eCountry.AU]: true,
      [eCountry.IT]: true
    },
    categories: {
      has: true,
      canSearch: false
    },
    noApi: {
      customUsername: true,
      verifyServer: true
    },
    EAN: {
      needed: false,
      allowAsin: false,
      canBeEmpty: false
    }
  },
  [ePlatform.WooCommerce]: {
    name: 'WooCommerce',
    storeName: 'WooCommerce',
    searchEngineName: 'Google',
    domain: {
      [eCountry.UK]: 'co.uk',
      [eCountry.US]: 'com',
      [eCountry.ES]: 'es',
      [eCountry.DE]: 'de',
      [eCountry.FR]: 'fr',
      [eCountry.AU]: 'com.au',
      [eCountry.IT]: 'it'
    },
    itemUrl: 'https://{shopName}/wp-admin/post.php?post={id}&action=edit',
    itemUrlOutUrl: false,
    useInternalInList: false,
    internalUrl: {},
    //identifierName: 'id',
    //identifierLabel: 'https://{shopName}/wp-admin/post.php?post={id}&action=edit',
    titleLength: 9999,
    descriptionLength: undefined,
    descriptionType: DescriptionType.FullHTML,
    usesAsin: false,
    canOptimizeTitle: {
      [eCountry.UK]: true,
      [eCountry.US]: true,
      [eCountry.ES]: true,
      [eCountry.DE]: true,
      [eCountry.FR]: true,
      [eCountry.AU]: true,
      [eCountry.IT]: true
    },
    isNoApi: false,
    canWeListForYou: {
      [eCountry.UK]: true,
      [eCountry.US]: true,
      [eCountry.ES]: true,
      [eCountry.DE]: true,
      [eCountry.FR]: true,
      [eCountry.AU]: true,
      [eCountry.IT]: true
    },
    categories: {
      has: false,
      canSearch: false
    },
    EAN: {
      needed: false,
      allowAsin: false,
      canBeEmpty: true
    }
  },
  [ePlatform.TikTok]: {
    name: 'TikTok',
    storeName: 'TikTok',
    searchEngineName: 'Google',
    domain: {
      [eCountry.UK]: 'co.uk',
      [eCountry.US]: 'com',
      [eCountry.ES]: 'es',
      [eCountry.DE]: 'de',
      [eCountry.FR]: 'fr',
      [eCountry.AU]: 'com.au',
      [eCountry.IT]: 'it'
    },
    itemUrl: 'https://{shopName}/wp-admin/post.php?post={id}&action=edit',  //THIS IS WRONG
    itemUrlOutUrl: false,
    useInternalInList: false,
    internalUrl: {},
    //identifierName: 'id',
    //identifierLabel: 'https://{shopName}/wp-admin/post.php?post={id}&action=edit',
    titleLength: 255,
    descriptionLength: undefined,
    descriptionType: DescriptionType.FullHTML,
    usesAsin: false,
    canOptimizeTitle: {
      [eCountry.UK]: true,
      [eCountry.US]: true,
      [eCountry.ES]: true,
      [eCountry.DE]: true,
      [eCountry.FR]: true,
      [eCountry.AU]: true,
      [eCountry.IT]: true
    },
    isNoApi: false,
    canWeListForYou: {
      [eCountry.UK]: true,
      [eCountry.US]: true,
      [eCountry.ES]: true,
      [eCountry.DE]: true,
      [eCountry.FR]: true,
      [eCountry.AU]: true,
      [eCountry.IT]: true
    },
    categories: {
      has: false,
      canSearch: false
    },
    EAN: {
      needed: false,
      allowAsin: false,
      canBeEmpty: true
    }
  },
};

export const GetPlatform = (channelId: ePlatform | undefined | null) => Platforms[channelId ?? ePlatform.eBay];

export const PlatformsNoApi = [ePlatform.eBayNoApi, ePlatform.Amazon, ePlatform.Facebook];
export const PlatformsEbay = [ePlatform.eBay, ePlatform.eBayNoApi];
export const IsNoApi = (channelId: ePlatform | undefined | null) => {
  if (channelId == null)
    return false;
  return PlatformsNoApi.find(x => x == channelId) != null;
};
export const IsApi = (channelId: ePlatform | undefined | null) => !IsNoApi(channelId);
export const IsEbay = (channelId: ePlatform | undefined | null) => {
  if (channelId == null)
    return false;
  return PlatformsEbay.find(x => x == channelId) != null;
};