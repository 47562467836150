import { RiseOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import '../../sass/affiliate-dashboard.scss';

import { DataTable } from 'src/small-components/tables/data-table';
import { getAffiliateDashboardGeneral, getAffiliateDashboardMonthly, GetAffiliateWithdrawals, RequestWithdrawal } from 'src/redux/dashboard/affiliatesStatsThunk';
import { useEffect, useState } from 'react';
import { Alert, DatePicker, Divider, Input, Spin, Tabs } from 'antd';
import { Moment } from 'moment';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip } from 'chart.js';
import { useTranslation, T } from '../../utils/transShim';
import moment from 'moment';
import { eCurrency } from '../../types/eCurrency';
import { ReactUtils } from '../../utils/react-utils';
import CopyToClipboard from 'react-copy-to-clipboard';
import { PrimaryBtn, SecondaryBtn } from '../../small-components/ActionBtns';
import miniAlert from 'mini-alert';
import { AffiliateWithdrawalsIntent } from '../../redux/dashboard/affiliatesStatsSlice';
import { WithdrawPayment } from '../../small-components/payment/failed-payment';
import { GetWithdrawalMethods } from '../../redux/payment/paymentThunk';

import { Links } from '../../links';
import { A } from '../../small-components/A';
Chart.register(ArcElement, Tooltip);

export const AffiliateDashboard = () => {
  const dispatch = useAppDispatch();
  const { general, monthly, withdrawals } = useAppSelector((state) => state.affiliatesDashboard);
  const { widthdrawalMethods } = useAppSelector((state) => state.payment);

  const [year, setYear] = useState(new Date().getUTCFullYear());
  const [month, setMonth] = useState(new Date().getUTCMonth() + 1);
  const [currency, SetCurrency] = useState<eCurrency>(eCurrency.EUR);

  useEffect(() => {
    dispatch(getAffiliateDashboardGeneral());
    dispatch(GetAffiliateWithdrawals());
    if (!widthdrawalMethods?.methods) {
      dispatch(GetWithdrawalMethods());
    }
  }, [1]);

  useEffect(() => {
    if (!monthly?.data?.[year]?.[month]) dispatch(getAffiliateDashboardMonthly({ month: month, year: year }));
  }, [month, year]);

  const HandleDatePickerChange = (date: Moment | null, dateString: string) => {
    if (dateString != '') {
      const dates = dateString.split('-');
      setMonth(parseInt(dates[1]));
      setYear(parseInt(dates[0]));
    }
  };

  const affColumns = [
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: <T k="Affiliate.TotalRevenue" />,
      dataIndex: 'totalCommission',
      rowClassName: 'totalCommission',
      render: (v: number) => ReactUtils.DrawPrice(Math.round(100 * v) / 100, currency)
    },
    {
      title: <T k="Affiliate.SubscriptionCommission" />,
      dataIndex: 'subscriptionCommission',
      render: (v: number) => ReactUtils.DrawPrice(Math.round(100 * v) / 100, currency)
    },
    {
      title: <T k="Affiliate.WeListForYou" />,
      dataIndex: 'weListForYouCommission',
      render: (v: number) => ReactUtils.DrawPrice(Math.round(100 * v) / 100, currency)
    },
    {
      title: <T k="Affiliate.Tokens" />,
      dataIndex: 'tokensCommission',
      render: (v: number) => ReactUtils.DrawPrice(Math.round(100 * v) / 100, currency)
    },
    {
      title: <T k="Affiliate.NoApiServer" />,
      dataIndex: 'noApiCommission',
      render: (v: number) => ReactUtils.DrawPrice(Math.round(100 * v) / 100, currency)
    }
  ];

  const HandleCopyText = () => {
    miniAlert({
      overflow: true,
      autoremove: true,
      time: 500,
      size: 'small',
      cartoon: false,
      text: 'Copied!'
    });
  };

  const totalUsers = general?.data?.users ?? 0;
  const totalWithStore = general?.data?.usersWithChannel ?? 0;
  const totalWithStorePer = totalUsers <= 0 ? 0 : (100 * totalWithStore) / totalUsers;
  const totalWithProducts = general?.data?.usersWithProducts ?? 0;
  const totalWithProductsPer = totalUsers <= 0 ? 0 : (100 * totalWithProducts) / totalUsers;

  const totalWithoutShop = totalUsers - totalWithStore;
  const totalWithoutShopPer = totalUsers <= 0 ? 0 : (100 * totalWithoutShop) / totalUsers;
  const totalWithoutProducts = totalWithStore - totalWithProducts;
  const totalWithoutProductsPer = totalUsers <= 0 ? 0 : (100 * totalWithoutProducts) / totalUsers;

  const monthUsers = monthly?.data?.[year]?.[month]?.users ?? 0;
  const monthData = monthly?.data?.[year]?.[month]?.data?.find((x) => x.currency == currency);
  const data = {
    labels: [useTranslation('Affiliate.WithoutStore'), useTranslation('Affiliate.NoProducts'), useTranslation('Affiliate.RegistersProducts')],
    datasets: [
      {
        label: 'Referrals performance',
        data: [totalWithoutShop, totalWithoutProducts, totalWithProducts],
        backgroundColor: ['#262e80', '#3a66ce', '#5e84db'],
        hoverOffset: 5
      }
    ]
  };

  const affiliateTag = general?.data?.affiliateTag
    ? 'https://app.hustlegotreal.com/Register/Landing?src=' + general.data.affiliateTag
    : '';

  const RenderToPay = () => {
    if (!withdrawals || withdrawals.loading || !widthdrawalMethods || widthdrawalMethods.loading)
      return <Spin />;

    const paidByCurrency: { [idcurrency: number]: number } = {};
    const errorByCurrency: { [idcurrency: number]: AffiliateWithdrawalsIntent } = {};
    for (const w of (withdrawals.withdrawals ?? [])) {
      if (w.success)
        paidByCurrency[w.currency] = (paidByCurrency[w.currency] ?? 0) + w.amountDue;
      else
        errorByCurrency[w.currency] = w;//In case there is an error it will be only the last one, server won't send previous errored
    }

    const toPay = (general?.data?.totalCommisionWithdrawable?.find((x) => x.currency == currency)?.amount ?? paidByCurrency[currency] ?? 0) - (paidByCurrency[currency] ?? 0);

    if (toPay == 0)
      return <></>;

    const RenderContent = () => {
      if (withdrawals.queues?.find(x => x == currency) != null || (errorByCurrency[currency] != null && errorByCurrency[currency].success == null)) {//if there is one errorByCurrency but success is not false, it means it is being processed right now
        return <p><T k='Affiliate.ProcessingWithdraw' /></p>;
      }


      const Button = () => {

        const HandleWithdraw = () => {
          dispatch(RequestWithdrawal({ currency }));
        };

        if (!widthdrawalMethods?.methods?.find(x => x.currency == currency)) {
          return <A to={Links.Configuration.Account.WithdrawalMethod}><SecondaryBtn><T k='Affiliate.Withdraw' /></SecondaryBtn></A>;
        }
        return <SecondaryBtn onClick={HandleWithdraw}><T k='Affiliate.Withdraw' /></SecondaryBtn>;
      };

      if (toPay < 20)
        return <p>
          <T k='Affiliate.WithdrawLessThan20' values={{ g: ReactUtils.DrawPrice(toPay, currency), d: ReactUtils.DrawPrice(20, currency) }} />
        </p>;

      return <>
        {errorByCurrency[currency] != null && errorByCurrency[currency].success == false && <Alert message={<WithdrawPayment error={errorByCurrency[currency].errorType} />} type="error" />}
        <p>
          <T k='Affiliate.ToWithdraw' />{' '}{ReactUtils.DrawPrice(toPay, currency)}{' '}{Button()}{' '}<T k='Affiliate.NoMoreThan1y' />
        </p>
      </>;
    };

    return <div className='withdraw-line'>
      {RenderContent()}
    </div>;
  };

  return (
    <div className="affiliate-dashboard-container">
      <div className="affiliate-dashboard">
        <div>
          <Tabs
            activeKey={currency.toString()}
            onChange={(v) => SetCurrency(parseInt(v) as eCurrency)}
            items={[
              {
                label: eCurrency[eCurrency.EUR],
                key: eCurrency.EUR.toString()
              },
              {
                label: eCurrency[eCurrency.GBP],
                key: eCurrency.GBP.toString()
              },
              {
                label: eCurrency[eCurrency.USD],
                key: eCurrency.USD.toString()
              }
            ]}
          />
        </div>
        {RenderToPay()}
        <h2 className="main-title">
          <T k="Affiliate.Overview" />
          {general?.loading && <Spin />}
        </h2>
        <div className="first-section">
          <div className="pie-chart-section">
            <div className="pie-chart">
              <Doughnut data={data} />
            </div>
            <div className="stats-pie-chart">
              <div className="total-registers">
                <h5>
                  <T k="Affiliate.TotalRegistersQP" values={{ q: totalUsers }} />
                </h5>
              </div>
              <div className="registers-no-store">
                <h5>
                  <T k="Affiliate.WithoutStoreQP" values={{ q: totalWithoutShop, per: Math.round(totalWithoutShopPer) }} />
                </h5>
              </div>
              <div className="registers-w-store">
                <h5>
                  <T k="Affiliate.RegistersStoreQP" values={{ q: totalWithStore, per: Math.round(totalWithStorePer) }} />
                </h5>
              </div>
              <div className="registers-w-no-products">
                <h5>
                  <T k="Affiliate.RegistersNoProductsQP" values={{ q: totalWithoutProducts, per: Math.round(totalWithoutProductsPer) }} />
                </h5>
              </div>
              <div className="registers-w-products">
                <h5>
                  <T k="Affiliate.RegistersProductsQP" values={{ q: totalWithProducts, per: Math.round(totalWithProductsPer) }} />
                </h5>
              </div>
            </div>
          </div>
          <div className="general-stats-container">
            <div className="general-stats">
              <div className="general-stat">
                <h4>
                  <T k="Affiliate.Commission" />
                </h4>
                <div className="circle-with-text">
                  <div className="circle-with-text2">
                    <h3 className="general-stat-content">{general?.data?.percentageComission ?? ''}%</h3>
                  </div>
                </div>
              </div>
              <div className="general-stat">
                <h4>
                  <T k="Affiliate.TotalRevenue" />
                </h4>
                <div className="circle-with-text">
                  <div className="circle-with-text2">
                    <h3 className="general-stat-content">
                      {ReactUtils.DrawPrice(general?.data?.totalCommision?.find((x) => x.currency == currency)?.amount ?? 0, currency)}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-actions">
          <Input type="text" value={affiliateTag} className="text-input" />
          <CopyToClipboard text={affiliateTag} onCopy={HandleCopyText}>
            <PrimaryBtn>
              <T k="copy" />
            </PrimaryBtn>
          </CopyToClipboard>
        </div>
        <Divider />
        <h2 className="main-title">
          <T k="Affiliate.MonthRevenue" />
          {monthly?.loading && <Spin />}
        </h2>
        <div className="info-section">
          <div className="month-selector">
            <DatePicker onChange={HandleDatePickerChange} picker="month" value={moment(new Date(year, month - 1, 1))} />
          </div>
          <div className="stats">
            <div className="stat">
              <div className="stat-header">
                <h4 className="stat-title">
                  <T k="Affiliate.Registers" />
                </h4>
                <UserOutlined />
              </div>
              <h3 className="stat-content">{monthUsers}</h3>
            </div>

            <div className="stat">
              <div className="stat-header">
                <h4 className="stat-title">
                  <T k="Affiliate.Revenue" />
                </h4>
                <RiseOutlined />
              </div>
              <h3 className="stat-content">{ReactUtils.DrawPrice(monthData?.totalSubscriptionCommission ?? 0, currency)}</h3>
            </div>
            <div className="stat">
              <div className="stat-header">
                <h4 className="stat-title">
                  <T k="Affiliate.RegisterRevenue" />
                </h4>
                <UserAddOutlined />
              </div>
              <h3 className="stat-content">
                {ReactUtils.DrawPrice(monthUsers <= 0 ? 0 : (monthData?.totalSubscriptionCommission ?? 0) / monthUsers, currency)}
              </h3>
            </div>
          </div>

          <div className="table-stats">
            <h2 className="main-title">
              <T k="Affiliate.RevenueReferrals" />
              {monthly?.loading && <Spin />}
            </h2>
            <DataTable dataSource={monthData?.history?.map((x) => ({ ...x, key: x.id })) ?? []} columns={affColumns} />
          </div>
          <Divider />
        </div>
      </div>
    </div>
  );
};
