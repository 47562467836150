import { eAccountSettings } from '../../../redux/account-configuration/account-configuration-slice';
import { GSetting, SettingType, TExtra } from '../account/generic/generic-setting-input';
import { AccountSettingSection } from './sections';

interface Ancestor {
  Field: eAccountSettings;
  Value: string;
}
export interface AccountSetting extends GSetting<eAccountSettings> {
  Labels: string[];
  Description: string[];
  Type: SettingType;
  Section: AccountSettingSection;
  Fields: eAccountSettings[];
  Values: (string | null)[];
  ChannelIds?: number[]; //If undefined it accepts all the channels
  Ancestors?: Ancestor[];
  AncestorsHide?: boolean;
  PlaceHolder?: string;
  Extra?: TExtra[];
  Mandatory?: boolean;
}

export const AccountSettings: AccountSetting[] = [
  {
    Labels: ['Account.Setting.Name.BusinessRegisteredCountry'],
    Description: ['Account.Setting.Description.BusinessRegisteredCountry'],
    Type: SettingType.List, 
    Section: AccountSettingSection.BillingAddress,
    Fields: [eAccountSettings.Country],
    Values: ['0'],
    Extra: [TExtra.CountriesList],
    Mandatory: true
  },
  {
    Labels: ['Account.Setting.Name.BusinessType'],
    Description: ['Account.Setting.Description.BusinessType'],
    Type: SettingType.List,
    Section: AccountSettingSection.BillingAddress,
    Fields: [eAccountSettings.BusinessType],
    Values: ['0'],
    Extra: [TExtra.BusinessTypeList],
    Mandatory: true
  },
  {
    Labels: ['Account.Setting.Name.BusinessName'],
    Description: ['Account.Setting.Description.BusinessName'],
    Type: SettingType.String,
    Section: AccountSettingSection.BillingAddress,
    Fields: [eAccountSettings.Name],
    Values: [''],
    Mandatory: true
  },
  {
    Labels: ['Account.Setting.Name.Address'],
    Description: ['Account.Setting.Description.Address'],
    Type: SettingType.String,
    Section: AccountSettingSection.BillingAddress,
    Fields: [eAccountSettings.Address1],
    PlaceHolder: 'Address Line 1',
    Values: ['']
  },
  {
    Labels: [''],
    Description: ['Account.Setting.Description.Address'],
    Type: SettingType.String,
    Section: AccountSettingSection.BillingAddress,
    Fields: [eAccountSettings.Address2],
    PlaceHolder: 'Address Line 2',
    Values: [''],
  },
  {
    Labels: ['Account.Setting.Name.City'],
    Description: ['Account.Setting.Description.City'],
    Type: SettingType.String,
    Section: AccountSettingSection.BillingAddress,
    Fields: [eAccountSettings.City],
    Values: [''],
  },
  {
    Labels: ['Account.Setting.Name.Postcode'],
    Description: ['Account.Setting.Description.Postcode'],
    Type: SettingType.String,
    Section: AccountSettingSection.BillingAddress,
    Fields: [eAccountSettings.Postcode],
    Values: [''],
  },
  {
    Labels: ['Account.Setting.Name.RegistrationNumber'],
    Description: ['Account.Setting.Description.RegistrationNumber'],
    Type: SettingType.String,
    Section: AccountSettingSection.BillingAddress,
    Fields: [eAccountSettings.Rn],
    Values: [''],
  },
  {
    Labels: ['Account.Setting.Name.VATNumber'],
    Description: ['Account.Setting.Description.VATNumber'],
    Type: SettingType.String,
    Section: AccountSettingSection.BillingAddress,
    Fields: [eAccountSettings.Vat],
    Values: [''],
  }
  //,
  //{
  //  Labels: ['Account.Setting.Name.SaveAll'],
  //  Description: [''],
  //  Type: SettingType.Button,
  //  Section: AccountSettingSection.BillingAddress,
  //  Fields: [eAccountSettings.None],
  //  Values: [''],
  //}
];
