import { Card, Checkbox, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ColumnData, ColumnId } from '../../../../small-components/tables/types/columns';
import { T } from '../../../../utils/transShim';
import { PopupModal } from '../../../../small-components/modals/popup-modal';

interface Props<RecordType> {
  onClose: () => void;
  isVisible: boolean;
  allColumns: ColumnData<RecordType>[];
  visibleColumns: ColumnId[];
  onChange: (newVisible: ColumnId[]) => void;
}
//eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
export const VisibleColumnsPopup = <RecordType extends object = any>(props: Props<RecordType>) => {
  const { isVisible, onClose, allColumns, visibleColumns, onChange } = props;

  const handleCheckBox = (e: CheckboxChangeEvent) => {
    const newVisibleC = new Set<number>(visibleColumns);
    const value = parseInt(e.target.value);
    if (e.target.checked) {
      newVisibleC.add(value);
    } else {
      newVisibleC.delete(value);
    }

    const result = Array.from(newVisibleC) as ColumnId[];
    onChange(result);
  };

  const ColumnRow = (col: ColumnData<RecordType>) => {
    const isVisible = visibleColumns.includes(col.id);

    return (
      <li key={col.id}>
        <Checkbox className="checkbox" checked={isVisible} value={col.id} onChange={handleCheckBox}>
          {typeof (col.title) === 'string' ? <T k={col.title} /> : col.title}
        </Checkbox>
      </li>
    );
  };

  return (
    <PopupModal onClose={onClose} maxWidth={900} open={isVisible}>
      <h5 className="cols-display-title"><T k='VisibleColumnsPopup.Title' /></h5>
      <p className="description"><T k='VisibleColumnsPopup.Description' /></p>
      <Card className="listings-card">
        <Row className="listings-cols">
          {/*<Col xs={16}>*/}
          <ul className="cols-list">
            {allColumns.map(ColumnRow)}
          </ul>
          {/*</Col>*/}
          {/*<Col xs={8}>*/}
          {/*  <div className="cols-amount">*/}
          {/*    <p><T k='VisibleColumnsPopup.Amount' /></p>*/}
          {/*    <h3>{visibleColumns.length}</h3>*/}
          {/*  </div>*/}
          {/*</Col>*/}
        </Row>
      </Card>
    </PopupModal>
  );
};