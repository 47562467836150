import { createAsyncThunk } from '@reduxjs/toolkit';
import { toastAlert } from '../../utils/toastAlert';
import { client } from '../client';
import { WithdrawalMethod } from './data/withdrawal-method';
import { ConfigurePaymentMethodRequest, CreateCheckoutSessionRequest, GetCheckoutInformationRequest, RemovePaymentRequest, SaveCurrenciesRequest } from './paymentSlice';

export const CreateCheckoutSession = createAsyncThunk('Payment/CreateCheckoutSession', async (data: CreateCheckoutSessionRequest, thunkAPI) => {
  try {
    const res = await client.post('Payment/CreateCheckoutSession', data);
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});

export const PostRemovePayment = createAsyncThunk('Payment/RemovePayment', async (data: RemovePaymentRequest, thunkAPI) => {
  try {
    const res = await client.post('Payment/RemovePayment', data);
    if (res.status === 200) toastAlert('Payment method removed successfully!', 'success');
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});

export const SaveCurrencies = createAsyncThunk('Payment/SaveCurrencies', async (data: SaveCurrenciesRequest[], thunkAPI) => {
  try {
    const res = await client.post('Payment/SaveCurrencies', { data: data });
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});

export const RetryPayment = createAsyncThunk('Payment/RetryPayment', async (id: number, thunkAPI) => {
  try {
    const res = await client.post('Payment/RetryPayment', { id:id });
    return res.data.response_data.responseObject;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});

export const ConfigurePaymentMethod = createAsyncThunk('Payment/ConfigurePaymentMethod', async (data: ConfigurePaymentMethodRequest, thunkAPI) => {
  try {
    const res = await client.post('Payment/Configure', data);
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});

export const SendCheckoutInformation = createAsyncThunk('Payment/SendCheckoutInformation', async (data: GetCheckoutInformationRequest, thunkAPI) => {
  try {
    const res = await client.post('Payment/SendCheckoutInformation', data);
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});

export const GetPaymentMethods = createAsyncThunk('Payment/GetPaymentMethods', async (_, thunkAPI) => {
  try {
    const res = await client.get('Payment/GetPaymentMethods');
    return res.data.response_data.responseObject.methods;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});

export const GetPendingPayments = createAsyncThunk('Payment/GetPendingPayments', async (_, thunkAPI) => {
  try {
    const res = await client.get('Payment/GetPendingPayments');
    return res.data.response_data.responseObject.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});


export const GetWithdrawalMethods = createAsyncThunk('Payment/GetWithdrawalMethods', async (_, thunkAPI) => {
  try {
    const res = await client.get('Payment/GetWithdrawalMethods');
    return res.data.response_data.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const SaveWithdrawalMethod = createAsyncThunk('Payment/SaveWithdrawalMethod', async (rq: WithdrawalMethod, thunkAPI) => {
  try {
    const res = await client.post('Payment/SaveWithdrawalMethod',rq);
    return res.data.response_data.success;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});