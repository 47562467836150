import { CloseIcon } from '../../small-components/CloseIcon';
import '../../sass/all-products.scss';
import { T } from '../../utils/transShim';
import { useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { Col, Row } from 'antd';
import { ReactUtils } from '../../utils/react-utils';
import { CatalogProduct } from '../../redux/catalog/catalogSlice';
import { eCurrency } from '../../types/eCurrency';
interface Props {
  children: CatalogProduct[];
  removeProduct: (id: number) => void;
  className: string;
  currency: eCurrency;
}

export const AllProducts = (props: Props) => {
  const { children, removeProduct, className, currency } = props;

  const { sources } = useAppSelector((state) => state.sources);
  const GetSourceName = (id: number) => sources?.find(x => x.id == id)?.name;

  return (
    <div className="selected-products-modal">
      {children.length ? (
        children?.map((c) => (
          <div className="product-card" key={c.id}>
            <img src={c.imageUrl} alt="" className="product-img" />
            <div className="product-info-area">
              <div className="header">
                <p className="title">
                  {c?.title}
                </p>
                <p className="source"><T k={'Catalog.By'} values={{ name:GetSourceName(c.sourceId) ?? '' }}/></p>
              </div>
              <Row className="transaction-details">
                <Col span={7}>
                  <p className="transaction-type"><T k='Catalog.Sell' /></p>
                  <p className="transaction-amount sell">
                    <span>&pound;</span>
                    {ReactUtils.DrawPrice(c.channelPrice, currency)}
                  </p>
                </Col>
                <Col span={7}>
                  <p className="transaction-type"><T k='Catalog.Cost'/></p>
                  <p className="transaction-amount cost">
                    <span>&pound;</span>
                    {ReactUtils.DrawPrice(c.sourcePrice, currency)}
                  </p>
                </Col>
                <Col span={7}>
                  <p className="transaction-type"><T k='Catalog.Profit'/></p>
                  <p className="transaction-amount profit">
                    {ReactUtils.DrawPrice(c.profit,currency)}
                  </p>
                </Col>
                <Col span={3}>
                  <CloseIcon className={`close-icon ${className}`} onClick={_ => removeProduct(c.id)} />
                </Col>
              </Row>
            </div>
          </div>
        ))
      ) : (
        <p>No selected products yet.</p>
      )}
    </div>
  );
};
