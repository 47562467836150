import { Col, Row } from 'antd';
import { ReactNode } from 'react';
import { CountriesRelation, CountriesOption } from '../../../../data/countries';
import { businessTypes, BusinessTypes } from '../../../../data/businessTypes';
import { Account } from '../../../../redux/account-configuration/account-configuration-slice';
import '../../../../sass/settings/settings.scss';
import { useTranslation, T, TransLinksValues, TransPlatformValues } from '../../../../utils/transShim';
import { SettingTwoOptions } from '../../../../small-components/settings/settings-two-options';
import { SettingBooleanTwoOptions } from '../../../../small-components/settings/settings-boolean-two-options';
import { SettingNumber } from '../../../../small-components/settings/setting-number';
import { SettingString } from '../../../../small-components/settings/setting-string';
import { SettingBoolean } from '../../../../small-components/settings/setting-boolean';
import { SettingBooleanNumber } from '../../../../small-components/settings/setting-boolean-number';
import { SettingBooleanStringNull } from '../../../../small-components/settings/setting-boolean-string-null';
import { SettingBooleanString } from '../../../../small-components/settings/setting-boolean-string';
import { ListData, SettingList } from '../../../../small-components/settings/setting-list';
import { SettingWordList } from '../../../../small-components/settings/setting-word-list';
import { SettingButton } from '../../../../small-components/settings/setting-button';

interface SettingDataBagData<G> {
  data: G | undefined;
  loading: boolean;
}
export enum SettingType {
  Number,
  Boolean,
  String,
  List,
  WordList,
  BooleanNumber,
  BooleanString,
  BooleanStringNull,
  SwitchTwoOptions,
  TwoOptions,
  Button
}

export interface SettingDataBag {
  refreshBussiness?: SettingDataBagData<boolean>;
  countries?: SettingDataBagData<CountriesOption[]>;
  businessTypes?: SettingDataBagData<BusinessTypes>;
  selectedAccount?: Account | undefined;
}
//When it is something special, like a list with values that come from the API or a different call to store that
export enum TExtra {
  CountriesList, BusinessTypeList
}

interface GAncestor<T extends number> {
  Field: T;
  Value: string;
}

export type GSetting<T extends number> = {
  Labels: string[];
  Description: string[];
  Type: SettingType;
  Fields: T[];
  Values: (string | null)[];
  ChannelIds?: number[]; //If undefined it accepts all the channels
  Ancestors?: GAncestor<T>[];
  AncestorsHide?: boolean;
  PlaceHolder?: string;
  Extra?: TExtra[];
  Mandatory?: boolean;
};

export type SettingsValue = string | null;
export interface GenericSettingKey<T extends number> {
  key: T;
  value: SettingsValue;
}
export interface GenericSavingSetting<T extends number>{
  loading: boolean;
  success: boolean;
  data: GenericSettingKey<T>;
}

interface Props<T extends number, T2 extends GSetting<T>> {
  setting: T2;
  currentSettingValues: Map<T, SettingsValue>;
  savingSetting?: Map<T, GenericSavingSetting<T>>;
  onSave: (key: T, value: SettingsValue) => void;
  translationValues: TransPlatformValues | TransLinksValues;
  dataBag: SettingDataBag
  onButtonClick?: () => void,
  allFieldsInfo: T2[],
  delayToSave: number,
  fullWidth?: boolean
}

export const GenericSettingInput = <T extends number, T2 extends GSetting<T>,>(props: Props<T, T2>) => {
  const { setting, currentSettingValues: configuration, savingSetting, onSave, translationValues, dataBag, onButtonClick, allFieldsInfo, delayToSave, fullWidth } = props;
  const TT = (k: string) => <T k={k} values={translationValues} />;
  const businessD = businessTypes.map(x => ({ ...x, name: useTranslation(x.name) as string }));

  const disabled = ((setting: T2) => {
    if (!setting.Ancestors)
      return false;

    for (const se of setting?.Ancestors ?? []) {
      if (configuration.has(se.Field)) {
        if (configuration.get(se.Field) != se.Value) {
          return true;
        }
      } else {//User doesn't have defined a value for this setting, so we will get the default value
        for (const cs of allFieldsInfo) {
          for (let i = 0; i < cs.Fields.length; i++) {//O(n^3)... not the best but it is a small quantity of data, no problem
            if (cs.Fields[i] == se.Field && cs.Values[i] != se.Value) {//Field[n] and DefaultValues[n] should be related
              return true;
            }
          }
        }
      }
    }

    return false;
  })(setting);

  if (disabled && setting.AncestorsHide)
    return <></>;

  const RenderSettingTwoOptions = (labels: string[], values: SettingsValue[], fields: T[], disabled: boolean) => {
    const value1 = configuration?.get(fields[0]) ?? values[0];
    const value2 = configuration?.get(fields[1]) ?? values[1];
    const check1Value = values[2];
    const check2Value = values[3];
    const label1 = labels[1];
    const label2 = labels[2];
    const savingState1 = savingSetting?.get(fields[0]);
    const savingState2 = savingSetting?.get(fields[1]);
    return (
      <Col span={fullWidth ? 24 : 8} className='limit-section'>
        <SettingTwoOptions
          value1={value1}
          value2={value2}
          check1Value={check1Value ?? ''}
          check2Value={check2Value ?? ''}
          label1={TT(label1)}
          label2={TT(label2)}
          onChange1={v => onSave(fields[0], v)}
          onChange2={v => onSave(fields[1], v)}
          disabled={disabled}
          loading1={savingState1?.loading ?? false}
          loading2={savingState2?.loading ?? false}
          delayToSave={delayToSave ?? 0}
        />
      </Col>
    );
  };

  const RenderSettingSwitchTwoOptions = (labels: string[], values: SettingsValue[], fields: T[], disabled: boolean) => {
    const value1 = configuration?.get(fields[0]) ?? values[0];
    const value2 = configuration?.get(fields[1]) ?? values[1];
    const check1Value = values[2];
    const defaultNumberValue = values[3];
    const label1 = labels[1];
    const label2 = labels[2];
    const savingState1 = savingSetting?.get(fields[0]);
    const savingState2 = savingSetting?.get(fields[1]);
    return (
      <Col span={fullWidth ? 24 : 8} className='limit-section'>
        <SettingBooleanTwoOptions
          value1={value1 ?? undefined}
          value2={value2 ?? undefined}
          defaultValue1={value1 ?? ''}
          defaultValue2={value2 ?? ''}
          defaultNumberValue1={check1Value ?? ''}
          defaultNumberValue2={defaultNumberValue ?? ''}
          label1={TT(label1)}
          label2={TT(label2)}
          onChange1={v => onSave(fields[0], v)}
          onChange2={v => onSave(fields[1], v)}
          disabled={disabled}
          loading1={savingState1?.loading ?? false}
          loading2={savingState2?.loading ?? false}
          delayToSave={delayToSave ?? 0}
        />
      </Col>
    );
  };

  const RenderSettingNumber = (values: SettingsValue[], fields: T[], disabled: boolean) => {
    const savingState = savingSetting?.get(fields[0]);
    const value = configuration?.get(fields[0]) ?? values[0];
    return (
      <Col span={fullWidth ? 24 : 8} className='input-container'>
        <SettingNumber
          value={value}
          onChange={v => onSave(fields[0], v)}
          key={fields[0]}
          loading={savingState?.loading ?? false}
          disabled={disabled}
          min={values[1]}
          max={values[2]}
          delayToSave={delayToSave ?? 0}
        />
      </Col>
    );
  };

  const RenderSettingString = (values: SettingsValue[], fields: T[], extra: TExtra[] | undefined, disabled: boolean) => {
    const savingState = savingSetting?.get(fields[0]);
    const value = configuration?.get(fields[0]) ?? values[0];
    return (
      <Col span={24} className='input-container'>
        <SettingString
          value={value ?? ''}
          onChange={v => onSave(fields[0], v)}
          key={fields[0]}
          loading={savingState?.loading ?? false}
          placeholder={setting?.PlaceHolder}
          disabled={disabled}
          delayToSave={delayToSave ?? 0}
        />
      </Col>
    );
  };

  const RenderSettingBoolean = (values: SettingsValue[], fields: T[], disabled: boolean) => {
    const savingState = savingSetting?.get(fields[0]);
    const value = configuration?.get(fields[0]) ?? values[0];
    return (
      <Col span={fullWidth ? 24 : 8} className='switch-container'>
        <SettingBoolean
          value={value ?? '0'}
          onChange={v => onSave(fields[0], v)}
          loading={savingState?.loading ?? false}
          disabled={disabled}
        />
      </Col>
    );
  };

  const RenderBooleanNumber = (values: SettingsValue[], fields: T[], disabled: boolean) => {
    const savingState1 = savingSetting?.get(fields[0]);
    const savingState2 = savingSetting?.get(fields[1]);
    const defaultValue1 = values[0];
    const defaultValue2 = values[1];
    const value1 = configuration?.get(fields[0]) ?? defaultValue1;
    const value2 = configuration?.get(fields[1]) ?? defaultValue2;
    return (
      <Col span={fullWidth ? 24 : 8} className='switch-container'>
        <SettingBooleanNumber
          value1={value1 ?? undefined}
          value2={value2 ?? undefined}
          defaultValue1={value1 ?? ''}
          defaultValue2={value2 ?? ''}
          onChange1={v => onSave(fields[0], v)}
          onChange2={v => onSave(fields[1], v)}
          loading1={savingState1?.loading ?? false}
          loading2={savingState2?.loading ?? false}
          disabled={disabled}
          delayToSave={delayToSave ?? 0}
        />
      </Col>
    );
  };

  const RenderBooleanString = (values: SettingsValue[], fields: T[], disabled: boolean) => {
    const savingState1 = savingSetting?.get(fields[0]);
    const savingState2 = savingSetting?.get(fields[1]);
    const defaultValue1 = values[0];
    const defaultValue2 = values[1];
    const value1 = configuration?.get(fields[0]) ?? defaultValue1;
    const value2 = configuration?.get(fields[1]) ?? defaultValue2;
    return (
      <Col span={fullWidth ? 24 : 8} className='switch-container'>
        <SettingBooleanString
          value1={value1 ?? undefined}
          value2={value2 ?? undefined}
          defaultValue1={value1 ?? ''}
          defaultValue2={value2 ?? ''}
          onChange1={v => onSave(fields[0], v)}
          onChange2={v => onSave(fields[1], v)}
          loading1={savingState1?.loading ?? false}
          loading2={savingState2?.loading ?? false}
          disabled={disabled}
          delayToSave={delayToSave ?? 0}
        />
      </Col>
    );
  };

  const RenderBooleanStringNull = (values: SettingsValue[], fields: T[], disabled: boolean) => {
    const savingState = savingSetting?.get(fields[0]);
    const defaultValue = values[0];
    const defaultStringValue = values[1];
    const value = configuration?.get(fields[0]) ?? defaultValue;
    return (
      <Col span={fullWidth ? 24 : 8} className='switch-container'>
        <SettingBooleanStringNull
          value={value ?? undefined}
          defaultValue={value}
          defaultStringValue={defaultStringValue ?? ''}
          onChange={v => onSave(fields[0], v)}
          loading={savingState?.loading ?? false}
          disabled={disabled}
          delayToSave={delayToSave ?? 0}
        />
      </Col>
    );
  };

  const RenderSettingList = (
    values: SettingsValue[],
    fields: T[],
    extra: TExtra[] | undefined,
    disabled: boolean
  ) => {
    const savingState = savingSetting?.get(fields[0]);
    const value = configuration?.get(fields[0]) ?? values[0];

    const listValues: ListData[] = [];
    for (let i = 1; i < values.length; i += 2) {
      listValues.push({ value: values[i] ?? '', label: TT(values[i + 1] ?? '') });
    }

    const loadingData = false;

    const AA = (data?: { data: { name: string; id: { toString: () => string; }; }[] }) => {
      const ds = data?.data ?? [];
      if (ds.length > 0) {
        listValues.push(...(ds.map((x: { name: string; id: { toString: () => string; }; }) => ({ label: x.name, value: x.id?.toString() })) ?? []));
      }
    };

    for (const e of extra ?? []) {
      switch (e) {
        case TExtra.CountriesList:
          AA({ data: CountriesRelation });
          break;
        case TExtra.BusinessTypeList:
          AA({
            data: businessD
          });
          break;
      }
    }

    return (
      <Col span={8} className='input-container'>
        <SettingList
          value={value ?? ''}
          listData={listValues}
          onChange={v => onSave(fields[0], v)}
          key={fields[0]}
          loadingData={loadingData}
          loading={savingState?.loading ?? false}
          disabled={disabled}
          placeholder={setting.PlaceHolder}
        />
      </Col>
    );
  };

  const RenderWordList = (values: SettingsValue[], fields: T[], disabled: boolean) => {
    const savingState = savingSetting?.get(fields[0]);
    const value = configuration?.get(fields[0]) ?? values[0];

    return (
      <Col span={fullWidth ? 24 : 8} className='input-container'>
        <SettingWordList
          value={value ?? ''}
          onChange={v => onSave(fields[0], v)}
          key={fields[0]}
          loading={savingState?.loading ?? false}
          disabled={disabled}
          delayToSave={delayToSave ?? 0}
        />
      </Col>
    );
  };

  const RenderButton = (values: SettingsValue[], extra: TExtra[] | undefined, disabled: boolean, dataBag: SettingDataBag) => {
    let loading = false;
    let label = TT(values[0] ?? 'Save All');
    for (const e of extra ?? []) {
      switch (e) {
        case TExtra.BusinessTypeList:
          loading = loading || (dataBag.refreshBussiness?.loading ?? false);
          if (dataBag.refreshBussiness?.data ?? false) {
            label = TT('Account.Setting.Name.SaveAll');
            disabled = true;
          }
          break;
      }
    }
    return <SettingButton label={'Save All ' + label} loading={loading} disabled={disabled} onClick={onButtonClick ?? (() => { return undefined; })} />;
  };

  const values = ((setting: T2) => {
    let translate = false;
    for (const e of setting?.Extra ?? []) {
      if (e == TExtra.BusinessTypeList) {
        translate = true;
        break;
      }
    }
    if (!translate) {
      return setting.Values;
    }

    const values = [...setting.Values];
    for (let i = 0; i < values.length; i++) {
      values[i] = useTranslation(values[i] ?? '') as string;
    }
    return values;
  })(setting);

  let input: JSX.Element;
  switch (setting.Type) {
    default:
    case SettingType.Number:
      input = RenderSettingNumber(values, setting.Fields, disabled);
      break;
    case SettingType.Boolean:
      input = RenderSettingBoolean(values, setting.Fields, disabled);
      break;
    case SettingType.String:
      input = RenderSettingString(values, setting.Fields, setting.Extra, disabled);
      break;
    case SettingType.List:
      input = RenderSettingList(values, setting.Fields, setting.Extra, disabled);
      break;
    case SettingType.TwoOptions:
      input = RenderSettingTwoOptions(setting.Labels, values, setting.Fields, disabled);
      break;
    case SettingType.SwitchTwoOptions:
      input = RenderSettingSwitchTwoOptions(setting.Labels, values, setting.Fields, disabled);
      break;
    case SettingType.WordList:
      input = RenderWordList(values, setting.Fields, disabled);
      break;
    case SettingType.BooleanNumber:
      input = RenderBooleanNumber(values, setting.Fields, disabled);
      break;
    case SettingType.BooleanString:
      input = RenderBooleanString(values, setting.Fields, disabled);
      break;
    case SettingType.BooleanStringNull:
      input = RenderBooleanStringNull(values, setting.Fields, disabled);
      break;
    case SettingType.Button:
      input = RenderButton(values, setting.Extra, disabled, dataBag);
  }

  const CapitalizeFirstLetter = (s: string | ReactNode) => {
    if (!s)
      return s;

    if (typeof (s) != 'string')
      return s;

    if (s.startsWith('eBay'))//eBay is an exception
      return s;

    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  if (setting.Type === SettingType.Button) {
    return (
      <Row className={'description-and-controls' + (disabled ? ' disabled' : '')} key={setting.Fields[0]}>
        <Col span={fullWidth ? 24 : 10} className='description-area'></Col>
        {input}
      </Row>
    );
  }
  else {
    return (
      <Row className={'description-and-controls' + (disabled ? ' disabled' : '')} key={setting.Fields[0]}>
        <Col span={fullWidth || setting.Type === SettingType.String ? 24 : 12} className='description-area'>
          <h2 className={disabled ? 'disabled' : ''}>{CapitalizeFirstLetter(useTranslation(setting.Labels[0]))}{setting.Mandatory ? <span style={{ color: 'red' }}>{' '}(<T k='Mandatory'/>)</span> : ''}</h2>
          {setting.Description.map((x, i) => <p key={i}>{TT(x)}</p>)}
        </Col>
        {input}
      </Row>
    );
  }
};
