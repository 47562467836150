import { TransLinksValues, TransPlatformValues } from '../../../../utils/transShim';
import { GenericSavingSetting, GenericSettingInput, GSetting, SettingDataBag, SettingsValue } from '../generic/generic-setting-input';

export enum eWithdrawalSettings {
  //Currency,
  AccountHolderName,
  Iban,
  SortCode,
  AccountNumber,
  RoutingNumber,
  AccountType
}
export type WithdrawalSetting = GSetting<eWithdrawalSettings>;
export type SavingSetting = GenericSavingSetting<eWithdrawalSettings>;

interface Props {
  setting: WithdrawalSetting;
  currentSettingValues: Map<eWithdrawalSettings, SettingsValue>;
  savingSetting?: Map<eWithdrawalSettings, SavingSetting>;
  onSave: (key: eWithdrawalSettings, value: SettingsValue) => void;
  translationValues: TransPlatformValues | TransLinksValues;
  dataBag: SettingDataBag
  onButtonClick?: () => void,
  allFieldsInfo: WithdrawalSetting[],
  fullWidth?: boolean
}

export const WithdrawalSettingInput = (props: Props) => GenericSettingInput<eWithdrawalSettings, WithdrawalSetting>({
  ...props, delayToSave: 0
});