import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useAppDispatch } from '../../../custom-hooks/reduxCustomHooks';
import { eCountry } from '../../../data/countries';
import { ePlatform } from '../../../data/platforms';
import { Links } from '../../../links';
import { getLinkAccountURL } from '../../../redux/channels/channelsThunk';

export const RelinkStore = () => {
  const { platform, country, name } = (() => {
    const { platform, isocountry, name } = useParams<{ platform: string, isocountry: string, name: string }>();
    return {
      platform: (parseInt(platform) ?? ePlatform.eBay) as ePlatform,
      country: (parseInt(isocountry) as eCountry) ?? eCountry.UK,
      name: name
    };
  })();

  const dispatch = useAppDispatch();
  useEffect(() => {
    (async () => {
      try {
        const rs = await dispatch(getLinkAccountURL(
          {
            channel: platform,
            country: country,
            shopName: name,
            redirectionUrl: window.location.origin + Links.NewChannelCallBack.Produce(platform, country)
          }
        ));
        if (rs.payload !== '' || rs.payload !== 'Sorry! Something went wrong') {
          window.location.assign(rs.payload);
        }
      } catch (error) {
        if (error) console.log('Link account to store error');
      }
    })();
  }, []);
  return (
    <form className="location-form">
      <h2 className="text-warning">Your store <b>{name}</b> need to relink your {ePlatform[platform] + ' ' + eCountry[country]} account before you continue</h2>
      <p>You will be automatically redirected to {ePlatform[platform]} in order to complete this step.</p>
    </form>
  );
};
