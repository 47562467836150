import { createSlice } from '@reduxjs/toolkit';
import { ComListing } from '../../components/extension/new-listing-form/com-listing';
import { MiniSettings } from '../../types/mini-settings';
import { monitorListing } from '../import-listings/importListingThunk';
import { ChanenlListingHistory, ForceRefresh, GetActiveListings, GetActiveListingsImages, GetPendingListings, GetTerminatedListings, ListingImageUrlList, Optimize, GetPriceChanges, SaveActiveListingsImagesInCache, SaveListingChanges, SaveMultipleListingChanges, GetStockChanges, TerminateListing, ListPendingNow, RemovePendingListing, PauseToReview, DefaultSettingsMultiple, Relist, LoadEditPendingListing, DidList } from './listings-thunk';

export type ActiveListing = {
  id: number;
  userProductSourceChannelId: number;
  channelListingId: number;
  channelOAuthId: number;
  channelItem: string; //Item Id
  channelQuantity: number;
  sourcePrice: number;
  channelPrice: number;
  title: string;
  createdOn: Date;
  status: eChannelListingStatus;
  productSourceId: number;
  lastTimeInStock: Date;
  sourceQuantity: number;
  views: number;
  watches: number;
  quantitySold: number;
  lastTimeSold: Date;
  productNotes: string;
  overrides: MiniSettings;
  sourceId: number;
  path: string;
  createdById: number;
  createdByName: string;
  updatedOn: Date;
  price?: number;
  endsOn: Date;
  asin?: string;
  isLowestPrice?: boolean;
  lowestPrice?: number;
  buyBoxPrice?: number;
  //origin: eChannelListingOrigin;
  variationAtributes: ChannelListingVariationAttributeOption[];
  optimized?: number;//0-undefined = no, 1 = title, 2 = description, 3 = title & description

  //Calculated in client
  otherChannelOAuthsIds: number[];
}

export type ChannelListingVariationAttributeOption = {
  id: number;
  channelListingId: number;
  attribute: string;
  option: string;
}

export enum eChannelListingOrigin {
  Unknown = 0,
  Extension = 1,
  BulkLister = 2,
  CompeliaImporter = 3,
  ExistingChannelListing = 4,
  RelistedDiscoveredBySync = 5,
  SmartLister = 6,
  Migration = 8,
  Relisted = 16,
  ManuallyByAnAdmin = 32,
  Catalog = 64,
  WeListForYou = 128
}

export type PendingListing = {
  id: number;
  channelOAuthId: number;
  channelItem: string;
  createdOn: Date | string;
  status: eChannelListingStatus;
  title: string
  createdById: number;
  verifiedOn: Date;
  createdByName: string;
  errorMessage: string;
  errorSourceInfo: string;
  categoryId: number;
  imageUrl: string;
  categoryName: string;
  path: string;
  sourceId: number;
  dontListUntil: Date | string | undefined;
  //Calculated in client
  channelListingId: number;
}

export type TerminatedListing = PendingListing;

export enum eChannelListingStatus {
  Unknown = 0x0,
  PreparedForFirstListing = 0x1,
  QueuedForWork = 0x2,
  TemporaryFailure = 0x4,
  PermanentFailure = 0x8,
  Retrying = 0x10,
  InvalidUserCredentials = 0x20,
  ListingCreatedSuccessfully = 0x40,
  RetryingTwice = 0x80,
  RetryingFinal = 0x100,
  ExceptionThrown = 0x200,
  CreatingListing = 0x400,
  Removed = 0x800,
  Terminated = 0x1000,
  PendingForScraping = 0x2000,
  PendingToReview = 0x4000,
  BULK = 0x8000,
  BulkScraping = 0x10000,
  ImportedWaitingForChannelData = 0x20000,
  PendingForRelist = 0x40000,
  Relisted = 0x80000,
  ListingInStore = 0x100000,
  ListingVariation = 0x200000,
  BulkApiCreated = -2147483648
}

export type ListingsSource = {
  id: number;
  channelOAuthId: number;
  createdOn: Date;
  lastProcessedOn: null;
  productSourceId: null;
  url: null;
  finishedOn: null;
  status: number;
  errorCode: null;
  errorMessage: null;
  title: null;
  listOOS: null;
  optimizeTitle: null;
  ignoreVero: null;
  needsReview: null;
  createdById: null;
  origin: null;
  dontListUntil: null;
  retries: number;
  channelListingId: null;
  batchId: string;
}

export type ActiveListingsImagesDictionary = { [id: number]: {loading?: boolean, url: string } };

export type ProductsData<T> = {
  products: T[];
  channelOAuthId: number;
};

export type PendingListingData = {
  products: PendingListing[];
  channelOAuthId: number;
};
export type ListingsState = {
  activeListings?: ProductsData<ActiveListing>;//ActiveListing[] | null;
  loadingActive: boolean;

  pendingListings?: ProductsData<PendingListing>;//PendingListing[] | null;
  loadingPending: boolean;

  terminatedListings?: ProductsData<TerminatedListing>//TerminatedListing[] | null;
  loadingTerminated: boolean;

  activeListingsImages?: ActiveListingsImagesDictionary;

  saving?: { loading: boolean; success?: boolean; }

  priceChanges?: { listingId: number; loading: boolean; success?: boolean; history?: ChanenlListingHistory[] }
  stockChanges?: { listingId: number; loading: boolean; success?: boolean; history?: ChanenlListingHistory[] },

  loadEditPending?: {
    [id: number]: { data?: ComListing, loading: boolean }
  }

  didList?: {
    loading?: boolean,
    didList?: boolean
  }
}

const initialState: ListingsState = {
  //activeListings: null,
  //pendingListings: null,
  //terminatedListings: null,
  loadingActive: false,
  loadingTerminated: false,
  loadingPending: false,
  activeListingsImages: {} as ActiveListingsImagesDictionary,
  saving: undefined,
  loadEditPending: {},
  didList: {}
};

export const listingsSlice = createSlice({
  name: 'listings',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    //ACTIVE
    builder.addCase(GetActiveListings.pending, (state) => {
      state.loadingActive = true;
    });
    builder.addCase(GetActiveListings.fulfilled, (state, { payload }) => {
      state.loadingActive = false;
      state.activeListings = {
        products: payload.listings,
        channelOAuthId: payload.channelOAuthId
      };
      state.saving = undefined;
      state.priceChanges = undefined;
      state.stockChanges = undefined;

      const othersDic: { [ps: number]: number[] } = {};
      for (const oc of payload.others) {
        for (const psId of oc.productSourecIds) {
          if (!othersDic[psId]) othersDic[psId] = [];
          othersDic[psId].push(oc.channelOauthId);
        }
      }
      for (const l of state.activeListings.products) {
        const o = othersDic[l.productSourceId];
        l.otherChannelOAuthsIds = o ? o : [];
      }

      const imgDic: ActiveListingsImagesDictionary = {};
      for (const idimg of payload.images) {
        if (!idimg || !idimg.id || !idimg.url)
          continue;
        imgDic[idimg.id] = {
          loading: false,
          url: idimg.url
        };
      }
      state.activeListingsImages = imgDic;
    });
    builder.addCase(GetActiveListings.rejected, (state) => {
      state.loadingActive = false;
    });
    builder.addCase(monitorListing.fulfilled, (state ) => {//We will delete listings cache if import is done
      state.activeListings = undefined;
      state.saving = undefined;
      state.priceChanges = undefined;
      state.stockChanges = undefined;
    });

    //PENDING
    builder.addCase(GetPendingListings.pending, (state) => {
      state.loadingPending = true;
    });
    builder.addCase(GetPendingListings.fulfilled, (state, { payload }) => {
      state.loadingPending = false;
      state.pendingListings = {
        products: payload.listings,
        channelOAuthId: payload.channelOAuthId
      };

      for (const l of state.pendingListings.products) {
        l.channelListingId = l.id;
      }
    });
    builder.addCase(GetPendingListings.rejected, (state) => {
      state.loadingPending = false;
    });

    //TERMINATED
    builder.addCase(GetTerminatedListings.pending, (state) => {
      state.loadingTerminated = true;
    });
    builder.addCase(GetTerminatedListings.fulfilled, (state, { payload }) => {
      state.loadingTerminated = false;
      state.terminatedListings = {
        products: payload.listings,
        channelOAuthId: payload.channelOAuthId
      };
    });
    builder.addCase(GetTerminatedListings.rejected, (state) => {
      state.loadingTerminated = false;
    });

    //LOAD IMAGE
    builder.addCase(GetActiveListingsImages.pending, (state, { meta }) => {
      state.activeListingsImages = state.activeListingsImages ?? [];
      for (const d of meta.arg) {
        state.activeListingsImages[d] = {
          ...(state.activeListingsImages[d] ?? { url: undefined }),
          loading: true
        };
      }
    });
    builder.addCase(GetActiveListingsImages.fulfilled, (state, { payload }) => {
      const alls: ListingImageUrlList = [];
      state.activeListingsImages = state.activeListingsImages ?? [];
      for (const d in payload?.channelListingMap) {
        const id = parseInt(d);
        const url = payload?.channelListingMap[d]?.[0];
        alls.push({ id, url });
        state.activeListingsImages[id] = { loading: false, url };
      }
      SaveActiveListingsImagesInCache(alls);
    });
    builder.addCase(GetActiveListingsImages.rejected, (state, { meta }) => {
      state.activeListingsImages = state.activeListingsImages ?? [];
      for (const d of meta.arg) {
        state.activeListingsImages[d] = {
          ...(state.activeListingsImages[d] ?? { url: undefined }),
          loading: false
        };
      }
    });

    //SAVE SINGLE ACTIVE PRODUCT CHANGES
    builder.addCase(SaveListingChanges.pending, (state) => {
      state.saving = { loading: true };
    });
    builder.addCase(SaveListingChanges.fulfilled, (state, { payload, meta }) => {
      state.saving = { loading: false, success: payload.success };

      const listings = state.activeListings?.products;
      if (listings == null)
        return;

      const changes = meta.arg;

      let i = 0;
      for (i; i < listings.length; i++) {
        if (listings[i].channelListingId == changes.channelListingId) {
          break;
        }
      }

      const listing = listings[i] as ActiveListing;

      if (!listing) return;
      
      const dbListing = payload.listing;

      const newListing = {
        ...listing,
        overrides: { ...listing.overrides }
      } as ActiveListing;
      newListing.title = dbListing.title ?? changes.title ?? newListing.title;
      newListing.channelPrice = dbListing.channelPrice ?? changes.price ?? newListing.channelPrice;
      newListing.productNotes = dbListing.productNotes ?? changes.notes ?? newListing.productNotes;
      newListing.channelQuantity = dbListing.channelQuantity ?? changes.quantity ?? newListing.channelQuantity;
      newListing.path = dbListing.path ?? newListing.path;
      newListing.productSourceId = dbListing.productSourceId ?? newListing.productSourceId;
      newListing.sourceId = dbListing.sourceId ?? newListing.sourceId;
      if (changes.dispatchTimeDays != null)
        newListing.overrides.dispatchDays = changes.dispatchTimeDays.value;
      if (changes.ignoreRules != null)
        newListing.overrides.ignoreRules = changes.ignoreRules.value;
      if (changes.markup != null)
        newListing.overrides.markup = changes.markup.value;
      if (changes.minQuantity != null)
        newListing.overrides.minQuantity = changes.minQuantity.value;
      if (changes.monitorPrice != null)
        newListing.overrides.monitorPrice = changes.monitorPrice.value;
      if (changes.monitorPriceDecrease != null)
        newListing.overrides.monitorPriceDecrease = changes.monitorPriceDecrease.value;
      if (changes.monitorStock != null)
        newListing.overrides.monitorStock = changes.monitorStock.value;
      if (changes.paymentPolicy != null)
        newListing.overrides.paymentProfileId = changes.paymentPolicy.value;
      if (changes.returnPolicy != null)
        newListing.overrides.returnProfileId = changes.returnPolicy.value;
      if (changes.shippingPolicy != null)
        newListing.overrides.shippingProfileId = changes.shippingPolicy.value;
      if (changes.priceDecreasePercentage != null)
        newListing.overrides.monitorPriceDecreasePercentage = changes.priceDecreasePercentage.value;
      if (changes.primeOnly != null)
        newListing.overrides.primeOnly = changes.primeOnly.value;

      listings[i] = newListing;
    });
    builder.addCase(SaveListingChanges.rejected, (state) => {
      state.saving = { loading: false, success: false};
    });

    //SAVE MULTIPLE ACTIVE PRODUCT CHANGES
    builder.addCase(SaveMultipleListingChanges.pending, (state) => {
      state.saving = { loading: true };
    });
    builder.addCase(SaveMultipleListingChanges.fulfilled, (state, { payload, meta }) => {
      state.saving = { loading: false, success: payload };

      const listings = state.activeListings?.products;
      if (listings == null)
        return;

      const changes = meta.arg;

      
      for (const listingId of changes.listingIds) {
        for (let i = 0; i < listings.length; i++) {
          const listing = listings[i] as ActiveListing;
          if (!listing) continue;
          if (listing.channelListingId != listingId) continue;

          const newListing = {
            ...listing,
            overrides: { ...listing.overrides }
          } as ActiveListing;

          const changeByL = changes.dataByListing?.[listing.channelListingId];
          if (changeByL) {
            newListing.channelPrice = changeByL.price?.value ?? newListing.channelPrice;
            newListing.overrides.markup = changeByL.markup?.value;
          }

          newListing.channelQuantity = changes.quantity?.value ?? newListing.channelQuantity;
          if (changes.dispatchTimeDays != null)
            newListing.overrides.dispatchDays = changes.dispatchTimeDays.value;
          if (changes.ignoreRules != null)
            newListing.overrides.ignoreRules = changes.ignoreRules.value;
          if (changes.minQuantity != null) {
            newListing.overrides.minQuantity = changes.minQuantity.value;
            if (newListing.overrides.minQuantity ?? 0 > newListing.channelQuantity) {
              newListing.channelQuantity = newListing.overrides.minQuantity ?? 0;
            }
          }
          if (changes.monitorPrice != null)
            newListing.overrides.monitorPrice = changes.monitorPrice.value;
          if (changes.monitorPriceDecrease != null)
            newListing.overrides.monitorPriceDecrease = changes.monitorPriceDecrease.value;
          if (changes.monitorStock != null)
            newListing.overrides.monitorStock = changes.monitorStock.value;
          if (changes.paymentPolicy != null)
            newListing.overrides.paymentProfileId = changes.paymentPolicy.value;
          if (changes.returnPolicy != null)
            newListing.overrides.returnProfileId = changes.returnPolicy.value;
          if (changes.shippingPolicy != null)
            newListing.overrides.shippingProfileId = changes.shippingPolicy.value;
          if (changes.priceDecreasePercentage != null)
            newListing.overrides.monitorPriceDecreasePercentage = changes.priceDecreasePercentage.value;
          if (changes.primeOnly != null)
            newListing.overrides.primeOnly = changes.primeOnly.value;

          listings[i] = newListing;
        }
      }
    });
    builder.addCase(SaveMultipleListingChanges.rejected, (state) => {
      state.saving = { loading: false, success: false };
    });

    //TERMINATE LISTINGS
    builder.addCase(TerminateListing.pending, (state) => {
      state.saving = { loading: true };
    });
    builder.addCase(TerminateListing.fulfilled, (state, { payload, meta }) => {
      state.saving = { loading: false, success: payload };

      const listings = state.activeListings?.products;
      if (listings == null)
        return;

      const request = meta.arg;

      for (let i = 0; i < listings.length; i++) {
        if (listings[i].channelListingId == request.id) {
          listings.splice(i, 1);
          state.terminatedListings = undefined;//Terminate listings must reload
          break;
        }
      }
    });
    builder.addCase(TerminateListing.rejected, (state) => {
      state.saving = { loading: false, success: false };
    });

    //FORCE REFRESH
    builder.addCase(ForceRefresh.pending, (state) => {
      state.saving = { loading: true };
    });
    builder.addCase(ForceRefresh.fulfilled, (state, { payload }) => {
      state.saving = { loading: false, success: payload };
    });
    builder.addCase(ForceRefresh.rejected, (state) => {
      state.saving = { loading: false, success: false };
    });

    //OPTIMISE TITLE
    builder.addCase(Optimize.pending, (state) => {
      state.saving = { loading: true };
    });
    builder.addCase(Optimize.fulfilled, (state, { payload, meta }) => {
      state.saving = { loading: false, success: payload.success };

      if (state.activeListings?.products == null || payload.title == null)
        return;

      if (!state.saving.success)
        return;

      for (const l of state.activeListings.products) {
        if (l.channelListingId == meta.arg.listingId) {
          l.title = payload.title;
          break;
        }
      }

    });
    builder.addCase(Optimize.rejected, (state) => {
      state.saving = { loading: false, success: false };
    });

    //PRICE CHANGES
    builder.addCase(GetPriceChanges.pending, (state, { meta }) => {
      state.priceChanges = { loading: true, listingId: meta.arg };
    });
    builder.addCase(GetPriceChanges.fulfilled, (state, { payload, meta }) => {
      state.priceChanges = { loading: false, listingId: meta.arg, success: payload.success, history: payload.history };
    });
    builder.addCase(GetPriceChanges.rejected, (state) => {
      state.priceChanges = { loading: false, listingId: state.priceChanges?.listingId ?? 0, success: false };
    });

    //STOCK CHANGES
    builder.addCase(GetStockChanges.pending, (state, { meta }) => {
      state.stockChanges = { loading: true, listingId: meta.arg };
    });
    builder.addCase(GetStockChanges.fulfilled, (state, { payload, meta }) => {
      state.stockChanges = { loading: false, listingId: meta.arg, success: payload.success, history: payload.history };
    });
    builder.addCase(GetStockChanges.rejected, (state) => {
      state.stockChanges = { loading: false, listingId: state.priceChanges?.listingId ?? 0, success: false };
    });

    //LIST NOW PENDING LISTING
    builder.addCase(ListPendingNow.pending, (state) => {
      state.saving = { loading: true };
    });
    builder.addCase(ListPendingNow.fulfilled, (state, { payload, meta }) => {
      state.saving = { loading: false, success: payload };

      if (state.pendingListings?.products == null)
        return;

      if (!state.saving.success)
        return;

      for (const l of state.pendingListings.products) {
        if (l.channelListingId == meta.arg) {
          l.status = eChannelListingStatus.ListingInStore;
          l.dontListUntil = undefined;
          break;
        }
      }

    });
    builder.addCase(ListPendingNow.rejected, (state) => {
      state.saving = { loading: false, success: false };
    });

    //REMOVE PENDING LISTING
    builder.addCase(RemovePendingListing.pending, (state) => {
      state.saving = { loading: true };
    });
    builder.addCase(RemovePendingListing.fulfilled, (state, { payload, meta }) => {
      state.saving = { loading: false, success: payload };

      const listings = state.pendingListings?.products;
      if (listings == null)
        return;

      if (!state.saving.success)
        return;

      for (let i = 0; i < listings.length; i++) {
        if (listings[i].channelListingId == meta.arg) {
          listings.splice(i, 1);
          break;
        }
      }

    });
    builder.addCase(RemovePendingListing.rejected, (state) => {
      state.saving = { loading: false, success: false };
    });

    //PAUSE PENDING LISTING
    builder.addCase(PauseToReview.pending, (state) => {
      state.saving = { loading: true };
    });
    builder.addCase(PauseToReview.fulfilled, (state, { payload, meta }) => {
      state.saving = { loading: false, success: payload };

      if (state.pendingListings?.products == null)
        return;

      if (!state.saving.success)
        return;

      for (const l of state.pendingListings.products) {
        if (l.channelListingId == meta.arg) {
          l.status = eChannelListingStatus.PendingToReview;
          break;
        }
      }

    });
    builder.addCase(PauseToReview.rejected, (state) => {
      state.saving = { loading: false, success: false };
    });

    //DEFAULT POLICIES
    builder.addCase(DefaultSettingsMultiple.pending, (state) => {
      state.saving = { loading: true };
    });
    builder.addCase(DefaultSettingsMultiple.fulfilled, (state, { payload }) => {
      state.saving = { loading: false, success: payload };
    });
    builder.addCase(DefaultSettingsMultiple.rejected, (state) => {
      state.saving = { loading: false, success: false };
    });

    //RELIST
    builder.addCase(Relist.pending, (state) => {
      state.saving = { loading: true };
    });
    builder.addCase(Relist.fulfilled, (state, { payload, meta }) => {
      state.saving = { loading: false, success: payload.success };
      if (state.terminatedListings) {
        const cl = state.terminatedListings?.products?.find(x => x.id == meta.arg);
        if (!cl)
          return;
        if (payload.success) {
          cl.status = eChannelListingStatus.PendingForRelist;
        } else {
          cl.status = eChannelListingStatus.Terminated;
          cl.errorMessage = payload.error;
        }
      }
    });
    builder.addCase(Relist.rejected, (state) => {
      state.saving = { loading: false, success: false };
    });

    //LOAD EDIT PENDING LISTING
    builder.addCase(LoadEditPendingListing.pending, (state, { meta }) => {
      if (!state.loadEditPending)
        state.loadEditPending = {};
      state.loadEditPending[meta.arg] = { loading: true };
    });
    builder.addCase(LoadEditPendingListing.fulfilled, (state, { payload, meta }) => {
      if (!state.loadEditPending)
        state.loadEditPending = {};
      state.loadEditPending[meta.arg] = { loading: false, data: payload };
    });
    builder.addCase(LoadEditPendingListing.rejected, (state, { meta }) => {
      if (!state.loadEditPending)
        state.loadEditPending = {};
      state.loadEditPending[meta.arg] = { loading: false };
    });

    //DidList
    builder.addCase(DidList.pending, (state) => {
      state.didList = {loading:true};
    });
    builder.addCase(DidList.fulfilled, (state, { payload}) => {
      state.didList = { loading: false, didList: payload };
    });
    builder.addCase(DidList.rejected, (state) => {
      state.didList = { loading: false };
    });
  }
});

export const { reducer: listingsReducer } = listingsSlice;