import { useHistory, useParams } from 'react-router-dom';
import { Links } from '../../links';
import { SecondaryBtn } from '../../small-components/ActionBtns';
import { StatusBar } from '../../small-components/StatusBar';
import { T } from '../../utils/transShim';
import { SmartListerNewFilterAdvanced } from './smart-lister-new-filter-advanced';
import { SmartListerNewFilterOnBoarding } from './smart-lister-new-filter-onboarding';

export const SmartListerNewFilter = () => {
  const history = useHistory();
  const advanced = (() => {
    const { mode } = useParams<{ mode?: string }>();
    return mode == 'advanced';
  })();
  const SetAdvanced = (a: boolean) => {
    if (a) {
      history.push(Links.SmartLister.OrderCreator.Advanced);
    } else {
      history.push(Links.SmartLister.OrderCreator.Simple);
    }
  };

  const Content = () => {
    if (advanced) return <SmartListerNewFilterAdvanced />;

    return <SmartListerNewFilterOnBoarding />;
  };

  return (
    <div className="smart-lister-layout">
      <div className="smart-lister">
        <StatusBar className="smart-lister-bar">
          <div className="stopbar">
            <SecondaryBtn onClick={() => SetAdvanced(!advanced)}>
              <T k={advanced ? 'SmartLister.Button.SimpleMode' : 'SmartLister.Button.AdvancedMode'} />
            </SecondaryBtn>
          </div>
          {Content()}
        </StatusBar>
      </div>
    </div>
  );
};
