import React from 'react';
import { Selector, SelectorValue } from '../../../small-components/form/selector';
import { PictureVariationsAttributeOptionPanel } from './pictures-variations-attribute-option-panel';

export type PictureVariationsPanelProps = {
  atributeOptionsList: { [id: string]: string[] };

  selectedAttribute: string | null;
  selectedOption: string;

  onAttributeSelected: (attribute: string) => void;
  onOptionSelected: (option: string, i: number) => void;

  children?: React.ReactNode;
}

export const PicturesVariationsPanel = (props: PictureVariationsPanelProps) => {

  const allAttributesMap: { value: string | null; label: string }[] = Object.keys(props.atributeOptionsList).map((x) => {
    return { value: x, label: 'By ' + x };
  });
  allAttributesMap.unshift({ value: null, label: 'Same images for all variations' });

  const OnAttributeSelected = (value: SelectorValue) => props.onAttributeSelected(value as string);
  const OptionSelected = (option: string, i: number) => props.onOptionSelected(option, i);

  const selectedValue = props.selectedAttribute
    ? { value: props.selectedAttribute, label: 'By ' + props.selectedAttribute }
    : { value: null, label: 'Same images for all variations' };

  return (
    <>
      <div className='variationsPanel variationsPanelImages'>
        <div className='header'>
          <label>Variaton photos</label>
          <Selector
            value={selectedValue?.value}
            onChange={OnAttributeSelected}
          >
            {allAttributesMap}
          </Selector>
        </div>
        {props.selectedAttribute && (
          <div className='list'>
            {props.atributeOptionsList[props.selectedAttribute]?.map((x, i) => (
              <PictureVariationsAttributeOptionPanel
                key={i}
                option={x}
                onSelect={(_) => OptionSelected(x, i)}
                selected={props.selectedOption == x}
              />
            ))}
          </div>
        )}
        {props.children}
      </div>
    </>
  );
};
