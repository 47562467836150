import { isObject } from 'util';
import { BooleanOrNull, DateOrNull, NumberOrNull } from '../../../utils/utils';
import { AdvancedFilterValueArrayContent, AdvancedFilterValueType, AdvancedSearchOptions, ColumnData, FieldType } from '../types/columns';
import { AdvancedFilterData } from './advanced-search';

type AdvancedFilterExtended<T> = {
  columnId: number;
  fieldName: string | number | readonly (string | number)[] | undefined;
  value: AdvancedFilterValueType;
  options?: AdvancedSearchOptions<T>;
}

//eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
export const AdvancedSearchFilter = <DataType extends object = any>(
  filter: AdvancedFilterData[],
  data: DataType[],
  columns: ColumnData<DataType>[]
) => {
  if (!data || !filter || !columns || columns.length == 0 || filter.length == 0)
    return data;

  //Data to be returned, we will remove later data if it doesn't fullfill filters
  const filteredData: DataType[] = [...data];

  //Add column data to filter---------------------
  const filterA = (() => {
    const filterA: AdvancedFilterExtended<DataType>[] = [];
    for (const f of filter) {
      const c = columns.find(x => x.id == f.columnId);
      if (!c)//This should never happen
        continue;
      filterA.push({ ...f, fieldName: c.dataIndex, options: c.advancedSearch });
    }
    return filterA;
  })();

  //Execute filtering----------------------------
  const FilterString = (value: unknown, filter: AdvancedFilterValueArrayContent) => {
    if (filter == null)
      return true;

    const filterValue = String(filter).trim().toLocaleLowerCase();

    if (filterValue.length == 0)
      return true;

    if (value == null)
      return false;

    const fieldValue = String(value).trim().toLocaleLowerCase();

    if (fieldValue.indexOf(filterValue) >= 0)
      return true;

    return false;
  };

  const FilterNumber = (value: unknown, filter: AdvancedFilterValueType) => {
    if (filter == null)
      return true;

    if (!Array.isArray(filter))
      return true;

    const [min, max] = filter.map(x => NumberOrNull(x));

    if (min == null && max == null)
      return true;

    const fieldValue = NumberOrNull(value);
    if (fieldValue == null || isNaN(fieldValue))
      return false;

    if (min != null) {
      if (min > fieldValue) {
        return false;
      }
    }
    if (max != null) {
      if (max < fieldValue) {
        return false;
      }
    }
    return true;
  };

  const FilterBoolean = (value: unknown, filter: AdvancedFilterValueArrayContent) => {
    if (filter == null)
      return true;

    const fieldValue = BooleanOrNull(value);//We don't use Boolean because Boolean('0') = true. BooleanOrNull('0') = false
    const filterValue = BooleanOrNull(filter);

    return fieldValue === filterValue;
  };

  const FilterDate = (value: unknown, filter: AdvancedFilterValueType) => {
    if (filter == null)
      return true;

    if (!Array.isArray(filter))
      return true;

    const [min, max] = filter.map(x => DateOrNull(x));

    if (min == null && max == null)
      return true;

    const fieldValue = DateOrNull(value);
    if (fieldValue == null)
      return false;

    if (min != null) {
      if (min > fieldValue) {
        return false;
      }
    }
    if (max != null) {
      if (max < fieldValue) {
        return false;
      }
    }
    return true;
  };

  const FilterNumberList = (value: unknown, filter: AdvancedFilterValueType) => {
    if (filter == null)
      return true;

    if (!Array.isArray(filter))
      return true;

    const filterList = filter.map(x => NumberOrNull(x)).filter(x => x != null);
    if (filterList.length == 0)
      return true;

    if (value == null)
      return false;

    const fieldValue = (Array.isArray(value) ? value : [value]).map(x => NumberOrNull(x)).filter(x => x != null);
    if (fieldValue.length == 0)
      return false;

    for (const filter of filterList) {
      let found = false;
      for (const val of fieldValue) {
        if (val == filter) {
          found = true;
          break;
        }
      }
      if (!found)
        return false;
    }

    return true;
  };

  const FilterMultipleSelect = (value: unknown, filter: AdvancedFilterValueType) => {
    if (filter == null)
      return true;

    if (!Array.isArray(filter))
      return true;

    if (filter.length == 0)
      return true;

    for (const f of filter) {
      if (value == f)
        return true;
    }

    return false;
  };

  const FilterDataByFilter = (d: DataType, f: AdvancedFilterExtended<DataType>) => {
    if (f == null || f.value == null || f.value.length == 0)
      return true;

    let fieldValue: unknown | undefined | null;
    if (Array.isArray(f.fieldName)) {
      fieldValue = d;
      for (const fn of f.fieldName as (string | number)[]) {
        if (!fieldValue || !isObject(fieldValue))
          return false;
        const k = fn as keyof typeof fieldValue;
        fieldValue = (fieldValue as Record<string, never>)[k];
      }
    } else {
      const k = f.fieldName as keyof typeof d;
      fieldValue = d[k];
    }

    if (f.options?.customFilter) {
      if (!f.options.customFilter(fieldValue as unknown, f.value, d))
        return false;
      return true;
    }

    switch (f.options?.type) {
      default:
      case FieldType.String:
        return FilterString(fieldValue, f.value[0]);
      case FieldType.Boolean:
        return FilterBoolean(fieldValue, f.value[0]);
      case FieldType.Date:
        return FilterDate(fieldValue, f.value);
      case FieldType.Number:
        return FilterNumber(fieldValue, f.value);
      case FieldType.OtherChannels:
        return FilterNumberList(fieldValue, f.value);
      case FieldType.MultipleSelect:
        return FilterMultipleSelect(fieldValue, f.value);
    }
  };

  const FilterData = (d: DataType) => {
    for (const f of filterA) {
      if (!FilterDataByFilter(d, f))
        return false;
    }
    return true;
  };

  for (let i = filteredData.length; i-- > 0;) {
    if (!FilterData(filteredData[i]))
      filteredData.splice(i,1);
  }

  return filteredData;
};