import { ReactNode } from 'react';
import { CircleDotIcon } from '../common/Icons';
import '../../sass/menu-list-item.scss';
import { A } from '../../small-components/A';


interface Props {
  listName?: string | ReactNode | JSX.Element;
  route: string;
  pathHttp?: boolean;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const MenuListItem = (props: Props) => {
  const { listName, route, pathHttp, onClick } = props;
  return (
    <div className="menu-list">
      <span className="dot-icon">
        <CircleDotIcon />
      </span>
      {pathHttp == true ? <A to={{ pathname: route }} onClick={onClick} target='_blank'>{listName}</A> : <A to={route ? route : '#'} onClick={onClick}>{listName}</A>}
    </div>
  );
};

export const HrefListItem = (props: Props) => {
  const { listName, route, onClick } = props;
  return (
    <div className="menu-list">
      <span className="dot-icon">
        <CircleDotIcon />
      </span>
      <A
        to={route}
        onClick={onClick}
      >
        {listName}
      </A>
    </div>
  );
};
