import { Input } from 'antd';

type Props = {
  name?: string;
  onChange: (value: string | null) => void;
}

export const StringField = (props: Props) => {
  const { onChange, name } = props;

  const ChangeValue = (v: string | null | undefined) => {
    const newValue = v?.trim() ?? '';
    onChange((newValue.length > 0) ? newValue : null);
  };
  const HandleChange = (e: React.FormEvent<HTMLInputElement>) => ChangeValue(e.currentTarget?.value);

  return <Input className="blue-input" name={name} onChange={HandleChange} />;
};