import { T } from '../../utils/transShim';
import { CancelBtn, SuccessBtn } from '../ActionBtns';
import { PopupModal } from './popup-modal';


type Props = {
  children?: React.ReactNode | React.ReactNode[];
  onOk?: () => void;
  onCancel?: () => void;
  okText?: React.ReactNode;
  cancelText?: React.ReactNode;
  visible?: boolean;
  okDisabled?: boolean;
  maskClosable?: boolean;
};
export const QuestionModal = (props: Props) => {
  const { children, onOk, onCancel, okText, cancelText, visible, okDisabled, maskClosable } = props;

  const footerButtons = [
    <CancelBtn key='cancel' onClick={onCancel}>
      {cancelText ?? <T k='Question.Cancel' />}
    </CancelBtn>,
    <SuccessBtn key='submit' onClick={onOk} disabled={okDisabled}>
      {okText ?? <T k='Question.Accept' />}
    </SuccessBtn>
  ];

  return <PopupModal
    open={visible}
    showCloseX={false}
    maskClosable={maskClosable ?? false}
    footer={footerButtons}
    onClose={onCancel}
  >
    {children}
  </PopupModal>;
};