import FieldCheck from './field-check';
import FieldText from './field-text';
import FieldSelect from './field-select';
import FieldCard from './field-card';
import { AutoOrderField, AutoOrderFieldType, UserSupplierSettingsKeys } from '../../../data/autoorder-fields';
import { SourceStaticData } from '../../../data/sources';
import { T } from '../../../utils/transShim';
import { SettingsValue } from '../../../redux/auto-ordering/autoOrderingThunk';

export type FieldProp = {
  field: AutoOrderField;
  value: SettingsValue;
  onChange: (newValue: string, setting: UserSupplierSettingsKeys) => void;
  source: SourceStaticData;
}

const TxtTypeFromType = (type: AutoOrderFieldType) => {
  switch (type) {
    case AutoOrderFieldType.TEXT:
      return 'text';
    case AutoOrderFieldType.EMAIL:
      return 'email';
    case AutoOrderFieldType.PASSWORD:
      return 'password';
    case AutoOrderFieldType.PHONE:
      return 'tel';
    case AutoOrderFieldType.NUMBER:
      return 'number';
    case AutoOrderFieldType.CARD:
    case AutoOrderFieldType.SELECT:
    case AutoOrderFieldType.BOOL:
    default: return '';
  }
};
const Field = (props: FieldProp) => {
  const { field, onChange, source } = props;
  const value = props.value ?? field.defaultValue?.toString();

  const HandleOnChange = (newValue: string) => {
    onChange?.(newValue, field.setting);
  };
  const label = <T k={field.label} values={{ sourceName: source.name }} />;
  const labelOff = field.labelOff ? <T k={field.labelOff} values={{ sourceName: source.name }} /> : <></>;
  const description = field.description  ? <T k={field.description} values={{ sourceName: source.name }} /> : <></>;

  switch (field.type) {
    case AutoOrderFieldType.TEXT:
    case AutoOrderFieldType.EMAIL:
    case AutoOrderFieldType.PASSWORD:
    case AutoOrderFieldType.PHONE:
    case AutoOrderFieldType.NUMBER:
      return <FieldText
        label={label}
        placeholder={field.placeholder}
        size={field.size}
        value={value ?? ''}
        onChange={HandleOnChange}
        type={TxtTypeFromType(field.type)}
        maxLength={field.maxLength}
      />;
    case AutoOrderFieldType.CARD:
      return <FieldCard
        label={label}
        placeholder={field.placeholder}
        value={value ?? ''}
        onChange={HandleOnChange}
        type={TxtTypeFromType(field.type)}
      />;
    case AutoOrderFieldType.BOOL:
      return <FieldCheck
        labelOn={label}
        labelOff={labelOff ?? label}
        onChange={(b) => HandleOnChange(b ? '1' : '0')}
        value={value != null ? value == 'true' || value == '1' : false}
        description={description}
      />;
    case AutoOrderFieldType.SELECT:
      return (
        <FieldSelect
          label={label}
          onChange={(b) => HandleOnChange(b.toString())}
          selectedValue={parseInt(value ?? '0')}
          options={field.selectValues ?? []}
          description={description}
        />
      );
    default: return (<></>);
  }
};
export default Field;

