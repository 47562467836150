import { ePlatform, PlatformsEbay } from '../../../data/platforms';


export enum ChannelSettingSection {
  Monitoring, Product, Business, Notifications,Channel
}

interface ChannelSettingSectionsInfo {
  Type: ChannelSettingSection;
  Label: string;
  ChannelIds?: ePlatform[]//If undefined it accepts all the channels
}

export const ChannelSettingsSections: ChannelSettingSectionsInfo[] = [
  {
    Type: ChannelSettingSection.Monitoring,
    Label: 'Channel.Monitoring'
  },
  {
    Type: ChannelSettingSection.Product,
    Label: 'Channel.Listing'
  },
  {
    Type: ChannelSettingSection.Business,
    Label: 'Channel.Business',
    ChannelIds: PlatformsEbay
  },
  {
    Type: ChannelSettingSection.Notifications,
    Label: 'Channel.Notifications'
  },
  {
    Type: ChannelSettingSection.Channel,
    Label: 'Channel.Channel'
  }
];