import { useRouteMatch } from 'react-router-dom';
import { Row, Col } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { Links } from '../../links';
import { AllServicesData, ServiceData } from './ServicesData';
import '../../sass/services/single-service.scss';
import { BuyTokensModal } from 'src/small-components/modals/buy-tokens-modal';
import { ReactUtils } from '../../utils/react-utils';
import { ePlatform } from '../../data/platforms';
import { useState } from 'react';
import { PrimaryBtn } from 'src/small-components/ActionBtns';
import { A } from '../../small-components/A';

export const SingleService = () => {
  const channel = ReactUtils.GetSelectedChannel();

  const [open, setOpen] = useState<boolean>(false);
  const HandleOpenModal = () => setOpen(!open);

  const FindService = (link: string) => {
    return useRouteMatch(link) !== null;
  };

  const data = AllServicesData(channel?.channelId ?? ePlatform.eBay).filter((s: ServiceData) => FindService(s.link))[0];

  const buttonCta = () => {
    switch (data?.id) {
      case 1:
        return (
          <A to={'/listingservice?selectedCurrency=1&selectedProduct=54&selectedBillingPeriod=3'}>
            <PrimaryBtn>
              Purchase list
            </PrimaryBtn>
          </A>
        );
      case 2:
        return (
          <PrimaryBtn>
            <A to={Links.PriceWarriorConfig}>Price Warrior configuration</A>
          </PrimaryBtn>
        );
      case 3:
        return (
          <A to="https://hustlegotreal.com/en/contact-us" rel="noreferrer" target='_blank'>
            <PrimaryBtn>
              Contact us
            </PrimaryBtn>
          </A>
        );
      case 4:
        return (
          <A to="https://hustlegotreal.com/en/no-api-server" rel="noreferrer" target='_blank'>
            <PrimaryBtn>
              View pricing
            </PrimaryBtn>
          </A>
        );
      case 5:
        return (
          <A to={'/configuration/auto-ordering/'}>
            <PrimaryBtn>
              Configure it!
            </PrimaryBtn>
          </A>
        );
      case 6:
        return (
          <A
            to="https://chromewebstore.google.com/detail/vero-checker-private/nlhklaenccbcnapnpaghliheibnkhdbc?hl=en"
            rel="noreferrer"
            target={'_blank'}
          >
            <PrimaryBtn>
              Install extension
            </PrimaryBtn>
          </A>
        );
      case 7:
        return (
          <A to="https://hustlegotreal.com/en/listing-service/" rel="noreferrer" target={'_blank'}>
            <PrimaryBtn>
                View pricing
            </PrimaryBtn>
          </A>
        );
      case 8:
        return (
          <PrimaryBtn onClick={HandleOpenModal}>
            <BuyTokensModal open={open} onClose={HandleOpenModal} />
            Buy tokens
          </PrimaryBtn>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="main-container">
      <A to={Links.Services.Main} className="back-to-services">
        <LeftOutlined style={{ fontSize: '19px' }} />
        Back to services
      </A>
      <div className="service-main-container">
        <Row className="service-area">
          <Col className="col-services" xs={24} sm={12} md={10} lg={8}>
            <div className="service-container">
              <div className="image-container">
                <img src={data?.image} />
              </div>
            </div>
          </Col>
          <Col className="col-services" xs={24} sm={12} md={14} lg={16}>
            <div className="description-area">
              <h2 className="service-title">{data?.title}</h2>
              <div className="service-advantages">
                <div>
                  {data?.paragraphs.map((x, index) => (
                    <p key={index}> {x} </p>
                  ))}

                  <ul>
                    {data?.bulletPoints.map((x, index) => (
                      <li key={index}> {x} </li>
                    ))}
                  </ul>
                </div>
                {buttonCta()}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
