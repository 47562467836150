import '../../sass/subscriptions/free-trial-expired.scss';
import { PrimaryBtn } from 'src/small-components/ActionBtns';
import { Links } from 'src/links';
import { useHistory } from 'react-router-dom';
import { T } from '../../utils/transShim';
import { useAppDispatch } from '../../custom-hooks/reduxCustomHooks';
import { actions } from '../../redux/user/userSlice';
import { persistor } from '../../redux/store';
import { ReactUtils } from '../../utils/react-utils';
import { A } from '../../small-components/A';

export const AutoOrderDebt = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const noChannel = ReactUtils.GetSelectedChannel() == null;

  const routeChange = (route: string) => {
    history.push(route);
  };

  const HandleLogout = () => {
    const keysToRemove = ['root', 'Authorization', 'globalTheme', 'isAuthenticated', 'newChannelSuccess'];
    dispatch(actions.logout());
    keysToRemove.forEach((key) => localStorage.removeItem(key));
    persistor.purge();
    routeChange(Links.Credentials.Login);
  };

  return (
    <div className={'freetrial-container' + (noChannel ? 'nochannel' : '')}>
      {/*To align*/}
      <div className="box-text">
        <h1 className="title-payment">
          <T k="AutoOrderDebt.Title" />
        </h1>
        <p className="description-trial">
          <T k="AutoOrderDebt.Description1" />
        </p>
        <div className="button-container">
          <A to={Links.Configuration.Account.PaymentMethod}>
            <PrimaryBtn className="renewal-button">
              <T k="AutoOrderDebt.Account.Button" />
            </PrimaryBtn>
          </A>
        </div>
        <br />
        <br />
        <br />
        <A to={Links.AffiliateDashboard}>
          <T k="Affiliate.Link.Visit" />
        </A>
      </div>
      {noChannel && (
        <A to="#" onClick={HandleLogout} className="logout">
          <T k="Menu.Logout" />
        </A>
      )}
      {!noChannel && <div></div>}
      {/*To align*/}
    </div>
  );
};
