import { EditOutlined } from '@ant-design/icons';
import { UserSupplierSettingsKeys } from '../../../../data/autoorder-fields';
import { AutoOrderingConfigurationAccountData } from '../../../../redux/auto-ordering/autoOrderingThunk';
import { DataTable } from '../../../../small-components/tables/data-table';
import { T } from '../../../../utils/transShim';

interface Props {
  accounts: AutoOrderingConfigurationAccountData[];
  onEditAccount: (a: AutoOrderingConfigurationAccountData | undefined) => void;
}

export const AOAccountsTable = (props: Props) => {

  const { accounts, onEditAccount } = props;

  type X = {
    active: boolean;
    name: string;
    id: number;
  };

  type AccountMicroData = {
    id: number;
    key: string | number;
    name: string;
    active: boolean;
  };


  const HandleAccountSelected = (value: number) => {
    onEditAccount(accounts.find((x) => x.id == value));
  };

  const accountListColumns = [
    {
      title: <T k="AutoOrderingConfiguration.Alias" />,
      key: 'name',
      render: (record: X) => record.name
    },
    {
      title: <T k="AutoOrderingConfiguration.Status" />,
      key: 'active',
      render: (record: X) => (record.active ? <T k="AutoOrderingConfiguration.Enabled" /> : <T k="AutoOrderingConfiguration.Disabled" />)
    },
    {
      title: '',
      key: 'edit',
      render: () => <EditOutlined />
    }
  ];

  const accountsDS: AccountMicroData[] =
    accounts?.map((x) => ({
      id: x.id,
      key: x.id,
      name: x.rawSettings?.find((x) => x.key == UserSupplierSettingsKeys.ALIAS)?.value ?? 'No name',
      active: x.rawSettings?.find((x) => x.key == UserSupplierSettingsKeys.ACTIVE)?.value == '1'
    })) ?? [];


  return <DataTable
    productNamePlural={<T k='Orders'/>}
    columns={accountListColumns}
    dataSource={accountsDS}
    onRow={(x) => ({ onClick: () => HandleAccountSelected(x.id) })}
    showTableInfo={false}
    hidePagination={true}
    pageSizes={[9999]}
  />;
};