export enum ColumnId {
  //Id = 1,
  //ChannelOauthId = 2,
  Status = 3,
  CreatedOn = 4,
  QuantityByTime = 5,
  //DontStartUntil = 6,
  //TimeInterval = 7,
  //QuantityMax = 8,
  OptimizeTitles = 9,
  OptimizeDescriptions = 10,
  ListVariations = 11,
  Price = 12,
  //PriceMin = 12,
  //PriceMax = 13,
  Profit = 14,
  //ProfitMin = 14,
  //ProfitMax = 15,
  TitleContains = 16,
  OnlyPrime = 17,
  //SourcesInclude = 18,
  Sources = 19,
  QuantityListed = 20,
  CheckHistoryButton = 21
}