import { T } from '../../../utils/transShim';
import { eShop } from './eShop';
import { PrimaryBtn, SecondaryBtn } from '../../../small-components/ActionBtns';

import { A } from '../../../small-components/A';

interface props {
  shop: eShop;
  onChangeNoApiServer: (value: boolean) => void;
}

export const NoApiServerSelect = (props: props) => {
  const { onChangeNoApiServer } = props;

  const HandleClickYesNoApiServer = () => onChangeNoApiServer(true);
  const HandleClickNoNoApiServer = () => onChangeNoApiServer(false);

  return (
    <form className="noapi-server">
      <h2 className="title"><T k="NewChannel.NoApiServer.Title" /></h2>
      <p>
        <A to="https://hustlegotreal.com/en/no-api-server/" target={'_blank'} rel="noreferrer">
          <T k="NewChannel.NoApiServer.MoreInfo" />
        </A>
      </p>
      <div className="buttons">
        <SecondaryBtn onClick={HandleClickNoNoApiServer}><T k='NewChannel.NoApiServer.No'/></SecondaryBtn>
        <PrimaryBtn onClick={HandleClickYesNoApiServer}><T k='NewChannel.NoApiServer.Yes' /></PrimaryBtn>
      </div>
    </form>
  );
};
