import { createSlice } from '@reduxjs/toolkit';
import { BillingPeriod } from '../../components/subscriptions/models/types';
import { LoginUser, RegisterUser, getUserToken, getUserQuota, ImpersonateUser, UnImpersonateUser, ConfirmUser, SetResetPassword, RefreshUser } from './userThunk';
export interface UserData {
  //email: string;
  //password: string;
  //name?: string;
  //confirmPassword?: string;
  //terms?: boolean;
  //success?: boolean;
  referral?: string;
  //affiliateTag?: string;
  createdOn?: Date;
  //isEnabled: boolean;
  //quotaUsed?: number;
  //quotaAdded?: number;
  //selectedOAuthId?: number;
  //id?: string;
  //userName?: string;
  //normalizedUserName?: string;
  //normalizedEmail?: string;
  //emailConfirmed?: boolean;
  //passwordHash?: string;
  //securityStamp?: string;
  //concurrencyStamp?: string;
  //phoneNumberConfirmed?: boolean;
  //twoFactorEnabled?: boolean;
  //lockoutEnabled?: boolean;
  //accessFailedCount?: number;
}

export interface ResetData {
  email: string;
}

export interface ResetPasswordData {
  email: string;
  password: string;
  confirmPassword: string;
  code: string;
}

export type Error = {
  code: string;
  description: string;
};

export type RegisterAnswer = {
  status: number
  data: {
    success: boolean,
    error: Error | Error[],
    response_errors: {
      error: Error | Error[]
    },
    errors: { [id: string]: string[] },
    token?: string,
    //user?: UserData
  }
}

export type ConfirmAnswer = {
  success: boolean
}
export type ResetAnswer = RegisterAnswer;


export type QuotaData = {
  quota: number;
  used: number;
  price: number;
  endsOn: string;
  currency: string;
  pending: number;
  cancelled: boolean;
  billingPeriod: BillingPeriod;
  automaticallyRestricted: boolean;
};

export type UserSliceState = {
  user: { token?: string, user?: UserData, updatedOn?: Date };
  tokens: number | null;
  quota: QuotaData | null;
  loading: boolean | false;
  loadingReset: boolean;
  userLoading: boolean;
  error: string;
  registerAnswer: RegisterAnswer | undefined;
  confirmAnswer: ConfirmAnswer | undefined;
  resetPasswordAnswer: ResetAnswer | undefined;
}

const initialState: UserSliceState = {
  user: {},
  tokens: null,
  quota: null,
  loading: false,
  userLoading: false,
  error: '',
  registerAnswer: undefined,
  confirmAnswer: undefined,
  loadingReset: false,
  resetPasswordAnswer: undefined
};

export interface ProductQuota {
  quota: number;
  used: number;
  price: number;
  endsOn: Date;
  currency: string;
  pending: number;
  cancelled: boolean;
}

export interface LocationState {
  from: { pathname: string }
}

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    logout: () => initialState
  },
  extraReducers: (builder) => {
    // User Login
    builder.addCase(LoginUser.pending, (state) => {
      state.userLoading = true;
      state.error = '';
    });
    builder.addCase(LoginUser.fulfilled, (state, { payload }) => {
      state.userLoading = false;
      state.user = { ...(payload.response_data ?? {}), updatedOn: new Date()};
    });
    builder.addCase(LoginUser.rejected, (state, { payload }) => {
      state.userLoading = false;
      state.error = String(payload);
    });

    // User Register
    builder.addCase(RegisterUser.pending, (state) => {
      state.loading = true;
      state.registerAnswer = undefined;
    });
    builder.addCase(RegisterUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.registerAnswer = payload.response_data as RegisterAnswer | undefined;
    });
    builder.addCase(RegisterUser.rejected, (state/*, { payload }*/) => {
      state.loading = false;
      //state.registerAnswer = payload?.response_data as RegisterAnswer | undefined;
    });

    // Refresh user data
    builder.addCase(RefreshUser.fulfilled, (state, { payload }) => {
      state.user = {
        ...(payload ?? {}),
        updatedOn: new Date(),
        token: state.user.token
      };
    });

    // User Confirm
    builder.addCase(ConfirmUser.pending, (state) => {
      state.loading = true;
      state.confirmAnswer = undefined;
    });
    builder.addCase(ConfirmUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.confirmAnswer = {
        success: payload
      };
    });
    builder.addCase(ConfirmUser.rejected, (state) => {
      state.loading = false;
      state.confirmAnswer = {
        success: false
      };
    });

    // User Password Changed using Remember Password
    builder.addCase(SetResetPassword.pending, (state) => {
      state.loadingReset = true;
      state.resetPasswordAnswer = undefined;
    });
    builder.addCase(SetResetPassword.fulfilled, (state, { payload }) => {
      state.loadingReset = false;
      state.resetPasswordAnswer = payload as ResetAnswer | undefined;
    });
    builder.addCase(SetResetPassword.rejected, (state, { payload }) => {
      state.loadingReset = false;
      state.resetPasswordAnswer = payload as ResetAnswer | undefined;
    });

    // Users's tokens
    builder.addCase(getUserToken.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getUserToken.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.tokens = payload;
    });
    builder.addCase(getUserToken.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = String(payload);
    });

    // Users's Quota
    builder.addCase(getUserQuota.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getUserQuota.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.quota = payload;
    });
    builder.addCase(getUserQuota.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = String(payload);
    });

    // User Impersonate
    builder.addCase(ImpersonateUser.pending, (state) => {
      state.userLoading = true;
      state.error = '';
    });
    builder.addCase(ImpersonateUser.fulfilled, (state, { payload }) => {
      state.userLoading = false;
      state.user = { ...payload, updatedOn: new Date()};
    });
    builder.addCase(ImpersonateUser.rejected, (state, { payload }) => {
      state.userLoading = false;
      state.error = String(payload);
    });

    // User UnImpersonate
    builder.addCase(UnImpersonateUser.pending, (state) => {
      state.userLoading = true;
      state.error = '';
    });
    builder.addCase(UnImpersonateUser.fulfilled, (state, { payload }) => {
      state.userLoading = false;
      state.user = { ...payload, updatedOn: new Date() };
    });
    builder.addCase(UnImpersonateUser.rejected, (state, { payload }) => {
      state.userLoading = false;
      state.error = String(payload);
    });
  }
});

export const { reducer: userReducer, actions } = userSlice;
