export enum eListingErrorFlags {
  NoError = 0,
  Unknown = 1,
  MustWait = 2,
  InvalidToken = 4,
  InvalidChannelItem = 8,
  UnknownSource = 16,
  UnableToScrape = 32,
  OOSIgnored = 64,
  ChannelAccountSuspended = 128,
  ForbiddenWords = 256,
  InVeRO = 256,
  TemporaryError = 512
}