import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ResendConfirmationEmail } from '../../redux/user/userThunk';
import { T } from '../../utils/transShim';

export const NotValidated = (props: { userCreated: Date }) => {
  const { userCreated } = props;
  const dispatch = useDispatch();

  const [sent, SetSent] = useState<boolean>(false);
  const [sending, SetSending] = useState<boolean>(false);

  const HandleClick = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    SetSending(true);
    await dispatch(ResendConfirmationEmail());
    SetSending(false);
    SetSent(true);
  };

  const RenderSend = () => {
    if (sent)
      return <T k='Notifications.Validation.Sent' />;
    if (sending)
      return <T k='Notifications.Validation.Sending' />;
    return <a href="#" onClick={HandleClick} ><T k='Notifications.Validation.Resend' /></a>;
  };

  const daysSinceCreation = ((new Date()).getTime() - userCreated.getTime()) / (24 * 60 * 60 * 1000);
  const daysLeft = Math.max(0,Math.round(7 - daysSinceCreation));

  return <>
    <T k='Notifications.Validation.Not' values={{ q: daysLeft }} />&nbsp;{RenderSend()}
  </>;
};

