export interface AutoOrderField {
  //id: number;
  label: string; //id to get the text from translations
  type: AutoOrderFieldType;
  placeholder?: string;
  size?: AutoOrderFieldSize;
  setting: UserSupplierSettingsKeys;
  description?: string;
  labelOff?: string;//Only for check
  selectValues?: { key: number, label: string }[]//only for AutoOrderFieldType.SELECT
  maxLength?: number;
  defaultValue?: number | boolean | string;
}
export enum AutoOrderFieldType {
  TEXT,
  PASSWORD,
  NUMBER,
  BOOL,
  EMAIL,
  PHONE,
  SELECT,
  CARD
}
export enum AutoOrderFieldSize {
  MEDIUM,
  SHORT,
  LONG
}
export enum UserSupplierSettingsKeys {
  ACTIVE = 1,
  CARD_NUMBER = 2,
  CARD_CVV = 3,
  PHONE = 4,
  USERNAME = 5,
  PASSWORD = 6,
  OTP = 7,
  ALIAS = 8,
  GIFT_CARD = 9,
  LAST_NAME = 10,
  STREET_1 = 11,
  FIRST_NAME = 12,
  STREET_2 = 13,
  COUNTRY = 14,
  CITY = 15,
  PROVINCE = 16,
  POSTCODE = 17,
  CARD_NAME = 18,
  CARD_DATE_MONTH = 19,
  CARD_DATE_YEAR = 20,
  MARK_AS_GIFT = 21,
  GIFT_MESSAGE = 22,
  GIFT_FROM = 23,
  PAYMENT_TYPE = 24,
  PROXY_CUSTOM = 25,
  PROXY_IP = 26,
  PROXY_PORT = 27,
  PROXY_PRIVATE = 28,
  PROXY_USERNAME = 29,
  PROXY_PASSWORD = 30,
  EMAIL = 31,
  SUPPLIER_PAYMENT_PASSWORD = 32,
  REPLACE_TELEPHONE = 33,
  PROTECTION_SERVICES_RETURN = 34,
  PROTECTION_SERVICES_LOGISTICS = 35,
  GIFT_CARD_NUMBER = 36,
  GIFT_CARD_PIN = 37,
  BUSINESS_ACCOUNT = 38,
  DISCOUNT_CODE = 39,
  COMPANY_NAME = 40
}
const Fields: {
  Alias: AutoOrderField;
  Disable: AutoOrderField;
  AuthorizeHGRAccount: AutoOrderField;
  EmailLogin: AutoOrderField;
  TextLogin: AutoOrderField;
  Password: AutoOrderField;
  OTP: AutoOrderField;
  Phone: AutoOrderField;
  Email: AutoOrderField;
  BusinessAccount: AutoOrderField;

  CompleteCard: AutoOrderField;
  FourNumbersCard: AutoOrderField;
  CVV: AutoOrderField;
  CardName: AutoOrderField;
  CardExpiryMonth: AutoOrderField;
  CardExpiryYear: AutoOrderField;
  SupplierPaymentPassword: AutoOrderField;

  BillingFirstName: AutoOrderField;
  BillingLastName: AutoOrderField;
  BillingStreet1: AutoOrderField;
  BillingStreet2: AutoOrderField;
  BillingCountry: AutoOrderField;
  BillingProvince: AutoOrderField;
  BillingCity: AutoOrderField;
  BillingPostCode: AutoOrderField;
  CompanyName: AutoOrderField;

  GiftCard: AutoOrderField;
  UsePoints: AutoOrderField;
  DiscountCode: AutoOrderField;

  MarkAsGift: AutoOrderField;
  GiftMessage: AutoOrderField;
  GiftFrom: AutoOrderField;

  PaymentTypeCardAndPaypal: AutoOrderField;
  PaymentTypeWalletRightNow: AutoOrderField;

  ProxyCustom: AutoOrderField,
  ProxyIp: AutoOrderField,
  ProxyPort: AutoOrderField,
  ProxyPrivate: AutoOrderField,
  ProxyUsername: AutoOrderField,
  ProxyPassword: AutoOrderField,
  ReplaceTelephone: AutoOrderField,

  ProtectionServicesReturn: AutoOrderField,
  ProtectionServicesLogistics: AutoOrderField,
  GiftCardNumber: AutoOrderField,
  GiftCardPin: AutoOrderField,
} = {
  Alias: {
    label: 'AutoOrderingConfiguration.Alias',
    type: AutoOrderFieldType.TEXT,
    setting: UserSupplierSettingsKeys.ALIAS
  },
  Disable: {
    label: 'AutoOrderingConfiguration.Enabled',
    labelOff: 'AutoOrderingConfiguration.Disabled',
    type: AutoOrderFieldType.BOOL,
    setting: UserSupplierSettingsKeys.ACTIVE,
    description: 'AutoOrderingConfiguration.Field.Enabled.Description'
  },
  AuthorizeHGRAccount: {
    label: 'AutoOrderingConfiguration.AuthorizedHGRAccount',
    labelOff: 'AutoOrderingConfiguration.DisauthorizeHGRAccount',
    type: AutoOrderFieldType.BOOL,
    setting: UserSupplierSettingsKeys.ACTIVE,
    description: 'AutoOrderingConfiguration.Field.AuthorizedHGRAccount.Description'
  },
  EmailLogin: {
    label: 'AutoOrderingConfiguration.Username',
    type: AutoOrderFieldType.EMAIL,
    placeholder: 'example@email.com',
    setting: UserSupplierSettingsKeys.USERNAME
  },
  TextLogin: {
    label: 'AutoOrderingConfiguration.Username',
    type: AutoOrderFieldType.TEXT,
    setting: UserSupplierSettingsKeys.USERNAME
  },
  Password: {
    label: 'AutoOrderingConfiguration.Password',
    type: AutoOrderFieldType.PASSWORD,
    setting: UserSupplierSettingsKeys.PASSWORD
  },
  OTP: {
    label: 'AutoOrderingConfiguration.OTP',
    type: AutoOrderFieldType.TEXT,
    setting: UserSupplierSettingsKeys.OTP,
    size: AutoOrderFieldSize.LONG
  },
  Phone: {
    label: 'AutoOrderingConfiguration.Phone',
    type: AutoOrderFieldType.PHONE,
    setting: UserSupplierSettingsKeys.PHONE
  },
  ReplaceTelephone: {
    label: 'AutoOrderingConfiguration.ReplacePhone',
    labelOff: 'AutoOrderingConfiguration.ReplacePhone',
    type: AutoOrderFieldType.BOOL,
    setting: UserSupplierSettingsKeys.REPLACE_TELEPHONE,
    description: 'AutoOrderingConfiguration.Field.ReplacePhone.Description',
    defaultValue: true
  },
  Email: {
    label: 'AutoOrderingConfiguration.Email',
    type: AutoOrderFieldType.EMAIL,
    setting: UserSupplierSettingsKeys.EMAIL
  },
  CompleteCard: {
    label: 'AutoOrderingConfiguration.CardNumber',
    type: AutoOrderFieldType.CARD,
    placeholder: '0000 0000 0000 0000',
    size: AutoOrderFieldSize.MEDIUM,
    setting: UserSupplierSettingsKeys.CARD_NUMBER
  },
  FourNumbersCard: {
    //Just last 4 numbers
    label: 'AutoOrderingConfiguration.Card4',
    type: AutoOrderFieldType.NUMBER,
    placeholder: '0000',
    size: AutoOrderFieldSize.SHORT,
    setting: UserSupplierSettingsKeys.CARD_NUMBER,
    maxLength: 4
  },
  CVV: {
    label: 'AutoOrderingConfiguration.Cvv',
    type: AutoOrderFieldType.TEXT,
    placeholder: '000',
    size: AutoOrderFieldSize.SHORT,
    setting: UserSupplierSettingsKeys.CARD_CVV
  },
  CardName: {
    label: 'AutoOrderingConfiguration.CardName',
    type: AutoOrderFieldType.TEXT,
    size: AutoOrderFieldSize.LONG,
    setting: UserSupplierSettingsKeys.CARD_NAME
  },
  CardExpiryMonth: {
    label: 'AutoOrderingConfiguration.Card_mm',
    type: AutoOrderFieldType.NUMBER,
    size: AutoOrderFieldSize.SHORT,
    maxLength: 2,
    placeholder: '01',
    setting: UserSupplierSettingsKeys.CARD_DATE_MONTH
  },
  CardExpiryYear: {
    label: 'AutoOrderingConfiguration.Card_yy',
    type: AutoOrderFieldType.NUMBER,
    size: AutoOrderFieldSize.SHORT,
    maxLength: 2,
    placeholder: '23',
    setting: UserSupplierSettingsKeys.CARD_DATE_YEAR
  },
  SupplierPaymentPassword: {
    label: 'AutoOrderingConfiguration.SupplierPaymentPassword',
    type: AutoOrderFieldType.PASSWORD,
    setting: UserSupplierSettingsKeys.SUPPLIER_PAYMENT_PASSWORD
  },
  BillingFirstName: {
    label: 'AutoOrderingConfiguration.BillingFirstName',
    type: AutoOrderFieldType.TEXT,
    setting: UserSupplierSettingsKeys.FIRST_NAME
  },
  BillingLastName: {
    label: 'AutoOrderingConfiguration.BillingLastName',
    type: AutoOrderFieldType.TEXT,
    setting: UserSupplierSettingsKeys.LAST_NAME
  },
  BillingStreet1: {
    label: 'AutoOrderingConfiguration.BillingStreet1',
    type: AutoOrderFieldType.TEXT,
    setting: UserSupplierSettingsKeys.STREET_1,
    size: AutoOrderFieldSize.LONG
  },
  BillingStreet2: {
    label: 'AutoOrderingConfiguration.BillingStreet2',
    type: AutoOrderFieldType.TEXT,
    setting: UserSupplierSettingsKeys.STREET_2,
    size: AutoOrderFieldSize.LONG
  },
  BillingCountry: {
    label: 'AutoOrderingConfiguration.BillingCountry',
    type: AutoOrderFieldType.TEXT,
    setting: UserSupplierSettingsKeys.COUNTRY,
    size: AutoOrderFieldSize.LONG
  },
  BillingProvince: {
    label: 'AutoOrderingConfiguration.BillingProvince',
    type: AutoOrderFieldType.TEXT,
    setting: UserSupplierSettingsKeys.PROVINCE,
    size: AutoOrderFieldSize.LONG
  },
  BillingCity: {
    label: 'AutoOrderingConfiguration.BillingCity',
    type: AutoOrderFieldType.TEXT,
    setting: UserSupplierSettingsKeys.CITY,
    size: AutoOrderFieldSize.LONG
  },
  BillingPostCode: {
    label: 'AutoOrderingConfiguration.BillingZip',
    type: AutoOrderFieldType.TEXT,
    setting: UserSupplierSettingsKeys.POSTCODE
  },
  GiftCard: {
    label: 'AutoOrderingConfiguration.GiftCard',
    type: AutoOrderFieldType.SELECT,
    setting: UserSupplierSettingsKeys.GIFT_CARD,
    selectValues: [
      { key: 0, label: 'Setting.No' },
      { key: 1, label: 'Setting.IfPossible' },
      { key: 2, label: 'AutoOrderingConfiguration.Field.Label.OnlyGifCard' }
    ]
  },
  UsePoints: {
    label: 'AutoOrderingConfiguration.UsePoints',
    type: AutoOrderFieldType.SELECT,
    setting: UserSupplierSettingsKeys.GIFT_CARD,
    selectValues: [{ key: 0, label: 'Setting.No' }, { key: 1, label: 'Setting.Yes' }]
  },
  MarkAsGift: {
    label: 'AutoOrderingConfiguration.MarkGift',
    type: AutoOrderFieldType.SELECT,
    setting: UserSupplierSettingsKeys.MARK_AS_GIFT,
    selectValues: [{ key: 0, label: 'Setting.No' }, { key: 1, label: 'Setting.Yes' }]
  },
  GiftMessage: {
    label: 'AutoOrderingConfiguration.GiftMessage',
    type: AutoOrderFieldType.TEXT,
    setting: UserSupplierSettingsKeys.GIFT_MESSAGE,
    size: AutoOrderFieldSize.LONG
  },
  GiftFrom: {
    label: 'AutoOrderingConfiguration.GiftFrom',
    type: AutoOrderFieldType.TEXT,
    setting: UserSupplierSettingsKeys.GIFT_FROM
  },
  BusinessAccount: {
    label: 'AutoOrderingConfiguration.BusinessAccount',
    type: AutoOrderFieldType.SELECT,
    setting: UserSupplierSettingsKeys.BUSINESS_ACCOUNT,
    selectValues: [{ key: 0, label: 'Setting.No' }, { key: 1, label: 'Setting.Yes' }]
  },
  CompanyName: {
    label: 'AutoOrderingConfiguration.CompanyName',
    type: AutoOrderFieldType.TEXT,
    setting: UserSupplierSettingsKeys.COMPANY_NAME,
    size: AutoOrderFieldSize.MEDIUM
  },
  DiscountCode: {
    label: 'AutoOrderingConfiguration.DiscountCode',
    type: AutoOrderFieldType.TEXT,
    setting: UserSupplierSettingsKeys.DISCOUNT_CODE,
    size: AutoOrderFieldSize.MEDIUM
  },
  PaymentTypeCardAndPaypal: {
    label: 'AutoOrderingConfiguration.PaymentType',
    type: AutoOrderFieldType.SELECT,
    setting: UserSupplierSettingsKeys.PAYMENT_TYPE,
    selectValues: [{ key: 1, label: 'AutoOrderingConfiguration.Field.Label.Card' }, { key: 2, label: 'PayPal' }],
  },
  PaymentTypeWalletRightNow: {
    label: 'AutoOrderingConfiguration.PaymentType',
    type: AutoOrderFieldType.SELECT,
    setting: UserSupplierSettingsKeys.PAYMENT_TYPE,
    selectValues: [{ key: 3, label: 'Wallet' }],
    description: 'AutoOrderingConfiguration.Field.PaymentType.Description'
  },
  ProxyCustom: {
    label: 'AutoOrderingConfiguration.ProxyCustom',
    labelOff: 'AutoOrderingConfiguration.ProxyCustom',
    type: AutoOrderFieldType.BOOL,
    setting: UserSupplierSettingsKeys.PROXY_CUSTOM,
    description: 'AutoOrderingConfiguration.Field.ProxyCustom.Description'
  },
  ProxyIp: {
    label: 'AutoOrderingConfiguration.ProxyIp',
    placeholder: '111.111.1.11',
    type: AutoOrderFieldType.TEXT,
    size: AutoOrderFieldSize.MEDIUM,
    setting: UserSupplierSettingsKeys.PROXY_IP
  },
  ProxyPort: {
    label: 'AutoOrderingConfiguration.ProxyPort',
    placeholder: '1234',
    type: AutoOrderFieldType.NUMBER,
    size: AutoOrderFieldSize.SHORT,
    setting: UserSupplierSettingsKeys.PROXY_PORT
  },
  ProxyPrivate: {
    label: 'AutoOrderingConfiguration.ProxyPrivate',
    labelOff: 'AutoOrderingConfiguration.ProxyPrivate',
    type: AutoOrderFieldType.BOOL,
    setting: UserSupplierSettingsKeys.PROXY_PRIVATE,
  },
  ProxyUsername: {
    label: 'AutoOrderingConfiguration.ProxyUsername',
    type: AutoOrderFieldType.EMAIL,
    setting: UserSupplierSettingsKeys.PROXY_USERNAME
  },
  ProxyPassword: {
    label: 'AutoOrderingConfiguration.ProxyPassword',
    type: AutoOrderFieldType.PASSWORD,
    setting: UserSupplierSettingsKeys.PROXY_PASSWORD
  },
  ProtectionServicesReturn: {
    label: 'AutoOrderingConfiguration.ProtectionServicesReturn',
    labelOff: 'AutoOrderingConfiguration.ProtectionServicesReturn',
    type: AutoOrderFieldType.BOOL,
    setting: UserSupplierSettingsKeys.PROTECTION_SERVICES_RETURN,
    description: 'AutoOrderingConfiguration.Field.ProtectionServicesReturn.Description',
    defaultValue: false
  },
  ProtectionServicesLogistics: {
    label: 'AutoOrderingConfiguration.ProtectionServicesLogistics',
    labelOff: 'AutoOrderingConfiguration.ProtectionServicesLogistics',
    type: AutoOrderFieldType.BOOL,
    setting: UserSupplierSettingsKeys.PROTECTION_SERVICES_LOGISTICS,
    description: 'AutoOrderingConfiguration.Field.ProtectionServicesLogistics.Description',
    defaultValue: false
  },
  GiftCardNumber: {//Asda uses this, they behave like a wallet
    label: 'AutoOrderingConfiguration.GiftCardNumber',
    type: AutoOrderFieldType.TEXT,
    setting: UserSupplierSettingsKeys.GIFT_CARD_NUMBER
  },
  GiftCardPin: {//Asda uses this, they behave like a wallet
    label: 'AutoOrderingConfiguration.GiftCardPin',
    type: AutoOrderFieldType.PASSWORD,
    setting: UserSupplierSettingsKeys.GIFT_CARD_PIN
  }
};
export { Fields };
