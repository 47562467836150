import { Spin, Switch } from 'antd';
import '../../sass/settings/settings.scss';

interface Props{
  defaultValue?: string | boolean | null;
  onChange?: (value: string | null, valueB: boolean | null) => void;
  loading?: boolean;
  disabled?: boolean;
  value?: string | boolean;
}

export const SettingBoolean = (props: Props) => {
  const { defaultValue, value, onChange, loading, disabled } = props;

  const defautlChecked = defaultValue == '1' || defaultValue?.toString()?.toLowerCase() == 'true';
  const checked = value == null
    ? undefined
    : value == '1' || value?.toString().toLowerCase() == 'true';

  return <div className="setting setting-boolean">
    {!loading && <Switch
      checked={checked} defaultChecked={defautlChecked}
      onChange={x => onChange?.(x ? '1' : '0', x)}
      disabled={disabled}
    />}
    {loading && <Spin />}
  </div>;
};