import { ePaymentError } from '../../redux/payment/paymentSlice';
import { T } from '../../utils/transShim';

type Props  = {
  error?: ePaymentError;
}

export const FailedPayment = (props: Props) => {
  const { error } = props;
  
  switch (error) {
    default:
    case ePaymentError.Unknown:
      return <T k='Payment.UnknownError'/>;
    case ePaymentError.Declined:
      return <T k='Payment.Declined'/>;
    case ePaymentError.Insufficient:
      return <T k='Payment.InsuficientFunds' />;
    case ePaymentError.InvalidCard:
      return <T k='Payment.InvalidCard' />;
    case ePaymentError.OtherBankError:
      return <T k='Payment.BankError' />;
  }
};


export const WithdrawPayment = (props: Props) => {
  const { error } = props;

  switch (error) {
    default:
    case ePaymentError.Unknown:
    case ePaymentError.Insufficient:
      return <T k='Withdraw.UnknownError' />;
    case ePaymentError.Declined:
      return <T k='Withdraw.Declined' />;
    case ePaymentError.InvalidCard:
      return <T k='Withdraw.InvalidCard' />;
    case ePaymentError.OtherBankError:
      return <T k='Withdraw.BankError' />;
  }
};