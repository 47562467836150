import { Channel } from '../../../redux/channels/channelsSlice';
import { useTranslation } from '../../../utils/transShim';
import { ReactUtils } from '../../../utils/react-utils';
import { GetPlatform } from '../../../data/platforms';
import Paragraph from 'antd/lib/typography/Paragraph';
import { Countries, eCountry } from '../../../data/countries';
import { A } from '../../../small-components/A';


type FieldValue = unknown;
type RecordType = Record<string, FieldValue>;

export const RenderChannelItem = (channelItem: string, rowR: RecordType) => {
  const row = rowR as { channel: Channel; asin?: string; id: number };

  const selectedChannel = localStorage.getItem('selectedChannel');
  const channel = (selectedChannel ? JSON.parse(selectedChannel) : null) as Channel;

  if (!channel) return channelItem;
  const platform = GetPlatform(channel.channelId);

  if (!platform) return channelItem;

  const url = ReactUtils.GetItemUrl(channel.channelId, channel.isoCountry, channelItem, channel.channelIdentifier, row.asin);
  if (!url) return channelItem;

  return (
    <A target="_blank" rel="noreferrer" to={platform.itemUrlOutUrl ? ReactUtils.GetOutUrl(url, channel.isoCountry) ?? '' : url ?? ''}>
      {channelItem}
    </A>
  );
};

export const RenderSource = (sourceName: string, rowR: RecordType) => {
  if (sourceName == undefined || sourceName == '') return useTranslation('OrderTable.Value.UnknownSource');
  const url = rowR.sourceUrl as unknown as string;
  return (
    <A target="_blank" rel="noreferrer" to={ReactUtils.GetOutUrl(url) ?? ''}>
      {sourceName}
    </A>
  );
};

export const RenderImage = (imageUrl: string) => {
  return (
    <div className="order-img-container">
      <img
        src={imageUrl}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = 'https://hustlegotreal.com/wp-content/uploads/2022/10/error-image.png';
        }}
        className="record-img"
      />
    </div>
  );
};

export const RenderDate = (date: Date) => <div className="date">{ ReactUtils.GetFormattedDateTime(date) }</div>;

export const RenderTitle = (title: string) => {
  return (
    <Paragraph ellipsis={{ rows: 2, tooltip: true } }>
      {title}
    </Paragraph>
  );
};

export const RenderProfit = (profit: number) => {
  const className = profit > 0 ? 'green' : profit < 0 ? 'red' : '';
  const selectedChannel = localStorage.getItem('selectedChannel');
  const channel = (selectedChannel ? JSON.parse(selectedChannel) : null) as Channel;

  if (!channel) return profit;
  const symbol = Countries[channel.isoCountry ?? eCountry.UK].CurrencySymbol;
  const profitText = (isNaN(profit) ? '' : symbol) + profit;
  return (
    <span className={className}>{profitText}</span>
  );
};