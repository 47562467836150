import { Row } from 'antd';
import ebay_logo from '../../../assets/channel/ebay.png';
import shopify_logo from '../../../assets/channel/shopify-2.png';
import amazon_logo from '../../../assets/channel/amazon-2.png';
import fb_logo from '../../../assets/channel/fb-marketplace-logo.png';
import tiktok_logo from '../../../assets/channel/tiktok-logo.png';
import woocommerce_logo from '../../../assets/channel/woocommerce.png';
import { T } from '../../../utils/transShim';
import { eShop } from './eShop';

export interface props {
  onChangePlatform: (newPlatform: number) => void;
  shop: number;
  step: number;
}

export const ShopSelectorImage = (props: { shop: eShop }) => {
  const src = (() => {
    switch (props.shop) {
      default:
      case eShop.eBay:
        return ebay_logo;
      case eShop.Amazon:
        return amazon_logo;
      case eShop.Facebook:
        return fb_logo;
      case eShop.Shopify:
        return shopify_logo;
      case eShop.WooComerce:
        return woocommerce_logo;
      case eShop.TikTok:
        return tiktok_logo;
    }
  })();
  return <img src={src} className="platform-img" alt='logo' />;
};

export const ShopSelector = (props: props) => {
  const { onChangePlatform, shop } = props;

  const RenderChannelCard = (shop: eShop) => {
    return (
      <div
        className="platform-card option-steps"
        onClick={_ => onChangePlatform(shop)}
      >
        <ShopSelectorImage shop={shop} />
      </div>
    );
  };

  return (
    <form className="platforms-form">
      <div className="platforms-area">
        <h2 className="title">
          <T k="NewChannel.ShopSelector.Title" />
        </h2>
        <Row className="cards-container" gutter={[0, 24]}>
          {/*{RenderChannelCard(eShop.TikTok)}*/}
          {RenderChannelCard(eShop.Facebook)}
          {RenderChannelCard(eShop.WooComerce)}
          {RenderChannelCard(eShop.eBay)}
          {RenderChannelCard(eShop.Shopify)}
          {RenderChannelCard(eShop.Amazon)}
        </Row>
        {!shop && <p className="danger-txt">*Please select a store</p>}
      </div>
    </form>
  );
};
