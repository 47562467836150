import { Col, Row, Spin } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../../custom-hooks/reduxCustomHooks';
import { BusinessPolicyType } from '../../../../../redux/channel-configuration/channels-configuration-slice';
import { loadBusinessPolicies } from '../../../../../redux/channel-configuration/channels-configuration-thunk';
import '../../../../../sass/listing-options.scss';
import { Selector } from '../../../../../small-components/form/selector';
import { T } from '../../../../../utils/transShim';

type Props = {
  valuePaymentProfileId: number | undefined;
  valueShippingProfileId: number | undefined;
  valueReturnProfileId: number | undefined;

  onChangePaymentProfileId: (value: number) => void;
  onChangeShippingProfileId: (value: number) => void;
  onChangeReturnProfileId: (value: number) => void;
};

export const ListingBusiness = (props: Props) => {
  const {
    valuePaymentProfileId, valueShippingProfileId, valueReturnProfileId,
    onChangePaymentProfileId, onChangeShippingProfileId, onChangeReturnProfileId
  } = props;

  const dispatch = useDispatch();

  const {
    businessPolicies,
    loadingBusiness
  } = useAppSelector((state) => state.channelConfiguration);

  useEffect(() => {
    dispatch(loadBusinessPolicies());
  }, [loadBusinessPolicies]);

  if (loadingBusiness)
    return <Spin />;

  const payments = businessPolicies?.filter(x => x.policyType == BusinessPolicyType.Payment) ?? [];
  const returns = businessPolicies?.filter(x => x.policyType == BusinessPolicyType.Returns) ?? [];
  const shipping = businessPolicies?.filter(x => x.policyType == BusinessPolicyType.Shipping) ?? [];

  return (
    <div className="listing-business">
      <Row className='description-and-controls' key='1'>
        <Col span={12} className='description-area'>
          <h2><T k='Sources.Table.Name.PolicyDelivery'/></h2>
        </Col>
        <Col span={8} className='switch-container'>
          <Selector value={valueShippingProfileId} onChange={x => onChangeShippingProfileId(x as number)}>
            {shipping.map(x => ({ value: x.id, label: x.name }))}
          </Selector>
        </Col>
      </Row>
      <Row className='description-and-controls' key='2'>
        <Col span={12} className='description-area'>
          <h2><T k='Sources.Table.Name.PolicyReturns' /></h2>
        </Col>
        <Col span={8} className='switch-container'>
          <Selector value={valueReturnProfileId} onChange={x => onChangeReturnProfileId(x as number)}>
            {returns.map(x => ({ value: x.id, label: x.name }))}
          </Selector>
        </Col>
      </Row>
      <Row className='description-and-controls' key='3'>
        <Col span={12} className='description-area'>
          <h2><T k='Sources.Table.Name.BusinessPayment' /></h2>
        </Col>
        <Col span={8} className='switch-container'>
          <Selector value={valuePaymentProfileId} onChange={x => onChangePaymentProfileId(x as number)}>
            {payments.map(x => ({ value: x.id, label: x.name }))}
          </Selector>
        </Col>
      </Row>
    </div>
  );
};