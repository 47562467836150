import { CloseCircleFilled } from '@ant-design/icons';
import { RelievCard } from './reliev-card';
import { VariationListingInfo, VariationsImages } from './variation-listing-info';

type VariationPanelProps = {
  info: VariationListingInfo;
  onSelect: () => void;
  selected: boolean;
  canBeClosed: boolean;
  onDeleteClicked?: (info: VariationListingInfo) => void;
  variationsImages: VariationsImages | undefined;
};
export const VariationPanel = (props: VariationPanelProps) => {
  const { info, variationsImages, onSelect, selected, canBeClosed } = props;

  const OnDeleteClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.onDeleteClicked) props.onDeleteClicked(props.info);
  };

  let imgUrl = info.images[0];
  if ((props.info.attributeOptions?.length ?? 0) == 1) {
    const atr = props.info.attributeOptions.find(x => x.attribute === variationsImages?.attribute);
    if (atr) {
      imgUrl = variationsImages?.imagesByOption?.find(x => x.option === atr.option)?.urls[0] ?? info.images[0];
    }
  }

  return (
    <RelievCard OnSelect={onSelect} selected={selected}>
      <div className="closecontainer">
        {canBeClosed && <CloseCircleFilled className="closeicon" onClick={OnDeleteClick}></CloseCircleFilled>}
      </div>
      <img className="variationImg" src={imgUrl} />
    </RelievCard>
  );
};
