import { CloseCircleOutlined, LeftOutlined, RightOutlined, StarOutlined } from '@ant-design/icons';
import { Button, Col, Input, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { PicturesVariationsPanel } from './pictures-variations-panel';
import { ListingVariationsResponse, VariationImages, VariationListingInfo, VariationsImages } from './variation-listing-info';
import { VariationsPanel } from './variations-panel';
import '../../../sass/listings/new-listing-extension.scss';
import { PrimaryBtn } from 'src/small-components/ActionBtns';

export type IImagesProps = {
  images?: string[];
  imagesChanged: (urls: string[]) => void;

  hasVariations: boolean;
  variations: ListingVariationsResponse | null;
  //onVariationInfoChanged: (info: VariationsImages) => any;
  onVariationsChanged: (info: ListingVariationsResponse) => void;
};

export type IImagesState = {
  addingNewImage: boolean;
  selectedOptionName?: string;
  selectedOptionId?: number; //In case the string changes
  selectedVariationAddingImg?: number;
};

export const ReCalculateUrls = (variations: ListingVariationsResponse): ListingVariationsResponse => {
  const newVariations: ListingVariationsResponse = {
    ...variations,
    variationImages: {
      ...variations.variationImages
    }
  };
  //If attribute is null, all images will be together, otherwise we filter by attribute
  const attributeToFilter = variations.variationImages?.attribute;
  const newImagesByOption: VariationImages[] = [];
  if (!attributeToFilter) {
    const uniqueOption: VariationImages = { option: null, urls: [] };
    for (const v of variations.variations) {
      for (const img of v.images) {
        uniqueOption.urls.push(img);
      }
    }
    newImagesByOption.push(uniqueOption);
  } else {
    const dicByOption: { [option: string]: string[] } = {};
    for (const v of variations.variations) {
      let opValue = '';
      for (const ao of v.attributeOptions) {
        if (ao.attribute == attributeToFilter) {
          opValue = ao.option;
          break;
        }
      }
      if (!dicByOption[opValue]) {
        dicByOption[opValue] = [];
      }
      for (const img of v.images) {
        dicByOption[opValue].push(img);
      }
    }
    for (const option of Object.keys(dicByOption)) {
      newImagesByOption.push({
        option: option,
        urls: dicByOption[option]
      });
    }
  }

  newVariations.variationImages.imagesByOption = newImagesByOption;
  return newVariations;
};

//export class ImagesView extends React.PureComponent<IImagesProps, IImagesState> {
export const ImagesView = (props: IImagesProps) => {
  const [addingNewImage, SetAddingNewImage] = useState<boolean>(false);
  const [selectedOptionName, SetSelectedOptionName] = useState<string | undefined>(undefined);
  const [selectedOptionId, SetSelectedOptionId] = useState<number | undefined>(undefined);
  const [selectedVariationAddingImg, SetSelectedVariationAddingImg] = useState<number | undefined>(undefined);
  const [refImgNewUrl, SetRefImgNewUrl] = useState<string>('');

  const GetSelectedOption = () => {
    if (!props.variations?.variationImages?.imagesByOption) return '';

    let io = props.variations.variationImages.imagesByOption.find((x) => x.option == selectedOptionName);
    if (io) {
      return selectedOptionName;
    }
    io = props.variations.variationImages.imagesByOption[selectedOptionId ?? 0];
    if (io) {
      return io.option;
    }
    return props.variations.variationImages.imagesByOption[0]?.option;
  };

  const GetVisibleVariationImages = (selectedOption: string) => {
    return props.variations?.variationImages?.imagesByOption?.find((x) => x.option == selectedOption)?.urls ?? [];
  };

  const OnAttributeSelected = (attribute: string) => {
    let newVariations: ListingVariationsResponse = {
      ...props.variations,
      variationImages: {
        ...(props.variations?.variationImages ?? []),
        attribute: attribute
      } as VariationsImages
    } as ListingVariationsResponse;

    newVariations = ReCalculateUrls(newVariations);

    props.onVariationsChanged(newVariations);
  };

  const OnOptionSelected = (option: string, i: number) => {
    SetSelectedOptionName(option);
    SetSelectedOptionId(i);
  };

  const SwapImages = (currentPosition: number, newPosition: number) => {
    if (!props.hasVariations) {
      const urls = [...(props.images ?? [])];
      const toMove = urls[currentPosition];
      urls[currentPosition] = urls[newPosition];
      urls[newPosition] = toMove;

      props.imagesChanged(urls);
    } else {
      const selectedOption = GetSelectedOption();

      const newImagesByOption = [...(props.variations?.variationImages?.imagesByOption ?? [])];
      const id = newImagesByOption.findIndex((x) => x.option == selectedOption);
      const urls = [...newImagesByOption[id].urls];

      const toMove = urls[currentPosition];
      urls[currentPosition] = urls[newPosition];
      urls[newPosition] = toMove;

      newImagesByOption[id].urls = urls;

      props.onVariationsChanged({
        ...props.variations,
        variationImages: {
          ...(props.variations?.variationImages ?? []),
          imagesByOption: newImagesByOption
        }
      } as ListingVariationsResponse);
    }
  };

  const PromoteImage = (currentPosition: number) => {
    if (!props.hasVariations) {
      const urls = [props.images?.[currentPosition] ?? ''];
      for (let i = 0; i < (props.images?.length ?? 0); i++) {
        if (i == currentPosition) continue;
        urls.push(props.images?.[i] ?? '');
      }
      props.imagesChanged(urls);
    } else {
      const selectedOption = GetSelectedOption();
      const newImagesByOption = [...(props.variations?.variationImages?.imagesByOption ?? [])];
      const id = newImagesByOption.findIndex((x) => x.option == selectedOption);
      const oldUrls = newImagesByOption[id].urls;
      const urls = [oldUrls[currentPosition]];

      for (let i = 0; i < oldUrls.length; i++) {
        if (i == currentPosition) continue;
        urls.push(oldUrls[i]);
      }

      newImagesByOption[id].urls = urls;

      props.onVariationsChanged({
        ...props.variations,
        variationImages: {
          ...(props.variations?.variationImages ?? []),
          imagesByOption: newImagesByOption
        }
      } as ListingVariationsResponse);
    }
  };

  const RemoveImage = (position: number) => {
    if (!confirm('Are you sure you want to remove this image?')) return;
    if (!props.hasVariations) {
      const urls = [...(props.images ?? [])];
      urls.splice(position, 1); // remove 1 from that array
      props.imagesChanged(urls);
    } else {
      const selectedOption = GetSelectedOption();
      const newImagesByOption = [...(props.variations?.variationImages?.imagesByOption ?? [])];
      const id = newImagesByOption.findIndex((x) => x.option == selectedOption);
      const oldUrls = newImagesByOption[id].urls;
      const urls = [...oldUrls];

      const urlToRemove = urls[position];

      urls.splice(position, 1); // remove 1 from that array
      newImagesByOption[id] = { ...newImagesByOption[id], urls: urls };

      const variations: VariationListingInfo[] = [...(props.variations?.variations ?? [])];
      let found = false;
      for (const i in variations) {
        const v = variations[i];
        for (const j in v.images) {
          if (v.images[j] == urlToRemove) {
            variations[i] = {
              ...variations[i],
              images: [...v.images]
            };
            variations[i].images.splice(j as unknown as number, 1);
            found = true;
            break;
          }
        }
        if (found) break;
      }

      props.onVariationsChanged({
        ...props.variations,
        variations: variations,
        variationImages: {
          ...(props.variations?.variationImages ?? []),
          imagesByOption: newImagesByOption
        }
      } as ListingVariationsResponse);
    }
  };

  const AddImageUrl = (url: string) => {
    if (!props.hasVariations) {
      const urls = [...(props.images ?? [])];
      urls.push(url);
      props.imagesChanged(urls);
    } else {
      const variations: VariationListingInfo[] = [...(props.variations?.variations ?? [])];
      const variation: VariationListingInfo = { ...variations[selectedVariationAddingImg ?? 0] };
      const vImg: string[] = [...(variation.images ?? [])];
      vImg.push(url);
      variation.images = vImg;
      variations[selectedVariationAddingImg ?? 0] = variation;

      const newVariationImages = { ...(props.variations?.variationImages ?? []) } as VariationsImages;
      newVariationImages.imagesByOption = [...newVariationImages.imagesByOption];
      if (!newVariationImages.attribute) {
        newVariationImages.imagesByOption[0].urls.push(url);
      } else {
        const optionC = variation?.attributeOptions?.find((x) => x?.attribute == newVariationImages?.attribute)?.option;
        for (const i in newVariationImages.imagesByOption) {
          const ibo = newVariationImages.imagesByOption[i];
          if (ibo.option == optionC) {
            ibo.urls = [...ibo.urls];
            ibo.urls.push(url);
            newVariationImages.imagesByOption[i] = ibo;
            break;
          }
        }
      }

      const newVariations: ListingVariationsResponse = {
        ...props.variations,
        variations: variations,
        variationImages: newVariationImages
      } as ListingVariationsResponse;
      props.onVariationsChanged(newVariations);
    }
  };

  let images: string[];
  const selectedOption = GetSelectedOption();
  if (props.hasVariations) {
    images = GetVisibleVariationImages(selectedOption ?? '');
  } else {
    images = props.images ?? [];
  }

  const rows = [];
  let columns = [];
  for (let ix = 0; ix < images.length; ix++) {
    const i = ix;
    const imgUrl = images[i];
    columns.push(
      <Col xs={11} md={7} key={i} className="image-container">
        <img className="img-responsive" src={imgUrl} alt="Product Image" />
        <Input.Group className="buttons" compact>
          {i > 0 && (
            <Button onClick={(_) => SwapImages(i, i - 1)}>
              <LeftOutlined />
            </Button>
          )}
          {i !== 0 && (
            <Button onClick={(_) => PromoteImage(i)} style={{ color: 'gold' }}>
              <StarOutlined />
            </Button>
          )}
          {i < images.length - 1 && (
            <Button onClick={(_) => SwapImages(i, i + 1)}>
              <RightOutlined />
            </Button>
          )}
          <Button onClick={(_) => RemoveImage(i)}>
            <CloseCircleOutlined />
          </Button>
        </Input.Group>
      </Col>
    );
    if (columns.length % 3 == 0) {
      rows.push(
        <Row className="image-row" key={i}>
          {columns}
        </Row>
      );
      columns = []; // keep previous refs of array, 'arr.length = 0;' would also work
    }
  }
  if (columns && columns.length) rows.push(<Row key={rows.length}>{columns}</Row>);

  const attributeOptionsDicSet: { [attribute: string]: Set<string> } = {};
  const attributeOptionsDic: { [attribute: string]: string[] } = {};
  props.variations?.variations?.forEach((x) => {
    x.attributeOptions.forEach((y) => {
      if (!attributeOptionsDicSet[y.attribute]) {
        attributeOptionsDicSet[y.attribute] = new Set();
        attributeOptionsDic[y.attribute] = [];
      }
      if (!attributeOptionsDicSet[y.attribute].has(y.option)) {
        attributeOptionsDicSet[y.attribute].add(y.option);
        attributeOptionsDic[y.attribute].push(y.option);
      }
    });
  });

  return (
    <>
      {props.hasVariations && (
        <>
          <PicturesVariationsPanel
            atributeOptionsList={attributeOptionsDic}
            selectedAttribute={props.variations?.variationImages?.attribute ?? null}
            selectedOption={selectedOption ?? ''}
            onAttributeSelected={OnAttributeSelected}
            onOptionSelected={OnOptionSelected}
          />
          <div>Warning: Adding new variations or modifying their current attributes will reset the images ordering.</div>
        </>
      )}

      <p className="images-text-counter">Number of images: {images.length}</p>
      <PrimaryBtn className=" btn-addimages" onClick={() => SetAddingNewImage(true)}>
        Add image from link
      </PrimaryBtn>

      <div className="imgGallery">{rows}</div>
      <Modal
        open={addingNewImage}
        onCancel={() => SetAddingNewImage(false)}
        title="Add image from link"
        footer={
          <>
            <Button type="primary" onClick={() => SetAddingNewImage(false)}>
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={(_) => {
                SetAddingNewImage(false);

                const newImgUrl = refImgNewUrl;

                if (!newImgUrl.startsWith('http://') && !newImgUrl.startsWith('https://')) {
                  alert('Wrong url, remember to include http:// or https://');
                  return;
                }

                AddImageUrl(newImgUrl);
              }}
            >
              Accept
            </Button>
          </>
        }
      >
        <Input type="text" placeholder="https://www.url.com/img.jpg" onChange={(e) => SetRefImgNewUrl(e.currentTarget.value)} />
        {props.hasVariations && (
          <VariationsPanel
            attributePanel={false}
            canClearVariations={false}
            mainVariationId={-99} //Not relevant. Only used to differenciate which one can't be deleted and since we don't let it to delete
            onSelected={(_, i) => SetSelectedVariationAddingImg(i)}
            selectedVariationIndex={selectedVariationAddingImg ?? 0}
            variations={props.variations}
          />
        )}
      </Modal>
    </>
  );
};
