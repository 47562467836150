
import { ePlatform, GetPlatform } from '../../data/platforms';
import { Links } from '../../links';
import { A } from '../../small-components/A';
import { PrimaryBtn } from '../../small-components/ActionBtns';
import { useTranslation } from '../../utils/transShim';

export interface ServiceData {
  id: number;
  title: string;
  shortDescription: string;
  paragraphs: React.ReactNode[];
  bulletPoints: React.ReactNode[];
  image: string;
  link: string;
  currency?: string;
  price?: string;
  slug: string;
}

export const AllServicesData = (platform: ePlatform): ServiceData[] => {
  return [
    {
      id: 1,
      title: 'Winning products list',
      shortDescription:
        'By providing a CSV file with URLs of winning products, we give our users access to a selection of products that have proven success and customer satisfaction.',
      paragraphs: [
        'A winning product is one that effectively meets the needs and desires of its target market, resulting in strong sales and customer satisfaction. It should be of high quality and provide value to the user, with features and benefits that differentiate it from its competitors. A winning product should also have a clear and compelling brand message that resonates with its audience, as well as a strong and unique value proposition that sets it apart in the market.',
        'This service is an efficient and cost-effective way for store owners to discover new products to add to their inventory, as it eliminates the need for time-consuming research and manual analysis of product data.',
        'Using our CSV file, you can quickly and easily import a list of winning products into your store and start selling them to your customers. This can help you increase your sales and revenue, and potentially grow your business.',
        'There are several reasons why you should have winning products in your store:'
      ],
      bulletPoints: [
        'High demand: Winning products are in high demand, which means that there is a large market for them. This can lead to higher sales and profits for your store.',
        'Competitive advantage: Having a unique or high-quality winning product can give you a competitive advantage over other stores that do not offer the same product.',
        'Customer satisfaction: Winning products are able to meet the needs or wants of a significant portion of the market, which can lead to higher levels of customer satisfaction.',
        'Brand reputation: Offering winning products can help to build and enhance your store’s reputation as a reliable and reputable source for high-quality products.'
      ],
      image: 'https://hustlegotreal.com/wp-content/uploads/2022/12/iconservice.png',
      slug: 'winning-products-list',
      link: Links.Services.WinningProductsList,
      currency: '',
      price: ''
    },
    {
      id: 2,
      title: 'Price Warrior',
      shortDescription:
        'Ensure sales like never before, with this tool you will be able to see your competitors prices and make sure you are always offering a more competitive price.',
      paragraphs: [
        'Fight against your competition! Thanks to Price Warrior you can ensure that your price is always the cheapest and get the most sales possible, automate your pricing with this tool and scale your business by beating your rivals.'
      ],
      bulletPoints: [
        'Price Warrior monitors all your listings every day to detect thieves.',
        'It undercuts thieves by a set amount automatically.',
        'Define a minimum markup you are willing to sell at. Price Warrior will never set the price below that markup.',
        'Price Warrior will update automatically, and you will have access to a report showing the status of all the listings being managed by Price Warrior.'
      ],
      image: 'https://hustlegotreal.com/wp-content/uploads/2022/08/price-warrior.jpg',
      slug: 'price-warrior',
      link: Links.Services.PriceWarrior,
      currency: '€',
      price: '17.99'
    },
    {
      id: 3,
      title: 'Private Supplier',
      shortDescription:
        'Take your drop shipping business to the next level and increase your sales avoiding competition from other sellers.',
      paragraphs: [
        'Take your drop shipping business to the next level and increase your sales avoiding competition from other sellers.',
        'Due to popular demand, we are now offering you the option to have exclusive drop shipping suppliers. This means that you will be the only seller allowed to use that specific supplier via Hustle Got Real.',
        'Other sellers won’t even see the name of your supplier, and you will be able to use Hustle Got Real to list their items and monitor any stock/price changes automatically.',
        'Once you have sent the first payment, the supplier will be available to you within 3 business days.If you are interested in this service, please email contact@hustlegotreal.com, indicating your supplier’s website.'
      ],
      bulletPoints: [],
      image: 'https://hustlegotreal.com/wp-content/uploads/2022/08/private-supplier-hustle-got-real.png',
      slug: 'private-supplier',
      link: Links.Services.PrivateSupplier,
      currency: '£',
      price: '200'
    },
    {
      id: 4,
      title: 'No API Server',
      shortDescription:
        'Thanks to our non-Api servers your store will be connected 24/7 to our servers so your stock and prices will always be kept up to date.',
      paragraphs: [
        <A key="4" to={Links.ConfigureNoapi}>
          <PrimaryBtn>Manage no api server</PrimaryBtn>
        </A>,
        'Thanks to our non-Api servers your store will be connected 24/7 to our servers so your stock and prices will always be kept up to date.'
      ],
      bulletPoints: [
        'eBay store connected 24/7 to update your product information',
        'NO API extension connected to our remote server',
        'Avoid ebay bans'
      ],
      image: 'https://hustlegotreal.com/wp-content/uploads/2022/08/no-api-server.png',
      slug: 'no-api-server',
      link: Links.Services.NoApiServer,
      currency: '€',
      price: '15.99'
    },
    {
      id: 5,
      title: 'Auto Ordering',
      shortDescription:
        'Orders will now be processed automatically and you will be able to configure and manage your auto ordering settings directly from your HGR account.',
      paragraphs: [
        'Forget about processing your orders manually. They will now be processed automatically and you will be able to configure and manage your auto ordering settings directly from your HGR account.'
      ],
      bulletPoints: [
        'When you have an order in your store, our system will detect it and start processing it automatically.',
        'When the order is being processed, HGR will do the entire checkout process in record time and it will be marked as dispatched on your store'
      ],
      image: 'https://hustlegotreal.com/wp-content/uploads/2022/08/auto-ordering.png',
      slug: 'auto-ordering',
      link: Links.Services.AutoOrdering,
      currency: '',
      price: ''
    },
    {
      id: 6,
      title: 'VeRo Checker',
      shortDescription: 'We help Ebay sellers to identify brands participating in the VeRo Program, based on reports from the community.',
      paragraphs: [
        'We help Ebay sellers to identify brands participating in the VeRo Program, based on reports from the community.',
        'Is checking your own VeRo list time consuming every time you list an item? Are you still using excel files to keep track of new brands added to the VeRo list?',
        'Check for brands participating in the Ebay VeRo Program and help the community reporting any new addition from your own experience.'
      ],
      bulletPoints: [
        'Avoid eBay bans',
        "Find out the brands that don't allow reselling",
        'Protect your online business from legal disputes',
        'Ensure compliance with eBay policies'
      ],
      image:
        'https://lh3.googleusercontent.com/Zgk-AtBUU6Oh-UDi4up49vsdfCN87NdAMOdWBi0jmB6E6DWnm6P0aaabmekWGSv_YskB2UxbiLCbEqiRnd5_MG2rMQ=w128-h128-e365-rj-sc0x00ffffff',
      link: Links.Services.VeRoChecker,
      slug: 'vero-checker'
    },
    {
      id: 7,
      title: 'Listing Service',
      shortDescription: 'If you don’t know what products to list, we will choose the best winning products for your store.',
      paragraphs: [
        'If you don’t know what products to list, we will choose the best winning products for your store. We will list them optimising the titles with the best performing keywords so you don’t have to worry about listing at all.'
      ],
      bulletPoints: [
        'Purchase the listing package that you want us to list',
        'You will receive a confirmation email telling you to set up your preferences',
        'Choose your preferences.',
        'Click on Start Listing',
        'The team will process your order and email you as soon as your listings are published.'
      ],
      image: 'https://hustlegotreal.com/wp-content/uploads/2022/08/Listing-service.png',
      link: Links.Services.ListingService,
      slug: 'listing-service'
    },
    {
      id: 8,
      title: 'AI-Powered Tokens',
      shortDescription:
        'Unlock the power of AI to optimize your product titles and descriptions. We use AI to analyze and select the best keywords for your product.',
      paragraphs: [
        'Are you struggling to come up with the perfect product titles and descriptions for your e-commerce store? Look no further! Our token-based AI optimization service can help you boost your sales by analyzing your products and generating the most effective titles and descriptions for them.',
        'With our cutting-edge AI technology, you can easily improve the visibility and appeal of your products on online platforms. Our algorithm takes into account various factors such as customer behavior and search trends to optimize your product titles and descriptions for maximum impact.',
        "By purchasing our tokens, you'll be able to access our advanced optimization tools and see the results for yourself. Don't waste any more time trying to come up with the perfect product titles and descriptions - let our AI do the work for you and watch your sales soar!"
      ],
      bulletPoints: [
        useTranslation('Publish.Optimize.Explanation.1'),
        useTranslation('Publish.Optimize.Explanation.2', { searchEngineName: GetPlatform(platform).searchEngineName ?? 'Google' }),
        useTranslation('Publish.Optimize.Explanation.3'),
        useTranslation('Publish.Optimize.Explanation.4'),
        useTranslation('Publish.Optimize.Explanation.5'),
        useTranslation('Publish.Optimize.Explanation.6')
      ],
      image: 'https://hustlegotreal.com/wp-content/uploads/2022/08/optimization-tokens.png',
      link: Links.Services.AiTokens,
      slug: 'ai-tokens'
    }
  ];
};
