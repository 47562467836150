import { Col, Row } from 'antd';
import { A } from '../../../small-components/A';

import { EanType } from './ean-field';
import { ProductInChannelData } from './product-in-channel-data';

export interface SelectedProductContentProps {
  product?: ProductInChannelData;
  editProductInChannel: boolean;
  OnEditProductInChannelChange?: (editing: boolean) => void;
}

export const SelectedProductContent = (props: SelectedProductContentProps) => {
  const prd = props.product;

  let message = '';
  switch (prd?.selectedBy) {
    case EanType.EAN:
      message = 'We have found one product with this EAN in Amazon.';
      break;
    default:
      message = '';
  }

  return (
    <Row className="product-selected">
      <Col sm={14} className="image">
        <img src={prd?.mainImage} />
      </Col>

      <Col sm={6} className="fields">
        <div className="title">
          <A to={prd?.url} target="_blank" rel="noreferrer">
            {prd?.title}
          </A>
        </div>
        <div className="price">{prd?.price}</div>
        <div className="textInfo">{message}</div>
      </Col>
    </Row>
  );
};
