
import { ePlatform } from '../../../data/platforms';
import { ListingColumnId } from './columns';

export const ActiveListingsColumns = {
  [ePlatform.eBay]: [
    ListingColumnId.Image,
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title,
    ListingColumnId.VariationOptions,
    ListingColumnId.SellPrice,
    ListingColumnId.CostPrice,
    ListingColumnId.Profit,
    ListingColumnId.Markup,
    ListingColumnId.Stock,
    ListingColumnId.CreatedOn,
    ListingColumnId.CreatedBy,
    ListingColumnId.Notes,
    ListingColumnId.MonitorPrice,
    ListingColumnId.MonitorStock,
    ListingColumnId.MonitorPriceDecrease,
    ListingColumnId.MonitorPriceDecreasePercentage,
    ListingColumnId.IgnoreRules,
    ListingColumnId.UnsoldDays,
    ListingColumnId.OutOfStockDays,
    ListingColumnId.Sales,
    ListingColumnId.Watchers,
    ListingColumnId.EndingOn,
    ListingColumnId.DispatchDays,
    ListingColumnId.Optimized,
    ListingColumnId.OtherChannels
  ],
  [ePlatform.eBayNoApi]: [
    ListingColumnId.Image,
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title,
    ListingColumnId.VariationOptions,
    ListingColumnId.SellPrice,
    ListingColumnId.CostPrice,
    ListingColumnId.Profit,
    ListingColumnId.Markup,
    ListingColumnId.Stock,
    ListingColumnId.CreatedOn,
    ListingColumnId.CreatedBy,
    ListingColumnId.Notes,
    ListingColumnId.MonitorPrice,
    ListingColumnId.MonitorStock,
    ListingColumnId.MonitorPriceDecrease,
    ListingColumnId.MonitorPriceDecreasePercentage,
    ListingColumnId.IgnoreRules,
    ListingColumnId.UnsoldDays,
    ListingColumnId.OutOfStockDays,
    ListingColumnId.Sales,
    ListingColumnId.Views,
    ListingColumnId.Watchers,
    ListingColumnId.EndingOn,
    ListingColumnId.DispatchDays,
    ListingColumnId.Optimized,
    ListingColumnId.OtherChannels
  ],
  [ePlatform.Shopify]: [
    ListingColumnId.Image,
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title,
    ListingColumnId.SellPrice,
    ListingColumnId.CostPrice,
    ListingColumnId.Profit,
    ListingColumnId.Markup,
    ListingColumnId.Stock,
    ListingColumnId.CreatedOn,
    ListingColumnId.CreatedBy,
    ListingColumnId.Notes,
    ListingColumnId.MonitorPrice,
    ListingColumnId.MonitorStock,
    ListingColumnId.MonitorPriceDecrease,
    ListingColumnId.MonitorPriceDecreasePercentage,
    ListingColumnId.IgnoreRules,
    ListingColumnId.UnsoldDays,
    ListingColumnId.OutOfStockDays,
    ListingColumnId.Optimized,
    ListingColumnId.OtherChannels
  ],
  [ePlatform.WooCommerce]: [
    ListingColumnId.Image,
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title,
    ListingColumnId.SellPrice,
    ListingColumnId.CostPrice,
    ListingColumnId.Profit,
    ListingColumnId.Markup,
    ListingColumnId.Stock,
    ListingColumnId.CreatedOn,
    ListingColumnId.CreatedBy,
    ListingColumnId.Notes,
    ListingColumnId.MonitorPrice,
    ListingColumnId.MonitorStock,
    ListingColumnId.MonitorPriceDecrease,
    ListingColumnId.MonitorPriceDecreasePercentage,
    ListingColumnId.IgnoreRules,
    ListingColumnId.UnsoldDays,
    ListingColumnId.OutOfStockDays,
    ListingColumnId.Optimized,
    ListingColumnId.OtherChannels
  ],
  [ePlatform.Amazon]: [
    ListingColumnId.Image,
    ListingColumnId.AmazonAsin,
    ListingColumnId.AmazonSku,
    ListingColumnId.Source,
    ListingColumnId.Title,
    ListingColumnId.SellPrice,
    ListingColumnId.AmazonLowestPrice,
    ListingColumnId.AmazonBuyBox,
    ListingColumnId.CostPrice,
    ListingColumnId.Profit,
    ListingColumnId.Markup,
    ListingColumnId.Stock,
    ListingColumnId.CreatedOn,
    ListingColumnId.CreatedBy,
    ListingColumnId.Notes,
    ListingColumnId.MonitorPrice,
    ListingColumnId.MonitorStock,
    ListingColumnId.MonitorPriceDecrease,
    ListingColumnId.MonitorPriceDecreasePercentage,
    ListingColumnId.IgnoreRules,
    ListingColumnId.UnsoldDays,
    ListingColumnId.OutOfStockDays,
    ListingColumnId.Optimized,
    ListingColumnId.OtherChannels
  ],
  [ePlatform.Facebook]: [
    ListingColumnId.Image,
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title,
    ListingColumnId.SellPriceNoDecimal,
    ListingColumnId.CostPrice,
    ListingColumnId.Profit,
    ListingColumnId.Markup,
    ListingColumnId.Stock,
    ListingColumnId.CreatedOn,
    ListingColumnId.CreatedBy,
    ListingColumnId.Notes,
    ListingColumnId.MonitorPrice,
    ListingColumnId.MonitorStock,
    ListingColumnId.MonitorPriceDecrease,
    ListingColumnId.MonitorPriceDecreasePercentage,
    ListingColumnId.IgnoreRules,
    //ListingColumnId.UnsoldDays,
    //ListingColumnId.OutOfStockDays,
    ListingColumnId.Optimized,
    ListingColumnId.OtherChannels
  ],
  [ePlatform.TikTok]: [
    ListingColumnId.Image,
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title,
    ListingColumnId.SellPriceNoDecimal,
    ListingColumnId.CostPrice,
    ListingColumnId.Profit,
    ListingColumnId.Markup,
    ListingColumnId.Stock,
    ListingColumnId.CreatedOn,
    ListingColumnId.CreatedBy,
    ListingColumnId.Notes,
    ListingColumnId.MonitorPrice,
    ListingColumnId.MonitorStock,
    ListingColumnId.MonitorPriceDecrease,
    ListingColumnId.MonitorPriceDecreasePercentage,
    ListingColumnId.IgnoreRules,
    //ListingColumnId.UnsoldDays,
    //ListingColumnId.OutOfStockDays,
    ListingColumnId.Optimized,
    ListingColumnId.OtherChannels
  ]
};


export const ActiveListingsColumnsVisibleByDefault = {
  [ePlatform.eBay]: [
    ListingColumnId.Image,
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title,
    ListingColumnId.SellPrice,
    ListingColumnId.CostPrice,
    ListingColumnId.Profit,
    ListingColumnId.Markup,
    ListingColumnId.Stock,
    ListingColumnId.CreatedOn
  ],
  [ePlatform.eBayNoApi]: [
    ListingColumnId.Image,
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title,
    ListingColumnId.SellPrice,
    ListingColumnId.CostPrice,
    ListingColumnId.Profit,
    ListingColumnId.Markup,
    ListingColumnId.Stock,
    ListingColumnId.CreatedOn
  ],
  [ePlatform.Shopify]: [
    ListingColumnId.Image,
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title,
    ListingColumnId.SellPrice,
    ListingColumnId.CostPrice,
    ListingColumnId.Profit,
    ListingColumnId.Markup,
    ListingColumnId.Stock,
    ListingColumnId.CreatedOn
  ],
  [ePlatform.Amazon]: [
    ListingColumnId.Image,
    ListingColumnId.AmazonAsin,
    ListingColumnId.AmazonSku,
    ListingColumnId.Source,
    ListingColumnId.Title,
    ListingColumnId.SellPrice,
    ListingColumnId.CostPrice,
    ListingColumnId.Profit,
    ListingColumnId.Markup,
    ListingColumnId.Stock
  ],
  [ePlatform.Facebook]: [
    ListingColumnId.Image,
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title,
    ListingColumnId.SellPriceNoDecimal,
    ListingColumnId.CostPrice,
    ListingColumnId.Profit,
    ListingColumnId.Markup,
    ListingColumnId.Stock,
    ListingColumnId.CreatedOn
  ],
  [ePlatform.WooCommerce]: [
    ListingColumnId.Image,
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title,
    ListingColumnId.SellPriceNoDecimal,
    ListingColumnId.CostPrice,
    ListingColumnId.Profit,
    ListingColumnId.Markup,
    ListingColumnId.Stock,
    ListingColumnId.CreatedOn
  ],
  [ePlatform.TikTok]: [
    ListingColumnId.Image,
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title,
    ListingColumnId.SellPriceNoDecimal,
    ListingColumnId.CostPrice,
    ListingColumnId.Profit,
    ListingColumnId.Markup,
    ListingColumnId.Stock,
    ListingColumnId.CreatedOn
  ]
};


export const OptimizeColumns = {
  [ePlatform.eBay]: [
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title
  ],
  [ePlatform.eBayNoApi]: [
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title
  ],
  [ePlatform.Shopify]: [
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title
  ],
  [ePlatform.WooCommerce]: [
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title
  ],
  [ePlatform.Amazon]: [
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title
  ],
  [ePlatform.Facebook]: [
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title
  ],
  [ePlatform.TikTok]: [
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title
  ]
};

export const OptimizeColumnsVisibleByDefault = {
  [ePlatform.eBay]: [
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title
  ],
  [ePlatform.eBayNoApi]: [
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title
  ],
  [ePlatform.Shopify]: [
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title
  ],
  [ePlatform.Amazon]: [
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title
  ],
  [ePlatform.Facebook]: [
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title
  ],
  [ePlatform.WooCommerce]: [
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title
  ],
  [ePlatform.TikTok]: [
    ListingColumnId.ChannelItem,
    ListingColumnId.Source,
    ListingColumnId.Title
  ]
};