import { ReloadOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { T } from '../../../utils/transShim';
import { SecondaryBtn } from '../../ActionBtns';
import { RefreshData } from '../types/refresh-data';

interface Props {
  refresh: RefreshData
}
export const RefreshBtn = (props: Props) => {
  const { refresh } = props;

  const [time, SetTime] = useState<Date>(new Date());

  const timerToRefresh = (refresh?.minTime ?? 0) * 1000 - ((new Date()).getTime() - refresh.lastRefreshTime.getTime());
  const disableRefresh = timerToRefresh > 0;

  useEffect(() => {
    if (!!refresh && (refresh.minTime ?? 0) > 0) {
      setTimeout(() => SetTime(new Date()), 500);
    }
  }, [refresh?.show, refresh?.minTime, time]);

  const TooltipMsg = () => {
    if (refresh.loading)
      return <T k='Table.Loading'/>;
    return disableRefresh ? <T k='Table.RefreshIn' values={{ s: Math.floor(timerToRefresh / 1000) }} /> : <T k='Table.Refresh' />;
  };

  return <Tooltip title={TooltipMsg()}>
    <>{/*Tooltip won't work with custom directly as children, in that cases it needs something like this*/}
      <SecondaryBtn className="refresh-btn" onClick={refresh?.onRefresh} disabled={(refresh.loading ?? false) || disableRefresh}>
        <ReloadOutlined />
      </SecondaryBtn>
    </>
  </Tooltip>;
};