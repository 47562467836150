import { ColumnType } from 'antd/es/table';

export type ColumnId = number;

export type SmartSearchOptions<T> = {
  ignore?: boolean;
  customFilter?: (fieldValue: unknown, searchTerm: string, row: T) => boolean;
}
export enum FieldType {
  String, Number, Date, Boolean, OtherChannels, MultipleSelect
}
export enum Size {
  Simple, Double
}

export type AdvancedFilterValueArrayContent = string | number | Date | boolean | undefined | null;
export type AdvancedFilterValueType = AdvancedFilterValueArrayContent[] | undefined | null;
export type AdvancedFilterSelectorValueType = [string , number];

export type AdvancedSearchOptions<T> = {
  ignore?: boolean;
  type?: FieldType;
  size?: Size;
  customFilter?: (fieldValue: unknown, filter: AdvancedFilterValueType, row: T) => boolean;
  values?: AdvancedFilterSelectorValueType[];
}

export interface ColumnData<T> extends ColumnType<T> {
  id: ColumnId;
  smartSearch?: SmartSearchOptions<T>;
  advancedSearch?: AdvancedSearchOptions<T>;
  expandable?: {
    expandable: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    expandableCondition?: (value: any, row: T) => boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    showExpandable?: (value: any, row: T) => boolean,
    align?: 'left' | 'right'
  }
;
}
