import { createRef, useState, useEffect, useMemo } from 'react';
import { Card, Carousel, Space, Spin } from 'antd';
import { CarouselRef } from 'antd/es/carousel';
import { PrimaryBtn } from '../../small-components/ActionBtns';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { getServices } from 'src/redux/subscriptions/subsThunk';
import { Product } from '../../redux/subscriptions/subsSlice';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import '../../sass/subscriptions/subscriptions.scss';
//import { Checkout } from './Checkout';

import { BillingPeriod } from './models/types';
import { eCurrency } from '../../types/eCurrency';
import { A } from '../../small-components/A';

export const OurServices = () => {
  const [slides, setSlides] = useState<number>(3);
  const [activeCurrency, setActiveCurrency] = useState<eCurrency>(eCurrency.EUR);
  const [currency, setCurrency] = useState('\u20AC');
  const sliderRef = createRef<CarouselRef>();
  const dispatch = useAppDispatch();
  const { services, loading } = useAppSelector((state) => state.services);

  useEffect(() => {
    dispatch(getServices());
  }, [getServices]);
  const handleNext = () => sliderRef?.current?.next();
  const handlePrev = () => sliderRef?.current?.prev();
  const tabletScreen = window.matchMedia('(max-width: 1030px)');
  const mobileScreen = window.matchMedia('(max-width: 750px)');

  const getBillingPeriodDiscount = (billingPeriodId: number) => {
    switch (billingPeriodId) {
      case BillingPeriod.Monthly:
        return '';
      case BillingPeriod.Biannually:
        return '20% off';
      case BillingPeriod.Yearly:
        return '40% off';
    }
  };

  const getBillingPeriodText = (billingPeriodId: number) => {
    switch (billingPeriodId) {
      case BillingPeriod.Monthly:
        return '';
      case BillingPeriod.Biannually:
        return '(6 months)';
      case BillingPeriod.Yearly:
        return '(1 year)';
    }
  };

  const getBillingPeriodMonths = (billingPeriodId: number) => {
    switch (billingPeriodId) {
      case BillingPeriod.Monthly:
        return 1;
      case BillingPeriod.Biannually:
        return 6;
      case BillingPeriod.Yearly:
        return 12;
      default:
        return 1;
    }
  };

  const HandleChangeCurrency = (currency: eCurrency) => {
    setActiveCurrency(currency);
    if (currency === eCurrency.EUR) {
      setCurrency('\u20AC');
    } else if (currency === eCurrency.USD) {
      setCurrency('\u0024');
    } else if (currency === eCurrency.GBP) {
      setCurrency('\u00A3');
    }
  };

  const parentToChild = (value: number, billing: number, type: number): void => {
    localStorage.setItem('productId', value.toString());
    localStorage.setItem('billing', billing.toString());
    localStorage.setItem('currencyId', activeCurrency.toString());
    localStorage.setItem('upgradingSubscription', 'false');
    localStorage.setItem('type', type.toString());
  };

  const renderSlides = useMemo(() => {
    if (tabletScreen.matches) {
      setSlides(2);
      slides;
    }
    if (mobileScreen.matches) {
      setSlides(1);
      slides;
    }
    return slides;
  }, [slides]);

  return (
    <div className="subscriptions-container">
      {loading ? (
        <Spin />
      ) : (
        <div className="carousel-container">
          <div className="currencies-container">
            <PrimaryBtn id={eCurrency.EUR.toString()} onClick={_ => HandleChangeCurrency(eCurrency.EUR)} className={activeCurrency === eCurrency.EUR ? 'active-currency' : ''}>
                EUR
            </PrimaryBtn>
            <PrimaryBtn id={eCurrency.USD.toString()} onClick={_ => HandleChangeCurrency(eCurrency.USD)} className={activeCurrency === eCurrency.USD ? 'active-currency' : ''}>
                USD
            </PrimaryBtn>
            <PrimaryBtn id={eCurrency.GBP.toString()} onClick={_ => HandleChangeCurrency(eCurrency.GBP)} className={activeCurrency === eCurrency.GBP ? 'active-currency' : ''}>
                GBP
            </PrimaryBtn>
          </div>
          <Carousel slidesToShow={renderSlides} className="carousel" dots={false} ref={sliderRef}>
            {services?.map((p: Product) => (
              <Card key={p.id} className="subscription">
                <p className="listings-count">
                  <strong>{p.name}</strong>
                </p>

                {p.prices.map((prc) => {
                  if (prc.currencyId === activeCurrency && prc.platformId === 2 && prc.billingPeriodId <= 2) {
                    const months = getBillingPeriodMonths(prc.billingPeriodId);
                    return <div className="container-sub" key={prc.id}>
                      <A to="/checkout" onClick={() => parentToChild(p.id, 0, p.type)} key={p.id}>
                        <div className="rate-details top-most">
                          <span className="euro">{currency}</span>
                          <h1 className="monthly-rate">
                            {(prc.price / months).toFixed(2)}
                          </h1>
                          <h3 className="frequency">/mo</h3>
                        </div>
                        <p className="forty-off">
                          {getBillingPeriodDiscount(prc.billingPeriodId)}
                        </p>
                        <h4 className="duration-services">{getBillingPeriodText(prc.billingPeriodId)}</h4>
                      </A>
                    </div>;
                  }
                  else if (prc.currencyId === activeCurrency && prc.platformId === 2 && prc.billingPeriodId > 2) {
                    return <div className="container-sub" key={prc.id}>
                      <A to="/checkout" onClick={() => parentToChild(p.id, 0, p.type)} key={p.id}>
                        <div className="rate-details top-most">
                          <span className="euro">{currency}</span>
                          <h1 className="monthly-rate">
                            {prc.price}
                          </h1>
                        </div>
                      </A>
                    </div>;
                  }
                })}
              </Card>
            ))}
          </Carousel>
          <Space className="control-btns-container">
            <LeftOutlined onClick={handlePrev} style={{ fontSize: '19px' }} />
            <RightOutlined onClick={handleNext} style={{ fontSize: '19px' }} />
          </Space>
        </div>
      )}
    </div>
  );
};