import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useAppDispatch } from '../../custom-hooks/reduxCustomHooks';
import { Links } from '../../links';
import { getChannels } from '../../redux/channels/channelsThunk';
import { UnImpersonateUser } from '../../redux/user/userThunk';
import '../../sass/top-bar.scss';
import { SuperLogout } from '../../utils/logout';
import { ReactUtils } from '../../utils/react-utils';
import { toastAlert } from '../../utils/toastAlert';

export const UnImpersonate = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isImpersonated = ReactUtils.stringToBoolean(localStorage.getItem('isImpersonated'));

  const impersonateRequest = async () => {
    const rs = await dispatch(UnImpersonateUser());
    if (rs.payload.token) {
      await SuperLogout(false);
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('isImpersonated', 'false');
      localStorage.setItem('Authorization', rs.payload.token);
      history.push(Links.Dashboard);
      await dispatch(getChannels());

    } else {
      await SuperLogout(true);
    }
  };

  useEffect(() => {
    if (isImpersonated) {
      impersonateRequest();
    }
    else {
      toastAlert('you are not impersonated', 'error');
    }
  }, []);

  return (
    <>
      <h3>Please wait while UnImpersonating the user...</h3>
    </>
  );

};
