import { createAsyncThunk } from '@reduxjs/toolkit';
import { ComListing } from '../../components/extension/new-listing-form/com-listing';
import { client } from '../client';
import { ActiveListing, PendingListing, TerminatedListing } from './listings-slice';
import unmap, { compArray } from './unmap';

export type ListingImageUrlList = { id: number, url: string }[];
export type ProductSourceChannelOauthId = {
  channelOauthId: number;
  productSourecIds: number[];
};

export type SaveProductChangeNumber = {
  value?: number;
}
export type SaveProductChangeBoolean = {
  value?: boolean;
}
export type SaveProductChangesRequest = {
  channelListingId: number;
  title?: string;
  notes?: string;
  quantity?: number;
  price?: number;
  markup?: SaveProductChangeNumber;
  minQuantity?: SaveProductChangeNumber;
  dispatchTimeDays?: SaveProductChangeNumber;
  monitorPrice?: SaveProductChangeBoolean;
  monitorStock?: SaveProductChangeBoolean;
  monitorPriceDecrease?: SaveProductChangeBoolean;
  priceDecreasePercentage?: SaveProductChangeNumber;
  ignoreRules?: SaveProductChangeBoolean;
  primeOnly?: SaveProductChangeBoolean;
  sourceUrl?: string;
  shippingPolicy?: SaveProductChangeNumber;
  returnPolicy?: SaveProductChangeNumber;
  paymentPolicy?: SaveProductChangeNumber;
}
export type TerminateListingRequest = {
  id: number;
}
export type ForceRefreshRequest = {
  ids: number[]
}
export type SaveMultipleProductChangesRequest = {
  listingIds: number[];
  dataByListing?: {
    [id: number]: {
      markup?: SaveProductChangeNumber
      price?: SaveProductChangeNumber
    }
  };
  //markup?: SaveProductChangeNumber;
  //increaseMarkup?: SaveProductChangeNumber;
  //price?: SaveProductChangeNumber;
  quantity?: SaveProductChangeNumber;
  profit?: SaveProductChangeNumber;
  monitorPrice?: SaveProductChangeBoolean;
  monitorStock?: SaveProductChangeBoolean;
  monitorPriceDecrease?: SaveProductChangeBoolean;
  priceDecreasePercentage?: SaveProductChangeNumber;
  ignoreRules?: SaveProductChangeBoolean;
  minQuantity?: SaveProductChangeNumber;
  primeOnly?: SaveProductChangeBoolean;
  shippingPolicy?: SaveProductChangeNumber;
  returnPolicy?: SaveProductChangeNumber;
  paymentPolicy?: SaveProductChangeNumber;
  dispatchTimeDays?: SaveProductChangeNumber;
}
export enum eChannelListingHistoryAction {
  Unknown = 0,
  Created = 1,
  StatusChanged = 10,
  ManualTerminated = 200,
  ManualPrice = 201,
  ManualNotTerminated = 202,
  ManualQuantity = 203,
  ManualTitle = 204,
  ManualStockMonitoring = 205,
  ManualPriceMonitoring = 206,
  ManualPriceDecreaseMonitoring = 207,
  ManualPriceDecreaseMonitoringPercentage = 208,
  ManualOverwriteRules = 209,
  ManualRelist = 210,
  ManualSourceUrl = 211,
  ManualListNow = 212,
  BulkManualMarkup = 300,
  BulkManualQuantity = 301,
  BulkManualStockMonitoring = 302,
  BulkManualPriceMonitoring = 303,
  BulkManualPriceDecreaseMonitoring = 304,
  BulkManualPriceDecreaseMonitoringPercentage = 305,
  BulkManualOverwriteRules = 306,
  MonitorPrice = 400,
  MonitorQuantityDueMinQuantiy = 401,
  MonitorQuantity = 402,
  MonitorTitle = 403,
  MonitorTerminated = 404,
  MonitorNotTerminated = 405,
  MonitorListed = 406,
  MonitorBulkListed = 407,
  MonitorInStock = 408,
  MonitorOutOfStock = 409,
  MonitorOutOfStockSimulated = 410,
  MonitorPriceDueOutOfStockPriceIncrease = 411,
  MonitorChangedShipping = 412,
  RelistedDiscoveredBySync = 413,
  MonitorUpdateFailed = 414,
  NotificationTerminated = 500,
  NotificationNotTerminated = 501
}
export type ChanenlListingHistory = {
  id: number;
  channelListing: number;
  action: eChannelListingHistoryAction;
  date: string | Date;
  oldValue?: string;
  newValue?: string;
  extra?: string;
}
const GetWeeklyNumber = () => {
  const currentDate = new Date();
  // Obt�n el n�mero de la semana del a�o
  const weekNumber = Math.floor((currentDate.getTime() - new Date(currentDate.getFullYear(), 0, 1).getTime()) / (7 * 24 * 60 * 60 * 1000));
  // Devuelve el n�mero de la semana
  return weekNumber;
};
const LoadActiveImagesFromCache = () => {
  const imgS = localStorage.getItem('listings.active.images' + GetWeeklyNumber());
  return (imgS ? JSON.parse(imgS) : []) as ListingImageUrlList;
};
export const SaveActiveListingsImagesInCache = (data: ListingImageUrlList) => {
  localStorage.removeItem('listings.active.images' + (GetWeeklyNumber() - 1));
  localStorage.setItem('listings.active.images' + GetWeeklyNumber(), JSON.stringify(data));
};


export const GetActiveListings = createAsyncThunk(
  'SearchProduct/GetActiveListings',
  async (_, { rejectWithValue } /* destructured thunkAPI's prop */) => {
    try {
      const res = await client.get('/SearchProduct/GetActiveListings');
      const iter = unmap(res.data.response_data?.data as compArray);

      const rv: ActiveListing[] = [];
      let status = iter.next();
      while (!status.done) {
        const itm = status.value as ActiveListing;
        rv.push(itm);
        status = iter.next();
      }

      return {
        listings: rv,
        images: LoadActiveImagesFromCache(),
        others: res.data.response_data?.otherPsId as ProductSourceChannelOauthId[],
        channelOAuthId: (res.data.response_data?.channelOAuthId ?? 0) as number
      };
    } catch (error) {
      return rejectWithValue('Sorry! Something went wrong!!! ):');
    }
  }
);

export const GetPendingListings = createAsyncThunk(
  'listings/getPendingListing',
  async (_, { rejectWithValue }) => {
    try {
      const res = await client.get('/SearchProduct/getPendingListings');
      const data = res?.data?.response_data;
      return data as {
        listings: PendingListing[],
        channelOAuthId: number
      };
    } catch (error) {
      return rejectWithValue('Sorry! Something went wrong');
    }
  });

export const GetTerminatedListings = createAsyncThunk(
  'listings/getTerminatedListings',
  async (_, { rejectWithValue }) => {
    try {
      const res = await client.get('/SearchProduct/getTerminatedListings');
      const data = res?.data?.response_data;
      return data as {
        listings: TerminatedListing[],
        channelOAuthId: number
      };
    } catch (error) {
      return rejectWithValue('Sorry! Something went wrong');
    }
  }
);

export const GetActiveListingsImages = createAsyncThunk('SearchProduct/getListingsImages', async (ids: number[], thunkAPI) => {
  try {
    const res = await client.post('SearchProduct/getListingsImages', ids);
    return res.data.response_data as {channelListingMap: {[id: number]: string[]}};
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});

type ActiveListingSearchItem = {
  userProductSourceChannelId: number;
  channelListingId: number;
  createdOn: string;
  status: number;
  channelItem: string;
  title: string;
  channelQuantity: number;
  channelPrice: number;
  sourcePrice: number;
  sourceId: number;
  productMarkup?: number;
  productMinQuantity?: number
  productPriceDecreasePercentage?: number;
  productOverrideFlags?: number;
  productNotes?: string;
  productDispatchTimeDays?: number;
  path: string;
  createdById?: number;
  createdByName: string;
  updatedOn: string;
  views: number;
  watches: number;
  lastTimeSold: string;
  quantitySold: number;
  sourceQuantity: number;
  lastTimeInStock: string;
  endsOn: string;
  asin: string;
  isLowestPrice?: boolean;
  lowestPrice?: boolean;
  buyBoxPrice?: boolean;
  productSourceId: number;
  origin: number;
  monitorStock?: boolean;
  monitorPrice?: boolean;
  monitorPriceDecrease?: boolean;
  ignoreRules?: boolean;
  primeOnly?: boolean;
}

export const SaveListingChanges = createAsyncThunk('SearchProduct/SaveProductChanges', async (d: SaveProductChangesRequest, thunkAPI) => {
  try {
    const res = await client.post('SearchProduct/SaveProductChanges', d);
    return res.data.response_data as { success: boolean, listing: ActiveListingSearchItem };
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const SaveMultipleListingChanges = createAsyncThunk('SearchProduct/SaveMultipleProductChanges', async (d: SaveMultipleProductChangesRequest, thunkAPI) => {
  try {
    const res = await client.post('SearchProduct/SaveMultipleProductChanges', d);
    return res.data.response_data.success as boolean;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const TerminateListing = createAsyncThunk('SearchProduct/TerminateListing', async (d: TerminateListingRequest, thunkAPI) => {
  try {
    const res = await client.post('SearchProduct/TerminateListing', d);
    return res.data.response_data.success as boolean;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const ForceRefresh = createAsyncThunk('SearchProduct/ForceRefresh', async (d: ForceRefreshRequest, thunkAPI) => {
  try {
    const res = await client.post('SearchProduct/ForceRefresh', d);
    return res.data.response_data.success as boolean;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const Optimize = createAsyncThunk('SearchProduct/Optimize', async (d: {listingId?: number, productSourceId: number, optimizeTitle: boolean, optimizeDescription: boolean}, thunkAPI) => {
  try {
    const res = await client.post('SearchProduct/Optimize', d);
    return res.data.response_data as {
      success: boolean; title?: string, paragraphs?: string[], properties?: string[], tableValues?: {value:string, key:string}[] };
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const GetStockChanges = createAsyncThunk('SearchProduct/StockChanges', async (listingId: number, thunkAPI) => {
  try {
    const res = await client.get('SearchProduct/StockChanges?id=' + listingId);
    res.data.response_data?.history?.sort((a: ChanenlListingHistory, b: ChanenlListingHistory) => b.id - a.id);//order descendant
    return res.data.response_data as { success: boolean; history: ChanenlListingHistory[] };
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const GetPriceChanges = createAsyncThunk('SearchProduct/PriceChanges', async (listingId: number, thunkAPI) => {
  try {
    const res = await client.get('SearchProduct/PriceChanges?id=' + listingId);
    res.data.response_data?.history?.sort((a: ChanenlListingHistory, b: ChanenlListingHistory) => b.id - a.id);//order descendant
    return res.data.response_data as { success: boolean; history: ChanenlListingHistory[] };
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const ListPendingNow = createAsyncThunk('SearchProduct/ListNow', async (listingId: number, thunkAPI) => {
  try {
    const res = await client.post('SearchProduct/ListNow', { id: listingId });
    return res.data.response_data?.success as boolean | undefined ?? false;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const RemovePendingListing = createAsyncThunk('SearchProduct/RemoveListing', async (listingId: number, thunkAPI) => {
  try {
    const res = await client.post('SearchProduct/RemoveListing', { id: listingId });
    return res.data.response_data?.success as boolean | undefined ?? false;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const PauseToReview = createAsyncThunk('SearchProduct/PauseToReview', async (listingId: number, thunkAPI) => {
  try {
    const res = await client.post('SearchProduct/PauseToReview', { id: listingId });
    return res.data.response_data?.success as boolean | undefined ?? false;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const DefaultSettingsMultiple = createAsyncThunk('SearchProduct/DefaultSettingsMultiple', async (listingIds: number[], thunkAPI) => {
  try {
    const res = await client.post('SearchProduct/DefaultSettingsMultiple', { ids: listingIds });
    return res.data.response_data?.success as boolean | undefined ?? false;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const Relist = createAsyncThunk('SearchProduct/Relist', async (id: number, thunkAPI) => {
  try {
    const res = await client.post('SearchProduct/Relist', { id: id });
    return res.data.response_data as { success: boolean; error: string };
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const LoadEditPendingListing = createAsyncThunk('ChannelListing/EditPending', async (id: number, thunkAPI) => {
  try {
    const res = await client.get('ChannelListing/EditPending?id=' + id);
    return res.data.response_data as ComListing;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});
export const DidList = createAsyncThunk('Listing/DidList', async (_, thunkAPI) => {
  try {
    const res = await client.get('Listing/DidList');
    return res.data.response_data.didList as boolean;
  } catch (error) {
    return thunkAPI.rejectWithValue('Sorry! Something went wrong');
  }
});