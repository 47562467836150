import { MessageFilled } from '@ant-design/icons';
import { ChatContainerActionsRef } from '../../small-components/chat-container';

interface Props {
  chatEnabled: boolean;
  chatActions: ChatContainerActionsRef;
}

export const HelpChatButton = (props: Props) => {
  const { chatEnabled, chatActions } = props;

  if (!chatEnabled) return <></>;

  const HandleClick = () => {
    chatActions.maximize?.();
  };

  return (
    <>
      <div className="help-chat-button">
        <MessageFilled onClick={HandleClick} />
      </div>
    </>
  );
};
