import { Button, Col, Modal, Progress, Row } from 'antd';
import '../sass/listing-options.scss';
import { GetPlatform } from '../data/platforms';
import { eCountry } from '../data/countries';
import { eChannelListingStatus } from '../redux/listings/listings-slice';
import { CatalogProduct, CatalogStatusData } from '../redux/catalog/catalogSlice';
import { eBulkListingStatus } from '../redux/listings/auto-list-slice';
import { ReactUtils } from '../utils/react-utils';
import { StatusPendingModal } from '../components/listings/Listings/components/pending/status-pending-modal';
import { useState } from 'react';
import '../sass/catalog.scss';
import { T } from '../utils/transShim';
import { eCurrency } from '../types/eCurrency';
import { A } from './A';


interface props {
  data: CatalogProduct;
  status: CatalogStatusData | undefined;
  currency: eCurrency;
}

export interface ErrorKind {
  severity: ErrorKindSeverity;
  message?: string;
  extraData?: string;
}

export enum KindType {
  basic = 0,
  error = 1,
  progress = 2,
  done = 3,
  pendingError = 5,
  pendingProgress = 6,
  pendingReview = 7
}

export interface Kind {
  type: KindType;
  errorKind?: ErrorKind;
}

export enum ErrorKindSeverity {
  warning = 0,
  error = 1
}

export const CatalogStatus = (props: props) => {
  const { data, status, currency } = props;

  const channel = ReactUtils.GetSelectedChannel();
  const platform = GetPlatform(channel?.channelId);

  const [showStatusModal, SetShowStatusModal] = useState<boolean>(false);

  const ChannelInfoToUrl = (channelSite: eCountry, channelIdentifier: string, itemId: string) => {
    if (platform && platform.itemUrl) {
      let baseUrl: string;
      if (platform.itemUrl instanceof Object) {
        baseUrl = platform.itemUrl[channelSite];
      } else {
        baseUrl = platform.itemUrl;
      }
      return baseUrl.replace('{id}', itemId).replace('{shopName}', channelIdentifier);
    } else {
      return undefined;
    }
  };


  const getCurrency = () => {
    const currency = ReactUtils.GetCurrencyByCountry(channel?.isoCountry);
    switch (currency) {
      case eCurrency.AUD:
        return 'AU$';
      case eCurrency.EUR:
        return '\u20AC';
      case eCurrency.USD:
        return '\u0024';
      case eCurrency.GBP:
      default:
        return '\u00A3';
    }
  };

  const Kind = (): Kind => {
    switch (status?.status) {
      default:
        return { type: KindType.basic };
      case eBulkListingStatus.DONE:
        if (!status?.listingStatus?.status) {
          return { type: KindType.pendingProgress };
        }
        if ((status.listingStatus.status & (eChannelListingStatus.PermanentFailure | eChannelListingStatus.TemporaryFailure)) > 0) {
          return {
            type: KindType.pendingError,
            errorKind: {
              severity: ErrorKindSeverity.error,
              extraData: status.listingStatus.sourceInfo,
              message: status.listingStatus.errorMessage
            }
          };
        }
        if ((status.listingStatus.status & eChannelListingStatus.PendingToReview) > 0) {
          return { type: KindType.pendingReview };
        }
        if ((status.listingStatus.status & (eChannelListingStatus.PreparedForFirstListing | eChannelListingStatus.CreatingListing)) > 0) {
          return { type: KindType.pendingProgress };
        }
        return { type: KindType.done };
      case eBulkListingStatus.UNKNOWN:
      case eBulkListingStatus.ERROR:
        return {
          type: KindType.error,
          errorKind: {
            severity: ErrorKindSeverity.error,
            message: status.errorMessage
          }
        };
      case eBulkListingStatus.PROCESSING:
      case eBulkListingStatus.VALIDATING:
      case eBulkListingStatus.TEMPORAL_ERROR:
        return { type: KindType.progress };
    }
  };

  const RenderProgress = () => {
    let percent = 0;
    let msg: React.ReactNode = '';
    const storeName = platform?.storeName ?? '';
    switch (Kind().type) {
      case KindType.basic:
        percent = 100;
        break;
      case KindType.error:
      case KindType.pendingError:
        percent = 100;
        msg = <T k='Error'/>;
        break;
      case KindType.pendingProgress:
        percent = 66;
        if (platform.isNoApi) {
          msg = <T k='Catalog.WaitingExtension' values={{storeName}} />;
        } else {
          if (storeName) msg = <T k="Catalog.PublishingOn" values={{ storeName }} />;
          else msg = <T k="Catalog.Publishing"/>;
        }
        break;
      case KindType.progress:
        percent = 33;
        msg = <T k='Catalog.ObtainingDetails'/>;
        break;
    }
    return (
      <Row className="cardProgress">
        <Col sm={24}>
          <Progress className="progress-bar-catalog" percent={percent} status="active" />
          <span className="status-msg">{msg}</span>
        </Col>
      </Row>
    );
  };

  const RenderBeingSend = () => {
    return (
      <Row className="cardProgress">
        <Col sm={24}>
          <Progress className="progress-bar-catalog" percent={0} status="active" />
          <span className="status-msg"><T k='Catalog.Sending'/></span>
        </Col>
      </Row>
    );
  };

  const RenderSent = () => {
    return (
      <Row className="cardProgress">
        <Col sm={24}>
          <Progress className="progress-bar-catalog" percent={10} status="active" />
          <span className="status-msg"><T k='Catalog.Starting' /></span>
        </Col>
      </Row>
    );
  };

  const RenderBasic = () => {
    if (data.sent) {
      return RenderSent();
    }

    return (
      <Row className="transaction-details">
        <Col span={8} className="details">
          <p className="transaction-type"><T k='Catalog.Sell'/></p>
          <p className="transaction-amount sell">
            <span>{getCurrency()}</span>
            {data.channelPrice}
          </p>
        </Col>
        <Col span={8} className="details">
          <p className="transaction-type"><T k='Catalog.Cost' /></p>
          <p className="transaction-amount cost">
            <span>{getCurrency()}</span>
            {data.sourcePrice}
          </p>
        </Col>
        <Col span={8} className="details">
          <p className="transaction-type"><T k='Catalog.Profit' /></p>
          <p className="transaction-amount profit">
            {ReactUtils.DrawPrice(data.profit, currency)}
          </p>
        </Col>
      </Row>
    );
  };

  const RenderDone = () => {
    if (!data) {
      return <p className="cardMsg">Done</p>;
    }

    return (
      <p className="cardMsg">
        <A
          to={ChannelInfoToUrl(
            channel?.isoCountry ?? eCountry.UK,
            channel?.channelIdentifier ?? '',
            status?.listingStatus?.asin ?? status?.listingStatus?.channelItem ?? ''
          )}
          target="_blank"
          rel="noreferrer"
        >
          View in your store
        </A>
      </p>
    );
  };

  //const renderSending = () => {
  //  return (
  //    <div className="lds-ripple">
  //      <div></div>
  //      Sending your Listing
  //      <div></div>
  //    </div>
  //  );
  //};

  //const OnShowError = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  //  e.preventDefault();
  //  e.stopPropagation();
  //  setShowError(true);
  //};

  const RenderPendingPending = () => {
    return (
      <>
        <p className="cardMsg">
          <Button type="primary" onClick={() => SetShowStatusModal(true)}>
            {status?.errorMessage ?? 'Error'}
          </Button>
          <br />
        </p>
        <StatusPendingModal
          channel={channel}
          visible={showStatusModal}
          onClose={() => SetShowStatusModal(false)}
          errorMessage={status?.listingStatus?.errorMessage}
          errorSourceInfo={status?.listingStatus?.sourceInfo}
          channelListingId={status?.channelListingId ?? 0}
        />
      </>
    );
  };
  const RenderPendingReview = () => {
    return (
      <>
        <p className="cardMsg">
          <Button type="primary" onClick={() => SetShowStatusModal(true)}>
            <T k='Catalog.PendingReview'/>
          </Button>
          <br />
        </p>
        <StatusPendingModal
          key={status?.channelListingId ?? 0}
          channel={channel}
          visible={showStatusModal}
          onClose={() => SetShowStatusModal(false)}
          errorMessage={status?.listingStatus?.errorMessage}
          errorSourceInfo={status?.listingStatus?.sourceInfo}
          channelListingId={status?.channelListingId ?? 0}
          status={status?.listingStatus?.status}
        />
      </>
    );
  };

  const renderUnrecoverableError = (dKind: ErrorKind | undefined) => {
    return (
      <>
        <p className="cardMsg">
          <span className="errorMsg" title={dKind?.message}>
            <Button type="primary" onClick={() => SetShowStatusModal(true)}>
              Not published. Click for details.
            </Button>
          </span>
        </p>
        <Modal open={showStatusModal} onCancel={() => SetShowStatusModal(false)} title="Unrecoverable error" footer={null}>
          {dKind?.message}
        </Modal>
      </>
    );
  };

  const k = Kind();

  if (data.beingSend) {
    return RenderBeingSend();
  }

  return (
    <div>
      {(() => {
        switch (k.type) {
          case KindType.done:
            return RenderDone();
          case KindType.pendingReview:
            return RenderPendingReview();
          case KindType.pendingError:
            return RenderPendingPending();
          default:
          case KindType.pendingProgress:
          case KindType.progress:
            return RenderProgress();
          case KindType.basic:
            return RenderBasic();
          case KindType.error:
            return renderUnrecoverableError(k?.errorKind);
        }
      })()}
    </div>
  );
};
