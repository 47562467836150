import { useState, useMemo } from 'react';
import { Select} from 'antd';
import '../../sass/selector.scss';
import { SelectorData, SelectorValue } from './selector';

const { Option, OptGroup } = Select;

interface Props extends Omit<React.ComponentProps<typeof Select>, 'onChange'> {
  children: SelectorData | SelectorData[];
  defaultValue?: SelectorValue[];
  onChange?: (value: SelectorValue[]) => void;
  value?: SelectorValue[];
  showSelectedOptions?: boolean;
  sortable?: boolean;
}

export const MultipleSelector = (props: Props) => {
  const {
    children,
    className,
    defaultValue,
    value,
    onChange,
    showSelectedOptions
  } = props;

  const childrens = (Array.isArray(children) ? children : [children]);
  const [internalValue, SetInternalValue] = useState<SelectorValue[]>(value ?? defaultValue ?? []);
  const selectedValues = value ?? internalValue;

  const selectedChildren = useMemo(() => {
    const Unroll = (cs: SelectorData[]) => {
      const cc: SelectorData[] = [];
      for (const c of cs) {
        if (c.options) {
          for (const ci of Unroll(c.options)) {
            cc.push(ci);
          }
        } else {
          cc.push(c);
        }
      }
      return cc;
    };
    const childrensRealValues = Unroll(childrens);

    return selectedValues.map(x => {
      const g = childrensRealValues.find(c => c.value == x);
      if (g)
        return g;
      return { label: x, value: x } as SelectorData;
    }).filter(x => x != null) as SelectorData[];

  }, [childrens, selectedValues]);

  const OnChange = (val: SelectorData[]) => {
    const values = val.map(x => x.value);
    SetInternalValue(values);
    onChange?.(values);
  };

  const Filter = (x: SelectorData) => {
    if (x.options)
      return x.options.length != 0;

    return showSelectedOptions || !selectedValues.find(y => (y === x.value));
  };
  const FilterOptions = (x: SelectorData) => {
    if (x.options) {
      const newO = x.options.filter(Filter);
      return {
        label: x.label, value: x.value, options: newO
      } as SelectorData;
    }
    return x;
  };

  const RenderOption = (c: SelectorData, key: string) => {
    if (c.options) {
      return (
        <OptGroup key={'g_' + key} label={c.label}>
          {c.options.map((x, ind) => RenderOption(x, key + '_' + ind))}
        </OptGroup>
      );
    } else {
      return (
        <Option key={'o_' + key} value={c.value}>
          {c.label}
        </Option>
      );
    }
  };

  const optionData = childrens.map(FilterOptions).filter(Filter);
  const options = optionData.map((x, i) => RenderOption(x, i.toString()));

  const pProps = { ...props, defaultValue:undefined };
  delete pProps['defaultValue'];
  delete pProps['sortable'];
  return (
    <Select
      {...pProps}
      mode='multiple'
      labelInValue={true}
      className={'selector ' + className}
      onChange={OnChange}
      value={selectedChildren}
    >
      {options}
    </Select>
  );
};