import { Alert, Button, Radio, RadioChangeEvent, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../custom-hooks/reduxCustomHooks';
import { GetPlatform } from '../../../data/platforms';
import { Account, eAccountSettings, SettingKey } from '../../../redux/account-configuration/account-configuration-slice';
import { getAccountConfiguration, saveAccountSetting } from '../../../redux/account-configuration/account-configuration-thunk';
import { ReactUtils } from '../../../utils/react-utils';
import { toastAlert } from '../../../utils/toastAlert';
import { T, TransUtils } from '../../../utils/transShim';
import { AccountSettingSection } from '../configuration/sections';
import { AccountSetting, AccountSettings } from '../configuration/settings';
import { AccountSettingInput } from './account/account-setting-input';
import { SettingDataBag, SettingsValue } from './generic/generic-setting-input';


export interface Props {
  display: boolean;
}

export const Billing = (props: Props) => {
  const { display } = props;

  const dispatch = useAppDispatch();

  const selectedChannel = ReactUtils.GetSelectedChannel();
  const platformInfo = GetPlatform(selectedChannel?.channelId);
  const translationValues = { ...TransUtils.GetLinksValues(), ...TransUtils.GetPlatformValues(platformInfo) };

  const bag: SettingDataBag = {};

  //Load from api------------------------------------------------------------
  const {
    settings,
    loading: settingsLoading,
    savingSettings: savingSettingsState
  } = useAppSelector((state) => state.accountConfiguration);

  const [configuration, SetConfiguration] = useState<SettingKey[]>([]);

  useEffect(() => {
    if (!settings || settingsLoading)
      return;
    SetConfiguration(settings);
  }, [settings?.length, settingsLoading]);

  useEffect(() => {
    dispatch(getAccountConfiguration());
  }, [getAccountConfiguration]);

  const SaveSetting = (key: eAccountSettings, value: SettingsValue | boolean) => {
    const newConf = [...configuration];
    let found: SettingKey | undefined = undefined;
    let i = 0;
    for (i = 0; i < newConf.length; i++) {
      if (newConf[i]?.key == key) {
        found = newConf[i];
        break;
      }
    }
    if (found != null) {
      newConf[i] = {
        ...found,
        value: value?.toString() ?? null
      };
    } else {
      newConf.push({
        key: key,
        value: value?.toString() ?? null
      });
    }
    SetConfiguration(newConf);
  };

  const configMap = new Map(configuration?.map((x) => [x.key, x.value]) ?? []);
  const savingSetting = new Map(savingSettingsState?.map((x) => [x.data.key, x]));
  const iAmBusiness = (() => {
    const x = configMap.get(eAccountSettings.IAmBusiness);
    return x != null ? ReactUtils.stringToBoolean(x) : null;
  })();

  const OnButtonClick = async () => {
    const model: Account = {};
    configuration?.map((x) => {
      switch (x.key) {
        case eAccountSettings.Address1:
          model.address1 = x.value;
          break;
        case eAccountSettings.Address2:
          model.address2 = x.value;
          break;
        case eAccountSettings.BusinessType:
          model.businessType = x.value;
          break;
        case eAccountSettings.City:
          model.city = x.value;
          break;
        case eAccountSettings.Country:
          model.country = x.value;
          break;
        case eAccountSettings.IAmBusiness:
          model.iAmBusiness = x.value != null ? ReactUtils.stringToBoolean(x.value) : null;
          break;
        case eAccountSettings.NeedInformation:
          model.needInformation = ReactUtils.stringToBoolean(x.value);
          break;
        case eAccountSettings.Roi:
          model.roi = ReactUtils.stringToBoolean(x.value);
          break;
        case eAccountSettings.Name:
          model.name = x.value;
          break;
        case eAccountSettings.Postcode:
          model.postcode = x.value;
          break;
        case eAccountSettings.Rn:
          model.rn = x.value;
          break;
        case eAccountSettings.Vat:
          model.vat = x.value;
          break;
      }
    });

    const rp = await dispatch(saveAccountSetting(model));

    if (rp.payload?.success) {
      toastAlert('Account successfully updated', 'success');
    } else {
      toastAlert(rp.payload.error?.[0]?.description, 'error');
    }
    dispatch(getAccountConfiguration());
  };

  const onChangeIAmBusiness = (e: RadioChangeEvent) => {
    const v = e.target.value as number;
    const valRadio = (v == 3) ? null : (v == 1);
    SaveSetting(eAccountSettings.IAmBusiness, valRadio);
  };
  const accountBusinessNotConfigured = configuration != null && configuration.find(x => x.key == eAccountSettings.IAmBusiness)?.value == null;

  const loading = settingsLoading || !settings;

  const settingsA = AccountSettings.filter(
    (x) => x.Section == AccountSettingSection.BillingAddress && (!x.ChannelIds || x.ChannelIds.includes(selectedChannel?.channelId ?? 0))
  );
  const valRadio = iAmBusiness == null ? 3 : (iAmBusiness ? 1 : 2);

  const RenderSetting = (setting: AccountSetting) => {
    return (
      <AccountSettingInput
        key={setting.Fields[0]}
        setting={setting}
        savingSetting={savingSetting}
        currentSettingValues={configMap}
        translationValues={translationValues}
        onSave={SaveSetting}
        dataBag={bag}
        onButtonClick={OnButtonClick}
        allFieldsInfo={AccountSettings}
      />
    );
  };

  if (!display)
    return <></>;

  if (loading)
    return <Spin />;

  return (
    <div>
      {accountBusinessNotConfigured && <><Alert type='error' message={<T k='Account.Setting.Forced' />} /><br /></>}
      <div>
        <Radio.Group value={valRadio} onChange={onChangeIAmBusiness} className="account-type">
          <Radio className="radio" value={1}>
            <h2><T k='Account.Setting.BusinessAccount' /></h2>
          </Radio>
          <p><T k='Account.Setting.Description.BusinessAccount' /></p>
          {iAmBusiness && <div className="subsection">
            {settingsA.map((x) => RenderSetting(x))}
          </div>}
          <p> &nbsp; </p>
          <Radio className="radio" value={2}>
            <h2><T k='Account.Setting.PersonalAccount' /></h2>
          </Radio>
          <p><T k='Account.Setting.Description.PersonalAccount' /></p>
        </Radio.Group>
      </div>
      <div className="save-button-container">
        <Button className="primary-btn save-btn" onClick={OnButtonClick} disabled={valRadio == 3}>
          <T k='Account.Setting.Name.SaveAll' />
        </Button>
      </div>
    </div>
  );
};