import { createSlice } from '@reduxjs/toolkit';
import { GetListingServices } from './listingServicesThunk';

export interface ListingService {
  id: number;
  channelOAuthId?: number;
  includedSources: string;
  excludedSources: string;
  minSourcePrice?: number;
  maxSourcePrice?: number;
  minProfit?: number;
  maxProfit?: number;
  notes: string;
  userSubscriptionPaymentId: number;
  updatedOn?: string;
  startedOn?: string;
  endedOn?: string;
  userNotes: string;
  listings: number;
  purchasedOn?: Date;
}

export interface ListingPreferences {
  label: string;
  id: number;
}

type ListingServiceSliceD = {
  data?: ListingService[];
  loading: boolean;
  error?: string;
}

export type ListingServiceSlice = {
  serviceSlice?: ListingServiceSliceD
}

const initialState: ListingServiceSlice = {
  serviceSlice: {
    loading: false,
    error: ''
  }
};


export const listingServicesSlice = createSlice({
  name: 'dashboard-listing-services',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(GetListingServices.pending, (state) => {
      state.serviceSlice = {
        loading: true,
        error: ''
      };
    });
    builder.addCase(GetListingServices.fulfilled, (state, { payload }) => {
      (payload as ListingService[] | undefined)?.sort((a, b) => b.userSubscriptionPaymentId - a.userSubscriptionPaymentId);
      state.serviceSlice = {
        loading: false,
        data: payload
      };
    });
    builder.addCase(GetListingServices.rejected, (state, { payload }) => {
      state.serviceSlice = {
        loading: false,
        error: String(payload)
      };
    });

  }
});

export const { reducer: listingServicesReducer } = listingServicesSlice;
