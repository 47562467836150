import { Alert, Spin } from 'antd';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { ePlatform } from '../../data/platforms';
import { DidList } from '../../redux/listings/listings-thunk';
import { ReactUtils } from '../../utils/react-utils';
import { NotValidated } from '../topbar/not-validated';
import { DashboardList } from './DashboardList';
import { DashboardNoList } from './DashboardNoList';
import { UnlockBar } from './panels/unlock-bar';

export const Dashboard = () => {
  const dispatch = useAppDispatch();
  const { didList } = useAppSelector((state) => state.listings);
  const { notifications } = useAppSelector((x) => x.notifications);

  const channel = ReactUtils.GetSelectedChannel();

  useEffect(() => {
    if (!didList?.didList)//Once has list one time, we don't need to ask it again
      dispatch(DidList());
  }, []);

  const RenderDashboard = () => {
    if (didList?.didList || channel?.channelId == ePlatform.Amazon)
      return <DashboardList />;

    if (didList?.loading || didList?.didList == null)
      return <Spin />;

    return <DashboardNoList />;
  };


  return <>
    {notifications.notActivated && <Alert type='error' message={<NotValidated userCreated={new Date(notifications.userCreated ?? '')} />} />}
    <UnlockBar />
    {RenderDashboard()}
  </>;

};
