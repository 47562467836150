import { createSlice } from '@reduxjs/toolkit';
import { GenericSavingSetting, GenericSettingKey } from '../../components/account-settings/account/generic/generic-setting-input';
import { getAccountConfiguration, saveAccountSetting } from './account-configuration-thunk';

export interface Account {
  country?: string | null;
 businessType?: string | null;
 name?: string | null;
 address1?: string | null;
 address2?: string | null;
 city?: string | null;
 postcode?: string | null;
 rn?: string | null;
 vat?: string | null;
 roi?: boolean | null;
 needInformation?: boolean | null;
 iAmBusiness?: boolean | null;
}

export enum eAccountSettings {
  None = 0,
  NeedInformation = 1,
  IAmBusiness = 2,
  Country = 3,
  BusinessType = 4,
  Name = 5,
  Address1 = 6,
  Address2 = 7,
  City = 8,
  Postcode = 9,
  Rn = 10,
  Vat = 11,
  Roi = 12
}

export type SettingKey = GenericSettingKey<eAccountSettings>;
export type SavingSetting = GenericSavingSetting<eAccountSettings>;

export interface AccountConfigurationSliceState {
  settings: SettingKey[] | null;
  loading: boolean;
  success: boolean;
  error: string;
  savingSettings: SavingSetting[];
}

const initialState: AccountConfigurationSliceState = {
  settings: null,
  loading: true,
  error: '',
  savingSettings: [],
  success: true
};

export const AccountConfigurationSlice = createSlice({
  name: 'accountConfiguration',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    //GET
    builder.addCase(getAccountConfiguration.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getAccountConfiguration.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.settings = payload;
      state.savingSettings = [];
    });
    builder.addCase(getAccountConfiguration.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = String(payload);
    });

    //SAVE
    builder.addCase(saveAccountSetting.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(saveAccountSetting.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = payload?.success;
    });
    builder.addCase(saveAccountSetting.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = String(payload);
    });
  }
});

export const { reducer: accountConfigurationReducer } = AccountConfigurationSlice;
