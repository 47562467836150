import { DescriptionType } from '../../../data/platforms';
import { Template } from '../../../redux/templates/templatesSlice';
import { ComListing } from './com-listing';

function RemoveTagArea(original: string, beginTAG: string, endTAG: string): string {
  let strN = original;
  let startI = original.indexOf(beginTAG);
  let endI = original.indexOf(endTAG);

  while (startI >= 0 && endI > startI) {
    const part1 = strN.slice(0, startI);
    const part2 = strN.slice(endI + endTAG.length, original.length);

    strN = part1 + part2;

    startI = strN.indexOf(beginTAG);
    endI = strN.indexOf(endTAG);
  }

  return strN;
}
function ReplaceTagArea(
  original: string,
  beginTAG: string,
  endTAG: string,
  contentTAG: string,
  content: string
): string {
  return original
    .replace(new RegExp(beginTAG, 'g'), '')
    .replace(new RegExp(endTAG, 'g'), '')
    .replace(new RegExp(contentTAG, 'g'), content);
}


const RemoveTagsExceptBR = (htmla: string) => {
  let html = htmla.replace('<br>', '||br||');
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  html = tmp.textContent || tmp.innerText;
  return html.replace('||br||', '<br>');
};

function getBaseDescription(data: ComListing | null | undefined, accepted: DescriptionType): string {
  const rq = data?.product;

  const onlyBr = accepted == DescriptionType.OnlyBR || accepted == DescriptionType.Plain;
  const br = accepted == DescriptionType.Plain ? '' : '<br/>';

  const p = onlyBr ? br : '<p>';
  const cP = onlyBr ? br : '</p>';
  const tr = onlyBr ? '' : '<tr>';
  const cTr = onlyBr ? br : '</tr>';
  const td = onlyBr ? '\t' : '<td>';
  const cTd = onlyBr ? '' : '</td>';
  const table = onlyBr ? '' : '<table>';
  const cTable = onlyBr ? '' : '</table>';

  let sb = (rq?.paragraphs?.length ?? 0) == 0 ? '' : p + (rq?.paragraphs?.join(cP + p) ?? '') + cP;

  if (rq?.tableValues && rq.tableValues.length) {
    sb += table + '';
    for (const kvp of rq.tableValues) {
      const key = kvp.key;
      const value = kvp.value;

      if (!key || !value) continue;

      sb += tr + td;
      sb += key;
      sb += cTd + td;
      sb += value;
      sb += cTd + cTr;
      /*
      try {
          switch (key) {
              case "Weight":

                  if (numbersOnly.test(value)) {
                      var numbers = numbersOnly.exec(value)[0];
                      var parts = numbers.split(/\.,/);

                      //rq.Static.Weight = new Weight {
                      //    Major = int.Parse(parts[0]),
                      //    Minor = parts.Length > 1 ? int.Parse(parts[1]) : (int?)null
                      //};
                  }
                  break;
          }
      } catch (e) {

          console.error(e, "Exception in FillFeaturesAndTableValues");
      }*/
    }
    sb += cTable;
  }

  //for (let section of (rq?.sections ?? [])) {
  //  if (section.title.Contains('features')) {
  //    // json["features"] != null
  //    sb += ul;
  //    for (let value of section.details || []) {
  //      sb += li;
  //      sb += value.text;
  //      sb += cLi;
  //    }
  //    sb += cUl;
  //  }
  //}

  if (onlyBr) {
    sb = RemoveTagsExceptBR(sb);
  }

  //if(string.IsNullOrEmpty(rq.Static.Description))
  //rq.Static.Description = rq.Static.Description + sb.ToString();
  return sb;
}

export const GetTemplateHtml = (
  templates: Template[],
  rawData: ComListing | null | undefined,
  templateId: number,
  accepted: DescriptionType
) => {
  let newHtml = '';
  const templateIndex = templates.findIndex((f) => f.id === templateId);

  if (!templates[templateIndex]) {
    console.log('couldnt find template id ix...');
    newHtml = getBaseDescription(rawData, accepted);
  } else {

    const onlyBr = accepted == DescriptionType.OnlyBR || accepted == DescriptionType.Plain;
    const br = accepted == DescriptionType.Plain ? '' : '<br/>';

    const p = onlyBr ? br : '<p>';
    const cP = onlyBr ? br : '</p>';
    const li = onlyBr ? '' : '<li>';
    const cLi = onlyBr ? br : '</li>';
    const ul = onlyBr ? '' : '<ul>';
    const cUl = onlyBr ? br : '</ul>';
    const tr = onlyBr ? '' : '<tr>';
    const cTr = onlyBr ? br : '</tr>';
    const td = onlyBr ? '\t' : '<td>';
    const cTd = onlyBr ? '' : '</td>';
    const strong = onlyBr ? '' : '<strong>';
    const cStrong = onlyBr ? '' : '</strong>';
    const table = onlyBr ? '' : '<table>';
    const cTable = onlyBr ? '' : '</table>';

    newHtml = templates[templateIndex].html as string;
    const rq = rawData?.product;

    newHtml = newHtml
      .replace(new RegExp('_TITLE_', 'g'), rq?.title ?? '')
      .replace(new RegExp('_MAIN_IMAGE_', 'g'), rq?.imageUrls?.[0]?.replace(/\$/g, '$$$$') ?? '');

    if (!rq?.paragraphs || rq?.paragraphs.length == 0) {
      newHtml = RemoveTagArea(newHtml, '_BEGIN_DESCRIPTION_SECTION_', '_END_DESCRIPTION_SECTION_');
    } else {
      newHtml = ReplaceTagArea(
        newHtml,
        '_BEGIN_DESCRIPTION_SECTION_',
        '_END_DESCRIPTION_SECTION_',
        '_DESCRIPTION_CONTENT_',
        p + rq.paragraphs.join(cP + p) + cP
      );
    }

    if (!rq?.properties || rq?.properties?.length == 0) {
      newHtml = RemoveTagArea(newHtml, '_BEGIN_FEATURES_SECTION_', '_END_FEATURES_SECTION_');
    } else {
      newHtml = ReplaceTagArea(
        newHtml,
        '_BEGIN_FEATURES_SECTION_',
        '_END_FEATURES_SECTION_',
        '_FEATURES_CONTENT_',
        ul + li + rq.properties.join(cLi + li) + cLi + cUl
      );
    }

    newHtml = RemoveTagArea(newHtml, '_BEGIN_SECTIONS_SECTION_', '_END_SECTIONS_SECTION_');

    if (!rq?.tableValues || rq?.tableValues?.length == 0) {
      newHtml = RemoveTagArea(newHtml, '_BEGIN_TABLE_SECTION_', '_END_TABLE_SECTION_');
    } else {
      let content = table + '';
      const oc = rq.tableValues.slice().sort((a, b) => (a.key > b.key ? 1 : b.key > a.key ? -1 : 0));
      for (const pair of oc) {
        content += tr + td + strong + pair.key + cStrong + cTd + td + pair.value + cTd + cTr;
      }
      content += cTable;
      newHtml = ReplaceTagArea(
        newHtml,
        '_BEGIN_TABLE_SECTION_',
        '_END_TABLE_SECTION_',
        '_TABLE_CONTENT_',
        content
      );
    }
  }
  return newHtml;
};
