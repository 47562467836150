import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../custom-hooks/reduxCustomHooks';
import { Links } from '../../../links';
import { ConfirmUser } from '../../../redux/user/userThunk';
import { A } from '../../../small-components/A';

export const UserConfirm = () => {

  const dispatch = useAppDispatch();
  const { userId, code } = useParams<{ userId?: string, code?:string }>();
  const { confirmAnswer } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (userId && code)
      dispatch(ConfirmUser({ userId, code}));
  }, [userId, code]);

  if (!userId || !code)
    return <>Wrong parameters</>;

  if (confirmAnswer) {
    if (confirmAnswer.success) {
      return <>Email confirmed. <A href={Links.Dashboard}>Continue</A></>;
    } else {
      return <>Error confirming account</>;
    }
  }

  return <>Confirming account...</>;
};