import { Affix } from 'antd';
import { UserSupplierSettingsKeys } from '../../../../data/autoorder-fields';
import { AutoOrderingConfigurationAccountData, UserSupplierType } from '../../../../redux/auto-ordering/autoOrderingThunk';
import { Source } from '../../../../redux/sources/sourceSlice';
import { SuccessBtn } from '../../../../small-components/ActionBtns';
import { toastAlert } from '../../../../utils/toastAlert';
import { T } from '../../../../utils/transShim';
import { AOAccountSettings } from './ao-account-settings';

interface Props {
  account: AutoOrderingConfigurationAccountData;
  sourceData: Source;
  onRemove: (account: AutoOrderingConfigurationAccountData) => void;
  onSave: (account: AutoOrderingConfigurationAccountData) => void;
  onChange: (account: AutoOrderingConfigurationAccountData) => void;
}

export const AOAccountEdit = (props: Props) => {
  const { account, sourceData, onRemove, onSave, onChange } = props;

  const GetRawSetting = (s: UserSupplierSettingsKeys) => account?.rawSettings?.find((x) => x.key == s);
  const GetRawSettingValue = (s: UserSupplierSettingsKeys) => GetRawSetting(s)?.value;

  const HandleSaveAutoOrderAccount = () => {
    if (account.type == UserSupplierType.HGR) {
      onSave(account);
      return;
    }

    const alias = GetRawSettingValue(UserSupplierSettingsKeys.ALIAS)?.trim() ?? '';
    if (alias) {
      onSave(account);
    } else {
      toastAlert('Please provide an Alias in order to save account', 'error');
    }
  };

  return <div className="auto-ordering-account-area">
    <div className="auto-ordering-left">{<AOAccountSettings sourceData={sourceData} account={account} onRemove={onRemove} onChange={onChange} />}</div>
    <div className="auto-ordering-right">
      <Affix offsetTop={80}>
        <SuccessBtn onClick={HandleSaveAutoOrderAccount}><T k="AutoOrderingConfiguration.SaveChanges" /></SuccessBtn>
      </Affix>
    </div>
  </div>;
};