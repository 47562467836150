import { AutoOrderField, Fields } from './autoorder-fields';
import { eCountry } from './countries';

export interface SourceStaticData {
  id: number;
  name: string;
  url: string;
  isoCountry: eCountry;
  autoorder: {
    enabled: boolean;
    fields: AutoOrderField[];
    alert?: string;
    hgrAccountFields?: AutoOrderField[];
    hgrAccountDiscount?: number;
  };
}

const AmazonFields: AutoOrderField[] = [
  Fields.EmailLogin,
  Fields.Password,
  Fields.OTP,
  Fields.GiftCard,
  Fields.CompleteCard,
  Fields.BillingFirstName,
  Fields.BillingLastName,
  Fields.BillingStreet1,
  Fields.BillingStreet2,
  Fields.BillingCity,
  Fields.BillingPostCode,
  Fields.BillingProvince,
  Fields.BillingCountry,
  Fields.GiftCard,
  Fields.MarkAsGift,
  Fields.GiftMessage,
  Fields.GiftFrom
];
const BanggoodFields: AutoOrderField[] = [
  Fields.EmailLogin,
  Fields.Password,
  Fields.FourNumbersCard,
  Fields.UsePoints,
  Fields.BillingFirstName,
  Fields.BillingLastName,
  Fields.BillingStreet1,
  Fields.BillingStreet2,
  Fields.BillingCity,
  Fields.BillingPostCode,
  Fields.BillingProvince,
  Fields.BillingCountry,
  Fields.PaymentTypeCardAndPaypal
];
const amazonAlert = 'Account must be prime or business prime';

const Sources: {
  [id: number]: SourceStaticData;
} = {
  1: {
    id: 1,
    name: 'Amazon',
    url: 'www.amazon.co.uk',
    isoCountry: eCountry.UK,
    autoorder: {
      enabled: true,
      fields: AmazonFields,
      alert: amazonAlert
    }
  },
  30: {
    id: 30,
    name: 'Amazon',
    url: 'www.amazon.com',
    isoCountry: eCountry.US,
    autoorder: {
      enabled: true,
      fields: AmazonFields,
      alert: amazonAlert
    }
  },
  140: {
    id: 140,
    name: 'Amazon',
    url: 'www.amazon.com.au',
    isoCountry: eCountry.AU,
    autoorder: {
      enabled: true,
      fields: AmazonFields,
      alert: amazonAlert
    }
  },
  141: {
    id: 141,
    name: 'Amazon',
    url: 'www.amazon.de',
    isoCountry: eCountry.DE,
    autoorder: {
      enabled: true,
      fields: AmazonFields,
      alert: amazonAlert
    }
  },
  107: {
    id: 107,
    name: 'Amazon',
    url: 'www.amazon.es',
    isoCountry: eCountry.ES,
    autoorder: {
      enabled: true,
      fields: AmazonFields,
      alert: amazonAlert
    }
  },
  97: {
    id: 97,
    name: 'Amazon',
    url: 'www.amazon.fr',
    isoCountry: eCountry.FR,
    autoorder: {
      enabled: true,
      fields: AmazonFields,
      alert: amazonAlert
    }
  },
  132: {
    id: 132,
    name: 'Amazon',
    url: 'www.amazon.it',
    isoCountry: eCountry.IT,
    autoorder: {
      enabled: true,
      fields: AmazonFields,
      alert: amazonAlert
    }
  },
  3: {
    id: 3,
    name: 'Costco',
    url: 'www.costco.co.uk',
    isoCountry: eCountry.UK,
    autoorder: {
      enabled: true,
      fields: [Fields.EmailLogin, Fields.Password, Fields.FourNumbersCard, Fields.CVV]
    }
  },
  78: {
    id: 78,
    name: 'Costco',
    url: 'www.costco.com',
    isoCountry: eCountry.US,
    autoorder: {
      enabled: true,
      fields: [Fields.EmailLogin, Fields.Password, Fields.FourNumbersCard, Fields.CVV, Fields.Email]
    }
  },
  10: {
    id: 10,
    name: 'Robert Dyas',
    url: 'www.robertdyas.co.uk',
    isoCountry: eCountry.UK,
    autoorder: {
      enabled: true,
      fields: [Fields.EmailLogin, Fields.Password, Fields.CompleteCard, Fields.CVV, Fields.CardName, Fields.CardExpiryMonth, Fields.CardExpiryYear]
    }
  },
  5: {
    id: 5,
    name: 'UK Banggood',
    url: 'uk.banggood.com',
    isoCountry: eCountry.UK,
    autoorder: {
      enabled: true,
      fields: BanggoodFields
    }
  },
  56: {
    id: 56,
    name: 'US Banggood',
    url: 'us.banggood.com',
    isoCountry: eCountry.US,
    autoorder: {
      enabled: true,
      fields: BanggoodFields
    }
  },
  57: {
    id: 57,
    name: 'Banggood Com',
    url: 'www.banggood.com',
    isoCountry: eCountry.US,
    autoorder: {
      enabled: true,
      fields: BanggoodFields
    }
  },
  70: {
    id: 70,
    name: 'Banggood Com',
    url: 'www.banggood.com',
    isoCountry: eCountry.UK,
    autoorder: {
      enabled: true,
      fields: BanggoodFields
    }
  },
  59: {
    id: 59,
    name: 'Costway',
    url: 'www.costway.co.uk',
    isoCountry: eCountry.UK,
    autoorder: {
      enabled: true,
      fields: [Fields.EmailLogin, Fields.Password, Fields.CompleteCard, Fields.CVV, Fields.CardName, Fields.CardExpiryMonth, Fields.CardExpiryYear]
    }
  },
  60: {
    id: 60,
    name: 'Costway',
    url: 'www.costway.com',
    isoCountry: eCountry.US,
    autoorder: {
      enabled: true,
      fields: [Fields.EmailLogin, Fields.Password, Fields.CompleteCard, Fields.CVV, Fields.CardName, Fields.CardExpiryMonth, Fields.CardExpiryYear]
    }
  },
  31: {
    id: 31,
    name: 'Walmart',
    url: 'www.walmart.com',
    isoCountry: eCountry.US,
    autoorder: {
      enabled: true,
      fields: [Fields.EmailLogin, Fields.Password, Fields.FourNumbersCard, Fields.CVV]
    }
  },
  221: {
    id: 221,
    name: 'SaleYee',
    url: 'www.saleyee.com',
    isoCountry: eCountry.UK,
    autoorder: {
      enabled: true,
      fields: [Fields.EmailLogin, Fields.Password, Fields.CompleteCard, Fields.CVV, Fields.CardName, Fields.CardExpiryMonth, Fields.CardExpiryYear, Fields.SupplierPaymentPassword, Fields.ProtectionServicesReturn, Fields.ProtectionServicesLogistics]
    }
  },
  222: {
    id: 222,
    name: 'SaleYee',
    url: 'www.saleyee.com',
    isoCountry: eCountry.US,
    autoorder: {
      enabled: true,
      fields: [Fields.EmailLogin, Fields.Password, Fields.CompleteCard, Fields.CVV, Fields.CardName, Fields.CardExpiryMonth, Fields.CardExpiryYear, Fields.SupplierPaymentPassword, Fields.ProtectionServicesReturn, Fields.ProtectionServicesLogistics]
    }
  },
  185: {
    id: 185,
    name: 'Dropship Traders',
    url: 'www.dropship-traders.co.uk',
    isoCountry: eCountry.UK,
    autoorder: {
      enabled: true,
      fields: [Fields.EmailLogin, Fields.Password]
    }
  },
  67: {
    id: 67,
    name: 'Zavvi',
    url: 'www.zavvi.com',
    isoCountry: eCountry.UK,
    autoorder: {
      enabled: true,
      fields: [Fields.EmailLogin, Fields.Password, Fields.FourNumbersCard, Fields.CVV]
    }
  },
  219: {
    id: 219,
    name: 'Zavvi',
    url: 'us.zavvi.com',
    isoCountry: eCountry.US,
    autoorder: {
      enabled: true,
      fields: [Fields.EmailLogin, Fields.Password, Fields.FourNumbersCard, Fields.CVV]
    }
  },
  63: {
    id: 63,
    name: 'VidaXL',
    url: 'www.vidaxl.co.uk',
    isoCountry: eCountry.UK,
    autoorder: {
      enabled: true,
      fields: [
        Fields.EmailLogin, Fields.Password, Fields.CompleteCard, Fields.CVV, Fields.CardName, Fields.CardExpiryMonth, Fields.CardExpiryYear,
        Fields.BillingFirstName,
        Fields.BillingLastName,
        Fields.BillingStreet1,
        Fields.BillingStreet2,
        Fields.BillingCity,
        Fields.BillingPostCode,
        Fields.BillingProvince,
        Fields.BillingCountry,
        Fields.Email
      ]
    }
  },
  90: {
    id: 90,
    name: 'VidaXL',
    url: 'www.vidaxl.com',
    isoCountry: eCountry.US,
    autoorder: {
      enabled: true,
      fields: [
        Fields.EmailLogin, Fields.Password, Fields.CompleteCard, Fields.CVV, Fields.CardName, Fields.CardExpiryMonth, Fields.CardExpiryYear,
        Fields.BillingFirstName,
        Fields.BillingLastName,
        Fields.BillingStreet1,
        Fields.BillingStreet2,
        Fields.BillingCity,
        Fields.BillingPostCode,
        Fields.BillingProvince,
        Fields.BillingCountry,
        Fields.Email
      ]
    }
  },
  99: {
    id: 99,
    name: 'VidaXL B2B',
    url: 'b2b.vidaxl.com',
    isoCountry: eCountry.UK,
    autoorder: {
      enabled: true,
      fields: [Fields.EmailLogin, Fields.Password, Fields.PaymentTypeWalletRightNow]
    }
  },
  118: {
    id: 118,
    name: 'VidaXL B2B',
    url: 'b2b.vidaxl.com',
    isoCountry: eCountry.US,
    autoorder: {
      enabled: true,
      fields: [Fields.EmailLogin, Fields.Password, Fields.PaymentTypeWalletRightNow]
    }
  },
  71: {
    id: 71,
    name: 'JD Williams',
    url: 'www.jdwilliams.co.uk',
    isoCountry: eCountry.UK,
    autoorder: {
      enabled: true,
      fields: [Fields.EmailLogin, Fields.Password, Fields.FourNumbersCard, Fields.CVV]
    },
  },
  65: {
    id: 65,
    name: 'Asda',
    url: 'direct.asda.com',
    isoCountry: eCountry.UK,
    autoorder: {
      enabled: true,
      fields: [Fields.EmailLogin, Fields.Password, Fields.GiftCardNumber, Fields.GiftCardPin]
    }
  },
  64: {
    id: 64,
    name: 'Aosom',
    url: 'www.aosom.co.uk',
    isoCountry: eCountry.UK,
    autoorder: {
      enabled: true,
      fields: [Fields.EmailLogin, Fields.Password, Fields.FourNumbersCard, Fields.CVV, Fields.BusinessAccount, Fields.DiscountCode, Fields.CompanyName],
      hgrAccountFields: [Fields.CompanyName],
      hgrAccountDiscount: 15
    }
  }
};
export default Sources;