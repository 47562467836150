import * as React from 'react';

type SimpleCardProps = {
  OnSelect: () => void;
  selected?: boolean;
  children?: React.ReactNode;
}

export const RelievCard = (props: SimpleCardProps) => {
  const OnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    props.OnSelect();
  };

  return (
    <div className={'relievCard' + (props.selected ? ' selected' : '')} onClick={(e) => OnClick(e)}>
      <div className="inner">{props.children}</div>
    </div >
  );
};
