import { Col, Row, Spin } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../../custom-hooks/reduxCustomHooks';
import { BusinessPolicyType } from '../../../../../redux/channel-configuration/channels-configuration-slice';
import { loadBusinessPolicies } from '../../../../../redux/channel-configuration/channels-configuration-thunk';
import '../../../../../sass/listing-options.scss';
import { Selector, SelectorData } from '../../../../../small-components/form/selector';
import { useTranslation, T } from '../../../../../utils/transShim';
import { SetValueNumber } from './types';

type Props = {
  valuePaymentProfileId: SetValueNumber;
  valueShippingProfileId: SetValueNumber;
  valueReturnProfileId: SetValueNumber;

  onChangePaymentProfileId: (p: SetValueNumber) => void;
  onChangeShippingProfileId: (p: SetValueNumber) => void;
  onChangeReturnProfileId: (p: SetValueNumber) => void;
};

export const ListingMultipleBusiness = (props: Props) => {
  const {
    valuePaymentProfileId, valueShippingProfileId, valueReturnProfileId,
    onChangePaymentProfileId, onChangeShippingProfileId, onChangeReturnProfileId
  } = props;

  const dispatch = useDispatch();

  const {
    businessPolicies,
    loadingBusiness
  } = useAppSelector((state) => state.channelConfiguration);

  useEffect(() => {
    dispatch(loadBusinessPolicies());
  }, [loadBusinessPolicies]);


  const payments: SelectorData[] = businessPolicies?.filter(x => x.policyType == BusinessPolicyType.Payment).map(x => ({ value: x.id, label: x.name })) ?? [];
  const returns: SelectorData[] = businessPolicies?.filter(x => x.policyType == BusinessPolicyType.Returns).map(x => ({ value: x.id, label: x.name })) ?? [];
  const shipping: SelectorData[] = businessPolicies?.filter(x => x.policyType == BusinessPolicyType.Shipping).map(x => ({ value: x.id, label: x.name })) ?? [];
  payments.push({ value: null, label: useTranslation('Setting.DontChange') });
  returns.push({ value: null, label: useTranslation('Setting.DontChange') });
  shipping.push({ value: null, label: useTranslation('Setting.DontChange') });

  if (loadingBusiness)
    return <Spin />;

  return (
    <div className="listing-business">
      <Row className='description-and-controls' key='1'>
        <Col span={12} className='description-area'>
          <h2><T k='Sources.Table.Name.PolicyDelivery'/></h2>
        </Col>
        <Col span={8} className='switch-container'>
          <Selector value={valueShippingProfileId?.value} onChange={x => onChangeShippingProfileId(x != null ? { value: x as number } : null)}>
            {shipping}
          </Selector>
        </Col>
      </Row>
      <Row className='description-and-controls' key='2'>
        <Col span={12} className='description-area'>
          <h2><T k='Sources.Table.Name.PolicyReturns' /></h2>
        </Col>
        <Col span={8} className='switch-container'>
          <Selector value={valueReturnProfileId?.value} onChange={x => onChangeReturnProfileId(x != null ? { value: x as number } : null)}>
            {returns}
          </Selector>
        </Col>
      </Row>
      <Row className='description-and-controls' key='3'>
        <Col span={12} className='description-area'>
          <h2><T k='Sources.Table.Name.BusinessPayment' /></h2>
        </Col>
        <Col span={8} className='switch-container'>
          <Selector value={valuePaymentProfileId?.value} onChange={x => onChangePaymentProfileId(x != null ? { value: x as number } : null)}>
            {payments}
          </Selector>
        </Col>
      </Row>
    </div>
  );
};