import { PrimaryBtn } from 'src/small-components/ActionBtns';
import Spreadsheet, { Matrix, CellBase } from 'react-spreadsheet';
import { Alert, Checkbox, Form, Progress, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import '../../sass/listings/listings.scss';
import { ReactUtils } from '../../utils/react-utils';
const { Item } = Form;
import { GetMissingListings, monitorListing } from 'src/redux/import-listings/importListingThunk';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import { toastAlert } from '../../utils/toastAlert';
import { AddExistingListingResponse, ImportListingsState } from '../../redux/import-listings/importListingSlice';
import { PopupModal } from '../../small-components/modals/popup-modal';
import { ePlatform, IsEbay } from '../../data/platforms';
//import { isNullOrUndefined } from 'util';
import { ImportItem } from './Listings/components/import/import-item';
import { A } from '../../small-components/A';

export type ImportListingData = {
  id: string;
  title: string;
  variationSku?: string;
  variationAttributes?: { [key: string]: string };
  sourceURL: string;
  sourceVariation?: string;
  sourceWarehouse?: string;
  markup?: string;
};

export const ImportListings = () => {
  //Get channels and selected channel
  const channel = ReactUtils.GetSelectedChannel();
  const dispatch = useAppDispatch();
  //Checkboxes modes
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  //const [uploadCount, setUploadCount] = useState<number>(0);
  const [title, setTitle] = useState<string>("Don't close until it is finished");
  const [variationMode, setVariationMode] = useState<boolean>(IsEbay(channel?.channelId));
  //const [toRelist, setToRelist] = useState<boolean>(false);
  //const variationMode = IsEbay(channel?.channelId);
  const toRelist = false;
  const [percent, setPercent] = useState(0);
  const [fromOtherPlatform, setFromOtherPlatform] = useState<boolean>(false);
  const [manualMode, setManualMode] = useState<boolean>(false);
  const [oAuthId] = useState(602219);
  //const [spreadSheetData, setSpreadSheetData] = useState<Matrix<{ value: string }>>([]); //ReactSpreadSheet Array
  const [spreadSheetData, setSpreadSheetData] = useState<Matrix<CellBase<string>>>([]); //ReactSpreadSheet Array
  const [importListingData, setImportListingData] = useState<Array<ImportListingData>>([]); 
  const [columnsNames, setColumnsNames] = useState<string[]>([]); //columnsName
  //let [listings] = useState<Record<string, unknown>[]>([]); //2D Array converted to 1D Array
  const [newRow, setNewRow] = useState<Matrix<CellBase<string>>>([]);
  const [response, setResponse] = useState<AddExistingListingResponse>({
    inserted: [],
    wereInYourHGR: [],
    repeated: [],
    emptyUrl: [],
    notValidUrl: [],
    ok: false,
    error: ''
  });

  let skuName = channel?.name + ' Identifier';
  if (channel?.channelId === 4) {
    skuName = 'SKU';
  }

  const { missingListings: missingListingsDB, loadingMissing } = useAppSelector((state) => state.importLists as ImportListingsState);

  // Effect Spreadsheet Headings
  useEffect(() => {
    const colNames = [
      skuName,
      manualMode ? 'Title (Optional)' : 'Title',
      variationMode ? ['Variation sku', 'Variation attributes'] : '',
      'Source URL',
      fromOtherPlatform ? ['Source variation (optional)', 'Source warehouse (optional)'] : '',
      'Markup (optional)'
    ]
      .flat()
      .filter((cn) => String(cn).trim()); // remove all entries with empty string
    setColumnsNames(colNames);

    const newR = colNames.map(() => {
      const newCell: CellBase<string> = {
        value: ''
      };
      return newCell;
    });
    setNewRow([newR]);
  }, [fromOtherPlatform, manualMode, variationMode]);

  // Effect Manual Mode
  useEffect(() => {
    if (missingListingsDB == null) return;

    if (missingListingsDB.length == 0 && manualMode == false) setManualMode(true);
  }, [missingListingsDB?.length]);

  // Effect Variation Mode
  useEffect(() => {
    const vMode = manualMode ? false : IsEbay(channel?.channelId);
    setVariationMode(vMode);
  }, [manualMode]);

  const VariationsAtributeGetDic = (value: string | undefined) => {
    if (!value)
      return undefined;
    if (!value.startsWith('{')) {
      value = '{' + value;
    }
    if (!value.endsWith('}')) {
      value = value + '}';
    }
    return JSON.parse(value) as { [key: string]: string };
  };

  // Effect Spreadsheet Data
  useEffect(() => {
    const missingListings = missingListingsDB != null ? [...missingListingsDB] : null;
    missingListings?.sort((a, b) => a?.channelTitle?.localeCompare(b?.channelTitle) ?? false);

    if (missingListings !== null && missingListings.length != 0 && !manualMode) {  // typescript was not letting me use map without this condition
      /*
      const ssData = missingListings.map((ml) => {
        const v: CellBase<string>[] = [
          { value: ml.channelItem, readOnly: true }, //SKU
          { value: ml.channelTitle, readOnly: true } //Title
        ];
        if (variationMode) {
          v.push({ value: ml.sku, readOnly: true }); //Variation SKU
          const v_attr =
            ml.variationAttributes == null
              ? ''
              : ml.variationAttributes.map((x) => '"' + x.key + '"' + ':' + '"' + x.value + '"').join(',');
          v.push({ value: v_attr, readOnly: true }); //Variation Attributes
        }
        v.push({ value: '' }); //Source Url
        if (fromOtherPlatform) {
          v.push({ value: '' }); // Source variation
          v.push({ value: '' }); // Source warehouse
        }
        v.push({ value: '' }); //Markup
        return v;
      });
      setSpreadSheetData(ssData);
      */
      const ild = missingListings.map((ml) => {
        const item: ImportListingData = {
          id: ml.channelItem,
          title: ml.channelTitle,
          sourceURL: '',
          variationSku: ml.sku,
          variationAttributes: VariationsAtributeGetDic(ml.variationAttributes?.map(x => '"' + x.key + '"' + ':' + '"' + x.value.replaceAll('"','\\"') + '"').join(','))
        };

        return item;
      });
      setImportListingData(ild);
    } else {
      setSpreadSheetData(Array(5).fill(newRow));
    }

    //setUploadCount(0);
  }, [fromOtherPlatform, missingListingsDB, manualMode]);

  const ValidSourceURL = (url: string | undefined) => (url?.length ?? 0) > 5;

  const updateImportListingItem = (data: ImportListingData) => {
    const ild = importListingData;
    ild.filter(item => item.id === data.id)[0] = data;
    setImportListingData(ild);
    //setUploadCount(ild.filter(item => ValidSourceURL(item.sourceURL)).length);
  };

  const IsRowEmpty = (data: Matrix<CellBase<string>>, index: number): boolean => {
    let i = 0;
    for (i = 0; i < columnsNames.length; i++) {
      if (data[index][i]?.value === '' || data[index][i]?.value === undefined) {
        continue;
      } else break;
    }
    return i == columnsNames.length ? true : false;
  };
  

  
  
  const GetSpreadsheetRowValue = (row: (CellBase<string> | undefined)[]): ImportListingData => {
    let cell = 0;
    const ild: ImportListingData = {
      id: row[cell++]?.value ?? '',
      title: row[cell++]?.value ?? '',
      sourceURL: ''
    };
    if (variationMode) {
      ild.variationSku = row[cell++]?.value ?? '';
      ild.variationAttributes = VariationsAtributeGetDic(row[cell++]?.value);
    }
    ild.sourceURL = row[cell++]?.value ?? '';
    if (fromOtherPlatform) {
      ild.sourceVariation = row[cell++]?.value ?? '';
      ild.sourceWarehouse = row[cell++]?.value ?? '';
    }
    ild.markup = row[cell++]?.value ?? '';

    return ild;
  };
  const GetSpreadSheetValues = () => {
    const groupByChannelItem: { [id: string]: ImportListingData[] } = {}; //variations

    for (let i = 0; i < spreadSheetData.length; i++) {
      if (IsRowEmpty(spreadSheetData, i)) continue;

      const listing = GetSpreadsheetRowValue(spreadSheetData[i]);

      if (!groupByChannelItem[listing.id]) {
        groupByChannelItem[listing.id] = [];
      }
      groupByChannelItem[listing.id].push(listing);

      //listings.push(listing);
    }
    const listings: ImportListingData[] = [];
    for (const id in groupByChannelItem) {
      const ls = groupByChannelItem[id];

      if (!variationMode || ls.length == 1) {
        if (!ValidSourceURL(ls[0].sourceURL)) continue;

        listings.push(ls[0]);
        continue;
      }

      //We need to check if all variations have the sourceUrl, otherwise we won't send them
      let allValid = true;
      for (const l of ls) {
        allValid = allValid && ValidSourceURL(l.sourceURL);
      }
      if (!allValid) continue;

      for (const l of ls) {
        listings.push(l);
      }
    }
    return listings;
  };
  //const GetRowSourceUrl = (row: (CellBase<string> | undefined)[]): string => {
  //  const cell = variationMode ? 4 : 2;
  //  return row[cell]?.value ?? '';
  //};

  const monitorList = async () => {
    setPercent(1);
    setResponse({
      inserted: [],
      wereInYourHGR: [],
      repeated: [],
      emptyUrl: [],
      notValidUrl: [],
      ok: false,
      error: ''
    });
    const inserted: string[] = [];
    const wereInYourHGR: string[] = [];
    const repeated: string[] = [];
    const emptyUrl: string[] = [];
    const notValidUrl: string[] = [];
    let error = '';
    let ok = false;

    setLoading(true);
    const listings = manualMode ? GetSpreadSheetValues() : importListingData.filter(item => ValidSourceURL(item.sourceURL));
    const grids = [...listings];
    let counter = 0;
    const chunk = 10; //Aprox
    while (grids.length > counter) {
      const listing = grids.slice(counter, counter + chunk);
      const rp = await dispatch(monitorListing({ toRelist, oAuthId, listings: listing }));
      counter += listing.length;
      rp.payload?.inserted?.map((x: string) => {
        inserted.push(x);
      });
      rp.payload?.wereInYourHGR?.map((x: string) => {
        wereInYourHGR.push(x);
      });
      rp.payload?.repeated?.map((x: string) => {
        repeated.push(x);
      });
      rp.payload?.emptyUrl?.map((x: string) => {
        emptyUrl.push(x);
      });
      rp.payload?.notValidUrl?.map((x: string) => {
        notValidUrl.push(x);
      });

      error = error + rp?.payload?.error + '\n';
      ok = ok || rp.payload?.ok;
      setPercent(Math.round((100 * counter) / listings.length));
    }
    setResponse({
      inserted: inserted,
      emptyUrl: emptyUrl,
      error: error,
      notValidUrl: notValidUrl,
      ok: ok,
      repeated: repeated,
      wereInYourHGR: wereInYourHGR
    });

    if (ok) {
      toastAlert('Listings uploaded Successfully', 'success');
      setTitle('Done! You can close this window');
    } else {
      toastAlert('Something went wrong! Please try again', 'error');
      setTitle('Done with Errors! You can close this window');
    }
    setLoading(false);
    setPercent(100);
    dispatch(GetMissingListings()); //Refresh the list
  };

  const onCloseModal = () => {
    setUploading(false);
    setTitle("Don't close until it is finished");
  };

  const SpreadSheetDataEqual = (data1: Matrix<CellBase<string>>, data2: Matrix<CellBase<string>>) => {
    if (data1.length != data2.length) return false;
    for (let i = 0; i < data1.length; i++) {
      if (data1[i].length != data2[i].length) return false;
      for (let j = 0; j < data1[i].length; j++) {
        if (data1[i][j]?.value != data2[i][j]?.value) return false;
      }
    }
    return true;
  };

  const onDataChanged = (newData: Matrix<CellBase<string>>) => {
    if (SpreadSheetDataEqual(spreadSheetData, newData)) return;

    // Bug in Spreadsheet: https://github.com/iddan/react-spreadsheet/issues/130
    /*
    let isModified = false;
    let modifiedData: Matrix<CellBase<string>> = [];
    if (!manualMode) {
      modifiedData = newData.map((row, index) => {
        return row.map((cell, index2) => {
          const c = cell as CellBase<string>;
          const original = spreadSheetData[index][index2] as CellBase<string>;
          if (c.readOnly && c.value != original.value) {
            isModified = true;
            return original;
          }
          return c;
        });
      });
    }
    */
    if (!IsRowEmpty(newData, newData.length - 1) && manualMode) {
      setSpreadSheetData(newData.concat(newRow));
    }  else {
      setSpreadSheetData(newData);
    }
    
    //setUploadCount(spreadSheetData.filter(ssd => ValidSourceURL(GetRowSourceUrl(ssd))).length);
  };

  const AlertAmazon = () => {
    const x = () => {
      return <>To get the best result publish on Amazon using <A to="https://sellercentral.amazon.com/product-search?ref=xx_catadd_favb_xx" target="_blank">Official Amazon tool</A> and once imported you can add it to HGR using this tool.</>;
    };
    return <Alert type="warning" message={x()}/>;
  };

  return (
    <div className="import-listings">
      {loadingMissing ? (
        <Spin />
      ) : (
        <>
          {channel?.channelId == ePlatform.Amazon && AlertAmazon()}
          <div className="explanation-section">
            <p>You can add/import your existing products in bulk by following these steps:</p>
            <ul>
              {manualMode && <li>Copy and paste your Item IDs (which are the identifiers of your products) in the column provided.</li>}
              <li>
                Right next to it, in the Source URL column, you have to copy and paste the corresponding URLs for each product (item ID).
              </li>
              <li>Once you have done that, you have to click on Monitor products.</li>
            </ul>
            <p>As soon as your items are imported, HGR will start monitoring them. They will be imported with your current markup.</p>
            <p>
              If there is any issue with any of your products, such as invalid URL or already existing product, you will be informed after
              submitting your products. You will see which of them have successfully been imported and which have not.
            </p>
          </div>
          <div className="checkboxes-section">
            <div className="checkboxes">
              {manualMode && IsEbay(channel?.channelId) && (
                <div className="variations-section">
                  <Checkbox onChange={() => setVariationMode(!variationMode)}>Variations mode</Checkbox>
                  {variationMode && (
                    <div className="variations-mode-explanation">
                      <p>If you wish to import variations, there are two more fields you will need to specify.</p>
                      <ul>
                        <li>
                          Variation sku: This ID corresponds to the label used to distinguish between variations that have the same item ID.
                        </li>
                        <li>Variation attribute: It is used to specify the value of the attribute/s of the variation.</li>
                      </ul>
                      <p>Example</p>
                      <p>Variation attributes : {'"Color": "Red"'}</p>
                      <p>If the variation has more than one attribute you can separate them with a comma.</p>
                      <p>Example</p>
                      <p>Variation attributes : {'"Color": "Red" , "Size": "Small"'}</p>
                    </div>
                  )}
                </div>
              )}
              <div className="otherplatform-section">
                <Checkbox onChange={() => setFromOtherPlatform(!fromOtherPlatform)}>Import products from another tool</Checkbox>
                {fromOtherPlatform && <div className="otherplatform-mode-explanation"></div>}
              </div>
            </div>
          </div>
          {missingListingsDB?.length !== 0 && (
            <PrimaryBtn
              className="manual-switch-btn"
              onClick={() => {
                setManualMode(!manualMode);
              }}
            >
              {!manualMode ? 'Change to Manual Import' : 'Change to Auto Import'}
            </PrimaryBtn>
          )}
          {(!manualMode && missingListingsDB != null && missingListingsDB?.length !== 0) ? (
            <>
              <div className='import-item-header'>
                These are the products we have <b>detected in your store.</b> You only have to fill in the URLS of the corresponding product and then click on <b>monitor products.</b>
              </div>
              <div className="import-item-container">
                {importListingData.map((item,index) => {
                  return <ImportItem
                    key={index}
                    data={item}
                    fromOtherPlatform={fromOtherPlatform}
                    updateItem={updateImportListingItem}
                  />;
                })}
              </div>
            </>
            
          ) : (
            <Spreadsheet
              data={spreadSheetData}
              onChange={onDataChanged}
              //onCellCommit={onDataChanged}
              columnLabels={columnsNames}
              className={'spreadsheet center-block' + (variationMode ? ' variationMode' : '') + (fromOtherPlatform ? ' fromOtherPlatform' : '') + (manualMode ? ' manual' : '') }
            />
          )}

          <div className="table-container">
            <div className="button-container">
              <Item>
                <PrimaryBtn
                  disabled={loading}
                  htmlType="submit"
                  onClick={() => {
                    setUploading(true);
                    monitorList();
                  }}
                >
                  Monitor products
                </PrimaryBtn>
              </Item>
            </div>
          </div>
        </>
      )}

      {response.ok && (
        <div className="result-container">
          <Row>
            <h2>Monitor Listings Result</h2>
            <hr />
          </Row>
          {response.inserted.length > 0 && (
            <div className="text-success">
              <Row className="help">These listings could be shown without title and some other information during 24h.</Row>
              <h4 className="text-success">Successful added to HGR</h4>
              {response.inserted.map((value, index) => (
                <li key={'a_' + index}>{value}</li>
              ))}
              <br />
            </div>
          )}
          {response.wereInYourHGR.length > 0 && (
            <div className="text-info">
              <h4 className="text-info">
                {response.wereInYourHGR.length + ' ' + (response.wereInYourHGR.length > 1 ? 'URLs were' : 'URL was')} in your account. Not
                added to the system
              </h4>
              {response.wereInYourHGR.map((value, index) => (
                <li key={'b_' + index}>{value}</li>
              ))}
              <br />
            </div>
          )}
          {response.repeated.length > 0 && (
            <div className="text-primary">
              <h4 className="text-primary">
                {response.repeated.length + ' ' + (response.repeated.length > 1 ? 'URLs were' : 'URL was')} Duplicated
              </h4>
              {response.repeated.map((value, index) => (
                <li key={'c_' + index}>{value}</li>
              ))}
              <br />
            </div>
          )}
          {response.emptyUrl.length > 0 && (
            <div className="text-danger">
              <h4 className="text-danger">
                {response.emptyUrl.length + ' ' + (response.emptyUrl.length > 1 ? 'URLs were' : 'URL was')} empty. Not added to the system
              </h4>
              {response.emptyUrl.map((value, index) => (
                <li key={'d_' + index}>{value}</li>
              ))}
              <br />
            </div>
          )}
          {response.notValidUrl.length > 0 && (
            <div className="text-danger">
              <h4 className="text-danger">
                {response.notValidUrl.length + ' ' + (response.notValidUrl.length > 1 ? 'URLs were' : 'URL was')} invalid. Not added to the
                system
              </h4>
              {response.notValidUrl.map((value, index) => (
                <li key={'e_' + index}>{value}</li>
              ))}
            </div>
          )}
        </div>
      )}
      <PopupModal
        open={uploading || loading}
        maxWidth={500}
        onClose={onCloseModal}
        maskClosable={percent === 100}
        showCloseX={percent === 100}
        title={title}
      >
        {percent === 100 && (
          <>
            {response.ok ? (
              <div className="result-container">
                {response.inserted.length > 0 && (
                  <div className="text-success">
                    <h4 className="text-success">
                      {response.inserted.length} listing{response.inserted.length > 1 ? 's' : ''} imported successfully
                    </h4>
                  </div>
                )}
                {response.wereInYourHGR.length > 0 && (
                  <div className="text-info">
                    <h4 className="text-info">
                      {response.wereInYourHGR.length + ' ' + (response.wereInYourHGR.length > 1 ? 'URLs were' : 'URL was')} in your account.
                      Not added to the system
                    </h4>
                  </div>
                )}
                {response.repeated.length > 0 && (
                  <div className="text-primary">
                    <h4 className="text-primary">
                      {response.repeated.length + ' ' + (response.repeated.length > 1 ? 'URLs were' : 'URL was')} Duplicated
                    </h4>
                  </div>
                )}
                {response.emptyUrl.length > 0 && (
                  <div className="text-danger">
                    <h4 className="text-danger">
                      {response.emptyUrl.length + ' ' + (response.emptyUrl.length > 1 ? 'URLs were' : 'URL was')} empty. Not added to the
                      system
                    </h4>
                  </div>
                )}
                {response.notValidUrl.length > 0 && (
                  <div className="text-danger">
                    <h4 className="text-danger">
                      {response.notValidUrl.length + ' ' + (response.notValidUrl.length > 1 ? 'URLs were' : 'URL was')} invalid. Not added
                      to the system
                    </h4>
                  </div>
                )}
              </div>
            ) : (
              <div className="text-danger">
                <h4 className="text-danger">{response.error}</h4>
              </div>
            )}
          </>
        )}
        <div>
          <Progress percent={percent} status={percent == 100 ? 'success' : 'active'} />
        </div>
      </PopupModal>
    </div>
  );
};
