import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../custom-hooks/reduxCustomHooks';
import {
  SendCode,
  getRequestCode,
  getManagedServers,
  UpdateManagedServers,
  getSolutionState,
  SendAnswer
} from 'src/redux/dashboard/noApiServersThunk';
import { PrimaryBtn } from 'src/small-components/ActionBtns';
import '../../sass/no-api/configure-noapi.scss';
import { Selector, SelectorValue } from 'src/small-components/form/selector';
import { Input, Alert, Breadcrumb, Spin } from 'antd';
import { Credentials, NoApiServerConnectionStatus, NoApiServerVerifySolutionStatus } from '../../redux/dashboard/noApiServersSlice';
import Button from 'antd/es/button';
import { ePlatform, GetPlatform, PlatformInfo } from '../../data/platforms';
import React from 'react';
import { toastAlert } from '../../utils/toastAlert';
import { A } from '../../small-components/A';
import { T } from '../../utils/transShim';
import incognito_img from '../../assets/icons/incognito.svg';
import { ChannelLabel } from '../../small-components/channel-label';
import { ReactUtils } from '../../utils/react-utils';
import { Channel } from '../../redux/channels/channelsSlice';

export const ConfigureNoapi = () => {
  const dispatch = useAppDispatch();

  const { manageServerResult, loading } = useAppSelector((state) => state.managedServers);
  const { loadingGetRequestCode } = useAppSelector((state) => state.noApiServers);

  const channels = ReactUtils.GetAllChannels();

  //Data for backend
  const [code, SetCode] = useState<{ [id: number]: string }>({});
  const [allCredentials, setAllCredentials] = (() => {
    const [_creds, _SetCred] = useState<Credentials[] | undefined>(manageServerResult?.credentials ?? undefined);
    if (manageServerResult.credentials != null && (_creds == null || _creds.length != manageServerResult.credentials.length)) {
      _SetCred(manageServerResult.credentials);
    }
    return [_creds ?? [], _SetCred];
  })();
  const [forceUpdate, setForceUpdate] = useState<boolean>(false);

  const HandleOptionChange = (value: number, subscriptionId: number) => {

    const x = manageServerResult?.channels?.find(x => x.id == value);
    const newCredentials = allCredentials.map((obj) => {
      //If we find it, we replace it with the new info
      if (obj.subscriptionId === subscriptionId) {
        return {
          ...obj,
          channelOauthId: value,
          loginUsername: null,
          channelItem: x?.channelId ?? obj.channelItem
        };
      }
      // Otherwise return object as it is
      return obj;
    });

    setAllCredentials(newCredentials);
  };

  const UpdateCredentials = (credentials: Credentials[]) => {
    const newState = credentials?.map((obj) => {
      let user = '';
      manageServerResult?.channels?.map((x: { id: SelectorValue; displayName: string }) => {
        if (x.id == obj.channelOauthId) {
          user = x.displayName;
        }
      });
      // otherwise return object as is
      return { ...obj, loginUsername: user };
    });
    setAllCredentials(newState);
  };

  const HandleUserNameChange = (value: string, subscriptionId: number) => {
    const newState = allCredentials.map((obj) => {
      if (obj.subscriptionId === subscriptionId) {
        return { ...obj, loginUsername: value };
      }
      // otherwise return object as is
      return obj;
    });
    setAllCredentials(newState);
  };

  const HandlePasswordChange = (value: string, index: number) => {
    const newState = allCredentials.map((obj, i) => {
      if (index === i) {
        return { ...obj, password: value };
      }
      // otherwise return object as is
      return obj;
    });
    setAllCredentials(newState);
  };

  const HandleTwoStepSecret = (value: string, index: number) => {
    const newState = allCredentials.map((obj, i) => {
      if (index === i) {
        return { ...obj, twoStepSecret: value };
      }
      // otherwise return object as is
      return obj;
    });
    setAllCredentials(newState);
  };

  const Refresh = async (credentials: Credentials) => {
    try {
      const rp = await dispatch(getSolutionState(credentials.noApiServerId));
      const newCred = [...allCredentials];
      for (const i in newCred) {
        if (newCred[i]?.noApiServerId == credentials.noApiServerId) {
          newCred[i] = {
            ...newCred[i],
            lastUpdated: rp?.payload?.lastUpdated,
            state: rp?.payload?.state,
            solutionState: rp?.payload?.solutionState
          };
          break;
        }
      }
      setAllCredentials(newCred);
    } catch (ex) {
      console.log(ex);
    }
  };

  const NeedRefresh = (channelId: ePlatform, credentials: Credentials) => {
    if (channelId != ePlatform.eBayNoApi) return false;

    switch (credentials.state) {
      case NoApiServerConnectionStatus.RequiresOTP:
      case NoApiServerConnectionStatus.RequiresSoftOTP:
        return true;
      default:
        return false;
    }
  };

  const thereAreServers = (allCredentials?.length ?? 0) > 0;

  useEffect(() => {
    if (thereAreServers)
      setTimeout(() => {
        if (forceUpdate) {
          //We will comunicate only 1 each 2.
          allCredentials?.forEach((x) => {
            if (NeedRefresh(x.channelItem, x)) Refresh(x);
          });
        }
        setForceUpdate(!forceUpdate);
      }, 500);
  }, [forceUpdate, thereAreServers]);

  const GetTimer = (credentials: Credentials) => 10 * 60 - (new Date().getTime() - new Date(credentials.lastUpdated).getTime()) / 1000;
  const TimeTooLong = (credentials: Credentials) => GetTimer(credentials) < 0;

  const InfoMsg = (credentials: Credentials) => {
    if (credentials.solutionState == null || TimeTooLong(credentials)) return '';

    switch (credentials.solutionState) {
      default:
        return '';
      case NoApiServerVerifySolutionStatus.InitialState:
        if ((credentials.state & NoApiServerConnectionStatus.RequiresOTP) == 0) return 'Requesting code...';
        return '';
      case NoApiServerVerifySolutionStatus.CodeSent:
        return 'Code being processed...';
      case NoApiServerVerifySolutionStatus.AnswerSent:
        return 'Answer being processed...';
    }
  };

  const ErrorMsg = (credentials: Credentials) => {
    if (credentials.solutionState == null || TimeTooLong(credentials)) return '';

    switch (credentials.solutionState) {
      default:
        return '';
      case NoApiServerVerifySolutionStatus.SecurityAnswer24H:
        return "By security eBay doesn't allow you to answer more questions. You must wait 24h.";
      case NoApiServerVerifySolutionStatus.WrongAnswer:
        return 'Wrong answer. Try it again';
      case NoApiServerVerifySolutionStatus.WrongCode:
        return 'Wrong code. Try it again';
    }
  };

  const Question = (credentials: Credentials) => {
    if (credentials.solutionState == null || TimeTooLong(credentials)) return '';

    switch (credentials.solutionState) {
      default:
        return '';
      case NoApiServerVerifySolutionStatus.WaitingSecurityAnswer:
      case NoApiServerVerifySolutionStatus.WrongAnswer:
        return credentials.solutionData.question;
    }
  };

  const TimerStr = (credentials: Credentials) => {
    if (credentials.solutionState == null) return '';

    const difSecs = GetTimer(credentials);
    if (difSecs < 0) return '';

    let timer = false;
    switch (credentials.solutionState) {
      default:
        break;
      case NoApiServerVerifySolutionStatus.InitialState:
        timer = (credentials.state & NoApiServerConnectionStatus.RequiresOTP) != 0;
        break;
      case NoApiServerVerifySolutionStatus.WaitingCode:
      case NoApiServerVerifySolutionStatus.WaitingSecurityAnswer:
      case NoApiServerVerifySolutionStatus.WaitingNotification:
      case NoApiServerVerifySolutionStatus.WrongAnswer:
      case NoApiServerVerifySolutionStatus.WrongCode:
      case NoApiServerVerifySolutionStatus.CodeSent:
      case NoApiServerVerifySolutionStatus.AnswerSent:
        timer = true;
        break;
    }

    if (!timer) return '';

    const m = Math.floor(difSecs / 60);
    const s = Math.floor(difSecs - m * 60);
    return m + ':' + (s > 9 ? s.toString() : '0' + s);
  };

  const TimerHidden = (credentials: Credentials) => {
    if (credentials.solutionState == null || TimeTooLong(credentials)) return true;

    switch (credentials.solutionState) {
      case NoApiServerVerifySolutionStatus.InitialState:
        return (credentials.state & NoApiServerConnectionStatus.RequiresOTP) == 0;
      default:
        return true;
      case NoApiServerVerifySolutionStatus.WaitingCode:
      case NoApiServerVerifySolutionStatus.WaitingSecurityAnswer:
      case NoApiServerVerifySolutionStatus.WaitingNotification:
      case NoApiServerVerifySolutionStatus.WrongAnswer:
      case NoApiServerVerifySolutionStatus.WrongCode:
      case NoApiServerVerifySolutionStatus.CodeSent:
      case NoApiServerVerifySolutionStatus.AnswerSent:
        return false;
    }
  };

  const RequestCodeHidden = (credentials: Credentials) => {
    if (TimeTooLong(credentials)) return false;
    return credentials.solutionState != null;
  };

  const CodeInputFormHidden = (credentials: Credentials) => {
    if (credentials.solutionState == null || TimeTooLong(credentials)) return true;

    switch (credentials.solutionState) {
      case NoApiServerVerifySolutionStatus.InitialState:
        if (credentials.state == NoApiServerConnectionStatus.RequiresOTP) {
          return false;
        }
        return true;
      case NoApiServerVerifySolutionStatus.WaitingCode:
      case NoApiServerVerifySolutionStatus.WrongCode:
        return false;
      default:
        return true;
    }
  };

  const HandleSetCode = (id: number, v: string) => {
    const newC = { ...code };
    newC[id] = v;
    SetCode(newC);
  };

  const ConfirmButtonHidden = (credentials: Credentials) => {
    if (credentials.solutionState == null) return true;

    return credentials.solutionState != NoApiServerVerifySolutionStatus.WaitingNotification;
  };

  const SendAnswerFormHidden = (credentials: Credentials) => {
    if (credentials.solutionState == null) return true;

    switch (credentials.solutionState) {
      default:
        return true;
      case NoApiServerVerifySolutionStatus.WaitingSecurityAnswer:
      case NoApiServerVerifySolutionStatus.WrongAnswer:
        return false;
    }
  };

  const UpdateUICredentials = (aCredentials: Credentials[]) => {
    const crConts = document.querySelectorAll('.method2Container'); //This is awful it must be changed in the future
    for (const cCont of crConts) {
      const id = cCont.getAttribute('data-id');
      let credentials;
      for (const c of aCredentials) {
        if (c.noApiServerId == Number(id)) credentials = c;
      }

      if (credentials == null) continue;
    }
  };

  const SaveChanges = async () => {
    await dispatch(UpdateManagedServers(allCredentials));
    const r = await dispatch(getManagedServers());
    const cred = r?.payload?.credentials ?? [];
    UpdateCredentials(cred);
    UpdateUICredentials(cred);
    setAllCredentials(cred);

    toastAlert('Saved successfully', 'success');
  };

  const HandleRequestCode = async (id: number) => {
    await dispatch(getRequestCode(id));
  };

  const HandleSendCode = async (id: number) => {
    await dispatch(
      SendCode({
        id: id,
        code: code[id]
      })
    );
  };

  const HandleSendAnswer = async (id: number) => {
    const codeInput = document.querySelector('.answerOf' + id)?.nodeValue;
    await dispatch(
      SendAnswer(
        JSON.stringify({
          id: id,
          answer: codeInput
        })
      )
    );
  };

  const RequiresSoftOTPOthers = (x: Credentials) => <React.Fragment key={x.noApiServerId + '_n'}></React.Fragment>;
  const RequiresSoftOTPFacebook = (platform: PlatformInfo, x: Credentials) => {
    return (
      <tr key={x.noApiServerId + '_d'}>
        <td colSpan={4} style={{ width: '100%' }}>
          <Alert
            message={
              <>
                Please access your{' '}
                <A to="https://www.facebook.com" target="_blank" rel="noreferrer">
                  Facebook
                </A>{' '}
                and confirm the login attempt. Note: The location you will see on the {platform.storeName} request may be different from
                your actual location and can be disregarded
                <br />
                <br />
                If the message is not showing up, or you already confirmed it, please click on &quot;Save changes&quot; and our system will
                try to connect again.
                <br />
                <br />
                You might receive a notification on your phone, please ensure that you approve it for us to be able to connect.
              </>
            }
            type="error"
          />
        </td>
      </tr>
    );
  };
  const RequiresSoftOTPEbay = (platform: PlatformInfo, x: Credentials) => {
    const name = platform.storeName;
    return (
      <React.Fragment key={x.noApiServerId + '_n'}>
        <tr key={x.noApiServerId + '_d'}>
          <td colSpan={4} style={{ width: '100%' }}>
            <Alert message={name + ' requires us to enter a code in order to access your account'} type="error" />
          </td>
        </tr>
        <tr style={{ borderBottom: '1px lightgray solid' }} key={x.noApiServerId + '_e'}>
          <td colSpan={4} className="method2Container" data-id={x.noApiServerId}>
            {/* <h4>Method 2</h4>*@*/}
            <span className="errorC">{ErrorMsg(x)}</span>
            <span className={'timer' + (TimerHidden(x) ? ' hidden' : '')}>{TimerStr(x)}</span>
            <div className={'row codeInputForm' + (CodeInputFormHidden(x) ? ' hidden' : '')}>
              <div className="col-sm-8">
                <div className="input-group">
                  <label className="input-group-addon">Code: </label>
                  <input
                    className={'form-control codeInput ' + 'codeOf' + x.noApiServerId}
                    onChange={(e) => HandleSetCode(x.noApiServerId, e.currentTarget.value)}
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <Button className="btn btn-primary sendCode" data-id={x.noApiServerId} onClick={() => HandleSendCode(x.noApiServerId)}>
                  Send
                </Button>
              </div>
            </div>
            <div className={'row sendAnswerForm' + (SendAnswerFormHidden(x) ? ' hidden' : '')}>
              <br />
              <span className="question">{Question(x)}</span>
              <div className="col-sm-8">
                <div className="form-group answerInputForm">
                  <input className={'form-control answerInput ' + 'answerOf' + x.noApiServerId} />
                </div>
              </div>
              <div className="col-sm-4">
                <Button className="btn btn-primary sendAnswer" data-id={x.noApiServerId} onClick={() => HandleSendAnswer(x.noApiServerId)}>
                  Send
                </Button>
              </div>
            </div>
            <span data-origin={x.lastUpdated?.toString()}></span>
            <span className="info">{InfoMsg(x)}</span>
            {loadingGetRequestCode && <Spin />}
            {!loadingGetRequestCode && (
              <Button
                className={'btn-primary requestCode' + (RequestCodeHidden(x) ? ' hidden' : '')}
                onClick={() => HandleRequestCode(x.noApiServerId)}
              >
                Request code
              </Button>
            )}
            <Button
              className={'btn-primary confirmButton' + (ConfirmButtonHidden(x) ? ' hidden' : '')}
              onClick={() => HandleSendCode(x.noApiServerId)}
              data-id={x.noApiServerId}
            >
              Done
            </Button>
          </td>
        </tr>
      </React.Fragment>
    );
  };

  const RequiresSoftOTP = (channelId: ePlatform, x: Credentials, ignoreRetries = false) => {
    const platform = GetPlatform(channelId);
    if (!platform.noApi?.verifyServer || (!ignoreRetries && x.errorRetries < 2)) return RequiresSoftOTPOthers(x);

    switch (channelId) {
      case ePlatform.eBayNoApi:
        return RequiresSoftOTPEbay(platform, x);
      case ePlatform.Facebook:
        return RequiresSoftOTPFacebook(platform, x);
    }
    return RequiresSoftOTPOthers(x);
  };

  const RequiresOTPrAmazon = (x: Credentials) => {
    return (
      <tr key={x.noApiServerId + '_a'} style={{ borderBottom: '1px lightgray solid' }}>
        <td colSpan={4} style={{ width: '100%' }}>
          <Alert
            message={
              "Disable 2-step verification; otherwise the server won't be able to log in your account. Once done, please click on Save Changes."
            }
            type="error"
          />
        </td>
      </tr>
    );
  };

  const RequiresOTPrFacebook = (x: Credentials) => {
    return (
      <tr key={x.noApiServerId + '_a'} style={{ borderBottom: '1px lightgray solid' }}>
        <td colSpan={4} style={{ width: '100%' }}>
          <Alert
            message={
              <>
                Disable 2-step verification on this{' '}
                <A to="https://www.facebook.com/security/2fac/settings/" target="_blank" rel="noreferrer">
                  link
                </A>
                ; otherwise the server won&apos;t be able to log in your account. Once done, please click on Save Changes.
              </>
            }
            type="error"
          />
        </td>
      </tr>
    );
  };

  const RequiresOTPr = (channelId: ePlatform, x: Credentials) => {
    if (channelId == ePlatform.Amazon) return RequiresOTPrAmazon(x);
    if (channelId == ePlatform.Facebook) return RequiresOTPrFacebook(x);

    return RequiresSoftOTP(channelId, x, true);
  };

  const NotASellerEbay = (x: Credentials) => {
    return (
      <tr style={{ borderBottom: '1px lightgray solid' }} key={x.noApiServerId + '_f'}>
        <td colSpan={3} style={{ width: '100%' }}>
          <Alert message={"We have detected that you don't have a Seller Account. Please create account and try again"} type="error" />
        </td>
        <td>
          <A onClick={() => location.reload()} className="btn btn-default" to={''}>
            Retry
          </A>
        </td>
      </tr>
    );
  };

  const NotASellerAmazon = (x: Credentials) => {
    return (
      <tr style={{ borderBottom: '1px lightgray solid' }} key={x.noApiServerId + '_g'}>
        <td colSpan={3} style={{ width: '100%' }}>
          <Alert message={"We have detected that you don't have a Seller Account at " + name} type="error" />
        </td>
      </tr>
    );
  };

  const NotASeller = (channelId: ePlatform, x: Credentials) => {
    switch (channelId) {
      case ePlatform.eBayNoApi:
        return NotASellerEbay(x);
      case ePlatform.Amazon:
        return NotASellerAmazon(x);
      default:
      case ePlatform.Facebook:
        return <></>;
      //return NotASellerFacebook(x);
    }
  };

  const AccountSuspended = (channelId: ePlatform, x: Credentials) => {
    const platform = GetPlatform(channelId);
    return (
      <tr style={{ borderBottom: '1px lightgray solid' }} key={x.noApiServerId + '_h'}>
        <td colSpan={3} style={{ width: '100%' }}>
          <Alert
            message={
              'We have detected that your account has been suspended by ' + platform.storeName + '. Once you have solved it, click "Retry".'
            }
            type="error"
          />
        </td>
        <td>
          <button onClick={() => location.reload()} className="btn btn-default">
            Retry
          </button>
        </td>
      </tr>
    );
  };

  const BadUsernameEbay = (x: Credentials) => {
    return (
      <tr style={{ borderBottom: '1px lightgray solid' }} key={x.noApiServerId + '_i'}>
        <td colSpan={4} style={{ width: '100%' }}>
          <Alert
            key={x.noApiServerId + '_k'}
            message={
              <Breadcrumb>
                <Breadcrumb.Item>
                  Your accounts name is wrong. You can change it from &nbsp; <A to="/channel-configuration">channel-configuration</A>
                </Breadcrumb.Item>
              </Breadcrumb>
            }
            type="error"
          />
        </td>
      </tr>
    );
  };

  const BadUsernameAmazon = (x: Credentials) => {
    return (
      <tr style={{ borderBottom: '1px lightgray solid' }} key={x.noApiServerId + '_i'}>
        <td colSpan={4} style={{ width: '100%' }}>
          <Alert message={'Wrong email. Please, type it again and save it.'} type="error" key={x.noApiServerId + '_j'} />
        </td>
      </tr>
    );
  };

  const BadUsername = (channelId: ePlatform, x: Credentials) => {
    if (channelId == ePlatform.eBayNoApi) return BadUsernameEbay(x);
    return BadUsernameAmazon(x); //Amazon no api and Facebook no api
  };

  const BadPassword = (channelId: ePlatform, x: Credentials) => {
    const platform = GetPlatform(channelId);
    return (
      <tr style={{ borderBottom: '1px lightgray solid' }} key={x.noApiServerId + '_k'}>
        <td colSpan={4} style={{ width: '100%' }}>
          {channelId == ePlatform.Facebook && (
            <Alert
              message={
                <>
                  Wrong password or username. Please, type it again and save it. Remember to write the{' '}
                  <b>password and username from {platform.storeName}</b>, not from HGR.
                </>
              }
              type="error"
            />
          )}
          {channelId != ePlatform.Facebook && (
            <Alert
              message={
                <>
                  Wrong password. Please, type it again and save it. Remember to write the <b>password from {platform.storeName}</b>, not
                  from HGR.
                </>
              }
              type="error"
            />
          )}
        </td>
      </tr>
    );
  };

  const UserActionRequired = (x: Credentials) => {
    return (
      <tr style={{ borderBottom: '1px lightgray solid' }}>
        <td colSpan={4} style={{ width: '100%' }} key={x.noApiServerId + '_l'}>
          <Alert
            message={
              name +
              ' requires an action from you. Go to ' +
              name +
              ', log in, and follow the instructions that ' +
              name +
              ' will give to you.'
            }
            type="error"
          />
        </td>
      </tr>
    );
  };

  const NoApiServerStatus = (state: NoApiServerConnectionStatus, channelId: ePlatform, x: Credentials) => {
    if ((state & NoApiServerConnectionStatus.RequiresOTP) != 0) return RequiresOTPr(channelId, x);

    if ((state & NoApiServerConnectionStatus.RequiresSoftOTP) != 0) return RequiresSoftOTP(channelId, x);

    if ((state & NoApiServerConnectionStatus.NotASeller) != 0) return NotASeller(channelId, x);

    if ((state & NoApiServerConnectionStatus.AccountSuspended) != 0) return AccountSuspended(channelId, x);

    if ((state & NoApiServerConnectionStatus.BadUsername) != 0) return BadUsername(channelId, x);

    if ((state & NoApiServerConnectionStatus.BadPassword) != 0) return BadPassword(channelId, x);

    if ((state & NoApiServerConnectionStatus.UserActionRequired) != 0) return UserActionRequired(x);

    return <React.Fragment key={'rr' + x.noApiServerId} />;
  };

  useEffect(() => {
    UpdateCredentials(allCredentials ?? []);
    UpdateUICredentials(allCredentials ?? []);
    (async () => {
      const r = await dispatch(getManagedServers());

      UpdateCredentials(r?.payload?.credentials ?? []);
      UpdateUICredentials(r?.payload?.credentials ?? []);
      setAllCredentials(manageServerResult?.credentials ?? []);
    })();
  }, [getManagedServers]);

  const FilterChannels = (stealth: boolean, x: Channel) => {
    if (stealth)
      return x.channelId == ePlatform.eBayNoApi;
    return GetPlatform(x.channelId).isNoApi;
  };

  const selectOneOption = {
    label: <T k='NoApiServer.SelectOne'/>,
    value:'0'
  };

  //const HandleProxyClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, x: Credentials, i: number) => {
  //  e.stopPropagation();
  //  e.preventDefault();

  //};

  const Subscribed = () => {
    return <>
      <table>
        <thead>
          <tr style={{ borderBottom: '2px gray solid' }}>
            <th></th>
            <th><T k="NoApiServerConfiguration.Subscription"/></th>
            <th><T k="NoApiServerConfiguration.Channel" /></th>
            <th><T k="NoApiServerConfiguration.Username" /></th>
            <th><T k="NoApiServerConfiguration.StorePassword" /></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {allCredentials?.map((x: Credentials, i: number) => {
            const platform = GetPlatform(x.channelItem);
            return (
              <React.Fragment key={i}>
                <tr key={i}>
                  <td>{x.stealth ? <img src={incognito_img} /> : <></>}</td>
                  <td>
                    <h5>{
                      x.stealth ? 
                        <T k="NoApiServerConfiguration.NoApiServerStealth" />
                        :
                        <T k="NoApiServerConfiguration.NoApiServerNormal" />
                    }</h5>
                  </td>
                  <td>
                    <div>
                      <Selector
                        onChange={(value: SelectorValue) => HandleOptionChange(Number(value), x.subscriptionId)}
                        defaultValue={x.channelOauthId ?? 0}
                      >
                        {[selectOneOption, ...(channels?.filter(c => FilterChannels(x.stealth, c))?.map(c => ({ value: c.id, label: <ChannelLabel key={c.id} channel={c} /> })) ?? [])]}
                      </Selector>
                    </div>
                  </td>
                  <td>
                    <div>
                      <Input
                        disabled={!platform?.noApi?.customUsername}
                        defaultValue={x.loginUsername ?? undefined}
                        value={x.loginUsername ?? manageServerResult.channels?.find((y) => x.channelOauthId == y.id)?.displayName}
                        onChange={(e) => HandleUserNameChange(e.target.value, x.subscriptionId)}
                        placeholder="username"
                      />
                    </div>
                  </td>
                  <td>
                    <div>
                      <Input.Password
                        onChange={(e) => {
                          HandlePasswordChange(e.target.value, i);
                        }}
                        value={x.password}
                      />
                    </div>
                  </td>
                  {/*<td>*/}
                  {/*  <a href="#" onClick={(e) => HandleProxyClick(e, x, i)}><ShareAltOutlined /></a>*/}
                  {/*</td>*/}
                </tr>

                {x.channelItem == ePlatform.Amazon && (
                  <tr key={i + '_' + 4}>
                    <div id="keyform">
                      <p>
                        Key (
                        <A to="https://hustlegotreal.com/en/noapiserver-amazonkey/" target="_blank" rel="noreferrer">
                          Where to get it?
                        </A>
                        )
                      </p>
                      <div>
                        <Input value={x.twoStepSecret} onChange={(e) => HandleTwoStepSecret(e.target.value, i)} />
                      </div>
                    </div>
                  </tr>
                )}
                {x.state !== null && x.channelOauthId !== null && NoApiServerStatus(x.state, x.channelItem, x)}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <Button onClick={() => SaveChanges()}><T k='NoApiServer.Save'/></Button>
    </>;
  };

  const NoSubscribed = () => {
    return <div className="nosuscribed-container">
      <div className="nosuscribed">
        <h3>Oops it looks like you don&apos;t have any no api server contracted.</h3>
        <p>Do you want to keep your NO API extension running 24/7?</p>
        <p>We can do it for you for only 12.99GBP/month.</p>
        <A to="https://hustlegotreal.com/en/no-api-server/" rel="noreferrer" target="_blank" className="footer-link">
          <PrimaryBtn>
            Read more
          </PrimaryBtn>
        </A>
      </div>
    </div>;
  };

  const Content = () => {
    if (thereAreServers) {
      return Subscribed();
    } else {
      return NoSubscribed();
    }
  };

  if (loading) return <Spin />;


  return (
    <div className="configure-noapi-container">
      <h1><T k="NoApiServerConfiguration.Title"/></h1>
      <div className="no-api-servers">
        {Content()}
      </div>
    </div>
  );
};
