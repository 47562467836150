import React from 'react';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  layout?: 'vertical' | 'horizontal';
}

export const FormLayout = (props: Props) => {
  return <div {...props} className={'ant-form ' + (props.layout === 'vertical' ? 'ant-form-vertical ' : '') + (props.className ?? '')}>
    {props.children}
  </div>;
};