import { SmartListerFilter } from '../../../../redux/smart-lister/data/smart-lister-filter';
import { SmartListerFilterType } from '../../../../redux/smart-lister/data/smart-lister-filter-type';
import { T } from '../../../../utils/transShim';
import { RenderBoolean } from '../../../listings/Listings/columns-renders';
import { RenderBooleanSF, RenderCheckHistoryButton, RenderPrice, RenderProfit, RenderQuantityByTime, RenderQuantityListed, RenderSourceList, RenderStatus } from './column-renders';
import { ColumnId } from './columns-id';
import { ArrayExpandableCondition, GenericExpandableCondition } from './expandable-conditions';
import { SmartListerColumnData } from './smart-lister-column-data ';

export const Columns: SmartListerColumnData[]  = [
  {
    id: ColumnId.ListVariations,
    title: <T k='SmartLister.Table.PublishVariations' />,
    dataIndex: 'listVariations',
    render: RenderBooleanSF
  },
  {
    id: ColumnId.OptimizeTitles,
    title: <T k='SmartLister.Table.OptimizeTitles' />,
    dataIndex: 'optimizeTitles',
    render: RenderBoolean
  },
  {
    id: ColumnId.OptimizeDescriptions,
    title: <T k='SmartLister.Table.OptimizeDescriptions' />,
    dataIndex: 'optimizeDescriptions',
    render: RenderBoolean
  },
  {
    id: ColumnId.QuantityByTime,
    title: <T k='SmartLister.Table.Quantity' />,
    dataIndex: 'quantityByTime',
    render: RenderQuantityByTime
  },
  {
    id: ColumnId.Price,
    title: <T k='SmartLister.Table.Price' />,
    dataIndex: 'priceMin',
    render: RenderPrice
  },
  {
    id: ColumnId.Price,
    title: <T k='SmartLister.Table.Profit' />,
    dataIndex: 'ProfitMin',
    render: RenderProfit
  },
  {
    id: ColumnId.QuantityListed,
    title: <T k='SmartLister.Table.Listed' />,
    dataIndex: 'quantityListed',
    render: RenderQuantityListed
  },
  {
    id: ColumnId.Status,
    title: <T k='SmartLister.Table.Status' />,
    dataIndex: 'status',
    render: RenderStatus
  },
  {
    id: ColumnId.OnlyPrime,
    title: <T k='SmartLister.Table.OnlyPrime' />,
    dataIndex: 'onlyPrime',
    render: RenderBoolean,
    expandable: {
      expandable: true,
      expandableCondition: (_, f: SmartListerFilter) => f.type != SmartListerFilterType.Smart,
      showExpandable: ArrayExpandableCondition
    }
  },
  {
    id: ColumnId.Sources,
    title: <T k='SmartLister.Table.Sources' />,
    dataIndex: 'sourceList',
    render: RenderSourceList,
    expandable: {
      expandable: true,
      expandableCondition: GenericExpandableCondition,
      showExpandable: ArrayExpandableCondition
    }
  },
  {
    id: ColumnId.TitleContains,
    title: <T k='SmartLister.Table.TitleContains' />,
    dataIndex: 'titleContains',
    expandable: {
      expandable: true,
      expandableCondition: GenericExpandableCondition,
      showExpandable: GenericExpandableCondition
    }
  }, {
    id: ColumnId.CheckHistoryButton,
    title: '',
    dataIndex: 'id',
    render: RenderCheckHistoryButton,
    expandable: {
      expandable: true,
      align: 'right'
    }
  }
];