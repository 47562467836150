import { T } from '../../../utils/transShim';
import { eShop } from './eShop';
import { PrimaryBtn, SecondaryBtn } from 'src/small-components/ActionBtns';

import { A } from '../../../small-components/A';

interface props {
  step: number;
  platform: eShop;
  onNext: () => void;
}
export const DoYouHaveAccount = (props: props) => {
  const { platform, onNext } = props;

  const url = () => {
    switch (platform) {
      default:
      case eShop.eBay:
        return 'https://hustlegotreal.com/en/how-to-create-an-ebay-seller-account/';
      case eShop.Shopify:
        return 'https://www.shopify.com/free-trial?ref=hustle-got-real-ltd';
      case eShop.Amazon:
        return 'https://hustlegotreal.com/en/how-to-create-an-amazon-seller-account';
      case eShop.Facebook:
        return 'https://www.facebook.com/marketplace';
      case eShop.WooComerce:
        return 'https://wordpress.com/';
      case eShop.TikTok:

    }
  };

  const RenderNo = () => {
    const Text = () => {
      switch (platform) {
        case eShop.eBay:
        case eShop.Amazon:
          return <T k="NewChannel.DoYouHave.HowTo" values={{ n: eShop[platform] }}/>;
        case eShop.Shopify:
          return <T k="NewChannel.DoYouHave.CreateAcc" values={{ n: eShop[platform] }} />;
        case eShop.WooComerce:
          return <T k="NewChannel.DoYouHave.CreateOwnStore" values={{ n: eShop[platform] }} />;
        case eShop.Facebook:
          return <T k="NewChannel.DoYouHave.AccessMarketplace" values={{ n: eShop[platform] }} />;
        case eShop.TikTok:
        default:
          return <T k="NewChannel.DoYouHave.AccessTo" values={{ n: eShop[platform] }} />;
      }
    };

    return <A to={url()} className="alternative-link become-seller" target="_blank" rel="noreferrer">
      <SecondaryBtn>
        {Text()}
      </SecondaryBtn>
    </A>;
  };

  return (
    <div className="container-info">
      <h2 className="title"><T k="NewChannel.DoYouHave.Title" values={{ n: eShop[platform] }} /></h2>
      <div className="buttons">
        {RenderNo()}
        <PrimaryBtn onClick={onNext}><T k="NewChannel.DoYouHave.Yes" values={{ n: eShop[platform] }} /></PrimaryBtn>
      </div>
    </div>
  );
};
