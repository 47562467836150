import { Spin } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../custom-hooks/reduxCustomHooks';
import { ClaimPrize, Prizes } from '../../../../redux/dashboard/prizesThunk';
import { getUserToken } from '../../../../redux/user/userThunk';
import { PrimaryBtn } from '../../../../small-components/ActionBtns';
import { T } from '../../../../utils/transShim';

type Props = {
  prize: Prizes;
};

export const TokenTask = (props: Props) => {
  const { prize } = props;
  const dispatch = useAppDispatch();

  const { claiming } = useAppSelector((state) => state.prizes);
  const loadingClaim = claiming?.[prize.id]?.loading ?? false;
  const claimed = claiming?.[prize.id]?.done ?? false;

  const HandleClick = async () => {
    await dispatch(ClaimPrize({ id: prize.id }));
    dispatch(getUserToken());
  };

  return (
    <div className="alert-with-button">
      <T k="Dashboard.Tasks.Prizes.Tokens" values={{ q: prize.quantity }} />
      <PrimaryBtn onClick={HandleClick} disabled={loadingClaim || claimed}>
        {!loadingClaim && !claimed && <T k="Dashboard.Tasks.Prizes.Tokens.Claim" />}
        {!loadingClaim && claimed && <T k="Dashboard.Tasks.Prizes.Tokens.Claimed" />}
        {loadingClaim && <Spin />}
      </PrimaryBtn>
    </div>
  );
};
