import { MessageType } from './message-type';

export interface DetailRowProps {
  title: string | JSX.Element;
  message?: string;
  type: MessageType;
}

export const DetailRow = (props: DetailRowProps) => {

  const GetCustomClass = () => {
    switch (props.type) {
      case MessageType.Information:
        return 'information';
      case MessageType.Warning:
        return 'warning';
      case MessageType.Error:
        return 'error';
      case MessageType.Success:
        return 'success';
      default:
        return '';
    }
  };

  const GetCustomIcon = () => {
    switch (props.type) {
      case MessageType.Information:
        return 'glyphicon glyphicon-info-sign';
      case MessageType.Warning:
        return 'glyphicon-exclamation-sign';
      case MessageType.Error:
        return 'glyphicon glyphicon-remove';
      case MessageType.Success:
        return 'glyphicon glyphicon-ok-sign';
      default:
        return '';
    }
  };

  return (
    <div className='card'>
      <div className='card-inner'>
        <div className={'row-title ' + GetCustomClass()}>
          <span className={'glyphicon ' + GetCustomIcon()} aria-hidden='true'></span>{' '}
          {props.title}
        </div>
        <hr className='card-inner-separator' />
        <div className='row-body'>{props.message}</div>
      </div>
    </div>
  );
};