import { Col, Popover, Row } from 'antd';
import moment from 'moment';

import { useAppSelector } from '../../../custom-hooks/reduxCustomHooks';
import { Links } from '../../../links';
import { A } from '../../../small-components/A';
import { PrimaryBtn } from '../../../small-components/ActionBtns';
import { T } from '../../../utils/transShim';

export const QuotaPanel = () => {
  const { quota: productQuota } = useAppSelector((state) => state.user);

  const mendsOn = productQuota != null ? moment(productQuota.endsOn) : moment.utc();
  const mnow = moment.utc();
  const leftDays = productQuota != null ? mendsOn.diff(mnow, 'days') : 0;
  const leftHours = productQuota != null ? mendsOn.diff(mnow, 'hours') : 0;
  const leftMinutes = productQuota != null ? mendsOn.diff(mnow, 'minutes') : 0;
  const leftSeconds = productQuota != null ? mendsOn.diff(mnow, 'seconds') : 0;
  const freeQuota = productQuota != null && productQuota.price == 0 && productQuota.quota <= 10;

  //const Frequency = (frequency: BillingPeriod) => {
  //  switch (frequency) {
  //    default:
  //      return <></>;
  //    case BillingPeriod.Biannually:
  //      return <>/ {<T k='Listings.Dashboard.6month' />}</>;
  //    case BillingPeriod.Monthly:
  //      return <>/ {<T k='Listings.Dashboard.month' />}</>;
  //    case BillingPeriod.Yearly:
  //      return <>/ {<T k='Listings.Dashboard.year' />}</>;
  //  }
  //};

  //Quick wins
  const QuotaRecommendations = () => {
    if (productQuota != null) {
      const halfQuota = productQuota?.quota / 2;
      const quotaAlmostFilled = productQuota?.quota - productQuota?.quota * 0.2;

      const popOverQuotaNotFilled = (
        <div className="popover-content">
          <p>
            You have {productQuota?.quota - productQuota?.used} products left to fill your quota, the more you list, the more likely you are
            to sell. Do you want us to list for you?
          </p>
          <A to={Links.Services.ListingService}>
            <PrimaryBtn>View service</PrimaryBtn>
          </A>
        </div>
      );
      const popOverQuotatFilled = (
        <div className="popover-content">
          <p>
            Your quota is about to reach maximum capacity, we highly recommend that you upgrade your subscription. You can request a refund
            for the unused days of your current subscription.
          </p>
          <A to={Links.Subscriptions}>
            <PrimaryBtn>Upgrade Now</PrimaryBtn>
          </A>
        </div>
      );

      if (productQuota?.used < halfQuota) {
        return (
          <>
            <Popover content={popOverQuotaNotFilled}>
              <div className="products active-sub-alert">
                <p>
                  <T k="Listings.Dashboard.Monitored" />
                </p>
                <p>
                  <b>{productQuota?.used}</b>
                </p>
              </div>
            </Popover>
          </>
        );
      } else if (productQuota?.used > quotaAlmostFilled) {
        return (
          <>
            <Popover content={popOverQuotatFilled}>
              <div className="products active-sub-alert">
                <p>
                  <T k="Listings.Dashboard.Monitored" />
                </p>
                <p>
                  <b>{productQuota?.used}</b>
                </p>
              </div>
            </Popover>
          </>
        );
      } else {
        return (
          <div className="products">
            <p>
              <T k="Listings.Dashboard.Monitored" />
            </p>
            <p>
              <b>{productQuota?.used}</b>
            </p>
          </div>
        );
      }
    }
  };

  return (
    <Col className="white-box" xs={24} lg={10}>
      <Row className="quota-pannel">
        <Col xs={24} md={12} className="left-part">
          <div className="active-sub-main">
            <h4>
              <b>
                <T k="Subscription.Dashboard.Title" />
              </b>
            </h4>
            {freeQuota || (productQuota?.cancelled && leftDays < 7) ? (
              <span className="active-sub">
                <T k="Subscription.Dashboard.FreePlan" />
              </span>
            ) : (
              <span className="active-sub">
                {productQuota?.quota}&nbsp;
                <T k="Subscription.Dashboard.Products" />
              </span>
            )}
          </div>
          <div className="products-recom">{QuotaRecommendations()}</div>
          <div className="sub-allow">
            <p>
              <T k="Listings.Dashboard.Allowance" />
            </p>
            <p>
              <b>{productQuota?.quota}</b>
            </p>
          </div>
        </Col>
        <Col xs={24} md={12} className="right-part">
          {productQuota?.price != null && (
            <>
              <div className={freeQuota || (productQuota?.cancelled && leftDays < 7) ? 'box-sub-free' : 'box-sub'}>
                {/*<div className="container-numbers">*/}
                {/*  <h2 className="price-sub">{ReactUtils.DrawPrice(productQuota?.price, productQuota?.currency as unknown as eCurrency)}</h2>*/}
                {/*  <p className="frequency-payment">{Frequency(productQuota?.billingPeriod) }</p>*/}
                {/*</div>*/}

                {freeQuota || productQuota?.cancelled ? (
                  <>
                    {freeQuota && (
                      <h2 className="freeplan">
                        <T k="Subscription.Dashboard.FreePlan" />
                      </h2>
                    )}
                    <br />
                    {leftDays > 0 && <T k="Subscription.Dashboard.EndsOn" values={{ left: leftDays }} />}
                    {leftDays == 0 && leftHours > 0 && <T k="Subscription.Dashboard.EndsOnH" values={{ left: leftHours }} />}
                    {leftDays == 0 && leftHours == 0 && leftMinutes > 0 && (
                      <T k="Subscription.Dashboard.EndsOnM" values={{ left: leftMinutes }} />
                    )}
                    {leftDays == 0 && leftHours == 0 && leftMinutes == 0 && leftSeconds > 0 && (
                      <T k="Subscription.Dashboard.EndsOnS" values={{ left: leftSeconds }} />
                    )}
                  </>
                ) : (
                  <></>
                )}
                <span className="offer">
                  <T k="Subscription.Dashboard.Offer" />
                </span>
                <div className="plan">
                  <A className="redirection-link upgrade" to="/subscriptions">
                    <h4 className="upgrade-txt">
                      {freeQuota || productQuota?.cancelled ? (
                        <T k="Listings.Dashboard.SubscribeNow" />
                      ) : (
                        <T k="Listings.Dashboard.Upgrade" />
                      )}
                    </h4>
                  </A>
                </div>
              </div>
            </>
          )}
        </Col>
      </Row>
    </Col>
  );
};
