import { ReactNode } from 'react';
import { Input, Button } from 'antd';
import advancedSearchIcon from '../../../assets/icons/listsearch.svg';
import column_img from '../../../assets/icons/columnimg.svg';
import compact_img from '../../../assets/icons/compact-mode.svg';
import { T, useTranslation } from '../../../utils/transShim';
import { SearchOutlined } from '@ant-design/icons';
import { DeleteBtn, SecondaryBtn } from '../../ActionBtns';
import Tooltip from 'antd/es/tooltip';
import { RefreshData } from '../types/refresh-data';
import { RefreshBtn } from './refresh-btn';

interface TableActionBtnsProps {
  showColumns?: boolean;
  onClick?: () => void;
  onSideDrawer?: () => void;
  onShowColumns?: () => void;
  onChangeCompactMode?: () => void;
  showSeletectedProducts?: boolean;
  showAllSelectedProducts?: () => void;
  addAllProducts?: boolean;
  handleAddAllProducts?: () => void;
  clearAllSelectedProducts?: boolean;
  handleClearAllSelectedProducts?: () => void;
  renderAdvancedSearch?: boolean;
  renderCompactMode?: boolean;
  refresh?: RefreshData
}

export interface SearchInputProps {
  value?: string;
  onSearch: (value: string) => void;
  searchWithButton?: boolean;
}

interface FiltersProps {
  handleSideDrawer?: () => void;
  disabled?: boolean;
  children: ReactNode;
}

export const SearchInput = ({ value, onSearch, searchWithButton }: SearchInputProps) => {
  const placeHolder = useTranslation('search') as string;
  if (searchWithButton) {
    return <Input.Search placeholder={placeHolder} onSearch={onSearch} value={value} />;
  }
  return (
    <Input
      placeholder={placeHolder}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSearch(e.currentTarget.value)}
      onKeyUp={(event) => onSearch(event.currentTarget.value)}
      value={value}
      suffix={<SearchOutlined />}
    />
  );
};

export const TableActionBtns = (props: TableActionBtnsProps) => {
  const {
    showColumns,
    onShowColumns,
    onSideDrawer,
    showSeletectedProducts,
    showAllSelectedProducts,
    addAllProducts,
    handleAddAllProducts,
    clearAllSelectedProducts,
    handleClearAllSelectedProducts,
    renderAdvancedSearch,
    renderCompactMode,
    onChangeCompactMode,
    refresh
  } = props;
  return (
    <div className="search-bars-container">
      {(renderAdvancedSearch ?? true) && (
        <Tooltip title={<T k='Table.AdvancedSearch' />}>
          <>{/*Tooltip won't work with custom directly as children, in that cases it needs something like this*/}
            <SecondaryBtn className="advanced-search-btn" onClick={onSideDrawer} >
              <img src={advancedSearchIcon} height="20" alt="" />
            </SecondaryBtn>
          </>
        </Tooltip>
      )}
      { refresh && refresh?.show &&(
        <RefreshBtn refresh={refresh} />
      )}
      {(renderCompactMode ?? true) && (
        <Tooltip title={<T k='Table.CompactMode' />}>
          <>{/*Tooltip won't work with custom directly as children, in that cases it needs something like this*/}
            <SecondaryBtn className="change-compact-btn" onClick={onChangeCompactMode} >
              <img src={compact_img} height="20" alt="" />
            </SecondaryBtn>
          </>
        </Tooltip>
      )}
      {showColumns && (
        <Tooltip title={<T k='Table.ShowColumns' />}>
          <>{/*Tooltip won't work with custom directly as children, in that cases it needs something like this*/}
            <SecondaryBtn className="view-columns-btn" onClick={onShowColumns}>
              <img src={column_img} height="20" alt="" />
            </SecondaryBtn>
          </>
        </Tooltip>
      )}

      {showSeletectedProducts && (
        <p className="view-all-selected-products" onClick={showAllSelectedProducts}>
          View All Selected Products
        </p>
      )}

      {addAllProducts && (
        <Button className="add-all-products" onClick={handleAddAllProducts}>
          Add All
        </Button>
      )}

      {clearAllSelectedProducts && (
        <DeleteBtn className="clear-all-products" onClick={handleClearAllSelectedProducts}>
          Clear All
        </DeleteBtn>
      )}
    </div>
  );
};

export const FiltersBtn = ({ children, handleSideDrawer, disabled }: FiltersProps) => (
  <SecondaryBtn className="filters-btn" onClick={handleSideDrawer} disabled={disabled}>
    <img src={advancedSearchIcon} height="20" alt="" />
    {children}
  </SecondaryBtn>
);
