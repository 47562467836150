import { AdvancedFilterSelectorValueType, AdvancedFilterValueArrayContent, AdvancedFilterValueType, FieldType } from '../../types/columns';
import { BooleanField } from './boolean-field';
import { DateField } from './date-field';
import { MultipleSelectorField } from './multiple-selector-field';
import { NumberField } from './number-field';
import { OtherChannelsField } from './other-channels-field';
import { StringField } from './string-field';

type Props = {
  type?: FieldType
  name?: string;
  onChange: (value: AdvancedFilterValueType) => void;
  onDateChange: (value: AdvancedFilterValueType) => void;
  values?: AdvancedFilterSelectorValueType[];
}

export const AdvancedSearchField = (props: Props) => {
  const { name, type, onChange, onDateChange, values } = props;

  const HandleSingleField = (value: AdvancedFilterValueArrayContent) => value != null ? onChange([value]) : onChange(null);

  switch (type) {
    default:
    case FieldType.String:
      return <StringField name={name} onChange={HandleSingleField} />;
    case FieldType.Date:
      return <DateField onChange={onDateChange} />;
    case FieldType.Boolean:
      return <BooleanField name={name} onChange={HandleSingleField} />;
    case FieldType.Number:
      return <NumberField name={name} onChange={onChange} />;
    case FieldType.OtherChannels:
      return <OtherChannelsField onChange={onChange} />;
    case FieldType.MultipleSelect:
      return <MultipleSelectorField onChange={onChange} values={values ?? []} />;
  }
};