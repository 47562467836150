import { T } from '../../../utils/transShim';

export const tipsAmazon = (
  <ul>
    <li>
      <T k="AutoOrderingConfiguration.TipsBan.Amazon.1" />
    </li>
    <li>
      <T k="AutoOrderingConfiguration.TipsBan.Amazon.2" />
    </li>
    <li>
      <T k="AutoOrderingConfiguration.TipsBan.Amazon.3" />
    </li>
    <li>
      <T k="AutoOrderingConfiguration.TipsBan.Amazon.4" />
    </li>
  </ul>
);
