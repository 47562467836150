import { T } from '../../../utils/transShim';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../custom-hooks/reduxCustomHooks';
import { getAuthRequest } from '../../../redux/new-channel/newChannelThunk';
import { useHistory, useParams } from 'react-router';
import { ePlatform } from '../../../data/platforms';
import { eCountry } from '../../../data/countries';
import { Links } from '../../../links';
import { eNewChannelStep } from './step';
import { eShop, PlatformToShop } from './eShop';

interface props {
  platform?: ePlatform;
  //code?: string;
  //hmac?: string;
  //host?: string;
  //timestamp?: string;
  site?: eCountry;
  //shop?: string;
}

export const NewChannelCallback = (props: props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  let { platformId, site } = (() => {
    const { channel, site } = useParams<{ channel?: string, site?: string }>();
    return {
      platformId: channel != null ? parseInt(channel) as ePlatform : undefined,
      site: site != null ? parseInt(site) as eCountry : undefined,
    };
  })();

  platformId = props.platform ?? platformId;
  site = props.site ?? site;

  //WooCommerce-----------------------------------------------
  switch (platformId) {
    case ePlatform.WooCommerce:
    {
      const prs: { [id: string]: string | number } = {};
      (new URLSearchParams(window.location.search)).forEach((value, param) => prs[param] = value);

      if (prs['success']?.toString() == '1') {
        history.push(
          Links.NewChannel.Produce(
            eNewChannelStep.End,
            eShop.WooComerce,
            site ?? eCountry.UK
          )
        );
        return <></>;
      } else {
        history.push(
          Links.NewChannel.Produce(
            eNewChannelStep.Name,
            eShop.WooComerce,
            site
          )
        );
        return <></>;
      }
    }
    case ePlatform.Shopify:
      {
        if (!site) {
          history.push(
            Links.NewChannel.Produce(
              eNewChannelStep.Country,
              eShop.Shopify
            )
          );
          return <></>;
        }
      }
      break;
  }

  //eBay, Shopify & Woocomerce 2nd step------------------------------------------------------
  const request = async () => {
    const prs: { [id: string]: string | number } = {};
    if (platformId != null)
      prs.CXID = platformId as number;
    if (site != null)
      prs.site = site as number;

    (new URLSearchParams(window.location.search)).forEach((value, param) => prs[param] = value);

    if (platformId != null)
      prs.CXID = platformId;
    if (site != null)
      prs.site = site;

    const rs = await dispatch(getAuthRequest(prs));

    if (rs.payload) {
      history.push(
        Links.NewChannel.Produce(
          eNewChannelStep.End,
          PlatformToShop(platformId ?? ePlatform.eBay),
          site ?? eCountry.UK
        )
      );
    } else {
      switch (platformId) {
        case ePlatform.eBay:
          history.push(
            Links.NewChannel.Produce(
              eNewChannelStep.ApiNoApi,
              PlatformToShop(platformId),
              site as eCountry ?? eCountry.UK
            )
          );
          break;
        case ePlatform.Shopify:
          history.push(
            Links.NewChannel.Produce(
              eNewChannelStep.DoYouHave,
              PlatformToShop(platformId)
            )
          );
          break;
      }
    }
  };

  useEffect(() => {
    request();
  }, []);

  return (
    <div className="list-cards-container">
      <h3 className='title'><T k='Channel.AddNewChannel.CallBack' /></h3>
    </div>
  );
};